
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Shirditomaharashtra() {



  const cardData =
  {
    keyword: 'Shirdi to Maharashtra Darshan Package',
    heading: 'Saitirtha Travels: Shirdi to Maharashtra Darshan Package',
    headingDescription: 'Explore the diverse and vibrant state of Maharashtra with Saitirtha Travels comprehensive Shirdi to Maharashtra Darshan Package. This package is crafted to take you through the states most iconic landmarks, cultural heritage sites, scenic landscapes, and spiritual destinations. From the bustling city of Mumbai to the ancient caves of Ajanta and Ellora, this journey offers an immersive experience across Maharashtra.',

    top: 'Top Places to Visit in Maharashtra with Saitirtha Travels',

    topPlaces: [
        {
            "title": "Mumbai - Gateway of India and Marine Drive",
            "description": "Begin your journey in Mumbai, the vibrant financial capital of India. Start with a visit to the iconic Gateway of India, a monumental arch that stands as a symbol of the city’s rich history. From there, take a relaxing walk along Marine Drive, also known as the Queen's Necklace, where you can enjoy stunning views of the Arabian Sea, especially at sunset. Explore the bustling markets filled with local handicrafts, experience the vibrant nightlife at nearby pubs and restaurants, and visit historical monuments like the Chhatrapati Shivaji Maharaj Terminus and the Taj Mahal Palace Hotel to get a taste of Mumbai's diverse spirit."
        },
        {
            "title": "Ajanta and Ellora Caves",
            "description": "Marvel at the UNESCO World Heritage Sites of Ajanta and Ellora Caves, renowned for their ancient rock-cut temples and exquisite carvings that showcase Buddhist, Hindu, and Jain artistry. The Ajanta Caves, with their stunning frescoes and sculptures, depict various stories from the Jataka tales, while the Ellora Caves are famous for the monumental Kailasa temple, carved out of a single rock. These caves are an incredible testament to ancient Indian art, architecture, and spirituality, offering visitors a glimpse into the country's rich cultural heritage."
        },
        {
            "title": "Aurangabad - Bibi Ka Maqbara",
            "description": "Known as the 'Mini Taj,' Bibi Ka Maqbara is a stunning mausoleum built by Emperor Aurangzeb in memory of his wife, Dilras Banu Begum. Its intricate Mughal architecture features beautiful gardens and a reflective pool, reminiscent of the grandeur of the Taj Mahal. Surrounded by peaceful grounds, this site is a must-visit for history buffs and architecture enthusiasts alike, providing insight into the era's artistic styles and the legacy of the Mughal Empire."
        },
        {
            "title": "Nashik - Trimbakeshwar Temple",
            "description": "A prominent pilgrimage site, Trimbakeshwar Temple is dedicated to Lord Shiva and is one of the twelve Jyotirlingas in India. Located near the source of the Godavari River, the temple is set against picturesque hills and boasts exquisite architecture and spiritual significance. Devotees flock here to partake in rituals and offerings, making it a lively and spiritual place. The surrounding region is also known for its vineyards and wine production, offering visitors a unique blend of spirituality and culinary experiences."
        },
        {
            "title": "Mahabaleshwar",
            "description": "Escape to the scenic hill station of Mahabaleshwar, known for its breathtaking viewpoints, lush greenery, and pleasant climate. Visit famous points like Arthur's Seat and Elephant’s Head Point for panoramic views of the Western Ghats. Enjoy boating on Venna Lake, indulge in fresh strawberries at local farms, and explore the vibrant local markets. With its tranquil atmosphere and stunning landscapes, Mahabaleshwar is perfect for nature lovers and those seeking relaxation."
        },
        {
            "title": "Pune - Shaniwar Wada and Aga Khan Palace",
            "description": "Pune offers a rich blend of history and modernity. Visit Shaniwar Wada, the historic fort of the Maratha Empire, known for its impressive architecture and historical significance. Explore its beautiful gardens and learn about the intriguing stories of the Peshwas. Then head to the Aga Khan Palace, a national monument connected to India's freedom movement, which features beautiful Italian arches and serene gardens, serving as a museum and a reminder of Mahatma Gandhi's efforts during the struggle for independence."
        },
        {
            "title": "Lonavala and Khandala",
            "description": "Lonavala and Khandala are popular hill stations near Mumbai, famous for their breathtaking landscapes, waterfalls, and scenic viewpoints. Enjoy a peaceful retreat with stunning vistas at spots like Tiger Point and Bhushi Dam, where you can relax amidst nature. These hill stations are perfect for weekend getaways, offering various trekking trails, picturesque picnic spots, and opportunities for adventure activities like paragliding and zip-lining."
        },
        {
            "title": "Alibaug - Beaches and Forts",
            "description": "Alibaug is known for its clean beaches and historic forts, making it a perfect beach getaway. Visit the Kolaba Fort, which is located in the sea and can be reached by walking during low tide. Enjoy the serene beaches where you can relax, sunbathe, and indulge in water sports. The coastal cuisine is a highlight, with numerous restaurants serving delicious seafood and local delicacies, creating a relaxing atmosphere for a day by the ocean."
        },
        {
            "title": "Kolhapur - Mahalaxmi Temple",
            "description": "The Mahalaxmi Temple in Kolhapur is an important pilgrimage site dedicated to Goddess Mahalaxmi, known for its stunning architecture and vibrant cultural atmosphere. This temple is one of the Shakti Peethas and attracts thousands of devotees every year who come to seek blessings. The intricate carvings, beautiful idols, and the lively surroundings make it a significant spiritual destination, while the local markets nearby offer a glimpse into the region's rich handicrafts and culinary delights."
        },
        {
            "title": "Nagpur - Deekshabhoomi",
            "description": "Known as the geographical center of India, Nagpur is home to Deekshabhoomi, a sacred monument where Dr. B.R. Ambedkar embraced Buddhism along with thousands of his followers. This iconic site is characterized by its stunning stupa architecture and peaceful ambiance, providing a spiritually uplifting experience for visitors. The monument is surrounded by beautifully landscaped gardens, making it an ideal place for reflection and learning about Dr. Ambedkar's profound impact on Indian society."
        }
    ],    
    
            
        
"services": [
    {
        "name": "Shirdi to Mumbai Pune Nashik Darshan Package",
        "description": "Embark on a spiritually enriching journey with our Shirdi to Mumbai Pune Nashik Darshan Package. This package takes you through the vibrant cities of Mumbai, Pune, and Nashik, where you'll visit iconic temples, historic landmarks, and beautiful attractions. From the serene ambience of the Gateway of India in Mumbai to the spiritual sites of Pune and the famous Kalaram Temple in Nashik, this package promises a perfect blend of spirituality, culture, and history."
    },
    {
        "name": "Shirdi to Maharashtra Tour Package",
        "description": "Explore the rich cultural tapestry of Maharashtra with our Shirdi to Maharashtra Tour Package. This all-inclusive package is designed to offer a holistic experience, covering the state’s most famous tourist spots. From historical forts, picturesque beaches, hill stations like Mahabaleshwar, to sacred pilgrimage sites, this package promises to provide a diverse view of Maharashtra’s natural beauty, culture, and heritage."
    },
    {
        "name": "Best Shirdi to Maharashtra Darshan Package",
        "description": "The Best Shirdi to Maharashtra Darshan Package is perfect for those looking for an enriching pilgrimage experience. This package includes visits to significant religious sites, such as the famous temples of Pandharpur, Nashik, and more. Experience the spiritual tranquility and cultural heritage of Maharashtra as you travel through its sacred landscapes, with guided tours and comfortable transportation provided."
    },
    {
        "name": "Shirdi to Mumbai Pune Nashik Sightseeing Tour",
        "description": "Join our Shirdi to Mumbai Pune Nashik Sightseeing Tour for a comprehensive tour of these iconic cities. In Mumbai, you’ll visit landmarks such as the Gateway of India and Marine Drive. Pune, with its rich history and forts like Shaniwar Wada, offers a fascinating peek into Maharashtra’s glorious past. Nashik, known for its religious significance, boasts temples like the Kalaram Temple and a scenic wine region, perfect for those looking to blend spirituality with leisure."
    },
    {
        "name": "Shirdi to Maharashtra Tourist Places Package",
        "description": "Our Shirdi to Maharashtra Tourist Places Package is designed for travelers who want to explore the many facets of Maharashtra. From the beaches of Konkan to the hill stations of Mahabaleshwar and Lonavala, this package covers diverse attractions. Whether you’re seeking a quiet retreat in nature or an exploration of the state’s cultural heritage, our package ensures that you experience the best of Maharashtra in a convenient and well-planned itinerary."
    },
    {
        "name": "Shirdi to Maharashtra Pilgrimage Tour",
        "description": "For those seeking spiritual solace, the Shirdi to Maharashtra Pilgrimage Tour is an ideal choice. This tour takes you to revered temples and shrines, including Shirdi, Pandharpur, Nashik, and more. Experience the tranquility of the sacred places, with expert guides providing historical insights into the significance of each destination. This tour is designed to offer spiritual enrichment alongside the serenity of nature."
    },
    {
        "name": "Shirdi to Mumbai Pune Nashik Taxi for Darshan",
        "description": "Travel in comfort with our Shirdi to Mumbai Pune Nashik Taxi for Darshan service. Our professional drivers ensure a smooth and safe journey, letting you focus on the spiritual experience. This service is perfect for those visiting multiple sacred sites across these cities. Enjoy a flexible itinerary, where you can visit the temples and landmarks at your own pace, while we take care of the travel logistics."
    },
    {
        "name": "Shirdi to Maharashtra Car Rental for Sightseeing",
        "description": "Our Shirdi to Maharashtra Car Rental for Sightseeing service offers you the freedom to explore Maharashtra at your own pace. With a variety of vehicles to choose from, including sedans, SUVs, and luxury cars, you can create a customized itinerary. Whether you're visiting temples, forts, hill stations, or beaches, this service gives you the flexibility and independence to make your own travel plans."
    },
    {
        "name": "Maharashtra Darshan from Shirdi",
        "description": "Experience the best of Maharashtra with our Maharashtra Darshan from Shirdi tour. This comprehensive tour includes visits to important pilgrimage sites, cultural landmarks, and natural wonders. Whether you're interested in exploring spiritual sites like Pandharpur and Nashik, or visiting hill stations such as Mahabaleshwar, this tour covers the major attractions and ensures a memorable journey."
    },
    {
        "name": "Shirdi to Maharashtra Distance for Darshan",
        "description": "The Shirdi to Maharashtra Distance for Darshan varies depending on your destinations. Whether you're traveling to Mumbai, Pune, Nashik, or any other pilgrimage site, we provide detailed information about the distance, travel times, and routes. Our services ensure that you travel efficiently and comfortably, with well-maintained vehicles and experienced drivers to navigate the roads."
    },
    {
        "name": "Maharashtra Darshan Tour Package From Shirdi",
        "description": "Our Maharashtra Darshan Tour Package from Shirdi is a fully-inclusive package that offers transportation, accommodation, and guided tours. This package ensures a stress-free journey as we take care of all logistics, allowing you to enjoy the spiritual and cultural landmarks of Maharashtra. Visit temples, holy sites, and scenic locations with the guidance of experienced local experts."
    },
    {
        "name": "Shirdi to Goa Packages",
        "description": "Extend your journey with our Shirdi to Goa Packages. After your spiritual journey in Shirdi, relax on the beautiful beaches of Goa and explore the vibrant nightlife. Our packages are customizable, offering a perfect blend of spiritual retreats and leisure time. Enjoy the pristine beaches, Portuguese architecture, and the relaxed vibe of Goa, making it the ideal post-pilgrimage getaway."
    },
    {
        "name": "Shirdi to Mahabaleshwar Tour Package",
        "description": "Escape to the scenic beauty of the Western Ghats with our Shirdi to Mahabaleshwar Tour Package. Known for its lush green landscapes, cool weather, and viewpoints, Mahabaleshwar is the perfect destination for nature lovers. This package includes visits to famous spots like Arthur’s Seat, Venna Lake, and the ancient Mahabaleshwar Temple, offering a refreshing contrast to the religious atmosphere of Shirdi."
    },
    {
        "name": "Shirdi to 12 Jyotirlinga Tour Package",
        "description": "Our Shirdi to 12 Jyotirlinga Tour Package takes you on a sacred journey to visit all twelve Jyotirlinga shrines in Maharashtra. This pilgrimage is of great spiritual significance, and our package offers a seamless and comfortable way to experience these holy destinations. With guided tours and well-planned transportation, this tour is perfect for devotees seeking to complete their sacred journey through the Jyotirlingas."
    },
    {
        "name": "Shirdi to Shaktipeeth Package",
        "description": "Our Shirdi to Shaktipeeth Package offers a unique blend of spirituality and cultural exploration. Visit the revered Shaktipeeths of Maharashtra, each with its own historical and religious significance. This tour is designed for those who want to deepen their spiritual connection by visiting the sacred shrines dedicated to Goddess Shakti. Enjoy a peaceful journey, guided by experts who share the history and significance of each site."
    },
    {
        "name": "Shirdi to Pandharpur Darshan Package",
        "description": "Join our Shirdi to Pandharpur Darshan Package to visit the famous Vithoba Temple, a significant pilgrimage site for devotees from across the country. Known as the 'Pandharpur of the South,' this journey offers a deep spiritual experience. Our package includes comfortable transportation, guided visits to the temple, and time for reflection and devotion."
    },
    {
        "name": "Shirdi to Maharashtra Darshan Package Contact Information",
        "description": "For more information or to book your Shirdi to Maharashtra Darshan Package, please contact SaitirthaTours & Travels at +91 9923879009 / 9922479009. Our team is ready to assist you with all your travel needs, ensuring that your journey is comfortable, enriching, and memorable. We are committed to providing the best travel experience, allowing you to focus on your spiritual and cultural exploration."
    }
],


"tableData": [
    ["- Shirdi to Mumbai Pune Nashik Darshan Package", "- Shirdi to Maharashtra Tour Package"],
    ["- Best Shirdi to Maharashtra Darshan Package", "- Shirdi to Mumbai Pune Nashik Sightseeing Tour"],
    ["- Shirdi to Maharashtra Tourist Places Package", "- Shirdi to Maharashtra Pilgrimage Tour"],
    ["- Shirdi to Mumbai Pune Nashik Taxi for Darshan", "- Shirdi to Maharashtra Car Rental for Sightseeing"],
    ["- Maharashtra Darshan from Shirdi", "- Shirdi to Maharashtra Distance for Darshan"],
    ["- Maharashtra Darshan Tour Package From Shirdi", "- Shirdi to Goa Packages"],
    ["- Shirdi to Mahabaleshwar Tour Package", "- Shirdi to 12 Jyotirlinga Tour Package"],
    ["- Shirdi to Sadeteen Shaktipeeth Package", "- Shirdi to Pandharpur Darshan Package"],
    ["- SaitirthaTours & Travels Shirdi", ""]
  ],
  "whychoose": [
    {
      "WhyChooseheading": "Comprehensive Itinerary",
      "WhyChoosedescription": "Our Maharashtra Darshan Package is thoughtfully curated to cover all major attractions in the state, ensuring you experience the rich culture, history, and natural beauty of Maharashtra. Each itinerary includes visits to important pilgrimage sites, stunning landscapes, and vibrant urban areas, allowing you to appreciate the diverse offerings of the region. From the iconic Gateway of India in Mumbai to the serene hills of Mahabaleshwar, our carefully designed routes guarantee that you won't miss any highlights. We also incorporate ample time for exploration and relaxation, ensuring a fulfilling travel experience."
    },
    {
      "WhyChooseheading": "Expert Guidance",
      "WhyChoosedescription": "Our knowledgeable guides accompany you throughout the journey, providing insights into each destination and ensuring you make the most of your visit. They share interesting facts, legends, and historical context about the sites you visit, enriching your understanding of Maharashtra’s heritage. With their local expertise, they can also recommend the best times to visit specific attractions, ensuring you have a memorable experience. Their assistance extends to practical aspects, such as navigating the areas and helping you interact with locals, enhancing your travel enjoyment."
    },
    {
      "WhyChooseheading": "Comfortable Travel",
      "WhyChoosedescription": "Enjoy the journey in our modern, air-conditioned vehicles, providing comfort and relaxation as you travel from one destination to another. We prioritize your comfort with spacious seating, ample legroom, and amenities designed for a pleasant travel experience. Our vehicles are equipped with the latest safety features, ensuring a secure ride. Whether you are traveling alone or with family and friends, you can relax and enjoy the scenic views of Maharashtra as you move between the various attractions on your itinerary."
    },
    {
      "WhyChooseheading": "Customizable Packages",
      "WhyChoosedescription": "We understand that every traveler has unique preferences. Our packages can be customized to fit your specific needs, including the duration and specific places of interest. Whether you want to spend more time at a particular site, add extra destinations, or tailor the experience to include special activities like local tours or culinary experiences, we are here to accommodate your requests. Our goal is to ensure that your journey aligns perfectly with your expectations and travel desires, providing a personalized experience."
    },
    {
      "WhyChooseheading": "Safe and Secure",
      "WhyChoosedescription": "Your safety is our top priority. All our drivers are experienced and adhere to strict safety protocols, ensuring a secure travel experience. We conduct thorough background checks and training for our drivers, emphasizing safety and customer care. Our vehicles are regularly inspected and maintained to meet safety standards. Additionally, we utilize GPS tracking systems to monitor your journey in real-time, providing an extra layer of security and reassurance for our travelers."
    },
    {
      "WhyChooseheading": "Affordable Pricing",
      "WhyChoosedescription": "We offer competitive rates with no hidden fees. Our transparent pricing structure makes it easy to plan your budget for the trip. We understand the importance of value for money in travel, and we strive to provide high-quality services without compromising affordability. By offering various package options, we ensure you can choose one that fits your budget while still receiving exceptional service and support throughout your journey."
    },
    {
      "WhyChooseheading": "Flexible Booking Options",
      "WhyChoosedescription": "You can easily book your package online or contact our customer service for assistance with your reservation. Our booking process is designed to be straightforward, allowing you to secure your travel plans with minimal hassle. Should you need to make changes or adjustments, our team is always available to help, providing flexible options to meet your travel requirements. We aim to make your planning experience as easy and convenient as possible, ensuring you have peace of mind from the moment you book."
    },
    {
      "WhyChooseheading": "24/7 Customer Support",
      "WhyChoosedescription": "Our dedicated customer support team is available around the clock to assist you with any inquiries or changes to your itinerary. Whether you have questions before your trip or need assistance during your travels, we are here to help. Our responsive support team is committed to ensuring that you have a smooth and enjoyable experience from start to finish. We prioritize clear communication and are always ready to address any concerns you may have, making your satisfaction our top priority."
    },
    {
      "WhyChooseheading": "Positive Feedback",
      "WhyChoosedescription": "Our past customers have shared positive experiences about our services, highlighting our commitment to quality and customer satisfaction. We take pride in our strong reputation built on delivering memorable journeys and exceptional service. Many of our clients return to us for their travel needs, recommending us to friends and family. Their feedback drives us to continually improve our offerings, ensuring we meet and exceed the expectations of all our travelers."
    },
    {
      "WhyChooseheading": "Cultural Experiences",
      "WhyChoosedescription": "Enjoy unique cultural experiences, including local cuisine and festivals, giving you a deeper understanding of Maharashtra's heritage. Our packages often include opportunities to taste regional specialties, participate in local celebrations, or visit markets and artisanal shops, allowing you to immerse yourself in the culture. We encourage interactions with local communities and recommend experiences that highlight the diverse traditions of Maharashtra, enhancing your overall travel experience and making it truly memorable."
    }
  ]





        
  }


  const faqData = [
    {
        question: "What destinations are included in the Maharashtra Darshan Package?",
        answer: "The package typically includes major attractions like Ajanta and Ellora Caves, Nashik, Pune, Lonavala, Mahabaleshwar, and more."
    },
    {
        question: "How long is the duration of the package?",
        answer: "The duration can vary based on the itinerary you choose, but most packages range from 3 to 7 days."
    },
    {
        question: "What type of vehicle will be provided?",
        answer: "We offer a variety of vehicles, including sedans, SUVs, and coaches, depending on the group size and package selected."
    },
    {
        question: "Are meals included in the package?",
        answer: "Yes, meals can be included based on your package selection. Please check with our customer service for specific details."
    },
    {
        question: "Can I customize my itinerary?",
        answer: "Absolutely! We can customize the itinerary to suit your preferences and interests. Just let us know what you'd like to include."
    },
    {
        question: "What is the cancellation policy?",
        answer: "We have a cancellation policy in place. Please contact our customer service for detailed information regarding cancellations."
    },
    {
        question: "Is travel insurance included?",
        answer: "Travel insurance is not included but can be arranged upon request for your peace of mind during the journey."
    },
    {
        question: "How do I book the package?",
        answer: "You can book the package online through our website or contact our customer service team for assistance."
    },
    {
        question: "Are there any hidden costs?",
        answer: "No, we pride ourselves on transparency. All costs will be outlined during the booking process with no hidden fees."
    },
    {
        question: "What should I do if I have any complaints?",
        answer: "If you have any concerns or feedback during your journey, please reach out to our customer service for prompt resolution."
    }
];

const testimonials = [
    {
        name: "Mrs. Kavita Sharma",
        text: "Our family had an amazing experience with the Maharashtra Darshan Package from Saitirtha Travels. The itinerary was well-planned, and we enjoyed every destination. Our guide was knowledgeable and friendly, making the trip memorable!"
    },
    {
        name: "Mr. Rajesh Patil",
        text: "I highly recommend Saitirtha Travels for the Maharashtra Darshan Package. The service was excellent, and we felt safe throughout our journey. The cab was comfortable, and we saw some beautiful places. Will book again!"
    }
];





  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
<Helmet>
    <title>Contact: +91 9923879009 | SaitirthaTours & Travels | Shirdi to Maharashtra Darshan Package | Best Taxi Service | SaitirthaTours & Travels</title>
    <meta name="description" content="Book an affordable Shirdi to Maharashtra Darshan Package with SaitirthaTours & Travels. Offering tours to Mumbai, Pune, Nashik, and other Maharashtra pilgrimage sites in comfortable vehicles like Innova, Ertiga, or Tempo Traveller." />
    <meta property="og:title" content="Contact: +91 9923879009 | SaitirthaTours & Travels | Shirdi to Maharashtra Darshan Package | Best Taxi Service | SaitirthaTours & Travels" />
    <meta property="og:description" content="Book an affordable Shirdi to Maharashtra Darshan Package with SaitirthaTours & Travels. Offering tours to Mumbai, Pune, Nashik, and other Maharashtra pilgrimage sites in comfortable vehicles like Innova, Ertiga, or Tempo Traveller." />
    <meta property="og:url" content="https://saitirthatravels.com/shirdi-to-maharashtra-darshan-package" />
    <meta property="og:image" content="https://saitirthatravels.com/images/shirdi-to-maharashtra-darshan-package.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Book an affordable Shirdi to Maharashtra Darshan Package with SaitirthaTours & Travels. Offering tours to Mumbai, Pune, Nashik, and other Maharashtra pilgrimage sites in comfortable vehicles like Innova, Ertiga, or Tempo Traveller.",
            "url": "https://saitirthatravels.com/shirdi-to-maharashtra-darshan-package",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/shirdi-to-maharashtra-darshan-package.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi to Maharashtra Darshan Package",
                "Shirdi to Mumbai Pune Nashik Darshan package",
                "Shirdi to Maharashtra tour package",
                "Best Shirdi to Maharashtra Darshan package",
                "Shirdi to Mumbai Pune Nashik sightseeing tour",
                "Shirdi to Maharashtra Tourist Places Package",
                "Shirdi to Maharashtra pilgrimage tour",
                "Shirdi to Mumbai Pune Nashik taxi for Darshan",
                "Shirdi to Maharashtra car rental for sightseeing",
                "Maharashtra Darshan from Shirdi",
                "Shirdi to Maharashtra distance for Darshan",
                "Maharashtra Darshan Tour Package From Shirdi",
                "Shirdi to Goa Packages",
                "Shirdi to Mahabaleshwar Tour Package",
                "Shirdi to 12 Jyotirlinga Tour Package",
                "Shirdi to Sadeteen Shaktipeeth Package",
                "Shirdi to Pandharpur Darshan Package",
                "SaitirthaTours & Travels Shirdi"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/68.Shirdi to Maharashtra Darshan Package.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirditomaharashtra;