
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Shirdioutstatiponcabs() {



  const cardData =
  {
    keyword: ' Shirdi Outstation Cabs',
    heading: 'Saitirtha Travels: Shirdi Outstation Cabs',
    headingDescription: 'When it comes to exploring the scenic beauty and spiritual destinations around Shirdi, Saitirtha Travels provides reliable outstation cab services. Our comfortable and well-maintained vehicles, along with experienced drivers, ensure a pleasant journey to your chosen destinations.',

    top: 'Top Places to Visit Near Shirdi with Saitirtha Travels',

    topPlaces: [
        {
            "title": "Shani Shingnapur",
            "description": "Located about 70 kilometers from Shirdi, Shani Shingnapur is a remarkable village known for its unique temple dedicated to Lord Shani, the deity associated with justice and retribution. One of the most striking features of this village is the absence of doors on its houses, symbolizing the unwavering faith of the locals in the deity's protection from theft and harm. Visitors can experience the vibrant atmosphere of the temple, witness the rituals performed, and learn about the traditions that make this village a fascinating destination."
        },
        {
            "title": "Sai Baba's Samadhi Mandir",
            "description": "No visit to Shirdi is complete without exploring Sai Baba's Samadhi Mandir, which houses the final resting place of the revered spiritual leader, Sai Baba. The temple complex is a place of immense devotion, providing a serene atmosphere for prayer and reflection. Visitors can observe the intricate architecture and partake in the various rituals, including the daily aarti (devotional singing) that honors Sai Baba's legacy. The peaceful ambiance and the presence of countless devotees create a deeply spiritual experience."
        },
        {
            "title": "Ajanta and Ellora Caves",
            "description": "Approximately 100 kilometers from Shirdi, the Ajanta and Ellora Caves are UNESCO World Heritage Sites famed for their stunning rock-cut architecture and intricate ancient sculptures. Ajanta is known for its exquisite Buddhist frescoes, while Ellora features a remarkable blend of Hindu, Buddhist, and Jain caves, showcasing the rich cultural heritage of India. These caves not only highlight ancient craftsmanship but also provide insights into the historical and artistic significance of the region, making them a must-visit for tourists."
        },
        {
            "title": "Nashik",
            "description": "Just a short drive from Shirdi, Nashik is a vibrant city renowned for its picturesque wineries and historical temples. The Panchavati area is particularly popular, featuring several significant temples, including the Kalaram Temple and Saptashrungi Temple, along with the sacred Godavari River. Visitors can indulge in wine tasting, explore the local cuisine, and immerse themselves in the spiritual atmosphere that Nashik offers, making it a delightful blend of culture, spirituality, and natural beauty."
        },
        {
            "title": "Bhandardara",
            "description": "About 150 kilometers from Shirdi, Bhandardara is a picturesque hill station celebrated for its breathtaking landscapes, serene Arthur Lake, and enchanting waterfalls. This tranquil destination is perfect for nature lovers and adventure seekers, offering opportunities for trekking, camping, and photography. The lush greenery and panoramic views of the Sahyadri mountains create an idyllic setting for relaxation and exploration, making Bhandardara a hidden gem for those looking to escape the hustle and bustle of city life."
        },
        {
            "title": "Saptashrungi Devi Temple",
            "description": "Located around 130 kilometers from Shirdi, the Saptashrungi Devi Temple is dedicated to Goddess Saptashrungi, known for her divine power and protection. The temple is situated atop a hill, and the trek to reach it is both challenging and rewarding, offering stunning views of the surrounding valleys and forests. This spiritual retreat, surrounded by breathtaking natural beauty, attracts devotees seeking blessings and a deeper connection with the divine, making it a popular pilgrimage site."
        },
        {
            "title": "Anjneri Hill",
            "description": "Anjneri Hill, located near Nashik, is believed to be the birthplace of Lord Hanuman, making it a significant site for devotees. This popular trekking destination offers breathtaking views from the summit, attracting adventure enthusiasts and nature lovers alike. The trek itself is an exhilarating experience, providing a chance to enjoy the lush landscapes and serene atmosphere. Anjneri Hill is not only a place for physical challenge but also a spiritual journey for those seeking to connect with the divine."
        },
        {
            "title": "Khandoba Temple",
            "description": "Situated in Jejuri, approximately 100 kilometers from Shirdi, Khandoba Temple is dedicated to Lord Khandoba, a popular deity in Maharashtra. This vibrant temple attracts numerous pilgrims, especially during festivals when the atmosphere is filled with celebration and devotion. Visitors can explore the intricately designed temple and participate in various rituals, experiencing the rich cultural traditions that surround this significant pilgrimage site."
        },
        {
            "title": "Panchavati",
            "description": "Panchavati, a sacred area in Nashik, is steeped in mythology and is home to several temples associated with the epic Ramayana. Visitors can explore historical sites such as the Kalaram Temple and the Saptashrungi Temple, as well as enjoy the scenic beauty along the banks of the Godavari River. The area is not only a spiritual hub but also a beautiful setting for leisurely strolls, picnics, and experiencing local traditions and rituals, making it a must-visit for anyone traveling to Nashik."
        },
        {
            "title": "Dharampur",
            "description": "Located approximately 120 kilometers from Shirdi, Dharampur is a serene destination known for its lush greenery and tranquil ambiance. This peaceful village offers a perfect escape from the hustle and bustle of city life, making it an ideal place for relaxation and rejuvenation. Visitors can immerse themselves in nature, enjoy the scenic surroundings, and take leisurely walks in the peaceful environment, allowing for a refreshing getaway."
        }
    ],  
    
            
        
"services": [
    {
        "name": "Shirdi Outstation Cabs Service",
        "description": "Travel seamlessly with our Shirdi outstation cabs service, designed to cater to your travel needs beyond Shirdi. Our service provides not only transportation but also a gateway to explore the diverse attractions of Maharashtra and neighboring states. Whether you're heading to scenic hill stations, historical landmarks, or spiritual sites, we ensure your journey is comfortable, safe, and enjoyable. With our knowledgeable drivers familiar with the best routes and local attractions, you can relax and enjoy the sights along the way."
    },
    {
        "name": "Car Rental in Shirdi for Outstation",
        "description": "We offer reliable car rental options in Shirdi for outstation travel, providing you with the flexibility to explore at your own pace. Choose from a diverse range of vehicles tailored to meet your journey requirements, whether you're traveling solo, with family, or in a group. Our rental process is simple and straightforward, with no hidden fees, allowing you to focus on your adventure. Enjoy the freedom of having your own vehicle, equipped with GPS navigation, ensuring you never lose your way while discovering the hidden gems of the region."
    },
    {
        "name": "Outstation Taxi Shirdi",
        "description": "Our outstation taxi services from Shirdi are perfect for exploring nearby attractions or traveling long distances comfortably. We prioritize customer safety and satisfaction, employing only professional drivers who are well-trained and knowledgeable about the routes. Each vehicle in our fleet is meticulously maintained to ensure a smooth ride. Whether you are visiting popular sites or lesser-known destinations, our outstation taxis are equipped to provide a premium travel experience, complete with amenities that enhance your journey."
    },
    {
        "name": "Best Taxi Service in Shirdi",
        "description": "Experience the best taxi service in Shirdi with our commitment to excellence in customer service and vehicle quality. Our fleet consists of well-maintained vehicles, ranging from economical sedans to luxurious SUVs, all driven by professional drivers dedicated to making your journey enjoyable. We offer 24/7 availability, ensuring you can book a taxi whenever you need it. Our customer support team is always ready to assist you with any inquiries or specific requirements you may have, providing a level of service that exceeds expectations."
    },
    {
        "name": "Cabs in Shirdi Airport",
        "description": "We provide cabs readily available at Shirdi Airport for your convenience, ensuring a hassle-free arrival and departure experience. Our drivers are stationed at the airport, ready to assist you with your luggage and escort you to your vehicle. With no waiting time and immediate service, you can transition smoothly from your flight to your destination. We offer a range of vehicle options to suit your preferences, whether you require a comfortable sedan or a spacious family van."
    },
    {
        "name": "Shirdi Outstation Cab Package",
        "description": "Our Shirdi outstation cab packages offer flexible options for various destinations, tailored to your travel preferences and budget. Each package includes everything you need for a complete travel experience, from vehicle hire to itinerary planning. Enjoy the convenience of pre-arranged travel, which allows you to relax and focus on your journey rather than logistics. Our packages are designed to cater to individuals, families, and groups, ensuring that everyone has an enjoyable experience."
    },
    {
        "name": "Shirdi Outstation Cab Package Price",
        "description": "Contact us for competitive pricing on our outstation cab packages from Shirdi, where we offer transparent rates with no hidden fees. We believe in providing great value for money, allowing you to explore the region without financial stress. Each package is competitively priced based on distance, vehicle type, and duration of service, ensuring you receive the best deal possible. Our dedicated team is always ready to assist you in finding a package that perfectly aligns with your travel needs."
    },
    {
        "name": "Shirdi Car Rental Rates per Day",
        "description": "Discover our daily car rental rates in Shirdi, designed to fit every budget and provide you with the freedom to explore at your leisure. We offer a variety of vehicles, ensuring that you can choose one that meets your needs, whether you’re traveling alone or with a large group. Our daily rates are straightforward and inclusive of insurance and other essential services, giving you peace of mind as you travel. With flexible rental periods, you can choose short-term or long-term rentals that suit your plans."
    },
    {
        "name": "Shirdi Local Tour Packages",
        "description": "We offer a variety of local tour packages in Shirdi, perfect for families and groups looking to explore the area comprehensively. Each package includes visits to major attractions, guided tours, and opportunities to experience local culture and cuisine. Our itineraries are thoughtfully crafted to ensure that you make the most of your visit, providing a mix of sightseeing, relaxation, and cultural immersion. Whether you’re interested in temple visits, local markets, or historical sites, our packages are designed to create unforgettable memories."
    },
    {
        "name": "Shirdi Local Tour Packages for Family",
        "description": "Our family-friendly local tour packages in Shirdi ensure a fun and memorable experience for all ages. Each package is designed with family dynamics in mind, including activities that cater to children and engaging experiences for adults. We focus on creating lasting memories by providing family-oriented tours that highlight Shirdi’s rich heritage and cultural significance. Enjoy comfortable transportation, knowledgeable guides, and a range of activities that guarantee fun for everyone."
    },
    {
        "name": "Shirdi Local Tour Operators",
        "description": "Choose from our trusted local tour operators in Shirdi for personalized and informative tours around the region. Our operators are experienced and knowledgeable about local attractions, culture, and history, providing insights that enrich your travel experience. Whether you’re looking for a comprehensive tour of Shirdi’s temples, a culinary exploration, or outdoor activities, our local operators can tailor a tour to meet your specific interests and preferences."
    },
    {
        "name": "SaitirthaTours & Travels",
        "description": "Opt for SaitirthaTours & Travels for all your transportation and travel needs in Shirdi. Our commitment to exceptional service means that we prioritize your comfort and satisfaction at every step of your journey. Our experienced team is ready to assist you with tailored travel solutions, whether you require local transportation, outstation travel, or guided tours. We strive to make every trip enjoyable and memorable, ensuring you have a delightful experience from start to finish."
    },
    {
        "name": "Shirdi Outstation Cabs Contact Information",
        "description": "For bookings or inquiries, please contact SaitirthaTours & Travels at +91 9923879009 / +91 9922479009. Our friendly and professional team is here to assist you with all your travel needs, providing the support you require to create unforgettable travel experiences. We are committed to ensuring your journey is seamless and enjoyable, and we look forward to serving you and helping you make the most of your time in Shirdi and beyond!"
    }
],
tableData: [
    ["- Car Rental in Shirdi for Outstation", "- Outstation Taxi Shirdi"],
    ["- Best Taxi Service in Shirdi", "- Cabs in Shirdi Airport"],
    ["- Shirdi Outstation Cab Package", "- Shirdi Outstation Cab Package Price"],
    ["- Shirdi Car Rental Rates per Day", "- Shirdi Local Tour Packages"],
    ["- Shirdi Local Tour Packages for Family", "- Shirdi Local Tour Operators"],
    ["- SaitirthaTours & Travels Shirdi", ""]
  ],

  whychoose: [
  {
    "WhyChooseheading": "Reliable Service",
    "WhyChoosedescription": "Our outstation cab services from Shirdi are designed to be reliable and punctual, ensuring that you are never left waiting. Whether you need a quick getaway or a scheduled trip, we always strive to arrive on time. We track real-time traffic conditions and manage logistics to ensure your ride is as smooth and timely as possible. With Saitirtha Travels, you can rely on us for a stress-free travel experience from start to finish."
  },
  {
    "WhyChooseheading": "Wide Range of Vehicles",
    "WhyChoosedescription": "We understand that each traveler has unique needs, which is why we offer a wide range of vehicles to choose from. Whether you're traveling solo or with a large group, we have options that will suit your needs perfectly. Our fleet includes well-maintained sedans, comfortable SUVs, spacious Innova Crystas, and even premium vehicles for those seeking extra comfort and luxury. Whatever the size of your group or your preference, you will find the perfect ride with us."
  },
  {
    "WhyChooseheading": "Affordable Pricing",
    "WhyChoosedescription": "We believe in offering competitive and affordable prices that cater to all budgets. Whether you are booking for a short trip or a longer journey, we maintain transparency in our pricing with no hidden fees or surprise charges. Our pricing model is designed to be flexible, providing affordable options without compromising on the quality of the service. You can travel in comfort and style at a price that won’t break the bank."
  },
  {
    "WhyChooseheading": "Experienced Drivers",
    "WhyChoosedescription": "Our drivers are the backbone of our service, and we take great care in hiring only experienced, professional, and courteous drivers. They are familiar with all routes, road conditions, and the best shortcuts to make your journey quicker and more enjoyable. Each of our drivers is thoroughly trained in safety protocols and customer service, ensuring that you receive the highest level of care during your trip. They are dedicated to your comfort, safety, and satisfaction, and will go above and beyond to make sure you have a pleasant journey."
  },
  {
    "WhyChooseheading": "Easy Booking Process",
    "WhyChoosedescription": "Booking your outstation cab with Saitirtha Travels is quick, simple, and hassle-free. Our user-friendly website allows you to book your ride in just a few clicks. Alternatively, you can always call our customer service team, who will help guide you through the booking process and answer any questions you might have. We offer multiple payment options for your convenience, including online payment, mobile wallets, and cash on delivery. Our simple process ensures you spend less time worrying about logistics and more time enjoying your trip."
  },
  {
    "WhyChooseheading": "Flexible Packages",
    "WhyChoosedescription": "We understand that every traveler has different needs, which is why we offer flexible travel packages to accommodate your itinerary. Whether you need a one-way trip, round trip, or multi-day rental, we can tailor our packages to meet your specific needs. You can also choose the type of vehicle, your departure time, and your return schedule, ensuring complete flexibility and control over your travel plans. Our customized packages are designed to give you the utmost convenience, whether you're traveling for leisure, business, or a pilgrimage."
  },
  {
    "WhyChooseheading": "24/7 Customer Support",
    "WhyChoosedescription": "Our dedicated customer support team is always available to assist you. Whether you need help with booking, have inquiries during your trip, or need to make changes to your travel schedule, we’re here to help. We understand that travel plans can change unexpectedly, so our team is ready to support you at any time, day or night. With Saitirtha Travels, you can rest assured knowing that you will always have a point of contact whenever you need assistance."
  },
  {
    "WhyChooseheading": "Comfort and Convenience",
    "WhyChoosedescription": "At Saitirtha Travels, we prioritize your comfort. Our vehicles are equipped with modern amenities such as air conditioning, comfortable seating, and spacious interiors for a smooth and comfortable ride. Whether you're traveling for a few hours or several days, we ensure that every ride is relaxing and stress-free. Additionally, we maintain all our vehicles regularly, ensuring that they are in excellent condition, so you can travel with peace of mind knowing that you're in safe and reliable hands."
  },
  {
    "WhyChooseheading": "Local Expertise",
    "WhyChoosedescription": "Our drivers are not only skilled in driving but also knowledgeable about the local area and the best routes. They can offer valuable recommendations on places of interest along the way, including attractions, eateries, and cultural sites. If you’re new to the region or looking for a little local insight, our drivers are happy to share tips and help enhance your journey. With their local expertise, your travel experience will be more enriching and enjoyable."
  },
  {
    "WhyChooseheading": "Customer Satisfaction Focus",
    "WhyChoosedescription": "Our commitment to customer satisfaction means we always go the extra mile to meet your expectations. From the moment you book your ride to the time you reach your destination, we aim to provide the best service possible. We value feedback from our customers and are always looking for ways to improve our services. At Saitirtha Travels, we believe that our success is built on satisfied customers, and we work tirelessly to ensure you have a smooth, comfortable, and memorable journey."
  }
]





        
  }


  const faqData = [
    {
        question: "What outstation destinations can I travel to from Shirdi?",
        answer: "We provide outstation cab services to various destinations across India, including Mumbai, Pune, Nasik, Aurangabad, and more."
    },
    {
        question: "Can I book a cab for a multi-day trip?",
        answer: "Yes, we offer customizable packages for multi-day trips. Please contact us for details."
    },
    {
        question: "Are there additional charges for long-distance travel?",
        answer: "Our pricing is transparent, and additional charges may apply based on distance and travel duration, which will be communicated clearly during booking."
    },
    {
        question: "What types of vehicles are available for outstation travel?",
        answer: "We offer a range of vehicles, including economy cars, SUVs, and larger vehicles for groups."
    },
    {
        question: "How do I book an outstation cab?",
        answer: "You can easily book an outstation cab online through our website or by calling our customer service team."
    },
    {
        question: "Is there a waiting charge if I need the driver to wait at my destination?",
        answer: "Yes, waiting charges may apply after a specified time, and we will inform you of these details during booking."
    },
    {
        question: "Can I make changes to my booking?",
        answer: "Yes, we allow modifications to bookings based on availability. Please contact us as soon as possible for any changes."
    },
    {
        question: "Do you provide child seats for outstation travel?",
        answer: "Yes, child seats are available upon request; please mention this during your booking."
    },
    {
        question: "What if my flight is delayed?",
        answer: "We monitor flight statuses and will adjust your pick-up time accordingly at no additional charge."
    },
    {
        question: "How can I contact you for assistance?",
        answer: "Our customer support team is available via phone or email to assist with any inquiries."
    }
];

const testimonials = [
    {
        name: "Mr. Prakash Nair",
        text: "My experience with Saitirtha Travels for outstation cabs from Shirdi was excellent! The cab was spacious and comfortable, making our journey enjoyable. The driver was knowledgeable about the routes and very accommodating. I highly recommend their service for anyone looking to explore the surrounding areas!"
    },
    {
        name: "Ms. Riya Sharma",
        text: "I had a wonderful trip using Saitirtha Travels for my outstation cab from Shirdi. The booking process was straightforward, and the vehicle was well-maintained. The driver was punctual and friendly, ensuring a smooth ride. I will definitely choose Saitirtha Travels for my future travels!"
    }
];





  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>

<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Shirdi Outstation Cabs | Affordable Car Rentals</title>
    <meta name="description" content="Book Outstation Cabs from Shirdi with Saitirtha Travels. We offer reliable taxi services for outstation trips, including customized packages and family tours. Affordable car rentals for destinations like Trimbakeshwar, Nashik, and more." />
    <meta name="keywords" content="Shirdi Outstation Cabs, Car rental in Shirdi for outstation, Outstation taxi Shirdi, Best taxi service in Shirdi, Cabs in Shirdi airport, Shirdi outstation cab package, Shirdi outstation cab package price, Shirdi car rental rates per day, Shirdi local tour packages, Shirdi local tour packages for family, Shirdi local tour operators, SaitirthaTours & Travels Shirdi" />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirtha Travels | Shirdi Outstation Cabs | Affordable Car Rentals" />
    <meta property="og:description" content="Book Outstation Cabs from Shirdi with Saitirtha Travels. We provide affordable car rentals and taxi services for outstation trips. Choose from a variety of packages and local tours for families." />
    <meta property="og:url" content="https://saitirthatravels.com/shirdi-outstation-cabs" />
    <meta property="og:image" content="https://saitirthatravels.com/images/shirdi-outstation-cabs.jpg" />
    <meta name="twitter:card" content="summary_large_image" />
    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Book Outstation Cabs from Shirdi with Saitirtha Travels. We provide affordable taxi services for outstation trips including customized packages and local tours for families.",
            "url": "https://saitirthatravels.com/shirdi-outstation-cabs",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/shirdi-outstation-cabs.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "250"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi Outstation Cabs",
                "Car rental in Shirdi for outstation",
                "Outstation taxi Shirdi",
                "Best taxi service in Shirdi",
                "Cabs in Shirdi airport",
                "Shirdi outstation cab package",
                "Shirdi outstation cab package price",
                "Shirdi car rental rates per day",
                "Shirdi local tour packages",
                "Shirdi local tour packages for family",
                "Shirdi local tour operators"
            ]
        })}
    </script>
</Helmet>

      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/Keyword 5-04.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirdioutstatiponcabs;