

import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

const Carousel = () => {
  useEffect(() => {
    const bootstrap = require('bootstrap/dist/js/bootstrap.bundle.min');
    const carouselElement = document.querySelector('#carouselExampleControls');
    if (carouselElement) {
      new bootstrap.Carousel(carouselElement, {
        ride: 'carousel',
        interval: 3000,  
      });
    }
  }, []);

  return (
    <div id="carouselExample" className="carousel slide" data-bs-ride="carousel" data-bs-interval="1000">
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img src="/images/saitirthaslides/Slide 3-01.jpg" className="d-block w-100" alt="Slide 1" />
        </div>
        <div className="carousel-item">
          <img src="/images/saitirthaslides/Slide 1-01.jpg" className="d-block w-100" alt="Slide 2" />
        </div>
        <div className="carousel-item">
          <img src="/images/saitirthaslides/Slide 1-02.jpg" className="d-block w-100" alt="Slide 3" />
        </div>
        <div className="carousel-item">
          <img src="/images/saitirthaslides/Slide 2-01.jpg" className="d-block w-100" alt="Slide 4" />
        </div>
        <div className="carousel-item">
          <img src="/images/saitirthaslides/Slider Size 1200x450px-01.jpg" className="d-block w-100" alt="Slide 5" />
        </div>
        <div className="carousel-item">
          <img src="/images/saitirthaslides/Slider Size 1200x450px-02.jpg" className="d-block w-100" alt="Slide 6" />
        </div>
        {/* <div className="carousel-item">
          <img src="https://wowslider.com/sliders/demo-18/data1/images/hongkong1081704.jpg" className="d-block w-100" alt="Slide 7" />
        </div> */}
        
        {/* Additional slides */}
      </div>
      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>

  );
};

export default Carousel;



