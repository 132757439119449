
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Shirditoashtavinayak() {



  const cardData =
  {
    keyword: '  Shirdi to Ashtavinayak Darshan Cab',
    heading: 'Saitirtha Travels: Shirdi to Ashtavinayak Darshan Cab',
    headingDescription: 'Embarking on the Ashtavinayak Darshan from Shirdi offers an enriching spiritual journey through the sacred sites dedicated to Lord Ganesha. At Saitirtha Travels, we provide a comfortable and reliable cab service that ensures your pilgrimage is smooth and fulfilling. Our Shirdi to Ashtavinayak Darshan cab service is tailored to meet your needs, making your spiritual adventure seamless.',

    top: 'Top Places to Visit in Ashtavinayak with Saitirtha Travels',

 "topPlaces": [
    {
        "title": "Siddhivinayak Temple, Siddhatek",
        "description": "The Siddhivinayak Temple in Siddhatek is one of the most revered Ashtavinayak pilgrimage sites dedicated to Lord Ganesha. This ancient temple is believed to house an idol that fulfills the wishes of its devotees, making it a sought-after destination for those seeking blessings for success, health, and happiness. The temple’s architecture is characterized by intricate carvings and beautiful design, surrounded by lush greenery that enhances its spiritual atmosphere. Devotees flock here to offer prayers, especially during festivals, creating a vibrant and lively environment filled with devotion. The temple's sacred charm and the heartfelt prayers of its visitors resonate deeply, making every visit a transformative experience."
    },
    {
        "title": "Ballaleshwar Temple, Ballalwadi",
        "description": "Located in the serene village of Ballalwadi, the Ballaleshwar Temple is dedicated to an incarnation of Ganesha known as Ballaleshwar. The temple's unique history and divine legends add to its allure, drawing pilgrims from far and wide. Its beautiful architecture, featuring intricate stonework and a serene ambiance, creates an ideal setting for worship and contemplation. Visitors are often captivated by the stories associated with the deity, believed to be a guardian of devotees. The temple celebrates various festivals with grandeur, offering an authentic glimpse into the local culture and traditions. The tranquil surroundings and spiritual energy make it a perfect spot for seekers of peace and blessings."
    },
    {
        "title": "Varadvinayak Temple, Mahad",
        "description": "The Varadvinayak Temple in Mahad is renowned for its captivating idol of Lord Ganesha, depicted in a unique seated posture. This temple is surrounded by picturesque landscapes, including lush hills and serene waters, enhancing its appeal as a pilgrimage site. The rituals and ceremonies performed here are steeped in tradition, creating a vibrant atmosphere of devotion. Many devotees visit the temple to seek Ganesha's blessings for success and prosperity. The temple's significance extends beyond spirituality, as it serves as a cultural hub during festivals, attracting large crowds of pilgrims who come together in celebration. The combination of natural beauty and spiritual richness makes Varadvinayak a must-visit destination."
    },
    {
        "title": "Chintamani Temple, Theur",
        "description": "Dedicated to Chintamani, a form of Lord Ganesha who is revered for granting peace of mind, the Chintamani Temple in Theur is a serene sanctuary for devotees. The temple boasts stunning architecture, characterized by ornate carvings and intricate designs that reflect the artistry of its time. Visitors are often struck by the temple's spiritual ambiance, which fosters a deep sense of calm and reflection. Many come here to seek solace from life's troubles and to engage in personal prayers for peace and clarity. The surrounding area adds to the temple's charm, with lush greenery and tranquil surroundings that invite meditation and introspection. The Chintamani Temple is not just a place of worship but a retreat for those seeking inner harmony."
    },
    {
        "title": "Mahaganapati Temple, Ranjangaon",
        "description": "The Mahaganapati Temple in Ranjangaon is famous for its magnificent idol of Lord Ganesha, which is believed to possess powerful divine energy. The temple’s serene atmosphere, combined with traditional rituals and practices, attracts devotees year-round. Its intricate architecture features beautifully crafted sculptures that tell stories of Ganesha's divine interventions and blessings. The temple is particularly busy during Ganesh Chaturthi, when large crowds gather to celebrate with fervor and devotion. Visitors often describe the experience as spiritually uplifting, with many leaving with a renewed sense of faith and gratitude. The Mahaganapati Temple stands as a testament to the enduring legacy of Ganesha worship in Maharashtra."
    },
    {
        "title": "Pali Ganapati Temple, Pali",
        "description": "Nestled in the picturesque village of Pali, the Pali Ganapati Temple is known for its striking idol of Lord Ganesha, beautifully crafted to represent his divine qualities. Surrounded by lush greenery, the temple offers a peaceful environment ideal for spiritual seekers and pilgrims alike. The temple’s tranquil setting is perfect for reflection and prayer, allowing visitors to immerse themselves in devotion without distractions. The vibrant local culture and community activities enhance the experience, especially during festivals when the temple is beautifully decorated. The Pali Ganapati Temple is a place where spirituality meets nature, making it a unique pilgrimage destination."
    },
    {
        "title": "Shri Vighnahar Ganapati, Ozar",
        "description": "The Shri Vighnahar Ganapati Temple in Ozar is dedicated to Vighnahar, the remover of obstacles, making it a favored destination for devotees seeking success and resolution to challenges. The temple’s unique charm lies in its rituals and the warm, welcoming atmosphere created by the local community. The architecture features traditional elements that reflect the deep-rooted cultural heritage of Maharashtra. Pilgrims are drawn to the temple to seek Ganesha's blessings, often engaging in various rituals that symbolize the removal of obstacles in their lives. The annual festivals are celebrated with great enthusiasm, attracting large crowds and creating a vibrant community spirit. Visitors leave feeling inspired and uplifted, having connected with Ganesha's divine energy."
    },
    {
        "title": "Girijatmaj Temple, Lenyadri",
        "description": "Set amidst the scenic hills of Lenyadri, the Girijatmaj Temple is unique as it is carved directly out of a mountain, offering a breathtaking backdrop for devotees. This temple, dedicated to Ganesha, combines spirituality with stunning natural beauty, making it a memorable pilgrimage destination. The journey to the temple involves climbing a series of steps, which adds to the sense of achievement and reverence as visitors reach the sacred site. The idol of Ganesha here is particularly revered for its unique form, and the peaceful atmosphere encourages deep meditation and connection with the divine. The panoramic views from the temple further enhance the spiritual experience, making it a favorite among pilgrims and nature lovers alike."
    },
    {
        "title": "Shri Siddhivinayak Ganapati, Aatpadi",
        "description": "The Shri Siddhivinayak Ganapati Temple in Aatpadi is a lesser-known gem among the Ashtavinayak temples, offering a tranquil setting for those looking to escape the crowds. This temple features a beautiful idol of Lord Ganesha, radiating a serene presence that draws visitors seeking spiritual connection. The temple's peaceful ambiance is ideal for personal prayer and reflection, allowing visitors to immerse themselves in devotion without the distractions common at busier pilgrimage sites. The surrounding area is lush and green, further contributing to the serene atmosphere. The Aatpadi temple offers a unique opportunity for visitors to connect with Ganesha in a calm and intimate environment."
    },
    {
        "title": "Vighneshwar Temple, Ozar",
        "description": "The Vighneshwar Temple in Ozar is another important site in the Ashtavinayak pilgrimage circuit. Known for its beautiful idol of Lord Ganesha and peaceful ambiance, this temple attracts many devotees seeking blessings and guidance. The rituals performed here are traditional and deeply meaningful, fostering a strong sense of community among visitors. The temple's architecture features intricate designs that showcase the artistry of the region. The serene surroundings invite visitors to engage in prayer and reflection, making it an ideal place for spiritual renewal. The Vighneshwar Temple is not only a site of worship but also a gathering place for those who share a common devotion to Ganesha, creating an atmosphere of unity and shared faith."
    }
],
    
            
        
"services": [
    {
      "name": "Shirdi to Ashtavinayak Darshan Cab",
      "description": "Embark on a spiritual journey from Shirdi to the Ashtavinayak temples, renowned for their divine significance and beautiful architecture. Our dedicated cab services are designed to make your pilgrimage seamless and memorable. Experience comfort and convenience as you visit each temple, focusing on your spiritual growth and reflection. With spacious vehicles and experienced drivers, you can relax and enjoy the serene landscapes along the way."
    },
    {
      "name": "Shirdi to Morgaon Darshan Taxi Service",
      "description": "Starting your Ashtavinayak pilgrimage with a visit to Morgaon, the first Ganesh temple in the Ashtavinayak series, is a must. Our Shirdi to Morgaon darshan taxi service ensures you reach this sacred site comfortably, allowing you to immerse yourself in the spiritual ambiance. Our knowledgeable drivers will share insights about the local history and culture, enhancing your journey and making it more enriching."
    },
    {
      "name": "Shirdi to Siddh Tek Darshan Cab Fare",
      "description": "Traveling to Siddh Tek is a key part of the Ashtavinayak tour. Our competitive Shirdi to Siddh Tek darshan cab fare provides you with an affordable option for your journey, ensuring you can focus on your spiritual experience without worrying about costs. We pride ourselves on transparent pricing, offering clear explanations of all fees involved, so you can budget effectively for your trip."
    },
    {
      "name": "Taxi Fare from Shirdi to Pali",
      "description": "For those planning to visit the Pali temple, we offer a transparent taxi fare from Shirdi to Pali. Our pricing is designed to be clear and straightforward, helping you budget for your trip effectively. With no hidden charges, you can travel with peace of mind. Our drivers are familiar with the best routes to minimize travel time and enhance your experience."
    },
    {
      "name": "Shirdi Mahad Car Rental",
      "description": "Consider our Shirdi Mahad car rental service for greater flexibility during your Ashtavinayak darshan. Choose your preferred vehicle from our well-maintained fleet and explore the temples at your own pace. This service empowers you to stop and enjoy the surroundings, making the most of your spiritual journey. Whether you wish to visit additional sites or take a leisurely break, we accommodate your needs."
    },
    {
      "name": "Shirdi to Ashtavinayak One Way Taxi",
      "description": "If you prefer a one-way trip, our Shirdi to Ashtavinayak one way taxi service is the perfect solution. Enjoy a hassle-free booking process and dependable transportation for your pilgrimage. Our commitment to punctuality ensures that you arrive at each destination on time, allowing you to fully participate in your spiritual activities without feeling rushed."
    },
    {
      "name": "Shirdi to Ashtavinayak Cab Booking",
      "description": "Booking your cab for the Ashtavinayak journey is simple with our user-friendly online platform. Reserve your Shirdi to Ashtavinayak cab in advance to secure your ride and avoid last-minute stress. Our customer service team is available to assist you throughout the booking process, ensuring that your experience is smooth and straightforward from start to finish."
    },
    {
      "name": "Shirdi to Ashtavinayak Travel by Taxi",
      "description": "Our Shirdi to Ashtavinayak travel by taxi service is dedicated to providing you with a smooth and comfortable ride. Our experienced drivers are familiar with the best routes, ensuring a timely arrival at each temple. Let us take care of the driving so you can focus on your spiritual experience and reflection. Our vehicles are equipped with modern amenities to enhance your comfort during the journey."
    },
    {
      "name": "Shirdi Ashtavinayak Cab Rates",
      "description": "We offer competitive Shirdi Ashtavinayak cab rates to make your journey affordable. Our pricing structure is designed to cater to different budgets without compromising on the quality of service. We understand that traveling for spiritual purposes should be accessible, and our goal is to provide excellent value while ensuring your comfort and safety."
    },
    {
      "name": "Shirdi to Ashtavinayak Taxi Fare Per Km",
      "description": "Our taxi fare from Shirdi to Ashtavinayak is calculated per kilometer, ensuring you pay only for the distance traveled. This pricing model provides transparency and helps you manage your travel expenses effectively. You can rest assured that there are no surprises when it comes to pricing, allowing you to focus on your spiritual journey."
    },
    {
      "name": "Affordable Shirdi Ashtavinayak Cab",
      "description": "Finding an affordable Shirdi Ashtavinayak cab service is easy with us. We aim to provide excellent service at competitive rates, making your pilgrimage accessible and enjoyable. Our commitment to affordability does not compromise the quality of your travel experience, and we continuously strive to enhance our offerings for our valued customers."
    },
    {
      "name": "Shirdi to Ashtavinayak Innova Crysta Cab",
      "description": "For a more luxurious experience, choose our Shirdi to Ashtavinayak Innova Crysta cab service. This spacious and comfortable vehicle is perfect for families or groups, providing ample room for everyone to travel together. Enjoy modern amenities and a smooth ride as you embark on your sacred journey, ensuring that every moment is enjoyable and comfortable."
    },
    {
      "name": "Shirdi to Ashtavinayak Darshan Tempo Traveller",
      "description": "If you're traveling with a larger group, our Shirdi to Ashtavinayak darshan tempo traveller service is ideal. Enjoy the convenience of spacious seating and enough luggage space for your journey. This option ensures that everyone travels together comfortably, fostering a sense of unity during your pilgrimage and allowing you to share in the experience as a group."
    },
    {
      "name": "Shirdi to Ashtavinayak Darshan Innova Crysta",
      "description": "Our Shirdi to Ashtavinayak darshan Innova Crysta option offers a premium travel experience. With modern amenities and comfortable seating, you can enjoy a relaxing ride to each of the Ashtavinayak temples. Let our attentive drivers take care of the road while you focus on your spiritual journey, providing you with the peace of mind to connect deeply with your faith."
    },
    {
      "name": "Contact Number for Shirdi to Ashtavinayak Darshan Cab",
      "description": "For prompt and efficient Shirdi to Ashtavinayak darshan cab services, contact Saitirtha Travels at +91 9923879009 / 9922479009. We ensure a smooth and enjoyable ride for all our customers, providing excellent customer support to address any inquiries or assistance you may need. Your spiritual journey is important to us, and we are here to make it as comfortable as possible."
    }
  ],

  "tableData": [
    ["- Shirdi to Morgaon Darshan Taxi Service", "- Shirdi to Siddh Tek Darshan Cab Fare"],
    ["- SaitirthaTours & Travels Shirdi", "- Taxi Fare from Shirdi to Pali"],
    ["- Shirdi Mahad Car Rental", "- Shirdi to Ashtavinayak One Way Taxi"],
    ["- Shirdi to Ashtavinayak Cab Booking", "- Shirdi to Ashtavinayak Travel by Taxi"],
    ["- Shirdi Ashtavinayak Cab Rates", "- Shirdi to Ashtavinayak Taxi Fare per Km"],
    ["- Affordable Shirdi Ashtavinayak Cab", "- Shirdi to Ashtavinayak Innova Crysta Cab"],
    ["- Shirdi to Ashtavinayak Darshan Tempo Traveller", "- Shirdi to Ashtavinayak Darshan Innova Crysta"]
],
"whychoose": [
    {
        "WhyChooseheading": "Seamless Booking Experience",
        "WhyChoosedescription": "Booking your cab from Shirdi to Ashtavinayak Darshan is made simple and quick through our intuitive online platform. You can effortlessly reserve your vehicle at any time that suits you, and our user-friendly interface ensures a hassle-free experience. Our online system allows you to view available vehicles, select your preferred ride, and complete the booking in just a few clicks. Once you book, you’ll receive instant confirmation, allowing you to focus on your spiritual journey with the assurance that your transportation is secured."
    },
    {
        "WhyChooseheading": "Variety of Vehicle Options",
        "WhyChoosedescription": "We offer a comprehensive fleet of vehicles to meet your travel needs, whether you’re traveling alone or in a group. Choose from compact cars for solo journeys, comfortable sedans for small groups, or spacious vans and tempo travellers for larger families or gatherings. Each vehicle is regularly maintained to ensure safety and comfort, equipped with necessary amenities to make your journey enjoyable. This variety allows you to select a vehicle that perfectly matches your group size and travel style, ensuring an optimal experience."
    },
    {
        "WhyChooseheading": "Experienced and Friendly Drivers",
        "WhyChoosedescription": "Our drivers are not only highly trained professionals but also friendly and accommodating individuals who prioritize your comfort and safety. They possess in-depth knowledge of the Ashtavinayak route, including key stops and local attractions, ensuring you reach your destination efficiently. Additionally, our drivers are adept at navigating through varying traffic conditions, which means you can sit back and relax during your journey. Their commitment to excellent service guarantees a pleasant travel experience, with a focus on your needs and preferences."
    },
    {
        "WhyChooseheading": "Competitive Pricing",
        "WhyChoosedescription": "We pride ourselves on offering transparent and competitive pricing for our Shirdi to Ashtavinayak cab services. Our pricing structure has no hidden costs, allowing you to budget effectively without unexpected surprises. We believe in delivering great value for your investment, and our flexible pricing options cater to different budgets. Furthermore, we offer various packages tailored to your travel needs, which can include group discounts and special rates for extended journeys, ensuring affordability without compromising quality."
    },
    {
        "WhyChooseheading": "Flexible Travel Plans",
        "WhyChoosedescription": "Understanding that every pilgrimage is unique, we provide flexible travel options that cater to your specific itinerary. Whether you need a one-way trip to a temple or a round trip for the full Ashtavinayak Darshan experience, we accommodate your schedule with ease. Our service allows you to adjust travel times and routes based on your preferences. You can make last-minute changes to your booking without stress, ensuring your travel arrangements fit seamlessly into your plans and allowing you to focus on your spiritual experience."
    },
    {
        "WhyChooseheading": "24/7 Customer Support",
        "WhyChoosedescription": "Our commitment to customer service is unwavering, with a dedicated support team available 24/7 to assist you with any questions or concerns. Whether you need assistance with your booking, require additional information about our services, or encounter any issues during your journey, our friendly staff is always ready to help. We value open communication and are committed to addressing your needs promptly, ensuring your experience is smooth and enjoyable from start to finish."
    },
    {
        "WhyChooseheading": "Safety and Hygiene",
        "WhyChoosedescription": "Your safety is our utmost priority. We implement strict hygiene protocols, including thorough sanitization of our vehicles before and after each trip. Our drivers adhere to health guidelines, ensuring a clean and safe environment for your travels. In response to ongoing health concerns, we also provide safety kits in our vehicles, including hand sanitizers and masks, allowing you to travel with confidence and peace of mind. This commitment to hygiene ensures that your journey is not only comfortable but also safe, allowing you to focus on your pilgrimage."
    },
    {
        "WhyChooseheading": "Real-Time Tracking",
        "WhyChoosedescription": "With our real-time tracking feature, you can easily monitor your vehicle’s location throughout your journey. This added layer of transparency not only keeps you informed about your travel progress but also enhances security, allowing you to share your ride details with family or friends. Knowing that you can track your cab provides peace of mind, especially when traveling to religious sites. Our tracking system is user-friendly and accessible through your booking confirmation, ensuring you’re always connected and informed during your travels."
    }
]

        
  }


  const faqData = [
    {
        question: "What types of vehicles do you offer for the trip?",
        answer: "We offer a variety of vehicles, including sedans, SUVs, and larger vehicles for group travel, ensuring we meet your specific needs."
    },
    {
        question: "How do I book a cab for Ashtavinayak Darshan?",
        answer: "You can easily book your cab online through our website or by contacting our customer service for assistance."
    },
    {
        question: "Are fuel and toll charges included in the fare?",
        answer: "Fuel and toll charges are generally not included in the base fare and will be calculated separately based on the journey."
    },
    {
        question: "Can I request a one-way trip?",
        answer: "Yes, we provide options for both one-way and round-trip bookings to fit your travel plans."
    },
    {
        question: "What payment methods are accepted?",
        answer: "We accept various payment methods, including credit/debit cards, UPI payments, and cash for your convenience."
    },
    {
        question: "Are your drivers experienced?",
        answer: "Yes, all our drivers are experienced, licensed, and knowledgeable about the Ashtavinayak routes, ensuring a safe trip."
    },
    {
        question: "What should I do if I need to cancel or change my booking?",
        answer: "You can cancel or modify your booking by reaching out to our customer support team as soon as possible for assistance."
    },
    {
        question: "What safety measures are in place during the ride?",
        answer: "We ensure regular sanitization of our vehicles and that our drivers follow health and safety protocols to keep you safe."
    },
    {
        question: "Is there a customer support number I can call for inquiries?",
        answer: "Yes, our customer support team is available 24/7 to assist with any questions or concerns you may have."
    },
    {
        question: "Can I choose a specific vehicle for my trip?",
        answer: "Yes, you can request a specific type of vehicle when making your booking, and we will do our best to accommodate your request."
    }
];

const testimonials = [
    {
        name: "Mr. Anil Deshmukh",
        text: "Our trip from Shirdi to Ashtavinayak Darshan was truly memorable thanks to Saitirtha Travels. The driver was punctual and very courteous."
    },
    {
        name: "Mrs. Sangeeta More",
        text: "We had a great experience traveling from Shirdi to Ashtavinayak with Saitirtha Travels. The vehicle was clean, and the driver was knowledgeable about the best routes."
    }
];





  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>

<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Shirdi to Ashtavinayak Darshan Cab | SaitirthaTours & Travels | Affordable Ashtavinayak Taxi Services</title>
    <meta name="description" content="Get affordable and reliable Shirdi to Ashtavinayak Darshan cab services. Offering one-way taxi, darshan tours, and more. Book now with Saitirtha Travels!" />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirtha Travels | Shirdi to Ashtavinayak Darshan Cab | SaitirthaTours & Travels | Affordable Ashtavinayak Taxi Services" />
    <meta property="og:description" content="Get affordable and reliable Shirdi to Ashtavinayak Darshan cab services. Offering one-way taxi, darshan tours, and more. Book now with Saitirtha Travels!" />
    <meta property="og:url" content="https://saitirthatravels.com/shirdi-to-ashtavinayak-darshan-cab" />
    <meta property="og:image" content="https://saitirthatravels.com/images/shirdi-to-ashtavinayak-darshan-cab.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Get affordable and reliable Shirdi to Ashtavinayak Darshan cab services. Offering one-way taxi, darshan tours, and more. Book now with Saitirtha Travels!",
            "url": "https://saitirthatravels.com/shirdi-to-ashtavinayak-darshan-cab",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/shirdi-to-ashtavinayak-darshan-cab.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi to Ashtavinayak Darshan taxi service",
                "Shirdi to Morgaon Darshan taxi service",
                "Shirdi to Siddh Tek Darshan cab fare",
                "Taxi fare from Shirdi to Pali",
                "Shirdi Mahad car rental",
                "Shirdi to Ashtavinayak one way taxi",
                "Shirdi to Ashtavinayak cab booking",
                "Shirdi to Ashtavinayak travel by taxi",
                "Shirdi Ashtavinayak cab rates",
                "Shirdi to Ashtavinayak taxi fare per km",
                "Affordable Shirdi Ashtavinayak cab",
                "Shirdi to Ashtavinayak Innova Crysta Cab",
                "Shirdi to Ashtavinayak darshan tempo traveller",
                "Shirdi to Ashtavinayak darshan Innova Crysta",
                "SaitirthaTours & Travels Shirdi"
            ]
        })}
    </script>
</Helmet>

      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/45.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirditoashtavinayak;