import React from 'react';
import { Helmet } from 'react-helmet';
import '../css/privacy-policy.css';

const PrivacyPolicy = () => {
  return (
    <div>

<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Ertiga On Rent in Shirdi | Kia Carens on rent in Shirdi | Affordable and Reliable Service</title> 
    <meta name="description" content="Book Ertiga on Rent in Shirdi with Sai Tirth Cabs. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Reliable and comfortable mini bus rental services." />
    <meta name="keywords" content="Force Urbania on Rent in Shirdi, Urbania on Rent in Manmad, Force Urbania on Rent in Shani Shingnapur, Shirdi to Bhimashankar Urbania on Rent, Shirdi to Nashik Force Urbania on Rent, Mini Bus on Rent in Shirdi, Luxury Force Urbania on Rent in Shirdi, 13 Seater Urbania on Rent, 17 Seater Urbania on Rent, Shirdi Airport Urbania on Rent, Sai Tirth Cabs Shirdi, Mini AC Bus on Rent, Mini Luxury Bus on Rent in Shirdi, Aura on Rent in Shirdi, Ertiga on Rent in Shirdi, Scorpio on Rent in Shirdi, Kia Carens on Rent in Shirdi" />
    <meta property="og:title" content="Contact: +91 9923879009 | Force Urbania On Rent in Shirdi | Luxury Urbania Bus Rental | Affordable and Reliable Service" />
    <meta property="og:description" content="Book Force Urbania on Rent in Shirdi with Sai Tirth Cabs. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Reliable and comfortable mini bus rental services." />
    <meta property="og:url" content="https://saitirthatravels.com/force-urbania-on-rent-in-shirdi" />
    <meta property="og:image" content="https://saitirthatravels.com/images/urbania-on-rent.jpg" />
    <meta name="twitter:card" content="summary_large_image" />
    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Sai Tirth Cabs Shirdi",
            "description": "Book Force Urbania on Rent in Shirdi. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Affordable and reliable mini bus rental services.",
            "url": "https://saitirthatravels.com/force-urbania-on-rent-in-shirdi",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/urbania-on-rent.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "250"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Force Urbania on Rent in Shirdi",
                "Urbania on Rent in Manmad",
                "Force Urbania on Rent in Shani Shingnapur",
                "Shirdi to Bhimashankar Urbania on Rent",
                "Shirdi to Nashik Force Urbania on Rent",
                "Mini Bus on Rent in Shirdi",
                "Luxury Force Urbania on Rent in Shirdi",
                "13 Seater Urbania on Rent",
                "17 Seater Urbania on Rent",
                "Shirdi Airport Urbania on Rent",
                "Sai Tirth Cabs Shirdi",
                "Aura on Rent in Shirdi",
                "Ertiga on Rent in Shirdi",
                "Scorpio on Rent in Shirdi",
                "Kia Carens on Rent in Shirdi"
            ]
        })}
    </script>
</Helmet>

      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h1 className="title">Privacy Policy</h1>
          </div>
        </div>
      </section>

     
      <section className="privacy-policy">
        <div className="container px-0">
          <div className="row g-0">
            <div className="col-12 col-md-7">
              <h3 className="title">Privacy Policy</h3>

              <p>
                At Saitirtha Travels, we are dedicated to protecting your privacy and ensuring the security of your personal information. This Privacy Policy describes how we collect, use, disclose, and safeguard your information when you use our services.
              </p>

              <div className="section">
                <p className="highlight darkcolor">Information We Collect:</p>
                <p className="highlight darkcolor">1. Personal Information:</p>
                <p>
                  When you book a service with Saitirtha Travels, we may collect personal details such as your name, contact information, payment details, and travel preferences.
                </p>

                <p className="highlight darkcolor">2. Usage Information:</p>
                <p>
                  We gather information regarding how you interact with our website and mobile app, including your IP address, device details, and pages visited.
                </p>

                <p className="highlight darkcolor">3. Location Information:</p>
                <p>
                  With your consent, we may collect location data to offer location-based services and improve our travel solutions.
                </p>
              </div>

              <div className="section">
                <p className="highlight darkcolor">How We Use Your Information:</p>
                <p className="highlight darkcolor">1. To Provide Services:</p>
                <p>
                  We utilize your information to process bookings, manage reservations, and deliver our transportation services efficiently.
                </p>

                <p className="highlight darkcolor">2. To Enhance Our Services:</p>
                <p>
                  We analyze user behavior and feedback to improve your experience and develop new features.
                </p>

                <p className="highlight darkcolor">3. For Communication:</p>
                <p>
                  We may use your contact information to send booking confirmations, updates, and promotional offers about our services.
                </p>

                <p className="highlight darkcolor">4. For Marketing Purposes:</p>
                <p>
                  With your permission, we may send you marketing communications regarding our services and exclusive offers.
                </p>
              </div>

              <div className="section">
                <p className="highlight darkcolor">Information Sharing and Disclosure:</p>
                <p className="highlight darkcolor">1. Third-Party Service Providers:</p>
                <p>
                  We may share your information with trusted third-party service providers who assist us in delivering our services.
                </p>

                <p className="highlight darkcolor">2. Legal Compliance:</p>
                <p>
                  We may disclose your information as required by law or to protect our rights or the safety of others.
                </p>
              </div>

              <div className="section">
                <p className="highlight darkcolor">Data Security:</p>
                <p>
                  We implement industry-standard security measures to protect your information from unauthorized access. However, no transmission method is completely secure.
                </p>

                <p className="highlight darkcolor">Your Rights:</p>
                <p>
                  You have the right to access, update, or delete your personal information. You can also opt out of marketing communications at any time.
                </p>

                <p className="highlight darkcolor">Changes to this Privacy Policy:</p>
                <p>
                  We reserve the right to modify this Privacy Policy at any time. Changes will take effect immediately upon posting on our website.
                </p>
              </div>
            </div>

            <div className='col-12 col-md-5'>
              <div className="image-container">
                <img src="/images/privacy.webp" className="bg-cover" alt="Privacy Policy" />
              </div>
            </div>
          </div>

          <div className="contact-info">
            <h2>Contact Us</h2>
            <p>If you have any questions regarding our privacy policy, please reach out:</p>
            <ul>
              <li><b>Phone:</b> <a href="tel:+919923879009">+91 9923879009</a></li>
              <li><b>Email:</b> <a href="mailto:booking@saitirthatravels.com">booking@saitirthatravels.com</a></li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
