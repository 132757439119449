
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Mumbaitoshirdicab() {



  const cardData =
  {
    keyword: ' Mumbai to Shirdi Cab',
    heading: 'Saitirtha Travels: Mumbai to Shirdi Cab',
    headingDescription: 'Traveling from Mumbai to Shirdi is a popular journey for devotees and tourists alike. Saitirtha Travels provides dependable and comfortable cab services to make your trip to the spiritual town of Shirdi hassle-free. Our professional drivers ensure a pleasant journey, allowing you to focus on your pilgrimage or exploration.',

    top: 'Top Places to Visit in Shirdi with Sitirth Travels',

    topPlaces: [
        {
            "title": "Sai Baba Samadhi Mandir",
            "description": "The heart of Shirdi, this temple is dedicated to Sai Baba, a revered saint known for his teachings of love, compassion, and unity. The beautifully constructed mandir houses Baba's marble statue, which is adorned with fresh flowers and offerings from devotees. This sacred site attracts millions of visitors each year, especially during festivals and significant events like the annual Sai Baba Palkhi. The divine atmosphere is profoundly felt during the Aarti (prayer ceremony), where devotees sing hymns and offer their prayers, creating an unforgettable spiritual experience."
        },
        {
            "title": "Dwarkamai Masjid",
            "description": "This historic mosque served as the home of Sai Baba for most of his life. Here, visitors can see the sacred dhuni (eternal flame) that Baba kept burning, symbolizing the light of knowledge and the fire of devotion. The walls are adorned with portraits and photos of Baba, each telling a story of his life and miracles. The tranquil ambiance of Dwarkamai, with its simple yet profound setting, invites devotees to meditate and reflect, allowing for a deeper connection with Sai Baba's teachings."
        },
        {
            "title": "Chavadi",
            "description": "Chavadi is the place where Sai Baba used to rest every alternate night, and it holds immense significance for his followers. The Chavadi features a beautiful wooden structure adorned with intricate carvings and is decorated with Baba’s photographs, flowers, and offerings from devotees. The atmosphere is serene, and visitors often feel a deep sense of peace while inside. Special ceremonies are held here on Thursdays, attracting large crowds who come to pay their respects and seek blessings."
        },
        {
            "title": "Sai Heritage Village",
            "description": "A cultural village that offers insights into the life and teachings of Sai Baba, Sai Heritage Village provides an educational experience for visitors. The village features replicas of significant places from Baba's life, including a traditional marketplace and scenes depicting his teachings and philosophy. Visitors can engage with exhibits that highlight the values of compassion and service that Baba embodied. The village serves as a reminder of the simplicity and humility that marked Baba's life, making it a worthwhile visit for anyone wanting to learn more about his legacy."
        },
        {
            "title": "Lendi Baug",
            "description": "A peaceful garden where Sai Baba often meditated, Lendi Baug is home to a beautiful neem tree under which he would sit and reflect. The garden features a well, a shrine dedicated to Sai Baba, and lush greenery that creates a tranquil setting for relaxation and contemplation. Visitors often take a moment to sit in the serene surroundings, enjoying the fragrant flowers and the sound of birds. The atmosphere of Lendi Baug is imbued with the essence of Sai Baba's spirituality, making it an ideal spot for personal reflection."
        },
        {
            "title": "Shri Saibaba Sansthan Trust",
            "description": "The Shri Saibaba Sansthan Trust manages the Sai Baba Samadhi Mandir and oversees various services for pilgrims. The trust provides essential facilities such as accommodation, food services, and health care to ensure a comfortable experience for all visitors. Additionally, the trust engages in community service and social welfare programs, reflecting Sai Baba's teachings of service to humanity. A visit to the trust’s office provides insight into the initiatives they undertake to honor Sai Baba's legacy and serve the needs of the community."
        },
        {
            "title": "Nashik",
            "description": "Just a short drive from Shirdi, Nashik is a city known for its vineyards and historic temples. It is often referred to as the 'Wine Capital of India,' where visitors can explore numerous wineries, partake in wine tasting sessions, and enjoy the scenic beauty of the vineyards. Nashik also holds significant religious importance, being a major pilgrimage site with temples like Kalaram Temple and Panchavati. Additionally, visitors can experience the Kumbh Mela sites along the Godavari River, where millions gather for spiritual cleansing."
        },
        {
            "title": "Ahmednagar Fort",
            "description": "Located nearby, Ahmednagar Fort is a historic site dating back to the 17th century. It offers visitors a glimpse into Maharashtra's rich history and the architectural prowess of the era. The fort features massive walls and gates, and within its premises, visitors can explore various structures, including temples and water cisterns. The panoramic views from the fort provide an excellent vantage point of the surrounding landscape and are perfect for photography and exploration."
        },
        {
            "title": "Ellora Caves",
            "description": "A UNESCO World Heritage Site, the Ellora Caves are renowned for their stunning rock-cut architecture and ancient sculptures that date back to the 5th to 10th centuries. The caves showcase a remarkable blend of Buddhist, Hindu, and Jain art and architecture, with the grand Kailasa temple being a highlight. Located a few hours from Shirdi, the Ellora Caves provide a fascinating insight into India's artistic heritage and are a must-visit for history and culture enthusiasts."
        },
        {
            "title": "Ajanta Caves",
            "description": "Another UNESCO World Heritage Site, the Ajanta Caves are famous for their exquisite paintings and sculptures that depict the life of the Buddha and various Jataka tales. Dating back to the 2nd century BCE, these caves offer a glimpse into ancient Indian art and spirituality. Visitors can explore the intricately carved interiors and stunning frescoes, which are considered masterpieces of Buddhist art. The caves are located a few hours from Shirdi and are an essential visit for those interested in history and culture."
        }
    ],  
    
            
        
"services": [
    {
        "name": "Mumbai to Shirdi Cab Service",
        "description": "Travel conveniently from Mumbai to Shirdi with our reliable cab service, designed to provide you with the utmost comfort and convenience. Known for its spiritual significance, Shirdi attracts millions of pilgrims each year. Our experienced drivers are well-versed in the best routes and ensure a smooth journey, allowing you to unwind and reflect on your spiritual journey as you travel to one of India’s most revered pilgrimage sites."
    },
    {
        "name": "Mumbai to Shirdi Taxi",
        "description": "Our dedicated taxi service operates between Mumbai and Shirdi, ensuring that you have a seamless travel experience. Whether you are traveling alone or with family, we offer a range of vehicles to suit your needs. Our well-maintained taxis are equipped with comfortable seating and air conditioning, so you can enjoy a pleasant ride while taking in the scenic beauty along the way. Book your ride with us for peace of mind, knowing you’ll arrive at your destination safely and on time."
    },
    {
        "name": "Mumbai to Shirdi Taxi Fare",
        "description": "The fare for a taxi from Mumbai to Shirdi varies based on the type of vehicle and the time of booking. Our pricing is transparent and straightforward, with no hidden fees or surprises. We believe in providing fair and competitive rates, so you can budget your travel expenses accordingly. Contact us for a detailed fare estimate that fits your travel preferences and vehicle choice."
    },
    {
        "name": "Mumbai to Shirdi Car Rental",
        "description": "Looking for a car rental service for your journey? We offer a diverse range of vehicles for hire, catering to your specific needs and preferences. Enjoy the freedom to travel at your own pace while experiencing the comfort and convenience of our well-maintained cars. Our rental options are designed to accommodate both solo travelers and families, ensuring a memorable experience as you make your way from Mumbai to Shirdi."
    },
    {
        "name": "Mumbai to Shirdi Taxi Service",
        "description": "Our taxi service is available 24/7, making it easy for you to plan your trip at your convenience. We understand that travel plans can change, which is why we offer flexible booking options. Our experienced drivers prioritize safety and reliability, ensuring that you have a comfortable ride. Enjoy the journey with our attentive service, which focuses on providing you with an exceptional travel experience."
    },
    {
        "name": "Shirdi to Mumbai Taxi Fare",
        "description": "Traveling back to Mumbai? Our competitive rates for the Shirdi to Mumbai journey are designed to ensure you get the best value for your money. We offer transparent pricing, so you can plan your return trip without any unexpected costs. Enjoy the peace of mind that comes with knowing you’ll receive excellent service at a fair price."
    },
    {
        "name": "Mumbai to Shirdi Cabs Round Trip",
        "description": "We offer round trip cab services from Mumbai to Shirdi, allowing you to enjoy your pilgrimage without any hassle. Our round trip packages provide you with flexibility and convenience, making it easy to customize your itinerary. With our professional drivers, you can focus on your spiritual journey while we take care of your transportation needs. Enjoy timely pickups and drop-offs that align with your schedule."
    },
    {
        "name": "Mumbai to Shirdi Taxi Innova Fare",
        "description": "Travel in comfort with our Innova taxi service, specially designed for families and larger groups. The fare for the Innova from Mumbai to Shirdi is competitive and structured to provide you with the best experience possible. With spacious seating, ample luggage space, and a smooth ride, our Innova taxis ensure that your journey is both comfortable and enjoyable."
    },
    {
        "name": "Mumbai to Shirdi Cab Service",
        "description": "Experience the best of our cab services with punctual pickups and drop-offs. We pride ourselves on our reliability and customer service, ensuring that your travel experience from Mumbai to Shirdi is pleasant and stress-free. Our commitment to quality means that every detail is taken care of, from the cleanliness of our vehicles to the professionalism of our drivers. Book your Mumbai to Shirdi cab service today and embark on a wonderful journey."
    },
    {
        "name": "Mumbai to Shirdi Taxi Cost",
        "description": "Our transparent pricing policy allows you to know the taxi cost upfront, so you can plan your trip without any financial surprises. We offer a detailed breakdown of all costs involved, allowing you to make informed decisions. Our goal is to provide you with a pleasant journey from Mumbai to Shirdi while maintaining high service standards and affordability."
    },
    {
        "name": "Mumbai Airport to Shirdi Taxi",
        "description": "We provide taxi services from Mumbai Airport to Shirdi, ensuring you can start your journey as soon as you land. Our drivers will be waiting for you, ready to assist with your luggage and provide a smooth transition from your flight to your pilgrimage. With our airport taxi service, you can relax knowing that your transportation is taken care of, allowing you to focus on your spiritual experience."
    },
    {
        "name": "Mumbai Airport to Shirdi Taxi Fare",
        "description": "The fare for traveling from Mumbai Airport to Shirdi varies based on the vehicle type and time of travel. We encourage you to contact us for detailed pricing information tailored to your travel preferences. Our competitive rates ensure that you receive great value without compromising on the quality of service."
    },
    {
        "name": "Mumbai to Shirdi Taxi Price",
        "description": "Get the best taxi prices for your journey from Mumbai to Shirdi with our competitive rates and excellent service. We understand the importance of providing value for your money, which is why we strive to offer affordable options that cater to your needs, making your pilgrimage both economical and enjoyable."
    },
    {
        "name": "Mumbai to Shirdi Taxi One Way",
        "description": "If you're looking for a one-way trip from Mumbai to Shirdi, we have flexible options to suit your travel needs. Our one-way taxi services provide convenience and comfort, allowing you to focus on your spiritual experience without the hassle of a round trip. Enjoy a straightforward booking process and exceptional service with every journey."
    },
    {
        "name": "Navi Mumbai to Shirdi Taxi",
        "description": "We also operate taxi services from Navi Mumbai to Shirdi. Enjoy a comfortable and convenient journey with us, as our experienced drivers ensure timely pickups and a smooth ride to your destination. Whether you are traveling alone or in a group, our fleet is ready to accommodate your needs."
    },
    {
        "name": "Thane to Shirdi Cab",
        "description": "For those traveling from Thane to Shirdi, we offer cab services that are punctual and reliable. Our drivers are familiar with the best routes to ensure you arrive at your destination comfortably and on time. Enjoy the peace of mind that comes with knowing you are in capable hands during your journey to the holy site."
    },
    {
        "name": "Kalyan to Shirdi Cab Charges",
        "description": "Our cab charges from Kalyan to Shirdi are competitive, making it easy for you to visit the holy city without breaking the bank. We believe in providing affordable options while maintaining high-quality service standards. Contact us for a fare estimate tailored to your specific travel needs."
    },
    {
        "name": "SaitirthaTours & Travels",
        "description": "Choose SaitirthaTours & Travels for all your transportation needs from Mumbai to Shirdi. Our commitment to excellent service and customer satisfaction ensures that every journey is a memorable one. We pride ourselves on our professionalism, reliability, and dedication to making your travel experience as enjoyable as possible."
    },
    {
        "name": "Mumbai to Shirdi Cab Contact Information",
        "description": "For bookings or inquiries, please contact SaitirthaTours & Travels at +91 9923879009 / +91 9922479009. Our friendly staff is always ready to assist you in planning your perfect pilgrimage. Let us make your journey from Mumbai to Shirdi a memorable one, as we focus on your comfort and satisfaction every step of the way!"
    }
],


tableData: [
    ["- Mumbai to Shirdi Taxi", "- Mumbai to Shirdi Taxi Fare"],
    ["- Mumbai to Shirdi Car Rental", "- Mumbai to Shirdi Taxi Service"],
    ["- Shirdi to Mumbai Taxi Fare", "- Mumbai to Shirdi Cabs Round Trip"],
    ["- Mumbai to Shirdi Taxi Innova Fare", "- Mumbai to Shirdi Cab Service"],
    ["- Mumbai to Shirdi Taxi Cost", "- Mumbai Airport to Shirdi Taxi"],
    ["- Mumbai Airport to Shirdi Taxi Fare", "- Mumbai to Shirdi Taxi Price"],
    ["- Mumbai to Shirdi Taxi One Way", "- Navi Mumbai to Shirdi Taxi"],
    ["- Thane to Shirdi Cab", "- Kalyan to Shirdi Cab Charges"],
    ["- SaitirthaTours & Travels Shirdi", ""]
  ],

  whychoose: [
  {
    "WhyChooseheading": "Reliable Transportation",
    "WhyChoosedescription": "Our Mumbai to Shirdi taxi service ensures you a dependable and timely journey. We take pride in offering transportation that you can trust, whether you're traveling for business or leisure. Our well-maintained fleet of vehicles is always ready to serve you, providing a smooth experience that allows you to relax during the journey. You can rely on us to get you to your destination safely and on time, every time."
  },
  {
    "WhyChooseheading": "Comfortable Rides",
    "WhyChoosedescription": "We prioritize comfort to ensure that your trip from Mumbai to Shirdi is relaxing and enjoyable. Our vehicles are equipped with premium seating, ample legroom, and air-conditioning to provide the most comfortable ride. Whether you're traveling alone or with family, our vehicles are designed to enhance your travel experience with a smooth and peaceful journey. Relax and enjoy the ride, knowing that comfort is a top priority."
  },
  {
    "WhyChooseheading": "Experienced Drivers",
    "WhyChoosedescription": "Our drivers are the heart of our service. With years of experience, they are well-versed in the best routes to Shirdi and the traffic conditions on the way. They are not only skilled drivers but also courteous and professional, ensuring a safe and pleasant ride. We carefully select our drivers to make sure they meet the highest standards of driving and customer service. Their knowledge of the area allows them to navigate efficiently, ensuring that your journey is both safe and timely."
  },
  {
    "WhyChooseheading": "Affordable Pricing",
    "WhyChoosedescription": "We offer some of the most competitive rates for Mumbai to Shirdi taxi services, ensuring that you receive the best value for your money. Our transparent pricing means you will never encounter hidden charges. We aim to provide top-quality service without overcharging our customers, making it affordable for families, solo travelers, and groups. Whether you choose a one-way trip or a round trip, our fares are designed to fit all budgets, ensuring a cost-effective solution for your travel."
  },
  {
    "WhyChooseheading": "24/7 Availability",
    "WhyChoosedescription": "Your travel needs can arise at any time, and we're always ready to accommodate you. Our services are available 24/7, meaning you can book a ride at any time of day or night. Whether you're arriving at an odd hour or have a sudden change of plans, our customer support team is available to assist you in booking your ride. With us, you’re never limited by time—book your ride anytime, and we’ll make sure to get you to your destination."
  },
  {
    "WhyChooseheading": "Easy Booking Process",
    "WhyChoosedescription": "We believe in making your booking experience as easy as possible. Our user-friendly online platform allows you to book your Mumbai to Shirdi taxi with just a few clicks. Alternatively, if you prefer, you can contact our customer service team directly for a personalized experience. Booking a taxi has never been easier. We offer multiple payment options, and you’ll receive immediate confirmation of your booking. No long waiting times, no hassles, just a simple and seamless process."
  },
  {
    "WhyChooseheading": "Flexible Itineraries",
    "WhyChoosedescription": "We understand that every traveler has unique needs. That’s why we offer flexible itineraries tailored to your schedule. Whether you’re planning a one-way trip, a round-trip, or require specific pick-up or drop-off points, we’re happy to accommodate your requirements. Our services are customizable, ensuring that your journey is smooth, convenient, and stress-free. We offer multi-day packages as well for those who wish to explore the destination at their own pace."
  },
  {
    "WhyChooseheading": "Safety Measures",
    "WhyChoosedescription": "Safety is our top priority. All our vehicles undergo regular maintenance checks to ensure they meet safety standards and are in perfect working order. In addition, we follow stringent sanitization protocols to guarantee that our cars are clean and safe for passengers. Our experienced drivers are trained in safety protocols, ensuring that your ride is secure. We also adhere to all government health and safety regulations, including using masks, sanitizers, and providing hand sanitizing stations in the vehicle. Travel with confidence, knowing your safety is always prioritized."
  },
  {
    "WhyChooseheading": "Dedicated Customer Support",
    "WhyChoosedescription": "Our commitment to excellent customer service extends beyond just the booking process. Our dedicated customer support team is always available to assist you with any questions or concerns during your trip. Whether you need assistance with booking, need to adjust your itinerary, or require help during your travel, we are here to support you. We believe in providing a personalized experience, ensuring that your needs are met every step of the way."
  },
  {
    "WhyChooseheading": "Local Insights",
    "WhyChoosedescription": "Our drivers are more than just skilled professionals; they are also local experts. As you travel from Mumbai to Shirdi, they can share valuable insights about the destinations and attractions along the way. Whether it’s about historical landmarks, cultural sights, or recommended places to stop for refreshments, our drivers enrich your journey with knowledge. Their friendly nature and local insights provide an added touch of comfort, making your trip enjoyable and educational."
  }
]





        
  }


  const faqData = [
    {
        question: "What is the distance from Mumbai to Shirdi?",
        answer: "The distance is approximately 240 kilometers, which typically takes about 5-6 hours by cab."
    },
    {
        question: "How can I book a cab from Mumbai to Shirdi?",
        answer: "Booking is easy through our website or by contacting our customer service team directly."
    },
    {
        question: "What types of vehicles do you offer?",
        answer: "We provide various options, including sedans, SUVs, and vans, to accommodate your specific needs."
    },
    {
        question: "Are toll and parking fees included in the fare?",
        answer: "Any applicable tolls and parking fees will be added to the total fare and discussed at the time of booking."
    },
    {
        question: "Can I arrange for a return trip from Shirdi to Mumbai?",
        answer: "Yes, we offer round-trip services and can customize your travel itinerary as needed."
    },
    {
        question: "What are the fare rates for Mumbai to Shirdi?",
        answer: "Fares vary depending on the vehicle type and travel duration. Please contact us for an accurate quote."
    },
    {
        question: "Is Saitirtha Travels safe for passengers?",
        answer: "Yes, we prioritize safety with thoroughly maintained vehicles and experienced drivers."
    },
    {
        question: "Can I change my booking after confirmation?",
        answer: "Yes, modifications can be made; please notify us as soon as possible to facilitate the changes."
    },
    {
        question: "What is your cancellation policy?",
        answer: "We have a specific cancellation policy in place. Please reach out for further details."
    },
    {
        question: "How do I contact customer support?",
        answer: "You can contact our customer support team via phone or email for assistance with your queries."
    }
];

const testimonials = [
    {
        name: "Mr. Ajay Khanna",
        text: "My trip from Mumbai to Shirdi with Saitirtha Travels was exceptional! The cab was spacious and comfortable, making the long journey enjoyable. The driver was friendly and knowledgeable about the area, ensuring a smooth ride. I highly recommend their service for anyone traveling to Shirdi!"
    },
    {
        name: "Ms. Kavita Singh",
        text: "Using Saitirtha Travels for my cab ride from Mumbai to Shirdi was a wonderful experience. The booking process was straightforward, and the car was in excellent condition. The driver was punctual and courteous, making the trip stress-free. I will definitely choose Saitirtha Travels for my future travels!"
    }
];




  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Mumbai to Shirdi Cab | Affordable Taxi Service | Shirdi Tour Packages</title>
    <meta name="description" content="Book Mumbai to Shirdi Cab with Saitirtha Travels. We offer affordable taxi services from Mumbai to Shirdi, including round trips, one-way trips, and more. Book your Shirdi tour package now!" />
    <meta name="keywords" content="Mumbai to Shirdi Taxi, Mumbai to Shirdi taxi fare, Mumbai to Shirdi car rental, Mumbai to Shirdi taxi service, Mumbai to Shirdi round trip, Mumbai to Shirdi Innova taxi, Mumbai to Shirdi cab service, Mumbai to Shirdi taxi cost, Mumbai airport to Shirdi taxi, Navi Mumbai to Shirdi cab, Thane to Shirdi cab, Kalyan to Shirdi cab, SaitirthaTours & Travels Shirdi" />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirtha Travels | Mumbai to Shirdi Cab | Affordable Taxi Service | Shirdi Tour Packages" />
    <meta property="og:description" content="Book Mumbai to Shirdi Cab with Saitirtha Travels. Affordable and reliable taxi services, including round trips, one-way, and group bookings from Mumbai to Shirdi. Plan your Shirdi tour with us." />
    <meta property="og:url" content="https://saitirthatravels.com/mumbai-to-shirdi-cab" />
    <meta property="og:image" content="https://saitirthatravels.com/images/mumbai-to-shirdi-cab.jpg" />
    <meta name="twitter:card" content="summary_large_image" />
    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Book Mumbai to Shirdi Cab with Saitirtha Travels. Affordable and reliable taxi services from Mumbai to Shirdi, including round trips, one-way, and more.",
            "url": "https://saitirthatravels.com/mumbai-to-shirdi-cab",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/mumbai-to-shirdi-cab.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "250"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Mumbai to Shirdi Taxi",
                "Mumbai to Shirdi taxi fare",
                "Mumbai to Shirdi car rental",
                "Mumbai to Shirdi taxi service",
                "Mumbai to Shirdi round trip",
                "Mumbai to Shirdi Innova taxi",
                "Mumbai to Shirdi cab service",
                "Mumbai to Shirdi taxi cost",
                "Mumbai airport to Shirdi taxi",
                "Navi Mumbai to Shirdi cab",
                "Thane to Shirdi cab",
                "Kalyan to Shirdi cab"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/84.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Mumbaitoshirdicab;