
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Kalyantoshirdi() {



  const cardData =
  {
    keyword: '  Kalyan to Shirdi Car Rental',
    heading: 'Saitirtha Travels: Kalyan to Shirdi Car Rental',
    headingDescription: 'Traveling from Kalyan to Shirdi is a journey filled with spiritual significance and scenic beauty. At Saitirtha Travels, we offer a reliable and comfortable car rental service that ensures your trip to Shirdi is enjoyable and hassle-free. Whether you are a pilgrim visiting the famous Sai Baba Temple or a tourist exploring the area, our Kalyan to Shirdi car rental service is designed to cater to your needs.',

    top: 'Top Places to Visit in Shirdi with Saitirtha Travels',

  "topPlaces": [
    {
        "title": "Shri Saibaba Samadhi Mandir",
        "description": "The Shri Saibaba Samadhi Mandir is the central attraction in Shirdi, drawing millions of devotees from across the globe. This sacred temple is not only a place of worship but also the final resting place of Sai Baba, whose teachings continue to inspire countless followers. The temple complex features intricately carved marble and gold embellishments, creating a serene atmosphere perfect for meditation and reflection. Visitors can witness daily aarti (worship ceremonies) and partake in the spiritual energy that permeates the space, making it a truly transformative experience."
    },
    {
        "title": "Dwarkamai Masjid",
        "description": "Dwarkamai Masjid is a historic mosque that holds immense significance for devotees as it was where Sai Baba spent a significant part of his life. Inside, the sacred Dhuni (eternal fire) burns continuously, symbolizing the light of knowledge and wisdom that Baba imparted. Devotees leave offerings at the Dhuni, creating a rich tapestry of faith and devotion. The mosque’s walls are adorned with poignant quotes from Sai Baba, inviting visitors to absorb his teachings. The ambiance is tranquil, making it an ideal spot for prayer and reflection on Baba's profound impact on spirituality."
    },
    {
        "title": "Chavadi",
        "description": "Chavadi, a simple yet significant structure, served as the resting place for Sai Baba on alternate nights. The interior is often decorated with colorful flowers and offerings, reflecting the love and reverence devotees have for him. The Chavadi provides insight into Baba’s humble lifestyle and his emphasis on simplicity and community. Many visitors find solace here, taking time to meditate and connect with the spiritual essence that Sai Baba embodied. It’s a place of quiet contemplation where one can reflect on their own spiritual journey."
    },
    {
        "title": "Shirdi Saibaba Museum",
        "description": "The Shirdi Saibaba Museum is a treasure trove of history, showcasing artifacts and personal belongings of Sai Baba. Visitors can explore exhibits that include photographs, clothing, and items that belonged to Baba, each telling a unique story of his life and teachings. The museum offers educational resources that provide deeper insights into Baba’s philosophies and his role as a spiritual guide. This informative stop enriches the pilgrimage experience, allowing visitors to connect with Sai Baba’s life in a tangible way."
    },
    {
        "title": "Lendi Baug",
        "description": "Lendi Baug is a picturesque garden located near the Samadhi Mandir, known for its tranquility and lush greenery. This serene spot is where Sai Baba often meditated, and it continues to be a favored location for visitors seeking peace and solace. The garden features a small well and various trees that Baba cared for, creating an inviting atmosphere for relaxation and reflection. Many visitors enjoy sitting quietly in the garden, absorbing the spiritual energy and natural beauty that surrounds them."
    },
    {
        "title": "Shri Saibaba Sansthan Temple Trust",
        "description": "The Shri Saibaba Sansthan Temple Trust plays a pivotal role in managing the affairs of the Sai Baba temple and ensuring the upkeep of this sacred site. The trust organizes various services for devotees, including community outreach programs, educational initiatives, and spiritual gatherings. Visitors can learn about the history of the trust and its ongoing efforts to promote Sai Baba’s teachings. The trust’s commitment to maintaining the sanctity of the pilgrimage site ensures that all who visit can experience the essence of Sai Baba’s love and compassion."
    },
    {
        "title": "Khandoba Mandir",
        "description": "Khandoba Mandir is dedicated to Lord Khandoba, a deity revered in the region and worshipped by Sai Baba. Located a short distance from the main temple, this beautiful temple features intricate architecture and vibrant murals depicting stories of Khandoba. The temple attracts many visitors, especially during festivals, when the atmosphere is filled with devotion and celebration. It offers a unique opportunity for visitors to engage with local traditions and the cultural richness of the area."
    },
    {
        "title": "Maalik Dham",
        "description": "Maalik Dham is a peaceful temple dedicated to Abdul Baba, who was Sai Baba’s revered guru. The temple serves as a serene sanctuary for meditation and spiritual growth, allowing visitors to connect deeply with the teachings and essence of Shirdi's spiritual lineage. Surrounded by natural beauty, this tranquil space encourages reflection and personal growth, making it a perfect retreat for those seeking a deeper understanding of spirituality and connection to their inner selves."
    },
    {
        "title": "Sainath Nagar",
        "description": "Sainath Nagar is a vibrant residential area that has developed around the main temple complex. It offers a range of accommodations, from budget lodgings to comfortable hotels, along with various dining options that cater to all tastes. This lively area is a hub for visitors looking to immerse themselves in the local culture, enjoy regional cuisine, and engage with the bustling community that thrives around the temple. It’s an ideal spot for pilgrims to rest and rejuvenate before continuing their spiritual journey."
    },
    {
        "title": "Shri Sai Baba's Chavadi",
        "description": "Shri Sai Baba's Chavadi holds a special place in the hearts of devotees as it exemplifies Baba’s simple lifestyle and teachings. Often adorned with fresh flowers and colorful offerings, the Chavadi serves as a poignant reminder of the humility and compassion that characterized Baba’s life. Visitors are drawn here to reflect on their personal journeys and to find inspiration in the lessons of love, tolerance, and service that Baba imparted. The peaceful atmosphere invites contemplation, making it a cherished stop for those seeking spiritual enrichment."
    }
],
    
            
        
"services": [
    {
      "name": "Kalyan to Shirdi Car Rental",
      "description": "When planning your journey from Kalyan to Shirdi, our reliable car rental service is essential for a smooth and enjoyable experience. We offer a variety of options that guarantee comfort, convenience, and affordability, ensuring that you can travel at your own pace. Our goal is to make every trip memorable, whether you're traveling for business, leisure, or pilgrimage."
    },
    {
      "name": "Car Rental from Kalyan to Shirdi",
      "description": "Our car rental service from Kalyan to Shirdi ensures a seamless travel experience. Our professional drivers are trained to navigate the best routes while adhering to all traffic regulations, minimizing delays. With a fleet of well-maintained vehicles, ranging from compact cars to spacious SUVs, we provide a smooth ride tailored to your specific needs, ensuring comfort and safety for all passengers."
    },
    {
      "name": "Kalyan Shirdi Taxi Service",
      "description": "Choose our Kalyan to Shirdi taxi service for an enjoyable and stress-free journey. Our experienced drivers not only excel at navigating the roads but also possess extensive knowledge about local attractions and points of interest. This ensures that you can make the most of your trip, whether you need a quick ride to your destination or a full-day rental for sightseeing and exploration."
    },
    {
      "name": "Taxi Fare from Kalyan to Shirdi",
      "description": "We pride ourselves on offering competitive taxi fares from Kalyan to Shirdi to ensure that our services remain affordable for everyone. Our transparent pricing structure means that you’ll know the total cost upfront, with no hidden charges. All our fares include taxes and fees, allowing you to budget effectively for your trip and enjoy peace of mind during your travels."
    },
    {
      "name": "Kalyan to Shirdi One Way Taxi",
      "description": "Booking a one-way taxi from Kalyan to Shirdi is quick and convenient with our user-friendly online booking system. You can reserve your cab in advance, ensuring that a vehicle is ready and waiting for you when you need it. Plus, our 24/7 customer support team is available to assist you with any inquiries or last-minute changes, providing a hassle-free experience from start to finish."
    },
    {
      "name": "Kalyan Shirdi Cab Rates",
      "description": "Our Kalyan Shirdi cab rates are designed to be among the most competitive in the region. We believe in providing value without compromising on service quality. Each rate comes with a clear breakdown of costs, enabling you to make informed decisions based on your travel budget. This transparency ensures there are no surprises along the way, making your journey worry-free."
    },
    {
      "name": "Kalyan Railway Station to Shirdi Cab",
      "description": "We also provide reliable cab services from Kalyan Railway Station to Shirdi, ensuring that your transport needs are met as soon as you arrive. Our drivers will be waiting for you at the station, ready to assist with your luggage and ensure a smooth transition to your next destination. This service is ideal for travelers looking for convenience and comfort right from the moment they step off the train."
    },
    {
      "name": "Best Cab Service from Kalyan to Shirdi",
      "description": "As the best cab service from Kalyan to Shirdi, we take pride in delivering excellent customer service every step of the way. Our team is dedicated to ensuring your comfort and satisfaction throughout your journey. Each vehicle in our fleet undergoes regular maintenance checks to guarantee safety and reliability, so you can travel with confidence."
    },
    {
      "name": "Kalyan to Shirdi Tour Package",
      "description": "Looking for a complete travel experience? Our Kalyan to Shirdi tour package includes a variety of options tailored to your needs. This can include sightseeing excursions, meal arrangements at local restaurants, and flexible itineraries that allow you to explore at your own pace. We aim to provide a comprehensive travel solution that enhances your visit to Shirdi."
    },
    {
      "name": "Kalyan to Shirdi Car Rental Contact Number",
      "description": "For prompt and efficient car rental services from Kalyan to Shirdi, please contact Saitirtha Travels at +91 9923879009 / 9922479009. Our dedicated team is ready to assist you with your transportation needs, ensuring a smooth and enjoyable ride for all our customers. Whether you’re booking a one-way trip or a round trip, we’re here to help every step of the way."
    }
  ],

  "tableData": [
    ["- Kalyan to Shirdi Car Rental", "- Car Rental from Kalyan to Shirdi"],
    ["- Kalyan Shirdi Taxi Service", "- Kalyan to Shirdi Cab Fare"],
    ["- Taxi Fare from Kalyan to Shirdi", "- Kalyan to Shirdi One Way Taxi"],
    ["- Kalyan to Shirdi Travel by Taxi", "- Kalyan Shirdi Cab Rates"],
    ["- Affordable Kalyan Shirdi Cab", "- Best Cab Service from Kalyan to Shirdi"],
    ["- Kalyan Railway Station to Shirdi Cab", "- Kalyan Railway Station to Shirdi Cab Booking"],
    ["- Kalyan Junction to Shirdi Cab", "- Kalyan to Shani Shingnapur Cab"],
    ["- Kalyan to Shirdi Tour Package", "- Kalyan to Shirdi Tour Package"]
],
"whychoose": [
    {
        "WhyChooseheading": "Convenient Booking Process",
        "WhyChoosedescription": "Booking your car rental from Kalyan to Shirdi is designed to be quick and hassle-free. Our user-friendly online platform allows you to reserve your vehicle in just a few clicks, ensuring you can make arrangements at your convenience, whether at home or on the go. Alternatively, you can contact our customer service for personalized assistance, making it easy to get answers to any questions you might have. We strive to provide a seamless experience from the start, helping you focus on enjoying your trip."
    },
    {
        "WhyChooseheading": "Variety of Vehicles",
        "WhyChoosedescription": "We offer a comprehensive range of vehicles to meet diverse travel needs, including compact cars for solo travelers, spacious SUVs for family outings, and luxury sedans for a premium experience. Our fleet is regularly maintained and thoroughly inspected for safety, ensuring a reliable and comfortable ride for all passengers. Each vehicle is equipped with modern amenities, such as air conditioning, comfortable seating, and entertainment options, making your journey not only safe but also enjoyable."
    },
    {
        "WhyChooseheading": "Experienced Drivers",
        "WhyChoosedescription": "Our professional drivers are not only well-trained but also possess extensive knowledge of the best routes from Kalyan to Shirdi. They are adept at navigating through traffic and finding the quickest paths, ensuring a smooth and pleasant journey. Our drivers prioritize your safety and comfort throughout the trip, making your travel experience more relaxed. Additionally, they can provide insights and recommendations about the local area, enhancing your overall experience."
    },
    {
        "WhyChooseheading": "Affordable Rates",
        "WhyChoosedescription": "We pride ourselves on providing competitive pricing for our Kalyan to Shirdi car rental services. Our pricing model is transparent, with no hidden fees, allowing you to enjoy great value for your travel. By offering various rental options and packages, we ensure that you can choose a service that fits your budget while enjoying quality transportation. We also provide discounts for long-term rentals and repeat customers, making our services even more economical."
    },
    {
        "WhyChooseheading": "Flexible Rental Options",
        "WhyChoosedescription": "Understanding that travel plans can vary, we offer flexible rental options to accommodate your schedule. Whether you require a one-way trip for a quick visit or a round trip for a longer stay, we allow you to customize the duration of your rental based on your specific travel needs. Our flexibility extends to last-minute changes, as we are dedicated to providing the transportation solution that works best for you."
    },
    {
        "WhyChooseheading": "24/7 Customer Support",
        "WhyChoosedescription": "Our dedicated customer support team is available around the clock to assist you with any queries or changes to your booking. Whether you need help with your reservation, have questions about our services, or require assistance during your journey, our friendly support staff is just a call away, ensuring you have the help you need whenever you need it. We aim to resolve any issues promptly, making your experience as stress-free as possible."
    },
    {
        "WhyChooseheading": "Safety and Hygiene",
        "WhyChoosedescription": "Your health and safety are our top priorities. We adhere to strict safety and hygiene protocols, which include regular sanitization of our vehicles before and after each trip. Our drivers follow comprehensive safety guidelines to create a secure travel environment. Additionally, we provide safety kits in each vehicle, including hand sanitizers and masks, giving you peace of mind and allowing you to travel comfortably and confidently."
    },
    {
        "WhyChooseheading": "Real-Time Tracking",
        "WhyChoosedescription": "Our car rental service includes real-time tracking, enabling you to monitor your vehicle's location throughout the journey. This feature adds an extra layer of transparency and security, ensuring you can keep track of your ride's progress. With real-time updates, you can stay informed about your estimated arrival time, making your travel experience more efficient and stress-free. This feature is particularly useful for coordinating pick-ups and drop-offs with multiple passengers."
    }
]


        
  }


  const faqData = [
    {
        question: "What types of vehicles are available for rent?",
        answer: "We offer a variety of vehicles, including sedans, SUVs, and luxury cars, to cater to different travel preferences and group sizes."
    },
    {
        question: "How can I book a car rental from Kalyan to Shirdi?",
        answer: "You can book a car rental through our website or by contacting our customer service team directly. We recommend booking in advance for the best availability."
    },
    {
        question: "Is there a minimum rental period?",
        answer: "There is no strict minimum rental period; we offer flexibility to accommodate your travel needs, whether it's a few hours or several days."
    },
    {
        question: "Are fuel and toll charges included in the rental price?",
        answer: "Fuel and toll charges are generally not included in the base rental price. These will be calculated separately based on your journey."
    },
    {
        question: "Can I modify or cancel my booking?",
        answer: "Yes, you can modify or cancel your booking. Please contact our customer support team as soon as possible to assist with your request."
    },
    {
        question: "Are the drivers experienced?",
        answer: "Yes, all our drivers are experienced, licensed, and familiar with the routes from Kalyan to Shirdi, ensuring a safe and efficient journey."
    },
    {
        question: "Do you provide one-way rentals?",
        answer: "Yes, we offer both one-way and round-trip rentals. You can choose the option that best fits your travel plans."
    },
    {
        question: "What safety measures do you take?",
        answer: "We prioritize safety by regularly sanitizing our vehicles and ensuring our drivers follow health and safety protocols to provide a secure travel experience."
    },
    {
        question: "What payment methods do you accept?",
        answer: "We accept multiple payment methods, including credit/debit cards, UPI transfers, and cash, for your convenience."
    },
    {
        question: "Is there a customer support line for inquiries?",
        answer: "Yes, our customer support line is available 24/7. You can reach us for any questions or assistance regarding your booking."
    }
];

const testimonials = [
    {
        name: "Mr. Sanjay Joshi",
        text: "Saitirtha Travels made our trip from Kalyan to Shirdi very comfortable. The driver was punctual and friendly, and the car was in excellent condition."
    },
    {
        name: "Mrs. Aditi Mehta",
        text: "I booked a car for a family trip to Shirdi, and it was a fantastic experience. The vehicle was spacious, and our driver was knowledgeable about the area."
    }
];



  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>

<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Kalyan to Shirdi Car Rental | SaitirthaTours & Travels | Affordable Kalyan to Shirdi Taxi Services</title>
    <meta name="description" content="Get affordable Kalyan to Shirdi car rental services. Offering one-way taxi, Shirdi tour packages, and more. Book your ride now with Saitirtha Travels!" />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirtha Travels | Kalyan to Shirdi Car Rental | SaitirthaTours & Travels | Affordable Kalyan to Shirdi Taxi Services" />
    <meta property="og:description" content="Get affordable Kalyan to Shirdi car rental services. Offering one-way taxi, Shirdi tour packages, and more. Book your ride now with Saitirtha Travels!" />
    <meta property="og:url" content="https://saitirthatravels.com/kalyan-to-shirdi-car-rental" />
    <meta property="og:image" content="https://saitirthatravels.com/images/kalyan-to-shirdi-car-rental.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Get affordable Kalyan to Shirdi car rental services. Offering one-way taxi, Shirdi tour packages, and more. Book your ride now with Saitirtha Travels!",
            "url": "https://saitirthatravels.com/kalyan-to-shirdi-car-rental",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/kalyan-to-shirdi-car-rental.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "150"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Kalyan to Shirdi car rental",
                "Car rental from Kalyan to Shirdi",
                "Kalyan Shirdi taxi service",
                "Kalyan to Shirdi cab fare",
                "Taxi fare from Kalyan to Shirdi",
                "Kalyan to Shirdi one way taxi",
                "Kalyan to Shirdi travel by taxi",
                "Kalyan Shirdi cab rates",
                "Affordable Kalyan Shirdi cab",
                "Best cab service from Kalyan to Shirdi",
                "Kalyan Railway Station to Shirdi Cab",
                "Kalyan junction to Shirdi cab",
                "Kalyan to Shani Shingnapur Cab",
                "Kalyan to Shirdi Tour Package",
                "SaitirthaTours & Travels Shirdi"
            ]
        })}
    </script>
</Helmet>

      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/43.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Kalyantoshirdi;