
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Rentalcarweddingshirdi() {



  const cardData =
  {
    keyword: 'Rental Car for Wedding in Shirdi',
    heading: 'Saitirtha Travels: Rental Car for Wedding in Shirdi',
    headingDescription: 'Planning a wedding can be overwhelming, but with the right transportation, you can ensure that every aspect of your big day goes smoothly. Our rental car services in Shirdi offer a range of vehicles tailored to meet your wedding transportation needs, ensuring a stylish and comfortable experience for you and your guests.',

    top: 'Top Places to Visit in Shirdi with Saitirtha Travels',

 "topPlaces": [
    {
        "title": "Shri Sai Baba Samadhi Mandir",
        "description": "The heart of Shirdi, this sacred temple is the resting place of Sai Baba. Couples often visit to seek blessings for a harmonious marriage, believing that the saint’s divine energy brings peace and prosperity. The temple’s spiritual aura, stunning marble architecture, and intricate carvings evoke a sense of calm and reverence, creating a serene environment for introspection and prayer. Devotees gather here for daily aartis, adding a rhythm of devotion to the air. A visit to the Samadhi Mandir is a profound experience, marking a peaceful start to any journey."
    },
    {
        "title": "Dwarkamai",
        "description": "Adjacent to the Samadhi Mandir, Dwarkamai is a mosque where Sai Baba spent much of his life. This humble place of worship holds the eternal Dhuni, a holy fire lit by Sai Baba himself, symbolizing purity, protection, and blessings. Pilgrims come to feel Baba’s presence in the air, taking in the warmth and divinity of the Dhuni, which Sai Baba once used for helping the needy. The walls hold an oil painting of Baba, along with other relics, making Dwarkamai a meaningful spot for reflection, gratitude, and remembrance of Baba’s compassionate life."
    },
    {
        "title": "Chavadi",
        "description": "Once Sai Baba’s residence, Chavadi now stands as a historical and spiritual landmark in Shirdi. It holds a unique charm with its simple architecture, carrying memories of Baba’s daily life and devotion. Here, couples and guests can experience the tranquil ambiance where Sai Baba would spend nights in meditation and selfless service. The evening aarti held at Chavadi draws numerous devotees, filling the air with devotion and peace, allowing visitors to connect deeply with the saint’s life. The site holds an important place in Shirdi’s history and spiritual landscape."
    },
    {
        "title": "Lendi Baug",
        "description": "A peaceful garden where Sai Baba would meditate and care for plants, Lendi Baug is filled with lush greenery and blooming flowers. Couples seeking a moment of tranquility can find solace in this serene environment, surrounded by beautiful landscaping and the sounds of nature. Sai Baba himself planted trees here, and it’s said that his spirit still blesses this garden. The serene water fountains and shaded benches offer visitors a space to relax, meditate, and recharge amidst the natural beauty, making it a rejuvenating stop in Shirdi."
    },
    {
        "title": "Khandoba Temple",
        "description": "Known as the first place where Sai Baba was welcomed to Shirdi, Khandoba Temple holds immense significance for followers. Dedicated to Lord Khandoba, a local deity revered by the community, the temple is known for its ancient architecture with intricate carvings and a timeless charm. Visitors often climb to this hilltop temple for its panoramic views and spiritual energy, finding it an ideal place for seeking blessings and reflecting on the saint’s journey. The temple's history and peaceful surroundings make it a beautiful spot for spiritual contemplation."
    },
    {
        "title": "Wet N Joy Water Park",
        "description": "Perfect for a break from sightseeing, Wet N Joy Water Park offers a day of fun and relaxation for families and friends visiting Shirdi. With thrilling water rides, wave pools, and designated relaxation areas, this park allows the wedding party to unwind and make lasting memories. Guests of all ages can enjoy various attractions, and couples can relax in the park’s scenic areas. A visit here provides a joyful experience and offers an alternative to the spiritual journey, letting guests indulge in a bit of adventure and recreation."
    },
    {
        "title": "Sai Heritage Village",
        "description": "Sai Heritage Village takes visitors back in time, providing a glimpse into the daily life and legacy of Sai Baba. Life-sized statues and displays depict scenes from Shirdi’s past, illustrating local traditions and the saint’s humble life. Couples and guests can enjoy a stroll through this living history museum, learning about the culture and spirit that defined Shirdi. The village’s cultural exhibits offer an educational experience, blending entertainment with historical significance, and providing visitors with a unique perspective on the life and times of Sai Baba."
    },
    {
        "title": "Upasani Maharaj Ashram",
        "description": "This tranquil ashram offers visitors a peaceful retreat with meditation sessions, spiritual teachings, and a chance to reflect on life’s purpose. Located slightly away from the main Shirdi area, the ashram provides a perfect space for those seeking inner peace. It’s especially popular among couples looking for a spiritual and rejuvenating experience, offering calm surroundings to unwind before or after wedding celebrations. The serene atmosphere and spiritual guidance available here make it a true sanctuary for visitors on their spiritual journey."
    },
    {
        "title": "Gurusthan",
        "description": "Marked by a large neem tree, Gurusthan is the spot where Sai Baba was first seen by his devotees. This place holds significant spiritual value, as the neem tree is said to carry Baba’s blessings, offering comfort and peace to those who sit beneath its shade. Couples and families visiting Shirdi often come to Gurusthan for quiet reflection, and to feel connected to the origins of Baba’s journey. The peaceful environment here offers a space for visitors to reflect, meditate, and feel inspired by Baba’s life and teachings."
    },
    {
        "title": "Prasadalaya",
        "description": "The Prasadalaya is known for serving free, wholesome meals to thousands of devotees each day. It’s a place of community and compassion, where visitors can experience the kindness and hospitality that Sai Baba promoted. Couples and wedding guests often share a meal here, enjoying the sense of unity and peace in this communal dining hall. The simplicity and humility of the Prasadalaya meal serve as a reminder of Baba’s message of equality and kindness. It’s an essential experience for those visiting Shirdi, fostering a spirit of sharing and gratitude."
    }
],
    
            
        
"services": [
    {
        "name": "Wedding Car Rental Shirdi",
        "description": "Elevate your wedding experience with our specialized wedding car rental services in Shirdi. We understand that your big day deserves nothing less than perfection, which is why we offer a carefully curated fleet of luxury vehicles that combine comfort and style. From classic sedans to opulent SUVs, each vehicle is designed to make a lasting impression as you arrive at your venue, turning heads and creating memorable moments."
    },
    {
        "name": "Rent Car for Wedding in Shirdi",
        "description": "Make your wedding day stress-free by renting a car tailored to your specific needs. Our professional chauffeurs are well-trained to provide exceptional service, ensuring you can relax and enjoy the ride. With our meticulous attention to detail, we take care of all logistics, allowing you to focus on the joy of your celebration, from pre-wedding ceremonies to the grand reception."
    },
    {
        "name": "Shirdi Wedding Car Hire",
        "description": "Our Shirdi wedding car hire services are designed for flexibility and convenience, offering a diverse selection of vehicles suitable for any size wedding party. Whether you need transport for the bride and groom, the bridal party, or all guests, we have options that cater to your requirements. Each vehicle is equipped with modern amenities to ensure a comfortable journey, allowing everyone to arrive refreshed and ready to celebrate."
    },
    {
        "name": "Best Wedding Car Rental Shirdi",
        "description": "When searching for the best wedding car rental in Shirdi, our commitment to excellence shines through. We pride ourselves on our exceptional customer service, ensuring that every detail of your transportation is handled with utmost professionalism. With well-maintained vehicles and friendly drivers, we guarantee a seamless experience that reflects the significance of your special day."
    },
    {
        "name": "Shirdi Bridal Car Rental",
        "description": "Create unforgettable memories with our bridal car rental options, specially designed to provide elegance and sophistication. Imagine arriving at your wedding venue in a beautifully decorated luxury car that complements your bridal attire. Our vehicles feature plush interiors, and we can customize decorations to match your wedding theme, ensuring that you make a stunning entrance on your big day."
    },
    {
        "name": "Luxury Car Rental for Wedding Shirdi",
        "description": "Indulge in the luxury you deserve with our premium car rental services tailored specifically for weddings. Our range of high-end vehicles offers a unique blend of style and comfort, making your journey a truly exceptional experience. Enjoy spacious interiors, advanced features, and top-notch service as you travel in a car that reflects your elegance and sophistication."
    },
    {
        "name": "Wedding Transportation Shirdi",
        "description": "Our wedding transportation services in Shirdi prioritize punctuality and reliability, ensuring that you and your guests arrive on time to all events. We understand the importance of smooth logistics on your wedding day, and our experienced team is dedicated to providing seamless transportation solutions. Count on us to handle the details while you focus on creating beautiful memories."
    },
    {
        "name": "Shirdi Marriage Car Rental",
        "description": "Ensure a flawless transportation experience for all wedding-related events with our marriage car rental services. From intimate family gatherings to elaborate celebrations, our fleet is ready to cater to all your needs. We provide a range of vehicles to accommodate everyone comfortably, making it easy for you to coordinate transportation for every important moment."
    },
    {
        "name": "Affordable Wedding Car Hire Shirdi",
        "description": "We believe that every couple deserves a beautiful wedding without breaking the bank. Our affordable wedding car hire options in Shirdi allow you to enjoy elegant transportation at competitive prices. We provide quality service and well-maintained vehicles, ensuring that you have a stylish ride that fits within your budget, making your dream wedding more attainable."
    },
    {
        "name": "Wedding Car Decoration Shirdi",
        "description": "Enhance the beauty of your wedding car with our professional decoration services. We offer a range of customizable options to suit your style, from floral arrangements to themed decor. Our team works closely with you to create a stunning look that aligns with your wedding theme, ensuring that your car stands out as a beautiful part of your special day."
    },
    {
        "name": "Cab Hire for Marriage in Shirdi",
        "description": "For a more casual yet stylish option, consider our cab hire services for your wedding events. Our cabs provide an economical way to transport guests while maintaining a level of elegance. With flexible booking options and reliable service, we make it easy for everyone to enjoy your celebration without worrying about transportation logistics."
    },
    {
        "name": "Car Hire for Marriage Function in Shirdi",
        "description": "From the engagement ceremony to the grand wedding reception, our car hire services are designed to accommodate all your marriage function transportation needs. We provide a diverse fleet of vehicles, ensuring that every guest has a comfortable and enjoyable journey to and from your events, enhancing the overall experience of your celebration."
    },
    {
        "name": "Rental Car for Wedding in Shirdi Contact Information",
        "description": "For personalized assistance or to book a rental car for your wedding in Shirdi, contact SaitirthaTours & Travels at +91 9923879009 / 9922479009. Our dedicated team is committed to making your special day memorable with our reliable, elegant transportation services, ensuring that every detail is perfect for your celebration."
    }
],

"tableData": [
    ["- Wedding Car Rental Shirdi", "- Rent Car for Wedding in Shirdi"],
    ["- Shirdi Wedding Car hire", "- Best wedding car rental Shirdi"],
    ["- Shirdi bridal car Rental", "- Luxury car Rental for Wedding Shirdi"],
    ["- Wedding transportation Shirdi", "- Shirdi marriage car rental"],
    ["- Affordable wedding car hire Shirdi", "- Wedding car decoration Shirdi"],
    ["- Car for marriage in Shirdi", "- Cab Hire for Marriage in Shirdi"],
    ["- Wedding car with decoration in Shirdi", "- Car hire for marriage function in Shirdi"],
    ["- SaitirthaTours & Travels Shirdi"]
  ],
"whychoose": [
    {
        "WhyChooseheading": "Elegant Fleet:",
        "WhyChoosedescription": "Our fleet includes a variety of elegant and luxurious vehicles tailored to add a special touch to your wedding day. From sleek limousines and classic cars to luxury SUVs and modern sedans, each vehicle is maintained to impeccable standards, polished to perfection, and ready to complement the grandeur of your celebration. Not only do our vehicles offer comfort and luxury, but they also make for a stunning backdrop in wedding photos, creating lasting memories of a truly stylish arrival."
    },
    {
        "WhyChooseheading": "Professional Drivers:",
        "WhyChoosedescription": "Our drivers are selected for their professionalism, experience, and commitment to excellence. Each driver is trained to be punctual, courteous, and attentive to your needs, ensuring your journey is as smooth and enjoyable as possible. Our drivers are not only skilled in safe driving practices but are also equipped to handle any special requests, whether it’s adjusting the music to your preferences or offering assistance to the bridal party. They bring a touch of elegance and calm, allowing you to relax and enjoy the ride with confidence and peace of mind."
    },
    {
        "WhyChooseheading": "Customizable Packages:",
        "WhyChoosedescription": "We understand that every wedding is unique, and our customizable packages are designed to accommodate your specific requirements. Whether you need multiple pick-ups for family members, bridal party transportation, or a stylish exit for the newlyweds, our team can create a personalized itinerary. You can also choose special decorations for the car, music options, and additional services, making sure that every detail aligns perfectly with your vision for the day. This flexibility ensures that all transportation logistics are seamlessly integrated into your wedding plans."
    },
    {
        "WhyChooseheading": "Timely Services:",
        "WhyChoosedescription": "Punctuality is essential for a stress-free wedding day. Our drivers meticulously plan each route to account for traffic and local events, ensuring a timely arrival at every location. Whether it's reaching the ceremony venue, taking a scenic route for photographs, or transporting guests from one venue to another, we prioritize on-time service. By ensuring that you and your loved ones stick to the day’s schedule, we help you enjoy every planned moment without the anxiety of delays."
    },
    {
        "WhyChooseheading": "Affordable Rates:",
        "WhyChoosedescription": "We offer luxury wedding transportation that suits a variety of budgets, allowing you to experience premium services without financial strain. Our pricing is transparent, with no hidden fees or unexpected charges, and we are committed to providing exceptional value. From elegant entry-level options to high-end luxury vehicles, our affordable rates allow you to choose the right fit for your wedding style and budget. We also offer flexible payment options and provide detailed quotes, so you know exactly what to expect."
    },
    {
        "WhyChooseheading": "Hassle-Free Booking:",
        "WhyChoosedescription": "Our booking process is designed for simplicity and convenience. From our user-friendly online platform to the option of direct assistance over the phone, reserving your wedding car is quick and easy. You can book well in advance or make last-minute adjustments if needed. Our responsive customer service team is available to answer questions, confirm details, and accommodate any special requests, so you can rest assured that your transportation needs are in good hands while you focus on other wedding preparations."
    },
    {
        "WhyChooseheading": "Local Knowledge:",
        "WhyChoosedescription": "Our drivers are highly familiar with the roads, popular venues, and hidden gems around Shirdi, allowing for smooth navigation and efficient routes. They are skilled at avoiding high-traffic areas and can recommend scenic locations for photographs or quick detours to accommodate unexpected changes. This local expertise ensures that no time is wasted, and you reach each destination with ease, adding convenience and peace of mind to your wedding day experience."
    },
    {
        "WhyChooseheading": "Comprehensive Insurance:",
        "WhyChoosedescription": "Safety is paramount, especially on such an important day. Our vehicles are fully insured, and our operations follow strict safety and maintenance protocols, ensuring a secure and stress-free experience. Each car undergoes regular maintenance checks, and our insurance covers both the vehicle and passengers, so you can enjoy your ride knowing that every precaution has been taken to protect you and your loved ones."
    },
    {
        "WhyChooseheading": "Support for Large Groups:",
        "WhyChoosedescription": "If your wedding involves transporting a larger group, we have a range of vehicle options to accommodate everyone comfortably. Our fleet includes spacious SUVs, minibuses, and larger coaches suitable for extended family and friends. These vehicles ensure that your entire party arrives together, making coordination easier and keeping everyone on the same schedule. With our group transportation options, you can offer your guests a seamless and comfortable travel experience that enhances the joy of your special day."
    },
    {
        "WhyChooseheading": "Positive Customer Feedback:",
        "WhyChoosedescription": "Our clients consistently commend our reliable service, attention to detail, and commitment to making their day unforgettable. We have received numerous positive testimonials praising our ability to handle logistics seamlessly, provide courteous and professional drivers, and deliver a top-tier experience. These customer reviews reflect our dedication to excellence, showing that we are trusted by couples and families who seek a flawless transportation experience on one of the most important days of their lives."
    }
]



        
  }


  const faqData = [
    {
        question: "What types of vehicles are available for wedding rentals?",
        answer: "We offer a variety of vehicles, including luxury sedans, SUVs, and minibuses, to cater to different wedding needs."
    },
    {
        question: "How do I book a rental car for my wedding?",
        answer: "You can book through our website or by calling our customer service team for assistance."
    },
    {
        question: "Are drivers included with the rental?",
        answer: "Yes, all rentals come with professional drivers who will handle the transportation on your wedding day."
    },
    {
        question: "Can I customize the rental duration?",
        answer: "Yes, we provide flexible rental durations that can be customized to fit your wedding schedule."
    },
    {
        question: "Is there a deposit required to book?",
        answer: "Yes, a deposit is typically required at the time of booking to secure your vehicle."
    },
    {
        question: "What if my wedding schedule changes?",
        answer: "We understand that plans may change. Please contact us as soon as possible to discuss rescheduling options."
    },
    {
        question: "Do you provide services for out-of-town weddings?",
        answer: "Yes, we can accommodate rental services for weddings outside of Shirdi, depending on availability."
    },
    {
        question: "Is insurance included in the rental price?",
        answer: "Yes, all our rental vehicles come with comprehensive insurance coverage for added peace of mind."
    },
    {
        question: "Can you provide transportation for guests as well?",
        answer: "Absolutely! We can arrange transportation for your guests with larger vehicles or multiple cars."
    },
    {
        question: "How can I contact you for more information?",
        answer: "You can reach us via phone or email for any inquiries or additional information regarding your wedding car rental."
    }
];

const testimonials = [
    {
        name: "Mrs. Sneha Rathi",
        text: "I cannot thank Saitirtha Travels enough for their exceptional service during our wedding. The car was stunning, and our driver was professional and friendly, making the day even more special. Highly recommend their wedding rental service!"
    },
    {
        name: "Mr. Ankit Joshi",
        text: "We booked a luxury car for our wedding through Saitirtha Travels, and everything was perfect! The vehicle was immaculate, and the driver was on time and very accommodating. I would recommend them to anyone planning a wedding!"
    }
];



  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Wedding Car Rental in Shirdi | Bridal Car Hire | Luxury Wedding Transportation</title>
    <meta name="description" content="Book the best wedding car rental services in Shirdi with Saitirtha Travels. Offering luxury, affordable, and decorated wedding cars, including bridal car hire, marriage car rentals, and more!" />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirtha Travels | Wedding Car Rental in Shirdi | Bridal Car Hire | Luxury Wedding Transportation" />
    <meta property="og:description" content="Book the best wedding car rental services in Shirdi with Saitirtha Travels. Offering luxury, affordable, and decorated wedding cars, including bridal car hire, marriage car rentals, and more!" />
    <meta property="og:url" content="https://saitirthatravels.com/wedding-car-rental-shirdi" />
    <meta property="og:image" content="https://saitirthatravels.com/images/wedding-car-rental-shirdi.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Book the best wedding car rental services in Shirdi with Saitirtha Travels. Offering luxury, affordable, and decorated wedding cars, including bridal car hire, marriage car rentals, and more!",
            "url": "https://saitirthatravels.com/wedding-car-rental-shirdi",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/wedding-car-rental-shirdi.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Wedding Car Rental Shirdi",
                "Rent Car for Wedding in Shirdi",
                "Shirdi Wedding Car Hire",
                "Best Wedding Car Rental Shirdi",
                "Shirdi Bridal Car Rental",
                "Luxury Car Rental for Wedding Shirdi",
                "Wedding Transportation Shirdi",
                "Shirdi Marriage Car Rental",
                "Affordable Wedding Car Hire Shirdi",
                "Wedding Car Decoration Shirdi",
                "Car for Marriage in Shirdi",
                "Cab Hire for Marriage in Shirdi",
                "Wedding Car with Decoration in Shirdi",
                "Car Hire for Marriage Function in Shirdi",
                "SaitirthaTours & Travels Shirdi"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/63.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Rentalcarweddingshirdi;