
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Shirditoujjjaincab() {



  const cardData =
  {
    keyword: '  Shirdi to Ujjain Cab',
    heading: 'Saitirtha Travels: Shirdi to Ujjain Cab',
    headingDescription: 'Embarking on a spiritual journey from Shirdi to Ujjain? Saitirtha Travels provides convenient and comfortable cab services to make your travel experience enjoyable. Known for its religious significance, Ujjain attracts pilgrims from all over India, and we ensure that your ride is smooth and hassle-free.',

    top: 'Top Places to Visit in Ujjain with Saitirtha Travels',

 "topPlaces": [
    {
        "title": "Mahakaleshwar Temple",
        "description": "The Mahakaleshwar Temple is a revered shrine dedicated to Lord Shiva, recognized as one of the twelve Jyotirlingas in India. This ancient temple, with its striking architecture and spiritual ambiance, attracts millions of devotees from across the country and beyond each year. The Bhasma Aarti, performed daily at dawn, is a must-see ritual that showcases the temple's religious significance and draws large crowds eager to witness the ceremony. The aarti involves the offering of sacred ash to the idol of Lord Shiva, creating an atmosphere of devotion and spirituality. The temple's intricate carvings and serene surroundings add to its allure, making it a pivotal site in Ujjain’s religious landscape."
    },
    {
        "title": "Kumbh Mela",
        "description": "Ujjain is famous for hosting the Kumbh Mela, one of the largest and most significant religious gatherings in the world. Occurring every 12 years, this grand event attracts millions of pilgrims who come to take a holy dip in the Shipra River, believed to cleanse sins and grant salvation. The Kumbh Mela is not just a religious event but a vibrant cultural spectacle, featuring various rituals, processions, and spiritual discourses. The atmosphere is charged with devotion, and the gathering of diverse groups creates a unique tapestry of faith and tradition. Visitors can immerse themselves in the spiritual fervor and witness the incredible camaraderie among devotees."
    },
    {
        "title": "Sandipani Ashram",
        "description": "Sandipani Ashram is an ancient site associated with the teachings of Lord Krishna and is believed to be the place where he studied under his guru Sandipani. The ashram offers visitors a serene environment rich in cultural history, where they can explore the tranquil surroundings and meditate. The site features beautiful gardens and remnants of ancient structures, evoking a sense of peace and connection to spiritual heritage. The ashram is also a center for learning, where visitors can delve into the teachings of Krishna and engage in discussions on philosophy and spirituality."
    },
    {
        "title": "Bade Ganeshji Ka Mandir",
        "description": "Bade Ganeshji Ka Mandir is a prominent temple dedicated to Lord Ganesha, renowned for its impressive idol of the deity, which stands tall and majestic. The temple is characterized by its peaceful environment, making it an ideal place for meditation and reflection. Devotees flock here to seek blessings and participate in various rituals that celebrate Lord Ganesha’s virtues. The temple's architecture, adorned with intricate carvings and vibrant colors, adds to its spiritual ambiance. Visitors can experience the warmth of devotion and partake in the festivities held during Ganesha Chaturthi, a significant festival celebrated with much fervor."
    },
    {
        "title": "Harsiddhi Temple",
        "description": "Harsiddhi Temple, dedicated to Goddess Harsiddhi, is a significant pilgrimage site that holds immense spiritual importance for devotees. The temple's architectural beauty, with its intricate carvings and detailed sculptures, is truly captivating. Visitors are drawn to the temple not only for its religious significance but also for the breathtaking views of the city and the surrounding landscape from its premises. The serene atmosphere enhances the spiritual experience, making it a popular spot for prayers and rituals. The temple is especially lively during festivals, when devotees gather to celebrate and offer their respects to the goddess."
    },
    {
        "title": "Vedh Shala",
        "description": "Vedh Shala, Ujjain's famous astronomical observatory, was built in the 17th century and offers fascinating insights into ancient Indian astronomy. This historic site showcases the remarkable techniques employed by astronomers of the time, featuring architectural elements designed for astronomical observations. Visitors can explore the functioning instruments and learn about the significant contributions of Indian astronomers to the field. The observatory's rich history and scientific relevance make it an engaging place for those interested in the stars and the universe, highlighting Ujjain’s legacy as a center of learning and knowledge."
    },
    {
        "title": "Mahakal Mandir",
        "description": "Located near the Mahakaleshwar Temple, Mahakal Mandir is known for its unique architecture and vibrant atmosphere. This temple offers various rituals and ceremonies, creating an engaging spiritual experience for visitors. The colorful decorations and fervent devotion evident during worship services contribute to the temple’s lively environment. Visitors can participate in the rituals and soak in the energy of the space, making it an essential stop for those on a spiritual journey through Ujjain. The temple also serves as a gathering place for devotees, fostering a sense of community and shared faith."
    },
    {
        "title": "Ram Ghat",
        "description": "Ram Ghat, located on the banks of the Shipra River, is a popular spot for pilgrims to take holy dips and perform various rituals. The ghat is particularly lively during religious festivals, where it transforms into a vibrant hub of activity, with devotees engaging in prayers and ceremonies. Visitors can enjoy the picturesque views of the river, particularly at sunrise and sunset when the surroundings are bathed in golden light. The atmosphere at Ram Ghat is both spiritual and festive, offering a unique glimpse into the religious practices of Ujjain and the significance of the Shipra River in Hindu mythology."
    },
    {
        "title": "Siddhavat",
        "description": "Siddhavat is a sacred spot marked by a venerable banyan tree, known for its spiritual significance and tranquility. It is believed to be a place where devotees can meditate and seek divine blessings. The serene surroundings provide an ideal setting for contemplation and reflection, attracting those in search of peace and spiritual connection. Visitors often gather here to perform rituals and partake in quiet meditation, creating a harmonious atmosphere that encourages introspection. The natural beauty and historical significance of Siddhavat make it a cherished destination for spiritual seekers and visitors alike."
    },
    {
        "title": "Gadkalika Mandir",
        "description": "Gadkalika Mandir is dedicated to Goddess Gadkalika and is renowned for its intricate carvings and historical significance. The temple’s architecture reflects the artistic heritage of the region, captivating visitors with its detailed sculptures and design. It serves as an essential stop for devotees seeking the blessings of the goddess, especially during the festival of Navratri when the temple comes alive with colorful decorations and fervent worship. Visitors can immerse themselves in the spiritual atmosphere and appreciate the artistry of the temple while participating in the rituals that celebrate the goddess’s power and grace."
    }
],
    
            
        
"services": [
    {
        "name": "Shirdi to Ujjain Cab",
        "description": "Embark on a comfortable and scenic journey from Shirdi to Ujjain, a revered pilgrimage destination known for its historical and spiritual significance. Our Shirdi to Ujjain cab service ensures a pleasant and hassle-free trip, making it easy for you to explore Ujjain’s attractions, such as the Mahakaleshwar Temple, and immerse yourself in its rich culture. With picturesque landscapes along the way, our service allows you to focus on your spiritual journey while we take care of your transportation needs."
    },
    {
        "name": "Shirdi to Ujjain Cab Service",
        "description": "We provide reliable Shirdi to Ujjain cab services tailored to meet diverse travel needs. Whether you're traveling solo, with family, or in a group, our fleet includes vehicles of various sizes and comforts, such as compact cars, sedans, and spacious SUVs. Our experienced and courteous drivers are committed to ensuring a safe and smooth journey, allowing you to relax and enjoy the beautiful views and vibrant culture as you travel from Shirdi to Ujjain."
    },
    {
        "name": "Shirdi to Ujjain Taxi Fare",
        "description": "We pride ourselves on offering affordable and transparent taxi fares from Shirdi to Ujjain. Our fare structure is straightforward, with no hidden costs, making it easy for you to plan your travel budget. You’ll receive detailed pricing information upfront, so you know exactly what to expect when booking with us. Additionally, we often run seasonal discounts and special packages, making your travel from Shirdi to Ujjain even more economical and accessible."
    },
    {
        "name": "Book Cab from Shirdi to Ujjain",
        "description": "Booking a cab from Shirdi to Ujjain with SaitirthaTours & Travels is simple and convenient. We offer flexible booking options that allow you to reserve a cab in advance, ensuring you have a vehicle ready when you need it. Our user-friendly online and phone booking systems make the reservation process quick and hassle-free, letting you choose the perfect vehicle based on your group size, travel needs, and preferences."
    },
    {
        "name": "One-Way Taxi Shirdi to Ujjain",
        "description": "If you need a one-way ride, our one-way taxi service from Shirdi to Ujjain is an ideal choice. This service allows you to travel to Ujjain without the obligation of booking a return trip. It's a budget-friendly option, especially suited for travelers needing transportation in one direction. Our dedicated drivers ensure that even a one-way journey is comfortable and convenient, allowing you to sit back and enjoy the ride."
    },
    {
        "name": "Round Trip Cab Shirdi to Ujjain",
        "description": "Our round-trip cab service from Shirdi to Ujjain offers ultimate flexibility for travelers looking to visit Ujjain and return to Shirdi. Ideal for both short visits and extended stays, this service includes all-day availability of the cab, so you can explore Ujjain at your own pace. You can even make stops at nearby attractions, enhancing your travel experience and making the most of your journey."
    },
    {
        "name": "Car Rental Shirdi to Ujjain",
        "description": "Our car rental services from Shirdi to Ujjain provide flexibility and control over your travel experience. Whether you're looking for a compact car, a luxurious sedan, or a spacious SUV, we have multiple options to cater to your specific needs. Renting a car with SaitirthaTours & Travels means you can enjoy a private, personalized travel experience, enabling you to stop and explore along the route as you wish, making it a perfect choice for adventurous travelers."
    },
    {
        "name": "Reliable Cab Shirdi to Ujjain",
        "description": "Our cab service from Shirdi to Ujjain is renowned for its dependability and excellent service. We prioritize passenger safety and comfort, ensuring that our cabs are well-maintained, sanitized, and equipped with all necessary amenities. Our knowledgeable drivers are familiar with the best routes, guaranteeing a safe and efficient journey from start to finish. With our reliable service, you can trust that you’re in good hands as you travel towards your spiritual destination."
    },
    {
        "name": "Distance Shirdi to Ujjain Taxi",
        "description": "The distance from Shirdi to Ujjain is approximately 350 kilometers, taking around 7-8 hours by taxi. Our drivers are experienced with this route, making the journey comfortable and enjoyable. With our comfortable cabs, you can relax while enjoying the scenic beauty and historical landscapes along the way, ensuring a pleasant travel experience. We'll take care of the journey so you can focus on the experiences awaiting you in Ujjain."
    },
    {
        "name": "Direct Cab Shirdi to Ujjain",
        "description": "Our direct cab service from Shirdi to Ujjain offers a non-stop, uninterrupted journey to your destination. This service is perfect for those who wish to reach Ujjain quickly and efficiently, without any detours. The direct service prioritizes swift arrivals, making it ideal for pilgrims and travelers on a tight schedule who want to maximize their time at their destination."
    },
    {
        "name": "Shirdi to Ujjain Innova Crysta Cab Booking",
        "description": "For a more premium travel experience, we offer the Innova Crysta cab from Shirdi to Ujjain. Known for its spacious interior and high comfort levels, the Innova Crysta is an excellent option for families and larger groups. Enjoy a luxurious and smooth journey in this well-equipped vehicle, complete with air conditioning, comfortable seating, and ample luggage space, ensuring everyone travels in style and comfort."
    },
    {
        "name": "Shirdi to Ujjain Ertiga Car on Rent",
        "description": "Our Ertiga car rental service provides a comfortable and budget-friendly travel solution for medium-sized groups. This versatile vehicle is perfect for families and groups looking for spacious transport without overspending. The Ertiga ensures a comfortable and enjoyable ride from Shirdi to Ujjain, allowing you to make the most of your journey together."
    },
    {
        "name": "Shirdi to Ujjain Sedan Cab Service on Rent",
        "description": "Our sedan cab rental service from Shirdi to Ujjain offers an ideal option for small groups or solo travelers seeking a comfortable yet economical ride. Our sedans are well-maintained and provide a smooth and efficient journey for those looking to travel with ease, making it a perfect choice for both leisure and business travelers."
    },
    {
        "name": "Shirdi Jyotirlinga Darshan Cab Service",
        "description": "Our Shirdi Jyotirlinga Darshan cab service is designed for pilgrims heading to Ujjain for spiritual purposes. Our experienced drivers are familiar with the best routes to the Jyotirlinga temples, ensuring a seamless and stress-free journey. Our cabs provide a peaceful atmosphere, perfect for your spiritual travels, allowing you to reflect and prepare for your holy visit."
    },
    {
        "name": "Shirdi to Mahakaleshwar Ujjain Cab Service",
        "description": "Our cab service to Mahakaleshwar in Ujjain is ideal for those planning to visit this holy site. Mahakaleshwar Temple is one of the most revered temples in India, and our cab service ensures you reach the temple in comfort and style, allowing you to focus on your spiritual experience without any transportation worries. We ensure that your journey to this sacred place is as meaningful as your visit."
    },
    {
        "name": "Cab Packages Shirdi to Ujjain",
        "description": "We offer a variety of cab packages from Shirdi to Ujjain tailored to suit different travel preferences. Whether you need a one-way trip, round trip, or a complete tour package, we have options that provide flexibility and convenience for all types of travelers. Our packages include stops at key attractions, giving you the chance to explore more of what Ujjain has to offer while ensuring a memorable experience."
    },
    {
        "name": "Shirdi to Ujjain Cab Fare",
        "description": "Our cab fare from Shirdi to Ujjain is competitive, providing value for money without compromising on service quality. We maintain a transparent fare structure, with clear pricing and no hidden charges. Our fare includes driver charges, fuel costs, and toll fees, ensuring you know exactly what you’re paying for. Our commitment to transparency means you can travel with peace of mind."
    },
    {
        "name": "Contact Number for Shirdi to Ujjain Cab Services",
        "description": "For more information or to make a booking, contact SaitirthaTours & Travels at +91 9923879009 / 9922479009. Experience a seamless, comfortable journey with our reliable Shirdi to Ujjain cab services. Our friendly team is ready to assist you with any inquiries and ensure your travel plans are executed smoothly, so you can focus on your pilgrimage."
    }
],
"tableData": [
    ["- Shirdi to Ujjain Cab Service", "- Shirdi Ujjain Taxi Fare"],
    ["- Best Cab from Shirdi to Ujjain", "- Cheap Taxi Shirdi Ujjain"],
    ["- Book Cab Shirdi Ujjain", "- One-way Taxi Shirdi to Ujjain"],
    ["- Round Trip Cab Shirdi Ujjain", "- Car Rental Shirdi to Ujjain"],
    ["- Reliable Cab Shirdi Ujjain", "- Distance Shirdi Ujjain Taxi"],
    ["- Direct Cab Shirdi to Ujjain", "- Cab Packages Shirdi to Ujjain"],
    ["- Shirdi to Ujjain Cab Fare", "- Shirdi To Ujjain Innova Crysta Cab Booking"],
    ["- Shirdi to Ujjain Ertiga Car on Rent", "- Shirdi to Ujjain Sedan Cab Service On Rent"],
    ["- Shirdi Jyotirlinga Darshan Cab Service", "- Shirdi to Mahakaleshwar Ujjain Cab Service"],
    ["- SaitirthaTours & Travels Shirdi", ""]
],
"whychoose": [
    {
        "WhyChooseheading": "Convenient Booking:",
        "WhyChoosedescription": "Booking your cab with Saitirtha Travels is designed to be effortless. Our intuitive website allows you to reserve your ride within minutes, and if you prefer a personal touch, our customer support team is always ready to assist you over the phone. This ensures that you can finalize your travel plans quickly, whether at home or on the go, eliminating any stress or confusion during the booking process."
    },
    {
        "WhyChooseheading": "Comfortable Journeys:",
        "WhyChoosedescription": "Traveling should be an enjoyable experience, and our fleet of vehicles reflects that philosophy. We offer a variety of well-maintained cars, including luxury options, all designed to provide exceptional comfort. With spacious seating, climate control, and smooth suspension, you can relax and enjoy the scenery as you travel from Shirdi to Ujjain. Your comfort is our priority, whether you're traveling alone or with a group."
    },
    {
        "WhyChooseheading": "Experienced and Friendly Drivers:",
        "WhyChoosedescription": "Our drivers are not just skilled in navigating the roads; they are also trained in customer service. Each driver undergoes rigorous background checks and training to ensure they provide a safe and pleasant experience. They are well-versed in the best routes and traffic patterns, which helps minimize travel time. Moreover, their friendly demeanor adds a personal touch to your journey, making you feel welcome and cared for."
    },
    {
        "WhyChooseheading": "Transparent Pricing:",
        "WhyChoosedescription": "At Saitirtha Travels, we believe in complete transparency when it comes to pricing. Our rates are competitive, and we provide clear breakdowns of all costs involved in your journey. There are no hidden fees or unexpected charges, so you can plan your travel budget with confidence. Our commitment to fair pricing ensures that you receive the best value for the exceptional service we provide."
    },
    {
        "WhyChooseheading": "Flexible Options:",
        "WhyChoosedescription": "Understanding that every traveler has different needs, we offer highly flexible options tailored to your specific requirements. Whether you need a one-way journey, a round trip with flexible timings, or multiple stops along the way, we are here to accommodate your preferences. Our goal is to ensure that your travel experience is seamless and tailored to fit your schedule."
    },
    {
        "WhyChooseheading": "24/7 Customer Support:",
        "WhyChoosedescription": "Travel plans can change at any moment, which is why our customer support team is available 24/7. Whether you have questions about your booking, need to make changes, or require assistance during your journey, our responsive and knowledgeable staff are just a call away. This round-the-clock availability ensures that you have support whenever you need it, adding an extra layer of convenience to your travel experience."
    },
    {
        "WhyChooseheading": "Safety and Hygiene Measures:",
        "WhyChoosedescription": "Your safety is our utmost priority. We maintain stringent cleaning and hygiene protocols for all our vehicles. Each cab undergoes regular sanitization, and we adhere to health guidelines to provide a safe travel environment. This commitment to safety gives you peace of mind, allowing you to focus on enjoying your journey without worrying about health concerns."
    },
    {
        "WhyChooseheading": "Punctuality Assured:",
        "WhyChoosedescription": "We understand that time is a precious commodity, especially when you have commitments such as flights or appointments. Our drivers are trained to be punctual, ensuring that you are picked up on time and arrive at your destination as scheduled. We take pride in our reputation for reliability, which is reflected in our timely service that consistently meets our customers' needs."
    },
    {
        "WhyChooseheading": "Local Insights:",
        "WhyChoosedescription": "Our drivers are not only skilled navigators but also knowledgeable about the local area. They can provide valuable insights and interesting stories about the places you pass along the way, enhancing your travel experience. This local knowledge can help you discover hidden gems and learn more about the culture and history of the region, making your trip more enriching and enjoyable."
    },
    {
        "WhyChooseheading": "Positive Customer Feedback:",
        "WhyChoosedescription": "Our customers are our greatest advocates. The many positive reviews and testimonials we receive reflect our commitment to exceptional service and customer satisfaction. We take pride in the long-lasting relationships we build with our clients, many of whom return to us for their travel needs and recommend us to their friends and family. This level of trust and loyalty is a testament to the quality of service we provide."
    }
]



        
  }

  const faqData = [
    {
        question: "How do I book a cab from Shirdi to Ujjain?",
        answer: "You can book a cab easily through our website or by calling our customer service for assistance."
    },
    {
        question: "What is the distance from Shirdi to Ujjain?",
        answer: "The distance is approximately 550 kilometers, and the journey typically takes around 10 to 12 hours."
    },
    {
        question: "Are tolls and taxes included in the fare?",
        answer: "Yes, all tolls and taxes are included in the fare, ensuring no surprises at the end of your journey."
    },
    {
        question: "Can I change my booking after it has been confirmed?",
        answer: "Yes, modifications can be made by contacting our support team, subject to availability."
    },
    {
        question: "What type of vehicles do you offer for this route?",
        answer: "We provide a range of vehicles, including sedans, SUVs, and larger options for group travel."
    },
    {
        question: "Are your drivers licensed and trained?",
        answer: "Yes, all our drivers are licensed professionals with extensive experience in providing safe transportation."
    },
    {
        question: "What payment methods are accepted?",
        answer: "We accept cash, credit/debit cards, and online payment options for your convenience."
    },
    {
        question: "Is customer support available at all times?",
        answer: "Yes, our customer support team is available 24/7 to assist you with any questions or concerns."
    },
    {
        question: "Can I book a round trip to Ujjain?",
        answer: "Absolutely! We offer flexible round trip options to accommodate your travel schedule."
    },
    {
        question: "What happens if my arrival time changes?",
        answer: "Our drivers monitor traffic and will adjust the pick-up time accordingly to ensure timely service."
    }
];

const testimonials = [
    {
        name: "Mr. Anil Joshi",
        text: "I recently booked a cab from Shirdi to Ujjain with Saitirtha Travels, and it was a wonderful experience. The driver was punctual and very polite, making the long journey enjoyable. The vehicle was clean and comfortable, which made a significant difference. Highly recommend their service!"
    },
    {
        name: "Mrs. Sneha Mehta",
        text: "Traveling from Shirdi to Ujjain with Saitirtha Travels was an excellent choice. The booking process was straightforward, and our driver was friendly and knowledgeable about the area. I felt safe and comfortable throughout the journey. I will definitely use their service again!"
    }
];





  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Shirdi to Ujjain Cab | SaitirthaTours & Travels | Affordable Taxi Service</title>
    <meta name="description" content="Book affordable and reliable taxi services from Shirdi to Ujjain. Offering one-way, round trip, and car rental options including Innova Crysta, Ertiga, and Sedan. Book now!" />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirtha Travels | Shirdi to Ujjain Cab | SaitirthaTours & Travels | Affordable Taxi Service" />
    <meta property="og:description" content="Book affordable and reliable taxi services from Shirdi to Ujjain. Offering one-way, round trip, and car rental options including Innova Crysta, Ertiga, and Sedan. Book now!" />
    <meta property="og:url" content="https://saitirthatravels.com/shirdi-to-ujjain-cab" />
    <meta property="og:image" content="https://saitirthatravels.com/images/shirdi-to-ujjain-cab.jpg" />
    <meta name="twitter:card" content="summary_large_image" />
  
    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Book reliable and affordable taxi services from Shirdi to Ujjain. We provide one-way, round trip, and rental services, including Innova Crysta, Ertiga, and Sedan. Book your ride now!",
            "url": "https://saitirthatravels.com/shirdi-to-ujjain-cab",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/shirdi-to-ujjain-cab.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "180"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi to Ujjain Cab service",
                "Shirdi Ujjain taxi fare",
                "Best cab from Shirdi to Ujjain",
                "Shirdi to Ujjain one way taxi",
                "Shirdi to Ujjain round trip",
                "Car rental Shirdi to Ujjain",
                "Shirdi Ujjain taxi rates",
                "Reliable cab Shirdi to Ujjain",
                "Shirdi to Ujjain Innova crysta",
                "Shirdi to Ujjain Ertiga car on rent",
                "Shirdi to Ujjain sedan cab service",
                "Shirdi Jyotirlinga Darshan cab service",
                "Shirdi to Mahakaleshwar Ujjain cab",
                "SaitirthaTours & Travels"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/56.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirditoujjjaincab;