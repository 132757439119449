
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Shirditojyotilinga() {



  const cardData =
  {
    keyword: ' Shirdi to 5 Jyotirlinga Package',
    heading: 'Saitirtha Travels: Shirdi to 5 Jyotirlinga Package',
    headingDescription: 'Embark on a spiritual journey with Saitirtha Travels, offering an exclusive Shirdi to 5 Jyotirlinga Package. This package is designed for devotees who wish to visit the five sacred Jyotirlinga temples in Maharashtra, combining devotion with exploration of the rich cultural heritage of the region.',

    top: 'Top Places to Visit on the 5 Jyotirlinga with Saitirtha Travels',

 "topPlaces": [
    {
        "title": "Grishneshwar Jyotirlinga (Ellora)",
        "description": "Located near Ellora Caves, Grishneshwar is the twelfth Jyotirlinga and is famous for its stunning temple architecture. The temple is dedicated to Lord Shiva, and visitors can witness the intricate carvings and serene atmosphere."
    },
    {
        "title": "Aundha Nagnath Jyotirlinga",
        "description": "Situated in Hingoli, Aundha Nagnath is one of the ancient Jyotirlingas. The temple is known for its unique architecture and peaceful surroundings, making it a perfect stop for spiritual seekers."
    },
    {
        "title": "Parli Vaijnath Jyotirlinga",
        "description": "Located in Beed district, Parli Vaijnath is famous for its annual pilgrimage and is an important site for devotees. The temple features stunning stone carvings and offers a tranquil environment for prayer."
    },
    {
        "title": "Bhimashankar Jyotirlinga",
        "description": "Nestled in the Western Ghats, Bhimashankar is surrounded by lush greenery and scenic landscapes. The temple is a popular trekking destination and is known for its natural beauty and wildlife."
    },
    {
        "title": "Kedarnath Jyotirlinga",
        "description": "While Kedarnath is primarily known for its temple in Uttarakhand, it is included in the Jyotirlinga circuit for its significance. The serene environment and breathtaking views make it a must-visit for spiritual travelers."
    }
],
    
            
        
"services": [
    {
        "name": "Shirdi to 5 Jyotirlinga Package",
        "description": "Embark on a spiritual journey with our Shirdi to 5 Jyotirlinga Package. This tour is perfect for devotees seeking to visit the sacred Jyotirlingas, which hold immense significance in Hindu mythology. Experience divine blessings and tranquility as you explore these revered temples. Our comprehensive itinerary includes comfortable transportation, accommodations, and ample time for worship, ensuring a fulfilling pilgrimage experience."
    },
    {
        "name": "Shirdi to Somnath Cabs",
        "description": "Our Shirdi to Somnath cabs offer a comfortable and convenient way to reach this iconic pilgrimage site. Enjoy a hassle-free ride with our experienced drivers, ensuring you arrive at Somnath ready for darshan. Our cabs are well-maintained and equipped with modern amenities, making your journey pleasant and stress-free."
    },
    {
        "name": "Mallikarjuna, Mahakaleshwar, Omkareshwar, Kedarnath Package",
        "description": "This comprehensive package includes visits to Mallikarjuna, Mahakaleshwar, Omkareshwar, and Kedarnath. Each temple offers a unique spiritual experience, and our guided tours ensure you don’t miss any highlights along the way. From rich histories to divine architecture, immerse yourself in the sacredness of these Jyotirlingas with a seamless travel experience."
    },
    {
        "name": "Shirdi to 5 Jyotirlinga Tour",
        "description": "Join our Shirdi to 5 Jyotirlinga tour and witness the divine aura of these sacred sites. Our tours are designed for both comfort and spiritual fulfillment, allowing you to connect with your faith deeply. With knowledgeable guides, you will learn about the significance of each Jyotirlinga, enhancing your pilgrimage with insightful information."
    },
    {
        "name": "Best Shirdi to Jyotirlinga Tour Package",
        "description": "We offer the best Shirdi to Jyotirlinga tour package available, tailored to meet the needs of every pilgrim. Enjoy our competitive rates, comfortable vehicles, and knowledgeable drivers who enhance your journey with insightful information. Each package is carefully crafted to ensure a memorable spiritual journey."
    },
    {
        "name": "Shirdi to 5 Jyotirlinga Darshan",
        "description": "Experience the ultimate Shirdi to 5 Jyotirlinga darshan with our well-planned itinerary. This pilgrimage offers spiritual insights and the chance to receive blessings from five of the most revered Jyotirlingas. Our service includes transportation, accommodation, and the opportunity for guided tours at each site, ensuring a comprehensive and spiritually enriching experience."
    },
    {
        "name": "Shirdi to Jyotirlinga Yatra",
        "description": "Embark on a memorable Shirdi to Jyotirlinga yatra. Our service ensures that your journey is comfortable, with everything from transportation to accommodations meticulously arranged for your convenience. Connect deeply with your spirituality as you visit each sacred site with ease and comfort."
    },
    {
        "name": "Shirdi to Somnath Mallikarjuna Mahakaleshwar Omkareshwar Kedarnath Taxi",
        "description": "Travel in comfort with our Shirdi to Somnath, Mallikarjuna, Mahakaleshwar, Omkareshwar, and Kedarnath taxi service. We provide reliable transportation options that cater to all your pilgrimage needs, ensuring a smooth journey with experienced drivers familiar with the best routes and practices for a spiritual journey."
    },
    {
        "name": "Shirdi to 5 Jyotirlinga Pilgrimage",
        "description": "Experience a fulfilling Shirdi to 5 Jyotirlinga pilgrimage. Our packages include all major Jyotirlingas, providing a rich spiritual experience with ample time for prayer and reflection at each site. We focus on creating an itinerary that allows for both spiritual engagement and personal reflection, enhancing the pilgrimage experience."
    },
    {
        "name": "Shirdi to 5 Jyotirlinga Car Rental",
        "description": "Looking for flexibility? Our Shirdi to 5 Jyotirlinga car rental services allow you to explore at your own pace. Choose from a variety of vehicles to suit your group size and preferences. Our cars are well-maintained, and you’ll have the freedom to make spontaneous stops at temples or scenic spots along the way."
    },
    {
        "name": "Shirdi to 5 Jyotirlinga Distance",
        "description": "The total distance from Shirdi to the 5 Jyotirlingas is well within reach, making this journey both manageable and rewarding. Our drivers are experienced in navigating the routes efficiently, ensuring you spend more time at the sacred sites and less time on the road."
    },
    {
        "name": "Shirdi to Bhimashankar Cab",
        "description": "Don’t miss the opportunity to visit Bhimashankar with our reliable Shirdi to Bhimashankar cab service. This site is renowned for its stunning architecture and serene atmosphere. Our cabs provide comfortable transportation, allowing you to focus on your spiritual experience."
    },
    {
        "name": "Shirdi to Grishneshwar Cab",
        "description": "Book our Shirdi to Grishneshwar cab service to visit this historic temple, known for its beautiful carvings and spiritual significance. Enjoy a comfortable ride while immersing yourself in the spiritual ambiance of one of the 12 Jyotirlingas."
    },
    {
        "name": "Shirdi to Vaijnath Cab Booking",
        "description": "Secure your transportation with our easy Shirdi to Vaijnath cab booking service, ensuring you have a comfortable journey to one of the 12 Jyotirlingas. Our booking process is straightforward, and our drivers are knowledgeable about the best routes."
    },
    {
        "name": "Shirdi to Trimbakeshwar Cab",
        "description": "Travel with ease using our Shirdi to Trimbakeshwar cab service. This temple is famous for its unique architecture and spiritual ambiance. Our drivers will ensure you have a smooth journey, allowing you to fully immerse yourself in the spiritual experience."
    },
    {
        "name": "Shirdi to Aundha Nagnath Taxi Fare",
        "description": "We offer competitive Shirdi to Aundha Nagnath taxi fare, making it affordable for all pilgrims to visit this sacred site. Our pricing is transparent, ensuring no hidden fees, and providing you with the best value for your spiritual journey."
    },
    {
        "name": "5 Jyotirlinga in Maharashtra Tour Package from Shirdi",
        "description": "Join us for the 5 Jyotirlinga in Maharashtra tour package from Shirdi. This carefully curated itinerary ensures you visit each Jyotirlinga while enjoying comfort and convenience. Experience the spiritual significance of each temple with well-organized transportation and accommodations."
    },
    {
        "name": "Shirdi to 5 Jyotirlinga Package Contact Information",
        "description": "For more details and to book your Shirdi to 5 Jyotirlinga Package, reach out to SaitirthaTours & Travels at +91 9923879009 / 9922479009. Let us help you create a spiritually enriching experience on your pilgrimage with tailored services to meet your needs and preferences."
    }
],


"tableData": [
    ["- Shirdi to 5 Jyotirlinga Package", "- Shirdi to Somnath Cabs"],
    ["- Mallikarjuna", "- Mahakaleshwar"],
    ["- Omkareshwar", "- Kedarnath Package"],
    ["- Shirdi to 5 Jyotirlinga Tour", "- Best Shirdi to Jyotirlinga Tour Package"],
    ["- Shirdi to 5 Jyotirlinga Darshan", "- Shirdi to Jyotirlinga Yatra"],
    ["- Shirdi to Somnath Mallikarjuna Mahakaleshwar Omkareshwar Kedarnath Taxi", "- Shirdi to 5 Jyotirlinga Pilgrimage"],
    ["- Shirdi to 5 Jyotirlinga Car Rental", "- Shirdi to 5 Jyotirlinga Distance"],
    ["- SaitirthaTours & Travels Shirdi", "- "]
],
"whychoose": [
    {
        "WhyChooseheading": "Comprehensive Itinerary:",
        "WhyChoosedescription": "Our package is meticulously designed to cover all five revered Jyotirlingas: Somnath, Mahakaleshwar, Omkareshwar, Kashi Vishwanath, and Grishneshwar. This comprehensive itinerary ensures you experience a deep and meaningful spiritual journey, allowing ample time at each site to soak in the divine atmosphere and partake in rituals. Each location has its own unique history and significance, providing travelers with an enriching experience that goes beyond mere sightseeing."
    },
    {
        "WhyChooseheading": "Comfortable Transportation:",
        "WhyChoosedescription": "We prioritize your comfort during the journey by providing well-maintained vehicles equipped with modern amenities. Our vehicles are spacious, allowing you to relax as you travel between the Jyotirlingas. With plush seating and climate control features, we ensure a smooth and pleasant journey, regardless of the distance between destinations. This focus on comfort makes a significant difference, especially during long travels, so you can enjoy the spiritual experience without fatigue."
    },
    {
        "WhyChooseheading": "Experienced Drivers:",
        "WhyChoosedescription": "Our drivers are not only skilled behind the wheel but also possess extensive knowledge of the routes and local culture surrounding each Jyotirlinga. They are committed to ensuring your safety and comfort throughout the trip. Their insights about the area, its traditions, and its significance can greatly enhance your travel experience, providing a deeper understanding of the places you visit. Their professionalism and courteous demeanor contribute to a positive and memorable journey."
    },
    {
        "WhyChooseheading": "Affordable Packages:",
        "WhyChoosedescription": "We understand the importance of offering great value for your pilgrimage. Our competitive pricing structure includes all necessary features—transportation, driver services, and more—without hidden costs. By providing transparent quotes and detailed information about what's included in your package, we ensure you can budget effectively for your spiritual journey. Our goal is to make this meaningful experience accessible to all travelers."
    },
    {
        "WhyChooseheading": "Personalized Experience:",
        "WhyChoosedescription": "Every traveler has unique preferences and needs, which is why we offer the flexibility to tailor your itinerary. Whether you wish to include additional stops at other sacred sites or require extensions for personal exploration, our team will work with you to create a customized plan that fits your wishes. This personalized approach ensures that your pilgrimage is not just a journey but a deeply personal experience aligned with your spiritual goals."
    },
    {
        "WhyChooseheading": "Timely Services:",
        "WhyChoosedescription": "We value your time and understand the importance of punctuality during your travels. Our dedicated team ensures timely departures and arrivals at each destination, allowing you to maximize your visit at each Jyotirlinga without the stress of delays. By planning ahead and monitoring traffic conditions, we strive to keep your itinerary on track, ensuring a smooth and efficient journey from start to finish."
    },
    {
        "WhyChooseheading": "Spiritual Guidance:",
        "WhyChoosedescription": "Our knowledgeable staff can provide valuable insights into the significance and history of each Jyotirlinga, enriching your spiritual journey. They can share stories, traditions, and rituals associated with each temple, enhancing your understanding and connection to the sites you visit. This guidance allows you to engage more meaningfully with your pilgrimage, deepening your spiritual experience."
    },
    {
        "WhyChooseheading": "24/7 Support:",
        "WhyChoosedescription": "Travel can be unpredictable, and our commitment to customer service means we are here for you around the clock. Whether you have questions about your booking, need to make changes, or require assistance during your trip, our dedicated customer service team is just a phone call away. This constant support ensures that you feel secure and well taken care of throughout your journey, allowing you to focus on your spiritual exploration."
    },
    {
        "WhyChooseheading": "Safety First:",
        "WhyChoosedescription": "Your safety is our utmost priority. We adhere to stringent safety protocols, conducting regular maintenance checks on all vehicles to ensure they are in excellent condition for your journey. Our drivers are trained to prioritize safety on the road, ensuring a secure travel experience. By following local regulations and guidelines, we aim to create a safe environment for all passengers, allowing you to travel with peace of mind."
    },
    {
        "WhyChooseheading": "Positive Customer Feedback:",
        "WhyChoosedescription": "Our dedication to providing outstanding service has resulted in numerous positive reviews from our travelers. We take pride in our reliability, professionalism, and the quality of our comprehensive services. Satisfied clients often commend our attention to detail, personalized approach, and the enriching experiences we offer. Choosing us means selecting a service that is trusted and appreciated by many pilgrims, ensuring you receive the best possible experience on your journey."
    }
]




        
  }


  const faqData = [
    {
        question: "What is included in the 5 Jyotirlinga package?",
        answer: "The package includes transportation, accommodations, and sightseeing at each Jyotirlinga."
    },
    {
        question: "How long does the tour take?",
        answer: "The duration varies depending on the itinerary but typically ranges from 5 to 7 days."
    },
    {
        question: "Can I customize my itinerary?",
        answer: "Yes, we offer flexibility in customizing your itinerary based on your preferences and schedule."
    },
    {
        question: "Are meals included in the package?",
        answer: "Meals are typically not included, but we can arrange meal options upon request."
    },
    {
        question: "What types of vehicles are used?",
        answer: "We use comfortable and well-maintained vehicles, including sedans, SUVs, and buses based on group size."
    },
    {
        question: "Is there a cancellation policy?",
        answer: "Yes, we have a cancellation policy that outlines the terms and conditions for cancellations."
    },
    {
        question: "How do I book the package?",
        answer: "You can book through our website or contact our customer service team directly for assistance."
    },
    {
        question: "What payment methods do you accept?",
        answer: "We accept various payment methods, including cash, credit/debit cards, and online transfers."
    },
    {
        question: "Are guides available for the tour?",
        answer: "Yes, we can arrange for knowledgeable guides at each Jyotirlinga if you request them in advance."
    },
    {
        question: "Can I join the tour in progress?",
        answer: "Joining mid-tour may be possible, but it depends on availability and scheduling. Please inquire for details."
    }
];

const testimonials = [
    {
        name: "Mr. Sunil Patil",
        text: "Our trip with Saitirtha Travels to the five Jyotirlingas was a divine experience! The entire journey was well-organized, and our driver was not only safe but also shared insightful stories about each place. I highly recommend this package for anyone seeking spiritual fulfillment."
    },
    {
        name: "Ms. Nisha Rao",
        text: "We booked the 5 Jyotirlinga package for our family, and it was absolutely wonderful. The comfort of the vehicle and the knowledge of our driver made our pilgrimage truly memorable. Every detail was taken care of, and we felt guided throughout our journey. Thank you, Saitirtha Travels!"
    }
];


  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Shirdi to 5 Jyotirlinga Package | Affordable Jyotirlinga Tour | SaitirthaTours & Travels</title>
    <meta name="description" content="Book your Shirdi to 5 Jyotirlinga tour package with Saitirtha Travels. Visit Somnath, Mallikarjuna, Mahakaleshwar, Omkareshwar, and Kedarnath with affordable taxi and car rental services." />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirtha Travels | Shirdi to 5 Jyotirlinga Package | Affordable Jyotirlinga Tour | SaitirthaTours & Travels" />
    <meta property="og:description" content="Book your Shirdi to 5 Jyotirlinga tour package with Saitirtha Travels. Visit Somnath, Mallikarjuna, Mahakaleshwar, Omkareshwar, and Kedarnath with affordable taxi and car rental services." />
    <meta property="og:url" content="https://saitirthatravels.com/shirdi-to-5-jyotirlinga-package" />
    <meta property="og:image" content="https://saitirthatravels.com/images/shirdi-to-5-jyotirlinga-package.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Book your Shirdi to 5 Jyotirlinga tour package with Saitirtha Travels. Visit Somnath, Mallikarjuna, Mahakaleshwar, Omkareshwar, and Kedarnath with affordable taxi and car rental services.",
            "url": "https://saitirthatravels.com/shirdi-to-5-jyotirlinga-package",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/shirdi-to-5-jyotirlinga-package.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi to 5 Jyotirlinga Package",
                "Shirdi to Somnath Cabs",
                "Mallikarjuna Jyotirlinga Tour",
                "Mahakaleshwar Jyotirlinga Tour",
                "Omkareshwar Jyotirlinga",
                "Kedarnath Jyotirlinga Package",
                "Shirdi to 5 Jyotirlinga tour",
                "Best Shirdi to Jyotirlinga tour package",
                "Shirdi to 5 Jyotirlinga darshan",
                "Shirdi to Jyotirlinga yatra",
                "Shirdi to Somnath Mallikarjuna Mahakaleshwar Omkareshwar Kedarnath taxi",
                "Shirdi to 5 Jyotirlinga pilgrimage",
                "Shirdi to 5 Jyotirlinga car rental",
                "5 Jyotirlinga in Maharashtra tour package",
                "Shirdi to Bhimashankar Cab",
                "Shirdi to Grishneshwar Cab",
                "Shirdi to Vaijnath Cab Booking",
                "Shirdi to Trimbakeshwar Cab",
                "Shirdi to Aundha Nagnath taxi fare",
                "5 Jyotirlinga package from Shirdi"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/61.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirditojyotilinga;