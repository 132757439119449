
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Shirditosurat() {



  const cardData =
  {
    keyword: ' Shirdi to Surat Cab',
    heading: 'Saitirtha Travels: Shirdi to Surat Cab',
    headingDescription: 'Traveling from Shirdi to Surat has never been easier with Saitirtha Travels. We provide reliable and comfortable cab services to ensure your journey is smooth and enjoyable. Whether you are heading for business or leisure, our dedicated team is here to meet your travel needs.',

    top: 'Top Places to Visit in Surat with Saitirtha Travels',

 "topPlaces": [
    {
        "title": "Sardar Patel Museum",
        "description": "Sardar Patel Museum is a prominent cultural institution that showcases the rich history and heritage of Surat. The museum is home to a diverse collection of artifacts, paintings, and historical exhibits that chronicle the city’s evolution from a bustling trading port to a modern urban center. Visitors can explore displays featuring traditional textiles, ancient manuscripts, and artworks that reflect Surat's vibrant culture. Educational programs and guided tours enhance the experience, making it an essential stop for history buffs and anyone interested in the intricate tapestry of Surat's past."
    },
    {
        "title": "Surat Castle",
        "description": "Constructed in the 1500s to safeguard the city from invasions, Surat Castle is an architectural gem that reflects the military prowess of the time. The castle's imposing walls and strategic design offer visitors a glimpse into the historical importance of Surat as a key trading hub. As you wander through the fort, you’ll encounter well-preserved structures and can enjoy breathtaking views of the Tapi River and the surrounding landscape. The site is not only significant for its history but also provides an excellent backdrop for photography, especially during sunset, making it a must-visit for both history enthusiasts and casual tourists."
    },
    {
        "title": "Jalaram Bapa Temple",
        "description": "Jalaram Bapa Temple is a revered site dedicated to the saint Jalaram Bapa, known for his compassion and service to humanity. The temple features stunning architecture that blends traditional and contemporary styles, creating a peaceful environment for worship. Inside, devotees can witness the sacred rituals and partake in the daily prayers that resonate with the spiritual energy of the place. The temple’s atmosphere is enhanced by the chanting of bhajans and the fragrant offerings of flowers and sweets. This site not only attracts local devotees but also pilgrims from far and wide, making it a significant spiritual destination."
    },
    {
        "title": "Gopi Talav",
        "description": "Gopi Talav is a picturesque lake surrounded by lush greenery and vibrant gardens, making it an ideal retreat for relaxation and recreation. Visitors can engage in a variety of activities, such as boat rides on the tranquil waters or leisurely strolls along the scenic pathways. The area is dotted with seating arrangements perfect for picnics, and local vendors offer a variety of snacks, enhancing the overall experience. Families and friends can gather here to enjoy the natural beauty and serenity of the lake, making it a popular spot for outings and evening relaxation."
    },
    {
        "title": "Dutch, Armenian, and English Cemetery",
        "description": "The Dutch, Armenian, and English Cemetery in Surat stands as a historical testament to the city’s diverse cultural influences and its significant role in international trade. This cemetery is the final resting place of many notable merchants and traders from different backgrounds, with beautifully carved tombstones that tell stories of their lives. Visitors can wander through the cemetery, appreciating the intricate designs and inscriptions, while learning about the multicultural history of Surat. This quiet, reflective space offers a unique perspective on the city’s past and its connections to various global communities."
    },
    {
        "title": "ISKCON Temple",
        "description": "The ISKCON Temple in Surat is a beautifully designed spiritual center dedicated to Lord Krishna. This temple not only serves as a place of worship but also hosts a range of community activities, including cultural events, festivals, and educational programs about Hindu philosophy. The serene environment, coupled with the temple's vibrant decorations and intricate artwork, creates a captivating atmosphere for visitors. Attending the daily aarti is a profound experience, as the melodic chants and the aromatic offerings enhance the spiritual ambiance. The temple's restaurant serves delicious vegetarian meals, allowing visitors to enjoy the flavors of traditional Indian cuisine."
    },
    {
        "title": "Sarthana Nature Park",
        "description": "Sarthana Nature Park is a lush green oasis that offers a serene escape for nature lovers and families alike. The park features well-maintained walking trails, diverse plant species, and a mini zoo showcasing various animals. It's an ideal place for children to learn about wildlife and for families to enjoy a day of outdoor activities. The peaceful environment invites visitors to relax, picnic, or simply soak in the beauty of nature. The park often hosts educational programs and nature workshops, making it a great spot for both recreation and learning."
    },
    {
        "title": "Dumas Beach",
        "description": "Dumas Beach, located just a short drive from Surat, is famed for its unique black sand and captivating sunsets. The beach is a popular destination for locals and tourists looking to unwind and enjoy the coastal scenery. Visitors can engage in various activities, from beach sports to enjoying local delicacies from the food stalls lining the shore. Evening strolls along the beach offer a chance to witness breathtaking sunset views, making it a perfect romantic getaway or a fun outing with family and friends. The beach's vibrant atmosphere, combined with the soothing sound of the waves, creates an unforgettable experience."
    },
    {
        "title": "Textile Market",
        "description": "Surat's textile market is a vibrant hub that showcases the city’s reputation as a leading center for textiles in India. The market is filled with an array of shops offering a vast selection of fabrics, sarees, and garments at competitive prices. Visitors can explore the bustling stalls, where local artisans display their crafts, and take part in the lively shopping experience. The market not only provides an opportunity to purchase exquisite textiles but also allows visitors to witness the craftsmanship that goes into each piece. Bargaining is common, adding to the excitement of the shopping adventure."
    },
    {
        "title": "Gopi Gali",
        "description": "Gopi Gali is a bustling street market renowned for its vibrant atmosphere, delectable street food, and shopping opportunities. This lively market is a great place to immerse yourself in the local culture, offering everything from traditional snacks to handmade crafts. Visitors can sample a variety of local delicacies, including savory snacks and sweet treats, making it a culinary adventure. The vibrant colors, sounds, and scents of Gopi Gali create an exciting environment, making it a must-visit for anyone looking to experience the flavors and culture of Surat. It’s a perfect spot for socializing and enjoying the local lifestyle."
    }
]
,
    
            
        
"services": [
    {
        "name": "Shirdi to Surat Cab",
        "description": "Traveling from Shirdi to Surat? Our Shirdi to Surat cab service offers a comfortable and convenient way to reach your destination. With a focus on customer satisfaction and reliable transportation, we ensure a smooth journey for all our passengers. Enjoy the scenic beauty along the way, as our experienced drivers take you through well-maintained routes, making your trip not just a journey but an experience to cherish."
    },
    {
        "name": "Shirdi to Surat Cab Service",
        "description": "Our Shirdi to Surat cab service is designed to cater to your travel needs with flexibility and convenience. Whether you're heading for business, leisure, or a pilgrimage, we have the perfect vehicle to accommodate your journey. With options for various group sizes, we guarantee a pleasant ride with a strong emphasis on safety and comfort, allowing you to relax and enjoy your travel."
    },
    {
        "name": "Shirdi Surat Taxi Fare",
        "description": "We offer competitive taxi fares from Shirdi to Surat without compromising on quality. Our transparent pricing ensures that you are informed of all costs upfront, allowing you to budget your trip effectively. With no hidden charges, our pricing structure is designed to provide you with the best value for your money, making travel accessible and straightforward."
    },
    {
        "name": "Best Cab from Shirdi to Surat",
        "description": "Looking for the best cab from Shirdi to Surat? Look no further! Our fleet consists of well-maintained vehicles, ensuring a safe and comfortable ride. Our experienced drivers are knowledgeable about the route, providing you with a pleasant travel experience. Each vehicle is equipped with modern amenities, including air conditioning and spacious seating, to enhance your comfort throughout the journey."
    },
    {
        "name": "Cheap Taxi Shirdi Surat",
        "description": "We understand the importance of affordability. That’s why we offer cheap taxi services from Shirdi to Surat. Enjoy quality service at reasonable prices, making your travel budget-friendly without compromising on comfort. We believe everyone should have access to reliable transportation, and our economical rates reflect that commitment."
    },
    {
        "name": "Book Cab Shirdi Surat",
        "description": "Booking a cab from Shirdi to Surat is quick and easy. Our online reservation system allows you to select your vehicle, confirm your booking, and receive instant confirmation. You can also reach out to our customer support team for assistance with your booking, ensuring a seamless experience. We’re here to make your journey as smooth as possible, from the moment you book to your final destination."
    },
    {
        "name": "One-Way Taxi Shirdi to Surat",
        "description": "If you're looking for a one-way taxi from Shirdi to Surat, we provide flexible options to suit your needs. Enjoy a hassle-free journey without the need for a round trip, allowing you to travel at your convenience. This service is ideal for those who need direct transportation to their destination without the hassle of returning, providing a straightforward travel solution."
    },
    {
        "name": "Round Trip Cab Shirdi Surat",
        "description": "For those needing a return journey, our round trip cab service from Shirdi to Surat is ideal. Experience the comfort of traveling in a private cab with the option to return at a time that suits you. Our round trip service offers the flexibility of planning your travel itinerary according to your schedule, making it easier for you to enjoy your visit."
    },
    {
        "name": "Car Rental Shirdi to Surat",
        "description": "Our car rental services from Shirdi to Surat allow you to have complete control over your travel itinerary. Choose from our range of vehicles to find the best fit for your journey, whether it's for a day trip or an extended stay. Our vehicles are well-maintained and equipped with the necessary features for a comfortable travel experience, ensuring you can explore at your own pace."
    },
    {
        "name": "Reliable Cab Shirdi Surat",
        "description": "Reliability is our priority. Our reliable cab service from Shirdi to Surat ensures that your driver will arrive on time, providing you with peace of mind as you travel to your destination comfortably and safely. Our commitment to punctuality means you can trust us to get you where you need to go without delays."
    },
    {
        "name": "Distance Shirdi Surat Taxi",
        "description": "The distance from Shirdi to Surat is approximately 270 kilometers, which makes for a manageable journey. Our drivers are familiar with the route, ensuring a smooth ride with minimal delays, allowing you to reach your destination efficiently. We aim to make your journey enjoyable, providing insights and recommendations for stops along the way."
    },
    {
        "name": "Shirdi to Surat Cab Price",
        "description": "The Shirdi to Surat cab price is determined by various factors, including the type of vehicle you choose and the duration of your trip. Contact us for a personalized quote based on your specific requirements. We ensure that our pricing is transparent, so you know exactly what you are paying for."
    },
    {
        "name": "Shirdi to Surat Tempo Traveller on Rent",
        "description": "Traveling with a larger group? Our Shirdi to Surat Tempo Traveller on rent is an excellent choice. With ample seating and luggage space, it's perfect for family trips, group outings, or corporate events. Enjoy the journey together, creating memories along the way, without the stress of coordinating multiple vehicles."
    },
    {
        "name": "Shirdi to Surat Innova Cab",
        "description": "For a more comfortable travel experience, consider our Shirdi to Surat Innova cab. Known for its spacious interiors and smooth ride, the Innova is ideal for long journeys, providing both comfort and luxury for you and your companions. Relax in style as you travel to your destination."
    },
    {
        "name": "Shirdi to Surat Ertiga Cab",
        "description": "We also offer the Shirdi to Surat Ertiga cab, which is perfect for families or groups seeking a compact yet spacious vehicle. Enjoy a comfortable journey without feeling cramped, ensuring a pleasant experience for all passengers."
    },
    {
        "name": "Shirdi to Surat One Way Taxi",
        "description": "Our one-way taxi service from Shirdi to Surat is tailored for travelers who need a simple, straightforward transportation option. Enjoy a seamless ride without worrying about return travel arrangements, allowing you to focus on your plans and enjoy your time in Surat."
    },
    {
        "name": "Shirdi to Surat Cab Packages",
        "description": "Take advantage of our Shirdi to Surat cab packages, which offer great value for your money. These packages often include special features, such as sightseeing stops, ensuring you get the most out of your trip while enjoying the scenic beauty along the way. Our goal is to enhance your travel experience with additional perks."
    },
    {
        "name": "Shirdi to Gujarat Cab Packages",
        "description": "Planning to explore more of Gujarat? Our Shirdi to Gujarat cab packages offer comprehensive travel solutions, including routes to popular destinations within the state. Enjoy the flexibility of customizing your journey according to your interests and preferences, making it a personalized adventure."
    },
    {
        "name": "Shirdi to Surat Mini Bus on Rent",
        "description": "For larger groups, we offer a mini bus on rent from Shirdi to Surat. Ideal for school trips, family gatherings, or corporate events, our mini buses provide comfortable seating and ample storage for luggage. Travel together with ease, enjoying the convenience of group travel."
    },
    {
        "name": "Contact Information",
        "description": "For bookings and inquiries, contact SaitirthaTours & Travels at +91 9923879009 / 9922479009. Experience a hassle-free and enjoyable ride with our Shirdi to Surat cab services. Our team is ready to assist you in planning your travel, ensuring a memorable experience tailored to your needs."
    }
]
,
"tableData": [
    ["- Shirdi to Surat Cab Service", "- Shirdi Surat Taxi Fare"],
    ["- Best Cab from Shirdi to Surat", "- Cheap Taxi Shirdi Surat"],
    ["- Book Cab Shirdi Surat", "- One-Way Taxi Shirdi to Surat"],
    ["- Round Trip Cab Shirdi Surat", "- Car Rental Shirdi to Surat"],
    ["- Reliable Cab Shirdi Surat", "- Distance Shirdi Surat Taxi"],
    ["- Shirdi to Surat Cab Price", "- Shirdi to Surat Tempo Traveller on Rent"],
    ["- Shirdi to Surat Innova Cab", "- Shirdi to Surat Ertiga Cab"],
    ["- Shirdi to Surat One Way Taxi", "- Shirdi to Surat Cab Packages"],
    ["- Shirdi to Gujarat Cab Packages", "- Shirdi to Surat Mini Bus on Rent"],
    ["- SaitirthaTours & Travels Shirdi"]
],
"whychoose": [
    {
        "WhyChooseheading": "Convenient Booking Options:",
        "WhyChoosedescription": "We understand that planning a trip can often be stressful, which is why we offer hassle-free booking options. You can easily arrange your travel through our user-friendly website or by calling our customer service team. Our system is designed to ensure a quick and straightforward process, allowing you to secure your cab service from Shirdi to Surat with just a few clicks or a simple phone call."
    },
    {
        "WhyChooseheading": "Comfortable Rides:",
        "WhyChoosedescription": "Travel in utmost comfort with our fleet of well-maintained cabs, which feature plush seating and modern air conditioning. Our vehicles are equipped to enhance your travel experience, providing a smooth ride that allows you to relax and enjoy the scenic journey from Shirdi to Surat. We prioritize your comfort, ensuring that every ride is a pleasurable experience."
    },
    {
        "WhyChooseheading": "Professional Drivers:",
        "WhyChoosedescription": "Our drivers are not only experienced but also possess extensive knowledge of the best routes between Shirdi and Surat. They are trained to provide a safe, efficient, and friendly service throughout your journey. Their familiarity with local traffic patterns and road conditions ensures a smooth ride, allowing you to sit back and enjoy the trip while being assured of your safety."
    },
    {
        "WhyChooseheading": "Affordable Pricing:",
        "WhyChoosedescription": "We pride ourselves on offering competitive rates for our cab services, making travel affordable for everyone. Our transparent pricing model ensures that there are no hidden fees, and you receive excellent value without compromising on quality. You can enjoy a reliable transportation service that fits your budget, allowing you to plan your expenses with confidence."
    },
    {
        "WhyChooseheading": "Flexible Travel Plans:",
        "WhyChoosedescription": "Recognizing that every traveler has unique needs, we offer flexible travel plans. Whether you need a one-way trip or a round trip, we can accommodate your schedule and preferences. Our customizable travel options ensure that you have control over your journey, making it convenient for you to adjust your plans as necessary while still enjoying a seamless travel experience."
    },
    {
        "WhyChooseheading": "24/7 Customer Support:",
        "WhyChoosedescription": "Our dedicated customer service team is available around the clock to assist you with any inquiries or concerns regarding your booking. Whether you have questions about our services, need to modify your itinerary, or require immediate assistance during your journey, our team is always ready to provide prompt and helpful support, ensuring you have a worry-free travel experience."
    },
    {
        "WhyChooseheading": "Hygiene and Safety Standards:",
        "WhyChoosedescription": "We prioritize your health and safety by adhering to stringent hygiene protocols. Our vehicles are regularly sanitized, and we follow health guidelines to ensure a safe journey for all passengers. You can travel with confidence, knowing that your well-being is our top priority and that we take every measure to provide a clean and safe environment during your travel."
    },
    {
        "WhyChooseheading": "Positive Customer Feedback:",
        "WhyChoosedescription": "Our commitment to delivering high-quality service has earned us a strong reputation for reliability and customer satisfaction. We value our customers' feedback and continuously strive to improve our services. The many positive reviews from our satisfied customers reflect our dedication to providing an excellent travel experience, and we take pride in our high customer retention rate."
    },
    {
        "WhyChooseheading": "Punctual Service:",
        "WhyChoosedescription": "We understand that timely travel is crucial for our customers. Our drivers are punctual and ensure that you reach your destination on time, no matter the circumstances. We monitor traffic conditions and plan routes accordingly to minimize delays, allowing you to travel without stress and arrive at your destination as scheduled."
    },
    {
        "WhyChooseheading": "Additional Services Available:",
        "WhyChoosedescription": "In addition to our standard cab services, we offer additional options tailored to your needs, such as luggage assistance or extra stops along your route. Our goal is to provide a personalized experience that meets all your travel requirements, ensuring that your journey is as comfortable and convenient as possible. Whether you need extra space for your luggage or wish to make additional stops, we are here to accommodate your requests."
    }
]



        
  }


  const faqData = [
    {
        question: "How do I book a cab from Shirdi to Surat?",
        answer: "You can book a cab easily through our website or by calling our customer support team for assistance."
    },
    {
        question: "What is the distance between Shirdi and Surat?",
        answer: "The distance is approximately 270 kilometers, and the journey typically takes around 5 to 6 hours by road."
    },
    {
        question: "Is the fare inclusive of tolls and taxes?",
        answer: "Yes, the fare includes all tolls and taxes. We aim for transparency in our pricing."
    },
    {
        question: "Can I modify my booking after confirmation?",
        answer: "Yes, you can modify your booking by contacting our customer support team before your scheduled travel date."
    },
    {
        question: "What types of vehicles do you offer for the Shirdi to Surat route?",
        answer: "We offer a range of vehicles, including sedans, SUVs, and luxury cars to suit your travel needs."
    },
    {
        question: "Are your drivers experienced?",
        answer: "Yes, all our drivers are licensed, experienced, and trained to provide safe and comfortable journeys."
    },
    {
        question: "What payment methods do you accept?",
        answer: "We accept various payment methods, including credit/debit cards, UPI, and cash for your convenience."
    },
    {
        question: "Is customer support available at all times?",
        answer: "Yes, our customer support team is available 24/7 to assist you with any inquiries or issues."
    },
    {
        question: "Can I book a cab for a round trip?",
        answer: "Yes, you can book a cab for a round trip from Shirdi to Surat and back at your convenience."
    },
    {
        question: "What if my flight is delayed?",
        answer: "Our drivers monitor flight schedules and will adjust pickup times accordingly to ensure you are accommodated."
    }
];

const testimonials = [
    {
        name: "Mr. Anil Kumar",
        text: "I recently traveled from Shirdi to Surat with Saitirtha Travels, and the experience was excellent. The cab was clean and comfortable, and our driver was very professional. He ensured we had a smooth journey, and I felt safe throughout. Highly recommend this service!"
    },
    {
        name: "Mrs. Neha Patil",
        text: "Saitirtha Travels made our trip from Shirdi to Surat hassle-free! The booking process was straightforward, and the driver arrived on time. He was courteous and knowledgeable about the route. I will definitely choose them again for future travel!"
    }
];



  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Shirdi to Surat Cab Service | Affordable Taxi Service | SaitirthaTours & Travels</title>
    <meta name="description" content="Book reliable and affordable cab services from Shirdi to Surat. One-way and round-trip options, including Innova, Ertiga, Tempo Traveller & Mini Bus rentals. Book now!" />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirtha Travels | Shirdi to Surat Cab Service | Affordable Taxi Service | SaitirthaTours & Travels" />
    <meta property="og:description" content="Book reliable and affordable cab services from Shirdi to Surat. One-way and round-trip options, including Innova, Ertiga, Tempo Traveller & Mini Bus rentals. Book now!" />
    <meta property="og:url" content="https://saitirthatravels.com/shirdi-to-surat-cab" />
    <meta property="og:image" content="https://saitirthatravels.com/images/shirdi-to-surat-cab.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Book reliable and affordable cab services from Shirdi to Surat. One-way and round-trip options, including Innova, Ertiga, Tempo Traveller & Mini Bus rentals. Book now!",
            "url": "https://saitirthatravels.com/shirdi-to-surat-cab",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/shirdi-to-surat-cab.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "180"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi to Surat cab service",
                "Shirdi Surat taxi fare",
                "Best cab from Shirdi to Surat",
                "Cheap taxi Shirdi Surat",
                "Book cab Shirdi Surat",
                "One-way taxi Shirdi to Surat",
                "Round trip cab Shirdi Surat",
                "Car rental Shirdi to Surat",
                "Reliable cab Shirdi Surat",
                "Distance Shirdi Surat taxi",
                "Shirdi to Surat cab price",
                "Shirdi to Surat Tempo Traveller on rent",
                "Shirdi to Surat Innova Cab",
                "Shirdi to Surat Ertiga Cab",
                "Shirdi to Surat One way taxi",
                "Shirdi to Surat cab packages",
                "Shirdi to Gujarat cab packages",
                "Shirdi to Surat mini bus on rent",
                "SaitirthaTours & Travels Shirdi"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/52.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirditosurat;