
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Shirditoujjaincabbooking() {



  const cardData =
  {
    keyword: ' Shirdi to Ujjain Cab Booking',
    heading: 'Saitirtha Travels: Shirdi to Ujjain Cab Booking',
    headingDescription: 'Travel comfortably from Shirdi to Ujjain with Saitirtha Travels, offering reliable cab services designed for a smooth and enjoyable journey. This route takes you through scenic landscapes and ensures you reach Ujjain, one of India’s most revered spiritual cities, without hassle. Whether you’re traveling for pilgrimage or exploration, our cab service guarantees a safe and comfortable ride.',

    top: 'Top Places to Visit in Ujjain with Saitirtha Travels',

    topPlaces: [
        {
            "title": "Mahakaleshwar Temple",
            "description": "A renowned Jyotirlinga shrine, the Mahakaleshwar Temple is one of the holiest Shiva temples in India. Known for its ancient architecture and divine aura, this temple attracts devotees from all over, especially for the famous Bhasma Aarti held at dawn."
        },
        {
            "title": "Kal Bhairav Temple",
            "description": "Dedicated to Kal Bhairav, an incarnation of Lord Shiva, this unique temple is known for its unusual ritual of offering liquor. The temple has a fascinating history and holds a special place in the hearts of devotees."
        },
        {
            "title": "Ram Ghat",
            "description": "Located on the banks of the Kshipra River, Ram Ghat is a sacred spot where pilgrims take holy dips. It’s also a venue for the famous Kumbh Mela, and visitors can enjoy serene boat rides along the river."
        },
        {
            "title": "Harsiddhi Temple",
            "description": "This ancient temple is dedicated to Goddess Harsiddhi and features striking Maratha-style architecture with unique pillars adorned with oil lamps. The temple’s ambiance, especially during evening prayers, adds to its spiritual appeal."
        },
        {
            "title": "Chintaman Ganesh Temple",
            "description": "A revered temple dedicated to Lord Ganesha, known as the deity who removes obstacles. This temple is visited by those seeking blessings for prosperity and success, and it holds significance for both devotees and tourists alike."
        },
        {
            "title": "Bade Ganeshji Ka Mandir",
            "description": "Located near Mahakaleshwar Temple, this temple houses a large idol of Lord Ganesha, along with representations of other deities. It's a popular spot for seeking blessings and is known for its peaceful setting."
        },
        {
            "title": "Pir Matsyendranath",
            "description": "An ancient shrine dedicated to Pir Matsyendranath, an important figure in Nath tradition, located along the banks of the Kshipra River. It’s a quiet and spiritually significant site with beautiful surroundings."
        },
        {
            "title": "Sandipani Ashram",
            "description": "Known as the ashram where Lord Krishna and Sudama were educated, this historical site is associated with Guru Sandipani. It’s a peaceful and enlightening place with links to the Mahabharata era."
        },
        {
            "title": "ISKCON Ujjain",
            "description": "A beautifully designed temple dedicated to Lord Krishna, featuring ornate architecture and peaceful gardens. The temple hosts various religious and cultural events and is a great place for meditation and reflection."
        },
        {
            "title": "Vikram Kirti Mandir Museum",
            "description": "A cultural museum dedicated to King Vikramaditya, showcasing exhibits on ancient Indian history, literature, and art. It offers a fascinating glimpse into Ujjain’s rich cultural heritage."
        }
    ],    
    
            
        
"services": [
    {
        "name": "Shirdi to Ujjain Cab",
        "description": "Our Shirdi to Ujjain Cab service provides a comfortable and reliable way to reach one of the most significant pilgrimage sites in India. With well-maintained vehicles and experienced drivers who ensure your safety and comfort throughout the journey, you can focus on the spiritual experience awaiting you in Ujjain. We offer flexible schedules, ensuring that your trip is as convenient as possible, whether you are traveling alone, with family, or in a group."
    },
    {
        "name": "Shirdi to Ujjain Cab Jyotirlinga Tour Package",
        "description": "Experience the divine with our Shirdi to Ujjain Cab Jyotirlinga Tour Package. This package includes a visit to the Mahakaleshwar Temple, one of the 12 Jyotirlingas in India, along with other important religious sites in Ujjain. Immerse yourself in the spiritual atmosphere of the city as you explore sacred temples, ghats, and other divine landmarks. With seamless transportation and knowledgeable guides, this package is designed to give you a complete and enriching spiritual experience."
    },
    {
        "name": "Shirdi to Mahakaleshwar Darshan",
        "description": "The Shirdi to Mahakaleshwar Darshan package is tailored for those looking to visit the Mahakaleshwar Temple, a revered Jyotirlinga site. Our package ensures that you have seamless transport to and from the temple, so you can focus entirely on your worship and exploration. With ample time to visit the temple, engage in prayer, and enjoy the spiritual environment, this package offers a hassle-free and enriching pilgrimage experience."
    },
    {
        "name": "Shirdi to Ujjain Tempo Traveller on Rent",
        "description": "For larger groups or families, we offer Shirdi to Ujjain Tempo Traveller on Rent. Our spacious and comfortable tempo travellers can accommodate multiple passengers, ensuring everyone travels together comfortably. With enough space for luggage and relaxed seating, your group can enjoy a pleasant journey while visiting the divine temples and attractions in Ujjain. This service is ideal for those looking to travel together without compromising on comfort."
    },
    {
        "name": "Ahmednagar to Ujjain Car Rental",
        "description": "We provide convenient Ahmednagar to Ujjain car rental services for travelers coming from different regions. Whether you're planning a solo trip or traveling with family or friends, our car rental service ensures a smooth, stress-free journey. With competitive rates and reliable service, you can enjoy the scenic route from Ahmednagar to Ujjain while focusing on your spiritual journey."
    },
    {
        "name": "Shirdi Airport to Ujjain Taxi Fare",
        "description": "Planning your trip from the airport? Our Shirdi Airport to Ujjain taxi fare is affordable, offering you a convenient and comfortable ride directly from the airport to your destination. Our professional drivers are familiar with the best routes to Ujjain, ensuring a smooth transition from your flight to your pilgrimage experience, all at a competitive price with no hidden fees."
    },
    {
        "name": "Shirdi to Omkareshwar Cab Booking",
        "description": "Extend your pilgrimage with our Shirdi to Omkareshwar Cab Booking service. Visit another sacred Jyotirlinga site, Omkareshwar, and make the most of your spiritual journey through India. Our experienced drivers ensure a safe and comfortable ride, and with our flexible booking options, you can visit Omkareshwar at your own pace, soaking in the serenity of this sacred temple town."
    },
    {
        "name": "Shirdi to Gujarat Jyotirlinga Tour Package",
        "description": "Our Shirdi to Gujarat Jyotirlinga Tour Package includes visits to several Jyotirlingas in Gujarat, offering you a comprehensive spiritual experience across states. This carefully designed itinerary will take you to the most significant Jyotirlingas, including Somnath and Dwarka, allowing you to experience the divine in various forms. With well-planned transport, accommodations, and guide services, this tour is the perfect spiritual journey."
    },
    {
        "name": "Shirdi to Indore Taxi Service",
        "description": "If you're looking to visit Indore after Ujjain, our Shirdi to Indore Taxi Service is available for your convenience. Travel comfortably in a well-maintained vehicle with an experienced driver. Indore, with its rich culture, history, and cuisine, offers a perfect addition to your trip. Enjoy a seamless travel experience as you explore this vibrant city in Madhya Pradesh."
    },
    {
        "name": "Shirdi to Ujjain Cab Contact Information",
        "description": "To book your Shirdi to Ujjain Cab or for any inquiries regarding our services, please contact SaitirthaTours & Travels at +91 9923879009 / 9922479009. Our dedicated team is here to ensure your journey is smooth, safe, and spiritually fulfilling. We offer personalized assistance and flexible booking options to meet your travel needs, ensuring a memorable pilgrimage experience."
    }
],


"tableData": [
    ["- Shirdi to Ujjain Cab", "- Shirdi to Ujjain Cab Jyotirlinga Tour Package"],
    ["- Shirdi to Ujjain Jyotirlinga Tour Package", "- Shirdi to Mahakaleshwar Darshan"],
    ["- Shirdi to Ujjain Tempo Traveller on Rent", "- Ahmednagar to Ujjain Car Rental"],
    ["- Shirdi Airport to Ujjain Taxi Fare", "- Shirdi to Omkareshwar Cab Booking"],
    ["- Shirdi to Gujarat Jyotirlinga Tour Package", "- Shirdi to Indore Taxi Service"],
    ["", ""],
    ["- SaitirthaTours & Travels Shirdi", ""]
  ],
  "whychoose": [
    {
      "WhyChooseheading": "Spiritual Journey",
      "WhyChoosedescription": "Travel from Shirdi, the abode of Sai Baba, to Ujjain, one of the seven Moksha Puris in India, known for its rich cultural heritage and religious significance, particularly the Mahakaleshwar Jyotirlinga. This journey allows you to immerse yourself in the spiritual ambiance of Ujjain, where ancient traditions and rituals blend seamlessly with everyday life, providing a unique opportunity to enhance your spiritual connection."
    },
    {
      "WhyChooseheading": "Comfortable Rides",
      "WhyChoosedescription": "Enjoy a comfortable ride in our well-maintained, air-conditioned vehicles, ensuring you arrive at your destination feeling relaxed and refreshed. Our fleet is designed with passenger comfort in mind, offering spacious seating and modern amenities, allowing you to sit back and enjoy the scenic beauty of your surroundings during the journey."
    },
    {
      "WhyChooseheading": "Experienced Drivers",
      "WhyChoosedescription": "Our professional drivers are knowledgeable about the routes and local attractions, providing a safe and smooth travel experience while sharing insights about your journey. They are committed to ensuring your safety and comfort, and their familiarity with the roads means you can trust them to navigate efficiently while providing engaging commentary along the way."
    },
    {
      "WhyChooseheading": "Flexible Booking Options",
      "WhyChoosedescription": "We offer a range of booking options tailored to your needs, whether it's a one-way trip or a round trip to Ujjain, ensuring your travel plans are convenient. Our online booking system is user-friendly, making it easy for you to secure your trip in just a few clicks. You can also modify your bookings easily to accommodate any changes in your plans."
    },
    {
      "WhyChooseheading": "Affordable Rates",
      "WhyChoosedescription": "Our competitive pricing ensures you receive excellent value for your journey without compromising on quality. Transparent pricing means no hidden fees, allowing you to budget effectively for your travel expenses. We believe in providing high-quality service at prices that are accessible for everyone, making your spiritual journey both fulfilling and economical."
    },
    {
      "WhyChooseheading": "Customer Satisfaction",
      "WhyChoosedescription": "Our commitment to excellent service has earned us a loyal customer base. We focus on your needs, making your travel experience seamless and enjoyable. We take the time to listen to your feedback and continuously improve our services, striving to exceed your expectations and make your journey as smooth as possible."
    },
    {
      "WhyChooseheading": "24/7 Support",
      "WhyChoosedescription": "Our customer support team is available around the clock to assist with bookings, answer queries, or provide support during your trip, ensuring peace of mind. Whether you need assistance planning your itinerary or have questions while on the road, our dedicated team is here to help you at any hour, providing reassurance throughout your travels."
    },
    {
      "WhyChooseheading": "Cultural Exploration",
      "WhyChoosedescription": "Take the opportunity to explore the beautiful temples and sites in Ujjain, such as the Mahakaleshwar Temple, Harsiddhi Temple, and the Kshipra River, adding depth to your spiritual journey. Our packages often include guided tours of these iconic locations, allowing you to appreciate their historical significance and architectural beauty while learning about the local customs and traditions."
    },
    {
      "WhyChooseheading": "Positive Reviews",
      "WhyChoosedescription": "Many satisfied customers have praised our reliable service, highlighting their enjoyable travel experiences with Saitirtha Travels. Our dedication to providing excellent service is reflected in the positive feedback we receive, encouraging us to maintain high standards and continually improve our offerings."
    },
    {
      "WhyChooseheading": "Customizable Packages",
      "WhyChoosedescription": "We can customize your itinerary based on your interests, whether you wish to explore more historical sites or simply enjoy the scenic views along the way. Our goal is to create a personalized travel experience that reflects your preferences and enhances your journey, making each trip unique and memorable."
    }
  ]





        
  }


  const faqData = [
    {
        question: "How far is Ujjain from Shirdi?",
        answer: "Ujjain is approximately 450 kilometers from Shirdi, and the journey typically takes around 8 to 10 hours by road, depending on traffic conditions."
    },
    {
        question: "What type of vehicle can I book?",
        answer: "We offer a variety of vehicles, including sedans, SUVs, and luxury cars, suitable for different group sizes and preferences."
    },
    {
        question: "Can I book a one-way trip?",
        answer: "Yes, we provide options for both one-way and round-trip bookings for your convenience."
    },
    {
        question: "Are toll charges included in the fare?",
        answer: "Toll charges are typically not included in the initial fare and will be charged as per actuals during the trip."
    },
    {
        question: "Is it possible to stop for sightseeing along the way?",
        answer: "Absolutely! You can customize your itinerary to include sightseeing stops at various attractions en route."
    },
    {
        question: "What payment methods do you accept?",
        answer: "We accept various payment methods, including online payments, cash, and bank transfers. Details will be provided during booking."
    },
    {
        question: "What happens if I need to cancel my booking?",
        answer: "Our cancellation policy is flexible. Please contact our customer service for specific details regarding cancellations and any applicable fees."
    },
    {
        question: "Is there an option for a return trip?",
        answer: "Yes, you can book a return trip from Ujjain to Shirdi as part of your package."
    },
    {
        question: "Do you offer additional services like guided tours?",
        answer: "We can arrange guided tours in Ujjain for a more enriching experience during your visit."
    },
    {
        question: "How do I contact customer support during my trip?",
        answer: "You can reach our customer support via the provided mobile numbers or email for any assistance you may need during your journey."
    }
];

const testimonials = [
    {
        name: "Mr. Ramesh Patil",
        text: "Our trip from Shirdi to Ujjain with Saitirtha Travels was absolutely wonderful! The driver was knowledgeable and friendly, making our journey enjoyable. The temples in Ujjain were breathtaking, and everything was well-organized. I highly recommend this service!"
    },
    {
        name: "Mrs. Kavita Sharma",
        text: "I booked a cab for our family trip to Ujjain from Shirdi, and it was a fantastic experience. The car was comfortable, and our driver took great care of us. We loved visiting the Mahakaleshwar Temple and exploring the city. Thank you, Saitirtha Travels, for a memorable journey!"
    }
];





  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
<Helmet>
    <title>Contact: +91 9923879009 | SaitirthaTours & Travels | Shirdi to Ujjain Cab Booking | Best Taxi Service | SaitirthaTours & Travels</title>
    <meta name="description" content="Book an affordable Shirdi to Ujjain Cab with SaitirthaTours & Travels. Offering one-way, round-trip, and rental services for your journey from Shirdi to Ujjain, Mahakaleshwar Darshan, and other Jyotirlinga tour packages." />
    <meta property="og:title" content="Contact: +91 9923879009 | SaitirthaTours & Travels | Shirdi to Ujjain Cab Booking | Best Taxi Service | SaitirthaTours & Travels" />
    <meta property="og:description" content="Book an affordable Shirdi to Ujjain Cab with SaitirthaTours & Travels. Offering one-way, round-trip, and rental services for your journey from Shirdi to Ujjain, Mahakaleshwar Darshan, and other Jyotirlinga tour packages." />
    <meta property="og:url" content="https://saitirthatravels.com/shirdi-to-ujjain-cab-booking" />
    <meta property="og:image" content="https://saitirthatravels.com/images/shirdi-to-ujjain-cab-booking.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Book an affordable Shirdi to Ujjain Cab with SaitirthaTours & Travels. Offering one-way, round-trip, and rental services for your journey from Shirdi to Ujjain, Mahakaleshwar Darshan, and other Jyotirlinga tour packages.",
            "url": "https://saitirthatravels.com/shirdi-to-ujjain-cab-booking",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/shirdi-to-ujjain-cab-booking.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi to Ujjain Cab",
                "Shirdi to Ujjain Cab Jyotirlinga Tour Package",
                "Shirdi to Mahakaleshwar Darshan",
                "Shirdi to Ujjain Tempo Traveller on Rent",
                "Ahmednagar to Ujjain Car Rental",
                "Shirdi Airport to Ujjain Taxi Fare",
                "Shirdi to Omkareshwar Cab Booking",
                "Shirdi to Gujarat Jyotirlinga Tour Package",
                "Shirdi to Indore Taxi Service",
                "SaitirthaTours & Travels Shirdi"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/70.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirditoujjaincabbooking;