
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Kopargaontoshirdi() {



  const cardData =
  {
    keyword: 'Kopargaon to Shirdi Taxi',
    heading: 'Saitirtha Travels: Kopargaon to Shirdi Taxi',
    headingDescription: 'When traveling from Kopargaon to Shirdi, Saitirtha Travels is your ideal partner for a comfortable and reliable taxi service. Our mission is to provide a seamless travel experience that allows you to relax and enjoy the journey. With our professional drivers and well-maintained vehicles, you can expect a hassle-free ride to one of the most revered pilgrimage destinations in India. Our easy online booking system allows you to secure your taxi in advance, ensuring you can focus on your spiritual journey without worrying about transportation.',

    top: 'Top Places to Visit in Shirdi with Saitirtha Travels',

    "topPlaces": [
        {
            "title": "1. Shirdi Saibaba Temple",
            "description": "The Shirdi Saibaba Temple is the heart of Shirdi and one of the most visited pilgrimage sites in India. Dedicated to the revered saint Sai Baba, the temple complex is home to the iconic Samadhi Mandir, where Saibaba’s mortal remains are interred. The stunning architecture is complemented by intricate carvings and vibrant floral decorations, enhancing the spiritual ambiance. Visitors can participate in daily rituals and experience the serene atmosphere that attracts millions of devotees each year."
        },
        {
            "title": "2. Dwarkamai Masjid",
            "description": "Dwarkamai Masjid is a significant site closely associated with Sai Baba, where he spent much of his life providing solace to devotees. The masjid features the sacred Dhuni, a continuous fire that symbolizes selfless service and love. Visitors can reflect on Saibaba’s teachings while admiring the simple yet profound atmosphere. The presence of a beautiful stone statue of Saibaba and various artifacts from his life enrich the spiritual experience, making it a key stop for those seeking deeper connection."
        },
        {
            "title": "3. Chavadi",
            "description": "Chavadi is a historical site where Sai Baba rested every alternate night. This tranquil space features an idol of Saibaba dressed in traditional attire and showcases artifacts from his daily life, offering visitors a glimpse into his routines. The peaceful ambiance invites contemplation and reflection, making it an essential part of the pilgrimage for those wishing to feel the essence of Saibaba’s presence."
        },
        {
            "title": "4. Shri Sai Baba Sansthan Trust",
            "description": "The Shri Sai Baba Sansthan Trust manages the temple and engages in various charitable activities inspired by Saibaba’s teachings. Visitors can learn about the trust’s initiatives focusing on community welfare, healthcare, and education. Engaging with these activities offers a unique opportunity to witness how Saibaba’s teachings positively impact lives, encouraging visitors to contribute to meaningful causes."
        },
        {
            "title": "5. Lendi Baug",
            "description": "Lendi Baug is a beautifully landscaped garden created by Saibaba, providing a peaceful retreat for visitors. The garden features a well, a banyan tree, and a small temple, making it an excellent spot for relaxation and introspection. The lush greenery and vibrant flowers create an inviting atmosphere, ideal for meditation amidst nature, allowing visitors to connect with their inner peace."
        },
        {
            "title": "6. Samadhi Mandir",
            "description": "The Samadhi Mandir, where Saibaba's mortal remains rest, is an architectural marvel adorned with exquisite marble work and intricate designs. It serves as a serene environment for worship and reflection. Visitors can pay their respects at the Samadhi, experiencing a profound sense of devotion. The daily aarti held here is a spiritual highlight that uplifts the hearts of all who attend, creating a divine experience."
        },
        {
            "title": "7. Sai Heritage Village",
            "description": "Sai Heritage Village offers a captivating glimpse into the life and teachings of Saibaba through various exhibitions and displays. Visitors can explore replicas of significant locations from Saibaba's life, enhancing their understanding of his teachings. This engaging experience is particularly valuable for families, providing an insightful journey into the principles of love, faith, and service that Saibaba espoused."
        },
        {
            "title": "8. Khandoba Mandir",
            "description": "Khandoba Mandir, dedicated to Lord Khandoba, is an ancient temple located near Shirdi, rich in historical significance as a site where Saibaba was once a devotee. The temple's peaceful ambiance and beautiful architecture attract visitors seeking blessings, particularly lively during festivals, adding to its charm and spiritual significance."
        },
        {
            "title": "9. Gurusthan",
            "description": "Gurusthan is a sacred site believed to be where Saibaba first appeared in Shirdi. Visitors can find a stone idol of Saibaba beneath a tree, offering a serene environment for meditation and prayer. This site is often frequented by devotees seeking blessings, making it an essential part of the Shirdi pilgrimage, filled with spiritual energy and connection."
        },
        {
            "title": "10. Shri Saibaba Museum",
            "description": "The Shri Saibaba Museum showcases a collection of artifacts and personal belongings associated with Saibaba. Visitors can explore photographs, manuscripts, and items that provide insights into Saibaba's life and teachings. The museum serves as a valuable educational resource, highlighting the profound impact Saibaba had on his devotees and the rich history surrounding the Shirdi pilgrimage."
        }
    ],    
    
            
        
    
   "services": [
    {
        "name": "Shri Saibaba Museum",
        "description": "The Shri Saibaba Museum showcases a collection of artifacts and personal belongings associated with Sai Baba. Visitors can explore photographs, manuscripts, and items that provide insights into Sai Baba's life and teachings. The museum serves as a valuable educational resource, highlighting the profound impact Sai Baba had on his devotees and the rich history surrounding the Shirdi pilgrimage."
    },
    {
        "name": "Kopargaon to Shirdi Taxi Service",
        "description": "Traveling from Kopargaon to Shirdi is convenient with our reliable taxi service. SaitirthaTours & Travels offers a comfortable and stress-free journey from Kopargaon to Shirdi, ensuring a pleasant experience. Whether you're visiting Shirdi Temple or exploring local attractions, we provide a seamless ride with well-maintained vehicles and professional drivers."
    },
    {
        "name": "Kopargaon to Shirdi Cab Fare",
        "description": "Our Kopargaon to Shirdi cab fare is affordable and transparent, ensuring there are no hidden charges. We provide detailed pricing, so you know the cost upfront, making it easier to budget for your trip."
    },
    {
        "name": "Kopargaon Shirdi Car Rental",
        "description": "Our Kopargaon Shirdi car rental service offers flexibility and comfort. Choose from our variety of vehicles to suit your needs, whether you're traveling solo or with family. Each vehicle is equipped with modern amenities for a smooth journey."
    },
    {
        "name": "Kopargaon to Shirdi One Way Taxi",
        "description": "For travelers only needing a one-way taxi from Kopargaon to Shirdi, we provide convenient and cost-effective options. Book with us to avoid unnecessary round-trip charges and enjoy a hassle-free journey."
    },
    {
        "name": "Kopargaon to Shirdi Cab Booking",
        "description": "Booking your Kopargaon to Shirdi cab is easy with our user-friendly platform. Complete your reservation quickly, and we’ll have a cab ready when you need it. We also offer 24/7 customer support for your convenience."
    },
    {
        "name": "Kopargaon Shirdi Cab Rates",
        "description": "Our Kopargaon Shirdi cab rates are competitive, ensuring you receive value for your money. Each rate is clearly detailed, with no hidden fees, allowing you to enjoy an affordable and comfortable ride."
    },
    {
        "name": "Kopargaon to Shirdi Taxi Fare per km",
        "description": "For customers looking to travel based on distance, our Kopargaon to Shirdi taxi fare per km offers flexibility and transparency. This rate structure helps you manage costs efficiently."
    },
    {
        "name": "Affordable Kopargaon Shirdi Cab",
        "description": "Our affordable Kopargaon Shirdi cab service makes it possible for everyone to travel comfortably without breaking the bank. We offer several budget-friendly options while maintaining high standards."
    },
    {
        "name": "Kopargaon to Shirdi Taxi",
        "description": "Traveling from Kopargaon to Shirdi? Book our reliable taxi service to enjoy a smooth ride. Our professional drivers know the best routes, ensuring a timely and enjoyable trip."
    },
    {
        "name": "Kopargaon Railway Station to Shirdi Taxi Fare",
        "description": "For those arriving at Kopargaon Railway Station, our taxi service offers a convenient transfer to Shirdi at a reasonable fare. Our drivers will be there to meet you, assist with luggage, and make your journey easy."
    },
    {
        "name": "Cab from Kopargaon to Shirdi",
        "description": "Choose our cab service from Kopargaon to Shirdi for a worry-free travel experience. With comfortable seating and modern amenities, we ensure every trip is enjoyable."
    },
    {
        "name": "Kopargaon to Shirdi Cab",
        "description": "Our Kopargaon to Shirdi cab service is ideal for both short and long stays. Our drivers prioritize your comfort, making sure each journey is as smooth as possible."
    },
    {
        "name": "Kopargaon to Shirdi Cab Fare",
        "description": "We provide competitive Kopargaon to Shirdi cab fares, helping you manage your travel budget effectively. Our transparent pricing policy ensures no hidden fees."
    },
    {
        "name": "Kopargaon to Shirdi Distance Taxi Fare",
        "description": "Calculate your Kopargaon to Shirdi distance taxi fare easily with our rate structure, making it convenient to plan your travel expenses."
    },
    {
        "name": "Kopargaon to Shirdi Taxi Charges",
        "description": "Our Kopargaon to Shirdi taxi charges are competitive and fair, providing a cost-effective way to travel without sacrificing quality."
    },
    {
        "name": "Kopargaon to Shirdi Taxi Fare",
        "description": "Our Kopargaon to Shirdi taxi fare is designed to fit your budget while offering a premium travel experience. Enjoy clear and transparent pricing with no hidden fees."
    },
    {
        "name": "Shirdi to Kopargaon Railway Station by Taxi",
        "description": "For those returning to Kopargaon Railway Station from Shirdi, our taxi service ensures a smooth and hassle-free transfer. Enjoy reliable service with experienced drivers."
    },
    {
        "name": "Shirdi to Kopargaon Railway Station Taxi Fare",
        "description": "We offer budget-friendly taxi fares for travel from Shirdi to Kopargaon Railway Station, providing an economical option for return journeys."
    },
    {
        "name": "Shirdi to Kopargaon Station Taxi Fare",
        "description": "Our Shirdi to Kopargaon station taxi fare is designed for affordability and convenience. Book in advance and enjoy a smooth journey at a reasonable price."
    },
    {
        "name": "Shirdi to Kopargaon Taxi",
        "description": "Our Shirdi to Kopargaon taxi service guarantees a comfortable and convenient journey, making it easy for you to travel between these locations."
    },
    {
        "name": "Kopargaon to Shirdi Taxi Contact Number",
        "description": "Contact SaitirthaTours & Travels at +91 9923879009 / +91 9922479009 for prompt and efficient Kopargaon to Shirdi taxi service. We ensure a smooth and enjoyable ride for all our customers."
    }
],
"tableData": [
    ["- Kopargaon to Shirdi Taxi Service", "- Kopargaon to Shirdi Cab Fare"],
    ["- Kopargaon Shirdi Car Rental", "- Kopargaon to Shirdi One Way Taxi"],
    ["- Kopargaon to Shirdi Cab Booking", "- Kopargaon Shirdi Cab Rates"],
    ["- Kopargaon to Shirdi Taxi Fare per KM", "- Affordable Kopargaon Shirdi Cab"],
    ["- Kopargaon to Shirdi Taxi", "- Kopargaon Railway Station to Shirdi Taxi Fare"],
    ["- Cab from Kopargaon to Shirdi", "- Kopargaon to Shirdi Cab"],
    ["- Kopargaon to Shirdi Cab Fare", "- Kopargaon to Shirdi Distance Taxi Fare"],
    ["- Kopargaon to Shirdi Taxi Charges", "- Kopargaon to Shirdi Taxi Fare"],
    ["- Shirdi to Kopargaon Railway Station by Taxi", "- SaitirthaTours & Travels Shirdi"],
    ["- Shirdi to Kopargaon Railway Station Taxi Fare", "- Shirdi to Kopargaon Station Taxi Fare"],
    ["- Shirdi to Kopargaon Taxi", ""]
],
"whychoose": [
    {
        "WhyChooseheading": "Reliable Transportation:",
        "WhyChoosedescription": "Our taxi services from Kopargaon to Shirdi prioritize reliability and ease, ensuring you reach your destination without delays. With an emphasis on consistent service quality, we strive to make each trip smooth and efficient, allowing you to travel stress-free whether you’re headed to a pilgrimage, visiting friends, or on a business journey. Our fleet is well-maintained and routinely inspected, ensuring a dependable mode of transportation that supports your timely arrival. Trust us to make each journey worry-free and aligned with your schedule."
    },
    {
        "WhyChooseheading": "Experienced Drivers:",
        "WhyChoosedescription": "Our drivers bring a wealth of experience and professionalism to every ride, offering safe and knowledgeable navigation along the route from Kopargaon to Shirdi. They are not only skilled at driving but are also familiar with the quickest and safest routes to ensure a smooth journey. With a commitment to creating a welcoming environment, our drivers go above and beyond to make each passenger feel comfortable and respected. Whether it's answering questions or suggesting places to visit along the way, they are dedicated to making your travel experience enjoyable."
    },
    {
        "WhyChooseheading": "Comfortable Vehicles:",
        "WhyChoosedescription": "Our fleet of taxis is designed to enhance your comfort and includes a variety of vehicle options, from spacious sedans to SUVs, catering to both solo travelers and groups. All vehicles are equipped with comfortable seating, climate control, and ample legroom to make your journey as pleasant as possible. Additionally, we prioritize cleanliness and hygiene, with regular cleaning and sanitization of each vehicle to ensure a safe and pristine environment for every trip. Relax in a cozy, well-kept vehicle that transforms your trip into a comfortable journey."
    },
    {
        "WhyChooseheading": "Transparent Pricing:",
        "WhyChoosedescription": "We believe in fairness and transparency when it comes to pricing. With us, you get straightforward, upfront fares with no hidden costs, so you always know what you’ll be paying. This allows you to plan your travel expenses without worrying about surprises or added fees at the end of your journey. Our transparent pricing model is designed to build trust and offer a sense of financial control, ensuring you can focus on your trip rather than unexpected expenses. You’ll receive accurate estimates before booking, making it easy to fit our services into your budget."
    },
    {
        "WhyChooseheading": "Convenient Booking Process:",
        "WhyChoosedescription": "Our easy-to-use booking platform lets you reserve a taxi in just a few clicks. Whether you prefer online booking through our website or a direct call to our customer service team, we make sure the process is quick and hassle-free. Our system is designed for convenience, providing instant confirmation and the flexibility to schedule rides according to your needs. Need to make last-minute changes? Our friendly team is ready to assist you, ensuring a seamless booking experience that aligns with your schedule."
    },
    {
        "WhyChooseheading": "24/7 Availability:",
        "WhyChoosedescription": "With our round-the-clock availability, you can schedule your ride anytime, whether it’s a late-night arrival or an early-morning departure. We understand that travel schedules can vary, so our service is tailored to offer full flexibility and support at any hour. This means no more stressing about late or early travel times, as our drivers and support staff are ready to accommodate your plans. This commitment to 24/7 service makes us an ideal choice for both urgent and pre-planned trips, giving you reliable options at all hours."
    },
    {
        "WhyChooseheading": "Punctuality Guaranteed:",
        "WhyChoosedescription": "We understand the importance of timeliness, especially when traveling for important occasions. With our service, punctuality is a priority. Our drivers plan routes carefully and monitor traffic conditions to ensure you reach your destination on time. This commitment to punctuality makes us a preferred choice for customers who value reliability, whether for catching trains, flights, or attending scheduled events. You can count on us to keep you on track with a journey that respects your time."
    },
    {
        "WhyChooseheading": "Flexible Payment Options:",
        "WhyChoosedescription": "We offer various payment options for a convenient transaction, including cash, card, UPI, and mobile wallets. You can select the payment method that suits you best, making the experience even more user-friendly. With our flexible options, paying for your ride is as easy as booking it, allowing you to focus more on your journey than on payment hassles. Secure, contactless payment options are also available, providing safety and ease for our passengers."
    },
    {
        "WhyChooseheading": "Local Expertise:",
        "WhyChoosedescription": "Our drivers know more than just the roads; they are well-versed in local insights and cultural knowledge. Whether you’re a first-time visitor or a frequent traveler, they can offer recommendations on local attractions, dining options, and tips for exploring Shirdi. Their local expertise can add an enriching layer to your travel, helping you experience the destination beyond the usual routes. This makes every journey not just a ride, but an opportunity to learn more about the region and its unique culture."
    }
]

        
  }


  const faqData = [
    {
        question: "How do I book a taxi from Kopargaon to Shirdi?",
        answer: "You can book your taxi through our website or by calling our customer support for assistance."
    },
    {
        question: "What is the fare for a taxi from Kopargaon to Shirdi?",
        answer: "The fare typically ranges from INR 600 to INR 900, depending on the vehicle type and current demand. Please check our website for the latest rates."
    },
    {
        question: "How long does the journey take?",
        answer: "The drive from Kopargaon to Shirdi usually takes about 1 to 1.5 hours, depending on traffic conditions."
    },
    {
        question: "Are there any additional charges?",
        answer: "Additional charges may apply for nighttime travel (after 10 PM) or for extra stops along the route."
    },
    {
        question: "What payment methods are accepted?",
        answer: "We accept various payment options, including credit/debit cards, UPI, and cash."
    },
    {
        question: "Can I modify or cancel my booking?",
        answer: "Yes, you can modify or cancel your booking through our website or by contacting customer support, according to our cancellation policy."
    },
    {
        question: "Is the taxi sanitized before each trip?",
        answer: "Yes, all our vehicles are thoroughly cleaned and sanitized before every journey to ensure your safety and comfort."
    },
    {
        question: "Can I request extra stops during the trip?",
        answer: "Yes, you can request reasonable extra stops for refreshments or sightseeing along the way."
    }
];

const testimonials = [
    {
        name: "Mrs. Leela Joshi",
        text: "I had a great experience with Saitirtha Travels on my journey from Kopargaon to Shirdi. The driver was punctual and friendly, and the ride was smooth. I will definitely book again!"
    },
    {
        name: "Mr. Rakesh Patil",
        text: "Saitirtha Travels provided excellent service for my trip to Shirdi. The booking process was easy, and the vehicle was very comfortable. Highly recommend them for any travel needs!"
    }
];


  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Kopargaon to Shirdi Taxi | SaitirthaTours & Travels</title>
    <meta name="description" content="Book an affordable taxi from Kopargaon to Shirdi with SaitirthaTours & Travels. Reliable cab services for one-way trips, railway station transfers, and temple visits." />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirtha Travels | Kopargaon to Shirdi Taxi | SaitirthaTours & Travels" />
    <meta property="og:description" content="Book an affordable taxi from Kopargaon to Shirdi with SaitirthaTours & Travels. Reliable cab services for one-way trips, railway station transfers, and temple visits." />
    <meta property="og:url" content="https://saitirthatravels.com/kopargaon-to-shirdi-taxi" />
    <meta property="og:image" content="https://saitirthatravels.com/images/kopargaon-shirdi-taxi.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Book an affordable taxi from Kopargaon to Shirdi with SaitirthaTours & Travels. Reliable cab services for one-way trips, railway station transfers, and temple visits.",
            "url": "https://saitirthatravels.com/kopargaon-to-shirdi-taxi",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/kopargaon-shirdi-taxi.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Kopargaon to Shirdi Taxi Service", 
                "Kopargaon to Shirdi Cab fare", 
                "Kopargaon Shirdi Car Rental", 
                "Kopargaon to Shirdi One Way Taxi", 
                "Kopargaon to Shirdi Cab Booking", 
                "Kopargaon Shirdi Cab Rates", 
                "Kopargaon to Shirdi taxi fare per km", 
                "Affordable Kopargaon Shirdi cab", 
                "Kopargaon to Shirdi Taxi", 
                "Kopargaon Railway station to Shirdi Taxi fare", 
                "Cab from Kopargaon to Shirdi", 
                "Kopargaon to Shirdi Cab", 
                "Kopargaon to Shirdi Cab Fare", 
                "Kopargaon to Shirdi Distance Taxi Fare", 
                "Kopargaon to Shirdi taxi charges", 
                "Shirdi to Kopargaon Railway Station by taxi", 
                "SaitirthaTours & Travels Shirdi", 
                "Shirdi to Kopargaon Railway Station Taxi Fare", 
                "Shirdi to Kopargaon Station Taxi Fare",
                "Shirdi to Kopargaon Taxi"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/31.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Kopargaontoshirdi;