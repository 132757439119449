
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Shirditourpackage() {



  const cardData =
  {
    keyword: ' Shirdi Tour Package',
    heading: 'Saitirtha Travels: Shirdi Tour Package',
    headingDescription: 'Experience the divine aura of Shirdi with our exclusive Shirdi Tour Package by Saitirtha Travels. This package is perfect for pilgrims and tourists alike, offering a blend of spirituality, culture, and comfort. Our aim is to make your visit to the holy city of Shirdi as memorable and hassle-free as possible.',

    top: 'Top Places to Visit in Shirdi with Saitirtha Travels',

 "topPlaces": [
    {
        "title": "Sai Baba Temple",
        "description": "The Sai Baba Temple is the main attraction in Shirdi, drawing millions of devotees each year. This sacred site is dedicated to the revered saint Sai Baba, known for his teachings of love, compassion, and faith. The temple complex is expansive, featuring beautiful architecture and tranquil surroundings where visitors can partake in daily prayers and aarti ceremonies."
    },
    {
        "title": "Samadhi Mandir",
        "description": "Located within the Sai Baba Temple complex, the Samadhi Mandir is the final resting place of Sai Baba. This serene spot holds immense spiritual significance for devotees. The atmosphere is filled with devotion, and visitors often meditate or offer prayers here, seeking blessings from the saint."
    },
    {
        "title": "Dwarkamai",
        "description": "Dwarkamai is a mosque where Sai Baba spent much of his life. The site is steeped in history and includes the sacred dhuni (fire) maintained by Baba. Visitors can view an oil painting of Sai Baba, along with other relics that illustrate his life and teachings. The tranquil ambiance here is perfect for contemplation."
    },
    {
        "title": "Chavadi",
        "description": "Chavadi is the place where Sai Baba would rest every alternate night. This site features a significant shrine and is known for its evening aarti, which draws numerous devotees. The history and spirituality of Chavadi make it a must-visit location for those seeking to understand Sai Baba’s life."
    },
    {
        "title": "Khandoba Temple",
        "description": "Located near Shirdi, Khandoba Temple is dedicated to Lord Khandoba, a local deity. The temple is situated on a hill and offers stunning views of the surrounding landscape. Its architecture and peaceful ambiance make it a popular site for both pilgrimage and photography."
    },
    {
        "title": "Lendi Garden",
        "description": "Lendi Garden is a serene garden where Sai Baba would take his walks. It features beautiful landscaping, lush greenery, and several trees planted by Baba himself. This is an ideal spot for visitors to relax, meditate, and enjoy nature after a day of sightseeing."
    },
    {
        "title": "Shri Saibaba Sansthan Trust",
        "description": "The trust manages the Sai Baba Temple and various facilities for devotees. Visitors can learn about the organization’s efforts to serve the community and maintain the temple complex. The trust also oversees various charitable activities and initiatives in the region."
    },
    {
        "title": "Gurusthan",
        "description": "Gurusthan is a sacred site where Sai Baba was first seen by his devotees. It is marked by a large neem tree, which is believed to have spiritual significance. The area is peaceful, allowing visitors to reflect on their spiritual journey."
    },
    {
        "title": "Siddhivinayak Mandir",
        "description": "A beautiful temple dedicated to Lord Ganesha, Siddhivinayak Mandir is a place where devotees seek blessings for success and prosperity. The temple’s intricate design and vibrant atmosphere make it a worthy stop on your Shirdi tour."
    },
    {
        "title": "Khandoba Hill",
        "description": "A short trek from Shirdi, Khandoba Hill offers panoramic views of the surrounding area. It is a popular spot for trekking enthusiasts and provides an excellent opportunity for photography. The hill is also associated with local legends and spirituality."
    }
],
    
            
        
"services": [
    {
        "name": "Shirdi Tour Package",
        "description": "Embark on a transformative journey to Shirdi, the revered abode of Sai Baba, where spirituality and serenity intertwine. Our Shirdi Tour Package is thoughtfully crafted to provide a holistic experience that caters to both spiritual seekers and tourists eager to explore the rich cultural heritage of this iconic destination. With meticulously planned itineraries and comfortable accommodations, we ensure your visit is not only seamless but also deeply enriching, allowing you to immerse yourself in the divine atmosphere of Shirdi."
    },
    {
        "name": "Best Shirdi Tour Packages",
        "description": "Discover a diverse selection of the best Shirdi tour packages designed to cater to various traveler preferences. Whether you're embarking on a family trip, a solo pilgrimage, or a spiritual retreat with friends, our packages encompass everything you need—from cozy accommodations in well-rated hotels to expert local guides who enhance your journey with insightful commentary on the history and significance of each site. Experience Shirdi like never before with our tailored options that meet your every need."
    },
    {
        "name": "Shirdi Darshan Package",
        "description": "Dive deep into the spiritual legacy of Sai Baba with our comprehensive Shirdi Darshan Package. This thoughtfully curated tour includes visits to sacred places such as the Samadhi Mandir, where devotees gather to pay their respects and seek blessings, and Dwarkamai, a mosque that holds immense historical significance. Enjoy guided tours that provide rich narratives and personal insights into the life and teachings of Sai Baba, ensuring a profound spiritual connection at each site."
    },
    {
        "name": "Shirdi Holiday Package",
        "description": "Seeking a getaway that blends spirituality with leisure? Our Shirdi Holiday Package offers the perfect combination of spiritual exploration and relaxing activities. Stay in comfort at well-rated hotels, indulge in sightseeing adventures, and experience the joy of shopping for local handicrafts. Savor the delightful flavors of regional cuisine as you immerse yourself in the vibrant culture of Shirdi, making your holiday both spiritually uplifting and enjoyable."
    },
    {
        "name": "Shirdi Sightseeing Tour",
        "description": "Our Shirdi Sightseeing Tour provides an enriching experience that goes beyond the religious sites. Discover local treasures like the Sai Baba Museum, which showcases memorabilia and artifacts from Sai Baba’s life, and explore the surrounding areas that offer unique cultural experiences. Engage with traditional music and dance performances, allowing you to connect with the local culture while enjoying the spiritual ambiance of Shirdi."
    },
    {
        "name": "Shirdi Spiritual Tour",
        "description": "Reconnect with your inner self through our Shirdi Spiritual Tour. This immersive package emphasizes spiritual practices, including guided meditation sessions and opportunities to participate in sacred prayer rituals at significant sites. Experience the tranquil environment of Shirdi as you engage in deep reflection and prayer, leaving with a renewed sense of peace, purpose, and connection to the divine."
    },
    {
        "name": "Affordable Shirdi Tour",
        "description": "At Saitirtha Travels, we believe that spiritual journeys should be accessible to all. Our Affordable Shirdi Tour offers budget-friendly options that do not compromise on quality or experience. Enjoy economical lodging, affordable meal plans, and competitive transportation rates, ensuring that your spiritual exploration remains enriching and affordable. Experience the profound serenity of Shirdi without stretching your budget."
    },
    {
        "name": "Shirdi Weekend Tour",
        "description": "Recharge your spirit with our Shirdi Weekend Tour, designed for those seeking a quick yet fulfilling spiritual retreat. Perfect for busy schedules, this package allows you to soak in the essence of Shirdi in just a couple of days. Enjoy a hassle-free itinerary that covers all major attractions while providing ample free time for personal exploration, reflection, and rest, making it an ideal getaway from the hustle and bustle of everyday life."
    },
    {
        "name": "Shirdi Travel Package",
        "description": "Our all-inclusive Shirdi Travel Package takes care of every detail of your journey, allowing you to focus solely on your spiritual experience. From the moment you arrive, our dedicated team will ensure your comfort, providing everything from airport transfers to guided tours, meals, and accommodations. This comprehensive approach allows you to fully immerse yourself in the spiritual atmosphere of Shirdi, worry-free and fully engaged."
    },
    {
        "name": "Shirdi Local Tour Packages",
        "description": "Experience the charm and beauty of Shirdi with our Shirdi Local Tour Packages, designed to help you explore nearby attractions such as the picturesque Shani Shingnapur, famous for its temple dedicated to Lord Shani. Enjoy excursions to lesser-known spots that provide a unique perspective on the region’s culture and spirituality, enriching your travel experience and deepening your connection to this sacred area."
    },
    {
        "name": "Shirdi Trip",
        "description": "Plan a fulfilling Shirdi trip with us, featuring a balanced mix of spiritual visits, cultural experiences, and leisure time. Whether you’re traveling with family, friends, or on your own, our customized itinerary ensures that every aspect of your journey is tailored to your interests and preferences. Create lasting memories as you explore the spiritual heart of India, with each moment designed for reflection and joy."
    },
    {
        "name": "Shirdi Family Tour Packages",
        "description": "Our Shirdi Family Tour Packages are thoughtfully designed to cater to groups of all sizes, ensuring a spiritually enriching experience for every family member. Engage in activities that are both fun and spiritually rewarding, with family-friendly accommodations and dining options. Create cherished memories together while exploring the sacred sites and cultural richness of Shirdi, fostering bonds and spirituality within your family."
    },
    {
        "name": "Shirdi Trip Package",
        "description": "Experience the best of Shirdi with our Shirdi Trip Package, which offers an immersive journey through this sacred land. This package includes visits to all key attractions, opportunities for local cuisine tasting, and participation in traditional rituals, making it a rich and fulfilling journey for travelers of all ages. Discover the spiritual and cultural layers of Shirdi, leaving you with unforgettable experiences."
    },
    {
        "name": "Shirdi Tours and Travels",
        "description": "Trust Shirdi Tours and Travels for an unforgettable pilgrimage experience. With years of expertise in the travel industry, we meticulously manage every aspect of your journey with precision and care. Our knowledgeable staff is dedicated to providing you with an enriching experience, complete with personalized service tailored to your needs. We prioritize your comfort and satisfaction, ensuring a memorable trip that resonates with your spiritual journey."
    },
    {
        "name": "2 Days Trip to Shirdi",
        "description": "Join us for a 2 days trip to Shirdi, designed for those who wish to experience the essence of this sacred place without a lengthy commitment. This package covers all essential visits and spiritual activities, allowing you to connect spiritually while enjoying a comfortable stay. Each moment is planned to facilitate reflection, prayer, and the opportunity to absorb the divine atmosphere of Shirdi."
    },
    {
        "name": "Package for Shirdi",
        "description": "Our customized package for Shirdi is perfect for those who prefer a personalized experience. Share your travel preferences and interests with us, and we will design a unique itinerary that reflects your desires, ensuring your visit is truly personal and fulfilling. Enjoy the flexibility of a journey tailored specifically to your spiritual and recreational needs."
    },
    {
        "name": "Shirdi 3 Days Package",
        "description": "Opt for our Shirdi 3 Days Package to explore in-depth the spiritual and cultural landscape of Shirdi. This extended stay allows for a more relaxed exploration of the region, including optional visits to nearby attractions like the breathtaking Ajanta and Ellora caves, providing a comprehensive experience of Maharashtra’s rich heritage and spiritual significance."
    },
    {
        "name": "Shirdi Mini Bus Package",
        "description": "For larger groups, our Shirdi Mini Bus Package offers comfortable transport and the convenience of traveling together. This option is ideal for families, corporate groups, or friends looking to explore Shirdi in a hassle-free manner. Share in the spiritual journey as you travel comfortably and create memories that will last a lifetime."
    },
    {
        "name": "Shirdi Innova Crysta Package",
        "description": "Travel in style and comfort with our Shirdi Innova Crysta Package. This premium travel option guarantees a luxurious experience, complete with spacious interiors and a focus on comfort, making your journey to Shirdi both enjoyable and relaxing. Experience the spiritual ambiance of Shirdi while enjoying the finest in travel comfort."
    },
    {
        "name": "Shirdi Car Rental Package",
        "description": "Our Shirdi Car Rental Package provides flexible transportation options for those who prefer to explore at their own pace. Choose from a range of vehicles that suit your group size and travel style, ensuring a convenient experience tailored to your needs. Enjoy the freedom to design your own adventure in Shirdi, discovering its spiritual essence on your terms."
    },
    {
        "name": "Shirdi Shani Shingnapur Package",
        "description": "Enhance your pilgrimage by adding a visit to Shirdi Shani Shingnapur with our specially designed package. This thoughtfully planned itinerary allows you to experience both spiritual destinations without the hassle of planning multiple trips, maximizing your time in this sacred region and enriching your journey through divine exploration."
    },
    {
        "name": "Contact Number for Shirdi Tour Package",
        "description": "For prompt and efficient assistance with your Shirdi Tour Package, contact Saitirtha Travels at +91 9923879009 / 9922479009. Our dedicated team is committed to ensuring a smooth and enjoyable ride for all our customers, prioritizing your comfort and satisfaction throughout your journey. Let us help you create unforgettable memories on your spiritual adventure."
    }
],

"tableData": [
    ["- Shirdi Tour Package", "- Best Shirdi Tour Packages"],
    ["- Shirdi Darshan Package", "- Shirdi Holiday Package"],
    ["- Shirdi Sightseeing Tour", "- Shirdi Spiritual Tour"],
    ["- Affordable Shirdi Tour", "- Shirdi Weekend Tour"],
    ["- Shirdi Travel Package", "- Shirdi Package"],
    ["- Shirdi Local Tour Packages", "- Shirdi Trip"],
    ["- Shirdi Family Tour Packages", "- Shirdi Tours and Travels 2 Days Trip to Shirdi"],
    ["- Package for Shirdi", "- Shirdi 3 Days Package"],
    ["- Shirdi Mini Bus Package", "- Shirdi Innova Crysta Package"],
    ["- Shirdi Car Rental Package", "- Shirdi Shani Shingnapur Package"],
    ["- SaitirthaTours & Travels Shirdi", "- "]
],
"whychoose": [
    {
        "WhyChooseheading": "All-Inclusive Packages:",
        "WhyChoosedescription": "Our Shirdi Tour Package offers a truly comprehensive travel experience, encompassing everything from comfortable accommodation and delicious meals to seamless transportation and guided tours. This all-in-one approach allows you to enjoy your spiritual journey without the stress of coordinating logistics, ensuring a smooth and enjoyable experience from the moment you arrive. With everything taken care of, you can focus solely on your pilgrimage and the spiritual enrichment it brings."
    },
    {
        "WhyChooseheading": "Comfortable Travel:",
        "WhyChoosedescription": "Travel in utmost comfort with our well-maintained vehicles, equipped with plush seating, air conditioning, and modern amenities. Our focus on comfort means that whether you're traveling a short distance or embarking on a longer journey, you can relax and enjoy the scenery. We prioritize your well-being, providing a smooth ride that minimizes travel fatigue, allowing you to arrive at each destination refreshed and ready to explore."
    },
    {
        "WhyChooseheading": "Experienced Guides:",
        "WhyChoosedescription": "Our team of experienced guides is dedicated to enriching your journey with their extensive knowledge of Shirdi's history, culture, and significance. They are passionate about sharing the stories behind each landmark, helping you to connect on a deeper level with the spiritual essence of Shirdi. With their guidance, you won't just see the sights; you'll understand their importance, making your visit more meaningful and memorable."
    },
    {
        "WhyChooseheading": "Flexible Itineraries:",
        "WhyChoosedescription": "Recognizing that every traveler is unique, we offer flexible itineraries that allow you to tailor your Shirdi experience to suit your individual preferences and interests. Whether you wish to visit specific temples, explore local markets, or enjoy leisure time, our team is committed to accommodating your needs. This flexibility ensures that your journey is personalized, allowing you to create lasting memories that resonate with your personal travel goals."
    },
    {
        "WhyChooseheading": "Value for Money:",
        "WhyChoosedescription": "Our pricing strategy is designed to provide you with exceptional value for your investment. We believe that quality travel experiences should be accessible to everyone, and we strive to offer competitive rates without compromising service. Our transparent pricing includes no hidden fees, so you can plan your budget confidently, ensuring that every aspect of your trip delivers the best possible experience."
    },
    {
        "WhyChooseheading": "24/7 Customer Support:",
        "WhyChoosedescription": "Traveling can come with unexpected changes and challenges, which is why our dedicated customer support team is available 24/7 to assist you. Whether you have questions about your booking, need to make changes to your itinerary, or require assistance during your trip, our friendly and knowledgeable staff is just a phone call away. We pride ourselves on being responsive and helpful, ensuring that you have the support you need at any time."
    },
    {
        "WhyChooseheading": "Timely Services:",
        "WhyChoosedescription": "We understand the importance of time, especially when it comes to spiritual journeys and pilgrimage activities. Our commitment to punctuality means that our drivers are trained to arrive promptly at your pick-up locations and follow schedules meticulously. We monitor traffic conditions to avoid delays, allowing you to make the most of your time at each destination and participate in rituals and events without the stress of running late."
    },
    {
        "WhyChooseheading": "Safety First:",
        "WhyChoosedescription": "Your safety is our paramount concern. We adhere to rigorous safety protocols, conducting regular maintenance checks on our vehicles and ensuring they meet all safety standards. Our drivers are trained to follow safe driving practices, so you can travel with peace of mind knowing that every measure has been taken to protect your well-being. This commitment to safety allows you to focus on your spiritual experience rather than worrying about logistics."
    },
    {
        "WhyChooseheading": "Positive Reviews:",
        "WhyChoosedescription": "We take immense pride in our reputation, built on a foundation of positive customer feedback. Our commitment to outstanding service has earned us a loyal customer base, and we continuously strive to exceed expectations. Traveler testimonials reflect our reliability, professionalism, and dedication to providing high-quality experiences. By choosing us, you can trust that you are selecting a service recognized for its excellence in the travel industry."
    },
    {
        "WhyChooseheading": "Local Insights:",
        "WhyChoosedescription": "Our team comprises locals who are intimately familiar with Shirdi and its surrounding areas. They provide invaluable insights and recommendations for attractions, dining options, and cultural experiences that you might not find in guidebooks. This local knowledge not only enhances your journey but also allows you to immerse yourself fully in the culture and traditions of the region, ensuring that your visit is both enriching and authentic."
    }
]




        
  }


  const faqData = [
    {
        question: "What does the Shirdi Tour Package include?",
        answer: "The package typically includes transportation, accommodation, meals, and guided tours of key attractions."
    },
    {
        question: "How long is the tour?",
        answer: "The duration varies, but most packages range from 2 to 4 days, depending on the itinerary."
    },
    {
        question: "Can the itinerary be customized?",
        answer: "Yes, we offer flexible itineraries that can be tailored to fit your preferences and schedule."
    },
    {
        question: "What types of accommodations are provided?",
        answer: "We provide a range of accommodations, from budget-friendly hotels to luxury options, based on your choice."
    },
    {
        question: "Are meals included in the package?",
        answer: "Yes, most packages include breakfast and other meals, but this can be adjusted based on your preferences."
    },
    {
        question: "Is transportation included?",
        answer: "Yes, the package includes transportation in comfortable vehicles throughout your stay in Shirdi."
    },
    {
        question: "How do I book the tour?",
        answer: "You can book directly through our website or by contacting our customer service team."
    },
    {
        question: "What payment methods do you accept?",
        answer: "We accept various payment methods, including cash, credit/debit cards, and online transfers."
    },
    {
        question: "Is travel insurance included?",
        answer: "Travel insurance is not typically included but can be arranged upon request."
    },
    {
        question: "Can I join the tour with my family or group?",
        answer: "Yes, we can accommodate families and groups of various sizes. Please inquire for special rates and arrangements."
    }
];

const testimonials = [
    {
        name: "Mrs. Kavita Mehta",
        text: "Our Shirdi tour with Saitirtha Travels was exceptional! The entire package was well-organized, and we felt truly cared for from start to finish. Our guide was knowledgeable and made our spiritual journey memorable. I highly recommend this package to anyone looking to explore Shirdi!"
    },
    {
        name: "Mr. Rohan Shah",
        text: "We had a wonderful time on our Shirdi tour! The accommodations were comfortable, and our driver was fantastic, making sure we visited all the important sites. The entire experience exceeded our expectations. Thank you, Saitirtha Travels!"
    }
];





  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>

<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Best Shirdi Tour Package | Affordable Shirdi Darshan & Travel Packages | SaitirthaTours & Travels</title>
    <meta name="description" content="Book the best Shirdi Tour Package with Saitirtha Travels. Enjoy affordable Shirdi Darshan, sightseeing, and family tours. Customizable 2-3 days Shirdi packages, including visits to Shani Shingnapur and more!" />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirtha Travels | Best Shirdi Tour Package | Affordable Shirdi Darshan & Travel Packages | SaitirthaTours & Travels" />
    <meta property="og:description" content="Book the best Shirdi Tour Package with Saitirtha Travels. Enjoy affordable Shirdi Darshan, sightseeing, and family tours. Customizable 2-3 days Shirdi packages, including visits to Shani Shingnapur and more!" />
    <meta property="og:url" content="https://saitirthatravels.com/shirdi-tour-package" />
    <meta property="og:image" content="https://saitirthatravels.com/images/shirdi-tour-package.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Book the best Shirdi Tour Package with Saitirtha Travels. Enjoy affordable Shirdi Darshan, sightseeing, and family tours. Customizable 2-3 days Shirdi packages, including visits to Shani Shingnapur and more!",
            "url": "https://saitirthatravels.com/shirdi-tour-package",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/shirdi-tour-package.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi Tour Package",
                "Best Shirdi Tour Packages",
                "Shirdi Darshan Package",
                "Shirdi Holiday Package",
                "Shirdi Sightseeing Tour",
                "Shirdi Spiritual Tour",
                "Affordable Shirdi Tour",
                "Shirdi Weekend Tour",
                "Shirdi Travel Package",
                "Shirdi Local Tour Packages",
                "Shirdi Trip",
                "Shirdi Family Tour Packages",
                "Shirdi Trip Package",
                "Shirdi Tours and Travels 2 days Trip",
                "Package for Shirdi",
                "Shirdi 3 days Package",
                "Shirdi Mini Bus Package",
                "Shirdi Innova Crysta Package",
                "Shirdi Car Rental Package",
                "Shirdi Shani Shingnapur Package",
                "SaitirthaTours & Travels Shirdi"
            ]
        })}
    </script>
</Helmet>

      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/Keyword 5-02.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirditourpackage;