
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Shirditopandharpur() {



  const cardData =
  {
    keyword: '  Shirdi to Pandharpur Cab',
    heading: 'Saitirtha Travels: Shirdi to Pandharpur Cab',
    headingDescription: 'Traveling from Shirdi to Pandharpur is a spiritual journey that connects you to one of Maharashtra is most revered pilgrimage sites. At Saitirtha Travels, we provide a comfortable and reliable cab service to ensure your travel experience is enjoyable and hassle-free. Our Shirdi to Pandharpur cab service is tailored to meet your needs, making your pilgrimage as smooth as possible.',

    top: 'Top Places to Visit in Pandharpur with Saitirtha Travels',

 "topPlaces": [
    {
        "title": "Vithoba Temple",
        "description": "The Vithoba Temple is the crown jewel of Pandharpur, drawing pilgrims from across India and beyond to pay homage to Lord Vithoba, a cherished incarnation of Lord Krishna. This sacred site boasts intricate carvings and vibrant murals that narrate the life and miracles of the deity, creating a captivating visual experience. The temple is not only a place of worship but also a hub of community and devotion, where festivals like Ashadhi Ekadashi see hundreds of thousands of devotees gather for darshan (viewing of the deity). The lively atmosphere, filled with bhajans (devotional songs) and the aroma of prasad (blessed food), makes every visit a deeply spiritual and uplifting experience."
    },
    {
        "title": "Rukmini Swayamvar Mandir",
        "description": "Nestled near the Vithoba Temple, the Rukmini Swayamvar Mandir is a tranquil haven dedicated to Rukmini, the beloved of Lord Krishna. This shrine is characterized by its ornate architecture, featuring exquisite carvings and delicate frescoes that illustrate the poignant love story of Rukmini and Krishna. Visitors are often enchanted by the serene ambiance that allows for peaceful worship and introspection. The temple hosts various religious ceremonies throughout the year, attracting devotees seeking blessings for marital happiness and spiritual growth. The connection to the divine romance and the temple's peaceful environment make it a perfect spot for reflection and devotion."
    },
    {
        "title": "Pandharpur Fort",
        "description": "The historical Pandharpur Fort offers an adventurous trek coupled with breathtaking panoramic views of the surrounding landscape. Built to defend the region, the fort's architecture features ancient walls and bastions that tell the stories of battles fought and won. As visitors explore the fort's grounds, they can appreciate the strategic significance of its location overlooking the town and the sacred river. The fort also serves as a reminder of the rich cultural history of Maharashtra, and it's an excellent spot for photography enthusiasts looking to capture stunning vistas. Exploring the fort gives visitors a unique perspective of the spiritual landscape of Pandharpur, blending history with spirituality."
    },
    {
        "title": "Pundalik Mandir",
        "description": "The Pundalik Mandir holds profound significance for devotees of Lord Vithoba, as it is dedicated to Pundalik, a legendary figure known for his unwavering devotion. The temple is steeped in lore, with stories recounting how Pundalik’s faith transformed his life and brought blessings to those who followed in his footsteps. The architecture is traditional, featuring intricate carvings that depict scenes from Pundalik's life and the teachings of Vithoba. Many pilgrims visit this site to seek blessings for spiritual progress and personal transformation, often sharing their own stories of divine intervention. The atmosphere is imbued with a sense of reverence, making it a key stop on the pilgrimage trail."
    },
    {
        "title": "Mahalakshmi Temple",
        "description": "Dedicated to Goddess Mahalakshmi, the Mahalakshmi Temple is a masterpiece of intricate design and devotion. Known for its detailed sculptures and vibrant festivals, this temple attracts thousands of visitors eager to seek the goddess's blessings for wealth and prosperity. The temple is adorned with colorful decorations during festivals, particularly during Diwali, when the surroundings are illuminated, creating a festive and joyful atmosphere. Devotees often participate in rituals that celebrate the goddess's grace, and the community spirit during these events is palpable. The temple serves not only as a place of worship but also as a cultural hub, showcasing the artistic talents of local craftsmen and the rich traditions of Maharashtra."
    },
    {
        "title": "Chandrabhaga River",
        "description": "The sacred Chandrabhaga River flows gracefully beside Pandharpur, considered one of the holiest rivers in Maharashtra. Many pilgrims come here for ritual bathing, which is believed to cleanse the soul and wash away sins. The riverbanks are often lined with devotees performing pooja (worship rituals) and offering prayers, creating a serene yet spiritually charged environment. The river is particularly beautiful at sunrise and sunset, offering a picturesque setting for reflection and meditation. Visitors are encouraged to take part in the rituals and experience the sense of community that permeates the riverside, where spirituality and nature come together harmoniously."
    },
    {
        "title": "Mangalagauri Temple",
        "description": "The Mangalagauri Temple is dedicated to the goddess Mangalagauri, revered for her blessings of health and prosperity. This temple is known for its beautiful idol, which is intricately adorned, and its tranquil environment that promotes meditation and spiritual contemplation. Many devotees visit the temple to seek blessings for marital harmony and fertility. The temple grounds are often filled with the sounds of chanting and devotion, especially during festival times when special poojas are conducted. The peaceful setting allows for a moment of respite from the bustling activities of the city, making it an ideal place for personal reflection and spiritual connection."
    },
    {
        "title": "Brahma Deva Mandir",
        "description": "The Brahma Deva Mandir is an ancient shrine dedicated to Lord Brahma, known for its unique place in Hindu worship, as Brahma is often overshadowed by other deities. This temple offers a rare opportunity for visitors to pay homage to the creator god in a quiet and less commercialized setting. The simple yet profound atmosphere of the temple encourages personal introspection and spiritual exploration. Visitors can often enjoy the peaceful surroundings and the opportunity to connect with the divine without the distractions that can accompany larger pilgrimage sites. The temple's serene ambiance and historical significance make it a worthwhile visit for those seeking spiritual growth."
    },
    {
        "title": "Chhatrapati Shivaji Maharaj Park",
        "description": "Chhatrapati Shivaji Maharaj Park is a well-maintained green oasis in the heart of Pandharpur, providing a perfect retreat after visiting the nearby temples. This park features beautifully landscaped gardens, walking paths, and playgrounds for children, making it an ideal spot for families to unwind. The lush greenery and the park's tranquil environment offer a refreshing break from the spiritual intensity of the temple visits. Visitors can enjoy leisurely strolls, engage in picnics, or simply relax on a bench and soak in the peaceful atmosphere. The park often hosts community events and cultural programs, further enriching the experience of those who come to enjoy its serene setting."
    },
    {
        "title": "Shri Kshetra Pandharpur",
        "description": "Shri Kshetra Pandharpur is the collective term for the area surrounding the Vithoba Temple, rich in spirituality and cultural heritage. The lively streets are filled with shops offering religious artifacts, local handicrafts, and delicious Maharashtrian cuisine, allowing visitors to immerse themselves in the local culture. The vibrant bazaars and eateries provide a true taste of Pandharpur's community spirit, where the exchange of stories and experiences enriches the pilgrimage journey. Walking through this area, visitors can interact with locals, savor traditional dishes, and witness the daily rhythms of life in this sacred town. The blend of spirituality and local culture creates a deeply immersive experience that leaves a lasting impression on all who visit."
    }
],
    
            
"services": [
    {
      "name": "Shirdi to Pandharpur Cab",
      "description": "Traveling from Shirdi to Pandharpur is made simple and convenient with our dedicated cab services. Whether you're visiting for a pilgrimage, leisure, or both, our reliable transportation options ensure you reach your destination comfortably and efficiently. Our experienced drivers are familiar with the local routes and can provide insights about the landmarks along the way, enriching your travel experience."
    },
    {
      "name": "Shirdi to Pandharpur Taxi Service",
      "description": "Our Shirdi to Pandharpur taxi service is tailored to meet the needs of every traveler. We provide a fleet of well-maintained vehicles that prioritize safety and comfort, ensuring you enjoy a smooth journey. Our experienced drivers navigate the best routes, allowing you to relax and appreciate the beautiful scenery throughout your trip, whether it’s for a religious visit or a leisurely getaway."
    },
    {
      "name": "Shirdi to Pandharpur Cab Fare",
      "description": "Understanding the cost of your journey is crucial. Our transparent Shirdi to Pandharpur cab fare structure means you’ll know what to expect without any hidden charges. We provide a detailed breakdown of all costs involved, helping you budget effectively for your trip. This commitment to transparency allows you to travel with peace of mind, knowing you won't encounter any unexpected expenses."
    },
    {
      "name": "Taxi Fare from Shirdi to Pandharpur",
      "description": "When you book a taxi from Shirdi to Pandharpur, you can expect competitive rates that cater to various types of travelers. Our pricing includes all applicable taxes and fees, ensuring no surprises along the way. Whether you're booking for a group or traveling solo, we have options that suit every need and budget, allowing for flexible travel arrangements."
    },
    {
      "name": "Shirdi Pandharpur Car Rental",
      "description": "For those looking for flexibility, our Shirdi Pandharpur car rental services allow you to travel at your own pace. Choose from our diverse range of vehicles, including compact cars and larger SUVs, and enjoy the freedom to stop and explore along the way. This service is designed for convenience, ensuring you have a vehicle that meets your specific requirements, whether for a pilgrimage or a sightseeing adventure."
    },
    {
      "name": "Shirdi to Pandharpur One Way Taxi",
      "description": "Booking a one-way taxi from Shirdi to Pandharpur is easy with us. Our user-friendly online booking system simplifies the reservation process, ensuring you have a vehicle ready when you need it. Our 24/7 customer support is available to assist you with any inquiries or changes, allowing you to travel with confidence knowing that help is always just a call away."
    },
    {
      "name": "Shirdi to Pandharpur Cab Booking",
      "description": "Our Shirdi to Pandharpur cab booking service is designed for your convenience. You can book your ride in advance, securing your transportation before your trip. Our drivers are punctual and reliable, ready to assist you at your specified pickup location, so you can start your journey without any delays. We strive to provide a seamless travel experience from the moment you book until you reach your destination."
    },
    {
      "name": "Shirdi Pandharpur Cab Rates",
      "description": "We offer competitive Shirdi Pandharpur cab rates, providing exceptional value without compromising on quality. Our pricing structure is designed to accommodate different budgets, ensuring that everyone can afford reliable transportation. We are committed to maintaining high standards of service, making your journey not only affordable but also enjoyable."
    },
    {
      "name": "Shirdi to Pandharpur Taxi Fare Per Km",
      "description": "Our Shirdi to Pandharpur taxi fare is calculated per kilometer, ensuring fairness and transparency in our pricing. This means you only pay for the distance you travel, making it an economical choice for your journey while maintaining high standards of service and comfort. Our pricing model is designed to be straightforward, allowing you to plan your budget effectively."
    },
    {
      "name": "Affordable Shirdi Pandharpur Cab",
      "description": "Looking for affordability? Our services include a variety of options for an affordable Shirdi Pandharpur cab experience. We strive to offer the best rates in the market without compromising on the quality of our service. By providing transparent pricing and excellent customer support, we ensure that you can travel comfortably without breaking the bank."
    },
    {
      "name": "Shirdi to Pandharpur Taxi",
      "description": "With our Shirdi to Pandharpur taxi service, you can expect excellent customer service and a smooth ride. Our fleet includes various vehicle types, accommodating different group sizes and preferences. Each vehicle is regularly maintained to ensure safety and reliability, allowing you to focus on your journey rather than the logistics of transportation."
    },
    {
      "name": "Shirdi to Pandharpur Cab Innova Crysta",
      "description": "For a more luxurious experience, consider booking our Shirdi to Pandharpur cab Innova Crysta. This spacious and comfortable vehicle is perfect for families or larger groups, providing ample legroom and modern amenities for a pleasant journey. With features designed for comfort and convenience, your travel experience will be elevated significantly."
    },
    {
      "name": "Shirdi to Pandharpur Darshan Cab Service",
      "description": "Our Shirdi to Pandharpur darshan cab service is specially designed for those visiting the sacred site. We ensure timely pickups and drop-offs, allowing you to focus on your spiritual journey without worrying about transportation logistics. Our drivers are familiar with the area and can guide you to important sites, enhancing your pilgrimage experience."
    },
    {
      "name": "Ahmednagar to Pandharpur Cab Booking",
      "description": "We also offer services for those traveling from Ahmednagar to Pandharpur. Our cab booking process is straightforward and user-friendly, ensuring you have reliable transportation from start to finish. Enjoy a comfortable ride and reach your destination without hassle, allowing you to focus on your travel plans and enjoy the journey."
    },
    {
      "name": "Shani Shingnapur to Pandharpur Taxi Fare",
      "description": "If you're traveling from Shani Shingnapur to Pandharpur, our taxi fare is competitive and provides excellent value for your journey. Enjoy the convenience of direct transport to your destination, making your travel experience as seamless as possible while ensuring you arrive comfortably and on time."
    },
    {
      "name": "Nashik to Pandharpur Cabs Fare",
      "description": "For travelers coming from Nashik, our cabs provide a comfortable and efficient option. We offer favorable Nashik to Pandharpur cab fares, ensuring you can travel affordably while enjoying a high level of service throughout your trip. Our goal is to make your journey enjoyable and stress-free from start to finish."
    },
    {
      "name": "Shirdi to Pandharpur Bus Hire on Rent",
      "description": "If you’re traveling with a larger group, consider our Shirdi to Pandharpur bus hire on rent. Our spacious buses are perfect for groups, ensuring everyone travels together comfortably. This option not only provides convenience but also fosters a sense of camaraderie among group members as you travel together towards your destination."
    },
    {
      "name": "Shirdi to Pandharpur Tempo Traveller on Rent",
      "description": "We also provide tempo travelers for rent for those needing more space. Our Shirdi to Pandharpur tempo traveller on rent services are ideal for family outings or group trips, ensuring that you have ample space for everyone and their luggage. With comfortable seating and amenities, your travel experience will be both pleasant and memorable."
    },
    {
      "name": "Shirdi Tempo Traveller Hire",
      "description": "Our Shirdi tempo traveller hire services offer a flexible option for larger groups. With ample seating and storage, you can enjoy a comfortable ride while traveling to Pandharpur, making it perfect for family or friends traveling together. This option allows for a shared experience, enhancing the joy of the journey."
    },
    {
      "name": "Shirdi to Pandharpur Taxi Fare",
      "description": "Our Shirdi to Pandharpur taxi fare is designed to be competitive and transparent, allowing you to travel without any surprises. We aim to keep our pricing fair and straightforward, ensuring you can budget for your trip without any unexpected costs. Our commitment to transparency reflects our dedication to excellent customer service."
    },
    {
      "name": "Pandharpur Darshan from Shirdi",
      "description": "If you are looking to experience the divine atmosphere of Pandharpur, our Pandharpur darshan from Shirdi service will ensure that you get there comfortably and efficiently. We prioritize your journey, providing reliable transport for your spiritual experience, allowing you to focus on your devotion and the beautiful sights along the way."
    },
    {
      "name": "Pandharpur to Shirdi Taxi",
      "description": "After your visit, our Pandharpur to Shirdi taxi service will be ready to take you back, ensuring your travel experience remains seamless. Our drivers will ensure you arrive at your destination safely and comfortably, providing you with a stress-free conclusion to your journey."
    },
    {
      "name": "Contact Number for Shirdi to Pandharpur Cab",
      "description": "For prompt and efficient Shirdi to Pandharpur cab services, contact Saitirtha Travels at +91 9923879009 / 9922479009. We are dedicated to providing a smooth and enjoyable ride for all our customers, ensuring your journey is as pleasant as possible. Our customer support team is ready to assist you with any inquiries or booking needs."
    }
  ],

  "tableData": [
    ["- Shirdi to Pandharpur Taxi Service", "- Shirdi to Pandharpur Cab Fare"],
    ["- Taxi Fare from Shirdi to Pandharpur", "- Shirdi Pandharpur Car Rental"],
    ["- Shirdi to Pandharpur One Way Taxi", "- Shirdi to Pandharpur Cab Booking"],
    ["- Shirdi to Pandharpur Travel by Taxi", "- Shirdi Pandharpur Cab Rates"],
    ["- Shirdi to Pandharpur Taxi Fare per km", "- Affordable Shirdi Pandharpur Cab"],
    ["- Shirdi to Pandharpur Cab", "- Shirdi to Pandharpur Taxi"],
    ["- Pandharpur to Shirdi Taxi", "- Shirdi to Pandharpur Taxi Fare"],
    ["- Shirdi to Pandharpur Cab Innova Crysta", "- Shirdi to Pandharpur Darshan Cab Service"],
    ["- Ahmednagar to Pandharpur Cab Booking", "- Shani Shingnapur to Pandharpur Taxi Fare"],
    ["- Nashik to Pandharpur Cabs Fare", "- Shirdi to Pandharpur Bus Hire on Rent"],
    ["- Shirdi to Pandharpur Tempo Traveller on Rent", "- Shirdi Tempo Traveller Hire"],
    ["- Shirdi to Pandharpur Taxi Fare", "- Pandharpur Darshan from Shirdi"],
    ["- Pandharpur to Shirdi Taxi", "- SaitirthaTours & Travels Shirdi"]
],
"whychoose": [
    {
        "WhyChooseheading": "Easy Online Booking",
        "WhyChoosedescription": "Reserving your cab from Shirdi to Pandharpur is a breeze with our intuitive online booking system. You can complete your booking in just a few clicks, making it incredibly convenient to plan your trip at your own pace. Whether you're at home or on the move, our platform is accessible 24/7, and you can receive instant confirmations via email or SMS, ensuring you have everything you need for your journey."
    },
    {
        "WhyChooseheading": "Diverse Fleet of Vehicles",
        "WhyChoosedescription": "We take pride in offering a comprehensive range of vehicles to meet various travel needs and preferences. From economical compact cars perfect for solo travelers to luxurious sedans and spacious vans designed for families or groups, our fleet is regularly maintained to the highest standards. Each vehicle is equipped with modern amenities such as air conditioning, comfortable seating, and entertainment systems, providing a pleasant travel experience."
    },
    {
        "WhyChooseheading": "Professional Drivers",
        "WhyChoosedescription": "Our drivers are the backbone of our service. With extensive training and a wealth of experience, they are adept at navigating the best routes to ensure timely arrivals. Beyond their driving skills, our drivers are committed to providing a courteous and friendly service. They can offer valuable insights about local attractions, making your journey not just a ride, but an enriching experience that enhances your visit."
    },
    {
        "WhyChooseheading": "Transparent Pricing",
        "WhyChoosedescription": "We believe in clear, honest pricing without surprises. Our competitive rates for the Shirdi to Pandharpur route come with no hidden fees, ensuring you know exactly what you’re paying for upfront. Our pricing structure is designed to be flexible, allowing you to choose from various packages that can be tailored to fit your budget and travel requirements, giving you peace of mind and confidence in your transportation choice."
    },
    {
        "WhyChooseheading": "Flexible Travel Options",
        "WhyChoosedescription": "Understanding that travel plans can change unexpectedly, we offer flexible rental options to accommodate your itinerary. Whether you need a one-way trip for a spontaneous visit or a round trip for a planned outing, our services are designed to be adaptable. You can easily adjust your booking as your travel plans evolve, ensuring a stress-free experience that fits your schedule."
    },
    {
        "WhyChooseheading": "24/7 Customer Support",
        "WhyChoosedescription": "Our commitment to exceptional customer service is unwavering. Our dedicated support team is available around the clock to assist you with any questions or concerns. Whether you need help with booking modifications, have queries about our services, or require assistance during your ride, our friendly staff is just a phone call or message away, ensuring you have reliable support whenever you need it."
    },
    {
        "WhyChooseheading": "Safety and Hygiene Measures",
        "WhyChoosedescription": "Your safety and well-being are our top priorities. We implement rigorous hygiene protocols, including thorough sanitization of our vehicles before and after each trip. Our drivers are trained to adhere to health guidelines and maintain cleanliness throughout your journey. We also provide safety kits in every vehicle, equipped with hand sanitizers and masks, allowing you to travel confidently knowing that your health is protected."
    },
    {
        "WhyChooseheading": "Real-Time Tracking",
        "WhyChoosedescription": "Our cab service includes a real-time tracking feature, empowering you to monitor your vehicle's location throughout the journey. This transparency enhances your peace of mind and allows for better coordination for pickups and drop-offs. You can easily share your ride details with family or friends, ensuring that they are updated on your travel status, which adds an extra layer of safety and assurance."
    }
]


        
  }

  const faqData = [
    {
        question: "What types of vehicles are available for the trip?",
        answer: "We offer a range of vehicles, including sedans, SUVs, and minivans, to cater to different travel needs and group sizes."
    },
    {
        question: "How do I book a cab from Shirdi to Pandharpur?",
        answer: "You can book your cab online via our website or by contacting our customer service team directly for assistance."
    },
    {
        question: "Are fuel charges included in the cab fare?",
        answer: "Fuel charges are typically not included in the base fare and will be calculated separately based on the distance traveled."
    },
    {
        question: "Can I book a one-way trip?",
        answer: "Yes, we provide both one-way and round-trip options for your convenience."
    },
    {
        question: "What payment methods do you accept?",
        answer: "We accept various payment methods, including credit/debit cards, UPI payments, and cash."
    },
    {
        question: "Are the drivers experienced?",
        answer: "Yes, all our drivers are experienced and knowledgeable about the routes, ensuring a safe and efficient journey."
    },
    {
        question: "What should I do if I need to cancel or modify my booking?",
        answer: "You can modify or cancel your booking by contacting our customer support team as soon as possible for assistance."
    },
    {
        question: "What safety measures are in place for the rides?",
        answer: "We ensure all our vehicles are regularly sanitized and our drivers adhere to health and safety protocols for your protection."
    },
    {
        question: "Is there a customer support number for inquiries?",
        answer: "Yes, our customer support is available 24/7 for any questions or assistance you may need."
    },
    {
        question: "Can I request a specific type of vehicle?",
        answer: "Yes, you can request a specific vehicle type when booking, and we will do our best to accommodate your preference."
    }
];

const testimonials = [
    {
        name: "Mr. Ramesh Patil",
        text: "Traveling from Shirdi to Pandharpur was a delightful experience with Saitirtha Travels. Our driver was friendly and knew all the shortcuts, which made our journey enjoyable."
    },
    {
        name: "Mrs. Neha Kulkarni",
        text: "I booked a cab for my family trip to Pandharpur, and I was thoroughly impressed. The car was clean and spacious, and the driver was very polite. Highly recommend!"
    },
    {
        name: "Mr. Sanjay Bhosale",
        text: "Saitirtha Travels offered excellent service for our trip from Shirdi to Pandharpur. Everything went smoothly, and I appreciated the timely arrival of our driver."
    }
];




  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Shirdi to Pandharpur Cab | SaitirthaTours & Travels | Affordable Pandharpur Taxi Services</title>
    <meta name="description" content="Get affordable and reliable Shirdi to Pandharpur cab services. Offering one-way taxi, darshan cab services, and more. Book now with Saitirtha Travels!" />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirtha Travels | Shirdi to Pandharpur Cab | SaitirthaTours & Travels | Affordable Pandharpur Taxi Services" />
    <meta property="og:description" content="Get affordable and reliable Shirdi to Pandharpur cab services. Offering one-way taxi, darshan cab services, and more. Book now with Saitirtha Travels!" />
    <meta property="og:url" content="https://saitirthatravels.com/shirdi-to-pandharpur-cab" />
    <meta property="og:image" content="https://saitirthatravels.com/images/shirdi-to-pandharpur-cab.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Get affordable and reliable Shirdi to Pandharpur cab services. Offering one-way taxi, darshan cab services, and more. Book now with Saitirtha Travels!",
            "url": "https://saitirthatravels.com/shirdi-to-pandharpur-cab",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/shirdi-to-pandharpur-cab.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi to Pandharpur taxi service",
                "Shirdi to Pandharpur cab fare",
                "Taxi fare from Shirdi to Pandharpur",
                "Shirdi Pandharpur car rental",
                "Shirdi to Pandharpur one way taxi",
                "Shirdi to Pandharpur cab booking",
                "Shirdi to Pandharpur travel by taxi",
                "Shirdi Pandharpur cab rates",
                "Shirdi to Pandharpur taxi fare per km",
                "Affordable Shirdi Pandharpur cab",
                "Shirdi to Pandharpur Taxi",
                "Pandharpur to Shirdi Taxi",
                "Shirdi to Pandharpur Taxi Fare",
                "Shirdi to Pandharpur cab Innova Crysta",
                "Shirdi to Pandharpur darshan cab service",
                "Ahmednagar to Pandharpur cab booking",
                "Shani Shingnapur to Pandharpur taxi fare",
                "Nashik to Pandharpur cabs fare",
                "Shirdi to Pandharpur bus hire on rent",
                "Shirdi to Pandharpur tempo traveller on rent",
                "Shirdi tempo traveller hire",
                "Pandharpur darshan from Shirdi",
                "Pandharpur to Shirdi taxi",
                "SaitirthaTours & Travels Shirdi"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/44.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirditopandharpur;