
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Shirditoswaptashrungi() {



  const cardData =
  {
    keyword: ' Shirdi to Saptashrungi Taxi Fare',
    heading: 'Saitirtha Travels: Shirdi to Saptashrungi Taxi Fare',
    headingDescription: 'Our taxi service from Shirdi to Saptashrungi ensures a comfortable and convenient journey to this revered destination. Enjoy a scenic drive through Maharashtras picturesque landscapes while our experienced drivers ensure a smooth travel experience.',

    top: 'Top Places to Visit Near Saptashrungi with Saitirtha Travels',

    topPlaces: [
        {
            "title": "Saptashrungi Temple",
            "description": "A revered pilgrimage site dedicated to Goddess Saptashrungi, this temple is perched atop a hill and surrounded by lush greenery. Devotees flock here to seek blessings and experience the spiritual atmosphere. The trek to the temple is rewarding, offering stunning views of the Sahyadri ranges."
        },
        {
            "title": "Nashik",
            "description": "Famous for its vineyards and wine tours, Nashik is also a significant religious city with numerous temples and ghats along the Godavari River. The Kumbh Mela, held every 12 years, attracts millions of pilgrims. Don’t miss tasting the local wines and exploring the beautiful surroundings."
        },
        {
            "title": "Anjneri Hill",
            "description": "Believed to be the birthplace of Lord Hanuman, Anjneri Hill offers excellent trekking opportunities. The trek is moderate and provides panoramic views of the Western Ghats, making it a perfect spot for nature lovers and photography enthusiasts."
        },
        {
            "title": "Harihar Fort",
            "description": "Known for its unique rock-cut steps, Harihar Fort is a historical marvel that attracts adventure seekers. The fort’s elevation offers breathtaking views of the surrounding landscape, and its rich history makes it an exciting destination for history buffs."
        },
        {
            "title": "Pandav Leni Caves",
            "description": "These ancient rock-cut caves date back to the 1st century BC and are significant for their beautiful sculptures and intricate carvings. The serene environment adds to the mystical experience of exploring these historical sites."
        },
        {
            "title": "Kalsubai Peak",
            "description": "As the highest peak in Maharashtra, Kalsubai is a popular trekking destination for adventure enthusiasts. The trek is challenging but offers stunning views at the summit, along with a small temple dedicated to Goddess Kalsubai."
        },
        {
            "title": "Bhatsa River Valley",
            "description": "Located near the village of Bhatsa, this serene river valley is perfect for picnics and nature walks. The lush greenery and tranquil waters create a picturesque setting for relaxation and photography."
        },
        {
            "title": "Talegaon Dabhade",
            "description": "A charming village known for its scenic beauty, Talegaon Dabhade is surrounded by hills and offers a peaceful retreat. Visitors can explore the local culture and enjoy the natural surroundings."
        },
        {
            "title": "Ratangad Fort",
            "description": "This ancient fort is known for its historical significance and trekking trails. The fort features a lake and is surrounded by lush forests, making it an ideal spot for outdoor activities and exploration."
        },
        {
            "title": "Sula Vineyards",
            "description": "Famous for its wine production, Sula Vineyards is a must-visit for wine enthusiasts. Take a tour of the vineyard, learn about the winemaking process, and enjoy wine tasting sessions while soaking in the scenic views of the vineyards."
        }
    ],    
    
            
        
"services": [
    {
        "name": "Shirdi to Saptashrungi Taxi Fare",
        "description": "Planning a trip from Shirdi to Saptashrungi? We offer competitive taxi fares to ensure you enjoy a comfortable journey without breaking the bank. Our fares are transparent, with no hidden charges, allowing you to budget your trip effectively."
    },
    {
        "name": "Shirdi to Saptashrungi Cab Package",
        "description": "Explore the beautiful Saptashrungi with our exclusive cab packages from Shirdi. Our packages include round-trip transportation, ensuring a hassle-free experience as you visit the sacred site."
    },
    {
        "name": "Shirdi to Saptashrungi Vani Cab Booking",
        "description": "Book a cab from Shirdi to Vani with ease! Our cab service is reliable, offering timely pickups and comfortable rides to ensure you reach your destination without any delays."
    },
    {
        "name": "Shirdi to Vani Gad Cab Booking",
        "description": "Experience convenience with our cab booking service from Shirdi to Vani Gad. Whether for a pilgrimage or sightseeing, our cabs are ready to take you on a smooth journey."
    },
    {
        "name": "Shirdi to Vani Cab Booking",
        "description": "Looking to visit Vani from Shirdi? Our cab booking service is designed for comfort and reliability, making your trip enjoyable. Enjoy scenic views on the way to this beautiful location."
    },
    {
        "name": "Shirdi to Vani Saptashrungi Taxi Service",
        "description": "Our dedicated taxi service from Shirdi to Vani Saptashrungi ensures that you have a comfortable and safe travel experience. We offer professional drivers familiar with the route to provide you with a smooth ride."
    },
    {
        "name": "Shirdi to Saptashrungi Darshan Cab Booking",
        "description": "Make your pilgrimage to Saptashrungi memorable with our cab booking service. We provide convenient and affordable options for your darshan journey, ensuring timely arrivals and comfortable rides."
    },
    {
        "name": "Shirdi to Saptashrungi Tour Package",
        "description": "Join our tour package from Shirdi to Saptashrungi, which includes transportation, sightseeing, and guided services. Enjoy a spiritual journey without the hassle of planning your itinerary."
    },
    {
        "name": "Shirdi to Saptashrungi Taxi Fare",
        "description": "Our taxi fares from Shirdi to Saptashrungi are designed to be competitive and fair. We provide detailed fare breakdowns to help you understand the pricing before you book."
    },
    {
        "name": "Shirdi to Vani Saptashrungi Mini Bus Booking",
        "description": "Traveling with a larger group? Our mini bus service from Shirdi to Vani Saptashrungi is perfect for family outings and group pilgrimages. Enjoy spacious seating and a comfortable ride."
    },
    {
        "name": "Shirdi to Vani Saptashrungi Tempo Traveller on Rent",
        "description": "Rent a tempo traveller for your group journey from Shirdi to Vani Saptashrungi. Our tempo travellers are well-maintained and come with experienced drivers to ensure a pleasant travel experience."
    },
    {
        "name": "Shirdi to Nashik Darshan Cab Service",
        "description": "Extend your journey to Nashik with our darshan cab service. We provide transportation from Shirdi to Nashik, allowing you to explore more of this spiritual region comfortably."
    },
    {
        "name": "SaitirthaTours & Travels",
        "description": "For all your travel needs from Shirdi to Saptashrungi, choose SaitirthaTours & Travels. We are committed to providing excellent service and making your journey enjoyable."
    },
    {
        "name": "Shirdi to Saptashrungi Taxi Fare Contact Information",
        "description": "For inquiries or to book your cab, please contact SaitirthaTours & Travels at +91 9923879009 / +91 9922479009. Let us help you embark on a memorable journey to Saptashrungi!"
    }
],

tableData: [
    ["- Shirdi to Saptashrungi Taxi Fare", "- Shirdi to Saptashrungi Cab Package"],
    ["- Shirdi to Saptashrungi Vani Cab Booking", "- Shirdi to Vani Gad Cab Booking"],
    ["- Shirdi to Vani Cab Booking", "- Shirdi to Vani Saptashrungi Taxi Service"],
    ["- Shirdi to Saptashrungi Darshan Cab Booking", "- Shirdi to Saptashrungi Tour Package"],
    ["- Shirdi to Saptashrungi Taxi Fare", "- Shirdi to Vani Saptashrungi Mini Bus Booking"],
    ["- Shirdi to Vani Saptashrungi Tempo Traveller on Rent", "- Shirdi to Nashik Darshan Cab Service"],
    ["- SaitirthaTours & Travels Shirdi"]
  ],
  whychoose: [
    {
      "WhyChooseheading": "Affordable Pricing",
      "WhyChoosedescription": "We ensure that our taxi fares are competitive, offering you the best value for your money. Whether it's a one-way trip or a round trip, our transparent pricing means no hidden charges, allowing you to plan your travel budget with confidence."
    },
    {
      "WhyChooseheading": "Comfortable Rides",
      "WhyChoosedescription": "Our taxis are regularly maintained and equipped with comfortable seating, ensuring that your journey from Shirdi to Saptashrungi is relaxing and smooth. Whether you're traveling alone or with companions, our vehicles offer a comfortable and enjoyable experience."
    },
    {
      "WhyChooseheading": "Experienced Drivers",
      "WhyChoosedescription": "Our professional drivers are experienced, well-trained, and familiar with the Shirdi to Saptashrungi route. They ensure that you have a safe and pleasant journey by driving efficiently and courteously, with a focus on your comfort and well-being."
    },
    {
      "WhyChooseheading": "Flexible Booking Options",
      "WhyChoosedescription": "We offer flexible booking options to accommodate your specific travel needs. Whether you're planning a one-way trip to Saptashrungi, a round trip, or a custom itinerary, we ensure that your travel plans are catered to in the most convenient way possible."
    },
    {
      "WhyChooseheading": "Punctuality",
      "WhyChoosedescription": "At Saitirtha Travels, we understand the value of your time. We ensure timely pickups and drop-offs for all our services, so you never have to worry about missing an appointment or losing valuable time during your journey."
    },
    {
      "WhyChooseheading": "Hygiene and Safety",
      "WhyChoosedescription": "Passenger safety is our top priority. Our taxis are thoroughly sanitized before each trip, adhering to the highest hygiene standards. We ensure that you travel in a clean and safe environment, allowing you to relax and enjoy your journey with peace of mind."
    },
    {
      "WhyChooseheading": "24/7 Availability",
      "WhyChoosedescription": "Our services are available round the clock, providing you the convenience to book a taxi whenever you need it. Whether it's an early morning ride or a late-night trip, you can count on us to be available to fulfill your travel requirements."
    },
    {
      "WhyChooseheading": "Customer Support",
      "WhyChoosedescription": "Our customer support team is always ready to assist you. From helping you with booking to addressing any inquiries or changes to your schedule, we provide responsive support to ensure a hassle-free experience during your journey."
    },
    {
      "WhyChooseheading": "Local Insights",
      "WhyChoosedescription": "Our experienced drivers not only drive but also serve as guides, sharing interesting facts and local insights about Saptashrungi and the surrounding areas. You’ll learn more about the region and its culture while on your journey, making your trip even more memorable."
    },
    {
      "WhyChooseheading": "Easy Online Booking",
      "WhyChoosedescription": "Our simple and user-friendly online booking system allows you to book your taxi with ease. You can reserve your ride from the comfort of your home or office, making the process fast and efficient."
    }
  ]
  




        
  }


  const faqData = [
    {
        question: "What is the fare for a taxi from Shirdi to Saptashrungi?",
        answer: "Taxi fares depend on the type of vehicle and the duration of the trip. Please contact us for a detailed quote."
    },
    {
        question: "Is the fare inclusive of tolls and taxes?",
        answer: "Yes, the fare typically includes tolls and applicable taxes. For specific details, please inquire when booking."
    },
    {
        question: "How long is the journey from Shirdi to Saptashrungi?",
        answer: "The journey takes approximately 3 to 4 hours, depending on traffic and road conditions."
    },
    {
        question: "Do you provide one-way and round-trip options?",
        answer: "Yes, we offer both one-way and round-trip taxi services from Shirdi to Saptashrungi."
    },
    {
        question: "Can I book a taxi for multiple days?",
        answer: "Yes, we offer multi-day rental options for your convenience. Please contact us for more information."
    },
    {
        question: "Are child seats available upon request?",
        answer: "Yes, we can provide child seats if requested at the time of booking."
    },
    {
        question: "What payment methods do you accept?",
        answer: "We accept various payment methods, including cash, credit/debit cards, and online payments."
    },
    {
        question: "Is there a cancellation policy?",
        answer: "Yes, we have a cancellation policy in place. Please inquire for specific terms and conditions."
    },
    {
        question: "Can I choose the vehicle for my trip?",
        answer: "Yes, you can choose from a range of vehicles based on your preference and group size."
    },
    {
        question: "What should I do if my flight is delayed?",
        answer: "Our drivers track flight timings and will adjust pickup accordingly to ensure you are not left waiting."
    }
];

const testimonials = [
    {
        name: "Mr. Ramesh Tiwari",
        text: "The taxi service from Shirdi to Saptashrungi offered by Saitirtha Travels was excellent! The fare was reasonable, and the ride was comfortable and timely. The driver was knowledgeable about the route, making our journey smooth and enjoyable. I highly recommend their service for anyone planning a trip!"
    },
    {
        name: "Ms. Neeta Agrawal",
        text: "I had a great experience with Saitirtha Travels for my taxi ride from Shirdi to Saptashrungi. The fare was very competitive, and the service was top-notch. The vehicle was clean, and the driver was professional and friendly. I will definitely use Saitirtha Travels for my future travel needs!"
    }
];





  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels |Shirdi to Saptashrungi Taxi Fare | SaitirthaTours & Travels | Cab Booking & Tour Packages</title>
    <meta name="description" content="Book a Shirdi to Saptashrungi taxi at affordable fares with SaitirthaTours & Travels. Available for Vani, Saptashrungi Darshan, Vani Gad, mini bus, and tempo traveller services." />
    <meta property="og:title" content="Shirdi to Saptashrungi Taxi Fare | SaitirthaTours & Travels | Cab Booking & Tour Packages" />
    <meta property="og:description" content="Book a Shirdi to Saptashrungi taxi at affordable fares with SaitirthaTours & Travels. Available for Vani, Saptashrungi Darshan, Vani Gad, mini bus, and tempo traveller services." />
    <meta property="og:url" content="https://saitirthatravels.com/shirdi-to-saptashrungi-taxi-fare" />
    <meta property="og:image" content="https://saitirthatravels.com/images/saptashrungi-taxi.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Book a Shirdi to Saptashrungi taxi at affordable fares with SaitirthaTours & Travels. Available for Vani, Saptashrungi Darshan, Vani Gad, mini bus, and tempo traveller services.",
            "url": "https://saitirthatravels.com/shirdi-to-saptashrungi-taxi-fare",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/saptashrungi-taxi.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "300"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi to Saptashrungi Taxi Fare",
                "Shirdi to Saptashrungi Cab Package",
                "Shirdi to Saptashrungi Vani Cab Booking",
                "Shirdi to Vani Gad Cab Booking",
                "Shirdi to Vani Cab Booking",
                "Shirdi to Vani Saptashrungi Taxi Service",
                "Shirdi to Saptashrungi Darshan Cab Booking",
                "Shirdi to Saptashrungi Tour Package",
                "Shirdi to Saptashrungi Taxi Fare",
                "Shirdi to Vani Saptashrungi Mini Bus Booking",
                "Shirdi to Vani Saptashrungi Tempo Traveller on Rent",
                "Shirdi to Nashik Darshan Cab Service"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/79.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirditoswaptashrungi;