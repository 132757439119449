
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Shirditomatheran() {



  const cardData =
  {
    keyword: ' Shirdi to Matheran Taxi',
    heading: 'Saitirtha Travels: Shirdi to Matheran Taxi',
    headingDescription: 'When it comes to traveling from Shirdi to Matheran, Saitirtha Travels stands out as the premier choice for discerning travelers. We understand that your journey should be as enjoyable as the destination itself. Our commitment to providing top-notch taxi services ensures that you have a comfortable, reliable, and seamless travel experience, allowing you to make the most of your time in Matheran.',

    top: 'Top Places to Visit in Matheran with Saitirtha Travels',

  "topPlaces": [
    {
        "title": "1. Venna Lake",
        "description": "Venna Lake is a picturesque destination surrounded by lush greenery, offering a variety of boating facilities. Visitors can enjoy pedal boats and horse rides while taking in the serene views. The lake is a popular picnic spot, making it ideal for families and couples seeking a peaceful retreat."
    },
    {
        "title": "2. Pratapgad Fort",
        "description": "Built in the 17th century, Pratapgad Fort is a historic site that provides breathtaking views of the surrounding valleys and hills. With its impressive architecture and rich history, the fort is a must-visit for history enthusiasts and trekkers looking to explore its cultural significance."
    },
    {
        "title": "3. Arthur’s Seat",
        "description": "Known as the 'Queen of the Points,' Arthur's Seat offers stunning panoramic views of the valley below. This viewpoint is famous for its unique geological formations and is a popular spot for photography and nature lovers wishing to capture the beauty of the landscape."
    },
    {
        "title": "4. Mahabaleshwar Temple",
        "description": "This ancient temple dedicated to Lord Shiva is one of the most significant religious sites in the region. The temple's beautiful architecture and spiritual ambiance attract numerous pilgrims and tourists, making it a central part of Mahabaleshwar's cultural heritage."
    },
    {
        "title": "5. Kate's Point",
        "description": "Kate's Point is another popular viewpoint that provides breathtaking views of the Western Ghats and the Krishna Valley. It is an ideal spot for watching the sunset and enjoying the natural beauty of the region, attracting both tourists and local visitors alike."
    },
    {
        "title": "6. Mapro Garden",
        "description": "A well-known strawberry farm and garden, Mapro Garden is famous for its delicious strawberry-based products. Visitors can indulge in fresh strawberries, ice creams, and various snacks while exploring the beautiful surroundings of the garden, making it a delightful stop for food lovers."
    },
    {
        "title": "7. Lingmala Waterfall",
        "description": "Lingmala Waterfall is a stunning natural attraction located amidst dense forests. Particularly captivating during the monsoon season, the trek to the waterfall is refreshing, and the sight of the cascading water is mesmerizing, making it a perfect spot for nature enthusiasts."
    },
    {
        "title": "8. Elephant's Head Point",
        "description": "Named for its resemblance to an elephant's head, this viewpoint offers breathtaking vistas of the surrounding hills and valleys. It's a great spot for a leisurely walk and capturing stunning photographs of the natural beauty that defines Mahabaleshwar."
    },
    {
        "title": "9. Tapola",
        "description": "Often referred to as the 'Mini Mahabaleshwar,' Tapola is a serene village located near Mahabaleshwar. Known for its scenic beauty, it is the perfect destination for boating and enjoying the tranquility of nature, making it a favored spot for those looking to unwind."
    },
    {
        "title": "10. Dhobi Waterfall",
        "description": "Dhobi Waterfall is a beautiful waterfall situated just a few kilometers from Mahabaleshwar. Its lush surroundings and the sound of flowing water create a peaceful atmosphere, making it an ideal spot for picnics and nature walks for families and groups."
    }
],
    
            
        
"services": [
    {
        "name": "Shirdi to Matheran Taxi Service",
        "description": "Experience a smooth and comfortable ride from Shirdi to the scenic town of Matheran with SaitirthaTours & Travels. Our reliable taxi service is designed to ensure you enjoy the breathtaking landscapes along the way, making your journey as pleasant as your destination."
    },
    {
        "name": "Shirdi to Matheran Cab Fare",
        "description": "We provide transparent and competitive cab fares from Shirdi to Matheran, ensuring affordability without sacrificing quality. Our pricing model is straightforward, so you can budget your trip with confidence and avoid any unexpected costs."
    },
    {
        "name": "Taxi Fare from Shirdi to Matheran",
        "description": "Enjoy transparent pricing with our detailed taxi fare breakdown for the trip from Shirdi to Matheran. We aim to make your travel planning easy and stress-free, with no hidden charges to worry about."
    },
    {
        "name": "Shirdi Matheran Car Rental",
        "description": "Choose from a range of rental cars, including comfortable sedans and spacious SUVs, tailored to fit your travel needs. Our well-maintained vehicles ensure a smooth ride, whether you're traveling solo or with family."
    },
    {
        "name": "Shirdi to Matheran One Way Taxi",
        "description": "Our one-way taxi service allows you to travel from Shirdi to Matheran without the hassle of round-trip commitments. Pay only for the distance you cover, making it a perfect option for those looking for flexibility in their travel plans."
    },
    {
        "name": "Shirdi to Matheran Cab Booking",
        "description": "Book your cab from Shirdi to Matheran easily through our user-friendly online cab booking system. With just a few clicks, you can secure your ride, ensuring a hassle-free reservation process and peace of mind for your journey."
    },
    {
        "name": "Shirdi to Matheran Travel by Taxi",
        "description": "Enjoy a relaxing trip from Shirdi to Matheran with our professional and comfortable taxi service. Our experienced drivers are knowledgeable about the area, ensuring a safe and enjoyable journey while you sit back and take in the scenic beauty."
    },
    {
        "name": "Shirdi Matheran Cab Rates",
        "description": "Our cab rates from Shirdi to Matheran are competitive and budget-friendly, making us a preferred choice for travelers. We cater to all types of budgets, ensuring that everyone can enjoy a comfortable and memorable ride."
    },
    {
        "name": "Shirdi to Matheran Taxi Fare per km",
        "description": "Our per-kilometer rates are affordable, allowing you to easily calculate the total cost of your journey. This transparency helps you manage your travel expenses more effectively and plan your trip accordingly."
    },
    {
        "name": "Affordable Shirdi Matheran Cab",
        "description": "SaitirthaTours & Travels offers a range of budget-friendly cab options from Shirdi to Matheran, ensuring that you can travel comfortably without breaking the bank. Quality service and affordability go hand in hand with us."
    },
    {
        "name": "Shirdi to Matheran Innova Taxi",
        "description": "Travel comfortably in an Innova with our taxi service from Shirdi to Matheran. This vehicle is ideal for families or small groups, providing ample space and comfort for all passengers, making your journey enjoyable."
    },
    {
        "name": "Shirdi to Matheran Innova Crysta",
        "description": "Experience luxury travel with our Innova Crysta rentals for your journey from Shirdi to Matheran. This premium vehicle offers enhanced comfort and style, ensuring a pleasant ride to your destination."
    },
    {
        "name": "Shirdi to Matheran Tempo Traveller on Rent",
        "description": "Ideal for group travel, our Tempo Traveller rental accommodates larger groups comfortably. Enjoy a spacious and comfortable journey from Shirdi to Matheran, with enough room for everyone and their luggage."
    },
    {
        "name": "Shirdi to Matheran Sedan Cabs",
        "description": "Our Sedan cabs provide a comfortable travel option for individuals or couples heading from Shirdi to Matheran. These vehicles are well-equipped for a pleasant ride, ensuring you arrive at your destination relaxed."
    },
    {
        "name": "Shirdi to Matheran One Day Trip",
        "description": "Plan a one-day trip from Shirdi to Matheran with our cab service, offering flexibility and convenience for a quick getaway. Enjoy a day filled with exploration and relaxation without the stress of lengthy travel."
    },
    {
        "name": "Contact Information",
        "description": "For bookings and inquiries, reach out to SaitirthaTours & Travels at +91 9923879009 / 9922479009. Our dedicated team is here to assist you in enjoying a seamless journey from Shirdi to Matheran, with personalized service to cater to your needs."
    }
],
"tableData": [
    ["- Shirdi to Matheran Taxi Service", "- Shirdi to Matheran Cab Fare"],
    ["- Taxi Fare from Shirdi to Matheran", "- Shirdi Matheran Car Rental"],
    ["- Shirdi to Matheran One Way Taxi", "- Shirdi to Matheran Cab Booking"],
    ["- Shirdi to Matheran Travel by Taxi", "- Shirdi Matheran Cab Rates"],
    ["- Shirdi to Matheran Taxi Fare Per KM", "- Affordable Shirdi Matheran Cab"],
    ["- Shirdi to Matheran Innova Taxi", "- Shirdi to Matheran Innova Crysta"],
    ["- Shirdi to Matheran Tempo Traveller on Rent", "- SaitirthaTours & Travels Shirdi"],
    ["- Shirdi to Matheran Sedan Cabs", "- Shirdi to Matheran One Day Trip"]
],
"whychoose": [
    {
        "WhyChooseheading": "Affordable Rates:",
        "WhyChoosedescription": "At Saitirtha Travels, we provide budget-friendly rates for our Shirdi to Matheran taxi services, designed to give you the best value. Our transparent pricing ensures there are no hidden fees, making it easy for you to plan your trip with confidence. Whether you're looking for a one-way trip or a round-trip journey, our competitive fares cater to your budget without compromising service quality."
    },
    {
        "WhyChooseheading": "Comfortable and Spacious Vehicles:",
        "WhyChoosedescription": "We understand that comfort is essential for long trips, which is why we offer a fleet of well-maintained and spacious vehicles, including sedans, SUVs, Innova Crystas, and even Tempo Travellers for larger groups. With ample legroom, clean interiors, and convenient luggage space, we aim to make your journey from Shirdi to Matheran as relaxing and enjoyable as possible."
    },
    {
        "WhyChooseheading": "Skilled Drivers:",
        "WhyChoosedescription": "Our drivers are experienced and knowledgeable about the best routes to Matheran, ensuring a smooth, efficient, and enjoyable travel experience. They are trained to prioritize your safety and comfort, and they are happy to share insights about landmarks and scenic spots along the way. With our drivers, you’ll enjoy not only a safe journey but also an engaging travel experience."
    },
    {
        "WhyChooseheading": "Easy Booking:",
        "WhyChoosedescription": "We strive to make booking a taxi from Shirdi to Matheran quick and easy. Our user-friendly online platform allows you to book your cab in a few simple steps. If you prefer assistance, our dedicated customer support team is available to help you book over the phone, answer questions, or accommodate special requests to make your trip seamless and convenient."
    },
    {
        "WhyChooseheading": "Flexible Timing:",
        "WhyChoosedescription": "We operate 24/7, giving you complete flexibility to schedule your journey at a time that suits you. Whether you prefer an early morning departure to explore Matheran during the day or a late-night journey, we’re here to accommodate your travel plans. With Saitirtha Travels, you can travel on your own terms without worrying about time constraints."
    },
    {
        "WhyChooseheading": "Safety First:",
        "WhyChoosedescription": "Your safety is our top priority. Our vehicles are regularly maintained and undergo thorough sanitization before each trip, ensuring a clean and hygienic environment. Additionally, our drivers are trained to follow all safety protocols to give you peace of mind throughout your journey. With Saitirtha Travels, you can trust that we prioritize your well-being from start to finish."
    }
]

        
  }


  const faqData = [
    {
        question: "What is the fare for a taxi from Shirdi to Matheran?",
        answer: "The fare generally ranges from INR 3,500 to INR 5,000, depending on the type of vehicle and current demand. For precise pricing, please check our website."
    },
    {
        question: "How long does the journey take?",
        answer: "The trip from Shirdi to Matheran typically takes about 6 to 7 hours, depending on traffic and road conditions."
    },
    {
        question: "Are there any additional fees?",
        answer: "Additional fees may apply for extra stops or if you choose a larger vehicle for your trip."
    },
    {
        question: "How can I book a taxi for this route?",
        answer: "You can easily book your taxi through our website or by contacting our customer support team for assistance."
    },
    {
        question: "What payment options are available?",
        answer: "We accept various payment methods, including credit/debit cards, UPI, and cash."
    },
    {
        question: "Can I modify or cancel my booking?",
        answer: "Yes, you can modify or cancel your booking by reaching out to customer support or through our website, following our cancellation policy."
    },
    {
        question: "Are the taxis cleaned before each trip?",
        answer: "Yes, all our vehicles are thoroughly sanitized before every journey to ensure your safety."
    },
    {
        question: "Can I request additional stops during the trip?",
        answer: "Yes, you can request reasonable extra stops for refreshments or sightseeing along the way."
    }
];

const testimonials = [
    {
        name: "Mr. Karan Singh",
        text: "My journey from Shirdi to Matheran with Saitirtha Travels was exceptional! The driver was punctual, and the car was very comfortable. I highly recommend their service!"
    },
    {
        name: "Mrs. Rina Kapoor",
        text: "Saitirtha Travels provided a fantastic taxi service for our trip to Matheran. The ride was smooth, and our driver was friendly and knowledgeable. A wonderful experience overall!"
    }
];




  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Shirdi to Matheran Taxi | SaitirthaTours & Travels</title>
    <meta name="description" content="Book a reliable and affordable taxi from Shirdi to Matheran with SaitirthaTours & Travels. One-way, round-trip, and comfortable journey options with professional drivers. Cab bookings available online." />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirtha Travels | Shirdi to Matheran Taxi | SaitirthaTours & Travels" />
    <meta property="og:description" content="Book a reliable and affordable taxi from Shirdi to Matheran with SaitirthaTours & Travels. One-way, round-trip, and comfortable journey options with professional drivers. Cab bookings available online." />
    <meta property="og:url" content="https://saitirthatravels.com/shirdi-to-matheran-taxi" />
    <meta property="og:image" content="https://saitirthatravels.com/images/shirdi-to-matheran-taxi.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Book a reliable and affordable taxi from Shirdi to Matheran with SaitirthaTours & Travels. One-way, round-trip, and comfortable journey options with professional drivers. Cab bookings available online.",
            "url": "https://saitirthatravels.com/shirdi-to-matheran-taxi",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/shirdi-to-matheran-taxi.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi to Matheran taxi service",
                "Shirdi to Matheran cab fare",
                "Taxi fare from Shirdi to Matheran",
                "Shirdi Matheran car rental",
                "Shirdi to Matheran one way taxi",
                "Shirdi to Matheran cab booking",
                "Shirdi to Matheran travel by taxi",
                "Shirdi Matheran cab rates",
                "Shirdi to Matheran taxi fare per km",
                "Affordable Shirdi Matheran cab",
                "Shirdi to Matheran Innova taxi",
                "Shirdi to Matheran Innova Crysta",
                "Shirdi to Matheran tempo traveller on rent",
                "Saitirthatours & travels Shirdi",
                "Shirdi to Matheran sedan cabs",
                "Shirdi to Matheran one day trip"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/37.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirditomatheran;