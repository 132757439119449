
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Shirditonashik() {



  const cardData =
  {
    keyword: 'Shirdi to Nasik Taxi Fare',
    heading: 'Saitirtha Travels: Shirdi to Nasik Taxi Fare ',
    headingDescription: 'When planning a trip from Shirdi to Nasik, Saitirtha Travels is your go-to choice for reliable and affordable taxi services. We understand the importance of a comfortable and hassle-free journey, especially when visiting a city renowned for its spiritual significance and scenic beauty. Our commitment to excellent service ensures that you can enjoy your travels without any stress, making the most of your time in Nasik.',

    top: 'Top Places to Visit in Nasik with Saitirtha Travels',

    topPlaces: [
        {
            "title": "1. Kumbh Mela",
            "description": "Held every twelve years, the Kumbh Mela is one of the largest religious gatherings in the world. Pilgrims flock to the banks of the Godavari River to take part in this holy event, seeking blessings and performing rituals. The vibrant atmosphere during the Kumbh Mela is filled with spiritual fervor, making it a unique experience for visitors."
        },
        {
            "title": "2. Shree Kalaram Sansthan Mandir",
            "description": "This ancient temple is dedicated to Lord Rama and is significant in Hindu mythology. Believed to be the place where Lord Rama and Sita worshipped, the temple features exquisite architecture and attracts numerous devotees. Visitors can explore the temple’s serene surroundings and immerse themselves in its spiritual ambiance."
        },
        {
            "title": "3. Sula Vineyards",
            "description": "Located on the outskirts of Nasik, Sula Vineyards is a popular destination for wine enthusiasts. Visitors can enjoy guided tours of the vineyard, learn about the wine-making process, and indulge in wine tastings. The picturesque setting of the vineyards, combined with beautiful views of the surrounding hills, makes it an ideal spot for relaxation and enjoyment."
        },
        {
            "title": "4. Anjneri Hill",
            "description": "Known for its trekking opportunities and panoramic views, Anjneri Hill is a must-visit for adventure lovers. The hill is believed to be the birthplace of Lord Hanuman and features a temple dedicated to him. The trek to the top is rewarding, offering stunning vistas of the lush landscape and surrounding valleys."
        },
        {
            "title": "5. Pandav Leni Caves",
            "description": "These ancient rock-cut caves date back to the 1st century BCE and are significant for their historical and archaeological value. Visitors can explore the intricately carved caves, which house various Buddhist sculptures and chaityas. The serene environment and rich history make the Pandav Leni Caves a fascinating destination for culture enthusiasts."
        },
        {
            "title": "6. Nashik Clock Tower",
            "description": "A prominent landmark in the city, the Nashik Clock Tower is a beautiful structure that showcases the colonial architecture of the region. It serves as a central point in the city, and visitors often gather around it to enjoy the bustling atmosphere of nearby markets and shops."
        },
        {
            "title": "7. Ganga Ghat",
            "description": "Located along the banks of the Godavari River, Ganga Ghat is a tranquil spot for visitors seeking a peaceful experience. The ghat is ideal for taking a leisurely stroll, enjoying a boat ride, or simply soaking in the natural beauty of the river and its surroundings."
        },
        {
            "title": "8. Muktidhaam Temple",
            "description": "A unique temple complex dedicated to various Hindu deities, Muktidhaam is constructed using pristine white marble. The temple features replicas of the famous Jyotirlingas, making it a significant pilgrimage site. Visitors can explore the intricate carvings and serene atmosphere that the temple offers."
        },
        {
            "title": "9. Brahmagiri Hill",
            "description": "This hill is known for its historical significance and scenic views. It is believed to be the site where the sage Vyas wrote the Mahabharata. The trek to the top rewards visitors with stunning views of the surrounding landscape, making it a popular spot for nature lovers and trekkers."
        },
        {
            "title": "10. Local Markets",
            "description": "Nasik is famous for its vibrant markets where visitors can shop for traditional handicrafts, textiles, and local produce. Engaging with local vendors provides a glimpse into the region's culture and allows visitors to take home unique souvenirs."
        }
    ],
            
        
    
    "services": [
        {
            "name": "Shirdi to Nasik Taxi Fare",
            "description": "Traveling from Shirdi to Nasik is a popular choice for many visitors looking to explore the vibrant culture and attractions of this renowned city. At SaitirthaTours & Travels, we offer a comprehensive range of taxi services designed to meet your travel needs efficiently and comfortably."
        },
        {
            "name": "Shirdi to Nasik Taxi Service",
            "description": "Our Shirdi to Nasik taxi service provides you with a seamless travel experience. Our professional drivers are well-versed in the best routes, ensuring you arrive at your destination on time and relaxed."
        },
        {
            "name": "Shirdi to Nasik Cab Fare",
            "description": "The Shirdi to Nasik cab fare is structured to be competitive, with transparent pricing to help you plan your budget effectively. We aim to offer the best value for our services without compromising on quality."
        },
        {
            "name": "Shirdi Nasik Car Rental",
            "description": "Looking for flexibility in your travel plans? Our Shirdi Nasik car rental options allow you to choose the vehicle that best suits your needs, whether it's for a day trip or an extended stay."
        },
        {
            "name": "Shirdi to Nasik One Way Taxi",
            "description": "For those who need a straightforward travel option, our Shirdi to Nasik one way taxi service is ideal. Enjoy a hassle-free ride without worrying about the return trip."
        },
        {
            "name": "Shirdi to Nasik Cab Booking",
            "description": "Booking your Shirdi to Nasik cab is quick and easy with our user-friendly platform. Whether you prefer to book online or over the phone, we ensure a smooth process to secure your transportation."
        },
        {
            "name": "Traveling Shirdi to Nasik by Taxi",
            "description": "Traveling Shirdi to Nasik by taxi offers the convenience of door-to-door service. Avoid the stress of public transport and enjoy the comfort of a private vehicle during your journey."
        },
        {
            "name": "Shirdi Nasik Cab Rates",
            "description": "Our Shirdi Nasik cab rates are designed to be affordable, with clear pricing that is communicated upfront. We strive to provide the best service at the most competitive rates."
        },
        {
            "name": "Shirdi to Nasik Taxi Fare per KM",
            "description": "Understanding the Shirdi to Nasik taxi fare per km is crucial for budget planning. We offer transparent pricing based on distance, ensuring you know exactly what to expect."
        },
        {
            "name": "Affordable Shirdi Nasik Cab",
            "description": "Looking for an affordable Shirdi Nasik cab? Our services are tailored to fit your budget, offering excellent value without sacrificing comfort or reliability."
        },
        {
            "name": "Shirdi to Nasik Cabs",
            "description": "Our fleet of Shirdi to Nasik cabs includes various vehicle options to cater to different group sizes and preferences, ensuring you have the right ride for your journey."
        },
        {
            "name": "Shirdi to Nasik Taxi",
            "description": "Choosing a Shirdi to Nasik taxi from SaitirthaTours & Travels means prioritizing your comfort and safety throughout your trip. Our vehicles are well-maintained and equipped for a pleasant ride."
        },
        {
            "name": "Shirdi to Nashik Cab",
            "description": "We provide reliable Shirdi to Nashik cab services for all your travel needs. Experience personalized service that focuses on your satisfaction."
        },
        {
            "name": "Taxi From Shirdi to Nashik",
            "description": "Our taxi from Shirdi to Nashik is perfect for those wishing to explore the beautiful sights and rich culture of Nashik. Enjoy the scenic journey with our experienced drivers."
        },
        {
            "name": "Shirdi to Nashik One Way Cab Service",
            "description": "For a convenient travel option, our Shirdi to Nashik one way cab service allows you to reach your destination without the need for a return trip, perfect for travelers on a tight schedule."
        },
        {
            "name": "Shirdi to Nashik Places to Visit",
            "description": "While traveling from Shirdi to Nashik, don’t miss out on exploring places to visit in Nashik, including the famous Kumbh Mela sites, wineries, and temples. Our drivers can guide you on the best attractions."
        },
        {
            "name": "Shirdi to Nashik Tempo Traveller Hire",
            "description": "If you're traveling in a larger group, consider our Shirdi to Nashik tempo traveller hire service, offering spacious seating and comfort for all passengers."
        },
        {
            "name": "Shirdi to Nashik Airport Cabs Booking",
            "description": "For those arriving at or departing from the airport, we offer convenient Shirdi to Nashik airport cabs booking, ensuring a seamless travel experience."
        },
        {
            "name": "Shirdi to Nashik Ertiga Cab Booking",
            "description": "Our Shirdi to Nashik Ertiga cab booking service provides a comfortable ride for families or groups looking for a little extra space during their journey."
        },
        {
            "name": "Shirdi to Nashik Innova Cab",
            "description": "Travel in style with our Shirdi to Nashik Innova cab options, perfect for those seeking a more luxurious travel experience."
        },
        {
            "name": "Shirdi to Nashik 7 Seater Cab",
            "description": "For larger groups, our Shirdi to Nashik 7 seater cab offers ample room and comfort, making it an excellent choice for family trips or group outings."
        },
        {
            "name": "Shirdi to Nashik Urbania Bus",
            "description": "We also provide options like the Shirdi to Nashik Urbania bus, ideal for travelers preferring bus transportation over private taxis."
        },
        {
            "name": "Shirdi to Nashik Mini Bus Booking",
            "description": "For larger groups, our Shirdi to Nashik mini bus booking service ensures that everyone travels together comfortably."
        },
        {
            "name": "Mini Bus On Rent in Shirdi",
            "description": "Explore our mini bus on rent in Shirdi service, perfect for school trips, corporate outings, or family gatherings."
        },
        {
            "name": "Shirdi to Nashik Taxi Fare",
            "description": "Understanding the Shirdi to Nashik taxi fare helps in planning your budget. Our pricing is competitive, with no hidden fees, ensuring a reliable and cost-effective travel solution."
        },
        {
            "name": "Shirdi Airport to Nashik Cab",
            "description": "If you're arriving at Shirdi airport, we provide a direct Shirdi airport to Nashik cab service for your convenience, ensuring a smooth connection between travel points."
        },
        {
            "name": "Contact Number for Shirdi to Nasik",
            "description": "For prompt and efficient Shirdi to Nasik services, contact Shirdi Saibaba Travels at +91 9923879009 or +91 9922479009. Our dedicated team is here to ensure you have a smooth and enjoyable ride."
        }
    ],    
    "tableData": [
        ["- Shirdi to Nasik Taxi Service", "- Shirdi to Nasik Cab fare"],
        ["- SaitirthaTours & Travels Shirdi", "- Shirdi Nasik Car Rental"],
        ["- Shirdi to Nasik One Way Taxi", "- Shirdi to Nasik Cab Booking"],
        ["- Shirdi to Nasik Travel by Taxi", "- Shirdi Nasik Cab Rates"],
        ["- Shirdi to Nasik Taxi Fare per KM", "- Affordable Shirdi Nasik Cab"],
        ["- Shirdi to Nasik Cabs", "- Shirdi to Nasik Taxi"],
        ["- Shirdi to Nashik Cab", "- Taxi From Shirdi to Nashik"],
        ["- Shirdi to Nashik One Way Cab Service", "- Shirdi to Nashik Places to Visit"],
        ["- Shirdi to Nashik Tempo Traveller Hire", "- Shirdi to Nashik Airport Cabs Booking"],
        ["- Shirdi to Nashik Ertiga Cab Booking", "- Shirdi to Nashik Innova Cab"],
        ["- Shirdi to Nashik 7 Seater Cab", "- Shirdi to Nashik Urbania Bus"],
        ["- Shirdi to Nashik Mini Bus Booking", "- Mini Bus On Rent in Shirdi"],
        ["- Shirdi to Nashik Taxi Fare", "- Shirdi Airport to Nashik Cab"]
    ],
    "whychoose": [
        {
            "WhyChooseheading": "Comfortable Travel Experience:",
            "WhyChoosedescription": "We ensure a pleasant journey from Shirdi to Nasik in our well-maintained and spacious vehicles, making your travel comfortable and enjoyable. Our fleet includes a variety of options, from sedans to SUVs, allowing you to choose the perfect vehicle for your needs. Each vehicle is equipped with comfortable seating and air conditioning for a refreshing ride."
        },
        {
            "WhyChooseheading": "Expert Drivers:",
            "WhyChoosedescription": "Our professional drivers are familiar with the best routes and local traffic conditions, providing a safe and efficient ride. Their extensive knowledge ensures that you reach your destination via the quickest and safest paths. Plus, they are friendly and ready to assist you throughout your journey."
        },
        {
            "WhyChooseheading": "Punctual Service:",
            "WhyChoosedescription": "We value your time and guarantee timely pickups and drop-offs, so you can stick to your schedule without any hassle. Our commitment to punctuality means you can plan your itinerary with confidence. Whether you’re catching a flight or attending an event, we ensure you arrive on time."
        },
        {
            "WhyChooseheading": "Affordable and Transparent Pricing:",
            "WhyChoosedescription": "Our taxi fares are competitive with no hidden charges, allowing you to budget your travel expenses with confidence. We provide clear quotes upfront, so you can enjoy your trip without worrying about unexpected costs. Our pricing structure is designed to be simple and straightforward."
        },
        {
            "WhyChooseheading": "Easy Booking Process:",
            "WhyChoosedescription": "Booking a taxi from Shirdi to Nasik is straightforward, with options available online or through our dedicated customer service team. You can choose your preferred method of booking, ensuring maximum convenience for you. Our online platform is user-friendly and allows you to book a ride in just a few clicks."
        },
        {
            "WhyChooseheading": "24/7 Customer Support:",
            "WhyChoosedescription": "Our customer support team is available around the clock to assist you with any queries or support during your journey. Whether you have questions before your trip or need assistance during your ride, we are here to help you at all times. Your satisfaction is our top priority, and we strive to provide exceptional service."
        },
        {
            "WhyChooseheading": "Customized Travel Solutions:",
            "WhyChoosedescription": "We understand that each traveler has unique needs. Whether you’re traveling alone, with family, or in a large group, we can customize our services to meet your specific requirements, ensuring a tailored travel experience. Our diverse fleet allows for a range of vehicle sizes, from compact cars to larger vans."
        },
        {
            "WhyChooseheading": "Local Insights and Recommendations:",
            "WhyChoosedescription": "Our drivers are not only skilled at navigating the roads but can also provide valuable insights about Nashik, including places to visit, local cuisine to try, and must-see attractions. Their local knowledge enhances your travel experience, helping you discover hidden gems along the way."
        },
        {
            "WhyChooseheading": "Commitment to Safety:",
            "WhyChoosedescription": "Safety is our top priority. All our vehicles undergo regular maintenance and safety inspections, and our drivers are trained in safe driving practices to ensure a secure journey from start to finish. We also comply with all local regulations to guarantee a safe travel experience."
        },
        {
            "WhyChooseheading": "Flexible Itineraries:",
            "WhyChoosedescription": "We offer flexible itineraries to accommodate changes in your travel plans. Whether you need to make additional stops or change your destination, we can adjust our services to suit your needs without any hassle. Our goal is to provide you with a seamless travel experience that adapts to your schedule."
        },
        {
            "WhyChooseheading": "Sustainable Travel Practices:",
            "WhyChoosedescription": "We are committed to reducing our environmental impact. Our fleet includes vehicles that are maintained for optimal fuel efficiency, and we encourage eco-friendly travel practices to contribute to a greener planet. We believe in promoting responsible tourism while ensuring your comfort and convenience."
        },
        {
            "WhyChooseheading": "Value-Added Services:",
            "WhyChoosedescription": "In addition to taxi services, we offer value-added services such as guided tours, assistance with itinerary planning, and recommendations for local attractions. This ensures that your travel experience is not just about getting from point A to B, but about making the most of your time in Nashik."
        }
    ]

        
  }


  const faqData = [
    {
      question: "What is the fare for a taxi from Shirdi to Nasik?",
      answer: "The fare generally ranges from INR 1,200 to INR 1,500, depending on the vehicle type and specific requests. Please contact us for the most accurate pricing."
    },
    {
      question: "How long does the journey from Shirdi to Nasik take?",
      answer: "The trip typically takes around 4 to 5 hours, depending on traffic conditions."
    },
    {
      question: "Are there any extra charges?",
      answer: "Additional charges may apply for nighttime travel (after 10 PM) or for any unscheduled stops along the way."
    },
    {
      question: "How can I book a taxi from Shirdi to Nasik?",
      answer: "You can book your taxi easily through our website or by calling our customer support for assistance."
    },
    {
      question: "What payment methods do you accept?",
      answer: "We accept various payment options, including credit/debit cards, UPI, and cash for your convenience."
    },
    {
      question: "Can I cancel my booking?",
      answer: "Yes, we offer flexible cancellation options. Please refer to our cancellation policy on the website for more details."
    },
    {
      question: "Are the taxis sanitized before each trip?",
      answer: "Yes, all our vehicles are thoroughly cleaned and sanitized before every journey to ensure your safety."
    },
    {
      question: "Can I request additional stops during the trip?",
      answer: "Yes, you can request reasonable stops for refreshments or sightseeing along the route."
    }
  ];
  
  const testimonials = [
    {
      name: "Mr. Ramesh Thakur",
      text: "I had a great experience with Saitirtha Travels for my trip from Shirdi to Nasik. The driver was professional and knew the route well. The ride was comfortable, and I appreciated the clear pricing. Highly recommend!"
    },
    {
      name: "Ms. Pooja Mehta",
      text: "Saitirtha Travels made my journey from Shirdi to Nasik stress-free. The booking process was easy, and our driver was friendly and informative. The vehicle was clean and spacious, making for a wonderful trip!"
    }
  ];
  
  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>

<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Shirdi to Nasik Taxi Fare | SaitirthaTours & Travels</title>
    <meta name="description" content="Book an affordable and reliable taxi from Shirdi to Nasik with SaitirthaTours & Travels. Enjoy a comfortable ride to Nashik with competitive rates, one-way and round-trip options, and multiple vehicle choices." />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirtha Travels | Shirdi to Nasik Taxi Fare | SaitirthaTours & Travels" />
    <meta property="og:description" content="Book an affordable and reliable taxi from Shirdi to Nasik with SaitirthaTours & Travels. Enjoy a comfortable ride to Nashik with competitive rates, one-way and round-trip options, and multiple vehicle choices." />
    <meta property="og:url" content="https://saitirthatravels.com/shirdi-to-nasik-taxi-fare" />
    <meta property="og:image" content="https://saitirthatravels.com/images/shirdi-to-nasik-taxi.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Book an affordable and reliable taxi from Shirdi to Nasik with SaitirthaTours & Travels. Enjoy a comfortable ride to Nashik with competitive rates, one-way and round-trip options, and multiple vehicle choices.",
            "url": "https://saitirthatravels.com/shirdi-to-nasik-taxi-fare",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/shirdi-to-nasik-taxi.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi to Nasik Taxi Service", 
                "Shirdi to Nasik Cab Fare", 
                "SaitirthaTours & Travels Shirdi", 
                "Shirdi Nasik Car Rental", 
                "Shirdi to Nasik One Way Taxi", 
                "Shirdi to Nasik Cab Booking", 
                "Shirdi to Nasik Travel by Taxi", 
                "Shirdi Nasik Cab Rates", 
                "Shirdi to Nasik Taxi Fare per km", 
                "Affordable Shirdi Nasik Cab", 
                "Shirdi to Nasik Cabs", 
                "Shirdi to Nasik Taxi", 
                "Shirdi to Nashik Cab", 
                "Taxi From Shirdi to Nashik", 
                "Shirdi to Nashik One Way Cab Service", 
                "Shirdi to Nashik Places to Visit", 
                "Shirdi to Nashik Tempo Traveller Hire", 
                "Shirdi to Nashik Airport Cabs Booking", 
                "Shirdi to Nashik Ertiga Cab Booking", 
                "Shirdi to Nashik Innova Cab", 
                "Shirdi to Nashik 7 Seater Cab", 
                "Shirdi to Nashik Urbania Bus", 
                "Shirdi to Nashik Mini Bus Booking", 
                "Mini Bus On Rent in Shirdi", 
                "Shirdi to Nashik Taxi Fare", 
                "Shirdi Airport to Nashik Cab"
            ]
        })}
    </script>
</Helmet>

      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/27.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirditonashik;