
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Shirditogujrat() {



  const cardData =
  {
    keyword: ' Shirdi to Gujarat Cab Services ',
    heading: 'Saitirtha Travels: Shirdi to Gujarat Cab Services ',
    headingDescription: 'Saitirtha Travels offers convenient and reliable cab services from Shirdi to various destinations across Gujarat. Whether you are traveling for business, pilgrimage, or leisure, our cab service ensures a comfortable and hassle-free journey from Shirdi to key cities and tourist spots in Gujarat.',

    top: 'Top Places to Visit in Gujarat with Saitirtha Travels',

    topPlaces: [
        {
            "title": "Somnath Temple",
            "description": "A prominent Jyotirlinga temple, Somnath is one of India's most revered pilgrimage sites. Known for its stunning architecture and seaside location, this temple holds great historical and spiritual significance."
        },
        {
            "title": "Dwarkadhish Temple",
            "description": "Located in Dwarka, this ancient temple is dedicated to Lord Krishna and is one of the Char Dham pilgrimage sites. Its intricate carvings and majestic design make it a must-visit for devotees and tourists alike."
        },
        {
            "title": "Gir National Park",
            "description": "Home to the Asiatic lion, Gir National Park is a famous wildlife sanctuary offering safaris where visitors can spot lions, leopards, and various species of birds in their natural habitat."
        },
        {
            "title": "Sabarmati Ashram",
            "description": "Situated on the banks of the Sabarmati River in Ahmedabad, this ashram was the residence of Mahatma Gandhi. It's now a museum showcasing his life and contributions to India's independence."
        },
        {
            "title": "Rann of Kutch",
            "description": "Known for its vast white salt desert, the Rann of Kutch offers a surreal landscape, especially during the Rann Utsav, a cultural festival that highlights local music, dance, and handicrafts."
        },
        {
            "title": "Statue of Unity",
            "description": "Standing tall at 182 meters, the Statue of Unity is the world's tallest statue, dedicated to Sardar Vallabhbhai Patel. It's located near the Sardar Sarovar Dam and offers panoramic views of the surrounding area."
        },
        {
            "title": "Saputara Hill Station",
            "description": "Gujarat’s only hill station, Saputara offers a cool retreat with lush greenery, scenic landscapes, and activities like boating, trekking, and ropeway rides."
        },
        {
            "title": "Laxmi Vilas Palace, Vadodara",
            "description": "This grand palace is the residence of the royal Gaekwad family and is one of the most stunning palaces in India. It showcases Indo-Saracenic architecture and includes a museum with royal artifacts."
        },
        {
            "title": "Akshardham Temple, Gandhinagar",
            "description": "Known for its intricate carvings and architectural beauty, Akshardham Temple is a popular spiritual site with exhibitions, gardens, and a water show that attracts visitors from all over."
        },
        {
            "title": "Modhera Sun Temple",
            "description": "A masterpiece of Solanki architecture, this ancient temple is dedicated to the Sun God and is known for its detailed carvings and the beautiful step-well within its complex."
        }
    ],  
    
            
        
"services": [
    {
        "name": "Shirdi to Ahmedabad Cab",
        "description": "Our Shirdi to Ahmedabad Cab service provides a direct and comfortable journey to Gujarat’s vibrant capital. Whether you're visiting for business, leisure, or spiritual reasons, our well-maintained vehicles and professional drivers ensure a smooth and hassle-free travel experience. Enjoy the convenience of direct transportation to Ahmedabad with flexible pick-up times and personalized service."
    },
    {
        "name": "Shirdi to Surat Taxi Service",
        "description": "With our Shirdi to Surat Taxi Service, you can explore the bustling city of Surat in comfort. Known for its textile industry and historical significance, Surat offers a unique blend of culture and commerce. Our experienced drivers will ensure you have a relaxing and safe journey, with a comfortable vehicle tailored to your preferences."
    },
    {
        "name": "Shirdi to Vadodara Taxi Fare",
        "description": "Check out our affordable Shirdi to Vadodara Taxi Fare for a cost-effective travel option to this cultural hub of Gujarat. Whether you're visiting to explore the Laxmi Vilas Palace, the Kirti Mandir, or simply sightseeing, our clean and well-maintained taxis offer a smooth and comfortable ride. Transparent pricing ensures no hidden charges, making it the perfect choice for travelers seeking a relaxed journey."
    },
    {
        "name": "Shirdi to Rajkot Innova Crysta Cab Booking",
        "description": "Travel in luxury with our Shirdi to Rajkot Innova Crysta Cab Booking service. Ideal for families and groups, the Innova Crysta offers ample space, premium comfort, and advanced features to ensure a pleasant journey to Rajkot. Whether for business or leisure, this vehicle provides the perfect balance of luxury and convenience."
    },
    {
        "name": "Shirdi to Jamnagar Ertiga Car",
        "description": "Book our Shirdi to Jamnagar Ertiga Car for a reliable and cozy ride. The Ertiga offers ample space for passengers and luggage, making it ideal for families and small groups. With experienced drivers who prioritize your comfort and safety, your journey to Jamnagar will be smooth and enjoyable."
    },
    {
        "name": "Shirdi to Bhavnagar Innova Cab",
        "description": "Our Shirdi to Bhavnagar Innova Cab service provides a convenient travel option for those heading to Bhavnagar, a city known for its educational institutions and historical landmarks. The Innova is the perfect choice for a comfortable and spacious ride, ensuring that you arrive at your destination feeling relaxed and refreshed."
    },
    {
        "name": "Shirdi to Gujarat Kia Carens Cab Booking",
        "description": "For those seeking a touch of modern luxury, our Shirdi to Gujarat Kia Carens Cab Booking offers a stylish and comfortable ride. The Kia Carens is a versatile vehicle, perfect for both long trips and city exploration. With its spacious interiors and advanced features, it is an excellent choice for travelers who want both comfort and style."
    },
    {
        "name": "Shirdi to Gujarat Tempo Traveller on Rent",
        "description": "For larger groups, our Shirdi to Gujarat Tempo Traveller on Rent is the ideal choice. With ample space for passengers and luggage, this vehicle ensures that everyone travels together comfortably. Whether you're visiting family, exploring tourist attractions, or on a spiritual journey, our Tempo Traveller offers the convenience and comfort of group travel."
    },
    {
        "name": "Shirdi to Gujarat Sedan Cab",
        "description": "Our Shirdi to Gujarat Sedan Cab offers an affordable and efficient travel option for small groups or solo travelers. With comfortable seating and a smooth ride, the sedan is a great way to travel across Gujarat, whether for business, leisure, or pilgrimage. Enjoy a relaxed journey with a reliable and professional driver."
    },
    {
        "name": "Shirdi Airport to Navsari Swift Dzire Taxi",
        "description": "Arriving at Shirdi Airport? Book our Shirdi Airport to Navsari Swift Dzire Taxi for a smooth and affordable ride directly from the airport to Navsari. The Swift Dzire is a compact yet comfortable vehicle, ideal for solo travelers or small groups. With our prompt and professional service, you can enjoy a hassle-free journey."
    },
    {
        "name": "Shirdi to Gujarat Somnath Jyotirlinga Tour",
        "description": "Embark on a spiritual journey with our Shirdi to Gujarat Somnath Jyotirlinga Tour. Visit the sacred Somnath Temple, one of the 12 Jyotirlingas, and immerse yourself in its divine atmosphere. Our well-planned itinerary ensures a comfortable journey with guided tours, making your pilgrimage to Somnath seamless and enriching."
    },
    {
        "name": "Shirdi to Gandhinagar 17 Seater Bus on Rent",
        "description": "Our Shirdi to Gandhinagar 17 Seater Bus on Rent is perfect for large groups or families visiting Gandhinagar, the capital of Gujarat. Travel together in comfort with plenty of space for everyone. Whether you're visiting the Akshardham Temple, the Mahatma Mandir, or exploring the city’s other landmarks, our spacious bus offers the convenience of group travel with professional drivers ensuring your safety."
    },
    {
        "name": "Shirdi to Gujarat Cab Contact Information",
        "description": "For Shirdi to Gujarat Cab bookings, or if you have any questions, reach out to SaitirthaTours & Travels at +91 9923879009 / 9922479009. Our team is dedicated to providing you with a seamless and enjoyable travel experience. Whether you're planning a pilgrimage, business trip, or family vacation, we ensure your journey to Gujarat is smooth, comfortable, and memorable."
    }
],

"tableData": [
    ["- Shirdi to Ahmedabad Cab", "- Shirdi to Surat Taxi Service"],
    ["- Shirdi to Vadodara Taxi Fare", "- Shirdi to Rajkot Innova Crysta Cab Booking"],
    ["- Shirdi to Jamnagar Ertiga Car", "- Shirdi to Bhavnagar Innova Cab"],
    ["- Shirdi to Gujarat Kia Carens Cab Booking", "- Shirdi to Gujarat Tempo Traveller on Rent"],
    ["- Shirdi to Gujarat Sedan Cab", "- Shirdi Airport to Navsari Swift Dzire Taxi"],
    ["- Shirdi to Gujarat Somnath Jyotirlinga Tour", "- Shirdi to Gandhinagar 17 Seater Bus on Rent"],
    ["", ""],
    ["- SaitirthaTours & Travels Shirdi", ""]
  ],
  "whychoose": [
    {
      "WhyChooseheading": "Seamless Travel Experience",
      "WhyChoosedescription": "Enjoy a smooth journey with experienced drivers who are well-versed in the routes and ensure safe, timely arrivals. Our drivers are trained to handle various road conditions and provide a reliable travel experience, allowing you to relax and enjoy the ride."
    },
    {
      "WhyChooseheading": "Flexible Vehicle Options",
      "WhyChoosedescription": "We provide a range of vehicles from sedans to SUVs, catering to group sizes and travel preferences for maximum comfort. Whether you're traveling alone or with a large group, we have the perfect vehicle to meet your needs, ensuring a comfortable journey for everyone."
    },
    {
      "WhyChooseheading": "Affordable Rates with No Hidden Costs",
      "WhyChoosedescription": "We maintain transparency with competitive rates, ensuring no unexpected charges during your trip. Our pricing structure is clear and straightforward, helping you budget effectively for your travel expenses without any surprises."
    },
    {
      "WhyChooseheading": "Customized Itineraries",
      "WhyChoosedescription": "Our service allows for flexible travel plans, so you can request stops at scenic spots or notable landmarks along the way. We work with you to create an itinerary that matches your interests, enhancing your travel experience with personalized touches."
    },
    {
      "WhyChooseheading": "Professional and Courteous Drivers",
      "WhyChoosedescription": "Our drivers prioritize safety and customer satisfaction, offering a pleasant travel experience with helpful insights along the route. They are not just drivers but also guides who are eager to share local knowledge and recommendations to enrich your journey."
    },
    {
      "WhyChooseheading": "Convenient Booking and Support",
      "WhyChoosedescription": "With easy booking options and 24/7 customer support, your journey is just a call or click away. We understand that travel plans can change, and our support team is always ready to assist you with any inquiries or adjustments you may need."
    },
    {
      "WhyChooseheading": "Sanitized and Maintained Fleet",
      "WhyChoosedescription": "Our cabs are sanitized and checked before every trip, providing a safe, clean environment for travelers. We prioritize your health and safety by ensuring our vehicles meet high cleanliness standards, making your travel experience worry-free."
    },
    {
      "WhyChooseheading": "Positive Customer Feedback",
      "WhyChoosedescription": "Our clients appreciate our reliable service, punctuality, and commitment to quality, making us a trusted choice for long-distance travel. We take pride in our reputation and continuously strive to exceed our customers' expectations with every trip."
    }
  ]





        
  }


  const faqData = [
    {
        question: "How long does it take to reach Gujarat from Shirdi?",
        answer: "The travel time to Gujarat varies by destination but generally takes around 8-10 hours."
    },
    {
        question: "Can I choose the type of cab?",
        answer: "Yes, we offer options including sedans, SUVs, and more to suit different group sizes and preferences."
    },
    {
        question: "Are round-trip bookings available?",
        answer: "Yes, both one-way and round-trip bookings are available for this route."
    },
    {
        question: "Are tolls included in the fare?",
        answer: "Tolls are typically separate and paid as per actuals during the journey."
    },
    {
        question: "Is it possible to make stops during the trip?",
        answer: "Absolutely! Let us know if you’d like to include stops, and we’ll plan accordingly."
    },
    {
        question: "What payment options do you accept?",
        answer: "We accept various payment methods, including online payments, cash, and bank transfers."
    },
    {
        question: "How flexible is your cancellation policy?",
        answer: "Our cancellation policy is customer-friendly. Contact support for details on any applicable charges."
    },
    {
        question: "Which cities in Gujarat do you cover?",
        answer: "We cover major cities like Ahmedabad, Surat, Vadodara, and Rajkot."
    },
    {
        question: "Can I bring extra luggage?",
        answer: "Please inform us of any extra luggage requirements during booking to ensure the right vehicle."
    },
    {
        question: "How can I contact support during the journey?",
        answer: "Our customer support team is available by phone or email to assist with any needs."
    }
];

const testimonials = [
    {
        name: "Mr. Shubham Patil",
        text: "Our trip from Shirdi to Gujarat with Saitirtha Travels was absolutely wonderful! The driver was knowledgeable and friendly, making our journey enjoyable. The temples in Gujarat were breathtaking, and everything was well-organized. I highly recommend this service!"
    },
    {
        name: "Mrs. Kavita Kapoor",
        text: "I booked a cab for our family trip to Gujarat from Shirdi, and it was a fantastic experience. The car was comfortable, and our driver took great care of us. We loved visiting the Mahakaleshwar Temple and exploring the city. Thank you, Saitirtha Travels, for a memorable journey!"
    }
];





  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
<Helmet>
    <title>Contact: +91 9923879009 | SaitirthaTours & Travels | Shirdi to Gujarat Cab | Best Taxi Service | SaitirthaTours & Travels</title>
    <meta name="description" content="Book an affordable Shirdi to Gujarat Cab with SaitirthaTours & Travels. Offering one-way, round-trip, and rental services for your journey to Ahmedabad, Surat, Vadodara, Rajkot, and other Gujarat destinations." />
    <meta property="og:title" content="Contact: +91 9923879009 | SaitirthaTours & Travels | Shirdi to Gujarat Cab | Best Taxi Service | SaitirthaTours & Travels" />
    <meta property="og:description" content="Book an affordable Shirdi to Gujarat Cab with SaitirthaTours & Travels. Offering one-way, round-trip, and rental services for your journey to Ahmedabad, Surat, Vadodara, Rajkot, and other Gujarat destinations." />
    <meta property="og:url" content="https://saitirthatravels.com/shirdi-to-gujarat-cab" />
    <meta property="og:image" content="https://saitirthatravels.com/images/shirdi-to-gujarat-cab.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Book an affordable Shirdi to Gujarat Cab with SaitirthaTours & Travels. Offering one-way, round-trip, and rental services for your journey to Ahmedabad, Surat, Vadodara, Rajkot, and other Gujarat destinations.",
            "url": "https://saitirthatravels.com/shirdi-to-gujarat-cab",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/shirdi-to-gujarat-cab.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi to Gujarat Cab",
                "Shirdi to Ahmedabad Cab",
                "Shirdi to Surat Taxi Service",
                "Shirdi to Vadodara Taxi Fare",
                "Shirdi to Rajkot Innova Crysta Cab Booking",
                "Shirdi to Jamnagar Ertiga Car",
                "Shirdi to Bhavnagar Innova Cab",
                "Shirdi to Gujarat Kia Carens Cab Booking",
                "Shirdi to Gujarat Tempo Traveller on Rent",
                "Shirdi to Gujarat Sedan Cab",
                "Shirdi Airport to Navsari Swift Dzire Taxi",
                "Shirdi to Gujarat Somnath Jyotirlinga Tour",
                "Shirdi to Gandhinagar 17 Seater Bus on Rent",
                "SaitirthaTours & Travels Shirdi"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/71.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirditogujrat;