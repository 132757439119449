
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Aurangabadairport() {



  const cardData =
  {
    keyword: ' Aurangabad Airport to Shirdi Cab',
    heading: 'Saitirtha Travels: Aurangabad Airport to Shirdi Cab',
    headingDescription: 'Traveling from Aurangabad Airport to Shirdi has never been easier with Saitirtha Travels. We offer reliable and comfortable cab services to ensure a smooth transition from the airport to the holy town of Shirdi. Our professional drivers are committed to providing a safe and pleasant journey, allowing you to focus on your pilgrimage or visit.',

    top: 'Top Places to Visit in Shirdi with Saitirtha Travels',

 "topPlaces": [
    {
        "title": "Sai Baba Samadhi Mandir",
        "description": "Sai Baba Samadhi Mandir is the spiritual centerpiece of Shirdi, dedicated to the revered saint Sai Baba. This magnificent temple draws millions of devotees each year, offering a serene atmosphere for worship and reflection. The grandeur of its architecture and the tranquil ambiance create a perfect retreat for visitors seeking spiritual solace. Within the temple, the Samadhi of Sai Baba serves as a focal point for prayers, where devotees come to seek blessings and connect with the divine."
    },
    {
        "title": "Dwarkamai Masjid",
        "description": "Dwarkamai Masjid, once a dwelling place for Sai Baba, holds immense significance for his followers. The site features a sacred flame (Dhuni) that has been burning continuously, symbolizing Baba’s teachings of love and compassion. Devotees gather here to pay their respects, immersing themselves in the spiritual energy that envelops this hallowed space. The unique architecture blends Hindu and Muslim influences, reflecting the universal appeal of Sai Baba’s message."
    },
    {
        "title": "Chavadi",
        "description": "Chavadi is a simple yet historically rich structure where Sai Baba would often rest. Steeped in reverence, it showcases various artifacts and photographs that narrate the story of Sai Baba’s life and his experiences in Shirdi. Visitors to Chavadi can experience the peaceful ambiance, making it an ideal spot for contemplation and connection with Baba’s legacy."
    },
    {
        "title": "Shri Khandoba Temple",
        "description": "Situated on a hilltop, Shri Khandoba Temple is an ancient temple dedicated to Lord Khandoba, offering breathtaking views and a serene environment. This temple is particularly vibrant during festivals, where the local culture and devotion come alive through colorful celebrations and rituals. The peaceful surroundings make it a perfect spot for visitors to enjoy nature and spirituality in harmony."
    },
    {
        "title": "Gurusthan",
        "description": "Gurusthan is a sacred site believed to be where Sai Baba first entered Shirdi, making it a place of deep reverence for devotees. Here, visitors can pay their respects at the tomb-like structure surrounded by a sacred neem tree, which adds to the spiritual ambiance. The serene atmosphere encourages visitors to reflect and seek blessings, embodying the essence of Sai Baba’s teachings."
    },
    {
        "title": "Shirdi Wet N Joy Water Park",
        "description": "Shirdi Wet N Joy Water Park is a fun and family-friendly destination featuring a variety of rides and attractions. This water park is perfect for unwinding and relaxing after a day of visiting spiritual sites in Shirdi. With its vibrant atmosphere and thrilling water activities, families can enjoy a refreshing break while creating lasting memories."
    },
    {
        "title": "Sainagar Railway Station",
        "description": "Sainagar Railway Station serves as a major transport hub, connecting Shirdi with various cities across India. This well-maintained station offers convenient access for pilgrims and tourists, ensuring a hassle-free travel experience. With its range of facilities, Sainagar Railway Station enhances the overall journey for visitors heading to this holy destination."
    },
    {
        "title": "Nashik",
        "description": "Located a short drive from Shirdi, Nashik is renowned for its vineyards and temples. Visitors can explore the Panchavati area, which holds significant religious importance, and experience wine tasting tours at local vineyards, blending spirituality with leisure. Nashik's rich cultural heritage and scenic beauty make it a delightful addition to any itinerary."
    },
    {
        "title": "Ahmednagar Fort",
        "description": "Ahmednagar Fort is a historical gem known for its architectural beauty and significant role in Indian history. Visitors can explore its expansive ruins, bastions, and historical artifacts, gaining insight into the region's past. The fort’s grandeur and strategic importance offer a fascinating glimpse into the historical narratives of Maharashtra."
    },
    {
        "title": "Bhandardara",
        "description": "Bhandardara is a picturesque destination ideal for nature lovers, offering stunning landscapes, waterfalls, and the tranquil Arthur Lake. This scenic getaway is perfect for those looking to relax and rejuvenate after visiting Shirdi. With opportunities for trekking, boating, and enjoying the beauty of nature, Bhandardara promises an enriching experience for all visitors."
    }
],
    
            
        
"services": [
    {
        "name": "Aurangabad Airport to Shirdi Cab",
        "description": "Experience a smooth and hassle-free journey from Aurangabad Airport to Shirdi with our reliable cab service. Our Aurangabad Airport to Shirdi cab service is tailored to provide you with utmost convenience and comfort, ensuring you arrive in Shirdi safely and on time. Whether you're a pilgrim visiting the sacred Shirdi Sai Baba temple or a tourist exploring the region, our service is designed to meet your travel needs."
    },
    {
        "name": "Aurangabad Airport Shirdi Taxi Fare",
        "description": "We offer competitive and transparent taxi fare rates for your journey from Aurangabad Airport to Shirdi, making it affordable to book with us. Our pricing is upfront, with no hidden fees or unexpected charges, ensuring you receive the best value for a safe and comfortable ride. Additionally, we regularly update our fare structure to remain competitive and accessible for all travelers."
    },
    {
        "name": "Best Cab from Aurangabad Airport to Shirdi",
        "description": "Our fleet includes a variety of vehicles to cater to all travel needs, from economy options to luxury rides. Whether you’re looking for an affordable taxi from Aurangabad Airport to Shirdi or a premium vehicle for a more luxurious experience, we have options that suit your preferences and budget. Our commitment to excellent service and customer satisfaction makes us the best choice for your travel needs."
    },
    {
        "name": "Cheap Taxi Aurangabad Airport to Shirdi",
        "description": "For budget-conscious travelers, our cheap taxi service from Aurangabad Airport to Shirdi ensures you get a quality ride without overspending. Enjoy affordable rates while traveling in well-maintained vehicles that prioritize your comfort and safety. We believe that cost-effective travel should not compromise the quality of service you receive."
    },
    {
        "name": "Book Cab Aurangabad Airport to Shirdi",
        "description": "Booking a cab from Aurangabad Airport to Shirdi is quick and easy with our service. We offer both online and offline booking options, so you can reserve your ride at your convenience. Our user-friendly booking platform ensures a hassle-free experience, allowing you to select your preferred vehicle and receive instant confirmation."
    },
    {
        "name": "One-Way Taxi Aurangabad Airport to Shirdi",
        "description": "Our one-way taxi service from Aurangabad Airport to Shirdi is perfect for travelers looking to reach Shirdi without the obligation of planning a return trip. This flexible option is often more economical, catering to those who prefer a straightforward journey. Experience the freedom of travel without the stress of round-trip arrangements."
    },
    {
        "name": "Round Trip Cab Aurangabad Airport to Shirdi",
        "description": "For those needing a return journey, our round trip cab service from Aurangabad Airport to Shirdi offers a convenient solution. Plan your trip with ease, knowing that your transportation needs for both directions are covered. Enjoy the peace of mind that comes with having a reliable cab ready to take you back at your preferred time."
    },
    {
        "name": "Car Rental Aurangabad Airport to Shirdi",
        "description": "We offer car rental services from Aurangabad Airport to Shirdi, allowing you to travel at your own pace and make stops as needed. With our flexible car rental options, you can customize your journey, whether it's for sightseeing or simply enjoying the beautiful landscape along the way. This option is perfect for travelers who prefer a bit more control over their travel itinerary."
    },
    {
        "name": "Reliable Cab Aurangabad Airport to Shirdi",
        "description": "Safety and reliability are our top priorities. Our cab service from Aurangabad Airport to Shirdi is operated by experienced drivers who know the best routes, ensuring you reach your destination smoothly and on time. All our vehicles are regularly maintained and sanitized for your comfort and peace of mind."
    },
    {
        "name": "Distance Aurangabad Airport to Shirdi Taxi",
        "description": "The distance between Aurangabad Airport and Shirdi is approximately 110 kilometers. Our drivers are well-versed in this route, making the journey efficient and enjoyable. Sit back, relax, and enjoy the scenic beauty of Maharashtra as you travel towards one of India’s most revered pilgrimage sites."
    },
    {
        "name": "Direct Cab Aurangabad Airport to Shirdi",
        "description": "Our direct cab service from Aurangabad Airport to Shirdi ensures a nonstop journey, eliminating unnecessary stops and delays. This dedicated service prioritizes getting you to your destination promptly, making it ideal for travelers on a tight schedule or those eager to reach Shirdi without interruptions."
    },
    {
        "name": "Sambhaji Nagar to Shirdi Cab Service",
        "description": "For travelers from Sambhaji Nagar (Aurangabad), our cab service provides a convenient, comfortable ride to Shirdi. Enjoy the reliability of our cabs, which offer a perfect blend of comfort and efficiency, allowing you to relax and focus on your spiritual journey to one of India's most sacred places."
    },
    {
        "name": "Aurangabad Airport to Shirdi Cab Contact Information",
        "description": "For more information or to book your Aurangabad Airport to Shirdi cab, contact SaitirthaTours & Travels at +91 9923879009 / 9922479009. Our dedicated team is here to assist you with any inquiries or booking needs, ensuring you have a seamless and pleasant travel experience. Let us help make your journey to Shirdi smooth and enjoyable."
    }
],
"tableData": [
    ["- Aurangabad Airport to Shirdi Cab Service", ""],
    ["- Aurangabad Airport Shirdi Taxi Fare", ""],
    ["- Best Cab from Aurangabad Airport to Shirdi", ""],
    ["- Cheap Taxi Aurangabad Airport Shirdi", ""],
    ["- Book Cab Aurangabad Airport to Shirdi", ""],
    ["- One-Way Taxi Aurangabad Airport Shirdi", ""],
    ["- Round Trip Cab Aurangabad Airport Shirdi", ""],
    ["- Car Rental Aurangabad Airport to Shirdi", ""],
    ["- Reliable Cab Aurangabad Airport Shirdi", ""],
    ["- Distance Aurangabad Airport Shirdi Taxi", ""],
    ["- Direct Cab Aurangabad Airport to Shirdi", ""],
    ["- Sambhaji Nagar to Shirdi Cab Service", ""],
    ["- SaitirthaTours & Travels Shirdi", ""]
],
"whychoose": [
    {
        "WhyChooseheading": "Convenient Transfers:",
        "WhyChoosedescription": "Our service ensures a smooth transition from Aurangabad Airport to Shirdi, which is essential for travelers eager to start their journey immediately upon arrival. We provide a meet-and-greet service where our drivers will be waiting for you at the airport with your name displayed on a sign, making it easy to locate your transportation. Our knowledgeable team is always prepared to assist with any special requirements you may have, such as luggage handling or adjusting your itinerary on the fly."
    },
    {
        "WhyChooseheading": "Comfortable Ride:",
        "WhyChoosedescription": "Your comfort is our priority. Our fleet consists of a wide range of vehicles, including sedans, SUVs, and vans, all equipped with plush seating, air conditioning, and ample legroom to ensure a relaxing journey. Whether you are traveling solo or with a group, we guarantee that our vehicles are clean, well-maintained, and designed to make your ride enjoyable. Additionally, we regularly update our fleet to include the latest models, ensuring you have the best possible travel experience."
    },
    {
        "WhyChooseheading": "Professional Drivers:",
        "WhyChoosedescription": "Our drivers are more than just operators; they are your travel companions. Each driver undergoes thorough training and background checks to ensure they meet our high standards of safety and professionalism. They are familiar with all the best routes to Shirdi, and their local knowledge allows them to navigate efficiently while avoiding traffic. Our drivers also prioritize your comfort and can accommodate specific requests, whether it's adjusting the temperature in the car or providing local recommendations."
    },
    {
        "WhyChooseheading": "24/7 Availability:",
        "WhyChoosedescription": "We understand that travel can be unpredictable, which is why our cab service is available 24/7. Whether you have a late-night flight or an early morning arrival, our team is always ready to serve you. Our round-the-clock availability ensures that you can make your travel arrangements according to your schedule without any limitations. Just reach out to us anytime, and we will have a vehicle ready for you, allowing for flexible travel plans that suit your needs."
    },
    {
        "WhyChooseheading": "Transparent Pricing:",
        "WhyChoosedescription": "Our commitment to transparency means that you will always know what you are paying for. We provide detailed quotes that outline all the costs involved, ensuring that there are no surprises at the end of your journey. Our pricing structure is designed to be fair and competitive, and we offer different package options to suit various budgets. This level of honesty helps you make informed decisions while planning your travel expenses."
    },
    {
        "WhyChooseheading": "Easy Booking Process:",
        "WhyChoosedescription": "Booking a cab with us is as simple as it gets. Our website features an easy-to-use interface that allows you to book your cab in just a few clicks. You can also call our friendly customer service team, who will guide you through the booking process if you prefer personal assistance. Whether you're a tech-savvy traveler or someone who prefers a more traditional approach, we have options that cater to your preferences, ensuring a hassle-free experience."
    },
    {
        "WhyChooseheading": "Timely Pick-Up:",
        "WhyChoosedescription": "We respect your time. Our drivers are punctual and ensure that they arrive at your location ahead of schedule, ready to assist you as soon as you need it. This is particularly important for airport transfers, where timing is crucial. We monitor flight arrivals and adjust our schedules accordingly to accommodate delays or changes in your itinerary, giving you one less thing to worry about when you travel."
    },
    {
        "WhyChooseheading": "Flexible Payment Options:",
        "WhyChoosedescription": "We offer a variety of payment methods to cater to your convenience. Whether you prefer to pay cash, use credit/debit cards, or opt for online payment methods, we accommodate your choice. This flexibility ensures that you can handle your transactions in a way that suits you best, making the entire travel process smoother and more enjoyable. Plus, our transparent billing means you won’t have to deal with any unexpected fees or charges."
    },
    {
        "WhyChooseheading": "Customer Satisfaction:",
        "WhyChoosedescription": "At Saitirtha Travels, customer satisfaction is our guiding principle. We are committed to providing a high level of service, and we continuously seek feedback to improve our offerings. Our team is always ready to assist with any requests or concerns you may have, ensuring that your experience with us is nothing short of excellent. We believe that a satisfied customer is our best advertisement, and we strive to exceed your expectations every time."
    },
    {
        "WhyChooseheading": "Positive Customer Feedback:",
        "WhyChoosedescription": "The success of our service is reflected in the positive reviews from our clients. Many of our customers return to us for their travel needs and recommend us to friends and family, showcasing our reliability and quality service. We take pride in building long-term relationships with our customers and continually working to enhance their travel experiences. Your feedback helps us maintain high standards and motivates us to keep improving."
    }
]




        
  }


  const faqData = [
    {
        question: "How do I book a cab from Aurangabad Airport to Shirdi?",
        answer: "You can easily book your cab through our website or by calling our customer service."
    },
    {
        question: "What is the fare for the cab service?",
        answer: "Fares depend on the vehicle type and distance. Please contact us for a specific quote."
    },
    {
        question: "Can I choose a specific vehicle for my trip?",
        answer: "Yes, we offer a variety of vehicles to suit your preferences and group size."
    },
    {
        question: "What if my flight is delayed?",
        answer: "Our drivers monitor flight arrivals and will adjust the pick-up time accordingly."
    },
    {
        question: "Are there any additional charges for the service?",
        answer: "We provide transparent pricing, and any additional fees will be communicated before booking."
    },
    {
        question: "How can I pay for the cab service?",
        answer: "We accept cash, credit/debit cards, and online payments for your convenience."
    },
    {
        question: "Are your drivers trained and professional?",
        answer: "Yes, all our drivers are experienced, licensed, and trained to provide safe transportation."
    },
    {
        question: "Is the cab service available at night?",
        answer: "Yes, our service operates 24/7, allowing you to book at any time."
    },
    {
        question: "How do I contact customer support?",
        answer: "You can reach our customer support team by phone or email for any inquiries."
    },
    {
        question: "What is the travel time from Aurangabad Airport to Shirdi?",
        answer: "The journey typically takes around 3 to 4 hours, depending on traffic conditions."
    }
];

const testimonials = [
    {
        name: "Mr. Ravi Malhotra",
        text: "I booked a cab from Aurangabad Airport to Shirdi, and the service was exceptional. The driver was friendly and knew the best routes to avoid traffic. The cab was clean and comfortable, making my trip enjoyable. I highly recommend Saitirtha Travels!"
    },
    {
        name: "Mrs. Anjali Verma",
        text: "Saitirtha Travels made my airport transfer to Shirdi stress-free. The booking process was easy, and the driver was punctual and very helpful. I felt safe and comfortable throughout the journey. I will definitely use their services again!"
    }
];





  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Aurangabad Airport to Shirdi Cab | Affordable Taxi Service</title>
    <meta name="description" content="Reliable and affordable taxi services from Aurangabad Airport to Shirdi. Book one-way or round-trip cabs, airport transfers, and car rentals for your Shirdi trip. Book now!" />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirtha Travels | Aurangabad Airport to Shirdi Cab | Affordable Taxi Service" />
    <meta property="og:description" content="Reliable and affordable taxi services from Aurangabad Airport to Shirdi. Book one-way or round-trip cabs, airport transfers, and car rentals for your Shirdi trip. Book now!" />
    <meta property="og:url" content="https://saitirthatravels.com/aurangabad-airport-to-shirdi-cab" />
    <meta property="og:image" content="https://saitirthatravels.com/images/aurangabad-airport-to-shirdi-cab.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Reliable and affordable taxi services from Aurangabad Airport to Shirdi. Book one-way or round-trip cabs, airport transfers, and car rentals for your Shirdi trip. Book now!",
            "url": "https://saitirthatravels.com/aurangabad-airport-to-shirdi-cab",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/aurangabad-airport-to-shirdi-cab.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Aurangabad Airport to Shirdi cab",
                "Shirdi taxi service",
                "Aurangabad to Shirdi car rental",
                "Shirdi airport transfers",
                "Aurangabad to Shirdi one-way taxi",
                "Round-trip cab from Aurangabad Airport to Shirdi",
                "Reliable taxi services from Aurangabad Airport",
                "Shirdi taxi fare from Aurangabad",
                "Direct cab from Aurangabad Airport to Shirdi",
                "SaitirthaTours & Travels Shirdi"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/58.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Aurangabadairport;