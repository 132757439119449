
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Shirditonagpur() {



  const cardData =
  {
    keyword: 'Shirdi to Nagpur Cab Service',
    heading: 'Saitirtha Travels: Shirdi to Nagpur Cab Service',
    headingDescription: 'Experience a comfortable and convenient journey from Shirdi to Nagpur with our reliable cab service. Saitirtha Travels offers a range of vehicles to suit your travel needs, ensuring a smooth ride through the scenic landscapes of Maharashtra. Enjoy a seamless trip to Nagpur, the Orange City, known for its historical, cultural, and natural attractions.',

    top: 'Top Places to Visit in Nagpur with Saitirtha Travels',

    topPlaces: [
        {
            "title": "Deekshabhoomi",
            "description": "A major pilgrimage center, Deekshabhoomi is a sacred monument where Dr. B.R. Ambedkar embraced Buddhism along with thousands of his followers in 1956. This historic site features stunning stupa architecture and serene surroundings that symbolize the transformation of thousands of lives. The peaceful ambiance, coupled with its cultural and historical significance, makes Deekshabhoomi a must-visit for anyone interested in understanding the impact of Dr. Ambedkar on Indian society."
        },
        {
            "title": "Futala Lake",
            "description": "A popular recreational spot, Futala Lake is known for its scenic beauty and relaxing atmosphere. The lake is beautifully landscaped and features a walking path around its perimeter. In the evenings, the lake is illuminated with colorful lights, offering a serene view perfect for an evening stroll or a boat ride. Local eateries nearby serve delicious snacks, making it a delightful spot for families and friends to unwind."
        },
        {
            "title": "Ambazari Lake and Garden",
            "description": "One of the largest and oldest lakes in Nagpur, Ambazari Lake is surrounded by lush greenery and a beautiful garden. The lake offers boating facilities, allowing visitors to enjoy the tranquil waters while taking in the scenic beauty. The adjoining garden features walking trails, playgrounds, and picnic spots, making it an ideal location for families looking to spend quality time together in a natural setting."
        },
        {
            "title": "Nagpur Ramtek Temple",
            "description": "This ancient temple, dedicated to Lord Rama, is located atop a hill and offers panoramic views of the surrounding area. The temple is not only significant for its religious importance but also for its historical architecture, with intricate carvings and serene surroundings. Devotees and tourists alike are drawn to the temple, especially during festivals, making it a vibrant center of spirituality and culture."
        },
        {
            "title": "Maharaj Bagh Zoo",
            "description": "Located in the heart of Nagpur, Maharaj Bagh Zoo is a small yet popular zoo that houses a variety of animals and birds, including some endangered species. The zoo is designed to provide a natural habitat for the animals, making it an educational and enjoyable experience for visitors. It's a great place for families and children to explore wildlife, learn about conservation, and enjoy nature within the city's bustling environment."
        },
        {
            "title": "Zero Mile Stone",
            "description": "The Zero Mile Stone is a historic landmark marking the geographical center of India, symbolizing Nagpur’s central position in the country. This monument is not only significant culturally but also serves as a great spot for photography and sightseeing. Visitors can learn about India’s geography and history while enjoying the surrounding gardens, making it a unique attraction in Nagpur."
        },
        {
            "title": "Dragon Palace Temple",
            "description": "A serene Buddhist temple known for its stunning architecture and peaceful environment, the Dragon Palace Temple features beautifully manicured gardens and intricate designs that reflect Buddhist culture. It is a popular destination for meditation and relaxation, attracting tourists and spiritual seekers alike. The tranquil atmosphere and scenic beauty of the temple make it a perfect getaway from the hustle and bustle of the city."
        },
        {
            "title": "Khindsi Lake",
            "description": "Located on the outskirts of Nagpur, Khindsi Lake is a popular spot for various water sports and recreational activities. Surrounded by lush greenery, this lake is ideal for boating, picnics, and enjoying nature. The picturesque setting and opportunities for adventure make it a great escape for families and groups looking to unwind and have fun in the great outdoors."
        },
        {
            "title": "Sitabuldi Fort",
            "description": "A historic fort situated in the heart of Nagpur, Sitabuldi Fort offers a glimpse into the region’s colonial history. Originally built in the 19th century, the fort is now under military control but occasionally opens to the public. Visitors can explore the fort's architecture and enjoy panoramic views of the city, making it a significant landmark that showcases Nagpur's rich past."
        },
        {
            "title": "Balaji Mandir at Seminary Hills",
            "description": "Nestled amidst the beautiful Seminary Hills, the Balaji Mandir is a famous Hindu temple dedicated to Lord Balaji. The temple is known for its peaceful atmosphere, stunning architecture, and beautiful views of the city below. It is a popular spiritual destination, attracting visitors who seek tranquility and a chance to unwind in a serene environment. The temple grounds are perfect for meditation and reflection, making it an ideal escape from the city's hustle."
        }
    ],    
    
            
        
"services": [
    {
        "name": "Shirdi to Nagpur Cab Service",
        "description": "Our Shirdi to Nagpur cab service provides reliable and comfortable transportation for individuals, families, and groups. Whether you’re traveling for business, leisure, or a spiritual journey, we offer a variety of vehicle options to suit your preferences and group size. Our drivers are experienced and knowledgeable about the best routes, ensuring a smooth and efficient ride. With timely pickups, safe travel, and customer satisfaction at the forefront, this service guarantees a hassle-free journey from Shirdi to Nagpur."
    },
    {
        "name": "Cab from Shirdi to Nagpur",
        "description": "When you book a cab from Shirdi to Nagpur, you can expect punctual pickups, a comfortable ride, and courteous, professional drivers. Our team is well-versed in navigating the best routes, ensuring that you arrive at your destination on time and with minimal stress. Choose from a wide range of vehicles including sedans, SUVs, and luxury options, depending on your comfort and travel needs. Whether you're traveling solo or with a group, we provide reliable, safe, and comfortable transportation."
    },
    {
        "name": "Shirdi Nagpur Taxi Fare",
        "description": "We offer competitive taxi fares for the Shirdi Nagpur route, providing great value for money while ensuring a premium travel experience. Our transparent pricing system ensures no hidden charges, so you know exactly what you're paying for. Whether you're booking a one-way ride or a round-trip, our pricing is designed to be affordable, making travel between Shirdi and Nagpur easy and cost-effective without compromising on quality."
    },
    {
        "name": "Shirdi to Nagpur Car Rental",
        "description": "Looking for flexibility in your travels? Our Shirdi to Nagpur car rental service offers you the freedom to rent a vehicle of your choice for as long as you need. Whether you're planning to explore the city, attend meetings, or just need transportation for a few days, we provide a range of options including economy cars, luxury vehicles, and SUVs. The car rental option allows you to travel at your own pace, with no fixed schedules or restrictions."
    },
    {
        "name": "One-way Cab from Shirdi to Nagpur",
        "description": "If you're only traveling one-way from Shirdi to Nagpur, our one-way cab service is the perfect solution. This option is ideal for travelers who don't require a return trip and want to avoid the extra costs associated with a round trip. Enjoy a comfortable, stress-free journey with experienced drivers, and rest assured that you will reach your destination promptly and safely."
    },
    {
        "name": "Round Trip Shirdi Nagpur Taxi",
        "description": "For those planning to visit both Shirdi and Nagpur, our round trip taxi service is the best option. Whether you’re traveling for leisure, pilgrimage, or work, we take care of all your transportation needs. Our service ensures a comfortable ride both ways, with well-maintained vehicles, experienced drivers, and a flexible itinerary that can be customized based on your schedule and preferences."
    },
    {
        "name": "Taxi Booking Shirdi Nagpur",
        "description": "Booking a taxi for your Shirdi Nagpur trip is easy with our simple and user-friendly taxi booking system. You can book your taxi online or by phone, and we’ll ensure that a driver is available to take you to your destination at your preferred time. Our booking process is hassle-free, and we prioritize customer satisfaction to ensure that your trip is smooth and stress-free from start to finish."
    },
    {
        "name": "Shirdi Nagpur Cab Booking",
        "description": "Our Shirdi Nagpur cab booking system is designed to make your travel planning easier. You can choose from a range of vehicle options, such as sedans, SUVs, or luxury cars, based on your preferences and the size of your group. Whether you're traveling alone or with family and friends, we ensure that your booking is seamless and that your cab arrives on time for a smooth journey."
    },
    {
        "name": "Cab Rates Shirdi to Nagpur",
        "description": "We offer competitive and transparent cab rates for the Shirdi to Nagpur route, ensuring that you get the best value for your money. Our pricing structure is simple, with no hidden fees, so you can easily calculate the cost of your trip in advance. Whether you need a one-way ride or a round trip, we strive to offer affordable and reliable service to all our customers."
    },
    {
        "name": "Best Cab Service Shirdi Nagpur",
        "description": "Our Shirdi Nagpur cab service is known for its excellence, reliability, and customer-centric approach. We pride ourselves on offering the best cab service for your journey between Shirdi and Nagpur. Our well-maintained fleet, experienced drivers, and commitment to customer satisfaction ensure that you have a safe, comfortable, and pleasant travel experience. Choose us for timely pickups, smooth rides, and hassle-free travel."
    },
    {
        "name": "Shirdi to Nagpur Innova Crysta on Rent",
        "description": "Travel in luxury and comfort with our Shirdi to Nagpur Innova Crysta on Rent service. This spacious and stylish vehicle is perfect for families, groups, or anyone looking for a comfortable long-distance ride. The Innova Crysta offers ample legroom, modern amenities, and a smooth ride, ensuring that you travel in style. Whether you're visiting for business or leisure, this vehicle provides the perfect blend of comfort, luxury, and practicality."
    },
    {
        "name": "Shirdi to Nagpur Tempo Traveller On Rent",
        "description": "For larger groups, our Shirdi to Nagpur Tempo Traveller On Rent service is the ideal choice. With a seating capacity for 12-20 passengers, the Tempo Traveller offers a comfortable and spacious environment for family trips, group outings, or corporate tours. The vehicle is equipped with air conditioning, plush seating, and ample luggage space, ensuring a pleasant and stress-free journey for everyone."
    },
    {
        "name": "Shirdi to Nagpur Ertiga On Rent",
        "description": "Our Shirdi to Nagpur Ertiga on Rent service strikes the perfect balance between comfort and economy. Ideal for small families or medium-sized groups, the Ertiga offers a spacious interior, air conditioning, and comfortable seating. Whether you're planning a quick trip or a longer stay, the Ertiga provides a reliable and cost-effective option for your travel needs."
    },
    {
        "name": "Shirdi to Nagpur Sedan Cab on Rent",
        "description": "If you’re traveling solo or with a small group, our Shirdi to Nagpur sedan cab on rent is the perfect option for a comfortable and compact ride. Choose from a selection of well-maintained vehicles that offer great fuel efficiency, air conditioning, and a smooth driving experience. This is an ideal option for budget-conscious travelers who still want a comfortable, stress-free journey between Shirdi and Nagpur."
    },
    {
        "name": "Nagpur to Shirdi by Car",
        "description": "If you're traveling from Nagpur to Shirdi, our reliable car service ensures a comfortable and convenient journey. We offer a variety of vehicle options based on your needs, whether you're traveling alone or with a group. Our professional drivers are well-versed in the best routes, ensuring timely arrivals and a smooth ride. Experience hassle-free travel with our service, whether you're going for a religious visit or a leisurely trip."
    },
    {
        "name": "Shirdi to Nagpur Car Rental Service",
        "description": "Our Shirdi to Nagpur Car Rental Service offers flexibility, comfort, and convenience for travelers who need a vehicle for a specific time frame. Whether you're in need of a short-term rental for a few days or a longer-term rental for your entire trip, we have a wide range of vehicles to suit your needs. With competitive rates and a variety of options, you can easily find the perfect car for your journey."
    },
    {
        "name": "Shirdi to Nagpur Taxi Service",
        "description": "Enjoy the convenience of our Shirdi to Nagpur taxi service, designed for travelers who value comfort, punctuality, and safety. Our fleet of vehicles is well-maintained, and our experienced drivers are familiar with the route, ensuring that your journey is smooth and hassle-free. Whether you're traveling for a spiritual visit, a business trip, or simply exploring, our taxi service offers the perfect solution for your travel needs."
    },
    {
        "name": "Shirdi to Nagpur cab Contact Information",
        "description": "For more information or to book your Shirdi to Nagpur cab, please contact SaitirthaTours & Travels at +91 9923879009 / 9922479009. Our customer service team is available to assist you with all your travel requirements and provide any additional information you may need. We are committed to making your journey as comfortable, safe, and enjoyable as possible."
    }
],

"tableData": [
    ["- Shirdi to Nagpur Cab Service", "- Cab from Shirdi to Nagpur"],
    ["- Shirdi Nagpur Taxi Fare", ""],
    ["- Shirdi to Nagpur Car Rental", "- One-way Cab from Shirdi to Nagpur"],
    ["- Round Trip Shirdi Nagpur Taxi", "- Taxi Booking Shirdi Nagpur"],
    ["- Shirdi Nagpur Cab Booking", "- Cab Rates Shirdi to Nagpur"],
    ["- Best Cab Service Shirdi Nagpur", "- Shirdi to Nagpur Cab Service"],
    ["- Shirdi to Nagpur Innova Crysta on Rent", "- Shirdi to Nagpur Tempo Traveller on Rent"],
    ["- Shirdi to Nagpur Ertiga on Rent", "- Shirdi to Nagpur Sedan Cab on Rent"],
    ["- Nagpur to Shirdi by Car", "- Shirdi to Nagpur Car Rental Service"],
    ["- Shirdi to Nagpur Taxi Service", "- SaitirthaTours & Travels Shirdi"]
  ],
  "whychoose": [
    {
      "WhyChooseheading": "Reliable Service",
      "WhyChoosedescription": "At Saitirtha Travels, we pride ourselves on providing dependable cab services for your journey from Shirdi to Nagpur. Our punctuality ensures you reach your destination on time, allowing you to plan your day without stress. We understand the importance of reliability in travel, and our team works diligently to guarantee timely pick-ups and drop-offs. Whether you’re heading to a business meeting or a family event, our commitment to on-time service gives you peace of mind. Our drivers are familiar with the best traffic routes, helping you avoid delays and ensuring a smooth travel experience."
    },
    {
      "WhyChooseheading": "Comfortable Vehicles",
      "WhyChoosedescription": "Travel in comfort with our fleet of well-maintained and spacious vehicles, designed to provide a smooth ride for both solo travelers and groups. We offer a range of vehicle options to meet your needs, whether you prefer a compact sedan, a spacious SUV, or a larger van for groups. Our vehicles are equipped with modern amenities to enhance your comfort, including air conditioning and ample legroom. We regularly service our cars to maintain high standards of cleanliness and functionality. With us, you can relax and enjoy the journey, focusing on your travel plans while we take care of the road."
    },
    {
      "WhyChooseheading": "Experienced Drivers",
      "WhyChoosedescription": "Our professional drivers are well-trained, friendly, and knowledgeable about the best routes, ensuring a safe and pleasant journey. They are committed to providing excellent customer service, making your trip enjoyable from start to finish. Each driver is licensed and has undergone thorough background checks for your safety. They are familiar with the nuances of local traffic patterns and conditions, which allows them to navigate efficiently and avoid delays. Our drivers are also trained in first aid and safety protocols, ensuring you have a secure travel experience."
    },
    {
      "WhyChooseheading": "Flexible Booking Options",
      "WhyChoosedescription": "We offer flexible booking options, allowing you to choose the type of vehicle that suits your needs and preferences, whether it's a sedan, SUV, or van. Our user-friendly booking system allows for easy online reservations, and our team is happy to assist with any specific requests you may have. You can modify your booking up to a certain time before your trip, accommodating any changes in your plans. Whether you need a last-minute ride or are planning ahead, we aim to provide options that fit your schedule and preferences."
    },
    {
      "WhyChooseheading": "Affordable Rates",
      "WhyChoosedescription": "Enjoy competitive pricing without compromising on quality. We provide transparent pricing with no hidden fees, making it easy to plan your budget. Our rates are designed to be affordable for all travelers, whether you're taking a short trip or a longer journey. We frequently review our pricing structure to ensure that we remain competitive in the market while maintaining high standards of service. By choosing Saitirtha Travels, you receive exceptional value for your money, allowing you to travel comfortably and affordably."
    },
    {
      "WhyChooseheading": "24/7 Customer Support",
      "WhyChoosedescription": "Our dedicated customer service team is available around the clock to assist you with any inquiries, changes, or emergencies during your trip. We understand that travel can be unpredictable, and our team is prepared to handle any situation. Whether you need help with your booking, have questions about our services, or encounter any issues during your trip, our responsive support team is just a phone call away. We pride ourselves on providing timely assistance and ensuring you have a seamless travel experience."
    },
    {
      "WhyChooseheading": "Safety First",
      "WhyChoosedescription": "Your safety is our priority. All our vehicles are regularly serviced, and our drivers follow strict safety protocols to ensure a secure travel experience. We conduct regular inspections of our fleet and implement comprehensive safety measures, including seatbelt use and adherence to traffic regulations. Our drivers are trained to respond effectively to any unforeseen situations that may arise during your journey. We also provide GPS tracking in our vehicles for added safety and peace of mind, allowing us to monitor your route and ensure timely assistance if needed."
    },
    {
      "WhyChooseheading": "Local Insights",
      "WhyChoosedescription": "Our drivers can offer valuable recommendations for stops along the way, including local eateries and attractions to enhance your travel experience. Their familiarity with the region allows them to suggest the best places to eat, explore, and enjoy, making your journey not just a ride but an adventure. They can share stories and insights about the local culture, giving you a richer understanding of the areas you travel through. Whether you’re interested in hidden gems or popular spots, our drivers will help make your trip more enjoyable and memorable."
    },
    {
      "WhyChooseheading": "Positive Reviews",
      "WhyChoosedescription": "We have received numerous positive reviews from satisfied customers who appreciate our commitment to excellent service and memorable experiences. Our clients frequently commend our reliability, friendly drivers, and comfortable vehicles. We strive to build lasting relationships with our customers, and their feedback helps us continually improve our services. We are proud of our reputation and work hard to maintain the high standards that our clients expect. By choosing us, you can be confident that you’re selecting a trusted and reputable service for your journey."
    },
    {
      "WhyChooseheading": "Convenient Payment Options",
      "WhyChoosedescription": "We provide multiple payment methods for your convenience, including cash, credit card, and online payments. Our flexible payment options allow you to choose the method that suits you best, making the booking process smoother and more efficient. You can pay securely online when booking, or settle your fare with the driver at the end of your journey, whichever you prefer. We ensure that all transactions are safe and straightforward, allowing you to focus on your travel plans without worrying about payment issues."
    }
  ]




        
  }


  const faqData = [
    {
        question: "How long does the journey from Shirdi to Nagpur take?",
        answer: "The journey typically takes around 10 to 11 hours, depending on traffic conditions and stops along the way."
    },
    {
        question: "What types of vehicles are available for this route?",
        answer: "We offer a variety of vehicles, including sedans, SUVs, and larger vans, to accommodate different group sizes and preferences."
    },
    {
        question: "Is there a fixed fare for the journey?",
        answer: "Yes, we provide a fixed fare based on the vehicle type and distance. Our pricing is transparent with no hidden charges."
    },
    {
        question: "Can I make stops during the journey?",
        answer: "Yes, you can request stops along the way for meals or sightseeing. Just inform your driver of your preferences."
    },
    {
        question: "What if my flight is delayed?",
        answer: "Our drivers are informed of flight schedules and will adjust their pickup times accordingly to ensure you are accommodated."
    },
    {
        question: "How do I book the cab?",
        answer: "You can book your Shirdi to Nagpur cab online through our website or by contacting our customer service team."
    },
    {
        question: "Is there a cancellation policy?",
        answer: "Yes, we have a cancellation policy in place. Please reach out to our customer service for details on how to cancel or modify your booking."
    },
    {
        question: "Are child seats available?",
        answer: "Yes, we can provide child seats upon request for your convenience and safety."
    },
    {
        question: "What should I do if I have a complaint?",
        answer: "If you have any complaints or feedback, please contact our customer service, and we will address your concerns promptly."
    },
    {
        question: "Can I book a round trip?",
        answer: "Yes, we offer round-trip bookings for your convenience. Please inquire about our special rates for round trips."
    }
];

const testimonials = [
    {
        name: "Mr. Anil Gupta",
        text: "Traveling from Shirdi to Nagpur with Saitirtha Travels was a wonderful experience! The driver was professional, and the car was clean and comfortable. I appreciated the smooth ride and timely arrival."
    },
    {
        name: "Mrs. Suman Rao",
        text: "I booked a cab for my family trip to Nagpur, and we were very pleased with the service. The driver was friendly, and the journey was hassle-free. I highly recommend Saitirtha Travels for anyone needing a reliable cab service!"
    }
];




  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>

<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Shirdi to Nagpur Cab | Best Taxi Service | SaitirthaTours & Travels</title>
    <meta name="description" content="Book an affordable Shirdi to Nagpur Cab with SaitirthaTours & Travels. Offering one-way, round-trip, and rental services for your journey from Shirdi to Nagpur in an Innova, Ertiga, or Tempo Traveller." />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirtha Travels | Shirdi to Nagpur Cab | Best Taxi Service | SaitirthaTours & Travels" />
    <meta property="og:description" content="Book an affordable Shirdi to Nagpur Cab with SaitirthaTours & Travels. Offering one-way, round-trip, and rental services for your journey from Shirdi to Nagpur in an Innova, Ertiga, or Tempo Traveller." />
    <meta property="og:url" content="https://saitirthatravels.com/shirdi-to-nagpur-cab" />
    <meta property="og:image" content="https://saitirthatravels.com/images/shirdi-to-nagpur-cab.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Book an affordable Shirdi to Nagpur Cab with SaitirthaTours & Travels. Offering one-way, round-trip, and rental services for your journey from Shirdi to Nagpur in an Innova, Ertiga, or Tempo Traveller.",
            "url": "https://saitirthatravels.com/shirdi-to-nagpur-cab",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/shirdi-to-nagpur-cab.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi to Nagpur Cab",
                "Cab from Shirdi to Nagpur",
                "Shirdi Nagpur taxi fare",
                "Shirdi to Nagpur car rental",
                "One-way cab from Shirdi to Nagpur",
                "Round trip Shirdi Nagpur taxi",
                "Taxi booking Shirdi Nagpur",
                "Shirdi Nagpur cab booking",
                "Cab rates Shirdi to Nagpur",
                "Best cab service Shirdi Nagpur",
                "Shirdi to Nagpur Cab Service",
                "Shirdi to Nagpur Innova Crysta on Rent",
                "Shirdi to Nagpur Tempo Traveller On Rent",
                "Shirdi to Nagpur Ertiga On Rent",
                "Shirdi to Nagpur Sedan cab on Rent",
                "Nagpur to Shirdi by car",
                "Shirdi to Nagpur Car Rental Service",
                "Shirdi to Nagpur Taxi Service",
                "SaitirthaTours & Travels Shirdi"
            ]
        })}
    </script>
</Helmet>

      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/67.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirditonagpur;