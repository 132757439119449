import React from 'react';
import Vision from './Vision';
import TestimonialSection from './TestimonialSection';
import UniqueSection from './UniqueSection';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const AboutSection = () => {
  return (
   <div>

<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Ertiga On Rent in Shirdi | Kia Carens on rent in Shirdi | Affordable and Reliable Service</title> 
    <meta name="description" content="Book Ertiga on Rent in Shirdi with Sai Tirth Cabs. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Reliable and comfortable mini bus rental services." />
    <meta name="keywords" content="Force Urbania on Rent in Shirdi, Urbania on Rent in Manmad, Force Urbania on Rent in Shani Shingnapur, Shirdi to Bhimashankar Urbania on Rent, Shirdi to Nashik Force Urbania on Rent, Mini Bus on Rent in Shirdi, Luxury Force Urbania on Rent in Shirdi, 13 Seater Urbania on Rent, 17 Seater Urbania on Rent, Shirdi Airport Urbania on Rent, Sai Tirth Cabs Shirdi, Mini AC Bus on Rent, Mini Luxury Bus on Rent in Shirdi, Aura on Rent in Shirdi, Ertiga on Rent in Shirdi, Scorpio on Rent in Shirdi, Kia Carens on Rent in Shirdi" />
    <meta property="og:title" content="Contact: +91 9923879009 | Force Urbania On Rent in Shirdi | Luxury Urbania Bus Rental | Affordable and Reliable Service" />
    <meta property="og:description" content="Book Force Urbania on Rent in Shirdi with Sai Tirth Cabs. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Reliable and comfortable mini bus rental services." />
    <meta property="og:url" content="https://saitirthatravels.com/force-urbania-on-rent-in-shirdi" />
    <meta property="og:image" content="https://saitirthatravels.com/images/urbania-on-rent.jpg" />
    <meta name="twitter:card" content="summary_large_image" />
    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Sai Tirth Cabs Shirdi",
            "description": "Book Force Urbania on Rent in Shirdi. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Affordable and reliable mini bus rental services.",
            "url": "https://saitirthatravels.com/force-urbania-on-rent-in-shirdi",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/urbania-on-rent.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "250"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Force Urbania on Rent in Shirdi",
                "Urbania on Rent in Manmad",
                "Force Urbania on Rent in Shani Shingnapur",
                "Shirdi to Bhimashankar Urbania on Rent",
                "Shirdi to Nashik Force Urbania on Rent",
                "Mini Bus on Rent in Shirdi",
                "Luxury Force Urbania on Rent in Shirdi",
                "13 Seater Urbania on Rent",
                "17 Seater Urbania on Rent",
                "Shirdi Airport Urbania on Rent",
                "Sai Tirth Cabs Shirdi",
                "Aura on Rent in Shirdi",
                "Ertiga on Rent in Shirdi",
                "Scorpio on Rent in Shirdi",
                "Kia Carens on Rent in Shirdi"
            ]
        })}
    </script>
</Helmet>

<section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
            <div className="title-outer text-center">
                <h1 className="title">About</h1>
              
            </div>
        </div>
    </section>


 <section className="about-section">
      <div className="auto-container">
        <div className="row">
          {/* Content Column */}
          <div className="content-column col-lg-6 col-md-12 col-sm-12 order-lg-2" >
            <div className="inner-column">
              <div className="sec-title">
                <span className="sub-title">Welcome to Our Company</span>
                <h2>We Provide Trusted Cab Services</h2>
                <div className="text">
                Since 2019, Saitirtha Travels has been dedicated to creating remarkable travel experiences that combine exploration, comfort, and cultural richness. Our team of travel experts designs carefully curated itineraries that bring together the best of each destination, from famous attractions to hidden gems. At Saitirtha Travels, we’re committed to responsible and sustainable tourism, partnering with local communities and eco-conscious organizations to leave a positive footprint wherever we go. Whether you dream of scenic mountain getaways, serene beaches, or immersive cultural adventures, we tailor each journey to provide unforgettable memories. Let Saitirtha Travels turn your travel aspirations into reality with personalized service, insight, and a passion for exceptional journeys.
                </div>
                
              </div>
              <blockquote className="blockquote-style-two">
                Founder - Mr. Avinash Narode
              </blockquote>
              <div className="bottom-box">
                {/* Features Block */}
                <div className="features-outer">
                  <div className="feature-block">
                    <i className="icon fal fa-calendar-check"></i> Online Booking
                  </div>
                  <div className="feature-block">
                    <i className="icon fal fa-headset"></i> 24/7 Support
                  </div>
                  <Link to ='/booking' className="theme-btn btn-style-one">
                    <span className="btn-title">Book a Taxi</span>
                  </Link>
                </div>

                {/* <div className="video-box" data-aos="fade-up">
                  <figure className="image">
                    <img src="/images/resource/about1-4.png" alt="Video Thumbnail" />
                  </figure>
                  <a href="https://www.youtube.com/watch?v=Fvae8nxzVz4" className="play-btn" data-fancybox="">
                    <div className="icon-triangle fas fa-play"></div>
                  </a>
                </div> */}
              </div>
            </div>
          </div>

          {/* Image Column */}
          <div className="image-column col-lg-6 col-md-12 col-sm-12">
            <div className="inner-column">
              <figure className="image reveal">
                <img src="/images/saibaba.jpg" alt="Image 1" />
              </figure>
              <figure className="image-2" data-aos="fade-up">
                {/* <img src="/images/resource/about1-2.png" alt="Image 2" /> */}
              </figure>
              <figure className="image-3 reveal">
                {/* <img src="/images/resource/about1-3.png" alt="Image 3" /> */}
              </figure>
            </div>

          </div>
        </div>
      </div>
    </section>
<UniqueSection/>
    <Vision/>
<TestimonialSection/>
   </div>
  );
};

export default AboutSection;
