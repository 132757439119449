
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Kiacarens() {



  const cardData =
  {
    keyword: ' Kia Carens On Rent in Shirdi',
    heading: 'Saitirtha Travels: Kia Carens On Rent in Shirdi',
    headingDescription: 'Discover the spiritual town of Shirdi with the convenience and comfort of a Kia Carens on rent. This modern MPV offers a perfect blend of style, space, and functionality, making it an ideal choice for families, groups, or anyone looking to explore the region. Saitirtha Travels ensures you have a smooth and enjoyable journey while visiting the renowned sites associated with Sai Baba and more.',

    top: 'Top Places to Visit in Shirdi with Saitirtha Travels',

    topPlaces: [
        {
            "title": "Sai Baba Temple",
            "description": "The Sai Baba Temple is the heart of Shirdi and one of the most significant pilgrimage sites in India. Dedicated to the revered saint Sai Baba, the temple is a magnificent structure that can accommodate thousands of devotees at a time. The main shrine houses a beautiful idol of Sai Baba, which is often adorned with flowers and offered aarti (worship). The temple complex features spacious courtyards, a serene atmosphere, and the option for devotees to participate in various rituals and ceremonies throughout the day. The evening aarti is particularly enchanting, filled with devotion and spirituality."
        },
        {
            "title": "Dwarkamai",
            "description": "Dwarkamai is an ancient mosque that holds immense historical and spiritual significance. It is believed to be where Sai Baba lived for most of his life and performed many miracles. Visitors can admire the sacred fire, Dhuni, that Baba lit and which still burns to this day. The mosque is adorned with beautiful calligraphy and pictures, illustrating Baba's life and teachings. The tranquil ambiance here provides a perfect setting for meditation and reflection on Baba’s wisdom and teachings."
        },
        {
            "title": "Chavadi",
            "description": "Located close to Dwarkamai, Chavadi is another significant place associated with Sai Baba. It served as a resting place for Baba, and he was known to spend alternate nights here. The structure features a beautiful portrait of Sai Baba, and the wooden architecture is reminiscent of the traditional style. The annual festival held here attracts numerous visitors and is marked by special prayers and celebrations. The ambiance is peaceful, allowing visitors to feel Baba's presence as they pay their respects."
        },
        {
            "title": "Lendi Garden",
            "description": "Lendi Garden is a beautiful and serene spot for visitors seeking solace in nature. This garden was once frequented by Sai Baba for meditation and reflection. The garden features a variety of plants and trees, making it an ideal place for a leisurely stroll or a quiet moment of contemplation. There are benches and shaded areas where visitors can sit and relax, enjoying the sounds of nature. The garden is also home to a beautiful well, believed to have been used by Sai Baba himself."
        },
        {
            "title": "Shri Khandoba Mandir",
            "description": "The Shri Khandoba Mandir is a significant temple dedicated to Lord Khandoba, an incarnation of Lord Shiva. Situated atop a hill, the temple offers panoramic views of the surrounding landscape. The architecture is impressive, adorned with intricate carvings and sculptures. Pilgrims visit this temple, especially during festivals, to seek blessings and participate in vibrant celebrations. The temple's location makes it a great spot for photography and to witness the natural beauty of the region."
        },
        {
            "title": "Maruti Mandir",
            "description": "Maruti Mandir is dedicated to Lord Hanuman and is a peaceful temple located near the Sai Baba Temple. It is a popular site among devotees who come to offer prayers and seek blessings for strength and courage. The temple features a serene ambiance, adorned with beautiful idols and traditional decor. Visitors often find the temple to be a perfect spot for quiet reflection and devotion."
        },
        {
            "title": "Gurusthan",
            "description": "Gurusthan holds a special place in the hearts of devotees, as it is believed to be where Sai Baba was first discovered by the villagers. The site features a small shrine with a portrait of Sai Baba and a sacred tree where many pilgrims tie threads to fulfill their wishes. The atmosphere is filled with spirituality, making it a must-visit for anyone seeking a deeper connection with Sai Baba’s teachings."
        },
        {
            "title": "Sai Heritage Village",
            "description": "Sai Heritage Village is a cultural center that showcases the life and teachings of Sai Baba. This attraction features a series of exhibits, including replicas of historical structures and displays of traditional crafts. Visitors can engage with guides who share stories of Baba’s life, making it an educational experience for all ages. The village aims to preserve the cultural heritage of the region while providing insights into the profound impact of Sai Baba on society."
        },
        {
            "title": "Khandoba Temple",
            "description": "This temple, located a short distance from Shirdi, is dedicated to Khandoba, a local deity. The temple is renowned for its beautiful architecture and serene surroundings. It attracts many devotees, especially during the annual fairs held in honor of Khandoba. Visitors can witness traditional rituals and participate in the festive atmosphere, experiencing the local culture and spirituality."
        },
        {
            "title": "Sainath Mahal",
            "description": "Sainath Mahal is a guesthouse managed by the Sai Baba Sansthan Trust. It provides comfortable accommodation for visitors coming to Shirdi. The mahal is conveniently located, making it easy for pilgrims to access the main attractions. The environment is calm and welcoming, with various facilities to ensure a pleasant stay. Staying at Sainath Mahal offers travelers a unique opportunity to immerse themselves in the spiritual ambiance of Shirdi."
        }
    ],    
    
            
        
"services": [
    {
        "name": "Kia Carens On Rent in Shirdi",
        "description": "Travel in style and comfort with the Kia Carens, a spacious and versatile vehicle perfect for families, corporate events, and special occasions. Our Kia Carens rental service in Shirdi ensures you have a reliable and enjoyable ride for any journey."
    },
    {
        "name": "Shirdi to Mumbai Kia Carens Car on Rent",
        "description": "Planning a trip from Shirdi to Mumbai? Our Kia Carens car rental service provides you with a comfortable and spacious vehicle for your journey. Experience a smooth ride with plenty of room for passengers and luggage."
    },
    {
        "name": "Shirdi to Mahabaleshwar Kia Carens on Rent",
        "description": "Escape to the scenic beauty of Mahabaleshwar with our Kia Carens on rent. Enjoy a family trip or group outing with ample space and comfort, making your travel experience unforgettable."
    },
    {
        "name": "Shirdi to Outstation Kia Carens on Rent",
        "description": "For those looking to explore beyond Shirdi, our Kia Carens is available for outstation rentals. Experience the freedom to travel where you want, with a vehicle that caters to your needs."
    },
    {
        "name": "Shirdi to Goa Kia Carens Cab Service",
        "description": "Make your trip to Goa memorable with our Kia Carens cab service. The spacious interiors and comfortable seating make it an ideal choice for long-distance travel with family or friends."
    },
    {
        "name": "Taxi Service Kia Carens in Shirdi",
        "description": "Our taxi service featuring the Kia Carens in Shirdi is designed for those who value comfort and convenience. Enjoy a reliable transportation option for your local and outstation travel needs."
    },
    {
        "name": "Kia Carens Cabs in Shirdi",
        "description": "Choose our Kia Carens cabs in Shirdi for a perfect travel experience. Whether for business or leisure, our vehicles are well-maintained and ready to serve you."
    },
    {
        "name": "Kia Cabs in Shirdi Airport",
        "description": "Arriving at Shirdi Airport? Our Kia cabs are available for airport transfers, ensuring you have a comfortable ride to your destination. Experience hassle-free travel with our dedicated service."
    },
    {
        "name": "Kia Carens on Rent in Shirdi",
        "description": "Renting a Kia Carens in Shirdi is a great choice for those needing a spacious vehicle. Ideal for family trips or group outings, our service provides you with flexibility and comfort."
    },
    {
        "name": "Kia Carens on Rent in Shirdi for Wedding",
        "description": "Make your special day even more special by hiring a Kia Carens for wedding transportation. Its spacious interior is perfect for transporting the bridal party or guests comfortably."
    },
    {
        "name": "Kia Carens for Corporate Events",
        "description": "Our Kia Carens is also available for corporate events, ensuring your employees travel in comfort and style. Whether for meetings or company outings, we cater to your corporate travel needs."
    },
    {
        "name": "Kia Carens for Corporate Office",
        "description": "For businesses seeking reliable transportation for their staff, our Kia Carens rental service provides a comfortable and professional solution. Ensure your team arrives at their destination on time and refreshed."
    },
    {
        "name": "SaitirthaTours & Travels",
        "description": "Choose SaitirthaTours & Travels for all your Kia Carens rental needs in Shirdi. Our commitment to customer satisfaction ensures you receive the best service and well-maintained vehicles."
    },
    {
        "name": "Kia Carens On Rent in Shirdi: Contact Information",
        "description": "For bookings or inquiries, contact SaitirthaTours & Travels at +91 9923879009 / +91 9922479009. We're here to make your travel experience as enjoyable and seamless as possible!"
    }
],

tableData: [
    ["- Shirdi to Mumbai Kia Carens Car on Rent", "- Shirdi to Mahabaleshwar Kia Carens on Rent"],
    ["- Shirdi to Outstation Kia Carens on Rent", "- Shirdi to Goa Kia Carens Cab Service"],
    ["- Taxi Service Kia Carens in Shirdi", "- Kia Carens Cabs in Shirdi"],
    ["- Kia Cabs in Shirdi Airport", "- Kia Carens on Rent in Shirdi"],
    ["- Kia Carens on Rent in Shirdi for Wedding", "- Kia Carens for Corporate Events"],
    ["- Kia Carens for Corporate Office", "- SaitirthaTours & Travels Shirdi"]
  ],
  whychoose: [
    {
      "WhyChooseheading": "Spacious and Comfortable",
      "WhyChoosedescription": "The Kia Carens is a perfect vehicle for families or groups traveling together. With seating for up to 7 passengers, it offers ample legroom and comfortable seating for everyone. Whether you’re heading out for a pilgrimage, a business trip, or a family vacation, the Kia Carens ensures that your travel is both spacious and comfortable. The generous interior space allows you to relax and enjoy the journey, making it ideal for long road trips or local tours around Shirdi and beyond."
    },
    {
      "WhyChooseheading": "Modern Features",
      "WhyChoosedescription": "The Kia Carens comes equipped with a host of modern features designed to enhance your travel experience. From touch-screen infotainment systems and USB charging ports to climate control and ergonomic seating, the Kia Carens offers a premium travel experience. Whether you’re traveling for business, leisure, or a special occasion, the modern amenities ensure you enjoy a smooth, enjoyable ride throughout your journey."
    },
    {
      "WhyChooseheading": "Affordable Rates",
      "WhyChoosedescription": "At SaitirthaTours & Travels, we provide competitive rental rates for the Kia Carens without compromising on quality or comfort. Our pricing is transparent, ensuring there are no hidden charges. Whether you're booking for a short local trip or a multi-day journey, our affordable rates allow you to travel comfortably within your budget. Experience a high-quality ride with value-for-money pricing, making your travel more cost-effective."
    },
    {
      "WhyChooseheading": "Professional Drivers",
      "WhyChoosedescription": "Our professional drivers are highly experienced and courteous, ensuring you have a safe and comfortable journey. They are well-versed in the local routes around Shirdi, as well as popular tourist destinations and outstation routes, guaranteeing you a smooth trip. Our drivers prioritize your safety and comfort, and they are committed to making your journey as pleasant as possible. Whether you’re going on a religious pilgrimage or a corporate event, trust our drivers to get you to your destination safely and on time."
    },
    {
      "WhyChooseheading": "Flexible Booking Options",
      "WhyChoosedescription": "We understand that each trip has unique requirements, which is why we offer flexible rental options for your convenience. Whether you need a Kia Carens for a few hours, a full day, or even multiple days, we can customize the rental plan to suit your schedule. This flexibility ensures that you only pay for the time you need, making our service ideal for both short trips and extended travel plans."
    },
    {
      "WhyChooseheading": "Well-Maintained Vehicles",
      "WhyChoosedescription": "We prioritize the maintenance and hygiene of our fleet to ensure that every vehicle, including the Kia Carens, meets the highest standards. Our vehicles undergo regular servicing and thorough sanitization after each trip to provide you with a clean and safe travel experience. This attention to detail guarantees that you travel in a well-maintained vehicle, free from any issues, and that your safety and comfort are always our top priorities."
    },
    {
      "WhyChooseheading": "Timely Service",
      "WhyChoosedescription": "At SaitirthaTours & Travels, we understand the value of time, which is why we prioritize punctuality in all our services. Our drivers will always ensure timely pick-ups and drop-offs, respecting your travel schedule. Whether you have a flight to catch, an important meeting, or a wedding to attend, you can rely on us to be there on time, every time. Our punctual service allows you to focus on your journey without the stress of delays."
    },
    {
      "WhyChooseheading": "24/7 Customer Support",
      "WhyChoosedescription": "Our dedicated customer support team is available 24/7 to assist with any questions, booking inquiries, or last-minute changes. Whether you need help with making a reservation, want to modify your booking, or need any other assistance, we’re here to provide timely support. Our customer service ensures that you can travel with peace of mind, knowing that help is always just a call away, whenever you need it."
    }
  ]
  



        
  }


  const faqData = [
    {
        question: "What is the rental price for the Kia Carens in Shirdi?",
        answer: "The rental price varies based on distance and trip duration. Contact us for an exact quote."
    },
    {
        question: "Is the Kia Carens suitable for long-distance travel?",
        answer: "Yes, the Kia Carens is comfortable and well-suited for long journeys."
    },
    {
        question: "Can I rent the Kia Carens for outstation trips?",
        answer: "Absolutely, we offer rental services for both local and outstation travel."
    },
    {
        question: "Is a driver included in the rental?",
        answer: "Yes, a professional driver is included with the rental."
    },
    {
        question: "Are fuel costs included in the rental price?",
        answer: "Typically, fuel costs are separate. However, we can arrange an all-inclusive package upon request."
    },
    {
        question: "Can I extend the rental period if needed?",
        answer: "Yes, rental extensions are possible, subject to vehicle availability."
    },
    {
        question: "Is local pickup and drop available in Shirdi?",
        answer: "Yes, we offer convenient pickup and drop services within Shirdi and nearby areas."
    },
    {
        question: "What is the cancellation policy?",
        answer: "We offer a flexible cancellation policy. Please contact our team for specific terms."
    },
    {
        question: "Is there a limit on the distance I can travel with the rental?",
        answer: "Our team can provide details on distance limits and any associated charges for exceeding them."
    },
    {
        question: "Are discounts available for multi-day rentals?",
        answer: "Yes, discounts are offered for extended bookings. Contact us to learn more."
    }
];

const testimonials = [
    {
        name: "Mr. Arjun Patel",
        text: "Renting the Kia Carens from Saitirtha Travels during my trip to Shirdi was an excellent choice! The car was modern, spacious, and very comfortable for our family. The service provided by the staff was outstanding, making the entire process hassle-free. I highly recommend Saitirtha Travels for anyone needing a reliable vehicle in Shirdi!"
    },
    {
        name: "Ms. Neha Joshi",
        text: "I had a wonderful experience with Saitirtha Travels when I rented the Kia Carens for my visit to Shirdi. The car was in fantastic condition and offered a smooth ride throughout our journey. The team was professional and attentive, ensuring all our needs were met. I will definitely choose Saitirtha Travels for my future car rentals!"
    }
];




  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
<Helmet>
    <title>Contact: +91 9923879009 | SaitirthaTours & Travels | Kia Carens On Rent in Shirdi | Best Taxi Service | SaitirthaTours & Travels</title>
    <meta name="description" content="Rent Kia Carens in Shirdi with SaitirthaTours & Travels. Offering Kia Carens rental services for weddings, corporate events, and outstation trips including to Mumbai, Goa, Mahabaleshwar, and more." />
    <meta property="og:title" content="Contact: +91 9923879009 | SaitirthaTours & Travels | Kia Carens On Rent in Shirdi | Best Taxi Service | SaitirthaTours & Travels" />
    <meta property="og:description" content="Rent Kia Carens in Shirdi with SaitirthaTours & Travels. Offering Kia Carens rental services for weddings, corporate events, and outstation trips including to Mumbai, Goa, Mahabaleshwar, and more." />
    <meta property="og:url" content="https://saitirthatravels.com/kia-carens-on-rent-shirdi" />
    <meta property="og:image" content="https://saitirthatravels.com/images/kia-carens-on-rent-shirdi.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Rent Kia Carens in Shirdi with SaitirthaTours & Travels. Offering Kia Carens rental services for weddings, corporate events, and outstation trips including to Mumbai, Goa, Mahabaleshwar, and more.",
            "url": "https://saitirthatravels.com/kia-carens-on-rent-shirdi",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/kia-carens-on-rent-shirdi.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.9",
                "reviewCount": "250"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi to Mumbai Kia Carens Car on Rent",
                "Shirdi to Mahabaleshwar Kia Carens on Rent",
                "Shirdi to Outstation Kia Carens on Rent",
                "Shirdi to Goa Kia Carens Cab Service",
                "Taxi Service Kia Carens in Shirdi",
                "Kia Carens Cabs in Shirdi",
                "Kia Cabs in Shirdi Airport",
                "Kia Carens on Rent in Shirdi",
                "Kia Carens on Rent in Shirdi for Wedding",
                "Kia Carens for Corporate Events",
                "Kia Carens for Corporate Office"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/76.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Kiacarens;