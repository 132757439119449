
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Toyotaetion() {



  const cardData =
  {
    keyword: ' Toyota Etios on Rent in Shirdi',
    heading: 'Saitirtha Travels: Toyota Etios on Rent in Shirdi',
    headingDescription: 'For travelers seeking a comfortable and reliable ride in Shirdi, Saitirtha Travels offers the Toyota Etios for rent. This compact sedan is known for its spacious interior, smooth handling, and excellent fuel efficiency, making it an ideal choice for both short trips and longer journeys. Enjoy the convenience and comfort of a personal vehicle as you explore the spiritual and scenic attractions of Shirdi.',

    top: 'Top Places to Visit in Shirdi with Saitirtha Travels',

 "topPlaces": [
    {
        "title": "Shri Sai Baba Samadhi Mandir",
        "description": "The Shri Sai Baba Samadhi Mandir is the heart and soul of Shirdi, dedicated to the revered spiritual leader Sai Baba, who is celebrated for his teachings of love, compassion, and selflessness. Millions of devotees from around the globe converge here to pay their respects, drawn by the temple's peaceful ambiance and the aura of devotion that permeates the surroundings. The temple's architecture features beautiful carvings and intricate designs that reflect the cultural heritage of India. During the daily aarti ceremonies, the atmosphere transforms into a deeply spiritual experience, with melodious bhajans (devotional songs) and the scent of incense creating a serene setting for meditation and prayer. The shrine, which houses the samadhi (final resting place) of Sai Baba, serves as a reminder of his enduring influence and the universal message of unity among all faiths."
    },
    {
        "title": "Dwarkamai Masjid",
        "description": "Dwarkamai Masjid is a historic mosque where Sai Baba spent a significant portion of his life, making it a crucial pilgrimage site for his followers. Within its walls lies a sacred fire, known as the dhuni, which has been burning continuously since Sai Baba's time, symbolizing the eternal light of wisdom and spiritual guidance. Visitors to Dwarkamai can immerse themselves in the peaceful atmosphere as they witness the devotion of countless pilgrims who come to offer prayers and seek blessings. The mosque is adorned with photographs and artifacts related to Sai Baba, allowing visitors to connect with his life story and teachings. The serene environment, combined with the sound of chanting and prayer, makes Dwarkamai a profound place for reflection and spiritual connection."
    },
    {
        "title": "Chavadi",
        "description": "Chavadi holds immense spiritual significance as the place where Sai Baba would rest every alternate night. This modest structure is decorated with beautiful offerings from devotees, showcasing their love and reverence for the saint. The interior of Chavadi is adorned with flowers, incense, and photographs, creating a vibrant atmosphere of devotion. The evening aarti performed here is a special occasion that attracts many visitors, providing a unique opportunity to participate in collective worship and experience the fervent devotion of fellow pilgrims. Chavadi is a symbol of Sai Baba's simplicity and humility, making it a place of profound historical and spiritual importance."
    },
    {
        "title": "Shri Sai Baba Museum",
        "description": "The Shri Sai Baba Museum is an enlightening destination that houses an extensive collection of artifacts related to Sai Baba. Here, visitors can explore personal items, such as his robes and utensils, along with photographs and manuscripts that offer insight into his life and teachings. The museum provides a comprehensive understanding of Sai Baba's philosophy, which emphasizes the importance of faith, charity, and compassion. As you wander through the exhibits, you can learn about the profound impact Sai Baba had on spirituality and the lives of countless devotees. The museum serves as a valuable educational resource, allowing visitors to appreciate the rich legacy of this beloved saint."
    },
    {
        "title": "Khandoba Temple",
        "description": "Situated atop a hill, Khandoba Temple is dedicated to Lord Khandoba, a significant deity in local folklore. This temple not only serves as a vital pilgrimage site but also offers breathtaking views of the surrounding landscape, making it a popular spot for both worship and photography. The temple's architecture reflects traditional Indian design, with intricately carved pillars and vibrant decorations that enhance its spiritual ambiance. Visitors are often drawn to the temple during vibrant festivals, where they can witness colorful celebrations and partake in rituals. The serene atmosphere and panoramic views provide a perfect backdrop for meditation and introspection, making Khandoba Temple a must-visit destination."
    },
    {
        "title": "Saptashrungi Devi Temple",
        "description": "A short drive from Shirdi leads you to the Saptashrungi Devi Temple, dedicated to the goddess Saptashrungi. Nestled amidst stunning hills, this temple is known for its breathtaking natural beauty and tranquil atmosphere. The temple is accessible via a scenic trek that offers a refreshing escape into nature, attracting those seeking both adventure and spirituality. Upon arrival, visitors can participate in rituals and seek blessings from the goddess, who is believed to grant wishes and bestow prosperity. The peaceful surroundings and the divine ambiance of the temple make it an ideal place for worship and contemplation, allowing visitors to connect with their spiritual selves."
    },
    {
        "title": "Shirdi Village",
        "description": "Exploring the village of Shirdi offers a delightful glimpse into local culture and traditions. The village is vibrant with life, featuring local markets where you can shop for handicrafts, religious souvenirs, and enjoy traditional snacks that reflect the culinary heritage of the region. Engaging with the warm and hospitable local community enhances your visit, providing insights into their daily lives and spiritual practices. Strolling through the village, you can experience the unique blend of spirituality and local culture, making it an enriching part of your journey in Shirdi. The lively atmosphere, coupled with the sounds of prayer and laughter, creates a memorable experience for all visitors."
    },
    {
        "title": "Naldurg Fort",
        "description": "Naldurg Fort, located near Shirdi, is a historic site that offers a fascinating glimpse into the region's past. Known for its impressive architecture and strategic significance, the fort features massive walls and bastions that tell stories of the bygone era. Visitors can explore the fort's various structures, including temples and living quarters, which provide insight into the lives of those who inhabited it. The panoramic views from the fort offer stunning vistas of the surrounding landscape, making it a perfect spot for photography and exploration. History enthusiasts will appreciate the fort's rich heritage and its role in the history of the region, making Naldurg Fort a captivating destination."
    },
    {
        "title": "Bhimashankar Wildlife Sanctuary",
        "description": "Located a bit further from Shirdi, Bhimashankar Wildlife Sanctuary is a nature lover's paradise. Spanning over a vast area of lush greenery, the sanctuary is home to a rich variety of flora and fauna, including the endangered Giant Indian Squirrel. The sanctuary offers numerous trekking trails that cater to adventure seekers, allowing visitors to immerse themselves in the beauty of nature. As you trek through the sanctuary, you may encounter various wildlife species and experience the serenity of the natural environment. Bhimashankar is an ideal destination for those looking to connect with nature and escape the hustle and bustle of daily life, providing a refreshing retreat in the lap of nature."
    },
    {
        "title": "Aurangabad Caves",
        "description": "The Aurangabad Caves, located near Shirdi, are renowned for their exquisite rock-cut sculptures and historical significance. Dating back to the 2nd to 6th centuries, these ancient caves showcase stunning artwork that reflects the Buddhist traditions of the time. Each cave features intricate carvings and frescoes that narrate stories from Buddhist scriptures, providing a fascinating insight into the region's rich cultural heritage. A visit to the Aurangabad Caves is not only a visual treat but also an educational experience, allowing visitors to appreciate the craftsmanship and artistry of ancient civilizations. The peaceful surroundings of the caves offer a perfect setting for contemplation and exploration."
    }
],
    
            
        
"services": [
    {
        "name": "Toyota Etios on Rent in Shirdi",
        "description": "Explore Shirdi and its surroundings with our reliable Toyota Etios rental services, offering a blend of comfort, style, and efficiency for local and outstation trips."
    },
    {
        "name": "Spacious and Comfortable Interior",
        "description": "Experience the spacious interiors of the Toyota Etios, designed for both solo travelers and families, ensuring a smooth ride through Shirdi's attractions."
    },
    {
        "name": "Rent Toyota Etios Shirdi",
        "description": "Renting a Toyota Etios in Shirdi is easy with flexible booking options, allowing you to explore at your own pace with a well-maintained vehicle."
    },
    {
        "name": "Etios Car Hire Shirdi",
        "description": "Our Etios car hire services in Shirdi provide a seamless travel experience with various rental packages tailored to your needs."
    },
    {
        "name": "Affordable Etios Rental Shirdi",
        "description": "We offer competitive rates for an affordable Etios rental in Shirdi, ensuring great value without compromising on quality."
    },
    {
        "name": "Toyota Etios for Hire in Shirdi",
        "description": "Perfect for business trips or family vacations, our Toyota Etios for hire in Shirdi includes modern amenities for a comfortable ride."
    },
    {
        "name": "Best Etios Rental Deals Shirdi",
        "description": "Take advantage of our best Etios rental deals in Shirdi, featuring attractive discounts and packages tailored to your travel needs."
    },
    {
        "name": "Shirdi Toyota Etios Rental Services",
        "description": "Our Shirdi Toyota Etios rental services focus on providing a hassle-free experience with professional customer service and well-maintained vehicles."
    },
    {
        "name": "Toyota Etios on Hire Near Shirdi",
        "description": "Conveniently find a Toyota Etios on hire near Shirdi with our ready-to-book services, ensuring you have a vehicle whenever you need it."
    },
    {
        "name": "Shirdi Toyota Etios Car Rental Rates",
        "description": "Check our Shirdi Toyota Etios car rental rates, structured to fit various budgets with a commitment to transparency and no hidden charges."
    },
    {
        "name": "Etios Rental Agency Shirdi",
        "description": "As a trusted Etios rental agency in Shirdi, we ensure our vehicles are regularly serviced and equipped for your safety and comfort."
    },
    {
        "name": "Sedan Cab on Rent Shirdi Airport",
        "description": "Arriving at Shirdi Airport? Book our sedan cab on rent for a convenient and comfortable pick-up service to your destination."
    },
    {
        "name": "Sedan Taxi Service in Shirdi",
        "description": "Our sedan taxi service in Shirdi offers reliable transportation for local trips or longer distances, ensuring a comfortable ride."
    },
    {
        "name": "Swift Dzire on Rent in Shirdi",
        "description": "Explore alternatives with our Swift Dzire on rent in Shirdi, offering the same level of comfort and reliability."
    },
    {
        "name": "Shirdi to Manmad Swift Dzire on Rent",
        "description": "Planning a trip from Shirdi to Manmad? Rent our Swift Dzire for a comfortable journey with spacious interiors."
    },
    {
        "name": "Sedan Car on Rent Shirdi Package",
        "description": "Explore our sedan car on rent Shirdi package, including flexible rental terms and additional services to enhance your travel experience."
    },
    {
        "name": "Contact Information",
        "description": "For prompt and efficient Toyota Etios on rent in Shirdi, contact Saitirtha Travels at +91 9923879009 / 9922479009 for a smooth and enjoyable ride."
    }
],
"tableData": [
    ["- Toyota Etios in Shirdi", "- Rent Toyota Etios Shirdi"],
    ["- Etios Car Hire Shirdi", "- Affordable Etios Rental Shirdi"],
    ["- Toyota Etios for Hire in Shirdi", "- Best Etios Rental Deals Shirdi"],
    ["- Shirdi Toyota Etios Rental Services", "- Toyota Etios on Hire Near Shirdi"],
    ["- Shirdi Toyota Etios Car Rental Rates", "- Etios Rental Agency Shirdi"],
    ["- Sedan Cab on Rent Shirdi Airport", "- Sedan Taxi Service in Shirdi"],
    ["- Sedan Cab Service in Shani Shingnapur", "- SaitirthaTours & Travels Shirdi"],
    ["- Swift Dzire on Rent in Shirdi", "- Shirdi to Manmad Swift Dzire on Rent"],
    ["- Sedan Car on Rent Shirdi Package"]
],
"whychoose": [
    {
        "WhyChooseheading": "Comfortable and Spacious Interior:",
        "WhyChoosedescription": "Our Toyota Etios is designed to provide a comfortable and spacious interior for all passengers. With ample legroom and comfortable seating, it’s perfect for both short and long journeys. Whether you’re traveling solo or with family, you can enjoy a relaxed ride without feeling cramped."
    },
    {
        "WhyChooseheading": "Reliable Performance:",
        "WhyChoosedescription": "The Toyota Etios is known for its reliability and fuel efficiency. It offers a smooth driving experience, making it an ideal choice for both city driving and highway travel. You can trust our vehicles to perform well, no matter the road conditions."
    },
    {
        "WhyChooseheading": "Well-Maintained Fleet:",
        "WhyChoosedescription": "We take pride in our fleet of well-maintained Toyota Etios vehicles. Each car undergoes regular servicing and checks to ensure they meet safety and performance standards. You can travel with peace of mind knowing you are in a safe and reliable vehicle."
    },
    {
        "WhyChooseheading": "Transparent and Affordable Pricing:",
        "WhyChoosedescription": "At Saitirtha Travels, we believe in transparent pricing with no hidden costs. Our affordable rates for renting a Toyota Etios make it easy for you to plan your travel budget. We offer competitive pricing tailored to suit different travel needs, ensuring excellent value for money."
    },
    {
        "WhyChooseheading": "Experienced Drivers:",
        "WhyChoosedescription": "Our drivers are not only skilled at navigating the roads but also knowledgeable about the local area. They ensure a safe and efficient travel experience, allowing you to relax and enjoy the journey. Whether you need recommendations for local attractions or assistance with your itinerary, our drivers are here to help."
    },
    {
        "WhyChooseheading": "Flexible Rental Options:",
        "WhyChoosedescription": "We offer flexible rental packages that can be tailored to your specific needs, whether you require the vehicle for a few hours, a full day, or longer. You can choose from hourly, daily, or multi-day rentals based on your travel plans, making it convenient for any occasion."
    },
    {
        "WhyChooseheading": "24/7 Customer Support:",
        "WhyChoosedescription": "Our dedicated customer service team is available 24/7 to assist you with your bookings and answer any queries. Whether you have questions about your rental, need help with your itinerary, or require support during your trip, we’re just a phone call away."
    },
    {
        "WhyChooseheading": "Safety and Hygiene Protocols:",
        "WhyChoosedescription": "Your safety is our utmost priority. We implement strict hygiene protocols, including regular sanitization of our vehicles and adherence to health guidelines. You can travel confidently, knowing that we take every precaution to ensure a clean and safe environment for our passengers."
    },
    {
        "WhyChooseheading": "Easy Booking Process:",
        "WhyChoosedescription": "Booking your Toyota Etios is made easy with our user-friendly online platform. You can reserve your car in just a few clicks, and our quick confirmation process ensures you can start your journey with minimal delay. For personalized assistance, our customer service team is ready to help via phone or email."
    }
]


        
  }


  const faqData = [
    {
        question: "How can I book a Tempo Traveller from Shirdi?",
        answer: "You can book a Tempo Traveller through our website or by contacting our customer support team for assistance."
    },
    {
        question: "What are the seating arrangements in the 17-seater Tempo Traveller?",
        answer: "The Tempo Traveller features comfortable seating arrangements designed to accommodate 17 passengers with ample legroom."
    },
    {
        question: "Are the rental rates inclusive of driver fees and fuel?",
        answer: "The rental rates typically include driver fees, but fuel charges may be calculated separately. Please check during booking for complete details."
    },
    {
        question: "Can I hire a Tempo Traveller for a one-way trip?",
        answer: "Yes, we offer both one-way and round-trip rental options for your convenience."
    },
    {
        question: "What payment methods do you accept?",
        answer: "We accept various payment methods, including credit/debit cards, UPI, and cash payments."
    },
    {
        question: "Are your drivers experienced and licensed?",
        answer: "Yes, all our drivers are professional, licensed, and trained to provide a safe and enjoyable travel experience."
    },
    {
        question: "What if I need to change or cancel my booking?",
        answer: "You can modify or cancel your booking by contacting our customer support team prior to your scheduled rental date."
    },
    {
        question: "What safety measures do you have in place?",
        answer: "We ensure that our vehicles are regularly sanitized, and our drivers adhere to health protocols to ensure your safety during travel."
    },
    {
        question: "Is customer support available for inquiries?",
        answer: "Yes, our customer support team is available 24/7 to assist you with any questions or concerns."
    },
    {
        question: "Can I specify any additional requirements for my rental?",
        answer: "Yes, you can communicate any special requirements during the booking process, and we will do our best to accommodate them."
    }
];

const testimonials = [
    {
        name: "Mrs. Anjali Mehta",
        text: "Our group trip from Shirdi to various attractions was a memorable experience thanks to Saitirtha Travels. The 17-seater Tempo Traveller was spacious and comfortable, and our driver was incredibly helpful. He knew all the best routes and made sure we had a fantastic time. I highly recommend this service for anyone needing group transport!"
    },
    {
        name: "Mr. Ramesh Kulkarni",
        text: "I rented a 17-seater Tempo Traveller from Shirdi for a family function, and I couldn't have been happier with the choice. The vehicle was clean and well-maintained, and our driver was friendly and professional. It made our travel stress-free and enjoyable. I will definitely choose Saitirtha Travels for my future trips!"
    }
];



  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Toyota Etios On Rent in Shirdi | Affordable Car Rentals | SaitirthaTours & Travels</title>
    <meta name="description" content="Rent a Toyota Etios in Shirdi. Affordable rental deals, best rates for Etios car hire, sedan taxi services in Shirdi. Book your Toyota Etios rental now!" />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirtha Travels | Toyota Etios On Rent in Shirdi | Affordable Car Rentals | SaitirthaTours & Travels" />
    <meta property="og:description" content="Rent a Toyota Etios in Shirdi. Affordable rental deals, best rates for Etios car hire, sedan taxi services in Shirdi. Book your Toyota Etios rental now!" />
    <meta property="og:url" content="https://saitirthatravels.com/toyota-etios-on-rent-shirdi" />
    <meta property="og:image" content="https://saitirthatravels.com/images/toyota-etios-on-rent-shirdi.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Rent a Toyota Etios in Shirdi. Affordable rental deals, best rates for Etios car hire, sedan taxi services in Shirdi. Book your Toyota Etios rental now!",
            "url": "https://saitirthatravels.com/toyota-etios-on-rent-shirdi",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/toyota-etios-on-rent-shirdi.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "150"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Toyota Etios on rent in Shirdi",
                "Rent Toyota Etios Shirdi",
                "Etios car hire Shirdi",
                "Affordable Etios rental Shirdi",
                "Best Etios rental deals Shirdi",
                "Shirdi Toyota Etios rental services",
                "Toyota Etios on hire near Shirdi",
                "Shirdi Toyota Etios car rental rates",
                "Etios rental agency Shirdi",
                "Sedan cab on rent Shirdi airport",
                "Sedan taxi service in Shirdi",
                "Sedan Cab Service in Shani Shingnapur",
                "SaitirthaTours & Travels Shirdi",
                "Swift Dzire on Rent in Shirdi",
                "Shirdi to Manmad Swift Dzire on Rent",
                "Sedan car on rent Shirdi package"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/49.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Toyotaetion;