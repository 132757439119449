
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Shirditoallmaharashtra() {



  const cardData =
  {
    keyword: ' Shirdi to All Maharashtra Cab Service',
    heading: 'Saitirtha Travels: Shirdi to All Maharashtra Cab Service',
    headingDescription: 'Saitirtha Travels provides a comprehensive cab service from Shirdi to various destinations across Maharashtra, ensuring a comfortable and convenient travel experience. Whether you’re a pilgrim visiting sacred sites, a tourist exploring natural beauty, or a business traveler, our cab service caters to your needs with a range of vehicles and flexible booking options. Our drivers are experienced and knowledgeable about the routes, allowing you to enjoy the scenic views of Maharashtra during your journey.',

    top: 'Top Places to Visit in Maharashtra with Saitirtha Travels',

    topPlaces: [
        {
            "title": "Ajanta Caves",
            "description": "A UNESCO World Heritage site, the Ajanta Caves are an exquisite collection of Buddhist rock-cut caves dating back to the 2nd century BCE. Known for their intricate sculptures and frescoes, these caves offer visitors a window into India's ancient artistic and spiritual heritage, making them a must-visit for history and art lovers."
        },
        {
            "title": "Ellora Caves",
            "description": "The Ellora Caves, another UNESCO site, display an extraordinary mix of Buddhist, Hindu, and Jain rock-cut temples and monasteries. The stunning Kailasa Temple, carved entirely from a single rock, is a remarkable feat of engineering and artistry, captivating all who visit."
        },
        {
            "title": "Mahabaleshwar",
            "description": "Famous for its lush landscapes and cool climate, Mahabaleshwar is a popular hill station offering numerous scenic viewpoints. Visitors can enjoy boating on Venna Lake, explore strawberry farms, and take in panoramic views from points like Arthur's Seat and Wilson Point."
        },
        {
            "title": "Panchgani",
            "description": "Close to Mahabaleshwar, Panchgani is celebrated for its scenic beauty and pleasant climate. Table Land, one of the highest plateaus in Asia, is ideal for picnics and adventure activities, while the area’s rich flora and fauna make it a favorite for nature walks and trekking."
        },
        {
            "title": "Shani Shingnapur",
            "description": "This unique village is renowned for the Shani Shingnapur Temple and its custom of houses without doors, protected by the blessings of Lord Shani. Pilgrims visit for blessings and to experience the vibrant festivals dedicated to Lord Shani throughout the year."
        },
        {
            "title": "Nashik",
            "description": "Known as India's wine capital, Nashik offers numerous vineyards for tours and tastings. It's also a major pilgrimage site, hosting the Kumbh Mela every 12 years, and features mythologically rich areas like Panchavati for an enriching blend of spiritual and cultural experiences."
        },
        {
            "title": "Aurangabad",
            "description": "Aurangabad serves as a gateway to the Ajanta and Ellora Caves. The city itself has historical treasures like Bibi Ka Maqbara, an architectural marvel reminiscent of the Taj Mahal, and the Aurangabad Caves, which showcase impressive Buddhist rock-cut designs."
        },
        {
            "title": "Alibaug",
            "description": "A charming coastal town, Alibaug is known for its pristine beaches and historic forts. Visitors can enjoy water sports, beach activities, and explore the Kolaba Fort, accessible just off the shore, making Alibaug an ideal retreat for a seaside escape."
        },
        {
            "title": "Lonavala and Khandala",
            "description": "These scenic hill stations are especially beautiful during the monsoon season, with lush green landscapes and serene lakes. Lonavala is famous for its chikki, while Khandala offers breathtaking views of the Sahyadri mountains, attracting nature lovers and trekkers alike."
        },
        {
            "title": "Kolhapur",
            "description": "Kolhapur is steeped in culture and history, famous for the revered Mahalaxmi Temple and its vibrant traditional markets. The city is also known for Kolhapuri chappals and spicy local delicacies like misal pav, making it a must-visit for both cultural and culinary exploration."
        }
    ],     
            
  "services": [
    {
        "name": "Shirdi to All Maharashtra Cab Service",
        "description": "Discover the convenience of traveling from Shirdi to all major destinations in Maharashtra with our premium cab service. At SaitirthaTours & Travels, we offer a wide range of vehicle options, from budget-friendly sedans to spacious SUVs, catering to both individual travelers and groups. Whether you're heading to a business meeting, a family event, or a sightseeing adventure, our professional drivers ensure a comfortable, safe, and hassle-free journey, letting you enjoy the scenic routes of Maharashtra with peace of mind. Trust us to get you there in style and on time."
    },
    {
        "name": "Shirdi to Sambhaji Nagar Cab Service",
        "description": "Travel with ease from Shirdi to Sambhaji Nagar with our dedicated cab service. Our experienced drivers are familiar with the best routes, ensuring you have a smooth and comfortable journey, whether for work or leisure. With clean, well-maintained vehicles and flexible booking options, we make it easy to enjoy a pleasant travel experience. Sit back and relax as we take you from Shirdi to Sambhaji Nagar with reliability and punctuality in mind."
    },
    {
        "name": "Shirdi to Aurangabad Cab Price",
        "description": "Our cab services from Shirdi to Aurangabad offer transparent and competitive pricing, ensuring you receive value for money without sacrificing comfort or quality. Our modern fleet includes a range of vehicle options to fit your budget, whether you're traveling solo, with family, or with a group. Request a customized quote and discover how affordable quality transportation can be. Enjoy a worry-free journey with no hidden costs, where your comfort and satisfaction are our top priorities."
    },
    {
        "name": "Shirdi to Jalgaon Cab Service",
        "description": "Our Shirdi to Jalgaon cab service provides an easy, comfortable, and efficient way to reach your destination. Our vehicles are equipped with amenities to enhance your travel experience, and our courteous drivers are trained to offer a safe and pleasant ride. We accommodate flexible schedules and pick-up locations to ensure your journey fits seamlessly into your plans, making it an ideal choice for business and leisure travelers alike."
    },
    {
        "name": "Shirdi to Dhule Cab Service",
        "description": "Travel from Shirdi to Dhule with confidence and ease through our cab service, designed to meet all your travel needs. With a focus on reliability, punctuality, and comfort, our service is perfect for business travelers and families seeking convenience and safety. Enjoy a smooth ride in our well-maintained cars, each equipped with comfortable seating and ample space for luggage, ensuring you arrive relaxed and refreshed at your destination."
    },
    {
        "name": "Shirdi to Jalna Cab Service",
        "description": "Our Shirdi to Jalna cab service offers travelers a stress-free, enjoyable journey with reliable pick-up and drop-off options. We understand the importance of timely arrivals, so our drivers take the most efficient routes to get you to your destination without delay. Our fleet includes a variety of vehicles to meet different preferences, all featuring comfortable interiors and smooth rides. Choose us for a journey where your needs come first."
    },
    {
        "name": "Shirdi to Nandurbar Cab",
        "description": "Make the journey from Shirdi to Nandurbar in comfort with our cab service, designed to provide a premium experience for all passengers. Our drivers are experienced in long-distance travel, ensuring a smooth ride and professional service. Whether you're traveling for a day trip or an extended stay, our vehicles are equipped to make your journey as relaxing and enjoyable as possible. Expect courteous service, reliable transportation, and a seamless booking process."
    },
    {
        "name": "Shirdi to Nandurbar Sedan Cab",
        "description": "For a stylish and comfortable journey from Shirdi to Nandurbar, choose our sedan cab service. Ideal for individuals or small groups, our sedans offer plush seating, modern interiors, and spacious legroom, making them perfect for long-distance trips. Enjoy a premium travel experience with our skilled drivers who prioritize your safety and comfort. Let us take you to Nandurbar in style, so you can arrive refreshed and ready for your day."
    },
    {
        "name": "Shirdi to Amravati Cab",
        "description": "Our Shirdi to Amravati cab service provides a dependable and enjoyable way to reach your destination. We offer a variety of vehicle options suited to your travel needs, all maintained to the highest standards of safety and comfort. Our drivers are well-versed with the route, allowing you to relax and enjoy the journey. With flexible booking options and attentive customer service, we are dedicated to making your travel experience as smooth as possible."
    },
    {
        "name": "Shirdi to Nagpur Cab",
        "description": "Travel to Nagpur from Shirdi with ease using our high-quality cab service. Known for our reliability and professionalism, we provide a range of vehicle choices to suit your comfort needs. Our drivers are experienced in long-distance travel and ensure that you arrive safely and on time. Whether you're on a business trip or a leisure journey, enjoy a comfortable ride with our premium service, where customer satisfaction is always our priority."
    },
    {
        "name": "Shirdi to Ahilya Nagar Cab",
        "description": "Our Shirdi to Ahilya Nagar cab service is the perfect solution for travelers seeking comfort and convenience. Our drivers are skilled, courteous, and prioritize your safety, taking you to Ahilya Nagar with the utmost professionalism. Our vehicles are modern and spacious, equipped with comfortable seating and air conditioning to enhance your travel experience. Enjoy peace of mind knowing that we have every aspect of your journey covered."
    },
    {
        "name": "Aliya Nagar Cabs Service",
        "description": "Our Aliya Nagar cab service from Shirdi offers a reliable and comfortable transportation option for all travelers. With well-trained drivers and a fleet of clean, well-maintained vehicles, we ensure that every ride is smooth and pleasant. Our service is ideal for both short trips and longer journeys, allowing you to relax and enjoy your travel experience with complete confidence."
    },
    {
        "name": "Local & Outstation Taxi Service in Shirdi",
        "description": "Our taxi services in Shirdi are designed to accommodate both local and outstation travel needs. With a focus on flexibility and quality, we offer transportation solutions for short trips within Shirdi or long-distance journeys across Maharashtra. Our vehicles are clean, safe, and equipped with modern amenities, and our drivers are knowledgeable and friendly. Trust us for all your transportation needs, whether you’re visiting Shirdi’s landmarks or heading to other cities."
    },
    {
        "name": "Book Outstation Taxi Service in Shirdi",
        "description": "Booking an outstation taxi from Shirdi has never been easier. With a range of vehicle options and reliable service, our outstation taxi service is ideal for travelers seeking a comfortable, efficient, and stress-free ride. Our transparent pricing and seamless booking process make it easy for you to arrange your journey, while our professional drivers ensure you reach your destination safely and on time."
    },
    {
        "name": "Shirdi to Satara Cab",
        "description": "Choose our Shirdi to Satara cab service for a reliable and enjoyable travel experience. Our fleet includes a range of vehicles to suit different budgets and group sizes, and our professional drivers make sure your journey is smooth and pleasant. Enjoy the convenience of door-to-door service, with a focus on punctuality, comfort, and safety that lets you relax and enjoy the ride."
    },
    {
        "name": "Shirdi to Nashik Cab",
        "description": "Our Shirdi to Nashik cab service is designed to make your travel as seamless and comfortable as possible. With knowledgeable drivers, clean vehicles, and a dedication to excellent customer service, we provide a reliable way to get to Nashik. Our flexible scheduling and transparent pricing allow you to plan your trip with ease, making us the preferred choice for travelers heading to Nashik."
    },
    {
        "name": "Shirdi to Mumbai Cab Service",
        "description": "Our Shirdi to Mumbai cab service offers a convenient and comfortable solution for travelers heading to the vibrant city of Mumbai. With a range of vehicles and professional drivers, we provide a smooth, reliable ride that allows you to focus on your journey rather than the logistics. Enjoy spacious seating, clean interiors, and attentive service from our team, committed to making your travel experience exceptional."
    },
    {
        "name": "Shirdi to Pune Cab",
        "description": "Our cab service from Shirdi to Pune is ideal for those seeking a safe, affordable, and comfortable journey. We provide well-maintained vehicles, skilled drivers, and competitive pricing to make your trip enjoyable and worry-free. Whether you’re traveling for business or leisure, our team ensures you have a pleasant experience from start to finish, with customer satisfaction as our priority."
    },
    {
        "name": "Shirdi to All Maharashtra Cab Service Contact Information",
        "description": "For bookings, quotes, or general inquiries regarding our cab services across Maharashtra, reach out to SaitirthaTours & Travels at +91 9923879009 / +91 9922479009. Our friendly team is available to assist with all your transportation needs, ensuring you receive the best service for a memorable journey."
    }
],

tableData: [
    ["- Shirdi to Sambhaji Nagar Cab Service", "- Shirdi to Aurangabad Cab Price"],
    ["- Shirdi to Jalgaon Cab Service", "- Shirdi to Dhule Cab Service"],
    ["- Shirdi to Jalna Cab Service", "- Shirdi to Nandurbar Cab"],
    ["- Shirdi to Nandurbar Sedan Cab", "- Shirdi to Amravati Cab"],
    ["- Shirdi to Nagpur Cab", "- Shirdi to Ahilya Nagar Cab"],
    ["- Aliya Nagar Cabs Service", "- Local & Outstation Taxi Service in Shirdi"],
    ["- Book Outstation Taxi Service in Shirdi", "- Local & Outstation Taxi Service in Shirdi"],
    ["- Shirdi to Satara Cab", "- Shirdi to Nashik Cab"],
    ["- Shirdi to Mumbai Cab Service", "- Shirdi to Pune Cab"],
    ["", ""]
  ],
  
  whychoose: [
    {
      "WhyChooseheading": "Comprehensive Coverage",
      "WhyChoosedescription": "We offer reliable cab services to all major cities and destinations across Maharashtra, ensuring that you can travel to popular places like Sambhaji Nagar, Aurangabad, Nashik, and more. Wherever you need to go, we provide seamless connectivity, making travel across the state easy and stress-free."
    },
    {
      "WhyChooseheading": "Comfortable Rides",
      "WhyChoosedescription": "Our fleet of well-maintained vehicles provides the utmost comfort, ensuring a pleasant journey for you and your companions. Spacious interiors, air conditioning, and modern amenities make your travel experience smooth and comfortable, no matter the distance."
    },
    {
      "WhyChooseheading": "Experienced Drivers",
      "WhyChoosedescription": "Our professional drivers are well-trained, courteous, and knowledgeable about local routes, traffic conditions, and scenic spots. With their expertise, you can rest assured that your journey will be safe, efficient, and enjoyable. They also provide helpful insights on local attractions and dining options."
    },
    {
      "WhyChooseheading": "Affordable Pricing",
      "WhyChoosedescription": "We offer competitive and transparent pricing for all our taxi services. Our affordable rates come with no hidden fees, ensuring that you get the best value for your money. Whether you are booking a short trip or long-distance travel, our pricing ensures that you can travel comfortably without breaking the bank."
    },
    {
      "WhyChooseheading": "Flexible Booking Options",
      "WhyChoosedescription": "We understand that travel plans can change. That’s why we offer flexible booking options for one-way, round-trip, and multi-city travel. Whether you need a quick trip or are planning a multi-day adventure, we provide personalized packages to match your specific requirements."
    },
    {
      "WhyChooseheading": "24/7 Availability",
      "WhyChoosedescription": "Our services are available around the clock, so you can book a cab at any time that suits your schedule. Whether it’s an early morning departure or a late-night arrival, we’re here to provide reliable taxi services whenever you need them."
    },
    {
      "WhyChooseheading": "Customer-Centric Service",
      "WhyChoosedescription": "We are dedicated to ensuring your satisfaction from start to finish. Our team offers personalized support and assistance, ensuring your booking process is smooth and hassle-free. We take pride in going above and beyond to meet your travel needs."
    },
    {
      "WhyChooseheading": "Safety First",
      "WhyChoosedescription": "Your safety is our top priority. All our vehicles are regularly serviced, insured, and adhere to the highest safety standards. We ensure that our drivers follow all safety protocols, and our vehicles are equipped with essential safety features to guarantee your protection during the journey."
    },
    {
      "WhyChooseheading": "Local Insights",
      "WhyChoosedescription": "Our drivers are locals who know the ins and outs of Maharashtra. They can share interesting insights about local culture, history, and tourist attractions, helping you make the most of your journey. Whether you're visiting Shirdi or traveling to nearby cities, they can offer recommendations for dining, sightseeing, and hidden gems."
    },
    {
      "WhyChooseheading": "Seamless Booking Process",
      "WhyChoosedescription": "Booking a cab with us is quick and simple. You can easily book your taxi online via our website or get in touch with our customer support team. Whether you need to book in advance or at the last minute, we make the process efficient and stress-free, so you can focus on enjoying your trip."
    }
  ]
  


        
  }


  const faqData = [
    {
        question: "How do I book a cab for travel across Maharashtra?",
        answer: "You can easily book your cab through our website or by calling our customer service for assistance."
    },
    {
        question: "What cities can I travel to from Shirdi?",
        answer: "We provide services to all major cities in Maharashtra, including Mumbai, Pune, Nashik, Aurangabad, and many more."
    },
    {
        question: "Are the rental rates inclusive of fuel?",
        answer: "Fuel charges may vary depending on the rental agreement. Detailed pricing information is provided during the booking process."
    },
    {
        question: "Can I customize my itinerary?",
        answer: "Yes, we offer customizable packages that allow you to choose your destinations and travel plans."
    },
    {
        question: "Are your drivers experienced and trained?",
        answer: "Yes, all our drivers are professional, experienced, and knowledgeable about local routes and regulations."
    },
    {
        question: "What payment methods do you accept?",
        answer: "We accept various payment methods including cash, credit/debit cards, and mobile wallets for your convenience."
    },
    {
        question: "What is your cancellation policy?",
        answer: "We have a flexible cancellation policy. Please contact us for specific details regarding your booking."
    },
    {
        question: "Is it safe to travel with Saitirtha Travels?",
        answer: "Yes, we prioritize safety with well-maintained vehicles and compliance with all safety regulations."
    },
    {
        question: "Can I request a baby seat for the cab?",
        answer: "Yes, baby seats can be arranged upon request at the time of booking."
    },
    {
        question: "What is your contact information?",
        answer: "For bookings and inquiries, you can reach us at:"
    }
];

const testimonials = [
    {
        name: "Mr. Sudhir Joshi",
        text: "My experience with Saitirtha Travels for the Shirdi to All Maharashtra cab service was outstanding! The cab was comfortable and well-maintained, making our journey smooth. The driver was professional and knowledgeable about the routes, which added to the overall experience. I highly recommend their services for anyone traveling across Maharashtra!"
    },
    {
        name: "Ms. Neha Patil",
        text: "I recently used Saitirtha Travels for a cab service from Shirdi to various destinations in Maharashtra, and it was fantastic! The booking process was simple, and the vehicle was spacious enough for our group. The driver was punctual and very accommodating. I will definitely choose Saitirtha Travels for my future travels!"
    }
];



  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
<Helmet>
    <title>Contact: +91 9923879009 |Saitirtha Travels| Shirdi to All Maharashtra Cab Service | Affordable & Reliable Taxi Services to Maharashtra Destinations</title>
    <meta name="description" content="Book cabs from Shirdi to all major Maharashtra destinations like Sambhaji Nagar, Aurangabad, Jalgaon, Dhule, Nashik, and more. Affordable, reliable, and comfortable taxi services for local & outstation travel. Book now!" />
    <meta name="keywords" content="Shirdi to Sambhaji Nagar Cab Service, Shirdi to Aurangabad Cab Price, Shirdi to Jalgaon Cab Service, Shirdi to Dhule Cab Service, Shirdi to Jalna Cab Service, Shirdi to Nandurbar Cab, Shirdi to Nandurbar Sedan Cab, Shirdi to Amravati Cab, Shirdi to Nagpur Cab, Shirdi to Ahilya Nagar Cab, Aliya Nagar Cabs Service, Local & Outstation Taxi Service in Shirdi, Book Outstation Taxi Service in Shirdi, Local & Outstation Taxi Service in Shirdi, Shirdi to Satara Cab, Shirdi to Nashik Cab, Shirdi to Mumbai Cab Service, Shirdi to Pune Cab" />
    <meta property="og:title" content="Contact: +91 9923879009 | Shirdi to All Maharashtra Cab Service | Affordable & Reliable Taxi Services to Maharashtra Destinations" />
    <meta property="og:description" content="Book cabs from Shirdi to all major Maharashtra destinations like Sambhaji Nagar, Aurangabad, Jalgaon, Dhule, Nashik, and more. Affordable, reliable, and comfortable taxi services for local & outstation travel. Book now!" />
    <meta property="og:url" content="https://saitirthatravels.com/shirdi-to-all-maharashtra-cab-service" />
    <meta property="og:image" content="https://saitirthatravels.com/images/shirdi-maharashtra-cab.jpg" />
    <meta name="twitter:card" content="summary_large_image" />
    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Sai Tirth Cabs Shirdi",
            "description": "Book cabs from Shirdi to all major Maharashtra destinations like Sambhaji Nagar, Aurangabad, Jalgaon, Dhule, Nashik, and more. Affordable, reliable, and comfortable taxi services for local & outstation travel. Book now!",
            "url": "https://saitirthatravels.com/shirdi-to-all-maharashtra-cab-service",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/shirdi-maharashtra-cab.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "250"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi to Sambhaji Nagar Cab Service",
                "Shirdi to Aurangabad Cab Price",
                "Shirdi to Jalgaon Cab Service",
                "Shirdi to Dhule Cab Service",
                "Shirdi to Jalna Cab Service",
                "Shirdi to Nandurbar Cab",
                "Shirdi to Nandurbar Sedan Cab",
                "Shirdi to Amravati Cab",
                "Shirdi to Nagpur Cab",
                "Shirdi to Ahilya Nagar Cab",
                "Aliya Nagar Cabs Service",
                "Local & Outstation Taxi Service in Shirdi",
                "Book Outstation Taxi Service in Shirdi",
                "Local & Outstation Taxi Service in Shirdi",
                "Shirdi to Satara Cab",
                "Shirdi to Nashik Cab",
                "Shirdi to Mumbai Cab Service",
                "Shirdi to Pune Cab"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/95.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirditoallmaharashtra;