
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Shirdiroundtripcab() {



  const cardData =
  {
    keyword: ' Shirdi Round Trip Cab Booking',
    heading: 'Saitirtha Travels: Shirdi Round Trip Cab Booking',
    headingDescription: 'Saitirtha Travels offers convenient round trip cab services from Shirdi, making it easy for travelers to explore the beautiful landscapes and spiritual destinations in and around the region. Our round trip service is designed for flexibility and comfort, allowing you to plan your itinerary according to your preferences. Whether you’re visiting local attractions, exploring nearby cities, or making a pilgrimage, we ensure a hassle-free travel experience.',

    top: 'Top Places to Visit on Round Trip Shirdi with Saitirtha Travels',

    topPlaces: [
        {
            "title": "Shirdi Sai Baba Temple",
            "description": "The Shirdi Sai Baba Temple is the heart of spiritual activity in Shirdi, drawing millions of devotees from across the globe. The temple complex houses the sacred Samadhi (resting place) of Sai Baba and a life-sized marble statue where devotees come to seek blessings and guidance. Visitors can participate in daily rituals and aarti ceremonies, enveloped by the temple's tranquil and divine ambiance. The complex also provides facilities such as accommodation, food, and meditation areas, making it a comprehensive spiritual experience."
        },
        {
            "title": "Sainagar Shirdi",
            "description": "Sainagar Shirdi, surrounding the Sai Baba Temple, includes lush gardens and museums detailing Sai Baba's life and teachings. One notable spot is the Samadhi Mandir, where Sai Baba's followers pay respects in quiet reverence. Sainagar is a place of peace, reflection, and spirituality, with plenty of serene areas for meditation. Additionally, visitors can explore the local markets for religious artifacts and souvenirs that reflect Shirdi's rich cultural heritage."
        },
        {
            "title": "Shani Shingnapur",
            "description": "A unique village near Shirdi, Shani Shingnapur is renowned for its powerful Shani temple, dedicated to Lord Shani, the deity of Saturn. The village has an extraordinary tradition of houses without doors, with villagers believing that Lord Shani's protection makes locks unnecessary. Devotees visit the temple to seek blessings for prosperity, success, and protection from negative influences. Festivities here are vibrant and lively, especially on Shani Amavasya (no-moon day), when thousands gather to celebrate the deity's blessings."
        },
        {
            "title": "Ajanta Caves",
            "description": "The Ajanta Caves, a UNESCO World Heritage site, are a marvel of ancient Buddhist architecture, dating back to the 2nd century BCE. These 30 rock-cut caves, set in a horseshoe-shaped cliff, showcase remarkable frescoes and sculptures depicting scenes from Buddha's life. The murals, which cover themes of compassion, love, and devotion, highlight the caves' historical significance. Art lovers, history enthusiasts, and spiritual seekers alike are mesmerized by the intricate artistry and the caves' serene setting amid the lush landscape."
        },
        {
            "title": "Ellora Caves",
            "description": "Another UNESCO World Heritage site, the Ellora Caves are celebrated for their extraordinary rock-cut architecture, merging Buddhist, Hindu, and Jain traditions. The Kailasa Temple, a monumental structure carved from a single rock, is the complex's centerpiece and stands as one of India's greatest architectural achievements. The complex's 34 caves depict mythological scenes and religious figures, offering visitors a glimpse into the rich religious and cultural history of ancient India. The scale and intricacy of these carvings make it a must-see destination."
        },
        {
            "title": "Nasik",
            "description": "Known as the 'Wine Capital of India,' Nasik is a city that merges spirituality with leisure. Visitors can explore vineyards and wineries, enjoying scenic tours and tastings amidst rolling hills. Besides wine, Nasik is also famous for its Kumbh Mela, held every 12 years along the banks of the Godavari River. The city's many temples, such as Trimbakeshwar and the Kalaram Temple, draw pilgrims year-round. A stroll along the river's ghats, steeped in mythology and history, provides a peaceful experience of the city’s cultural heritage."
        },
        {
            "title": "Aurangabad",
            "description": "Aurangabad is a historical hub in Maharashtra, often visited as a base to explore the Ajanta and Ellora Caves. The city itself is rich with attractions, such as the Bibi Ka Maqbara, an exquisite tomb often referred to as the 'Mini Taj Mahal.' Visitors can also explore the Aurangabad Caves, which offer Buddhist rock-cut architecture and serene surroundings. The local markets showcase handcrafted textiles, jewelry, and Paithani saris, providing a blend of history and vibrant modern culture that appeals to every traveler."
        },
        {
            "title": "Panchgani",
            "description": "Nestled in the Sahyadri mountains, Panchgani is a serene hill station known for its mild climate and scenic views. The Table Land plateau, one of the highest in Asia, offers sweeping vistas, ideal for picnics, trekking, and horse riding. Panchgani’s cooler temperatures make it an attractive retreat, with strawberry farms, quaint cafes, and craft markets that draw visitors looking for relaxation and scenic beauty. Its proximity to Mahabaleshwar allows travelers to explore both hill stations in one trip."
        },
        {
            "title": "Mahabaleshwar",
            "description": "Mahabaleshwar is a picturesque hill station known for its strawberries, misty landscapes, and scenic viewpoints. Visitors can enjoy a peaceful boat ride on Venna Lake, explore strawberry farms, and savor fresh local produce. The viewpoints, such as Arthur's Seat and Wilson Point, offer breathtaking panoramas of valleys and mountains. During the monsoon, the area comes alive with waterfalls and lush greenery, making it a favorite destination for nature lovers and photographers."
        },
        {
            "title": "Kolhapur",
            "description": "Kolhapur is a vibrant city known for its rich cultural heritage and delectable cuisine. The city is famous for the Mahalaxmi Temple, a revered site dedicated to Goddess Mahalaxmi. Kolhapur's bustling markets are filled with handicrafts, including the iconic Kolhapuri chappals (sandals) and traditional jewelry. The local cuisine, particularly dishes like misal pav, is a must-try for food enthusiasts. Kolhapur’s energetic and friendly atmosphere, coupled with its historical sites, makes it an exciting destination to explore."
        }
    ],  
    
            
  "services": [
    {
        "name": "Shirdi Round Trip Cab Booking",
        "description": "Enjoy the convenience of booking a round trip cab from Shirdi with our well-curated services. Whether you're planning to visit temples, explore local attractions, or embark on a sightseeing tour, we have customized packages to suit every travel need. Our round trip service includes professionally maintained vehicles, reliable drivers, and flexible schedules to enhance your experience. Travel with comfort and ease, knowing every detail of your journey has been taken care of."
    },
    {
        "name": "Shirdi to Shani Shingnapur Round Trip Taxi Fare",
        "description": "Our Shirdi to Shani Shingnapur round trip taxi service offers transparent, competitive pricing, making it easy for you to enjoy this sacred journey. Our clean and comfortable cabs, coupled with experienced drivers, ensure a pleasant ride as you explore one of Maharashtra’s famous spiritual destinations. Reach out to us for the best fares and a journey that emphasizes value, comfort, and convenience."
    },
    {
        "name": "Shirdi Round Trip Cab",
        "description": "Book a Shirdi round trip cab to experience seamless travel with SaitirthaTours & Travels. Our cabs are well-maintained and driven by professional chauffeurs who are highly familiar with local routes, ensuring smooth navigation and an enjoyable ride. Whether for a day tour or a longer trip, our round trip cabs allow you to maximize your time in Shirdi and the surrounding areas without any hassle."
    },
    {
        "name": "Shirdi Tour Package from Mumbai (1 Day)",
        "description": "Our 1-day Shirdi tour package from Mumbai is perfect for those looking for a quick spiritual retreat. This package includes round trip transportation, allowing you to experience Shirdi’s serene ambiance within a single day. Our comfortable vehicles and experienced drivers ensure a relaxing journey, giving you peace of mind as you focus on the divine atmosphere of Shirdi. Ideal for families, groups, or individual travelers on a tight schedule."
    },
    {
        "name": "Pune to Shirdi Cabs Round Trip",
        "description": "For travelers from Pune, we offer a reliable round trip cab service to Shirdi, providing comfort and flexibility. Our cabs are equipped with modern amenities, and our drivers are familiar with the Pune-Shirdi route, ensuring a safe and efficient journey. Book with us for a smooth, convenient ride, tailored to your schedule and travel needs, so you can focus on enjoying your visit to Shirdi."
    },
    {
        "name": "Shirdi to Nashik Round Trip Cabs",
        "description": "Travel with ease from Shirdi to Nashik using our round trip cab service, which is designed to make your journey stress-free. Our fleet includes a variety of vehicle options to accommodate all types of travelers, whether solo, in pairs, or in groups. With knowledgeable drivers who prioritize safety and comfort, we ensure a memorable travel experience, allowing you to explore Nashik at your own pace."
    },
    {
        "name": "Shirdi to Ajanta Ellora Round Trip Cabs",
        "description": "Explore the magnificent Ajanta and Ellora Caves with our round trip cab service from Shirdi. Our experienced drivers, who are familiar with the routes and history of these iconic destinations, provide helpful insights during the journey. Travel comfortably and conveniently in our well-equipped cabs, knowing that every detail has been arranged for a hassle-free experience. Ideal for history enthusiasts and cultural travelers alike."
    },
    {
        "name": "Shirdi to Ahmednagar Round Trip Cabs",
        "description": "Our Shirdi to Ahmednagar round trip cab service offers a convenient transportation option for travelers interested in exploring this historic city. Enjoy a smooth and comfortable ride in our spacious vehicles, driven by professionals who know the best routes to Ahmednagar. With flexible booking options, we make it easy for you to plan your journey and enjoy the sights without any worry about transportation."
    },
    {
        "name": "Shirdi to Bhimashankar Round Trip Cab Price",
        "description": "Visit the sacred site of Bhimashankar with our affordable round trip cab service from Shirdi. We offer competitive pricing to ensure that your pilgrimage remains budget-friendly while providing a reliable and comfortable ride. Our vehicles are maintained to high standards, and our drivers are experienced in navigating the journey, making it easy for you to relax and focus on the spiritual aspect of your trip."
    },
    {
        "name": "Shirdi to Trimbakeshwar Round Trip Cab",
        "description": "For those visiting the sacred Trimbakeshwar temple, our Shirdi to Trimbakeshwar round trip cab service offers a safe, enjoyable travel option. Our knowledgeable drivers are familiar with the routes and the requirements of pilgrimage travel, ensuring an efficient and comfortable journey. Relax in our spacious, clean vehicles as we take care of all the details, allowing you to focus on the spiritual experience."
    },
    {
        "name": "Shirdi to 5 Jyotirlinga Cab Package",
        "description": "Embark on a unique spiritual journey with our exclusive 5 Jyotirlinga cab package. This package includes round trips from Shirdi to five sacred Jyotirlinga shrines, providing an unparalleled religious experience for devotees. Our well-equipped cabs and experienced drivers make this journey smooth and comfortable, allowing you to focus on your spiritual quest while we handle the logistics and transportation."
    },
    {
        "name": "Shirdi to Supa MIDC Round Trip Cab Package",
        "description": "For business or leisure travelers, our Shirdi to Supa MIDC round trip cab package provides reliable, convenient transportation. With professionally trained drivers and well-maintained vehicles, we ensure that your journey is smooth, efficient, and tailored to your schedule. This service is ideal for professionals traveling to Supa MIDC or for those visiting nearby areas for leisure, ensuring a comfortable travel experience."
    },
    {
        "name": "SaitirthaTours & Travels Shirdi",
        "description": "At SaitirthaTours & Travels, we are dedicated to delivering exceptional travel services in and around Shirdi. With a diverse range of transportation options and a commitment to customer satisfaction, we provide reliable, comfortable, and safe travel solutions. Our team is here to assist you with all your travel needs, whether for local sightseeing, round trips, or custom tour packages."
    },
    {
        "name": "Shirdi Round Trip Cab Booking Contact Information",
        "description": "For bookings, quotes, or inquiries regarding our Shirdi round trip cab services, reach out to SaitirthaTours & Travels at +91 9923879009 / +91 9922479009. We look forward to making your travel experience enjoyable, safe, and memorable with our dedicated services."
    }
],

tableData: [
    ["- Shirdi to Shani Shingnapur Round Trip Taxi Fare", "- Shirdi Round Trip Cab"],
    ["- Shirdi Tour Package from Mumbai 1 Day", "- Pune to Shirdi Cabs Round Trip"],
    ["- Shirdi to Nashik Round Trip Cabs", "- Shirdi to Ajanta Ellora Round Trip Cabs"],
    ["- Shirdi to Ahmednagar Round Trip Cabs", "- Shirdi to Shani Shingnapur Round Trip Taxi Fare"],
    ["- Shirdi to Bhimashankar Round Trip Cab Price", "- Shirdi to Trimbakeshwar Round Trip Cab"],
    ["- Shirdi to 5 Jyotirlinga Cab Package", "- Shirdi to Supa MIDC Round Trip Cab Package"],
    ["", ""]
  ],
  
  whychoose: [
    {
      "WhyChooseheading": "Convenient Round Trip Packages",
      "WhyChoosedescription": "We provide specially designed round trip packages that cover popular destinations such as Shirdi, Shani Shingnapur, Nashik, and more. These packages ensure that your travel is comfortable, efficient, and completely hassle-free from start to finish."
    },
    {
      "WhyChooseheading": "Flexible Itineraries",
      "WhyChoosedescription": "Our round trip itineraries are fully customizable based on your preferences. You can adjust travel times, select additional stops, and tailor the entire experience to suit your needs. Whether you're visiting one destination or multiple, we ensure your trip fits your schedule."
    },
    {
      "WhyChooseheading": "Comfortable and Spacious Vehicles",
      "WhyChoosedescription": "We offer a wide selection of well-maintained, comfortable vehicles for your round trip. From spacious SUVs to luxury cars, our fleet ensures ample space and comfort for all passengers, so you can relax and enjoy your journey."
    },
    {
      "WhyChooseheading": "Experienced and Courteous Drivers",
      "WhyChoosedescription": "Our drivers are professional, experienced, and friendly, ensuring that your trip is smooth and enjoyable. They are familiar with local routes and attractions, helping you navigate through destinations with ease and providing valuable tips along the way."
    },
    {
      "WhyChooseheading": "Affordable Pricing",
      "WhyChoosedescription": "We offer competitive and transparent pricing for all our round trip packages. Our rates are designed to give you the best value for your money without any hidden costs, making us an affordable and reliable option for your travels."
    },
    {
      "WhyChooseheading": "24/7 Customer Support",
      "WhyChoosedescription": "Our customer support team is available around the clock to assist with any inquiries, bookings, or changes you may need. Whether you need help planning your itinerary or have last-minute adjustments, we’re here to provide prompt and reliable assistance."
    },
    {
      "WhyChooseheading": "Safety and Reliability",
      "WhyChoosedescription": "Your safety is our top priority. All our vehicles are regularly serviced and thoroughly checked to meet the highest safety standards. With us, you can travel confidently knowing that our vehicles and drivers are reliable and well-maintained."
    },
    {
      "WhyChooseheading": "On-Time Pickups and Drop-offs",
      "WhyChoosedescription": "We understand the importance of time. That’s why we guarantee on-time pickups and drop-offs, ensuring that you are never late for your destination or appointments. Our punctuality ensures that your travel experience remains stress-free and well-organized."
    },
    {
      "WhyChooseheading": "Easy Booking Process",
      "WhyChoosedescription": "Booking a round trip with us is quick and easy. Simply use our website to reserve your vehicle or contact our customer service team for personalized assistance. We make the booking process seamless, allowing you to focus on your travel plans."
    },
    {
      "WhyChooseheading": "Local Insights and Recommendations",
      "WhyChoosedescription": "Our drivers are locals who know the area well. They can share valuable recommendations for nearby attractions, restaurants, and things to do, allowing you to make the most of your trip. Their local knowledge adds a personal touch to your journey."
    }
  ]
  




        
  }

  const faqData = [
    {
        question: "How do I book a round trip cab from Shirdi?",
        answer: "You can book a round trip cab through our website or by calling our customer service for assistance."
    },
    {
        question: "What destinations can I visit on a round trip?",
        answer: "You can choose any destination you wish to visit during your round trip, including popular nearby attractions."
    },
    {
        question: "Are there any additional charges for round trip bookings?",
        answer: "The pricing is transparent, and any additional charges will be discussed during the booking process."
    },
    {
        question: "Can I change my itinerary after booking?",
        answer: "Yes, you can modify your itinerary after booking. Please contact us for assistance with changes."
    },
    {
        question: "What types of vehicles are available for round trips?",
        answer: "We offer a range of vehicles including sedans, SUVs, and vans to accommodate different group sizes."
    },
    {
        question: "Is there a waiting time included in the round trip?",
        answer: "Yes, we include a reasonable waiting time for your convenience, especially during sightseeing."
    },
    {
        question: "What is your cancellation policy for round trip bookings?",
        answer: "We have a flexible cancellation policy. Please refer to our terms or contact us for more information."
    },
    {
        question: "Are the drivers trained in safety protocols?",
        answer: "Yes, all our drivers are trained in safety protocols and follow all regulations for a secure travel experience."
    },
    {
        question: "Can I request specific pickup and drop-off locations?",
        answer: "Yes, you can specify your desired pickup and drop-off locations when booking."
    },
    {
        question: "What is your contact information?",
        answer: "For bookings and inquiries, you can reach us at:"
    }
];

const testimonials = [
    {
        name: "Mr. Vikram Kulkarni",
        text: "I had a wonderful experience with Saitirtha Travels for my round trip cab booking to Shirdi. The entire process was seamless, from booking to the return journey. The driver was punctual, friendly, and knowledgeable about the area, making our trip enjoyable. I highly recommend their services for anyone planning a round trip!"
    },
    {
        name: "Ms. Anjali Deshmukh",
        text: "Saitirtha Travels provided excellent service for my round trip cab booking to Shirdi. The vehicle was comfortable and well-maintained, making the journey relaxing. The staff was professional and attentive, ensuring everything went smoothly. I will definitely use Saitirtha Travels for my future trips!"
    }
];





  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels |Shirdi Round Trip Cab Booking | Affordable & Reliable Round Trip Cabs to Major Destinations</title>
    <meta name="description" content="Book affordable round trip cabs from Shirdi to popular destinations like Shani Shingnapur, Nashik, Ajanta Ellora, Bhimashankar, Trimbakeshwar, and more. Enjoy a comfortable and reliable taxi service for your round trip journey." />
    <meta name="keywords" content="Shirdi to Shani Shingnapur Round Trip Taxi Fare, Shirdi Round Trip Cab, Shirdi Tour Package from Mumbai 1 Day, Pune to Shirdi Cabs Round Trip, Shirdi to Nashik Round Trip Cabs, Shirdi to Ajanta Ellora Round Trip Cabs, Shirdi to Ahmednagar Round Trip Cabs, Shirdi to Shani Shingnapur Round Trip Taxi Fare, Shirdi to Bhimashankar Round Trip Cab Price, Shirdi to Trimbakeshwar Round Trip Cab, Shirdi to 5 Jyotirlinga Cab Package, Shirdi to Supa MIDC Round Trip Cab Package, SaitirthaTours & Travels Shirdi" />
    <meta property="og:title" content="Contact: +91 9923879009 | Shirdi Round Trip Cab Booking | Affordable & Reliable Round Trip Cabs to Major Destinations" />
    <meta property="og:description" content="Book affordable round trip cabs from Shirdi to popular destinations like Shani Shingnapur, Nashik, Ajanta Ellora, Bhimashankar, Trimbakeshwar, and more. Enjoy a comfortable and reliable taxi service for your round trip journey." />
    <meta property="og:url" content="https://saitirthatravels.com/shirdi-round-trip-cab-booking" />
    <meta property="og:image" content="https://saitirthatravels.com/images/shirdi-round-trip-cab.jpg" />
    <meta name="twitter:card" content="summary_large_image" />
    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Sai Tirth Cabs Shirdi",
            "description": "Book affordable round trip cabs from Shirdi to popular destinations like Shani Shingnapur, Nashik, Ajanta Ellora, Bhimashankar, Trimbakeshwar, and more. Enjoy a comfortable and reliable taxi service for your round trip journey.",
            "url": "https://saitirthatravels.com/shirdi-round-trip-cab-booking",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/shirdi-round-trip-cab.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "250"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi to Shani Shingnapur Round Trip Taxi Fare",
                "Shirdi Round Trip Cab",
                "Shirdi Tour Package from Mumbai 1 Day",
                "Pune to Shirdi Cabs Round Trip",
                "Shirdi to Nashik Round Trip Cabs",
                "Shirdi to Ajanta Ellora Round Trip Cabs",
                "Shirdi to Ahmednagar Round Trip Cabs",
                "Shirdi to Shani Shingnapur Round Trip Taxi Fare",
                "Shirdi to Bhimashankar Round Trip Cab Price",
                "Shirdi to Trimbakeshwar Round Trip Cab",
                "Shirdi to 5 Jyotirlinga Cab Package",
                "Shirdi to Supa MIDC Round Trip Cab Package",
                "SaitirthaTours & Travels Shirdi"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/Keyword 5-03.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirdiroundtripcab;