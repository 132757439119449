import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import { FaMobileAlt, FaEnvelope, FaFacebookF, FaTwitter, FaLinkedin, FaInstagram, FaSearch } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Header from './component/Header';
import HeroSection from './component/HeroSection';
import AboutSection from './pages/AboutSection';
import ServiceSection from './pages/Service';
import OurFleet from './pages/OurFleet';
import OurNetwork from './pages/OurNetwork';
import Booking from './pages/Booking';
import Gallery from './pages/Gallery';
import ContactDetails from './pages/ContactDetails';
import Enquiry from './pages/Enquiry';
import CareersSection from './pages/CareersSection';
import BlogSection from './pages/BlogSection';
import TermsConditions from './pages/TermsConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Foot from './component/Footer';
import BestCabserviceinshirdi from './pages/Best cab service in Shirdi';
import BusRatesTable from './pages/BusRatesTable';
import Packages from './pages/Packages';
import Shirdicarrental from './pages/Shirdi Car Rental';
import Shirdisightseeingcab from './pages/Shirdi Sightseeing Cab';
import Shirditomanmad from './pages/Shirdi to Manmad Cab';
import Shirditomumbai from './pages/Shirdi to Mumbai Cabs';
import Shirditoaurangabad from './pages/Shirdi to Aurangabad (Sambhaji Nagar) Taxi Fare';
import Shirditobhimashankar from './pages/Shirdi to Bhimashankar Cab';
import Shirdidarshantaxi from './pages/Shirdi Darshan Taxi';
import Shirditopune from './pages/Shirdi to Pune Cab';
import Shriditoahmed from './pages/Shirdi to Ahmednagar Cab';
import Shirdiairport from './pages/Shirdi Airport to Shirdi Temple Taxi Fare';
import Shirditonashik from './pages/Shirdi to Nasik Taxi Fare';
import Shirditotrimkeshwer from './pages/Shirdi to Trimbakeshwar Cab Fare';
import Shirdisaibaba from './pages/Shirdi Saibaba Taxi Booking Online';
import Airporttaxishirdi from './pages/Airport taxi Shirdi';
import Kopargaontoshirdi from './pages/Kopargaon to Shirdi Taxi';
import Shirditoshani from './pages/Shirdi to Shani Shingnapur Cab';
import Shirditoajanta from './pages/Shirdi to Ajanta Ellora Taxi Fare';
import Shirditogrishneshwer from './pages/Shirdi to Grishneshwar Cab';
import Shirditolonavala from './pages/Shirdi to Lonavala Taxi';
import Shirditomahabaleshwar from './pages/Shirdi to Mahabaleshwar Cab';
import Shirditomatheran from './pages/Shirdi to Matheran Taxi';
import Shirditokohlapur from './pages/Shirdi to Kolhapur Taxi';
import Shirditokonkan from './pages/Shirdi to Konkan Darshan Cab';
import Cabsforeventinshirdi from './pages/Cabs for Events in Shirdi';
import Monthlycabsservice from './pages/Monthly Cab Service Shirdi';
import Corporatcabservice from './pages/Corporate Cab Services in Shirdi';
import Kalyantoshirdi from './pages/Kalyan to Shirdi Car Rental';
import Shirditopandharpur from './pages/Shirdi to Pandharpur Cab';
import Shirditoashtavinayak from './pages/Shirdi to Ashtavinayak Darshan Cab';
import Shirditopuneairport from './pages/Shirdi to Pune Airport Cab';
import Shirditoverulcarrental from './pages/Shirdi to Verul Car Rental';
import Tempotraveller from './pages/Tempo Traveller 17 seater on rent Shirdi';
import Toyotaetion from './pages/Toyota Etios On Rent in Shirdi';
import Innovaonrent from './pages/Innova on Rent in Shirdi';
import Minibusonrentinshirdi from './pages/Mini bus on Rent in Shirdi';
import Shirditosurat from './pages/Shirdi to Surat Cab';
import Shirditoshegaon from './pages/Shirdi to Shegaon Cab';
import Shirditobhandardara from './pages/Shirdi to Bhandardara Taxi';
import Shirditomumbaiairport from './pages/Shirdi to Mumbai Airport Cab';
import Shirditoujjjaincab from './pages/Shirdi to Ujjain Cab';
import Onewaytaxi from './pages/One Way Taxi Shirdi';
import Aurangabadairport from './pages/Aurangabad Airport to Shirdi Cab';
import Nashikairport from './pages/Nasik Airport to Shirdi Taxi';
import Seaterbus from './pages/14 17 25 32 Seater Bus On Rent in Shirdi';
import Shirditojyotilinga from './pages/Shirdi to 5 Jyotirlinga Package';
import Shirditourpackage from './pages/Shirdi Tour Package';
import Rentalcarweddingshirdi from './pages/Rental Car for Wedding  Shirdi';
import Shirditooutstation from './pages/Shirdi to Outstation Cabs';
import Shirditoajantaellora from './pages/Shirdi to Ajanta Ellora Caves Package';
import Shirditomumbaidarshan from './pages/Shirdi to Mumbai Darshan Package';
import Shirditonagpur from './pages/Shirdi to Nagpur Cab';
import Shirditomaharashtra from './pages/Shirdi to Maharashtra Darshan Package';
import Shirditoooty from './pages/Shirdi to Ooty Mysore Cab Package';
import Shirditoujjaincabbooking from './pages/Shirdi to Ujjain Cab Booking';
import Shirditogujrat from './pages/Shirdi to Gujarat Cab';
import Sainagarshirdi from './pages/Sainagar Shirdi to Shirdi Cabs';
import Swiftdezire from './pages/Swift Dzire On Rent in Shirdi';
import Innovacrista from './pages/Innova Crysta Cab on Rent Shirdi';
import Ertigaonrentinshirdi from './pages/Ertiga On Rent in Shirdi';
import Kiacarens from './pages/Kia Carens On Rent in Shirdi';
import Tempotravelleronrent from './pages/Tempo Traveller 14 seater rent in Shirdi';
import Seaterbuses from './pages/50 Seater bus on rent in Shirdi';
import Shirditoswaptashrungi from './pages/Shirdi to Saptashrungi Taxi Fare';
import Shirditoallindia from './pages/Shirdi to All India Explorer Cab Service';
import Punetoshirdicab from './pages/Pune to Shirdi Cab';
import Punetoairport from './pages/Pune Airport to shirdi Taxi';
import Punetoshani from './pages/Pune to Shani Shingnapur Cab';
import Mumbaitoshirdicab from './pages/Mumbai to Shirdi Cab';
import Mumbaiairporttoshirdi from './pages/Mumbai Airport to Shirdi Cab';
import Shirditoindore from './pages/Shirdi to Indore Darshan Cab';
import Shirdiairportcab from './pages/Shirdi Airport Cabs';
import Shirdioutstatiponcabs from './pages/Shirdi Outstation Cabs';
import Shirdionewaycabservice from './pages/Shirdi One Way Cab Service';
import Shirditaxibooking from './pages/Shirdi Taxi Booking Online ';
import Shirdicabs from './pages/Shirdi Cabs';
import Shirdiairportpickup from './pages/Shirdi Airport Pick up & Drop Service';
import Forceurbaniaonrentinshirdi from './pages/Force Urbania On Rent in Shirdi';
import Seaterbusss from './pages/7 Seater Car On Rent in Shirdi';
import Shirditoallmaharashtra from './pages/Shirdi to all Maharashtra Cab Service';
import Shirdiroundtripcab from './pages/Shirdi Round Trip Cab Booking';
import Shirditohyderabad from './pages/Shirdi to Hyderabad Cab';
import ContactButtons from './component/ContactButtons';
import ScrollToTop from './component/ScrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop/>
  <ContactButtons/>
  <Header/>
      <Routes>
        
        <Route path="/" element={<HeroSection/>} />
        <Route path="/about" element={<AboutSection/>} />
        <Route path="/service" element={<ServiceSection/>} />
        <Route path="/our-fleets" element={<OurFleet/>} />
        <Route path="/our-network" element={<OurNetwork/>} />
        <Route path="/booking" element={<Booking/>} />
        <Route path="/gallery" element={<Gallery/>} />
        <Route path="/contact" element={<ContactDetails/>} />
        <Route path="/enquiry" element={<Enquiry/>} />
        <Route path="/career" element={<CareersSection/>} />
        <Route path="/blog" element={<BlogSection/>} />
        <Route path="/term-condition" element={<TermsConditions/>} />
        <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
        <Route path="/package" element={<Packages/>} />
        <Route path="/Best-cab-service-in-Shirdi" element={<BestCabserviceinshirdi/>} />
        <Route path="/Shirdi-Car-Rental" element={<Shirdicarrental/>} />
        <Route path="/Shirdi-Sightseeing-Cab" element={<Shirdisightseeingcab/>} />
        <Route path="/Shirdi-to-Manmad-Cab" element={<Shirditomanmad/>} />
        <Route path="/Shirdi-to-Mumbai-Cabs" element={<Shirditomumbai/>} />
        <Route path="/Shirdi-to-Aurangabad-Taxi-Fare" element={<Shirditoaurangabad/>} />
        <Route path="/Shirdi-to-Bhimashankar-Cab" element={<Shirditobhimashankar/>} />
        <Route path="/Shirdi-Darshan-Taxi" element={<Shirdidarshantaxi/>} />
        <Route path="/Shirdi-to-Pune-Cab" element={<Shirditopune/>} />
        <Route path="/Shirdi-to-Ahmednagar-Cab" element={<Shriditoahmed/>} />
        <Route path="/Shirdi-Airport-to-Shirdi-Temple-Taxi-Fare" element={<Shirdiairport/>} />
        <Route path="/Shirdi-to-Nasik-Taxi-Fare" element={<Shirditonashik/>} />
        <Route path="/Shirdi-to-Trimbakeshwar-Cab-Fare" element={<Shirditotrimkeshwer/>} />
        <Route path="/Shirdi-Saibaba-Taxi-Booking-Online" element={<Shirdisaibaba/>} />
        <Route path="/Airport-taxi-Shirdi" element={<Airporttaxishirdi/>} />
        <Route path="/Kopargaon-to-Shirdi-Taxi" element={<Kopargaontoshirdi/>} />
        <Route path="/Shirdi-to-Shani-Shingnapur-Cab" element={<Shirditoshani/>} />
        <Route path="/Shirdi-to-Ajanta-Ellora-Taxi-Fare" element={<Shirditoajanta/>} />
        <Route path="/Shirdi-to-Grishneshwar-Cab" element={<Shirditogrishneshwer/>} />
        <Route path="/Shirdi-to-Lonavala-Taxi" element={<Shirditolonavala/>} />
        <Route path="/Shirdi-to-Mahabaleshwar-Cab" element={<Shirditomahabaleshwar/>} />
        <Route path="/Shirdi-to-Matheran-Taxi" element={<Shirditomatheran/>} />
        <Route path="/Shirdi-to-Kolhapur-Taxi" element={<Shirditokohlapur/>} />
        <Route path="/Shirdi-to-Konkan-Darshan-Cab" element={<Shirditokonkan/>} />
        <Route path="/Cabs-for-Events-in-Shirdi" element={<Cabsforeventinshirdi/>} />
        <Route path="/Monthly-Cab-Service-Shirdi" element={<Monthlycabsservice/>} />
        <Route path="/Corporate-Cab-Services-in-Shirdi" element={<Corporatcabservice/>} />
        <Route path="/Kalyan-to-Shirdi-Car-Rental" element={<Kalyantoshirdi/>} />
        <Route path="/Shirdi-to-Pandharpur-Cab" element={<Shirditopandharpur/>} />
        <Route path="/Shirdi-to-Ashtavinayak-Darshan-Cab" element={<Shirditoashtavinayak/>} />
        <Route path="/Shirdi-to-Pune-Airport-Cab" element={<Shirditopuneairport/>} />
        <Route path="/Shirdi-to-Verul-Car-Rental" element={<Shirditoverulcarrental/>} />
        <Route path="/Tempo-Traveller-17-seater-on-rent-Shirdi" element={<Tempotraveller/>} />
        <Route path="/Toyota-Etios-On-Rent-in-Shirdi" element={<Toyotaetion/>} />
        <Route path="/Innova-on-Rent-in-Shirdi" element={<Innovaonrent/>} />
        <Route path="/Mini-bus-on-Rent-in-Shirdi" element={<Minibusonrentinshirdi/>} />
        <Route path="/Shirdi-to-Surat-Cab" element={<Shirditosurat/>} />
        <Route path="/Shirdi-to-Shegaon-Cab" element={<Shirditoshegaon/>} />
        <Route path="/Shirdi-to-Bhandardara-Taxi" element={<Shirditobhandardara/>} />
        <Route path="/Shirdi-to-Mumbai-Airport-Cab" element={<Shirditomumbaiairport/>} />
        <Route path="/Shirdi-to-Ujjain-Cab" element={<Shirditoujjjaincab/>} />
        <Route path="/One-Way-Taxi-Shirdi" element={<Onewaytaxi/>} />
        <Route path="/Aurangabad-Airport-to-Shirdi-Cab" element={<Aurangabadairport/>} />
        <Route path="/Nasik-Airport-to-Shirdi-Taxi" element={<Nashikairport/>} />
        <Route path="/14-17-25-32-Seater-Bus-On-Rent-in-Shirdi" element={<Seaterbus/>} />
        <Route path="/Shirdi-to-5-Jyotirlinga-Package" element={<Shirditojyotilinga/>} />
        <Route path="/Shirdi-Tour-Package" element={<Shirditourpackage/>} />
        <Route path="/Rental-Car-for-Wedding-Shirdi" element={<Rentalcarweddingshirdi/>} />
        <Route path="/Shirdi-to-Outstation-Cabs" element={<Shirditooutstation/>} />
        <Route path="/Shirdi-to-Ajanta-Ellora-Caves-Package" element={<Shirditoajantaellora/>} />
        <Route path="/Shirdi-to-Mumbai-Darshan-Package" element={<Shirditomumbaidarshan/>} />
        <Route path="/Shirdi-to-Nagpur-Cab" element={<Shirditonagpur/>} />
        <Route path="/Shirdi-to-Maharashtra-Darshan-Package" element={<Shirditomaharashtra/>} />
        <Route path="/Shirdi-to-Ooty-Mysore-Cab-Package" element={<Shirditoooty/>} />
        <Route path="/Shirdi-to-Ujjain-Cab-Booking" element={<Shirditoujjaincabbooking/>} />
        <Route path="/Shirdi-to-Gujarat-Cab" element={<Shirditogujrat/>} />
        <Route path="/Sainagar-Shirdi-to-Shirdi-Cabs" element={<Sainagarshirdi/>} />
        <Route path="/Swift-Dzire-On-Rent-in-Shirdi" element={<Swiftdezire/>} />
        <Route path="/Innova-Crysta-Cab-on-Rent-Shirdi" element={<Innovacrista/>} />
        <Route path="/Ertiga-On-Rent-in-Shirdi" element={<Ertigaonrentinshirdi/>} />
        <Route path="/Kia-Carens-On-Rent-in-Shirdi" element={<Kiacarens/>} />
        <Route path="/Tempo-Traveller-14-seater-rent-in-Shirdi" element={<Tempotravelleronrent/>} />
        <Route path="/50-Seater-bus-on-rent-in-Shirdi" element={<Seaterbuses/>} />
        <Route path="/Shirdi-to-Saptashrungi-Taxi-Fare" element={<Shirditoswaptashrungi/>} />
        <Route path="/Shirdi-to-All-India-Explorer-Cab-Service" element={<Shirditoallindia/>} />
        <Route path="/Pune-to-Shirdi-Cab" element={<Punetoshirdicab/>} />
        <Route path="/Pune-Airport-to-shirdi-Taxi" element={<Punetoairport/>} />
        <Route path="/Pune-to-Shani-Shingnapur-Cab" element={<Punetoshani/>} />
        <Route path="/Mumbai-to-Shirdi-Cab" element={<Mumbaitoshirdicab/>} />
        <Route path="/Mumbai-Airport-to-Shirdi-Cab" element={<Mumbaiairporttoshirdi/>} />
        <Route path="/Shirdi-to-Indore-Darshan-Cab" element={<Shirditoindore/>} />
        <Route path="/Shirdi-Airport-Cabs" element={<Shirdiairportcab/>} />
        <Route path="/Shirdi-Outstation-Cabs" element={<Shirdioutstatiponcabs/>} />
        <Route path="/Shirdi-One-Way-Cab-Service" element={<Shirdionewaycabservice/>} />
        <Route path="/Shirdi-Taxi-Booking-Online" element={<Shirditaxibooking/>} />
        <Route path="/Shirdi-Cabs" element={<Shirdicabs/>} />
        <Route path="/Shirdi-Airport-Pick-up-&-Drop-Service" element={<Shirdiairportpickup/>} />
        <Route path="/Force-Urbania-On-Rent-in-Shirdi" element={<Forceurbaniaonrentinshirdi/>} />
        <Route path="/7-Seater-Car-On-Rent-in-Shirdi" element={<Seaterbusss/>} />
        <Route path="/Shirdi-to-all-Maharashtra-Cab-Service" element={<Shirditoallmaharashtra/>} />
        <Route path="/Shirdi-Round-Trip-Cab-Booking" element={<Shirdiroundtripcab/>} />
        <Route path="/Shirdi-to-Hyderabad-Cab" element={<Shirditohyderabad/>} />
        
        </Routes>
    <Foot/>
    </Router>
 

  );
}

export default App;
