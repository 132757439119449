
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Shirditaxibooking() {



  const cardData =
  {
    keyword: ' Shirdi Taxi Booking Online',
    heading: 'Saitirtha Travels: Shirdi Taxi Booking Online',
    headingDescription: 'Booking a taxi online from Shirdi has never been easier with Saitirtha Travels. Our user-friendly platform allows you to reserve a reliable taxi quickly, ensuring that your travel plans are seamless and convenient. Whether you need a ride to a nearby temple, an outstation trip, or airport transfers, our online booking service caters to all your transportation needs.',

    top: 'Top Places to Visit from Shirdi with Saitirtha Travels',

    topPlaces: [
        {
            "title": "Sai Baba Temple",
            "description": "The heart of Shirdi, the Sai Baba Temple is a spiritual haven that attracts millions of devotees from around the world. Renowned for its serene atmosphere, the temple features a grand marble idol of Sai Baba and offers a space for prayer and reflection. The daily aarti (ritual of worship) and bhajans create a deeply spiritual experience, making it a must-visit for seekers and pilgrims wishing to pay homage to this beloved saint."
        },
        {
            "title": "Shirdi Village",
            "description": "Exploring the village of Shirdi provides a profound insight into the life of Sai Baba and the traditions surrounding his legacy. Visitors can walk through the streets he frequented, visit various spots associated with his life, and experience the local culture. The village atmosphere enhances the spiritual journey, offering a deeper understanding of the teachings and impact of Sai Baba on his followers."
        },
        {
            "title": "Khandoba Mandir",
            "description": "Located just 2 kilometers from the Sai Baba Temple, Khandoba Mandir is dedicated to Lord Khandoba, a significant deity in Maharashtra. This temple is a popular pilgrimage site, known for its stunning architecture and beautiful views of the surrounding hills. Visitors can partake in rituals and enjoy the peaceful environment, making it a delightful spot for spiritual reflection."
        },
        {
            "title": "Lendi Baug",
            "description": "A tranquil garden located near the Sai Baba Temple, Lendi Baug is where Sai Baba spent a considerable amount of time meditating and resting. The garden features a beautiful well, lush greenery, and ancient trees, offering a peaceful ambiance perfect for relaxation and introspection. Visitors often find solace here, enjoying the natural beauty and spiritual energy of the surroundings."
        },
        {
            "title": "Gurusthan",
            "description": "Gurusthan is an important pilgrimage site believed to be where Sai Baba first appeared as a young boy. This serene location features a small shrine and a sacred tree, attracting devotees and visitors seeking blessings. The atmosphere here is imbued with spirituality, making it a poignant place for those wanting to connect with Sai Baba's early life and teachings."
        },
        {
            "title": "Dixit Wada Museum",
            "description": "The Dixit Wada Museum is a historical site dedicated to showcasing the life and teachings of Sai Baba. Through various artifacts, photographs, and displays, visitors can learn about his legacy and the impact he had on countless lives. This museum offers a deeper understanding of Sai Baba's philosophies and his connection to the people of Shirdi."
        },
        {
            "title": "Shri Saibaba Sansthan Trust",
            "description": "The Shri Saibaba Sansthan Trust manages the operations of the Sai Baba Temple and various community services in Shirdi. Visitors can learn about the trust's initiatives, including educational programs and healthcare services, and understand its significant impact on the local community. Engaging with the trust provides insight into how Sai Baba's teachings continue to inspire philanthropy and service."
        },
        {
            "title": "Sainagar Shirdi Railway Station",
            "description": "Sainagar Shirdi Railway Station is the nearest railway station to Shirdi, serving as a vital link for travelers. Conveniently located, the station offers easy access to taxi services, making it straightforward for visitors to reach their accommodations or the Sai Baba Temple. Its proximity to Shirdi ensures a smooth travel experience for pilgrims and tourists alike."
        },
        {
            "title": "Shirdi Airport",
            "description": "Located about 15 kilometers from Shirdi, Shirdi Airport is the nearest airport and an ideal entry point for travelers preferring to fly. The airport offers convenient transport options, including taxis that can easily be booked to take you to your destination in Shirdi. Its accessibility makes it a popular choice for pilgrims visiting the Sai Baba Temple."
        },
        {
            "title": "Nearby Villages",
            "description": "Exploring the nearby villages offers a unique opportunity to experience local culture and traditions. Visitors can take a taxi to these villages and interact with the residents, gaining insights into their traditional lifestyles, customs, and daily practices. This immersive experience enriches the spiritual journey and enhances understanding of the region's heritage."
        }
    ],  
    
            
        
"services": [
    {
        "name": "Shirdi Taxi Booking Online",
        "description": "Experience hassle-free transportation with our Shirdi taxi booking online service, designed for your convenience and comfort. Our platform allows you to easily book a cab at any time, ensuring you can secure your transportation needs without any stress. With a few simple steps, you can customize your travel plans according to your schedule and preferences, allowing for a seamless travel experience tailored just for you."
    },
    {
        "name": "Online Cab Booking Shirdi",
        "description": "Booking a cab in Shirdi has never been easier. Our online platform provides a quick and efficient way to reserve your taxi, whether you need a ride for airport transfers, local sightseeing, or outstation travel. Simply input your travel details, choose from a range of vehicles, and confirm your booking. This service saves you time and ensures that you can focus on enjoying your trip while we take care of the logistics."
    },
    {
        "name": "Online Taxi Booking Shirdi",
        "description": "Enjoy a user-friendly interface for your online taxi booking in Shirdi. We offer a variety of vehicles to match your preferences, including sedans, SUVs, and luxury cars, ensuring that you have the right option for your travel needs. Our drivers are not only professional but also well-acquainted with the local routes, allowing for a smooth and enjoyable ride. You can rest assured that your comfort and safety are our top priorities."
    },
    {
        "name": "Shirdi Taxi Services",
        "description": "We take pride in providing reliable Shirdi taxi services that cater to all your transportation needs. Whether you're visiting the renowned Sai Baba Temple, exploring local attractions, or traveling to nearby destinations, our fleet is ready to serve you. Our cabs are maintained to the highest safety standards and equipped with modern amenities for your comfort. With our commitment to punctuality and professionalism, we guarantee that your travel experience will be both enjoyable and stress-free."
    },
    {
        "name": "SaitirthaTours & Travels Shirdi",
        "description": "Trust SaitirthaTours & Travels for all your taxi booking needs in Shirdi. Our dedication to customer satisfaction is unmatched, and we work tirelessly to make your travel experience as pleasant as possible. Our team is comprised of knowledgeable staff who are ready to assist you with any inquiries or special requests, ensuring a personalized service that meets your specific travel needs."
    },
    {
        "name": "Shirdi Cab Packages",
        "description": "Explore our diverse range of Shirdi cab packages designed to meet the needs of every traveler. Whether you're planning a short visit or a longer stay, we offer flexible options that include local tours, airport pickups, and special trips to nearby attractions. Each package is crafted to enhance your experience, providing you with opportunities to explore the best of Shirdi and its surroundings while enjoying exceptional service at competitive rates."
    },
    {
        "name": "Shirdi Taxi Booking Online",
        "description": "With our dedicated online taxi booking platform, you can reserve your Shirdi taxi anytime and from anywhere. Enjoy the freedom of choosing your preferred vehicle and receive instant confirmation of your booking. Our commitment to providing an efficient booking process is matched by our dedication to ensuring that your transportation needs are met with the utmost care and professionalism."
    },
    {
        "name": "Shani Shingnapur Online Cab Booking",
        "description": "Planning a pilgrimage to Shani Shingnapur? Utilize our online cab booking service to secure your ride effortlessly. We offer reliable transportation to this revered site, ensuring a comfortable and safe journey. Our experienced drivers are knowledgeable about the area, ready to provide assistance and insights during your trip, making your pilgrimage both convenient and spiritually fulfilling."
    },
    {
        "name": "Swift Dzire on Rent in Shirdi Online Booking",
        "description": "Need a compact car for your travels? Book a Swift Dzire online for your journey in Shirdi. This fuel-efficient vehicle is ideal for local trips, offering both comfort and convenience. With its compact size, it is perfect for navigating through busy streets while providing a pleasant driving experience, ensuring that your travels are both enjoyable and economical."
    },
    {
        "name": "Shirdi Airport Online Booking Cab Service",
        "description": "Arriving at Shirdi Airport? Pre-book your cab online for a hassle-free transfer to your hotel or any other destination in Shirdi. Our drivers will be waiting for you upon arrival, equipped to assist with your luggage and ensure a smooth transition from the airport to your desired location. We prioritize punctuality and comfort, so you can start your journey on a positive note."
    },
    {
        "name": "Nagarsol Online Booking Cab Service",
        "description": "If you're traveling to or from Nagarsol, our online booking service simplifies arranging a cab. We ensure prompt pickups and comfortable rides to your desired destination. Our experienced drivers know the best routes, making your journey efficient and pleasant, allowing you to relax and enjoy the ride without any worries."
    },
    {
        "name": "Manmad to Shirdi Online Booking Cab",
        "description": "Traveling from Manmad to Shirdi? Use our online booking platform to reserve your cab with ease. Enjoy a smooth ride with our professional drivers who are familiar with the best routes to ensure you arrive at your destination comfortably and on time. Our commitment to excellent service means you can sit back, relax, and enjoy the journey."
    },
    {
        "name": "Manmad to Shirdi Innova Cab Online Booking",
        "description": "For larger groups or families, our Innova cabs are an excellent choice. Book your Innova online for a spacious and comfortable travel experience from Manmad to Shirdi. Equipped with modern amenities, our Innova vehicles ensure that your group travels together in comfort, making the journey enjoyable for everyone involved."
    },
    {
        "name": "Shirdi to Manmad Online Booking Cab",
        "description": "Need a ride back from Shirdi to Manmad? Our online booking service allows you to easily schedule your cab. We provide timely pickups to ensure you reach your destination without delay. Our drivers are dedicated to providing a smooth and pleasant experience, focusing on your comfort and satisfaction throughout the journey."
    },
    {
        "name": "Shirdi to Manmad Taxi Online Booking",
        "description": "For all your travel requirements from Shirdi to Manmad, our taxi online booking service is here to assist you. We prioritize your comfort and punctuality, ensuring that your travel experience is seamless and enjoyable from start to finish. Our reliable service means you can trust us to get you where you need to go, hassle-free."
    },
    {
        "name": "Shirdi Taxi Booking Online Contact Information",
        "description": "For assistance with your Shirdi taxi booking online or to learn more about our services, feel free to reach out to SaitirthaTours & Travels at +91 9923879009 / +91 9922479009. Our team is available to help you plan your journey, provide additional information, and answer any questions you may have. We are committed to ensuring your travel experience is enjoyable and stress-free."
    }
],

tableData: [
    ["- Online Cab Booking Shirdi", "- Online Taxi Booking Shirdi"],
    ["- Shirdi Taxi Services", "- SaitirthaTours & Travels Shirdi"],
    ["- Shirdi Cab Packages", "- Shirdi Taxi Booking Online"],
    ["- Shirdi Taxi Booking Online", "- Shani Shingnapur Online Cab Booking"],
    ["- Swift Dzire on Rent in Shirdi Online Booking", "- Shirdi Airport Online Booking Cab Service"],
    ["- Nagarsol Online Booking Cab Service", "- Manmad to Shirdi Online Booking Cab"],
    ["- Manmad to Shirdi Innova Cab Online Booking", "- Shirdi to Manmad Online Booking Cab"],
    ["- Shirdi to Manmad Taxi Online Booking", ""]
  ],
  
  whychoose: [
    {
      "WhyChooseheading": "Convenient Online Booking",
      "WhyChoosedescription": "Our intuitive and user-friendly online booking system allows you to easily reserve your taxi from Shirdi in just a few simple steps. This time-saving feature eliminates the need for phone calls or in-person arrangements, offering convenience and flexibility when planning your journey."
    },
    {
      "WhyChooseheading": "Wide Range of Vehicles",
      "WhyChoosedescription": "Whether you're traveling solo, with family, or as part of a group, we offer a diverse range of vehicles to suit your needs. From compact sedans to larger SUVs and vans, we have the perfect ride for every occasion, ensuring that your travel experience is comfortable and hassle-free."
    },
    {
      "WhyChooseheading": "Transparent Pricing",
      "WhyChoosedescription": "We pride ourselves on offering clear and upfront pricing with no hidden charges. Our competitive rates ensure that you get excellent value for your money, whether you are booking a short ride or a long-distance trip. You will always know the total cost before confirming your booking."
    },
    {
      "WhyChooseheading": "Experienced Drivers",
      "WhyChoosedescription": "Our professional drivers are well-trained, experienced, and familiar with the routes around Shirdi and the surrounding areas. They ensure a smooth, safe, and pleasant journey for you. With their local knowledge and friendly service, your travel experience will be as enjoyable as it is reliable."
    },
    {
      "WhyChooseheading": "24/7 Availability",
      "WhyChoosedescription": "No matter the time of day or night, our taxi services are available 24/7 to cater to your travel needs. Whether you need a ride in the early morning or late at night, we ensure that you always have access to a dependable taxi service whenever you require it."
    },
    {
      "WhyChooseheading": "Real-Time Vehicle Tracking",
      "WhyChoosedescription": "Once your taxi is booked, you can track your vehicle in real-time using our online platform. This feature provides peace of mind by allowing you to monitor your ride's progress, making it easier for you to plan and track your travel seamlessly."
    },
    {
      "WhyChooseheading": "Easy Payment Options",
      "WhyChoosedescription": "We offer multiple payment options for your convenience, including cash, credit/debit cards, and mobile wallets. This variety ensures that paying for your ride is as easy and hassle-free as booking it, so you can focus on your journey instead of worrying about payment."
    },
    {
      "WhyChooseheading": "Customer Support",
      "WhyChoosedescription": "Our dedicated customer support team is always available to assist you with any questions or concerns you may have during the booking process. Whether you need help with your reservation or have special requests, we're here to provide timely and helpful assistance."
    },
    {
      "WhyChooseheading": "Clean and Comfortable Vehicles",
      "WhyChoosedescription": "We take cleanliness and passenger comfort seriously. Each of our vehicles is regularly cleaned and sanitized before every trip, ensuring that you travel in a hygienic and comfortable environment. Whether you’re on a short ride or a long journey, your comfort is our priority."
    },
    {
      "WhyChooseheading": "Positive Customer Reviews",
      "WhyChoosedescription": "We have built a reputation for providing reliable and excellent taxi service, as reflected in our positive customer reviews. Our clients appreciate the timely service, well-maintained vehicles, and professional drivers, making us the trusted choice for taxi services in Shirdi."
    }
  ]
  


        
  }


  const faqData = [
    {
        question: "How do I book a taxi online?",
        answer: "You can book a taxi online through our website by selecting your preferred vehicle, entering your travel details, and completing the payment process."
    },
    {
        question: "What payment methods do you accept?",
        answer: "We accept various payment methods, including cash, credit/debit cards, and mobile wallets for your convenience."
    },
    {
        question: "Can I modify my booking after it is confirmed?",
        answer: "Yes, you can modify your booking based on availability. Please contact us as soon as possible for any changes."
    },
    {
        question: "What types of vehicles are available for booking?",
        answer: "We offer a wide range of vehicles, including sedans, SUVs, and vans, to accommodate different group sizes and travel requirements."
    },
    {
        question: "Is there a cancellation policy?",
        answer: "Yes, we have a cancellation policy in place. Please refer to our website for detailed information about cancellation terms."
    },
    {
        question: "How can I contact customer support for assistance?",
        answer: "You can reach our customer support team via phone or email for any inquiries or assistance with your booking."
    },
    {
        question: "Are your taxis available for outstation trips?",
        answer: "Yes, we provide taxi services for both local and outstation trips from Shirdi to various destinations."
    },
    {
        question: "What if I need a taxi at an odd hour?",
        answer: "Our taxi services are available 24/7, so you can book a taxi at any hour that suits your travel plans."
    },
    {
        question: "Do you provide child seats in your taxis?",
        answer: "Yes, we can provide child seats upon request. Please inform us during the booking process."
    },
    {
        question: "What is your contact information for bookings?",
        answer: "For bookings and inquiries, you can contact us at the following:"
    }
];

const testimonials = [
    {
        name: "Mr. Harshit Verma",
        text: "I had a great experience with the online taxi booking service from Saitirtha Travels for my trip to Shirdi. The website was easy to navigate, and the booking process was seamless. The driver arrived on time, and the car was in excellent condition. I highly recommend Saitirtha Travels for hassle-free taxi bookings!"
    },
    {
        name: "Ms. Priyanka Mehta",
        text: "Booking a taxi online with Saitirtha Travels was a breeze! The service was prompt, and the vehicle was comfortable for my journey. The driver was courteous and professional, making my trip enjoyable. I will definitely choose Saitirtha Travels for my future taxi needs!"
    }
];




  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Shirdi Taxi Booking Online | Book Cabs with Saitirtha Travels | Affordable & Reliable Taxi Service</title>
    <meta name="description" content="Book Shirdi Taxi Services online with Saitirtha Travels. We offer easy and affordable online booking for Shirdi cabs, airport transfers, and outstation bookings like Manmad, Shani Shingnapur, and more." />
    <meta name="keywords" content="Shirdi Taxi Booking Online, Online Cab Booking Shirdi, Online Taxi Booking Shirdi, Shirdi Taxi Services, Shirdi Cab Packages, Shirdi Airport Online Booking Cab Service, Shani Shingnapur Online Cab Booking, Swift Dzire on Rent in Shirdi Online Booking, Shirdi to Manmad Online Booking Cab, Manmad to Shirdi Innova Cab Online Booking" />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirtha Travels | Shirdi Taxi Booking Online | Book Cabs with Saitirtha Travels | Affordable & Reliable Taxi Service" />
    <meta property="og:description" content="Book Shirdi Taxi Services online with Saitirtha Travels. We offer easy and affordable online booking for Shirdi cabs, airport transfers, and outstation bookings like Manmad, Shani Shingnapur, and more." />
    <meta property="og:url" content="https://saitirthatravels.com/shirdi-taxi-booking-online" />
    <meta property="og:image" content="https://saitirthatravels.com/images/shirdi-taxi-booking.jpg" />
    <meta name="twitter:card" content="summary_large_image" />
    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Book Shirdi Taxi Services online with Saitirtha Travels. We offer easy and affordable online booking for Shirdi cabs, airport transfers, and outstation bookings like Manmad, Shani Shingnapur, and more.",
            "url": "https://saitirthatravels.com/shirdi-taxi-booking-online",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/shirdi-taxi-booking.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "300"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi Taxi Booking Online",
                "Online Cab Booking Shirdi",
                "Online Taxi Booking Shirdi",
                "Shirdi Taxi Services",
                "Shirdi Cab Packages",
                "Shirdi Airport Online Booking Cab Service",
                "Shani Shingnapur Online Cab Booking",
                "Swift Dzire on Rent in Shirdi Online Booking",
                "Shirdi to Manmad Online Booking Cab",
                "Manmad to Shirdi Innova Cab Online Booking"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/Keyword 5-07.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirditaxibooking;