
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Tempotraveller() {



  const cardData =
  {
    keyword: ' Tempo Traveller 17 Seater on Rent in Shirdi',
    heading: 'Saitirtha Travels: Tempo Traveller 17 Seater on Rent in Shirdi',
    headingDescription: 'When planning a group trip, comfort and convenience are essential. Saitirtha Travels offers a spacious and reliable 17-seater Tempo Traveller for rent in Shirdi, making it the perfect choice for families, friends, or corporate outings. Our service is tailored to provide you with a comfortable journey as you explore the spiritual and cultural heritage of Shirdi and its surrounding areas.',

    top: 'Top Places to Visit in Shirdi with Saitirth',

  "topPlaces": [
    {
        "title": "Shri Sai Baba Samadhi Mandir",
        "description": "Shri Sai Baba Samadhi Mandir stands as the heart of Shirdi and is one of the most revered spiritual destinations in India. Dedicated to Sai Baba, a saint who transcended religious boundaries, this temple attracts millions of devotees each year who seek solace and spiritual guidance. The temple complex, with its exquisite architecture featuring intricately carved pillars and beautiful domes, creates a serene atmosphere that encourages reflection and devotion. Visitors can participate in the daily rituals, including the mesmerizing aarti ceremonies, which fill the air with devotional songs and chants, enhancing the spiritual ambiance. The shrine, housing Sai Baba's samadhi, exudes a profound sense of peace, making it a must-visit for anyone seeking spiritual fulfillment."
    },
    {
        "title": "Dwarkamai Masjid",
        "description": "Dwarkamai Masjid is not only a historical mosque but also a site of deep spiritual significance for Sai Baba's devotees. Once his humble abode, this mosque houses the sacred dhuni (eternal fire), which has been burning continuously for over a century, symbolizing the eternal light of wisdom and enlightenment. Visitors can witness the reverence shown by pilgrims who come to pay their respects and offer prayers, creating a deeply moving atmosphere. The walls of the mosque are adorned with mementos and photographs of Sai Baba, each telling a story of his life and teachings. The tranquil surroundings and the palpable devotion make it a sacred space for contemplation and connection with the divine."
    },
    {
        "title": "Chavadi",
        "description": "Chavadi is a site of great historical and spiritual importance, known as the place where Sai Baba would rest every alternate night. This simple structure is adorned with beautiful decorations made by devotees, showcasing their love and devotion. The interior is filled with offerings, including flowers and food, highlighting the vibrant spiritual practices of the visitors. The evening aarti held here is a highlight, drawing crowds who join in singing devotional songs. The Chavadi reflects the humility of Sai Baba's lifestyle, and visiting it provides a unique opportunity to connect with his legacy while experiencing the heartfelt devotion of his followers."
    },
    {
        "title": "Shri Sai Baba Museum",
        "description": "The Shri Sai Baba Museum is an insightful repository of artifacts that narrate the life and teachings of Sai Baba. This museum features a collection of Sai Baba's personal belongings, including his robes, photographs, and manuscripts, providing a glimpse into his daily life and spiritual journey. Visitors can explore exhibits that detail his philosophy of compassion and selflessness, making it a valuable educational resource. The museum also hosts various photographs that chronicle significant moments from Sai Baba's life, allowing visitors to feel a deeper connection to his story. The museum serves as a testament to his enduring influence and is a must-visit for anyone wishing to understand the essence of Sai Baba's teachings."
    },
    {
        "title": "Khandoba Temple",
        "description": "Khandoba Temple, perched majestically on a hilltop, is dedicated to Lord Khandoba, a revered deity in the region. The temple is an important pilgrimage site and offers breathtaking panoramic views of the surrounding landscape. Its architectural design reflects traditional Indian temple styles, with intricate carvings and vibrant decorations that evoke a sense of spirituality. The temple is bustling with activity during festivals, where devotees come to seek blessings and participate in various rituals. The tranquility of the site, combined with its scenic beauty, makes it an ideal place for meditation and reflection, providing visitors with an enriching spiritual experience."
    },
    {
        "title": "Saptashrungi Devi Temple",
        "description": "Located a short drive from Shirdi, Saptashrungi Devi Temple is dedicated to the goddess Saptashrungi and is situated in a picturesque setting atop a hill. The temple is famous for its stunning trekking routes that lead to the shrine, making it a popular destination for adventure seekers and pilgrims alike. The trek is surrounded by lush greenery, offering breathtaking views of the valleys below. Upon reaching the temple, visitors can participate in rituals and seek blessings from the goddess. The serene environment and the divine ambiance provide a perfect backdrop for spiritual reflection, making it a rewarding experience for those who undertake the journey."
    },
    {
        "title": "Shirdi Village",
        "description": "Exploring the charming village of Shirdi is an integral part of the experience for any visitor. The village is bustling with life, offering local markets that showcase a variety of handicrafts, religious souvenirs, and traditional snacks. Engaging with the warm and welcoming locals provides a unique insight into the rich cultural heritage of the region. Visitors can enjoy traditional Maharashtrian cuisine at local eateries, adding a culinary dimension to their journey. The vibrant atmosphere, filled with the sounds of daily life and the sights of colorful stalls, makes wandering through Shirdi Village a delightful experience that enhances the overall visit."
    },
    {
        "title": "Naldurg Fort",
        "description": "Naldurg Fort is a historic fort located near Shirdi, known for its impressive architecture and strategic significance. The fort, built atop a hill, offers stunning views of the surrounding landscape and the nearby rivers. Visitors can explore its well-preserved structures, which include massive walls, bastions, and intricate gateways that reflect the military engineering of its time. The fort's history is rich, having served as a stronghold for various dynasties, and guided tours are available to provide insights into its past. The serene environment surrounding the fort, combined with opportunities for exploration and photography, makes it a captivating destination for history enthusiasts."
    },
    {
        "title": "Bhimashankar Wildlife Sanctuary",
        "description": "Bhimashankar Wildlife Sanctuary, located a bit farther from Shirdi, is a biodiversity hotspot that attracts nature enthusiasts and adventure lovers alike. The sanctuary is home to a rich variety of flora and fauna, including the endangered Giant Indian Squirrel, making it a great spot for wildlife spotting and trekking. Visitors can traverse through lush green trails and enjoy the peaceful surroundings filled with the sounds of chirping birds and rustling leaves. The sanctuary also offers various trekking routes that lead to scenic viewpoints and ancient temples, allowing for a fulfilling day of exploration and connection with nature. The tranquility and natural beauty of Bhimashankar provide a refreshing getaway from the bustling town of Shirdi."
    },
    {
        "title": "Aurangabad Caves",
        "description": "Located near Shirdi, the Aurangabad Caves are an architectural marvel that showcases stunning rock-cut sculptures and frescoes dating back to the 2nd to 6th centuries. These ancient caves are less crowded than other historical sites, providing a peaceful atmosphere for exploration. Each cave features intricate carvings that depict Buddhist themes, offering a glimpse into the artistic style and religious practices of the time. The caves serve as a significant historical and cultural site, reflecting the region's rich heritage. History buffs and art enthusiasts will find the Aurangabad Caves a fascinating destination, allowing for deep appreciation of ancient craftsmanship and spiritual artistry."
    }
],   
            
        
"services": [
    {
      "name": "Tempo Traveller 17 Seater on Rent in Shirdi",
      "description": "When it comes to group travel in Shirdi, our Tempo Traveller 17 seater on rent is the perfect choice for families, friends, and corporate outings. Enjoy a spacious and comfortable ride as you explore the divine surroundings of Shirdi and its many attractions, including the sacred Sai Baba Temple, local markets, and beautiful landscapes."
    },
    {
      "name": "Tempo Traveller 17 Seater on Rent Shirdi",
      "description": "Our Tempo Traveller 17 seater on rent in Shirdi offers ample seating and luggage space, making it ideal for long journeys or local sightseeing. Equipped with comfortable seating, air conditioning, and modern amenities, you can experience convenience and comfort with every ride. Whether you're visiting for a day trip or an extended stay, our vehicle will cater to all your travel needs."
    },
    {
      "name": "17 Seater Tempo Traveller on Rent Near Me",
      "description": "Searching for a 17 seater Tempo Traveller on rent near you? Look no further! We provide flexible pick-up options to suit your travel plans, ensuring you have a hassle-free start to your journey. Our team is dedicated to providing prompt service, so you can embark on your adventure without delays."
    },
    {
      "name": "Tempo Traveller Bus Price 17 Seater on Rent Shirdi",
      "description": "We offer competitive pricing for our Tempo Traveller bus, ensuring you get great value for your money. The Tempo Traveller bus price for a 17 seater on rent in Shirdi is tailored to fit various budgets, allowing you to enjoy a premium travel experience without overspending."
    },
    {
      "name": "Tempo Traveller 17 Seater Rent per Km in Shirdi",
      "description": "Our transparent pricing policy includes the Tempo Traveller 17 seater rent per km in Shirdi, so you can easily calculate your travel costs. Enjoy a straightforward and honest pricing structure that makes budgeting for your trip simple and stress-free."
    },
    {
      "name": "Tempo Traveller 17 Seater Price per Km for Outstation",
      "description": "For outstation trips, our Tempo Traveller 17 seater price per km is competitive and offers a great balance of comfort and affordability. Perfect for long-distance travel, our vehicles ensure you arrive at your destination refreshed and relaxed, ready to enjoy your journey."
    },
    {
      "name": "17 Seater Bus Rate per Km Shirdi",
      "description": "The 17 seater bus rate per km in Shirdi is designed to be budget-friendly while providing high-quality service. Trust us to deliver exceptional travel experiences for every journey, no matter how far you’re traveling."
    },
    {
      "name": "Mini Bus on Rent in Shirdi",
      "description": "In addition to our Tempo Traveller, we also offer a mini bus on rent in Shirdi for larger groups. Enjoy spacious seating, air conditioning, and a range of amenities for a comfortable ride. Our mini bus service is perfect for school trips, family reunions, and group tours."
    },
    {
      "name": "Tempo Traveller on Rent",
      "description": "Opt for our Tempo Traveller on rent for any occasion, whether it's a wedding, corporate event, or family vacation. We ensure a well-maintained vehicle and professional drivers who prioritize your safety and satisfaction, making your journey enjoyable from start to finish."
    },
    {
      "name": "AC Tempo Traveller 17 Seater Per Km Rate",
      "description": "Our AC Tempo Traveller 17 seater per km rate ensures you travel in comfort, even on long journeys. Experience a pleasant climate as you explore the beautiful landscapes of Shirdi and beyond, making your travels not just functional but also enjoyable."
    },
    {
      "name": "17 Seater Traveller on Rent",
      "description": "Choose our 17 seater traveller on rent for your group travel requirements. With comfortable seating and modern facilities, it's the perfect choice for a memorable trip. Ideal for pilgrimage tours, sightseeing, or corporate outings, our vehicle can accommodate your specific needs."
    },
    {
      "name": "17 Seater Tempo Traveller Rate per Km",
      "description": "The 17 seater Tempo Traveller rate per km is designed to be competitive, offering you the best rates for quality transportation. Enjoy value without sacrificing comfort, ensuring a pleasant experience for all passengers."
    },
    {
      "name": "AC Bus on Rent and Non-AC Bus on Rent",
      "description": "Whether you prefer an AC bus on rent or a non-AC option, we cater to your needs. Enjoy flexibility in choosing the right vehicle for your group’s comfort and budget. Our diverse fleet ensures there’s a perfect option for every traveler."
    },
    {
      "name": "AC Tempo Traveller on Rent in Shirdi",
      "description": "Travel in style with our AC Tempo Traveller on rent in Shirdi. Designed for comfort, it’s perfect for longer trips and ensures a refreshing experience throughout your journey. Enjoy the modern amenities and spacious interiors as you create unforgettable memories with your group."
    },
    {
      "name": "Contact Number for Tempo Traveller 17 Seater on Rent in Shirdi",
      "description": "For prompt and efficient Tempo Traveller 17 seater on rent in Shirdi, contact Saitirtha Travels at +91 9923879009 / 9922479009. Our team is dedicated to ensuring a smooth and enjoyable ride for all our customers, providing assistance with bookings and inquiries to enhance your travel experience. Your satisfaction is our priority!"
    }
  ],
  "tableData": [
    ["- Shirdi Cab Service", "- SaitirthaTours & Travels"],
    ["- Tempo Traveller 17 Seater on Rent Shirdi", "- 17 Seater Tempo Traveller On Rent Near"],
    ["- Traveller Bus Price 17 Seater on Rent Shirdi", "- Tempo Traveller 17 Seater Rent Per KM in Shirdi"],
    ["- Tempo Traveller 17 Seater Price Per KM for Outsation", "- 17 Seater Bus Rate Per KM Shirdi"],
    ["- Mini Bus On Rent in Shirdi", "- AC Tempo Traveller 17 Seater Per KM Rate"],
    ["- 17 Seater Traveller on Rent", "- AC Bus on Rent Non AC Bus on Rent"],
    ["- AC Tempo Traveller on Rent in Shirdi"]
],
"whychoose": [
    {
        "WhyChooseheading": "Spacious and Comfortable:",
        "WhyChoosedescription": "Our 17-seater Tempo Traveller is designed for comfort, providing ample space for passengers and their luggage. Whether you're planning a family outing, a corporate retreat, or a group tour, our vehicle ensures that everyone travels comfortably without feeling cramped. The interior is thoughtfully designed, with comfortable seating that allows for a relaxed journey, making it ideal for long drives."
    },
    {
        "WhyChooseheading": "Well-Maintained Vehicles:",
        "WhyChoosedescription": "Each Tempo Traveller in our fleet is meticulously maintained and undergoes regular inspections to ensure optimal performance. We equip our vehicles with modern amenities, including air conditioning, entertainment systems, and plush seating, providing a smooth and enjoyable travel experience. This commitment to maintenance ensures that you can trust our vehicles for both safety and comfort."
    },
    {
        "WhyChooseheading": "Experienced Drivers:",
        "WhyChoosedescription": "Our professional drivers are not only skilled in driving but also possess extensive knowledge of local routes and traffic conditions. They prioritize your safety and comfort, ensuring a safe and efficient journey from Shirdi to your destination. Their familiarity with the area allows them to navigate smoothly and recommend the best routes, helping you avoid delays."
    },
    {
        "WhyChooseheading": "Flexible Rental Options:",
        "WhyChoosedescription": "We understand that travel needs can vary significantly, which is why we offer flexible rental packages tailored to your specific requirements. Whether you need a vehicle for a few hours, a full day, or longer-term rentals, we accommodate your preferences. Our various options ensure you get exactly what you need for your journey without compromising on convenience or cost."
    },
    {
        "WhyChooseheading": "Affordable Pricing:",
        "WhyChoosedescription": "Enjoy competitive rates with transparent pricing at Saitirtha Travels. We believe in providing exceptional service without hidden costs. Our pricing structure is straightforward, allowing you to plan your trip with confidence, knowing there will be no unexpected charges. We aim to deliver great value for money, making your travel experience both enjoyable and economical."
    },
    {
        "WhyChooseheading": "24/7 Customer Support:",
        "WhyChoosedescription": "Our dedicated customer service team is available around the clock to assist with bookings, provide travel information, and address any concerns. Whether you need help planning your itinerary, making modifications to your booking, or have urgent inquiries during your trip, our support team is always just a call away, ensuring a smooth travel experience."
    },
    {
        "WhyChooseheading": "Safety First:",
        "WhyChoosedescription": "Passenger safety is our top priority. We implement strict hygiene protocols and regularly sanitize our vehicles to ensure a clean and safe environment for all travelers. Our drivers adhere to all safety regulations, and we conduct thorough checks before each trip, giving you peace of mind as you travel with us."
    },
    {
        "WhyChooseheading": "Customizable Itineraries:",
        "WhyChoosedescription": "We offer the flexibility to tailor your travel itinerary according to your preferences. Whether you wish to explore local attractions, make multiple stops, or have a specific destination in mind, we work with you to create a personalized travel plan that meets your desires. This customization ensures you get the most out of your journey, making it as memorable and enjoyable as possible."
    },
    {
        "WhyChooseheading": "Convenient Booking Process:",
        "WhyChoosedescription": "Easily book your Tempo Traveller through our website or via phone, with quick confirmation for your convenience. Our user-friendly platform simplifies the booking experience, and our team is always ready to help with any specific requests you might have. We strive to make the entire process as seamless as possible, from inquiry to travel."
    }
]


        
  }


  const faqData = [
    {
        question: "How can I book a Tempo Traveller from Shirdi?",
        answer: "You can book a Tempo Traveller through our website or by contacting our customer support team for assistance."
    },
    {
        question: "What are the seating arrangements in the 17-seater Tempo Traveller?",
        answer: "The Tempo Traveller features comfortable seating arrangements designed to accommodate 17 passengers with ample legroom."
    },
    {
        question: "Are the rental rates inclusive of driver fees and fuel?",
        answer: "The rental rates typically include driver fees, but fuel charges may be calculated separately. Please check during booking for complete details."
    },
    {
        question: "Can I hire a Tempo Traveller for a one-way trip?",
        answer: "Yes, we offer both one-way and round-trip rental options for your convenience."
    },
    {
        question: "What payment methods do you accept?",
        answer: "We accept various payment methods, including credit/debit cards, UPI, and cash payments."
    },
    {
        question: "Are your drivers experienced and licensed?",
        answer: "Yes, all our drivers are professional, licensed, and trained to provide a safe and enjoyable travel experience."
    },
    {
        question: "What if I need to change or cancel my booking?",
        answer: "You can modify or cancel your booking by contacting our customer support team prior to your scheduled rental date."
    },
    {
        question: "What safety measures do you have in place?",
        answer: "We ensure that our vehicles are regularly sanitized, and our drivers adhere to health protocols to ensure your safety during travel."
    },
    {
        question: "Is customer support available for inquiries?",
        answer: "Yes, our customer support team is available 24/7 to assist you with any questions or concerns."
    },
    {
        question: "Can I specify any additional requirements for my rental?",
        answer: "Yes, you can communicate any special requirements during the booking process, and we will do our best to accommodate them."
    }
];

const testimonials = [
    {
        name: "Mrs. Anjali Mehta",
        text: "Our group trip from Shirdi to various attractions was a memorable experience thanks to Saitirtha Travels. The 17-seater Tempo Traveller was spacious and comfortable, and our driver was incredibly helpful. He knew all the best routes and made sure we had a fantastic time. I highly recommend this service for anyone needing group transport!"
    },
    {
        name: "Mr. Ramesh Kulkarni",
        text: "I rented a 17-seater Tempo Traveller from Shirdi for a family function, and I couldn't have been happier with the choice. The vehicle was clean and well-maintained, and our driver was friendly and professional. It made our travel stress-free and enjoyable. I will definitely choose Saitirtha Travels for my future trips!"
    }
];




  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>

<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Tempo Traveller 17 Seater on Rent Shirdi | SaitirthaTours & Travels | Affordable 17 Seater Bus Rental</title>
    <meta name="description" content="Looking for a 17-seater Tempo Traveller on rent in Shirdi? Book now for affordable and reliable services. Offering AC and non-AC options, per km rates, and more. SaitirthaTours & Travels!" />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirtha Travels | Tempo Traveller 17 Seater on Rent Shirdi | SaitirthaTours & Travels | Affordable 17 Seater Bus Rental" />
    <meta property="og:description" content="Looking for a 17-seater Tempo Traveller on rent in Shirdi? Book now for affordable and reliable services. Offering AC and non-AC options, per km rates, and more. SaitirthaTours & Travels!" />
    <meta property="og:url" content="https://saitirthatravels.com/tempo-traveller-17-seater-on-rent-shirdi" />
    <meta property="og:image" content="https://saitirthatravels.com/images/tempo-traveller-17-seater-on-rent-shirdi.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Looking for a 17-seater Tempo Traveller on rent in Shirdi? Book now for affordable and reliable services. Offering AC and non-AC options, per km rates, and more. SaitirthaTours & Travels!",
            "url": "https://saitirthatravels.com/tempo-traveller-17-seater-on-rent-shirdi",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/tempo-traveller-17-seater-on-rent-shirdi.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Tempo Traveller 17 Seater on Rent Shirdi",
                "17 Seater Tempo Traveller on Rent Shirdi",
                "Tempo traveller 17 Seater Rent Shirdi",
                "17 Seater Tempo Traveller on Rent near me",
                "17 Seater Bus Rental Shirdi",
                "Tempo traveller 17 Seater rent per km in Shirdi",
                "SaitirthaTours & Travels Shirdi",
                "Tempo traveller 17 Seater price per km for outstation",
                "17 Seater bus rate per km Shirdi",
                "Mini Bus on Rent in Shirdi",
                "Tempo Traveller on Rent in Shirdi",
                "AC Tempo Traveller 17 Seater per km rate",
                "17 Seater Tempo Traveller on Rent Shirdi",
                "AC bus on rent Shirdi",
                "Non-AC bus on rent Shirdi"
            ]
        })}
    </script>
</Helmet>

      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/48.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Tempotraveller;