
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Shirditogrishneshwer() {



  const cardData =
  {
    keyword: ' Shirdi to Grishneshwar Cabs',
    heading: 'Saitirtha Travels: Shirdi to Grishneshwar Cabs',
    headingDescription: 'When it comes to traveling from Shirdi to Grishneshwar, Saitirtha Travels stands out as a premier choice for discerning travelers. We understand that your journey should be as enjoyable as the destination itself. Our commitment to providing top-notch cab services ensures that you have a comfortable, reliable, and seamless travel experience, allowing you to make the most of your time in Grishneshwar.',

    top: 'Top Places to Visit in Grishneshwar with Saitirtha Travels',

    "topPlaces": [
    {
        "title": "1. Grishneshwar Temple",
        "description": "This ancient temple is dedicated to Lord Shiva and is one of the twelve Jyotirlingas in India. Renowned for its exquisite architecture and spiritual significance, it attracts pilgrims from across the country. The serene environment and beautiful surroundings make it a must-visit destination."
    },
    {
        "title": "2. Ellora Caves",
        "description": "Located nearby, the Ellora Caves are a UNESCO World Heritage Site, famous for their stunning rock-cut temples and intricate sculptures. The Kailasa Temple, dedicated to Lord Shiva, showcases remarkable craftsmanship. Visiting Ellora enhances the experience of exploring the rich cultural heritage of the region."
    },
    {
        "title": "3. Daulatabad Fort",
        "description": "A short distance from Grishneshwar, Daulatabad Fort offers a glimpse into Maharashtra's rich history. Known for its strategic location and impressive architecture, the fort provides breathtaking views of the surrounding landscape, making it a great spot for history enthusiasts and adventure lovers."
    },
    {
        "title": "4. Bhadra Maruti Temple",
        "description": "Situated close to Grishneshwar, this temple is dedicated to Lord Hanuman and is famous for its unique idol of Hanuman in a reclining position. Surrounded by lush greenery, it offers a peaceful environment for worship and reflection."
    },
    {
        "title": "5. Panchakki",
        "description": "Located in Aurangabad, Panchakki is a historical watermill that served as a source of water for the nearby Bibi Ka Maqbara. The site features a beautiful garden and impressive structures showcasing the engineering skills of the past, making it a serene spot to visit."
    },
    {
        "title": "6. Ajanta Caves",
        "description": "Although a bit farther, the Ajanta Caves are worth the visit for their stunning rock-cut architecture and ancient Buddhist murals. These caves are also a UNESCO World Heritage Site and provide a glimpse into India’s artistic heritage."
    },
    {
        "title": "7. Bibi Ka Maqbara",
        "description": "Often referred to as the 'Taj of the Deccan,' this mausoleum was built in memory of Aurangzeb's wife, Dilras Banu Begum. Its stunning Mughal architecture and beautiful gardens make it a significant historical site."
    },
    {
        "title": "8. Khultabad",
        "description": "A nearby town known for its historical significance, Khultabad is home to the tomb of the Mughal emperor Aurangzeb. Visitors can explore the beautifully constructed tomb and the surrounding area, rich in history."
    },
    {
        "title": "9. Chand Minar",
        "description": "Located in Daulatabad, this towering minaret is a remarkable piece of architecture that dates back to the 14th century. The structure stands tall and serves as a testament to the rich history and culture of the region."
    },
    {
        "title": "10. Ghrishneshwar Waterfall",
        "description": "Close to the Grishneshwar Temple, this picturesque waterfall offers a tranquil setting for relaxation and photography. The scenic beauty surrounding the waterfall enhances the experience of your visit."
    }
],  
    
            
        
    
  "services": [
    {
        "name": "Shirdi to Grishneshwar Taxi Service",
        "description": "Travel comfortably from Shirdi to Grishneshwar with SaitirthaTours & Travels' reliable taxi service. We understand the importance of your pilgrimage, which is why we prioritize punctuality and comfort. Our experienced drivers are not only familiar with the best routes to avoid traffic but are also trained to provide a safe and pleasant travel experience. The scenic drive to Grishneshwar offers beautiful landscapes that enhance your journey, making it spiritually fulfilling. We aim to ensure that your travel is as peaceful and stress-free as possible, allowing you to focus on your pilgrimage."
    },
    {
        "name": "Shirdi to Grishneshwar Cab Fare",
        "description": "We offer competitive cab fares from Shirdi to Grishneshwar, providing transparent and affordable pricing options that ensure value for your journey to this spiritual destination. Our fare structure is designed to be straightforward, eliminating any confusion. With us, you can rest assured that there are no hidden fees, and our pricing includes all applicable taxes. We encourage our customers to inquire about any special deals or packages that may be available, especially for group bookings, which can provide significant savings."
    },
    {
        "name": "Taxi Fare from Shirdi to Grishneshwar",
        "description": "Our taxi fare from Shirdi to Grishneshwar is based on distance and vehicle choice, with no hidden fees. We provide a clear estimate to help plan your travel expenses, ensuring you have a comprehensive understanding of the costs involved. Our team is always ready to assist you with fare breakdowns and help you select the best vehicle option for your needs. Whether you're traveling solo or in a group, we cater to different budgets while maintaining high service standards."
    },
    {
        "name": "Shirdi Grishneshwar Car Rental",
        "description": "Choose from a variety of car rental options for your trip from Shirdi to Grishneshwar. Our fleet includes comfortable, well-maintained cars, ensuring a smooth journey. We offer various vehicle types, including sedans, SUVs, and luxury cars, allowing you to select the perfect fit for your comfort level and group size. Each vehicle is equipped with modern amenities to enhance your travel experience. Additionally, our rental services include 24/7 customer support to assist you during your trip."
    },
    {
        "name": "Shirdi to Grishneshwar One-Way Taxi",
        "description": "Our one-way taxi service from Shirdi to Grishneshwar offers flexibility and affordability, allowing you to pay for just the journey you need without a round-trip commitment. This service is ideal for those who want to focus solely on visiting the temple without worrying about the return journey. Our drivers are knowledgeable about the best times to travel, ensuring that you arrive at Grishneshwar on time to partake in the spiritual activities without any rush."
    },
    {
        "name": "Shirdi to Grishneshwar Cab Booking",
        "description": "Booking a cab from Shirdi to Grishneshwar is easy with our online reservation system. We ensure a hassle-free process, allowing you to secure your transportation in just a few clicks. Our booking platform is user-friendly, and we also offer personalized assistance through our customer service hotline. Whether you prefer to book in advance or need last-minute arrangements, we are here to accommodate your needs and ensure a timely start to your journey."
    },
    {
        "name": "Shirdi to Grishneshwar Travel by Taxi",
        "description": "Explore the spiritual and scenic route from Shirdi to Grishneshwar by taxi. SaitirthaTours & Travels provides safe and comfortable travel for this sacred journey. As you travel, you can take in the beautiful countryside views, making the experience as enriching as the destination itself. Our drivers are not only skilled but also serve as guides, sharing interesting insights about the landmarks and points of interest along the way."
    },
    {
        "name": "Shirdi Grishneshwar Cab Rates",
        "description": "We offer fair and transparent cab rates for travel from Shirdi to Grishneshwar, catering to different budgets and vehicle preferences. Our pricing strategy is designed to provide you with the best value for your journey, ensuring that you receive quality service without overspending. We also provide a detailed explanation of our rates, including base fares and additional costs for amenities, so you can make informed decisions about your transportation."
    },
    {
        "name": "Shirdi to Grishneshwar Taxi Fare per km",
        "description": "Our per-kilometer taxi fare from Shirdi to Grishneshwar provides cost transparency, helping you plan your travel budget effectively. This pricing model allows you to estimate the total fare based on your travel requirements and vehicle choice. We pride ourselves on being competitive in the market while maintaining high service quality, ensuring that you can enjoy your pilgrimage without financial stress."
    },
    {
        "name": "Affordable Shirdi Grishneshwar Cab",
        "description": "SaitirthaTours & Travels provides affordable taxi services from Shirdi to Grishneshwar, allowing you to travel comfortably without overspending. We believe that everyone should have access to reliable transportation during their spiritual journeys. Our commitment to affordability does not compromise the quality of our service; we ensure that all our vehicles are well-maintained and our drivers are professional and courteous."
    },
    {
        "name": "Shirdi to Grishneshwar Taxi",
        "description": "Our taxi service from Shirdi to Grishneshwar offers punctuality and convenience, allowing you to focus on enjoying your trip to this holy destination. We understand the significance of your visit, and our services are tailored to meet your needs. Whether you're traveling alone or with family, our fleet is equipped to handle all group sizes, providing a comfortable ride for everyone involved."
    },
    {
        "name": "Shirdi to Grishneshwar Innova Cabs",
        "description": "For larger groups or added comfort, book our spacious Innova cabs for your trip from Shirdi to Grishneshwar. The Innova is known for its roomy interior and comfortable seating, making it an excellent choice for families or groups traveling together. Our drivers are experienced in managing group travel dynamics, ensuring a smooth and enjoyable experience for all passengers."
    },
    {
        "name": "Shirdi to Grishneshwar Ertiga Cab",
        "description": "Travel comfortably in our Ertiga cabs from Shirdi to Grishneshwar, a great choice for smaller groups seeking affordability and comfort. The Ertiga provides enough space for everyone while ensuring a cozy ride. Our well-trained drivers prioritize your comfort and safety, allowing you to relax and enjoy your journey to this sacred site."
    },
    {
        "name": "Shirdi to Tempo Traveller on Rent",
        "description": "Ideal for groups, our Tempo Traveller rental from Shirdi to Grishneshwar offers ample seating and comfort for group travel to this revered site. Our Tempo Travellers come equipped with air conditioning, comfortable seating, and plenty of space for luggage, ensuring a pleasant experience for all passengers. We understand the importance of group dynamics and work to create a harmonious travel experience for everyone involved."
    },
    {
        "name": "Shirdi to Grishneshwar Mini Bus on Rent",
        "description": "For larger groups, our mini bus rental service from Shirdi to Grishneshwar provides a spacious and comfortable journey, ideal for family trips or pilgrimages. Our mini buses are well-equipped with modern amenities and can accommodate a significant number of passengers, making them an excellent choice for group travel. With our experienced drivers, you can expect a safe and enjoyable journey as you make your way to Grishneshwar."
    },
    {
        "name": "Contact Information",
        "description": "For bookings or inquiries, contact SaitirthaTours & Travels at +91 9923879009 / +91 9922479009. Our customer service team is dedicated to providing you with prompt and helpful assistance, whether you have questions about our services, need help with booking, or want to discuss special requirements for your trip. We are committed to making your journey from Shirdi to Grishneshwar as reliable and pleasant as possible."
    }
],
"tableData": [
    ["- Shirdi to Grishneshwar Taxi Service", "- Shirdi to Grishneshwar Cab Fare"],
    ["- Taxi Fare from Shirdi to Grishneshwar", "- Shirdi Grishneshwar Car Rental"],
    ["- Shirdi to Grishneshwar One Way Taxi", "- Shirdi to Grishneshwar Cab Booking"],
    ["- Shirdi to Grishneshwar Travel by Taxi", "- Shirdi Grishneshwar Cab Rates"],
    ["- Shirdi to Grishneshwar Taxi Fare per KM", "- Affordable Shirdi Grishneshwar Cab"],
    ["- Shirdi to Grishneshwar Cab", "- Shirdi to Grishneshwar Taxi"],
    ["- SaitirthaTours & Travels Shirdi", "- Shirdi to Grishneshwar Taxi Fare"],
    ["- Shirdi to Grishneshwar Innova Cabs", "- Shirdi to Grishneshwar Ertiga Cab"],
    ["- Shirdi to Tempo Traveller On Rent", "- Shirdi to Grishneshwar Mini Bus On Rent"]
],
"whychoose": [
    {
        "WhyChooseheading": "Affordable Rates:",
        "WhyChoosedescription": "At Saitirtha Travels, we prioritize affordability without compromising on quality. Our rates for cab services from Shirdi to Grishneshwar are among the most competitive in the market, offering exceptional value for the experience we provide. With transparent pricing and no hidden fees, you can trust that what you see is what you pay. We understand the importance of budget-friendly travel, especially for pilgrimages, so we’ve tailored our pricing to suit travelers seeking both economy and comfort. Our rates are designed to make frequent trips feasible for all travelers, whether you're a solo pilgrim or visiting with family."
    },
    {
        "WhyChooseheading": "Comfortable Journey:",
        "WhyChoosedescription": "Experience unmatched comfort with our fleet of vehicles, each selected for spaciousness, cleanliness, and modern amenities to ensure an enjoyable journey. Our cabs are outfitted with plush seating and climate control to keep you relaxed during the drive from Shirdi to Grishneshwar, which makes a significant difference on longer routes. We cater to different group sizes and preferences with options ranging from economy to premium vehicles, so you can pick what best suits your needs. By prioritizing your comfort and relaxation, we aim to make each trip not just a commute but a refreshing part of your journey."
    },
    {
        "WhyChooseheading": "Professional Drivers:",
        "WhyChoosedescription": "Our drivers are seasoned professionals who bring extensive knowledge of the Shirdi-Grishneshwar route and an unwavering commitment to passenger safety and satisfaction. They undergo rigorous training not only in driving skills but also in customer service, ensuring that each passenger enjoys a pleasant and secure ride. With a deep understanding of local roads and traffic patterns, they guarantee timely arrivals and are always prepared with alternate routes if needed. Many of our drivers are also locals with valuable insights into Grishneshwar’s history and culture, adding a unique touch to your travel experience."
    },
    {
        "WhyChooseheading": "Easy Booking Process:",
        "WhyChoosedescription": "Booking with Saitirtha Travels is a breeze, designed for simplicity and convenience. Our user-friendly website allows you to book a cab in just a few clicks, providing instant confirmations and options to customize your booking. For those who prefer personal assistance, our responsive customer service team is available to help with any queries or special requests. We’ve streamlined our process to accommodate last-minute plans as well, ensuring you can secure a cab without any hassle. From the moment you decide to travel, we’re here to make your experience as smooth as possible, letting you focus on the journey ahead."
    },
    {
        "WhyChooseheading": "Flexible Timings:",
        "WhyChoosedescription": "With 24/7 service, we understand that travel doesn’t always happen at standard hours. Saitirtha Travels provides the flexibility to choose your departure time based on your schedule, so you can travel when it’s most convenient for you. Whether you need an early start to maximize your day or a late departure to fit around other plans, we accommodate your timing needs. Our commitment to flexible scheduling ensures that whether it’s a planned trip or a last-minute journey, you’ll always have a reliable travel option that aligns with your schedule."
    },
    {
        "WhyChooseheading": "Safety and Hygiene:",
        "WhyChoosedescription": "Your health and safety are paramount to us. All our vehicles undergo routine maintenance checks and are thoroughly sanitized before every trip, adhering to strict hygiene protocols to ensure a clean and safe environment. We prioritize passenger safety through regular vehicle inspections, and our drivers follow comprehensive safety protocols. This commitment extends beyond basic cleanliness to a proactive approach in making your travel experience secure. Especially important during pilgrimage travels, we understand the need for a safe, comfortable journey that leaves you free to focus on the spiritual and personal experiences of your trip."
    }
]

        
  }


  const faqData = [
    {
        question: "What is the fare for a cab from Shirdi to Grishneshwar?",
        answer: "The fare typically ranges from INR 1,500 to INR 2,500, depending on the vehicle type and demand. Please check our website for the latest pricing."
    },
    {
        question: "How long does the journey take?",
        answer: "The journey from Shirdi to Grishneshwar usually takes about 2 to 2.5 hours, depending on traffic conditions."
    },
    {
        question: "Are there any additional charges?",
        answer: "Extra charges may apply for additional stops or if you select a larger vehicle for your trip."
    },
    {
        question: "How can I book a cab for this route?",
        answer: "You can easily book your cab through our website or by contacting our customer support team for assistance."
    },
    {
        question: "What payment methods do you accept?",
        answer: "We accept various payment options, including credit/debit cards, UPI, and cash."
    },
    {
        question: "Can I modify or cancel my booking?",
        answer: "Yes, you can modify or cancel your booking by contacting customer support or through our website, in accordance with our cancellation policy."
    },
    {
        question: "Is the cab sanitized before each trip?",
        answer: "Yes, all our vehicles are thoroughly cleaned and sanitized before every journey to ensure your safety."
    },
    {
        question: "Can I request extra stops during the trip?",
        answer: "Yes, you can request reasonable extra stops for refreshments or sightseeing along the way."
    }
];

const testimonials = [
    {
        name: "Mr. Anil Patil",
        text: "I had a wonderful experience traveling from Shirdi to Grishneshwar with Saitirtha Travels. The driver was professional and punctual, and the vehicle was very comfortable. Highly recommend!"
    },
    {
        name: "Mrs. Seema Joshi",
        text: "Saitirtha Travels made our trip to Grishneshwar enjoyable. The booking was straightforward, and our driver shared interesting stories about the region. Will definitely choose them again!"
    }
];



  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Shirdi to Grishneshwar Cab | SaitirthaTours & Travels</title>
    <meta name="description" content="Book an affordable and reliable taxi from Shirdi to Grishneshwar with SaitirthaTours & Travels. We offer one-way and round-trip services for a comfortable journey." />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirtha Travels | Shirdi to Grishneshwar Cab | SaitirthaTours & Travels" />
    <meta property="og:description" content="Book an affordable and reliable taxi from Shirdi to Grishneshwar with SaitirthaTours & Travels. We offer one-way and round-trip services for a comfortable journey." />
    <meta property="og:url" content="https://saitirthatravels.com/shirdi-to-grishneshwar-cab" />
    <meta property="og:image" content="https://saitirthatravels.com/images/shirdi-to-grishneshwar-cab.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Book an affordable and reliable taxi from Shirdi to Grishneshwar with SaitirthaTours & Travels. We offer one-way and round-trip services for a comfortable journey.",
            "url": "https://saitirthatravels.com/shirdi-to-grishneshwar-cab",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/shirdi-to-grishneshwar-cab.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi to Grishneshwar Taxi Service",
                "Shirdi to Grishneshwar Cab Fare",
                "Taxi fare from Shirdi to Grishneshwar",
                "Shirdi Grishneshwar Car Rental",
                "Shirdi to Grishneshwar One Way Taxi",
                "Shirdi to Grishneshwar Cab Booking",
                "Shirdi to Grishneshwar Travel by Taxi",
                "Shirdi Grishneshwar Cab Rates",
                "Shirdi to Grishneshwar Taxi Fare per km",
                "Affordable Shirdi Grishneshwar Cab",
                "Shirdi to Grishneshwar Cab",
                "Shirdi to Grishneshwar Taxi",
                "Shirdi to Grishneshwar Innova Cabs",
                "Shirdi to Grishneshwar Ertiga Cab",
                "Shirdi to Tempo Traveller On Rent",
                "Shirdi to Grishneshwar Mini Bus On Rent"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/34.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirditogrishneshwer;