
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Shirditomumbai() {



  const cardData =
  {
    keyword: '  Shirdi to Mumbai Cabs ',
    heading: 'Saitirtha Travels: Shirdi to Mumbai Cabs ',
    headingDescription: 'When it comes to traveling from Shirdi to Mumbai, Saitirtha Travels stands out as a premier choice for discerning travelers. We understand that your journey should be as enjoyable as the destination itself. Our commitment to providing top-notch cab services ensures that you have a comfortable, reliable, and seamless travel experience, allowing you to make the most of your time in Mumbai.',

    top: 'Top  Places to Visit in Mumbai with Saitirtha Travels',

    topPlaces: [
        {
            "title": "1. Gateway of India",
            "description": "This monumental archway, built in 1924, is a significant historical landmark that commemorates the visit of King George V and Queen Mary. Overlooking the Arabian Sea, it’s not only a gateway to the city but also a popular gathering place for locals and tourists alike. Enjoy the fresh sea breeze, take stunning photographs, and explore the nearby Taj Mahal Palace Hotel, an iconic symbol of luxury and hospitality."
        },
        {
            "title": "2. Marine Drive",
            "description": "Stretching along the coast, Marine Drive is a picturesque boulevard that transforms into a dazzling promenade at night, resembling a necklace of lights. It’s a popular spot for couples, families, and friends to take evening strolls, enjoy street food, and appreciate the soothing sounds of the waves. The nearby Chowpatty Beach is famous for its local delicacies, including pav bhaji and bhel puri."
        },
        {
            "title": "3. Chhatrapati Shivaji Maharaj Terminus (CST)",
            "description": "An architectural marvel and a UNESCO World Heritage Site, CST is a bustling railway station that showcases stunning Victorian Gothic architecture. Built in 1887, it features intricate detailing and is a hub of activity with thousands of commuters passing through daily. Take a moment to admire its grandeur and learn about its historical significance as one of the busiest railway stations in India."
        },
        {
            "title": "4. Elephanta Caves",
            "description": "A short ferry ride from the Gateway of India takes you to Elephanta Island, home to ancient rock-cut temples dedicated to Lord Shiva. The caves, dating back to the 5th to 8th centuries, are famous for their stunning sculptures and intricate carvings. The highlight is the grand 20-foot sculpture of Trimurti, depicting a three-headed Shiva. The serene surroundings make it a perfect spot for history buffs and nature lovers alike."
        },
        {
            "title": "5. Haji Ali Dargah",
            "description": "This beautiful mosque is located on a small islet off the coast of Worli and is accessible only during low tide. Built in 1431, Haji Ali Dargah is not only a religious site but also a masterpiece of Indo-Islamic architecture. Visitors can experience the spiritual ambiance, explore the lush gardens, and enjoy breathtaking views of the Arabian Sea. The vibrant local market nearby offers a variety of traditional snacks and souvenirs."
        },
        {
            "title": "6. Juhu Beach",
            "description": "One of Mumbai's most popular beaches, Juhu is an ideal spot for relaxation and fun. The sandy shore is lined with palm trees and food stalls offering local treats. Engage in beach activities, enjoy the sunset, or simply relax with a good book. Juhu Beach is also famous for its film stars’ bungalows, making it a hotspot for celebrity sightings."
        },
        {
            "title": "7. Chhatrapati Shivaji Maharaj Vastu Sangrahalaya (CSMVS)",
            "description": "Formerly known as the Prince of Wales Museum, CSMVS is an essential destination for art and history enthusiasts. It boasts an extensive collection of artifacts, including sculptures, decorative art, and textiles from different eras and regions of India. The museum's impressive architecture and beautiful gardens make it a peaceful retreat from the city's hustle and bustle."
        },
        {
            "title": "8. Bandra-Worli Sea Link",
            "description": "This architectural marvel is a cable-stayed bridge that connects the suburbs of Bandra and Worli. Spanning approximately 5.6 kilometers, it provides stunning views of the Mumbai skyline and the Arabian Sea. Traveling on the sea link, especially at sunset, is a breathtaking experience that you shouldn't miss. It's also a symbol of modern Mumbai and an engineering feat that enhances connectivity across the city."
        },
        {
            "title": "9. Dharavi",
            "description": "Known as one of Asia's largest slums, Dharavi is also a thriving hub of entrepreneurship and creativity. Guided tours offer a unique insight into the daily lives of its residents, the businesses they run, and the vibrant community spirit that prevails. Exploring Dharavi can be an eye-opening experience, showcasing resilience, innovation, and the strong sense of community that defines this area."
        },
        {
            "title": "10. Babulnath Temple",
            "description": "Nestled near Marine Lines, Babulnath Temple is an ancient Hindu temple dedicated to Lord Shiva. The temple is known for its peaceful ambiance and beautiful architecture, attracting devotees and tourists alike. The temple is surrounded by lush greenery and offers a tranquil environment for worship and reflection amidst the bustling city."
        }
    ],    
        
    
    "services": [
        {
            "name": "Shirdi to Mumbai Cab Service",
            "description": "When traveling from Shirdi to Mumbai, choosing a reliable cab service is crucial for a comfortable journey. At SaitirthaTours & Travels, we pride ourselves on offering top-notch transportation solutions. Our cabs are meticulously maintained to ensure passenger safety and comfort, and our experienced drivers make sure you can relax as we take care of the road."
        },
        {
            "name": "Cab from Shirdi to Mumbai",
            "description": "Our cab service from Shirdi to Mumbai is designed to provide a seamless travel experience. We prioritize timely arrivals, navigating the best routes to ensure you reach your destination on schedule."
        },
        {
            "name": "Shirdi Mumbai Taxi Service",
            "description": "Our Shirdi to Mumbai taxi service caters to all types of travelers, offering a fleet of comfortable, air-conditioned vehicles equipped with modern amenities. Whether traveling solo or in a group, we provide reliable transportation with a commitment to customer satisfaction."
        },
        {
            "name": "Taxi from Shirdi to Mumbai",
            "description": "Booking a taxi from Shirdi to Mumbai is easy with our online platform. Reserve your cab in advance and enjoy a stress-free journey with our trained drivers and well-maintained vehicles."
        },
        {
            "name": "Shirdi to Mumbai Car Rental",
            "description": "Our car rental options from Shirdi to Mumbai provide flexibility and convenience. Choose from a wide selection of vehicles to suit your travel needs, ensuring a comfortable and enjoyable ride."
        },
        {
            "name": "Shirdi to Mumbai Taxi Fare",
            "description": "At SaitirthaTours & Travels, we offer competitive and transparent pricing for your trip from Shirdi to Mumbai. Contact us for an accurate fare estimate based on distance, vehicle type, and travel duration."
        },
        {
            "name": "Shirdi to Mumbai Cab Booking",
            "description": "Our easy cab booking system allows you to reserve your ride from Shirdi to Mumbai in a few clicks. Our team is ready to assist with any special requests to ensure a comfortable journey."
        },
        {
            "name": "Affordable Cab Shirdi to Mumbai",
            "description": "Our affordable cab options from Shirdi to Mumbai cater to a range of budgets without compromising quality. Enjoy reliable transportation with transparent pricing and no hidden charges."
        },
        {
            "name": "Best Cab for Shirdi to Mumbai",
            "description": "For the best cab service from Shirdi to Mumbai, choose SaitirthaTours & Travels. Our fleet includes a range of vehicles and professional drivers trained to ensure a safe, comfortable experience."
        },
        {
            "name": "Shirdi to Mumbai Cab Rate",
            "description": "Our cab rates from Shirdi to Mumbai are competitive and straightforward, helping you budget effectively. Reach out to our customer service for a detailed rate breakdown."
        },
        {
            "name": "Cab from Mumbai to Shirdi",
            "description": "Our cab service from Mumbai to Shirdi ensures a comfortable and enjoyable return journey. We prioritize punctuality, making sure you arrive on time for your schedule."
        },
        {
            "name": "Shirdi to Mumbai Cab One Way",
            "description": "Our one-way cab service from Shirdi to Mumbai provides flexibility and convenience. Book easily and expect the same high-quality service as a round trip."
        },
        {
            "name": "Shirdi to Mumbai Cab Service",
            "description": "Our cab service from Shirdi to Mumbai includes modern vehicles and experienced drivers for a safe and comfortable travel experience."
        },
        {
            "name": "Shirdi to Mumbai Airport Cab",
            "description": "Our Shirdi to Mumbai airport cab service ensures timely arrival for your flight. We monitor schedules and plan routes to help you avoid delays."
        },
        {
            "name": "Shirdi to Mumbai International Airport Cab Service",
            "description": "For international travelers, our cab service from Shirdi to Mumbai International Airport is dependable and convenient, ensuring timely arrival for international flights."
        },
        {
            "name": "Shirdi to Navi Mumbai Cab Service",
            "description": "Our Shirdi to Navi Mumbai cab service offers comfort with knowledgeable drivers and a selection of vehicles to meet your travel needs."
        },
        {
            "name": "Shirdi to Thane Cab Booking",
            "description": "Our Shirdi to Thane cab booking service is reliable and tailored to your needs, ensuring a stress-free and safe journey."
        },
        {
            "name": "Shirdi to Panvel Cab Booking",
            "description": "Book your cab from Shirdi to Panvel with ease. We offer well-maintained vehicles and professional drivers for a smooth ride."
        },
        {
            "name": "Shirdi to Siddhivinayak Taxi Fare",
            "description": "Our taxi fare from Shirdi to Siddhivinayak is competitive and transparent, allowing you to budget effectively for your trip."
        },
        {
            "name": "Shirdi to Mumbai Travel Packages",
            "description": "Our Shirdi to Mumbai travel packages include cab services, accommodation options, and guided tours for a comprehensive travel experience."
        },
        {
            "name": "Shirdi to Mumbai Transportation",
            "description": "SaitirthaTours & Travels offers a range of transportation solutions from Shirdi to Mumbai, ensuring you arrive safely and comfortably."
        },
        {
            "name": "Shirdi to Mumbai Local Tours",
            "description": "Enhance your journey with our local tours from Shirdi to Mumbai, offering guided experiences to explore the best attractions in Mumbai."
        }
    ],
    "tableData": [
        ["- Shirdi Car Rental", "- Rent a car in Shirdi with Driver"],
        ["- SaitirthaTours & Travels Shirdi", "- Shirdi Taxi Rental for Local Sightseeing"],
        ["- Car Hire Shirdi", "- Affordable Car Rental Shirdi"],
        ["- Rental Car Shirdi Sai Baba Temple", "- Best Car Rental Shirdi"],
        ["- Shirdi Airport Car Rental", "- Shirdi Car Rental"],
        ["- Shirdi Cab Service", "- Cab Booking in Shirdi"],
        ["- Car Hire in Shirdi", "- Rent a car for Shirdi"],
        ["- Shirdi Taxi Cab Service", "- Travel Agents in Shirdi"],
        ["- Tours and Travels in Shirdi", "- Best Travel Agency in Shirdi"],
        ["- Shirdi Cab Booking Service", "- Shirdi Saibaba Travels"]
    ],
    "whychoose": [
        {
            "WhyChooseheading": "Why Choose Saitirtha Travels for Your Shirdi to Mumbai?",
            "WhyChoosedescription": "At Saitirtha Travels, we strive to provide an exceptional travel experience for your journey from Shirdi to Mumbai, ensuring that your travel is as memorable as your destination. Our commitment to customer satisfaction and quality service makes us a trusted choice for travelers. Here’s why you should choose us:"
        },
        {
            "WhyChooseheading": "Exceptional Customer Service:",
            "WhyChoosedescription": "At Saitirtha Travels, we pride ourselves on delivering outstanding customer service. Our friendly and knowledgeable support team is available 24/7 to assist you with any questions or concerns you may have, ensuring a smooth and enjoyable travel experience."
        },
        {
            "WhyChooseheading": "Comprehensive Fleet of Vehicles:",
            "WhyChoosedescription": "We offer a diverse range of vehicles to meet your specific needs. Whether you prefer a comfortable sedan for solo travel or a spacious SUV for a family trip, our fleet ensures you travel in style and comfort."
        },
        {
            "WhyChooseheading": "Transparent Pricing:",
            "WhyChoosedescription": "Our commitment to transparency means you can trust us with honest pricing. We provide clear and upfront quotes with no hidden charges, allowing you to plan your budget effectively while enjoying quality transportation services."
        },
        {
            "WhyChooseheading": "Safety and Reliability:",
            "WhyChoosedescription": "Your safety is our top priority. All our drivers are experienced, licensed, and trained in safe driving practices. We conduct regular maintenance checks on our vehicles, ensuring they are in excellent condition, so you can relax knowing you are in safe hands."
        },
        {
            "WhyChooseheading": "Convenient Online Booking:",
            "WhyChoosedescription": "With our user-friendly online booking platform, securing your cab from Shirdi to Mumbai is quick and easy. You can reserve your ride in just a few clicks, making travel planning hassle-free."
        },
        {
            "WhyChooseheading": "Flexible Itineraries:",
            "WhyChoosedescription": "We understand that travel plans can change. Our services are flexible, allowing you to customize your itinerary based on your preferences, whether you need a one-way trip or a round trip."
        },
        {
            "WhyChooseheading": "Local Insights and Recommendations:",
            "WhyChoosedescription": "Our drivers are local experts who can provide valuable insights into places to visit and things to do in Mumbai, enhancing your travel experience and helping you discover hidden gems along the way."
        },
        {
            "WhyChooseheading": "Commitment to Customer Satisfaction:",
            "WhyChoosedescription": "We aim to exceed your expectations with every journey. We continuously seek feedback from our customers to improve our services, ensuring your experience is nothing short of exceptional."
        },
        {
            "WhyChooseheading": "COVID-19 Safety Measures:",
            "WhyChoosedescription": "We prioritize your health and safety by implementing stringent COVID-19 safety protocols. Our vehicles are thoroughly sanitized after each trip, and our drivers adhere to all health guidelines, providing you with peace of mind during your travels."
        },
        {
            "WhyChooseheading": "Affordable Travel Solutions:",
            "WhyChoosedescription": "We believe quality transportation should be accessible to everyone. Our competitive rates and various package options ensure that you can enjoy comfortable and reliable travel from Shirdi to Mumbai without breaking the bank."
        }
    ]

        
  }


  const faqData = [
    {
      question: "What are the cab options available for the Shirdi to Mumbai journey?",
      answer: "We offer a variety of vehicles, including sedans, SUVs, and luxury cars, to cater to different travel preferences and group sizes. You can choose the vehicle that best suits your needs for comfort and convenience."
    },
    {
      question: "How do I book a cab from Shirdi to Mumbai?",
      answer: "Booking a cab is easy! You can reserve your ride through our user-friendly online booking platform, or you can call our customer service team for assistance. We recommend booking in advance to secure your preferred vehicle."
    },
    {
      question: "What is the fare for a cab from Shirdi to Mumbai?",
      answer: "Our pricing is competitive and transparent. The fare depends on the type of vehicle you choose, the duration of the trip, and any additional services you may require. For an accurate quote, please contact our customer service or visit our website."
    },
    {
      question: "Is the cab service available 24/7?",
      answer: "Yes, our cab service from Shirdi to Mumbai is available 24/7. You can book your ride at any time, and we will ensure timely pick-up and drop-off, regardless of the hour."
    },
    {
      question: "Are your drivers experienced and professional?",
      answer: "Absolutely! Our drivers are well-trained, licensed, and have extensive knowledge of the routes. They prioritize your safety and comfort throughout the journey, providing a reliable and pleasant travel experience."
    },
    {
      question: "What safety measures are in place for COVID-19?",
      answer: "We take your health seriously. Our vehicles are thoroughly sanitized after each trip, and our drivers follow strict hygiene protocols. Face masks are mandatory for both drivers and passengers, ensuring a safe environment for your journey."
    },
    {
      question: "Can I make changes to my booking after it's confirmed?",
      answer: "Yes, we understand that plans can change. Please contact our customer service as soon as possible to discuss any changes to your booking. We will do our best to accommodate your requests."
    },
    {
      question: "Do you offer round trip services?",
      answer: "Yes, we offer both one-way and round trip services for your convenience. If you need a return journey from Mumbai to Shirdi, just let us know while booking."
    },
    {
      question: "What payment methods do you accept?",
      answer: "We accept various payment methods, including cash, credit/debit cards, and online payment options. You can choose the method that is most convenient for you at the time of booking."
    },
    {
      question: "How can I contact Saitirtha Travels for more information?",
      answer: "You can reach us through our customer service number or email. Our team is available to answer any questions and provide assistance with your bookings."
    }
  ];
  
  const testimonials = [
    {
      name: "Mrs. Neha Kapoor",
      text: "I recently booked a cab from Shirdi to Mumbai with Saitirtha Travels, and I couldn't be happier with the service. The booking process was seamless, and our driver arrived right on time. He was very professional and knew the best routes to take, which made our journey smooth and enjoyable. The cab was clean and comfortable, making the long drive very pleasant. I highly recommend Saitirtha Travels for anyone needing reliable transportation from Shirdi to Mumbai!"
    },
    {
      name: "Mr. Rajiv Sharma",
      text: "Saitirtha Travels provided an excellent cab service for our trip from Shirdi to Mumbai. The entire experience was hassle-free, from booking to drop-off. Our driver was friendly and shared interesting insights about the landmarks along the way. The vehicle was spacious and well-maintained, which made the journey enjoyable. I will definitely choose Saitirtha Travels again for future trips!"
    }
  ];
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Shirdi to Mumbai Cabs | SaitirthaTours & Travels</title>
    <meta name="description" content="Reliable and affordable taxi service from Shirdi to Mumbai. Book a cab for a comfortable journey, including one-way and round trips, to Mumbai Airport, Navi Mumbai, and more with SaitirthaTours & Travels." />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirtha Travels | Shirdi to Mumbai Cabs | SaitirthaTours & Travels" />
    <meta property="og:description" content="Reliable and affordable taxi service from Shirdi to Mumbai. Book a cab for a comfortable journey, including one-way and round trips, to Mumbai Airport, Navi Mumbai, and more with SaitirthaTours & Travels." />
    <meta property="og:url" content="https://saitirthatravels.com/shirdi-to-mumbai-cabs" />
    <meta property="og:image" content="https://saitirthatravels.com/images/shirdi-to-mumbai-cabs.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Reliable and affordable taxi service from Shirdi to Mumbai. Book a cab for a comfortable journey, including one-way and round trips, to Mumbai Airport, Navi Mumbai, and more.",
            "url": "https://saitirthatravels.com/shirdi-to-mumbai-cabs",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/shirdi-to-mumbai-cabs.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi to Mumbai Cab", 
                "Cab from Shirdi to Mumbai", 
                "Shirdi Mumbai Taxi Service", 
                "SaitirthaTours & Travels Shirdi", 
                "Taxi from Shirdi to Mumbai", 
                "Shirdi to Mumbai Car Rental", 
                "Shirdi to Mumbai Taxi Fare", 
                "Shirdi to Mumbai Cab Booking", 
                "Affordable Cab Shirdi to Mumbai", 
                "Best Cab for Shirdi to Mumbai", 
                "Shirdi to Mumbai Cab Rate", 
                "Shirdi to Mumbai Cab", 
                "Cab from Mumbai to Shirdi", 
                "Shirdi to Mumbai Cab One Way", 
                "Shirdi to Mumbai Cab Service", 
                "Shirdi to Mumbai Airport Cab", 
                "Shirdi to Mumbai International Airport Cab Service", 
                "Shirdi to Navi Mumbai Cab Service", 
                "Shirdi to Thane Cab Booking", 
                "Shirdi to Panvel Cab Booking", 
                "Shirdi to Siddhivinayak Taxi Fare", 
                "Shirdi to Dadar Cab Service", 
                "Shirdi to Navi Kurla Cab Service", 
                "Shirdi to Mumbai Airport Cab", 
                "Shirdi to Mumbai Cab One Way", 
                "Shirdi to Mumbai Drop Taxi", 
                "Shirdi Saibaba Travels"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/20.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirditomumbai;