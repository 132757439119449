
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Seaterbuses() {



  const cardData =
  {
    keyword: ' 50 Seater Bus on Rent in Shirdi',
    heading: 'Saitirtha Travels: 50 Seater Bus on Rent in Shirdi',
    headingDescription: 'Traveling in a large group? Our 50-seater bus rental service in Shirdi is the perfect solution for corporate events, family gatherings, weddings, or pilgrimages. Saitirtha Travels offers comfortable, spacious buses equipped with modern amenities to ensure a smooth and enjoyable journey for all passengers.',

    top: 'Top Places to Visit in Shirdi with Saitirtha Travels',

    topPlaces: [
        {
            "title": "Sai Baba Temple",
            "description": "The iconic shrine of Sai Baba, where millions of devotees gather for prayers. Experience the serenity and devotion that permeates the atmosphere, especially during the aarti ceremonies."
        },
        {
            "title": "Dwarkamai",
            "description": "A historic mosque where Sai Baba spent much of his life. The sacred Dhuni (eternal flame) is a significant aspect of this site, symbolizing the teachings of Sai Baba."
        },
        {
            "title": "Chavadi",
            "description": "A small yet significant place where Sai Baba would rest. This site offers a glimpse into his life and teachings, making it a meaningful stop for devotees."
        },
        {
            "title": "Lendi Garden",
            "description": "A serene garden that was once tended by Sai Baba. It is a peaceful spot for visitors to relax and reflect amidst beautiful greenery."
        },
        {
            "title": "Shri Khandoba Mandir",
            "description": "Located on a hill, this ancient temple dedicated to Lord Khandoba is a favorite among pilgrims. It hosts vibrant festivals and offers panoramic views of the surroundings."
        },
        {
            "title": "Gurusthan",
            "description": "The revered site where Sai Baba first became known. The shrine here holds deep spiritual significance and attracts many visitors."
        },
        {
            "title": "Sai Heritage Village",
            "description": "A cultural complex that showcases the life and teachings of Sai Baba through various exhibits, offering an educational experience for all ages."
        },
        {
            "title": "Maruti Mandir",
            "description": "This temple dedicated to Lord Hanuman is known for its peaceful ambiance and is a popular spot for devotees."
        },
        {
            "title": "Khandoba Temple",
            "description": "An ancient temple that attracts many devotees, especially during festivals. Its scenic location adds to its charm."
        },
        {
            "title": "Sainath Mahal",
            "description": "A convenient place for travelers to stay, located near major attractions. It offers comfortable accommodation and amenities for a relaxing stay."
        }
    ],    
    
            
        
"services": [
    {
        "name": "50 Seater Bus on Rent in Shirdi",
        "description": "Looking for spacious and comfortable transportation for your group? Our 50-seater bus on rent in Shirdi is the perfect solution for weddings, corporate events, local trips, and more. Experience hassle-free travel with ample seating and modern amenities."
    },
    {
        "name": "50 Seater Bus on Rent in Ahmednagar",
        "description": "We also offer a 50-seater bus rental service in Ahmednagar, catering to various travel needs and ensuring comfortable journeys for larger groups. Perfect for outings and celebrations, our buses are equipped for your convenience."
    },
    {
        "name": "50 Seater Bus on Rent for Marriage in Ahmednagar",
        "description": "Make your wedding transportation seamless with our 50-seater bus rental service in Ahmednagar. We provide spacious and luxurious buses to accommodate your guests, ensuring everyone arrives on time and in style."
    },
    {
        "name": "50 Seater Bus on Rent for Marriage in Shirdi",
        "description": "Planning a wedding in Shirdi? Our 50-seater bus service is ideal for transporting guests, providing comfort and ease during one of the most important days of your life."
    },
    {
        "name": "50 Seater Bus on Rent in Shirdi Airport",
        "description": "Traveling to or from Shirdi Airport? Rent our 50-seater bus for convenient airport transfers. Enjoy a comfortable ride to your destination, whether it's a hotel, temple, or any other location."
    },
    {
        "name": "50 Seater Bus on Rent for Corporate Events in Shirdi",
        "description": "Our 50-seater bus is an excellent choice for corporate events in Shirdi. Whether it's a conference, meeting, or team-building activity, we ensure reliable transportation for your employees."
    },
    {
        "name": "Bus Rental for Corporate Events in Shirdi",
        "description": "Looking for bus rental options for your corporate functions? We offer buses tailored for corporate needs, ensuring punctuality and comfort for all participants."
    },
    {
        "name": "Bus on Rent Pune for Trip Picnic in Shirdi",
        "description": "Planning a picnic or trip from Pune to Shirdi? Our 50-seater buses are available for rent, providing comfortable transportation for your entire group, allowing everyone to relax and enjoy the journey."
    },
    {
        "name": "Bus on Rent in Shirdi for Local Trip",
        "description": "Explore Shirdi and its surroundings with our local bus rental service. Perfect for sightseeing, our 50-seater buses provide ample space for a fun and enjoyable day out."
    },
    {
        "name": "50 Seater Bus on Rent Shirdi to Bhima Bhimashankar",
        "description": "Visit the scenic Bhima Bhimashankar with our 50-seater bus service. Enjoy a comfortable journey with your group while exploring this beautiful location."
    },
    {
        "name": "50 Seater Bus on Rent Shirdi to Ajanta Ellora",
        "description": "Travel to the renowned Ajanta and Ellora Caves with our spacious bus. Ideal for group excursions, our service ensures a pleasant and memorable experience."
    },
    {
        "name": "50 Seater Bus on Rent Shirdi to Trimbakeshwar",
        "description": "Make your pilgrimage to Trimbakeshwar more comfortable with our 50-seater bus service. Enjoy a relaxed journey with your family or friends while visiting this sacred site."
    },
    {
        "name": "50 Seater Bus Hire in Shirdi",
        "description": "Whether for events, tours, or local travel, our 50-seater bus hire service in Shirdi guarantees satisfaction. We prioritize customer service and vehicle maintenance for all our rentals."
    },
    {
        "name": "Full Bus Hire on Rent in Shirdi",
        "description": "Choose full bus hire in Shirdi for any large group transportation needs. We offer flexible rental options to accommodate your schedule and itinerary."
    },
    {
        "name": "SaitirthaTours & Travels",
        "description": "Select SaitirthaTours & Travels for your bus rental needs in Shirdi. We pride ourselves on providing reliable transportation services, ensuring a smooth travel experience for all our clients."
    },
    {
        "name": "50 Seater Bus on Rent in Shirdi Contact Information",
        "description": "For bookings or more information, please contact SaitirthaTours & Travels at +91 9923879009 / +91 9922479009. Let us take care of your travel arrangements and provide you with an unforgettable journey!"
    }
],

tableData: [
    ["- 50 Seater Bus on Rent in Shirdi", "- 50 Seater Bus on Rent in Ahmednagar"],
    ["- 50 Seater Bus on Rent for Marriage in Ahmednagar", "- 50 Seater Bus on Rent for Marriage in Shirdi"],
    ["- 50 Seater Bus on Rent in Shirdi Airport", "- 50 Seater Bus on Rent for Corporate Events in Shirdi"],
    ["- Bus Rental for Corporate Events in Shirdi", "- Bus on Rent Pune for Trip Picnic in Shirdi"],
    ["- Bus on Rent in Shirdi for Local Trip", "- 50 Seater Bus on Rent Shirdi to Bhimashankar"],
    ["- 50 Seater Bus on Rent Shirdi to Ajanta Ellora", "- 50 Seater Bus on Rent Shirdi to Trimbakeshwar"],
    ["- 50 Seater Bus Hire in Shirdi", "- Full Bus Hire on Rent in Shirdi"],
    ["- SaitirthaTours & Travels Shirdi"]
  ],
  whychoose: [
    {
      "WhyChooseheading": "Spacious Accommodation",
      "WhyChoosedescription": "Our 50-seater buses offer generous space to accommodate large groups, ensuring that everyone can travel comfortably. Whether you're going for a family gathering, a corporate event, or a wedding celebration, the ample space inside these buses ensures that no one feels cramped. It’s the perfect solution for group travel, allowing you to enjoy the journey together with ease."
    },
    {
      "WhyChooseheading": "Comfortable Seating",
      "WhyChoosedescription": "Our buses come equipped with comfortable reclining seats that provide excellent legroom for all passengers. The seating is designed for maximum comfort during long journeys, allowing you and your group to relax and enjoy the trip. Whether you're traveling for a few hours or a full day, our buses ensure a pleasant travel experience for everyone."
    },
    {
      "WhyChooseheading": "Cost-Effective for Groups",
      "WhyChoosedescription": "Renting a 50-seater bus is a budget-friendly option for transporting large groups. By choosing a single vehicle for your group, you can save money compared to hiring multiple cars or smaller vehicles. This makes it a highly economical solution for corporate outings, family tours, weddings, or any large group event, reducing overall costs and simplifying logistics."
    },
    {
      "WhyChooseheading": "Experienced Drivers",
      "WhyChoosedescription": "Our drivers are not only skilled but also well-acquainted with the local routes, traffic conditions, and the best paths to popular destinations around Shirdi. They ensure that you travel safely and on schedule, and they are committed to providing excellent customer service throughout your journey. With their experience, you can relax and focus on your trip without worrying about navigation."
    },
    {
      "WhyChooseheading": "Flexible Rental Options",
      "WhyChoosedescription": "We understand that every group has unique needs, which is why we offer flexible rental options. Whether you’re organizing a local sightseeing tour, a corporate event, or planning an outstation trip, we can tailor our services to suit your itinerary. You can book our 50-seater buses for a day, multiple days, or a one-way trip, ensuring that your travel plans are fully accommodated."
    },
    {
      "WhyChooseheading": "Well-Maintained Fleet",
      "WhyChoosedescription": "We take great pride in the upkeep of our fleet. Our 50-seater buses undergo regular maintenance to ensure they are in top condition for every journey. This includes checking safety features, mechanical components, and the overall cleanliness of the vehicle. When you travel with us, you can rest assured that you're in a reliable and well-maintained vehicle."
    },
    {
      "WhyChooseheading": "On-Time Departure",
      "WhyChoosedescription": "Timeliness is one of our top priorities. We understand that your time is valuable, so we ensure that our buses are punctual for every departure and arrival. Our drivers are trained to manage time efficiently, ensuring you stay on schedule throughout your trip, whether it's for a wedding, corporate event, or sightseeing tour."
    },
    {
      "WhyChooseheading": "Ample Luggage Space",
      "WhyChoosedescription": "Our 50-seater buses are equipped with ample luggage storage space, allowing you to bring along all your belongings without any hassle. Whether you're traveling for a wedding, corporate event, or a group vacation, the bus provides enough room to store your luggage, ensuring a comfortable and stress-free journey for all passengers."
    },
    {
      "WhyChooseheading": "24/7 Customer Support",
      "WhyChoosedescription": "Our dedicated customer support team is available 24/7 to assist you with bookings, inquiries, or any last-minute changes. We are committed to ensuring that your experience with us is smooth and hassle-free from start to finish. Whether you need to modify your booking or have a special request, our team is always ready to assist you."
    },
    {
      "WhyChooseheading": "Hygiene and Safety",
      "WhyChoosedescription": "Passenger safety and hygiene are our top priorities. We adhere to strict cleaning protocols, ensuring that our buses are thoroughly sanitized between each rental. From disinfecting seats, handles, and high-touch surfaces to ensuring proper ventilation, we maintain the highest standards of cleanliness to provide you with a safe and comfortable travel experience."
    }
  ]
  



        
  }


  const faqData = [
    {
        question: "What is the rental cost for a 50-seater bus in Shirdi?",
        answer: "The rental price varies based on duration, distance, and specific requirements. Contact us for a customized quote."
    },
    {
        question: "Can the bus be hired for outstation trips?",
        answer: "Yes, our 50-seater bus is available for both local and outstation travel."
    },
    {
        question: "Is a driver included in the rental?",
        answer: "Yes, all rentals come with a professional driver to ensure your comfort and safety."
    },
    {
        question: "Are fuel charges included in the rental price?",
        answer: "Fuel costs are typically separate, but we can offer packages that include fuel for your convenience."
    },
    {
        question: "Can I make changes to my booking after it's confirmed?",
        answer: "Yes, you can modify your booking based on availability. Please contact us for assistance."
    },
    {
        question: "How many passengers can the bus accommodate?",
        answer: "Our 50-seater bus comfortably seats up to 50 passengers, making it ideal for large groups."
    },
    {
        question: "Is there enough space for luggage?",
        answer: "Yes, there is ample storage for luggage, ensuring all your belongings can be accommodated."
    },
    {
        question: "What are the cancellation policies?",
        answer: "We offer flexible cancellation terms. Please reach out for specific details."
    },
    {
        question: "Do you offer discounts for long-term rentals?",
        answer: "Yes, we provide special rates for extended rental periods. Inquire for more details."
    },
    {
        question: "Are pickup and drop-off services available?",
        answer: "Yes, we offer convenient pickup and drop-off services within Shirdi and surrounding areas."
    }
];

const testimonials = [
    {
        name: "Mr. Deepak Nair",
        text: "Hiring the 50 seater bus from Saitirtha Travels for our corporate trip to Shirdi was a fantastic experience! The bus was comfortable and well-equipped, which made our journey enjoyable. The staff was professional and ensured everything went smoothly. I highly recommend Saitirtha Travels for large group travel!"
    },
    {
        name: "Ms. Kavita Sharma",
        text: "Our group trip to Shirdi was made even better by renting the 50 seater bus from Saitirtha Travels. The bus was spacious and clean, providing a comfortable ride for all of us. The service was excellent, and the team was very accommodating to our needs. I will definitely choose Saitirtha Travels for future group travels!"
    }
];




  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | 50 Seater Bus on Rent in Shirdi | Best Bus Rental Service | SaitirthaTours & Travels</title>
    <meta name="description" content="Hire a 50-seater bus in Shirdi for weddings, corporate events, local trips, and pilgrimages. Available for trips to Bhimashankar, Ajanta Ellora, Trimbakeshwar, and more with SaitirthaTours & Travels." />
    <meta property="og:title" content="Contact: +91 9923879009 | SaitirthaTours & Travels | 50 Seater Bus on Rent in Shirdi | Best Bus Rental Service | SaitirthaTours & Travels" />
    <meta property="og:description" content="Hire a 50-seater bus in Shirdi for weddings, corporate events, local trips, and pilgrimages. Available for trips to Bhimashankar, Ajanta Ellora, Trimbakeshwar, and more with SaitirthaTours & Travels." />
    <meta property="og:url" content="https://saitirthatravels.com/50-seater-bus-rent-shirdi" />
    <meta property="og:image" content="https://saitirthatravels.com/images/50-seater-bus-shirdi.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Hire a 50-seater bus in Shirdi for weddings, corporate events, local trips, and pilgrimages. Available for trips to Bhimashankar, Ajanta Ellora, Trimbakeshwar, and more with SaitirthaTours & Travels.",
            "url": "https://saitirthatravels.com/50-seater-bus-rent-shirdi",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/50-seater-bus-shirdi.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.9",
                "reviewCount": "350"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "50 Seater Bus on Rent in Shirdi",
                "50 Seater Bus on Rent in Ahmednagar",
                "50 Seater Bus on Rent for Marriage in Ahmednagar",
                "50 Seater Bus on Rent for Marriage in Shirdi",
                "50 Seater Bus on Rent in Shirdi Airport",
                "50 Seater Bus on Rent for Corporate Events in Shirdi",
                "Bus Rental for Corporate Events in Shirdi",
                "Bus on Rent Pune for Trip Picnic in Shirdi",
                "Bus on Rent in Shirdi for Local Trip",
                "50 Seater Bus on Rent Shirdi to Bhimashankar",
                "50 Seater Bus on Rent Shirdi to Ajanta Ellora",
                "50 Seater Bus on Rent Shirdi to Trimbakeshwar",
                "50 Seater Bus Hire in Shirdi",
                "Full Bus Hire on Rent in Shirdi"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/78.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Seaterbuses;