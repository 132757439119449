
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Shirditopune() {



  const cardData =
  {
    keyword: '  Shirdi to Pune Cab ',
    heading: 'Saitirtha Travels: Shirdi to Pune Cab ',
    headingDescription: 'When it comes to traveling from Shirdi to Pune, Saitirtha Travels offers premier cab services that cater to your travel needs. We believe that every journey should be a delightful experience, and our focus on comfort and reliability ensures that you have a smooth ride. Whether you are visiting for business, leisure, or to explore the cultural richness of Pune, we are here to make your trip memorable.',

    top: 'Top Places to Visit in Pune with Saitirtha Travels',

    topPlaces: [
        {
            "title": "1. Aga Khan Palace",
            "description": "This historical landmark, built in 1892, is known for its stunning architecture and significant role in India’s freedom struggle. The palace served as a prison for Mahatma Gandhi and his followers during the Quit India Movement. Today, it houses a museum showcasing artifacts related to Gandhi's life and Indian independence. The beautiful gardens surrounding the palace provide a serene environment for visitors to reflect on history."
        },
        {
            "title": "2. Shaniwar Wada",
            "description": "A majestic fortification built in 1732, Shaniwar Wada is a symbol of Pune’s rich Maratha history. Visitors can explore its grand entrance, impressive walls, and beautiful gardens. The light and sound show in the evenings narrates the fort's fascinating history, making it a must-visit for history enthusiasts."
        },
        {
            "title": "3. Saras Baug",
            "description": "A picturesque garden located in the heart of the city, Saras Baug is known for its serene ambiance and lush greenery. The garden features a temple dedicated to Lord Ganesha, attracting devotees and visitors alike. It’s an ideal spot for families and couples to enjoy a peaceful day amidst nature."
        },
        {
            "title": "4. Dagdusheth Halwai Ganapati Temple",
            "description": "This iconic temple, dedicated to Lord Ganesha, is renowned for its exquisite architecture and vibrant celebrations during Ganesh Chaturthi. The temple is adorned with intricate decorations and offers a peaceful atmosphere for worship. Visitors come from all over to seek blessings and experience the divine energy of this sacred site."
        },
        {
            "title": "5. Pune Okayama Friendship Garden",
            "description": "Inspired by the Japanese garden style, this tranquil garden offers a serene escape from the bustling city. The beautifully landscaped area features ponds, waterfalls, and a variety of plant species. It’s an ideal location for nature lovers and those looking to unwind in a peaceful setting."
        },
        {
            "title": "6. Pataleshwar Cave Temple",
            "description": "A rock-cut cave temple dedicated to Lord Shiva, Pataleshwar is an ancient site dating back to the 8th century. The temple features a large, intricately carved Shiva Linga and is a great place to explore the architectural prowess of ancient India. The surrounding area offers a calm atmosphere, perfect for meditation and reflection."
        },
        {
            "title": "7. National War Museum",
            "description": "This museum is dedicated to honoring the soldiers of the Indian Armed Forces. The exhibits include military artifacts, weapons, and displays that narrate the valor and sacrifices made by soldiers throughout history. It serves as an educational experience for visitors of all ages, showcasing India's rich military heritage."
        },
        {
            "title": "8. Khadakwasla Dam",
            "description": "Located a short drive from Pune, Khadakwasla Dam is a popular picnic spot. The dam offers stunning views of the water and surrounding hills, making it an ideal place for photography and relaxation. Visitors can enjoy boating or simply take a leisurely walk along the dam while appreciating the natural beauty."
        },
        {
            "title": "9. Lal Mahal",
            "description": "This historical palace was built in 1630 and served as the residence of Chhatrapati Shivaji Maharaj during his childhood. The Lal Mahal features beautiful architecture and offers a glimpse into the life of Shivaji Maharaj. Visitors can explore the premises and learn about the rich history of the Maratha Empire."
        },
        {
            "title": "10. Bund Garden",
            "description": "A well-maintained garden located along the Mula River, Bund Garden is a popular spot for jogging, picnics, and leisurely walks. The garden is lined with trees and colorful flowers, providing a peaceful environment for relaxation. It also features a small boating area, adding to its charm as a recreational spot."
        }
    ],        
        
    
    services: [
        {
            "name": "Shirdi to Pune Cab Service",
            "description": "When traveling from Shirdi to Pune, our cab service is your best option for reliable, comfortable, and affordable transportation. SaitirthaTours & Travels is dedicated to ensuring that your journey is as smooth as possible. Our fleet consists of modern, well-maintained vehicles equipped with air conditioning and safety features. Our professional drivers are not only skilled at navigating the roads but also possess extensive knowledge of the area, guaranteeing a pleasant and efficient trip. Whether you are visiting for business or leisure, we prioritize your comfort and safety."
        },
        {
            "name": "Shirdi Pune Taxi Service",
            "description": "Our Shirdi Pune taxi service is designed to meet the diverse needs of travelers. We offer a range of vehicles suitable for solo travelers, families, and groups. Each taxi is equipped with modern amenities to enhance your travel experience. Our drivers ensure timely pickups and drop-offs, making your journey hassle-free. With a focus on customer satisfaction, we strive to make your travel experience memorable from start to finish."
        },
        {
            "name": "Shirdi to Pune Cab Fare",
            "description": "Understanding the Shirdi to Pune cab fare is essential for effective trip planning. At SaitirthaTours & Travels, we offer competitive pricing without compromising on quality. Our fare structure is transparent, with no hidden costs, so you can budget effectively. Whether you’re traveling for a pilgrimage or business, our pricing reflects our commitment to providing value for money. We also offer special discounts for early bookings and group travel, ensuring that you get the best deal possible."
        },
        {
            "name": "Shirdi Pune Car Rental",
            "description": "For travelers who wish to explore Pune at their own pace, our Shirdi Pune car rental services provide the ideal solution. Choose from a wide selection of vehicles tailored to your travel needs, whether you require a compact car for solo adventures or a spacious vehicle for family trips. Our rental services come with flexible rental agreements and comprehensive insurance coverage, allowing you to drive with peace of mind while enjoying your journey."
        },
        {
            "name": "SaitirthaTours & Travels Shirdi",
            "description": "At SaitirthaTours & Travels Shirdi, we pride ourselves on our exceptional customer service. Our team is dedicated to ensuring that every aspect of your journey is taken care of. From the moment you book your cab to the time you reach your destination, we are here to assist you. Our professional drivers are trained to handle all types of travel scenarios, providing you with a seamless and enjoyable experience."
        },
        {
            "name": "Shirdi to Pune One Way Taxi",
            "description": "Traveling one way from Shirdi to Pune? Our one-way taxi option is designed for those who need a straightforward and cost-effective solution. With this service, you can enjoy the flexibility of booking a ride that fits your schedule without the need for a return journey. Our one-way trips are just as comfortable and reliable as our round-trip services, ensuring that you enjoy a pleasant experience."
        },
        {
            "name": "Shirdi to Pune Cab Booking",
            "description": "Booking your Shirdi to Pune cab is quick and easy with our user-friendly online platform. Reserve your taxi in advance to secure your transportation and enjoy a stress-free journey. Our booking process allows you to choose your preferred vehicle type, select your pick-up time, and even customize your ride with additional services. Our customer support team is available 24/7 to assist you with any questions or changes to your booking."
        },
        {
            "name": "Shirdi to Pune Travel by Taxi",
            "description": "Choosing to travel from Shirdi to Pune by taxi allows you to relax and enjoy the scenic views along the way without the stress of navigating unfamiliar roads. Our experienced drivers know the best routes to avoid traffic and ensure a timely arrival. Whether you're traveling for a spiritual visit or exploring the cultural sights of Pune, our taxi service offers comfort and convenience every step of the way."
        },
        {
            "name": "Shirdi to Pune Cab Rates",
            "description": "Our Shirdi to Pune cab rates are competitive and designed to accommodate a variety of budgets. We offer different packages and options to ensure everyone can access our quality services. Whether you require a basic cab or a luxury vehicle, we provide a range of pricing options. For your convenience, we ensure our rates are clear upfront, with no surprises on your final bill."
        },
        {
            "name": "Shirdi to Pune Taxi Fare",
            "description": "For a clear understanding of our Shirdi to Pune taxi fare, we provide detailed information before you book. Our fares are competitive and reflect the high level of service we offer. You can count on us for transparent pricing that helps you plan your budget effectively. Our commitment to affordability ensures that you receive the best value for your journey."
        },
        {
            "name": "Affordable Shirdi Pune Cab",
            "description": "Looking for an affordable Shirdi Pune cab option? Our services are designed to provide excellent value for money without compromising comfort or safety. We understand that cost-effective travel solutions are important for our customers, and we work hard to maintain competitive pricing while delivering a premium travel experience."
        },
        {
            "name": "Best Cab Service from Shirdi to Pune",
            "description": "We strive to be recognized as the best cab service from Shirdi to Pune. Our commitment to customer satisfaction, punctuality, and quality service sets us apart in the industry. Our drivers are trained to provide exceptional service, ensuring a comfortable and enjoyable ride. Experience the difference with SaitirthaTours & Travels, where your journey is our priority."
        },
        {
            "name": "Pune to Shirdi Cab",
            "description": "If you're traveling in the opposite direction, our Pune to Shirdi cab service is equally reliable and comfortable. We offer the same high standards of service for travelers heading from Pune to Shirdi, ensuring that your return journey is as pleasant as the trip out. Whether for spiritual purposes or leisure, we cater to all your travel needs."
        },
        {
            "name": "Pune to Shirdi Taxi",
            "description": "Our Pune to Shirdi taxi service guarantees a seamless travel experience for all passengers. We provide reliable transportation that caters to your schedule and preferences. Whether you’re visiting the Sai Baba Temple or exploring local attractions, we have the right solution for you."
        },
        {
            "name": "Pune to Shirdi Cab Package",
            "description": "For an all-inclusive travel experience, consider our Pune to Shirdi cab package. This option includes round-trip transportation with additional perks to enhance your journey. Enjoy the convenience of bundled services that cater to your travel needs while ensuring a smooth and enjoyable experience."
        },
        {
            "name": "Pune to Shirdi Cab Charges",
            "description": "We provide clear information regarding Pune to Shirdi cab charges to help you budget effectively for your trip. Our pricing is competitive and straightforward, ensuring that you can plan your travel expenses without any surprises. With us, you can trust that you are receiving high-quality service at a fair price."
        },
        {
            "name": "Shirdi to Pune Airport Cab",
            "description": "Need a ride to the airport? Our Shirdi to Pune airport cab service guarantees timely arrivals, giving you peace of mind for your travels. Our drivers are familiar with the best routes and monitor flight schedules to ensure you reach the airport well in advance. Enjoy a hassle-free ride with our attentive drivers who assist you with your luggage and any special requests."
        },
        {
            "name": "Shirdi to Pune Cab Price",
            "description": "We offer competitive Shirdi to Pune cab prices designed to cater to various budgets. Our commitment to affordability ensures that every traveler can access our premium services without overspending. We believe in providing transparency in pricing, so you can focus on enjoying your journey without worrying about hidden costs."
        },
        {
            "name": "Shirdi Pune Cab",
            "description": "Experience the convenience and reliability of our Shirdi Pune cab services. We prioritize customer comfort and safety, ensuring a pleasant journey between these two popular locations. With SaitirthaTours & Travels, your travel experience is enhanced by our commitment to excellence and customer care."
        },
        {
            "name": "Shirdi Pune Taxi",
            "description": "Our Shirdi Pune taxi services are designed to meet the diverse needs of our clients. From airport transfers to local sightseeing trips, we cover it all with reliability and professionalism. Our experienced drivers are trained to provide excellent service, making your journey enjoyable and efficient."
        },
        {
            "name": "Shirdi to Pune Cab One Way",
            "description": "For those looking for a straightforward journey, our Shirdi to Pune cab one way service offers the ideal solution. With our easy booking process and excellent customer service, your trip is in good hands. Enjoy the flexibility of traveling one way while benefiting from our quality service."
        },
        {
            "name": "Shirdi to Pune One Way Cab",
            "description": "Our Shirdi to Pune one way cab option is perfect for travelers seeking flexibility and convenience. With competitive rates and comfortable rides, we ensure a pleasant travel experience. This service is ideal for those who require a one-way trip without the need for a return journey."
        },
        {
            "name": "Shirdi to Pune Car",
            "description": "Choose our Shirdi to Pune car services for a stress-free travel experience. Our drivers are trained to provide top-notch service, ensuring you arrive at your destination feeling relaxed. We maintain our vehicles to the highest standards, guaranteeing a safe and comfortable ride."
        },
        {
            "name": "Shirdi to Pune Transport",
            "description": "For those in need of reliable transport from Shirdi to Pune, we provide comprehensive transportation services tailored to your needs. Whether you're traveling alone or with a group, our services are designed to accommodate all travel preferences, ensuring you have a smooth experience from start to finish."
        },
        {
            "name": "Shirdi to Pune Car Booking",
            "description": "Book your Shirdi to Pune car effortlessly with our online reservation system. We offer a variety of vehicles to suit your travel requirements, along with transparent pricing. Our user-friendly booking process allows you to customize your trip according to your preferences, ensuring a personalized travel experience."
        },
        {
            "name": "Affordable Shirdi to Pune Cab",
            "description": "Discover affordable Shirdi to Pune cab options that don't compromise on quality. At SaitirthaTours & Travels, we believe in providing value for money while ensuring your travel experience is comfortable and enjoyable. Our competitive rates make it easier for you to travel without overspending."
        }
    ],    
    "tableData": [
        ["- Shirdi Pune Taxi Service", "- Shirdi to Pune Cab Fare"],
        ["- Shirdi Pune Car Rental", "- SaitirthaTours & Travels Shirdi"],
        ["- Shirdi to Pune One Way Taxi", "- Shirdi to Pune Cab Booking"],
        ["- Shirdi to Pune Travel by Taxi", "- Shirdi to Pune Cab Rates"],
        ["- Shirdi to Pune Taxi Fare", "- Affordable Shirdi Pune Cab"],
        ["- Best Cab Service from Shirdi to Pune", "- Pune to Shirdi Cab"],
        ["- Pune to Shirdi Taxi", "- Pune to Shirdi Cab Package"],
        ["- Pune to Shirdi Cab Charges", "- Shirdi to Pune Airport Cab"],
        ["- Shirdi to Pune Cab Price", "- Shirdi to Pune Taxi Fare"],
        ["- Shirdi Pune Cab", "- Shirdi Pune Taxi"],
        ["- Shirdi to Pune Cab Fare", "- Shirdi to Pune Cab One Way"],
        ["- Shirdi to Pune One Way Cab", "- Shirdi to Pune Car"],
        ["- Shirdi to Pune Cab Service", "- Taxi Service from Shirdi to Pune"]
    ],
    "whychoose": [
        {
            "WhyChooseheading": "Punctual and Reliable Service:",
            "WhyChoosedescription": "We prioritize timeliness, ensuring you reach your destination in Pune promptly and safely. Our drivers are trained to follow optimal routes and manage travel times effectively, allowing you to relax and enjoy the journey without the stress of delays. With a focus on punctuality, we aim to enhance your travel experience and make sure you arrive on time for any appointments or activities."
        },
        {
            "WhyChooseheading": "Experienced Drivers:",
            "WhyChoosedescription": "Our professional drivers are well-versed in the Shirdi to Pune route, providing you with a smooth and efficient travel experience. They possess extensive local knowledge, allowing them to navigate traffic and identify the best stops along the way, enhancing your travel experience. Our drivers also prioritize your comfort and safety, ensuring a pleasant ride throughout your journey."
        },
        {
            "WhyChooseheading": "Comfortable and Clean Vehicles:",
            "WhyChoosedescription": "Enjoy your journey in our well-maintained and sanitized vehicles, designed for your comfort on long drives. Each vehicle is equipped with comfortable seating, climate control, and ample space for your luggage, ensuring a pleasant ride from start to finish. We also regularly clean and sanitize our vehicles to uphold the highest hygiene standards, so you can travel with peace of mind."
        },
        {
            "WhyChooseheading": "Transparent Pricing:",
            "WhyChoosedescription": "We offer clear and competitive pricing with no hidden fees, allowing you to plan your travel budget effectively. Our pricing structure is straightforward, and we provide upfront quotes, so you can make informed decisions without worrying about unexpected costs. We strive to provide the best value for your money, ensuring you receive top-notch service at a fair price."
        },
        {
            "WhyChooseheading": "Easy Booking Process:",
            "WhyChoosedescription": "Booking a cab from Shirdi to Pune is hassle-free, with multiple options available online or through our customer service. Our user-friendly booking platform allows you to select your travel date, vehicle type, and other preferences in just a few clicks, making your travel planning efficient and convenient. We also offer flexible payment options, so you can choose what works best for you."
        },
        {
            "WhyChooseheading": "24/7 Customer Support:",
            "WhyChoosedescription": "Our dedicated team is available around the clock to assist you with any inquiries or support you may need during your journey. Whether you need help with bookings, route changes, or any other questions, our friendly and knowledgeable customer support staff is ready to help you at any time, ensuring your peace of mind throughout your trip. We pride ourselves on our responsiveness and commitment to customer satisfaction."
        },
        {
            "WhyChooseheading": "Wide Range of Vehicle Options:",
            "WhyChoosedescription": "We offer a diverse selection of vehicles to accommodate different group sizes and travel needs. Whether you need a compact car for solo travel or a spacious SUV for a family trip, we have options that cater to your specific requirements. Our vehicles are equipped with modern amenities to ensure your comfort, making your journey enjoyable no matter the distance."
        },
        {
            "WhyChooseheading": "Local Insights and Recommendations:",
            "WhyChoosedescription": "Our drivers are local experts who can provide valuable insights into places to visit and things to do in Pune. They can recommend popular attractions, dining options, and hidden gems that you might not find in guidebooks, enriching your travel experience. This local knowledge enhances your journey, allowing you to explore Pune beyond the usual tourist spots."
        }
    ]
        
  }


  const faqData = [
    {
      question: "What is the fare for a cab from Shirdi to Pune?",
      answer: "The fare varies based on vehicle type and specific requests. Please contact us for the latest pricing information."
    },
    {
      question: "How long does the journey from Shirdi to Pune take?",
      answer: "The trip usually takes about 5 to 6 hours, depending on traffic conditions."
    },
    {
      question: "Are there any additional charges?",
      answer: "Additional charges may apply for nighttime travel (between 10 PM and 6 AM) or for any extra stops along the way."
    },
    {
      question: "How can I book a cab from Shirdi to Pune?",
      answer: "You can book your cab easily through our website or by contacting our customer support team for assistance."
    },
    {
      question: "What payment methods do you accept?",
      answer: "We accept various payment options, including credit/debit cards, UPI, and cash for your convenience."
    },
    {
      question: "Can I cancel my booking?",
      answer: "Yes, we offer flexible cancellation policies. Please review our cancellation terms on the website for more details."
    },
    {
      question: "Is the taxi sanitized before the trip?",
      answer: "Yes, all our vehicles are thoroughly cleaned and sanitized before every journey to ensure your safety."
    },
    {
      question: "Can I request additional stops during the journey?",
      answer: "Yes, you can request reasonable stops for refreshments or sightseeing along the route."
    }
  ];
  
  const testimonials = [
    {
      name: "Mr. Ravi Kulkarni",
      text: "I had a great experience traveling from Shirdi to Pune with Saitirtha Travels. The driver was professional and knew the best routes, making our journey smooth. The cab was comfortable, and I appreciated the transparent pricing. Highly recommend!"
    },
    {
      name: "Ms. Anjali Singh",
      text: "Saitirtha Travels provided excellent service for my trip from Shirdi to Pune. The booking process was simple, and our driver was friendly and informative. The ride was comfortable, and I felt safe throughout the journey. I will definitely use their services again!"
    }
  ];
  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Shirdi to Pune Cab | SaitirthaTours & Travels</title>
    <meta name="description" content="Book an affordable and reliable Shirdi to Pune cab with SaitirthaTours & Travels. Enjoy a comfortable journey with our taxi service, including one-way trips, airport transfers, and more." />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirtha Travels | Shirdi to Pune Cab | SaitirthaTours & Travels" />
    <meta property="og:description" content="Book an affordable and reliable Shirdi to Pune cab with SaitirthaTours & Travels. Enjoy a comfortable journey with our taxi service, including one-way trips, airport transfers, and more." />
    <meta property="og:url" content="https://saitirthatravels.com/shirdi-to-pune-cab" />
    <meta property="og:image" content="https://saitirthatravels.com/images/shirdi-to-pune-cab.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Book an affordable and reliable Shirdi to Pune cab with SaitirthaTours & Travels. Enjoy a comfortable journey with our taxi service, including one-way trips, airport transfers, and more.",
            "url": "https://saitirthatravels.com/shirdi-to-pune-cab",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/shirdi-to-pune-cab.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi Pune Taxi Service", 
                "Shirdi to Pune Cab Fare", 
                "Shirdi Pune Car Rental", 
                "SaitirthaTours & Travels Shirdi", 
                "Shirdi to Pune One Way Taxi", 
                "Shirdi to Pune Cab Booking", 
                "Shirdi to Pune Travel by Taxi", 
                "Shirdi to Pune Cab Rates", 
                "Shirdi to Pune Taxi Fare", 
                "Affordable Shirdi Pune Cab", 
                "Best Cab Service from Shirdi to Pune", 
                "Pune to Shirdi Cab", 
                "Pune to Shirdi Taxi", 
                "Pune to Shirdi Cab Package", 
                "Pune to Shirdi Cab Charges", 
                "Shirdi to Pune Airport Cab", 
                "Shirdi to Pune Cab Price", 
                "Shirdi to Pune Taxi Fare", 
                "Shirdi Pune Cab", 
                "Shirdi Pune Taxi", 
                "Shirdi to Pune Cab One Way", 
                "Shirdi to Pune One Way Cab", 
                "Shirdi to Pune Car", 
                "Shirdi to Pune Cab Service", 
                "Taxi Service from Shirdi to Pune"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/24.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirditopune;