import React, { useState } from 'react';

const Accordion = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {

    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
<div>
  {data.map((item, index) => (
    <div key={index}  style={{
      
      padding: '4px',

      
      }}>
      <div 
        style={{
          cursor: 'pointer',
          padding: '10px',
          border: '2px solid black',
          backgroundColor: '#EE1623',
          marginBottom: '0px',
          fontWeight: 'bold',
          color: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
        onClick={() => handleToggle(index)}
      >
        <span>{item.question}</span>
        <span>{activeIndex === index ? 'X' : '✓'}</span> {/* Change icons here */}
      </div>
      {activeIndex === index && (
        <div 
          style={{
            padding: '10px',
            border: '1px solid #34BAEB',
            marginBottom: '3px',
            borderTop: 'none',
            backgroundColor: 'lightgray'
          }}
        >
          {item.answer}
        </div>
      )}
    </div>
  ))}
</div>

  );
};

export default Accordion;
