
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Minibusonrentinshirdi() {



  const cardData =
  {
    keyword: 'Mini Bus on Rent in Shirdi',
    heading: 'Saitirtha Travels: Mini Bus on Rent in Shirdi',
    headingDescription: 'For larger groups traveling to Shirdi, Saitirtha Travels offers mini buses for rent, ensuring that everyone can travel together comfortably. Our mini buses are ideal for family outings, corporate events, or group pilgrimages. With ample space and modern amenities, we guarantee a smooth and enjoyable journey.',

    top: 'Top Places to Visit in Shirdi with Saitirtha Travels',

 "topPlaces": [
    {
        "title": "Shri Sai Baba Samadhi Mandir",
        "description": "The Shri Sai Baba Samadhi Mandir is the main spiritual attraction in Shirdi, dedicated to the beloved saint Sai Baba. This magnificent temple showcases stunning architecture, blending traditional Indian styles with a tranquil ambiance. Devotees from all over the world come to pay their respects, especially during the evening aarti, when the temple comes alive with devotional songs and prayers. The serene atmosphere, infused with spirituality, makes it a must-visit for anyone seeking solace and divine connection."
    },
    {
        "title": "Dwarkamai Masjid",
        "description": "Dwarkamai Masjid is a historic site that holds profound significance in the life of Sai Baba, where he lived and taught for many years. The masjid features a sacred fire that Sai Baba tended, symbolizing his teachings of love and spirituality. Visitors are enveloped in a spiritual ambiance, witnessing the devotion of fellow pilgrims who gather to offer their prayers. The peaceful surroundings, along with the presence of the sacred dhuni, create an environment conducive to reflection and connection with the divine."
    },
    {
        "title": "Chavadi",
        "description": "Chavadi is a spiritually significant place where Sai Baba rested every alternate night. This humble structure is adorned with offerings and is a focal point for evening prayers, which attract numerous devotees. The soulful atmosphere during these prayers creates a profound experience for visitors, encouraging reflection and worship. Chavadi serves as a reminder of Sai Baba's humble lifestyle and the deep faith of his followers, making it a cherished site for all who visit."
    },
    {
        "title": "Shri Sai Baba Museum",
        "description": "The Shri Sai Baba Museum is a treasure trove of artifacts and memorabilia related to Sai Baba’s life. This museum offers visitors valuable insights into his teachings and legacy, featuring photographs, manuscripts, and personal belongings that tell the story of his profound impact on spirituality. It is an ideal place to learn more about the saint, explore his philosophies, and understand the essence of his teachings that continue to inspire millions."
    },
    {
        "title": "Khandoba Temple",
        "description": "Khandoba Temple, an ancient shrine dedicated to Lord Khandoba, is perched atop a scenic hill, offering breathtaking views of the surrounding landscape. This temple is not only a popular pilgrimage destination but also a peaceful retreat for visitors seeking spiritual rejuvenation. The ambiance here is serene, especially during festive occasions when devotees gather to celebrate with fervor and devotion. The journey to the temple, coupled with its tranquil environment, makes it a delightful experience for all."
    },
    {
        "title": "Saptashrungi Devi Temple",
        "description": "Located a short drive from Shirdi, the Saptashrungi Devi Temple is nestled amidst picturesque hills and is dedicated to Goddess Saptashrungi. This temple is renowned for its serene surroundings, making it an ideal destination for worshippers and nature lovers alike. The tranquil atmosphere encourages visitors to engage in prayer and reflection, while the scenic beauty of the location enhances the spiritual experience. It's a perfect getaway for those looking to immerse themselves in nature and spirituality."
    },
    {
        "title": "Shirdi Village",
        "description": "Exploring Shirdi village allows visitors to immerse themselves in the local culture and heritage. The village is vibrant with life, where you can shop for unique handicrafts, sample local delicacies, and interact with warm-hearted locals who are proud of their traditions. Engaging with the community enriches your experience, offering a deeper understanding of the significance of Sai Baba in their lives and the cultural fabric of the region."
    },
    {
        "title": "Naldurg Fort",
        "description": "Naldurg Fort is a historic fort that offers a glimpse into the region's rich past. With its impressive architecture and strategic design, the fort provides breathtaking views of the surrounding landscape. It is a perfect destination for adventure seekers and history enthusiasts alike, as visitors can explore its expansive grounds and learn about its historical significance. The combination of history and scenic beauty makes Naldurg Fort a must-visit site for anyone traveling in the area."
    },
    {
        "title": "Bhimashankar Wildlife Sanctuary",
        "description": "Bhimashankar Wildlife Sanctuary, located a short trip from Shirdi, is a paradise for nature enthusiasts. The sanctuary boasts a diverse range of flora and fauna, making it an excellent spot for trekking and wildlife spotting. Visitors can immerse themselves in the natural beauty of the area while enjoying the tranquility it offers. The sanctuary is particularly famous for the endangered Giant Indian Squirrel, making it a significant destination for wildlife lovers looking to experience the region's biodiversity."
    },
    {
        "title": "Aurangabad Caves",
        "description": "The Aurangabad Caves, located nearby, are famous for their ancient rock-cut architecture, featuring impressive sculptures and intricate carvings. These caves date back to the 2nd to 6th centuries and provide a fascinating glimpse into India’s rich artistic heritage. Visitors can explore the various caves, each adorned with exquisite artwork that reflects the cultural and religious influences of the time. A trip to the Aurangabad Caves is not only an exploration of history but also an appreciation of the artistic brilliance of ancient civilizations."
    }
],
    
            
        
"services": [
    {
        "name": "Mini Bus on Rent in Shirdi",
        "description": "Traveling with a larger group? Our Mini Bus on Rent in Shirdi is the ideal solution for your transportation needs. Offering ample space and comfortable seating, our mini buses are perfect for family trips, corporate outings, or pilgrimage tours. Enjoy the convenience of traveling together without the hassle of coordinating multiple vehicles, making your journey more enjoyable and stress-free."
    },
    {
        "name": "Mini Bus Rental Shirdi",
        "description": "Our Mini Bus rental services in Shirdi cater to various group sizes and travel requirements. With options available for different seating capacities, you can select the right mini bus that fits your group's needs. Whether it’s a short trip or an extended journey, we ensure a smooth and enjoyable travel experience, allowing everyone to relax and enjoy the ride."
    },
    {
        "name": "Rent Mini Bus Shirdi",
        "description": "Renting a mini bus in Shirdi is easy and convenient with our user-friendly booking process. Our dedicated team provides exceptional customer service, ensuring that you have a hassle-free experience from start to finish. Choose from our fleet of well-maintained mini buses for your next adventure, and travel with peace of mind knowing that your vehicle is reliable."
    },
    {
        "name": "Mini Bus Hire Shirdi",
        "description": "When you opt for mini bus hire in Shirdi, expect reliability and comfort. Our mini buses are equipped with modern amenities, including air conditioning and comfortable seating, to enhance your journey. We cater to a variety of needs, from sightseeing tours to corporate events, ensuring that you have the best experience tailored to your group's preferences."
    },
    {
        "name": "Shirdi Mini Bus Rental Services",
        "description": "Our Shirdi mini bus rental services stand out for their quality and affordability. We offer competitive pricing without compromising service. Our mini buses are regularly inspected for safety and performance, ensuring you can travel without worries. Enjoy the comfort of a well-maintained vehicle as you explore Shirdi and its surroundings."
    },
    {
        "name": "Mini Coach Hire Shirdi",
        "description": "For larger groups, we offer mini coach hire in Shirdi. Our mini coaches are designed for comfort and convenience, providing a spacious environment for your travels. Perfect for school trips, family reunions, or business events, our mini coaches effectively accommodate your needs, allowing everyone to travel together comfortably."
    },
    {
        "name": "Affordable Mini Bus Rental Shirdi",
        "description": "Looking for affordable mini bus rental options in Shirdi? We provide budget-friendly rates without sacrificing quality. Our transparent pricing policy ensures you won’t encounter any hidden fees. Enjoy great value while traveling in comfort with your group, making your trips economical and enjoyable."
    },
    {
        "name": "Mini Bus on Hire Near Shirdi",
        "description": "Finding a mini bus on hire near Shirdi is quick and easy with our services. Whether you're planning a pilgrimage or a sightseeing trip, our mini buses are ready to take you wherever you need to go. We offer convenient pickup and drop-off options to enhance your travel experience and make your journey seamless."
    },
    {
        "name": "Shirdi Mini Bus Booking",
        "description": "Booking a mini bus in Shirdi is simple with our online reservation system. You can select your preferred vehicle, choose your travel dates, and confirm your booking within minutes. Our dedicated team is available to assist you with any questions or special requests, ensuring you have a personalized booking experience."
    },
    {
        "name": "Mini Bus Rental Agency Shirdi",
        "description": "As a reputable mini bus rental agency in Shirdi, we prioritize customer satisfaction. Our friendly staff is here to help you select the best vehicle for your needs, ensuring a smooth and enjoyable journey. We take pride in offering clean, comfortable, and well-maintained mini buses for all occasions."
    },
    {
        "name": "Best Mini Bus Rental Rates Shirdi",
        "description": "Explore the best mini bus rental rates in Shirdi with our competitive pricing. We offer flexible rental options to suit your budget and travel plans. Our goal is to provide affordable transportation without compromising on quality and service, making sure you get the most out of your travel experience."
    },
    {
        "name": "AC - Non AC Mini Bus Hire in Shirdi",
        "description": "We provide options for both AC and non-AC mini bus hire in Shirdi, allowing you to choose based on your comfort preferences and budget. Our AC mini buses ensure a cool and pleasant environment during your travels, while non-AC options are available for those seeking a more economical choice, ensuring all groups can find their perfect vehicle."
    },
    {
        "name": "Luxury Mini Bus On Rent in Shirdi",
        "description": "Experience travel in style with our luxury mini bus on rent in Shirdi. Ideal for special occasions or corporate events, our luxury mini buses come with high-end features and comfortable interiors to elevate your travel experience. Treat your group to a premium journey with our luxury options, ensuring a memorable outing."
    },
    {
        "name": "12, 16, 18, 20, 25, 30 Seater AC Mini Bus on Rent in Shirdi",
        "description": "We offer a variety of seating capacities to accommodate your group size, including 12, 16, 18, 20, 25, and 30-seater AC mini buses on rent in Shirdi. Whether you have a small family gathering or a large corporate group, we have the perfect vehicle for your needs, ensuring comfort for everyone on board."
    },
    {
        "name": "Shirdi to Ajanta Ellora Mini Bus On Rent",
        "description": "Planning a trip to the Ajanta Ellora caves? Our Shirdi to Ajanta Ellora mini bus on rent is the perfect choice for group travel. Enjoy a comfortable ride as you explore these UNESCO World Heritage sites, known for their stunning rock-cut architecture and historical significance. Let us handle the transport while you focus on the experience."
    },
    {
        "name": "Mini Bus On Rent in Nashik",
        "description": "We also provide mini buses on rent in Nashik for those traveling from Shirdi to nearby destinations. Our services extend beyond Shirdi, ensuring you have reliable transportation for all your travel needs, whether for business or leisure."
    },
    {
        "name": "Mini Bus On Rent in Shirdi Airport",
        "description": "Arriving at Shirdi Airport? Our mini bus on rent in Shirdi Airport ensures you have transportation ready upon your arrival. We provide convenient pickup services to help you start your journey smoothly, accommodating your group right from the start."
    },
    {
        "name": "Shirdi Darshan Mini Bus on Rent",
        "description": "Experience the spiritual beauty of Shirdi with our Shirdi Darshan mini bus on rent. Our knowledgeable drivers will guide you to all the significant temples and sites, ensuring you have a fulfilling pilgrimage experience. Travel together as a group while exploring the rich heritage of Shirdi."
    },
    {
        "name": "Contact Information",
        "description": "For booking and inquiries, contact SaitirthaTours & Travels at +91 9923879009 / 9922479009. Experience comfortable group travel with our mini bus rental services in Shirdi. Our team is here to assist you in planning your journey and ensuring a memorable experience."
    }
],
"tableData": [
    ["- Mini Bus Rental Shirdi", "- Rent Mini Bus Shirdi"],
    ["- Mini Bus Hire Shirdi", "- Shirdi Mini Bus Rental Services"],
    ["- Mini Coach Hire Shirdi", "- Affordable Mini Bus Rental Shirdi"],
    ["- Mini Bus On Hire Near Shirdi", "- Shirdi Mini Bus Booking"],
    ["- Mini Bus Rental Agency Shirdi", "- Best Mini Bus Rental Rates Shirdi"],
    ["- AC - Non AC Mini Bus Hire in Shirdi", "- Luxury Mini Bus On Rent in Shirdi"],
    ["- 12, 16, 18, 20, 25, 30 Seater AC Mini Bus On Rent in Shirdi", "- Shirdi to Ajanta Ellora Mini Bus On Rent"],
    ["- Shirdi to Ajanta Ellora Mini Bus On Rent", "- Mini Bus On Rent in Nashik"],
    ["- Mini Bus On Rent in Shirdi Airport", "- Shirdi Darshan Mini Bus On Rent"],
    ["- SaitirthaTours & Travels Shirdi"]
],
"whychoose": [
    {
        "WhyChooseheading": "Spacious Accommodation:",
        "WhyChoosedescription": "Our mini buses are designed to provide ample space for all passengers, ensuring a comfortable experience for large groups. With generous seating capacity, they are perfect for family gatherings, corporate events, or group tours. You can travel together without feeling cramped, making the journey more enjoyable."
    },
    {
        "WhyChooseheading": "Comfort and Luxury:",
        "WhyChoosedescription": "Enjoy a luxurious travel experience with our well-equipped mini buses. Each vehicle features comfortable seating, air conditioning, and modern amenities that cater to your needs. Whether you're embarking on a long journey or a short trip, you can relax and enjoy the ride in comfort."
    },
    {
        "WhyChooseheading": "Safe and Reliable:",
        "WhyChoosedescription": "Safety is our top priority. All our mini buses undergo regular maintenance and safety checks to guarantee a reliable and secure journey for every passenger. You can travel with peace of mind knowing that our vehicles meet high safety standards, providing you with a worry-free experience."
    },
    {
        "WhyChooseheading": "Experienced Drivers:",
        "WhyChoosedescription": "Our drivers are not only licensed professionals but also have extensive experience in navigating Shirdi and its surroundings. Their knowledge of the best routes ensures a smooth and secure ride. With their friendly demeanor and commitment to safety, you can relax and enjoy your journey."
    },
    {
        "WhyChooseheading": "Affordable Rates:",
        "WhyChoosedescription": "We pride ourselves on offering competitive pricing for mini bus rentals. Our rates are designed to provide excellent value for large groups without sacrificing quality. We believe that comfortable and reliable transportation should be accessible, allowing you to focus on your journey and the experiences ahead."
    },
    {
        "WhyChooseheading": "Flexible Rental Options:",
        "WhyChoosedescription": "Understanding that every group has unique needs, we offer flexible rental packages tailored to your requirements. Whether you need a mini bus for just a few hours, a full day, or multiple days, we provide options that fit your schedule and preferences, ensuring convenience for your travel plans."
    },
    {
        "WhyChooseheading": "24/7 Customer Support:",
        "WhyChoosedescription": "Our dedicated customer service team is available 24/7 to assist you with any inquiries or modifications related to your booking. Whether you need help with your reservation, have questions about our services, or require support during your trip, we are here to ensure your experience is seamless and enjoyable."
    },
    {
        "WhyChooseheading": "Hygiene and Safety:",
        "WhyChoosedescription": "We prioritize your health and safety by implementing strict hygiene protocols. Our mini buses are regularly sanitized, and we follow health guidelines to ensure a clean and safe environment for our passengers. You can travel confidently, knowing that we take every precaution to protect your well-being."
    },
    {
        "WhyChooseheading": "Convenient Booking Process:",
        "WhyChoosedescription": "Booking a mini bus with us is quick and hassle-free. You can reserve your vehicle easily through our user-friendly online platform or by contacting our customer support team for personalized assistance. We strive to make the booking experience as smooth as possible so you can focus on your travel plans."
    },
    {
        "WhyChooseheading": "Positive Customer Experience:",
        "WhyChoosedescription": "We take great pride in the positive feedback we receive from our customers, which reflects our commitment to providing excellent service. Our many satisfied clients speak to our dedication to quality and reliability. We continually strive to meet and exceed your expectations, ensuring your journey is memorable for all the right reasons."
    }
]


        
  }


  const faqData = [
    {
        question: "How do I book a mini bus in Shirdi?",
        answer: "You can book a mini bus online through our website or by contacting our customer support team for assistance."
    },
    {
        question: "Is the rental cost inclusive of the driver’s fee?",
        answer: "Yes, the rental price includes the driver’s fee. Additional charges may apply for specific requests."
    },
    {
        question: "What is the seating capacity of the mini bus?",
        answer: "Our mini buses typically seat between 12 to 20 passengers, making them ideal for medium-sized groups."
    },
    {
        question: "Are there any extra charges?",
        answer: "We strive for transparency in our pricing. Any additional charges will be clearly communicated at the time of booking."
    },
    {
        question: "Can I rent the mini bus for a one-way trip?",
        answer: "Yes, we offer one-way rental options for your convenience."
    },
    {
        question: "What payment methods do you accept?",
        answer: "We accept various payment methods, including credit/debit cards, UPI, and cash."
    },
    {
        question: "What is your cancellation policy?",
        answer: "You can cancel your booking by contacting our support team before your scheduled rental date."
    },
    {
        question: "Is customer support available for queries?",
        answer: "Yes, our customer support team is available 24/7 to assist with any questions or concerns."
    },
    {
        question: "Can I request additional amenities in the mini bus?",
        answer: "Yes, you can make special requests during the booking process, and we will do our best to accommodate them."
    },
    {
        question: "Are your drivers experienced and licensed?",
        answer: "Yes, all our drivers are fully licensed and have significant experience in providing safe and enjoyable travel experiences."
    }
];

const testimonials = [
    {
        name: "Ms. Sunita Mehta",
        text: "We rented a mini bus for our family reunion in Shirdi, and it was a fantastic experience! The bus was clean, spacious, and perfect for our large group. Our driver was friendly and ensured we had a smooth journey. I highly recommend Saitirtha Travels for anyone needing group transportation!"
    },
    {
        name: "Mr. Rajesh Deshmukh",
        text: "Saitirtha Travels provided us with a mini bus for our corporate event, and we were extremely pleased with the service. The bus was well-maintained, and our driver was professional and punctual. The entire experience was seamless, and I will definitely book with them again!"
    }
];




  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Mini Bus on Rent in Shirdi | Affordable Mini Bus Rentals | SaitirthaTours & Travels</title>
    <meta name="description" content="Rent a mini bus in Shirdi. Affordable mini bus rental services for local and outstation travel. Book 12-30 seater mini buses, AC and non-AC options. Book now!" />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirtha Travels | Mini Bus on Rent in Shirdi | Affordable Mini Bus Rentals | SaitirthaTours & Travels" />
    <meta property="og:description" content="Rent a mini bus in Shirdi. Affordable mini bus rental services for local and outstation travel. Book 12-30 seater mini buses, AC and non-AC options. Book now!" />
    <meta property="og:url" content="https://saitirthatravels.com/mini-bus-on-rent-shirdi" />
    <meta property="og:image" content="https://saitirthatravels.com/images/mini-bus-on-rent-shirdi.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Rent a mini bus in Shirdi. Affordable mini bus rental services for local and outstation travel. Book 12-30 seater mini buses, AC and non-AC options. Book now!",
            "url": "https://saitirthatravels.com/mini-bus-on-rent-shirdi",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/mini-bus-on-rent-shirdi.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.7",
                "reviewCount": "150"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Mini bus rental Shirdi",
                "Rent mini bus Shirdi",
                "Mini bus hire Shirdi",
                "Shirdi mini bus rental services",
                "Mini coach hire Shirdi",
                "Affordable mini bus rental Shirdi",
                "Mini bus on hire near Shirdi",
                "Shirdi mini bus booking",
                "Mini bus rental agency Shirdi",
                "Best mini bus rental rates Shirdi",
                "AC - Non AC mini bus hire in Shirdi",
                "Luxury mini bus on rent in Shirdi",
                "12 seater mini bus on rent in Shirdi",
                "16 seater mini bus rental Shirdi",
                "18 seater mini bus hire Shirdi",
                "20 seater mini bus rental Shirdi",
                "25 seater mini bus rental Shirdi",
                "30 seater mini bus hire Shirdi",
                "Shirdi to Ajanta Ellora mini bus on rent",
                "Mini bus on rent in Nashik",
                "Mini bus on rent Shirdi airport",
                "Shirdi Darshan mini bus on rent"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/51.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Minibusonrentinshirdi;