
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Onewaytaxi() {



  const cardData =
  {
    keyword: '  One Way Taxi in Shirdi',
    heading: 'Saitirtha Travels: One Way Taxi in Shirdi',
    headingDescription: 'Saitirtha Travels offers convenient and reliable one-way taxi services from Shirdi to various destinations. Whether you are heading to a nearby city or planning a long-distance journey, our one-way taxi service ensures a hassle-free travel experience tailored to your needs.',

    top: 'Top Places to Visit Near Shirdi with Saitirtha Travels',

"topPlaces": [
    {
        "title": "Sai Baba Samadhi Mandir",
        "description": "Sai Baba Samadhi Mandir is the heart of Shirdi and a sacred temple dedicated to Sai Baba, attracting thousands of devotees daily. The temple's serene atmosphere is complemented by beautifully maintained gardens and grand architecture, creating a spiritual haven for visitors. Inside the temple, devotees pay their respects at the Samadhi (final resting place) of Sai Baba, participating in various rituals and prayers. The temple is not only a place of worship but also a center of peace and reflection, where individuals come to connect with the divine and experience the teachings of Sai Baba."
    },
    {
        "title": "Dwarkamai Masjid",
        "description": "Dwarkamai Masjid, once a resting place for Sai Baba, has become a significant pilgrimage site, symbolizing the unity of different faiths. The mosque features a unique blend of Hindu and Muslim architectural styles, with its sacred flame (Dhuni) representing the eternal presence of Sai Baba's spirit. Devotees gather here to offer their prayers and seek blessings, enveloped in the spiritual aura of the place. The walls of the mosque are adorned with countless offerings and letters of gratitude from devotees, adding to its profound significance."
    },
    {
        "title": "Chavadi",
        "description": "Chavadi is a simple yet significant structure where Sai Baba would spend alternate nights, holding immense importance for his followers. Visitors to Chavadi can witness the peaceful ambiance and explore various artifacts displayed here that tell the story of Sai Baba’s life and teachings. The Chavadi is often adorned with flowers and offers a space for meditation and reflection, allowing devotees to connect with the essence of Sai Baba’s philosophy of love, compassion, and service."
    },
    {
        "title": "Shri Khandoba Temple",
        "description": "Perched on a hill, Shri Khandoba Temple is a historical temple dedicated to Lord Khandoba. It offers panoramic views of the surrounding region, making it a scenic spot for visitors. The temple is especially crowded during festivals, showcasing vibrant local culture and traditions through music, dance, and rituals. Pilgrims and tourists alike are drawn to the temple not only for its spiritual significance but also for the stunning landscapes that frame the area."
    },
    {
        "title": "Gurusthan",
        "description": "Gurusthan is a revered site believed to be where Sai Baba first arrived in Shirdi. It features a tomb-like structure and a sacred neem tree, making it a place of deep reverence for devotees seeking blessings. The serene environment encourages introspection and prayer, allowing visitors to connect with the spiritual legacy of Sai Baba. Gurusthan serves as a reminder of Sai Baba’s humble beginnings and the profound impact he has had on countless lives."
    },
    {
        "title": "Sainagar Railway Station",
        "description": "Sainagar Railway Station is a major railway hub that connects Shirdi with various cities across India. The station enhances accessibility for pilgrims and tourists, making it convenient for them to travel to and from this holy destination. With its well-maintained facilities and services, Sainagar Railway Station plays a crucial role in accommodating the influx of visitors coming to pay homage to Sai Baba."
    },
    {
        "title": "Shirdi Wet N Joy Water Park",
        "description": "Shirdi Wet N Joy Water Park is a fun-filled destination for families and children, offering a variety of rides and activities. This water park provides a refreshing escape from the spiritual sites, making it a great spot to relax and enjoy a day of leisure. With thrilling water slides, wave pools, and splash areas, visitors can unwind and have fun, creating lasting memories after their visits to the holy sites."
    },
    {
        "title": "Nashik",
        "description": "Located just a short drive from Shirdi, Nashik is renowned for its vineyards, temples, and its role in the Kumbh Mela. Visitors can explore the Panchavati area, home to several holy sites, including the sacred Godavari River. Nashik is also famous for its wine culture, offering wine tasting tours at local vineyards that provide a delightful experience for wine enthusiasts. The city's rich history, combined with its spiritual significance, makes it an attractive destination for travelers."
    },
    {
        "title": "Ahmednagar Fort",
        "description": "Ahmednagar Fort is an impressive historical fort that showcases the rich history of the region. Built in the 15th century, the fort features ancient ruins, bastions, and water reservoirs, reflecting the architectural prowess of its time. Visitors can explore its extensive grounds, learn about its significance in various historical contexts, and enjoy panoramic views from its ramparts. The fort serves as a reminder of the region's vibrant past and offers a glimpse into the heritage of Maharashtra."
    },
    {
        "title": "Bhandardara",
        "description": "Bhandardara is a scenic getaway located a few hours from Shirdi, celebrated for its stunning landscapes, waterfalls, and the famous Arthur Lake. This picturesque destination is perfect for nature lovers and those seeking tranquility away from the bustling city. Visitors can engage in outdoor activities such as trekking, boating, and camping, while the serene environment offers a refreshing retreat for relaxation and reflection. Bhandardara's natural beauty and serene atmosphere make it a popular spot for weekend getaways."
    }
],
    
            
        
"services": [
    {
        "name": "One Way Taxi Shirdi",
        "description": "Experience unparalleled convenience and affordability with our One Way Taxi Shirdi service. Whether you’re journeying to Pune, Mumbai, Nashik, or other nearby destinations, our one-way cab service is designed to ensure a seamless journey tailored to your individual schedule and preferences. We prioritize your comfort and satisfaction, making travel easy and enjoyable."
    },
    {
        "name": "Shirdi One Way Cab",
        "description": "Our Shirdi one-way cab services cater to travelers seeking reliable and comfortable transportation for single trips. We offer a diverse selection of vehicles, including compact cars, spacious SUVs, and luxury options, ensuring that you find the perfect fit for your travel group. Our professional drivers are dedicated to providing a safe and pleasant journey, allowing you to relax and enjoy the ride while taking in the scenic views."
    },
    {
        "name": "Shirdi to Pune One Way Cab",
        "description": "Enjoy a smooth and hassle-free ride with our Shirdi to Pune one-way cab service. This option is perfect for those needing to travel without the obligation of returning to Shirdi. Ideal for business travelers, families, and tourists alike, our service offers direct, comfortable transport, allowing you to focus on your plans in Pune without worrying about logistics."
    },
    {
        "name": "Shirdi to Mumbai One Way Taxi",
        "description": "Our Shirdi to Mumbai one-way taxi service provides a convenient and stress-free ride to the bustling city. With flexible booking options and competitive rates, this service is perfect for anyone traveling from Shirdi to Mumbai without a return journey. Enjoy the comfort of our well-maintained vehicles and the professionalism of our drivers, ensuring that your trip is both enjoyable and efficient."
    },
    {
        "name": "Shirdi to Nashik One Way Taxi Service",
        "description": "Traveling to Nashik? Our one-way taxi service from Shirdi is your ideal solution. Designed to be convenient and economical, our service guarantees a comfortable ride with experienced drivers who know the best routes. You can relax knowing you’ll arrive safely and on time, ready to enjoy everything Nashik has to offer."
    },
    {
        "name": "Shirdi to Pune Airport One Way Cab",
        "description": "Our Shirdi to Pune Airport one-way cab service is tailored for travelers with flights to catch. Enjoy a punctual and stress-free journey to the airport with our reliable service, ensuring that you arrive at your terminal well ahead of time. With us, you can say goodbye to the anxiety of missing your flight and focus on enjoying your travels."
    },
    {
        "name": "Aurangabad to Shirdi One Way Taxi Fare",
        "description": "Traveling from Aurangabad to Shirdi is now easier than ever with our affordable one-way taxi fare. This service is perfect for visitors looking to explore Shirdi without needing a return trip to Aurangabad. Our pricing is transparent, with no hidden fees, allowing you to enjoy a comfortable ride while staying within your budget."
    },
    {
        "name": "Shirdi to Nashik Airport One Way Cab Service",
        "description": "We offer a convenient one-way cab service from Shirdi to Nashik Airport, ideal for travelers with strict flight schedules. Our service guarantees a direct, reliable ride to the airport, ensuring a stress-free experience as you prepare for your journey. Count on us for prompt service that prioritizes your travel needs."
    },
    {
        "name": "Shirdi to Shani Shingnapur One Way Taxi Service",
        "description": "Our Shirdi to Shani Shingnapur one-way taxi service offers a comfortable and efficient ride to this famous pilgrimage site. Enjoy the journey with our experienced drivers, who ensure a smooth trip, allowing you to focus on your spiritual visit without the worry of returning to Shirdi."
    },
    {
        "name": "Shirdi to Manmad One Way Cab Service",
        "description": "Traveling from Shirdi to Manmad is made easy with our one-way cab service, providing a comfortable and budget-friendly option. Our drivers are familiar with the best routes, ensuring you arrive at your destination on time and with minimal hassle. Experience a smooth ride with us as you head to Manmad."
    },
    {
        "name": "Shirdi to Ajanta Ellora One Way Taxi Service",
        "description": "Explore the breathtaking Ajanta Ellora Caves with our one-way taxi service from Shirdi. Perfect for those eager to experience these UNESCO World Heritage Sites without the need to return to Shirdi, our service allows for a flexible travel experience. Enjoy the freedom to explore these historic attractions at your leisure."
    },
    {
        "name": "Shirdi One Way Taxi Service",
        "description": "Our Shirdi one-way taxi service is designed to provide flexible and convenient travel options for a variety of needs. Whether you're heading to Pune, Mumbai, Nashik, or beyond, our one-way service is available with a wide range of vehicles, competitive rates, and experienced drivers. Trust us to make your travel experience smooth and enjoyable."
    },
    {
        "name": "One Way Taxi from Shirdi Contact Information",
        "description": "For more information or to book your one-way taxi from Shirdi, contact SaitirthaTours & Travels at +91 9923879009 / 9922479009. Our dedicated team is here to assist you with any questions or booking needs, ensuring you enjoy a seamless and comfortable journey from Shirdi to your desired destination. Your satisfaction is our priority, and we look forward to serving you."
    }
],
"tableData": [
    ["- One Way Cab Shirdi", ""],
    ["- Shirdi One Way Taxi", "- Shirdi to Pune Cab Service"],
    ["- Best One Way Cab from Shirdi", "- Cheap Taxi Shirdi One Way"],
    ["- Book One Way Cab Shirdi", "- Reliable One Way Taxi Shirdi"],
    ["- Shirdi to Mumbai Taxi Fare", "- Direct One Way Cab Shirdi"],
    ["- One Way Cab Shirdi", "- One Way Taxi from Shirdi to Pune"],
    ["- Shirdi to Pune Airport Cab One Way", "- Shirdi to Mumbai Cab One Way"],
    ["- Aurangabad to Shirdi One Way Taxi Fare", "- Shirdi to Nashik One Way Taxi Service"],
    ["- Shirdi to Nashik Airport One Way Cab Service", "- Shirdi to Nashik Navi Mumbai One Way Taxi Service"],
    ["- Shirdi to Solapur One Way Taxi Service", "- Shirdi to Ajanta Ellora One Way Taxi Service"],
    ["- Shirdi One Way Taxi Service", "- Shirdi Shani Shingnapur One Way Taxi Service"],
    ["- Shani Shingnapur One Way Cab Service", "- Shirdi to Manmad One Way Cab Service"],
    ["- Shirdi Airport One Way Taxi Service", "- SaitirthaTours & Travels Shirdi"]
],
"whychoose": [
    {
        "WhyChooseheading": "Convenient One-Way Service:",
        "WhyChoosedescription": "Our one-way taxi service offers unparalleled flexibility, catering to travelers who need a simple drop-off without the hassle of planning a return trip. This service is perfect for those who are flying into or out of a location, attending events, or simply wish to explore the region without the commitment of a round trip. Enjoy peace of mind knowing your transportation needs are covered, allowing you to focus on what truly matters during your travels."
    },
    {
        "WhyChooseheading": "Affordable Fares:",
        "WhyChoosedescription": "We pride ourselves on providing some of the most competitive fares in the industry. Our pricing strategy is designed to ensure that you get the best possible deal for your one-way travel. We believe that quality transportation shouldn’t break the bank, which is why we offer transparent pricing with no hidden fees, ensuring you understand exactly what you're paying for. This allows you to enjoy your journey without worrying about unexpected costs."
    },
    {
        "WhyChooseheading": "Easy Booking Process:",
        "WhyChoosedescription": "Booking your one-way taxi with us is straightforward and user-friendly. Whether you prefer to book online through our streamlined website or speak directly with our customer support team, we provide multiple avenues to make your reservation. Our booking system is designed to minimize the time and effort needed to secure your ride, enabling you to finalize your travel plans swiftly and efficiently."
    },
    {
        "WhyChooseheading": "Well-Maintained Fleet:",
        "WhyChoosedescription": "Your safety and comfort are our utmost priorities. Our fleet consists of a diverse range of well-maintained vehicles that are regularly serviced to meet high safety and performance standards. Each vehicle is equipped with modern amenities to enhance your travel experience, including air conditioning, comfortable seating, and clean interiors. This attention to detail ensures that every journey you take with us is enjoyable and stress-free."
    },
    {
        "WhyChooseheading": "Experienced Drivers:",
        "WhyChoosedescription": "Our drivers are not only highly skilled and trained professionals but also friendly and approachable individuals dedicated to providing an excellent travel experience. They are familiar with the best routes, traffic patterns, and local landmarks, allowing them to navigate efficiently and ensure timely arrivals. Their focus on safety means you can relax and enjoy your ride, knowing that you are in capable hands."
    },
    {
        "WhyChooseheading": "Timely Pick-Up:",
        "WhyChoosedescription": "We understand that time is of the essence, especially when traveling for business or catching a flight. Our commitment to punctuality means you can count on us for timely pick-ups, ensuring that you arrive at your destination as scheduled. Our drivers are trained to arrive at your location ahead of time, allowing for a smooth transition to your next destination without the stress of delays."
    },
    {
        "WhyChooseheading": "24/7 Availability:",
        "WhyChoosedescription": "Our one-way taxi service operates 24/7, providing you with the flexibility to book rides at any hour, day or night. Whether you have a late-night flight, an early morning business meeting, or require transport for spontaneous travel, we are always here to accommodate your needs. This constant availability ensures you can rely on us no matter the time, giving you peace of mind during your travels."
    },
    {
        "WhyChooseheading": "Transparent Pricing:",
        "WhyChoosedescription": "We believe in honesty and transparency when it comes to pricing. Our fare structure is clearly communicated at the time of booking, so you will never encounter hidden fees or unexpected charges. This transparency is part of our commitment to customer satisfaction, allowing you to budget effectively for your trip and feel confident in the service you are receiving."
    },
    {
        "WhyChooseheading": "Customer-Centric Approach:",
        "WhyChoosedescription": "At Saitirtha Travels, we prioritize your satisfaction above all else. Our customer-centric philosophy drives us to continuously improve our services and cater to your unique travel needs. We welcome feedback and actively seek ways to enhance your experience, ensuring that every ride meets your expectations and leaves you completely satisfied. Our goal is to create lasting relationships with our customers based on trust and exceptional service."
    },
    {
        "WhyChooseheading": "Positive Reviews:",
        "WhyChoosedescription": "The trust we have built over the years is reflected in the glowing reviews from our satisfied customers. Many travelers commend our reliability, professionalism, and commitment to quality service. These testimonials not only highlight our success in meeting customer expectations but also serve as a testament to our dedication to providing outstanding travel experiences. We take pride in our reputation and continually strive to uphold the standards that have earned us such positive feedback."
    }
]




        
  }


  const faqData = [
    {
        question: "How do I book a one-way taxi from Shirdi?",
        answer: "You can easily book a one-way taxi through our website or by calling our customer service team."
    },
    {
        question: "What are the fares for one-way taxis?",
        answer: "Fares vary based on the distance and vehicle type. Please contact us for a specific quote."
    },
    {
        question: "Can I choose the vehicle type for my one-way trip?",
        answer: "Yes, we offer a variety of vehicles, including sedans, SUVs, and more for your one-way journey."
    },
    {
        question: "What if my plans change after booking?",
        answer: "You can modify your booking by contacting our support team, depending on availability."
    },
    {
        question: "Are there any extra charges for one-way trips?",
        answer: "Our pricing is transparent, and any applicable fees will be communicated upfront before booking."
    },
    {
        question: "How do I pay for my one-way taxi?",
        answer: "We accept various payment methods, including cash, credit/debit cards, and online payments."
    },
    {
        question: "What if my flight or train is delayed?",
        answer: "Our drivers monitor traffic and arrival times, ensuring they adjust the pick-up accordingly."
    },
    {
        question: "Are your drivers trained and experienced?",
        answer: "Yes, all our drivers are professional, licensed, and experienced in providing safe transportation."
    },
    {
        question: "Is the taxi service available at night?",
        answer: "Absolutely! Our one-way taxi service operates 24/7 for your convenience."
    },
    {
        question: "How can I contact customer support?",
        answer: "You can reach our customer support team by calling or emailing us for any inquiries or assistance."
    }
];

const testimonials = [
    {
        name: "Mr. Ramesh Sinha",
        text: "I booked a one-way taxi from Shirdi to Pune, and the service was outstanding. The driver arrived on time and was very professional. The ride was comfortable, and I appreciated the transparency in pricing. I would highly recommend Saitirtha Travels for their reliability!"
    },
    {
        name: "Mrs. Kavita Sharma",
        text: "My experience with Saitirtha Travels was excellent. I needed a one-way taxi to Nashik, and everything from booking to the ride was seamless. The driver was friendly and knowledgeable about the area, making the trip enjoyable. I’ll definitely use them again!"
    }
];




  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | One Way Taxi Shirdi | SaitirthaTours & Travels | Affordable Taxi Services</title>
    <meta name="description" content="Book reliable one-way taxi services from Shirdi. Offering affordable one-way cab rides to popular destinations like Pune, Mumbai, Nashik, Solapur, and more. Book now!" />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirtha Travels | One Way Taxi Shirdi | SaitirthaTours & Travels | Affordable Taxi Services" />
    <meta property="og:description" content="Book reliable one-way taxi services from Shirdi. Offering affordable one-way cab rides to popular destinations like Pune, Mumbai, Nashik, Solapur, and more. Book now!" />
    <meta property="og:url" content="https://saitirthatravels.com/one-way-taxi-shirdi" />
    <meta property="og:image" content="https://saitirthatravels.com/images/one-way-taxi-shirdi.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Book reliable one-way taxi services from Shirdi. Offering affordable one-way cab rides to popular destinations like Pune, Mumbai, Nashik, Solapur, and more. Book now!",
            "url": "https://saitirthatravels.com/one-way-taxi-shirdi",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/one-way-taxi-shirdi.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "One way taxi Shirdi",
                "Shirdi one way taxi",
                "Shirdi to Pune cab service",
                "Best one way cab from Shirdi",
                "Cheap taxi Shirdi one way",
                "Book one way cab Shirdi",
                "Reliable one way taxi Shirdi",
                "Shirdi to Mumbai taxi fare",
                "One way cab Shirdi to Pune",
                "Shirdi to Mumbai one way taxi",
                "Aurangabad to Shirdi one way taxi fare",
                "Shirdi to Nashik one way taxi service",
                "Shirdi to Nashik airport one way cab service",
                "Shirdi to Solapur one way taxi service",
                "Shirdi to Ajanta Ellora one way taxi service",
                "Shirdi Shani Shingnapur one way taxi service",
                "Shirdi to Manmad one way cab service",
                "Shirdi airport one way taxi service",
                "SaitirthaTours & Travels Shirdi"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/57.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Onewaytaxi;