import React from 'react';

const BusRatesTable = () => {
  const ratesData = [
    { seater: "4+1", vehicle: "Dzire, Etios, Xcent", rate: "₹3600", perKm: "₹12 per km", driverDA: "₹200" },
    { seater: "6+1", vehicle: "Ertiga", rate: "₹4200", perKm: "₹14 per km", driverDA: "₹200" },
    { seater: "6+1", vehicle: "Kia Carens", rate: "₹4500", perKm: "₹15 per km", driverDA: "₹200" },
    { seater: "6+1", vehicle: "Tourist Special Innova", rate: "₹5100", perKm: "₹17 per km", driverDA: "₹200" },
    { seater: "6+1", vehicle: "Innova Crysta", rate: "₹5700", perKm: "₹19 per km", driverDA: "₹200" },
    { seater: "13 Seater", vehicle: "Tempo Traveller 13 Seater", rate: "On Call", perKm: "Non A/C- ₹25, A/C- ₹30", driverDA: "₹500" },
    { seater: "17 Seater", vehicle: "Tempo Traveller 17 Seater", rate: "On Call", perKm: "Non A/C- ₹25, A/C- ₹30", driverDA: "₹500" },
    { seater: "20, 28, 32, 50 Seater", vehicle: "Minibus 20, 28, 32, 50 Seater", rate: "On Call", perKm: "-", driverDA: "₹500" },
  ];

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    margin: "20px auto"
  };

  const thStyle = {
    backgroundColor: "#EE1623",
    color: "white",
    padding: "8px",
    border: "1px solid #ddd"
  };

  const tdStyle = {
    padding: "8px",
    border: "1px solid #ddd",
    textAlign: "center"
  };

  const trEvenStyle = {
    backgroundColor: "#f2f2f2"
  };

  const handleWhatsAppClick = (vehicle) => {
    const message = `Hi, I would like to inquire about the rates for the ${vehicle}.`;
    const url = `https://wa.me/yourNumberHere?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  return (
    <div className='container p-4'>
      <h3> Rates</h3>
      <div className='table-responsive'>
        <table style={tableStyle}>
          <thead>
            <tr>
              <th style={thStyle}>Outstation Capacity</th>
              <th style={thStyle}>Type Of Vehicle</th>
              <th style={thStyle}>Outstation Rates</th>
              <th style={thStyle}>Outstation Per Day (300km)</th>
              <th style={thStyle}>Per Day Driver D.A.</th>
              <th style={thStyle}>Book Now</th>
            </tr>
          </thead>
          <tbody>
            {ratesData.map((row, index) => (
              <tr key={index} style={index % 2 === 0 ? trEvenStyle : null}>
                <td style={tdStyle}>{row.seater}</td>
                <td style={tdStyle}>{row.vehicle}</td>
                <td style={tdStyle}>{row.rate}</td>
                <td style={tdStyle}>{row.perKm}</td>
                <td style={tdStyle}>{row.driverDA}</td>
                <td style={tdStyle}>
                  <button
                    onClick={() => handleWhatsAppClick(row.vehicle)}
                    style={{ cursor: 'pointer' ,padding:'8px', backgroundColor:'#EE1623', borderRadius:'10px', color:'white'}}
                  >
                    Book Now
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BusRatesTable;














// import React from 'react';

// const BusRatesTable = () => {
//   const ratesData = [
//     { seater: "17 Seater", mumbai: "Tempo Traveller", mahabaleshwar: "27", specialPermitNumber: "9923879009" },
//     { seater: "20 Seater", mumbai: "Urbania Bus", mahabaleshwar: "30", specialPermitNumber: "9923879009" },
//     { seater: "26 Seater", mumbai: "Ac Bus", mahabaleshwar: "40", specialPermitNumber: "9923879009" },
//     { seater: "35 Seater", mumbai: "Ac Bus", mahabaleshwar: "48", specialPermitNumber: "9923879009" },
//     { seater: "45 Seater", mumbai: "Ac Bus", mahabaleshwar: "55", specialPermitNumber: "9923879009" },
//     { seater: "50 Seater", mumbai: "Ac Bus", mahabaleshwar: "60", specialPermitNumber: "9923879009" },
//   ];

//   const ratesData2 = [
//     { seater: "17 Seater", mumbai: "Tempo Traveller", mahabaleshwar: "24", specialPermitNumber: "9923879009" },
//     { seater: "20 Seater", mumbai: "Non Ac Bus", mahabaleshwar: "28", specialPermitNumber: "9923879009" },
//     { seater: "26 Seater", mumbai: "Non Ac Bus", mahabaleshwar: "32", specialPermitNumber: "9923879009" },
//     { seater: "35 Seater", mumbai: "Non Ac Bus", mahabaleshwar: "35", specialPermitNumber: "9923879009" },
//     { seater: "45 Seater", mumbai: "Non Ac Bus", mahabaleshwar: "45", specialPermitNumber: "9923879009" },
//     { seater: "50 Seater", mumbai: "Non Ac Bus", mahabaleshwar: "50", specialPermitNumber: "9923879009" },
//   ];

//   const tableStyle = {
//     width: "100%",
//     borderCollapse: "collapse",
//     margin: "20px auto"
//   };

//   const thStyle = {
//     backgroundColor: "#0DCAF0",
//     color: "white",
//     padding: "8px",
//     border: "1px solid #ddd"
//   };

//   const tdStyle = {
//     padding: "8px",
//     border: "1px solid #ddd",
//     textAlign: "center"
//   };

//   const trEvenStyle = {
//     backgroundColor: "#f2f2f2"
//   };

//   const handleWhatsAppClick = (number, seater) => {
//     const message = `Hi, I would like to inquire about the rates for the ${seater}.`;
//     const url = `https://wa.me/${number}?text=${encodeURIComponent(message)}`;
//     window.open(url, '_blank');
//   };

//   return (
//     <div className='container p-0'>
//       <h3>Rates for AC Buses</h3>
//       <div className='table-responsive'>
//         <table style={tableStyle}>
//           <thead>
//             <tr>
//               <th style={thStyle}>BUSES</th>
//               <th style={thStyle}>Car Name</th>
//               <th style={thStyle}>Per KM</th>
//               <th style={thStyle}>Book Now</th>
//             </tr>
//           </thead>
//           <tbody>
//             {ratesData.map((row, index) => (
//               <tr key={index} style={index % 2 === 0 ? trEvenStyle : null}>
//                 <td style={tdStyle}>{row.seater}</td>
//                 <td style={tdStyle}>{row.mumbai}</td>
//                 <td style={tdStyle}>{row.mahabaleshwar}</td>
//                 <td style={tdStyle}>
//                   <button
//                     onClick={() => handleWhatsAppClick(row.specialPermitNumber, row.seater)}
//                     style={{ cursor: 'pointer' }}
//                   >
//                     Book Now
//                   </button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>

//       <div>
//         <h3>Rates for Non AC Buses</h3>
//         <div className='table-responsive'>
//           <table style={tableStyle}>
//             <thead>
//               <tr>
//                 <th style={thStyle}>BUSES</th>
//                 <th style={thStyle}>Car Name</th>
//                 <th style={thStyle}>Per KM</th>
//                 <th style={thStyle}>Book Now</th>
//               </tr>
//             </thead>
//             <tbody>
//               {ratesData2.map((row, index) => (
//                 <tr key={index} style={index % 2 === 0 ? trEvenStyle : null}>
//                   <td style={tdStyle}>{row.seater}</td>
//                   <td style={tdStyle}>{row.mumbai}</td>
//                   <td style={tdStyle}>{row.mahabaleshwar}</td>
//                   <td style={tdStyle}>
//                     <button
//                       onClick={() => handleWhatsAppClick(row.specialPermitNumber, row.seater)}
//                       style={{ cursor: 'pointer' }}
//                     >
//                       Book Now
//                     </button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default BusRatesTable;