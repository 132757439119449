
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Shirditotrimkeshwer() {



  const cardData =
  {
    keyword: ' Shirdi to Trimbakeshwar Cab Fare',
    heading: 'Saitirtha Travels: Shirdi to Trimbakeshwar Cab Fare',
    headingDescription: 'When traveling from Shirdi to Trimbakeshwar, Saitirtha Travels is your premier choice for a comfortable and reliable cab service. Our focus is on providing an enjoyable travel experience that complements your visit to the sacred town of Trimbakeshwar, known for its rich spiritual heritage and stunning landscapes. With our top-notch service, you can relax and appreciate the journey while we take care of your transportation needs.',

    top: 'Top Places to Visit in Trimbakeshwar with Saitirtha Travels',

    topPlaces: [
        {
            "title": "1. Trimbakeshwar Temple",
            "description": "This ancient temple is dedicated to Lord Shiva and is one of the twelve Jyotirlingas in India. The temple is renowned for its unique architecture, featuring three faces representing Brahma, Vishnu, and Shiva. Pilgrims from all over the country visit to offer prayers and seek blessings, making it a must-visit spiritual site for devotees."
        },
        {
            "title": "2. Godavari River",
            "description": "The sacred Godavari River originates near Trimbakeshwar and holds immense religious significance. Visitors can enjoy serene walks along the riverbanks, participate in rituals, or simply relax by the water. The tranquil environment and natural beauty make it an ideal spot for reflection and meditation."
        },
        {
            "title": "3. Anjneri Hill",
            "description": "Located near Trimbakeshwar, Anjneri Hill is a popular trekking destination believed to be the birthplace of Lord Hanuman. The trek offers panoramic views of the surrounding landscape and is perfect for adventure enthusiasts seeking a blend of spirituality and nature."
        },
        {
            "title": "4. Kushavart Tirth",
            "description": "This holy water tank is located near the Trimbakeshwar Temple and is an important site for rituals and ceremonies. Devotees come here to perform sacred ablutions and immerse themselves in the spiritual atmosphere, which is enhanced by the surrounding natural beauty."
        },
        {
            "title": "5. Brahmagiri Hill",
            "description": "The Brahmagiri Hill is significant for its historical and spiritual connections, as it is believed to be the place where the sage Vyas composed the Mahabharata. The hill offers breathtaking views of the region and is an excellent spot for trekking and exploring nature."
        },
        {
            "title": "6. Nivrutti Nath Temple",
            "description": "Dedicated to Nivrutti Nath, this temple is an important pilgrimage site for followers of the Nath tradition. The temple’s serene ambiance and intricate architecture attract visitors seeking spiritual solace and connection to the divine."
        },
        {
            "title": "7. Rama Temple",
            "description": "Located near Trimbakeshwar, this temple is dedicated to Lord Rama and features beautiful carvings and architecture. The peaceful surroundings provide a perfect backdrop for prayer and meditation, making it a cherished spot for devotees."
        },
        {
            "title": "8. Saptashringi Devi Temple",
            "description": "Situated a bit further away, this temple is dedicated to the goddess Saptashringi and is renowned for its scenic location amidst the Sahyadri mountain range. The temple is a significant pilgrimage destination, especially during the Navratri festival."
        },
        {
            "title": "9. Local Markets",
            "description": "Trimbakeshwar has vibrant local markets where visitors can shop for religious artifacts, traditional clothing, and unique souvenirs. Interacting with local artisans and vendors offers a glimpse into the region's rich culture and traditions."
        },
        {
            "title": "10. Heritage Walks",
            "description": "Engage in heritage walks around Trimbakeshwar to explore its rich history and architecture. Guided tours can provide insights into the town's historical significance and cultural heritage, enhancing your overall experience."
        }
    ],
    
            
        
    
    "services": [
        {
            "name": "Shirdi to Trimbakeshwar Taxi Fare",
            "description": "Understanding the Shirdi to Trimbakeshwar cab fare is essential for your travel budget. We offer competitive pricing with no hidden charges, allowing you to plan your pilgrimage or sightseeing without financial worries."
        },
        {
            "name": "Shirdi to Trimbakeshwar Taxi Service",
            "description": "Our Shirdi to Trimbakeshwar taxi service offers reliable transportation tailored to your schedule. Whether you’re making a one-way trip or planning a round trip, our skilled drivers will ensure you reach your destination safely and promptly."
        },
        {
            "name": "SaitirthaTours & Travels Shirdi",
            "description": "At SaitirthaTours & Travels, we pride ourselves on our customer service and commitment to quality. Our fleet is well-maintained, ensuring a comfortable ride from Shirdi to Trimbakeshwar."
        },
        {
            "name": "Shirdi Trimbakeshwar Car Rental",
            "description": "If you prefer more flexibility, consider our Shirdi Trimbakeshwar car rental options. This allows you to explore at your own pace while enjoying the breathtaking landscapes along the way."
        },
        {
            "name": "Shirdi to Trimbakeshwar One Way Taxi",
            "description": "Our Shirdi to Trimbakeshwar one way taxi service is perfect for those looking for a straightforward journey without the hassle of returning to Shirdi immediately. Enjoy the ride with the convenience of a direct route."
        },
        {
            "name": "Shirdi to Trimbakeshwar Cab Booking",
            "description": "Booking your Shirdi to Trimbakeshwar cab is easy with our user-friendly online platform. Reserve your vehicle in advance or contact our customer service for personalized assistance."
        },
        {
            "name": "Shirdi Trimbakeshwar Cab Rates",
            "description": "We offer competitive Shirdi Trimbakeshwar cab rates to suit different budgets. Our pricing structure is transparent, ensuring you know what to expect before you travel."
        },
        {
            "name": "Shirdi to Trimbakeshwar Taxi Fare per KM",
            "description": "Our Shirdi to Trimbakeshwar taxi fare per km is clearly laid out, allowing you to budget your travel expenses efficiently. You can rest assured that our pricing is among the most competitive in the region."
        },
        {
            "name": "Affordable Shirdi Trimbakeshwar Cab",
            "description": "Looking for an affordable Shirdi Trimbakeshwar cab? We strive to provide the best service at rates that won't break the bank, making your spiritual journey accessible and enjoyable."
        },
        {
            "name": "Shirdi to Nasik Trimbakeshwar Taxi Fare",
            "description": "If your travels include a stop in Nasik, our Shirdi to Nasik Trimbakeshwar taxi fare is designed to give you flexibility while maintaining affordability for your travel plans."
        },
        {
            "name": "Trimbakeshwar to Shirdi Taxi Fare",
            "description": "For return trips, our Trimbakeshwar to Shirdi taxi fare options are equally competitive. We ensure that you have a pleasant return journey filled with reflections of your spiritual experience."
        },
        {
            "name": "From Shirdi to Trimbakeshwar by Taxi",
            "description": "Traveling from Shirdi to Trimbakeshwar by taxi provides a direct and personalized experience. Our drivers are well-acquainted with the best routes, ensuring you enjoy a smooth ride."
        },
        {
            "name": "Taxi Fare from Shirdi to Trimbakeshwar",
            "description": "Our taxi fare from Shirdi to Trimbakeshwar is structured to provide great value, ensuring you can focus on your spiritual pursuits without worrying about transportation costs."
        },
        {
            "name": "Shirdi to Trimbakeshwar Innova Cab",
            "description": "For families or larger groups, our Shirdi to Trimbakeshwar Innova cab service is perfect. Spacious and comfortable, the Innova ensures everyone travels together comfortably."
        },
        {
            "name": "Shirdi to Trimbakeshwar Tempo Traveller on Rent",
            "description": "If you’re planning to travel with a bigger group, our Shirdi to Trimbakeshwar tempo traveller on rent option is ideal. It provides ample space for everyone to enjoy the journey together."
        },
        {
            "name": "Shirdi to Trimbakeshwar Contact Number",
            "description": "Contact SaitirthaTours & Travels at +91 9923879009 / +91 9922479009 for prompt and efficient Shirdi to Trimbakeshwar services. We ensure a smooth and enjoyable ride for all our customers."
        }
    ],    
  "tableData": [
        ["- Shirdi to Trimbakeshwar Taxi Service", "- Shirdi to Trimbakeshwar Cab fare"],
        ["- SaitirthaTours & Travels Shirdi", "- Shirdi Trimbakeshwar Car Rental"],
        ["- Shirdi to Trimbakeshwar One Way Taxi", "- Shirdi to Trimbakeshwar Cab Booking"],
        ["- Shirdi Trimbakeshwar Cab Rates", "- Shirdi to Trimbakeshwar Taxi Fare per KM"],
        ["- Affordable Shirdi Trimbakeshwar Cab", "- Shirdi to Trimbakeshwar Cab"],
        ["- Shirdi to Nasik Trimbakeshwar Taxi Fare", "- Shirdi to Trimbakeshwar Cab Fare"],
        ["- Shirdi to Trimbakeshwar Taxi", "- Trimbakeshwar to Shirdi Taxi Fare"],
        ["- From Shirdi to Trimbakeshwar by Taxi", "- Shirdi to Trimbakeshwar Taxi Fare"],
        ["- Taxi Fare from Shirdi to Trimbakeshwar", "- Trimbakeshwar to Shirdi Cab"],
        ["- Trimbakeshwar to Shirdi Taxi", "- Cab from Shirdi to Trimbakeshwar"],
        ["- Shirdi to Trimbakeshwar Innova Cab", "- Shirdi to Trimbakeshwar Tempo Traveller on Rent"]
    ],

    "whychoose": [
        {
            "WhyChooseheading": "Convenient Cab Service:",
            "WhyChoosedescription": "We offer reliable and direct cab service from Shirdi to Trimbakeshwar, making it easy for you to visit this sacred destination. Our cabs are equipped to handle any travel needs, ensuring a seamless journey."
        },
        {
            "WhyChooseheading": "Professional Drivers:",
            "WhyChoosedescription": "Our skilled drivers are well-acquainted with the routes to Trimbakeshwar, ensuring a safe and comfortable journey for you. They are trained to navigate the local roads and provide a friendly atmosphere during your trip."
        },
        {
            "WhyChooseheading": "Comfort and Cleanliness:",
            "WhyChoosedescription": "Travel in comfort in our well-maintained vehicles, which are regularly cleaned and sanitized to ensure your safety and comfort. We prioritize hygiene and comfort to enhance your travel experience."
        },
        {
            "WhyChooseheading": "Transparent Pricing:",
            "WhyChoosedescription": "We believe in clear pricing with no hidden fees, providing you with an upfront fare estimate so you can plan your budget accordingly. Our pricing structure is straightforward, allowing you to understand your costs."
        },
        {
            "WhyChooseheading": "Easy Booking Process:",
            "WhyChoosedescription": "Booking your cab from Shirdi to Trimbakeshwar is simple and can be done online or via our customer service hotline. We offer flexible booking options to fit your schedule, ensuring convenience."
        },
        {
            "WhyChooseheading": "24/7 Customer Support:",
            "WhyChoosedescription": "Our dedicated customer support team is available around the clock to assist you with any inquiries or concerns. Whether you need help with your booking or have questions during your journey, we’re here to help."
        },
        {
            "WhyChooseheading": "Flexible Travel Options:",
            "WhyChoosedescription": "We offer a variety of vehicle options to cater to your travel needs, whether you’re traveling alone or in a group. Choose from sedans, SUVs, and larger vehicles for your comfort."
        },
        {
            "WhyChooseheading": "Local Expertise:",
            "WhyChoosedescription": "Our drivers not only navigate the roads expertly but also share insights about Trimbakeshwar and its surroundings. They can guide you to the best spots and enhance your overall experience."
        },
        {
            "WhyChooseheading": "Safety Measures:",
            "WhyChoosedescription": "Your safety is our priority. All our vehicles are regularly inspected, and we adhere to safety protocols to ensure a secure journey. We also provide contactless options for your convenience."
        },
        {
            "WhyChooseheading": "Customizable Itineraries:",
            "WhyChoosedescription": "We understand that every traveler has unique preferences. We can customize your itinerary to include additional stops or adjust your travel plans as needed, providing a personalized experience."
        },
        {
            "WhyChooseheading": "Commitment to Customer Satisfaction:",
            "WhyChoosedescription": "Our goal is to ensure that you have a delightful experience from start to finish. We listen to your feedback and continuously improve our services to meet your expectations."
        },
        {
            "WhyChooseheading": "Special Packages and Offers:",
            "WhyChoosedescription": "We frequently offer special packages and discounts for various routes, including group bookings and seasonal promotions. This allows you to enjoy quality service at even more affordable rates."
        },
        {
            "WhyChooseheading": "Eco-Friendly Practices:",
            "WhyChoosedescription": "We are committed to sustainability and incorporate eco-friendly practices into our operations. Our fleet includes vehicles that are fuel-efficient, reducing our carbon footprint while you travel."
        },
        {
            "WhyChooseheading": "Feedback-Driven Improvements:",
            "WhyChoosedescription": "We actively seek feedback from our customers to enhance our services. Your insights help us refine our offerings and ensure we meet your travel needs effectively."
        },
        {
            "WhyChooseheading": "Loyalty Rewards Program:",
            "WhyChoosedescription": "Join our loyalty program to earn points for every ride you take with us. Redeem these points for discounts or free rides, rewarding you for choosing Saitirtha Travels repeatedly."
        },
        {
            "WhyChooseheading": "Seamless Payment Options:",
            "WhyChoosedescription": "We provide various payment methods for your convenience, including online payment, mobile wallets, and cash. Choose what works best for you to make the payment process easy."
        },
        {
            "WhyChooseheading": "Experienced Management Team:",
            "WhyChoosedescription": "Our experienced management team ensures that all operations run smoothly. They oversee every aspect of the service, from vehicle maintenance to customer relations, for a seamless experience."
        }
    ]
        
  }


  const faqData = [
    {
        question: "What is the fare for a cab from Shirdi to Trimbakeshwar?",
        answer: "The fare typically ranges from INR 1,200 to INR 1,600, depending on the type of vehicle and current demand. Please contact us for the latest pricing."
    },
    {
        question: "How long does the journey take?",
        answer: "The trip from Shirdi to Trimbakeshwar usually takes around 3 to 4 hours, depending on traffic conditions."
    },
    {
        question: "Are there any additional charges?",
        answer: "Additional charges may apply for nighttime travel (after 10 PM) or for any extra stops along the way."
    },
    {
        question: "How can I book a cab from Shirdi to Trimbakeshwar?",
        answer: "You can easily book your cab through our website or by contacting our customer support team for assistance."
    },
    {
        question: "What payment methods are accepted?",
        answer: "We accept various payment options, including credit/debit cards, UPI, and cash for your convenience."
    },
    {
        question: "Can I cancel or modify my booking?",
        answer: "Yes, we provide flexible cancellation and modification options. Please refer to our cancellation policy for more details."
    },
    {
        question: "Are the vehicles cleaned and sanitized before each trip?",
        answer: "Yes, all our cabs are thoroughly cleaned and sanitized before every journey to ensure your safety."
    },
    {
        question: "Can I request additional stops during the trip?",
        answer: "Yes, you can request reasonable stops for refreshments or sightseeing along the route."
    }
];

const testimonials = [
    {
        name: "Mr. Anil Joshi",
        text: "I had a wonderful experience traveling from Shirdi to Trimbakeshwar with Saitirtha Travels. The driver was punctual and professional, and the ride was very comfortable. I highly recommend their service!"
    },
    {
        name: "Ms. Neeta Deshmukh",
        text: "Saitirtha Travels made my trip to Trimbakeshwar from Shirdi smooth and enjoyable. The cab was clean, and the driver was friendly and knowledgeable. A fantastic service overall!"
    }
];

  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Shirdi to Trimbakeshwar Cab Fare | SaitirthaTours & Travels</title>
    <meta name="description" content="Book an affordable taxi from Shirdi to Trimbakeshwar with SaitirthaTours & Travels. Enjoy a comfortable and budget-friendly ride to Trimbakeshwar with competitive rates, one-way options, and multiple vehicle choices." />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirtha Travels | Shirdi to Trimbakeshwar Cab Fare | SaitirthaTours & Travels" />
    <meta property="og:description" content="Book an affordable taxi from Shirdi to Trimbakeshwar with SaitirthaTours & Travels. Enjoy a comfortable and budget-friendly ride to Trimbakeshwar with competitive rates, one-way options, and multiple vehicle choices." />
    <meta property="og:url" content="https://saitirthatravels.com/shirdi-to-trimbakeshwar-cab-fare" />
    <meta property="og:image" content="https://saitirthatravels.com/images/shirdi-to-trimbakeshwar-taxi.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Book an affordable taxi from Shirdi to Trimbakeshwar with SaitirthaTours & Travels. Enjoy a comfortable and budget-friendly ride to Trimbakeshwar with competitive rates, one-way options, and multiple vehicle choices.",
            "url": "https://saitirthatravels.com/shirdi-to-trimbakeshwar-cab-fare",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/shirdi-to-trimbakeshwar-taxi.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi to Trimbakeshwar Taxi Service", 
                "Shirdi to Trimbakeshwar Cab Fare", 
                "SaitirthaTours & Travels Shirdi", 
                "Shirdi Trimbakeshwar Car Rental", 
                "Shirdi to Trimbakeshwar One Way Taxi", 
                "Shirdi to Trimbakeshwar Cab Booking", 
                "Shirdi Trimbakeshwar Cab Rates", 
                "Shirdi to Trimbakeshwar Taxi Fare per km", 
                "Affordable Shirdi Trimbakeshwar Cab", 
                "Shirdi to Trimbakeshwar Cab", 
                "Shirdi to Nasik Trimbakeshwar Taxi Fare", 
                "Shirdi to Trimbakeshwar Taxi", 
                "Trimbakeshwar to Shirdi Taxi Fare", 
                "From Shirdi to Trimbakeshwar by Taxi", 
                "Shirdi to Trimbakeshwar Innova Cab", 
                "Shirdi to Trimbakeshwar Tempo Traveller on Rent", 
                "Shirdi to Trimbakeshwar Taxi Fare", 
                "Trimbakeshwar to Shirdi Cab"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/28.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirditotrimkeshwer;