
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Shirdiairportcab() {



  const cardData =
  {
    keyword: 'Shirdi Airport Cabs',
    heading: 'Saitirtha Travels: Shirdi Airport Cabs',
    headingDescription: 'For travelers flying into Shirdi Airport, Saitirtha Travels offers reliable and convenient cab services to help you reach your destination smoothly. Whether you are heading to a hotel, a pilgrimage site, or exploring the surrounding areas, our professional drivers ensure a comfortable and hassle-free ride.',

    top: 'Top Places to Visit Near Shirdi with Saitirtha Travels',

    topPlaces: [
        {
            "title": "Shirdi Sai Baba Temple",
            "description": "The Shirdi Sai Baba Temple is an iconic spiritual site dedicated to Sai Baba, a revered leader known for his teachings on love, compassion, and forgiveness. This magnificent temple attracts millions of devotees every year, creating a serene atmosphere conducive to prayer and reflection. The temple complex hosts various rituals and ceremonies throughout the day, including the mesmerizing aarti (devotional singing) and the distribution of prasad (blessed food). Visitors can admire the beautiful marble statue of Sai Baba, set within the tranquil surroundings of the temple grounds."
        },
        {
            "title": "Dwarkamai Mosque",
            "description": "Located adjacent to the Sai Baba Temple, the Dwarkamai Mosque is a significant spiritual site where Sai Baba lived for many years. This humble mosque features the sacred dhuni (eternal flame), which symbolizes the spiritual fire of Sai Baba’s teachings. Visitors can explore various artifacts and personal items associated with Baba’s life, providing insights into his profound impact on his followers. The peaceful atmosphere and the presence of devoted pilgrims make Dwarkamai a place of deep reverence and reflection."
        },
        {
            "title": "Chavadi",
            "description": "The Chavadi is a simple yet significant structure that served as a meeting place for Sai Baba and his devotees. It holds special importance, especially on Thursdays, when large gatherings occur for aarti and communal prayers. The Chavadi features several photographs of Baba and is adorned with offerings made by devotees, creating a spiritually charged environment. Visitors often find themselves immersed in the devotional atmosphere, reflecting on Sai Baba’s teachings and their personal faith."
        },
        {
            "title": "Shani Shingnapur",
            "description": "Located approximately 70 kilometers from Shirdi, Shani Shingnapur is renowned for its unique temple dedicated to Lord Shani, the deity associated with the planet Saturn. What sets this village apart is that the houses in Shani Shingnapur are built without doors, symbolizing the residents' unwavering faith in Lord Shani’s protection. This vibrant village attracts many devotees who come to seek blessings and witness the distinct customs and traditions surrounding the temple. The atmosphere is filled with spirituality, making it a fascinating stop for visitors."
        },
        {
            "title": "Saptashrungi Devi Temple",
            "description": "Nestled about 130 kilometers from Shirdi, the Saptashrungi Devi Temple is dedicated to Goddess Saptashrungi and is located atop a hill. The journey to the temple involves a scenic trek that rewards visitors with breathtaking views of the surrounding landscape. This temple is significant for its spiritual ambiance and is a popular pilgrimage destination for devotees seeking blessings from the goddess. The trek is not only a spiritual experience but also an opportunity to connect with nature and enjoy the stunning vistas."
        },
        {
            "title": "Nashik",
            "description": "A short drive from Shirdi, Nashik is a culturally rich city known for its vineyards, ancient temples, and the famous Kumbh Mela, held every twelve years. Visitors can explore the Panchavati area, which is home to historic temples dedicated to various deities, including the iconic Kalaram Temple. The Godavari River, flowing through Nashik, adds to the city's spiritual charm, providing picturesque spots for relaxation and contemplation. Nashik’s thriving wine industry also offers wine tours and tastings, making it an appealing destination for both spiritual and culinary experiences."
        },
        {
            "title": "Ajanta and Ellora Caves",
            "description": "The Ajanta and Ellora Caves, approximately 100 kilometers from Shirdi, are UNESCO World Heritage Sites celebrated for their stunning rock-cut architecture and intricate sculptures. Ajanta Caves are known for their beautiful frescoes and ancient Buddhist art, while Ellora Caves showcase a remarkable blend of Hindu, Buddhist, and Jain temples carved from solid rock. These caves offer a deep dive into India’s rich history and artistic heritage, making them a must-visit for history and art enthusiasts eager to explore the masterpieces of ancient craftsmanship."
        },
        {
            "title": "Bhandardara",
            "description": "About 150 kilometers from Shirdi, Bhandardara is a serene hill station known for its lush landscapes, cascading waterfalls, and the picturesque Arthur Lake. This tranquil destination is ideal for nature lovers and adventure seekers, offering opportunities for trekking, camping, and photography. The breathtaking views of the Sahyadri mountain range and the pristine natural surroundings create a perfect backdrop for relaxation and exploration. Bhandardara is a hidden gem that invites visitors to unwind and immerse themselves in the beauty of nature."
        },
        {
            "title": "Anjneri Hill",
            "description": "Anjneri Hill, located near Nashik, is believed to be the birthplace of Lord Hanuman, making it a site of great religious significance. The trek to the hilltop is a favorite among trekking enthusiasts, offering panoramic views of the surrounding valleys and hills. Along the way, visitors can explore various temples and enjoy the natural beauty of the landscape. The hill is not only a pilgrimage site but also an excellent location for adventure and outdoor activities, attracting those who seek both spiritual enrichment and physical challenge."
        },
        {
            "title": "Khandoba Temple",
            "description": "Situated in the nearby town of Jejuri, the Khandoba Temple is dedicated to Lord Khandoba, a revered deity in Maharashtra. This vibrant temple is an important pilgrimage site, drawing thousands of devotees, especially during festivals. The atmosphere is lively and colorful, filled with devotion and celebrations that create a unique cultural experience. Visitors can explore the intricately carved temple architecture and participate in various rituals, making it a significant stop for those interested in the spiritual traditions of the region."
        }
    ],  
    
            
        
"services": [
    {
        "name": "Shirdi Airport Cabs Service",
        "description": "Experience convenient and reliable transportation with our Shirdi Airport cab service. Our team is dedicated to providing you with a comfortable and safe journey, ensuring you reach your destination on time. From the moment you step off the plane, our professional drivers will be ready to assist you with your luggage and guide you to your cab. We take pride in our commitment to punctuality and customer satisfaction, making your travel experience as smooth as possible."
    },
    {
        "name": "Best Taxi Service in Shirdi",
        "description": "Choose the best taxi service in Shirdi for all your travel needs. Our extensive fleet includes a variety of vehicles, catering to everyone from solo travelers to large groups. We offer competitive rates without compromising on quality, ensuring that your journey is both economical and enjoyable. Our experienced drivers are knowledgeable about local attractions, making them the perfect guides for your Shirdi visit. Enjoy personalized service and make the most of your time in this holy city."
    },
    {
        "name": "Innova Crysta on Rent at Shirdi Airport",
        "description": "Rent an Innova Crysta for spacious and luxurious travel from Shirdi Airport. This vehicle is designed for comfort, featuring ample legroom and modern amenities, making it ideal for families and groups. With a professional driver at the wheel, you can sit back and relax as you travel to your destination. The Innova Crysta is perfect for longer journeys, providing a smooth ride whether you're heading to the temple or exploring nearby attractions. Experience travel in style and comfort with us."
    },
    {
        "name": "Shirdi Airport Tempo Traveller on Rent",
        "description": "For larger groups, we provide tempo traveller rentals at Shirdi Airport. This spacious vehicle allows you to travel together comfortably, accommodating up to 12 passengers with plenty of room for luggage. Equipped with air conditioning and comfortable seating, our tempo travellers are perfect for family outings, group pilgrimages, or any event where you want to travel together. Enjoy the convenience of group travel without compromising on comfort, and let us take care of your transportation needs."
    },
    {
        "name": "Shirdi Airport Cab Booking",
        "description": "Easily book your cab from Shirdi Airport with our user-friendly booking system. Our platform offers a seamless booking experience, allowing you to choose your preferred vehicle and specify your travel dates effortlessly. Whether you need immediate transportation or wish to book in advance, our service ensures that your cab is ready when you need it. Plus, with 24/7 availability, you can rest assured knowing that we’re always here to assist you, no matter the time of day."
    },
    {
        "name": "Shirdi Airport Cab Booking Online",
        "description": "Our online booking platform makes securing a cab from Shirdi Airport quick and easy. Simply select your travel date, choose from a range of vehicles, and confirm your booking in a matter of minutes. This convenient service allows you to plan your journey seamlessly, ensuring that a reliable cab is ready for you as soon as you land. Enjoy the peace of mind that comes with having your transportation arranged in advance, allowing you to focus on your pilgrimage or travel plans."
    },
    {
        "name": "Shirdi Cab Packages",
        "description": "Explore our various cab packages designed to meet your travel requirements. Our packages cater to individual and group travels, including everything from local sightseeing to extended trips to nearby cities. Each package is customizable, allowing you to tailor your itinerary based on your interests and needs. With competitive pricing and a commitment to excellent service, we ensure that your journey is not only enjoyable but also easy to navigate."
    },
    {
        "name": "Cabs in Shirdi Airport",
        "description": "We have a fleet of cabs ready at Shirdi Airport for your convenience. Whether you're traveling solo, as a couple, or with a large family, we have the perfect vehicle to suit your needs. Our cabs are well-maintained and equipped with modern amenities to ensure a comfortable ride. With a focus on safety and reliability, our drivers are familiar with the area, ensuring that you reach your destination efficiently and without hassle."
    },
    {
        "name": "Ertiga on Rent at Shirdi Airport",
        "description": "Rent a Suzuki Ertiga for a comfortable and budget-friendly ride from Shirdi Airport. This vehicle offers the perfect balance of space and efficiency, comfortably seating up to seven passengers. Ideal for family trips or group outings, the Ertiga provides enough room for both passengers and luggage. Enjoy a smooth ride as you explore Shirdi and its surrounding attractions, and benefit from the ease of booking and convenience of our service."
    },
    {
        "name": "Swift Dzire on Rent at Shirdi Airport",
        "description": "Our Swift Dzire rentals at Shirdi Airport offer a compact yet comfortable travel experience. Ideal for smaller groups or solo travelers, this car combines comfort and fuel efficiency, making it a perfect choice for navigating city roads and longer journeys alike. With comfortable seating and a well-designed interior, the Swift Dzire ensures a pleasant travel experience as you explore the sacred city of Shirdi and its spiritual surroundings."
    },
    {
        "name": "Outstation Cab in Shirdi",
        "description": "We provide outstation cab services for travelers looking to explore destinations beyond Shirdi. Whether you're planning a trip to nearby pilgrimage sites, tourist attractions, or scenic locations, our reliable transportation options make it easy to venture out. Our experienced drivers know the best routes to take, ensuring you arrive at your destination safely and efficiently while enjoying the journey."
    },
    {
        "name": "Cabs Service in Shirdi",
        "description": "Our cab service in Shirdi is designed to cater to all your transportation needs, whether local or outstation. We offer a variety of vehicle options, ensuring that you can find the perfect ride for your requirements. With a focus on customer satisfaction, we prioritize timely pickups and comfortable rides, making your travels in and around Shirdi stress-free and enjoyable."
    },
    {
        "name": "Taxi Car Rentals in Shirdi",
        "description": "Rent a car in Shirdi for flexible travel options. Choose from our wide selection of vehicles to fit your needs, whether for a short trip to the temple or a longer journey to explore the region. Our rental process is straightforward and hassle-free, providing you with the freedom to travel at your own pace while enjoying the sights and sounds of this spiritual destination."
    },
    {
        "name": "Shirdi Airport to Temple Distance Taxi Fare",
        "description": "We provide competitive taxi fares for the journey from Shirdi Airport to the temple. Our transparent pricing policy ensures that you know the cost upfront, allowing you to budget your trip effectively. Whether you're visiting for a day or planning an extended stay, our rates are designed to offer you the best value without compromising on the quality of service."
    },
    {
        "name": "Pune Airport to Shirdi Temple Taxi Service Fare",
        "description": "Our service includes competitive fares for taxi rides from Pune Airport to Shirdi Temple. Enjoy a seamless travel experience with our reliable cab service, ensuring timely pickups and comfortable rides. With us, you can relax and focus on your spiritual journey, knowing that your transportation needs are in good hands."
    },
    {
        "name": "Shirdi Airport to Trimbakeshwar Cab Service",
        "description": "Book a cab for a smooth journey from Shirdi Airport to Trimbakeshwar. Our dedicated cab service prioritizes your comfort and convenience, providing a reliable option to visit this sacred site. Our experienced drivers will guide you along the way, ensuring that you have a stress-free experience as you travel to one of the most revered temples in Maharashtra."
    },
    {
        "name": "SaitirthaTours & Travels",
        "description": "Choose SaitirthaTours & Travels for your transportation needs from Shirdi Airport and experience exceptional service and comfort. We are dedicated to making your journey enjoyable and stress-free, with a focus on customer satisfaction. Our professional team is always available to assist you with any travel-related queries, ensuring you have a memorable experience during your time in Shirdi."
    },
    {
        "name": "Shirdi Airport Cabs Contact Information",
        "description": "For bookings or inquiries, please contact SaitirthaTours & Travels at +91 9923879009 / +91 9922479009. Our friendly team is here to assist you with any questions you may have, providing support to ensure a smooth and enjoyable journey from the moment you arrive. We look forward to serving you on your journey and helping you create lasting memories!"
    }
],
tableData: [
    ["- Shirdi Airport Cab Service", "- Best Taxi Service in Shirdi"],
    ["- Innova Crysta on Rent in Shirdi Airport", "- Shirdi Airport Tempo Traveller on Rent"],
    ["- Shirdi Airport Cab Booking", "- Shirdi Airport Cab Booking Online"],
    ["- Shirdi Cab Packages", "- Cabs in Shirdi Airport"],
    ["- Ertiga on Rent in Shirdi Airport", "- Swift Dzire on Rent in Shirdi Airport"],
    ["- Outstation Cab in Shirdi", "- Cabs Service in Shirdi"],
    ["- Taxi Car Rentals in Shirdi", "- Shirdi Airport to Temple Distance Taxi Fare"],
    ["- Pune Airport to Shirdi Temple Taxi Service Fare", "- Shirdi Airport to Trimbakeshwar Cab Service"],
    ["- SaitirthaTours & Travels Shirdi", ""]
  ],


 whychoose: [
  {
    "WhyChooseheading": "Convenient Airport Transfers",
    "WhyChoosedescription": "We understand how stressful traveling can be, especially when you're navigating through airports. Our dedicated airport cab service offers convenient transfers between Shirdi Airport and your destination, ensuring a smooth and hassle-free experience. We’re here to make sure your arrival and departure are as seamless as possible, with no waiting times or confusion. Whether you're coming in for a pilgrimage or leaving Shirdi after your trip, our reliable taxi service guarantees a comfortable and timely journey."
  },
  {
    "WhyChooseheading": "Punctuality Guaranteed",
    "WhyChoosedescription": "We know how important it is to stick to your schedule, especially when you have a flight to catch. With Saitirtha Travels, you can rest assured that our drivers will be punctual, picking you up on time and getting you to your destination with no delays. We monitor all flights to ensure that we adjust our services based on any changes, ensuring you never miss a flight or have to wait for your cab. Trust our timely service to give you peace of mind throughout your journey."
  },
  {
    "WhyChooseheading": "Wide Range of Vehicles",
    "WhyChoosedescription": "Our fleet includes a variety of vehicles to meet every passenger's needs, from solo travelers to large groups. Choose from premium sedans, spacious SUVs, or larger vehicles like tempo travelers and mini-buses for group trips. We also offer specialized vehicles like the Innova Crysta or Ertiga for those seeking extra space or luxury. Regardless of your group size, we ensure that you travel in comfort and style, with modern amenities and air conditioning in all vehicles."
  },
  {
    "WhyChooseheading": "Professional Drivers",
    "WhyChoosedescription": "All our drivers are fully trained professionals with extensive knowledge of Shirdi and surrounding areas. They are skilled in navigating the roads, ensuring a smooth and safe journey for every passenger. Our drivers are courteous, well-versed with local routes, and are committed to your comfort and safety. They understand the importance of making your trip enjoyable, and their friendly demeanor will ensure you feel comfortable throughout the ride."
  },
  {
    "WhyChooseheading": "Competitive Pricing",
    "WhyChoosedescription": "We believe in providing transparent and competitive pricing that gives you the best value for your money. Whether you’re booking a one-way trip or a round trip, we offer affordable rates with no hidden charges. Unlike other services, we provide upfront quotes, so you always know the exact cost before you book your ride. With us, you can be confident that you are getting a premium service without the premium price tag."
  },
  {
    "WhyChooseheading": "24/7 Availability",
    "WhyChoosedescription": "We operate 24/7, meaning that no matter what time your flight arrives or departs, we will be there to provide reliable transportation. Early morning flights? Late-night arrivals? No problem. Our round-the-clock service ensures you can book a ride at your convenience, whenever you need it. With flexible scheduling, you never have to worry about timing – we’re always ready when you are."
  },
  {
    "WhyChooseheading": "Easy Online Booking",
    "WhyChoosedescription": "Our booking process is designed to be simple and convenient. You can reserve your cab through our easy-to-use online platform or by calling our customer service team for assistance. With just a few clicks, you can choose your vehicle, enter your travel details, and have your ride confirmed instantly. We also offer the flexibility of booking in advance or last-minute reservations, ensuring a smooth experience no matter how you choose to plan your trip."
  },
  {
    "WhyChooseheading": "Clean and Comfortable Vehicles",
    "WhyChoosedescription": "We place a high priority on cleanliness and passenger comfort. All of our vehicles are sanitized regularly to ensure they are clean and hygienic for every ride. Our vehicles are equipped with air conditioning and spacious seating, ensuring that your journey is both comfortable and pleasant, no matter how long or short. Whether you're traveling for business or leisure, we guarantee a smooth, clean, and relaxed ride."
  },
  {
    "WhyChooseheading": "Flexible Scheduling",
    "WhyChoosedescription": "We understand that plans can change unexpectedly. Whether you need to shift your travel time or make last-minute changes, our flexible scheduling options allow you to adjust your booking as per your convenience. We make it easy to rebook or change your pick-up and drop-off locations, providing you with the freedom to adapt to your travel needs without any extra hassle."
  },
  {
    "WhyChooseheading": "Customer Satisfaction Focus",
    "WhyChoosedescription": "Our dedication to customer satisfaction is at the heart of everything we do. We prioritize delivering exceptional service from start to finish, ensuring that your experience with us is nothing short of excellent. From booking your ride to reaching your destination, our goal is to provide a seamless, stress-free journey. Our friendly customer support team is available 24/7 to address any inquiries or special requests, ensuring that all your needs are met with a personal touch."
  }
]





        
  }


  const faqData = [
    {
        question: "How far is Shirdi Airport from Shirdi?",
        answer: "Shirdi Airport is approximately 14 kilometers from the town of Shirdi."
    },
    {
        question: "What types of cabs are available for airport transfers?",
        answer: "We offer various types of vehicles, including sedans, SUVs, and luxury cars, to cater to different group sizes and preferences."
    },
    {
        question: "How do I book a cab from Shirdi Airport?",
        answer: "You can book a cab online through our website or contact our customer service team for assistance."
    },
    {
        question: "Are there additional charges for late-night airport transfers?",
        answer: "No, we have a transparent pricing policy, and late-night transfers are charged at the same rate."
    },
    {
        question: "Can I book a round trip from the airport?",
        answer: "Yes, we provide round-trip cab services from Shirdi Airport to your destination and back."
    },
    {
        question: "What is the cancellation policy for airport cab bookings?",
        answer: "We have a flexible cancellation policy; please reach out to our team for details."
    },
    {
        question: "Is it safe to travel with Saitirtha Travels?",
        answer: "Yes, we prioritize passenger safety with well-maintained vehicles and experienced drivers."
    },
    {
        question: "Do you provide child seats if needed?",
        answer: "Yes, we can provide child seats upon request; please inform us at the time of booking."
    },
    {
        question: "How can I contact customer support?",
        answer: "You can reach our customer support team via phone or email for any inquiries or assistance."
    },
    {
        question: "What payment methods do you accept?",
        answer: "We accept various payment methods, including cash, credit/debit cards, and online payments."
    }
];

const testimonials = [
    {
        name: "Mr. Nitin Rao",
        text: "I had a fantastic experience with Saitirtha Travels for my ride from Shirdi Airport. The cab was ready and waiting for me upon my arrival, which made everything so convenient. The vehicle was clean and comfortable, and the driver was friendly and professional. I highly recommend their services for anyone flying into Shirdi!"
    },
    {
        name: "Ms. Aditi Patil",
        text: "Saitirtha Travels provided excellent cab service from Shirdi Airport. The booking process was simple, and the driver was punctual and courteous. The car was in great condition, and I felt safe throughout the journey. I will definitely use Saitirtha Travels for my future trips!"
    }
];





  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Shirdi Airport Cabs | Best Taxi Service in Shirdi</title>
    <meta name="description" content="Book Shirdi Airport Cabs with Saitirtha Travels. We offer reliable and affordable taxi services, including Innova, Ertiga, Swift Dzire, and Tempo Traveller rentals. Explore Shirdi and nearby places with our convenient cab booking options." />
    <meta name="keywords" content="Shirdi Airport Cabs, Shirdi airport cab service, Best taxi service in Shirdi, Innova crysta on rent in Shirdi airport, Shirdi airport tempo traveller on rent, Shirdi airport cab booking, Shirdi cab packages, Cabs in Shirdi airport, Ertiga on rent in Shirdi airport, Swift Dzire on rent in Shirdi airport, Outstation cab in Shirdi, Taxi Car Rentals in Shirdi, Shirdi airport to temple distance taxi fare, Pune airport to Shirdi temple taxi service fare, Shirdi airport to Trimbakeshwar cab service, SaitirthaTours & Travels Shirdi" />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirtha Travels | Shirdi Airport Cabs | Best Taxi Service in Shirdi" />
    <meta property="og:description" content="Book Shirdi Airport Cabs with Saitirtha Travels. We provide convenient and affordable taxi services to Shirdi, including premium cars and outstation taxis. Explore Shirdi, Trimbakeshwar, and other nearby destinations." />
    <meta property="og:url" content="https://saitirthatravels.com/shirdi-airport-cabs" />
    <meta property="og:image" content="https://saitirthatravels.com/images/shirdi-airport-cabs.jpg" />
    <meta name="twitter:card" content="summary_large_image" />
    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Book Shirdi Airport Cabs with Saitirtha Travels. We offer reliable and affordable taxi services from Shirdi Airport, including Innova, Swift Dzire, Ertiga, and Tempo Traveller rentals. Convenient outstation services to Trimbakeshwar and other destinations.",
            "url": "https://saitirthatravels.com/shirdi-airport-cabs",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/shirdi-airport-cabs.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "250"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi Airport Cabs",
                "Shirdi airport cab service",
                "Best taxi service in Shirdi",
                "Innova crysta on rent in Shirdi airport",
                "Shirdi airport tempo traveller on rent",
                "Shirdi airport cab booking",
                "Shirdi cab packages",
                "Cabs in Shirdi airport",
                "Ertiga on rent in Shirdi airport",
                "Swift Dzire on rent in Shirdi airport",
                "Outstation cab in Shirdi",
                "Taxi Car Rentals in Shirdi",
                "Shirdi airport to temple distance taxi fare",
                "Pune airport to Shirdi temple taxi service fare",
                "Shirdi airport to Trimbakeshwar cab service"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/87.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirdiairportcab;