
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Shirdisaibaba() {



  const cardData =
  {
    keyword: ' Shirdi Saibaba Taxi Booking Online',
    heading: 'Saitirtha Travels: Shirdi Saibaba Taxi Booking Online',
    headingDescription: 'When it comes to traveling to Shirdi, the birthplace of Saibaba, Saitirtha Travels offers the best taxi booking services online. We understand that your journey should be comfortable, convenient, and filled with spiritual significance. Our online booking system allows you to easily reserve your taxi, ensuring that you have a seamless travel experience to one of India’s most revered pilgrimage sites.',

    top: 'Top Places to Visit in Shirdi with Saitirtha Travels',

    topPlaces: [
        {
            "title": "1. Shirdi Saibaba Temple",
            "description": "The Shirdi Saibaba Temple is the heart of Shirdi and one of the most visited pilgrimage sites in India. This magnificent temple, built in honor of the saint, attracts millions of devotees each year. The temple complex features the iconic Samadhi Mandir, where Saibaba’s mortal remains are interred, and the beautiful idol of Saibaba, which is adorned with flowers and jewels. The spiritual ambiance and daily rituals create a serene environment for visitors to offer their prayers and experience divine blessings."
        },
        {
            "title": "2. Dwarkamai Masjid",
            "description": "Dwarkamai Masjid is a significant site closely associated with Saibaba. It was here that he spent a considerable part of his life, offering solace to devotees. The masjid houses the sacred Dhuni, a fire that Saibaba tended to, symbolizing selfless service and love. Visitors can reflect on Saibaba’s teachings while admiring the simple yet profound atmosphere of this historic site. The presence of a beautiful stone statue of Saibaba further enhances the spiritual experience."
        },
        {
            "title": "3. Chavadi",
            "description": "Chavadi is another important site for devotees, where Saibaba used to rest every alternate night. This place holds immense significance in the lives of his followers and features an idol of Saibaba dressed in a traditional robe. The Chavadi also showcases various artifacts from Saibaba’s life, providing visitors a glimpse into his daily routines and practices. The spiritual energy of Chavadi makes it a must-visit spot for anyone seeking a deeper connection with Saibaba."
        },
        {
            "title": "4. Shri Sai Baba Sansthan Trust",
            "description": "The Shri Sai Baba Sansthan Trust manages the temple and various charitable activities associated with Saibaba’s teachings. Visitors can learn about the trust’s initiatives that aim to serve the community, including healthcare, education, and social welfare programs. Engaging with the trust’s activities offers a unique opportunity to witness the impact of Saibaba’s teachings on the lives of many."
        },
        {
            "title": "5. Lendi Baug",
            "description": "Lendi Baug is a picturesque garden created by Saibaba. It features a beautiful well, a banyan tree, and a small temple dedicated to him. This serene garden is an ideal retreat for visitors looking to relax and reflect amidst nature. The calm environment encourages meditation and contemplation, making it a perfect spot for those seeking spiritual solace. The garden is also home to several unique plants and flowers, enhancing its natural beauty."
        },
        {
            "title": "6. Samadhi Mandir",
            "description": "The Samadhi Mandir is the final resting place of Saibaba and an architectural marvel. The temple is adorned with intricate carvings and beautiful marble work, creating a serene atmosphere for devotees. Visitors can pay their respects at the Samadhi, experiencing a profound sense of peace and devotion. The daily aarti (prayer ceremony) held here is a spiritually uplifting experience that draws many devotees and offers a glimpse into the deep love and reverence people have for Saibaba."
        },
        {
            "title": "7. Sai Heritage Village",
            "description": "Sai Heritage Village is an attractive site showcasing the life and teachings of Saibaba through exhibitions and displays. The village features various replicas of significant places from Saibaba's life, creating an engaging environment for visitors to learn about his teachings and philosophy. It’s an educational experience for both adults and children, highlighting the importance of love, faith, and service in Saibaba's teachings."
        },
        {
            "title": "8. Khandoba Mandir",
            "description": "Khandoba Mandir, dedicated to Lord Khandoba, is an ancient temple situated near Shirdi. This temple is historically significant as it is believed that Saibaba was once a devotee of Lord Khandoba. The temple's architecture and tranquil surroundings attract visitors who seek blessings from the deity. The vibrant festivities during festivals draw a large crowd, adding to the temple's charm."
        },
        {
            "title": "9. Gurusthan",
            "description": "Gurusthan is a sacred site where Saibaba was said to have first appeared in Shirdi. A beautiful stone idol of Saibaba is placed under a tree, symbolizing his divine presence. The site is often frequented by devotees who come to meditate and seek blessings. The peaceful atmosphere makes it a perfect location for reflection and connection with Saibaba."
        },
        {
            "title": "10. Shri Saibaba Museum",
            "description": "The Shri Saibaba Museum showcases a collection of personal belongings and artifacts associated with Saibaba. The museum offers insights into his life and teachings, featuring photographs, manuscripts, and items used by him. Visiting the museum provides a deeper understanding of Saibaba’s profound impact on his devotees and the rich history of the Shirdi pilgrimage."
        }
    ],    
    
            
        
    
   "services": [
        {
            "name": "Shirdi to Trimbakeshwar Taxi Fare",
            "description": "Understanding the Shirdi to Trimbakeshwar cab fare is essential for your travel budget. Our fares are not only competitive but also designed to ensure transparency, allowing you to plan your pilgrimage or sightseeing without financial worries. We provide detailed fare breakdowns and options for various vehicle types, ensuring you choose the right fit for your journey."
        },
        {
            "name": "Shirdi to Trimbakeshwar Taxi Service",
            "description": "Our Shirdi to Trimbakeshwar taxi service offers reliable transportation tailored to your schedule. With a focus on customer satisfaction, our skilled drivers are trained to provide a safe and pleasant journey, whether you’re making a one-way trip or planning a round trip. Enjoy the convenience of door-to-door service and relax knowing you’re in capable hands."
        },
        {
            "name": "SaitirthaTours & Travels Shirdi",
            "description": "At SaitirthaTours & Travels, we pride ourselves on our customer service and commitment to quality. Our fleet is well-maintained, ensuring a comfortable ride from Shirdi to Trimbakeshwar. Our knowledgeable team is always available to assist you with your travel plans, ensuring that every aspect of your journey meets your expectations and needs."
        },
        {
            "name": "Shirdi Trimbakeshwar Car Rental",
            "description": "If you prefer more flexibility, consider our Shirdi Trimbakeshwar car rental options. This allows you to explore at your own pace while enjoying the breathtaking landscapes along the way. Choose from a variety of well-maintained vehicles, from compact cars to spacious SUVs, perfect for any group size and travel style."
        },
        {
            "name": "Shirdi to Trimbakeshwar One Way Taxi",
            "description": "Our Shirdi to Trimbakeshwar one-way taxi service is perfect for those looking for a straightforward journey without the hassle of returning to Shirdi immediately. With no need to plan for a round trip, you can enjoy the ride and focus on your destination, making the most of your time at the temple."
        },
        {
            "name": "Shirdi to Trimbakeshwar Cab Booking",
            "description": "Booking your Shirdi to Trimbakeshwar cab is easy with our user-friendly online platform. Reserve your vehicle in advance or contact our customer service for personalized assistance. Our booking system allows you to select your preferred vehicle, view real-time availability, and make secure payments, ensuring a seamless booking experience."
        },
        {
            "name": "Shirdi Trimbakeshwar Cab Rates",
            "description": "We offer competitive Shirdi Trimbakeshwar cab rates to suit different budgets. Our pricing structure is transparent, ensuring you know what to expect before you travel. Additionally, we provide special discounts for group bookings and frequent travelers, making it easier for everyone to embark on their spiritual journeys."
        },
        {
            "name": "Shirdi to Trimbakeshwar Taxi Fare per KM",
            "description": "Our Shirdi to Trimbakeshwar taxi fare per km is clearly laid out, allowing you to budget your travel expenses efficiently. You can rest assured that our pricing is among the most competitive in the region, providing great value without compromising on service quality."
        },
        {
            "name": "Affordable Shirdi Trimbakeshwar Cab",
            "description": "Looking for an affordable Shirdi Trimbakeshwar cab? We strive to provide the best service at rates that won't break the bank, making your spiritual journey accessible and enjoyable. We believe everyone should have the opportunity to experience the tranquility of Trimbakeshwar, which is why our pricing is designed to accommodate various financial plans."
        },
        {
            "name": "Shirdi to Nasik Trimbakeshwar Taxi Fare",
            "description": "If your travels include a stop in Nasik, our Shirdi to Nasik Trimbakeshwar taxi fare is designed to give you flexibility while maintaining affordability for your travel plans. We provide detailed information on routes, travel time, and fare estimates, so you can plan accordingly and enjoy your pilgrimage without worries."
        },
        {
            "name": "Trimbakeshwar to Shirdi Taxi Fare",
            "description": "For return trips, our Trimbakeshwar to Shirdi taxi fare options are equally competitive. We ensure that you have a pleasant return journey filled with reflections of your spiritual experience. Our drivers are familiar with the best routes, providing a timely and comfortable ride back to Shirdi."
        },
        {
            "name": "From Shirdi to Trimbakeshwar by Taxi",
            "description": "Traveling from Shirdi to Trimbakeshwar by taxi provides a direct and personalized experience. Our drivers are well-acquainted with the best routes, ensuring you enjoy a smooth ride. This direct service eliminates the stress of navigating public transportation, allowing you to sit back, relax, and focus on your spiritual journey."
        },
        {
            "name": "Taxi Fare from Shirdi to Trimbakeshwar",
            "description": "Our taxi fare from Shirdi to Trimbakeshwar is structured to provide great value, ensuring you can focus on your spiritual pursuits without worrying about transportation costs. We offer clear fare policies and upfront pricing, so you can travel with confidence knowing there are no hidden fees."
        },
        {
            "name": "Shirdi to Trimbakeshwar Innova Cab",
            "description": "For families or larger groups, our Shirdi to Trimbakeshwar Innova cab service is perfect. Spacious and comfortable, the Innova ensures everyone travels together comfortably. Our drivers are experienced in handling group dynamics, making your journey enjoyable and hassle-free."
        },
        {
            "name": "Shirdi to Trimbakeshwar Tempo Traveller on Rent",
            "description": "If you’re planning to travel with a bigger group, our Shirdi to Trimbakeshwar tempo traveller on rent option is ideal. It provides ample space for everyone to enjoy the journey together while accommodating luggage and personal belongings comfortably."
        },
        {
            "name": "Shirdi to Trimbakeshwar Contact Number",
            "description": "Contact SaitirthaTours & Travels at +91 9923879009 / +91 9922479009 for prompt and efficient Shirdi to Trimbakeshwar services. Our customer service team is ready to assist you with inquiries, bookings, and any other travel needs, ensuring a smooth and enjoyable ride for all our customers."
        }
    ],
    "tableData": [
        ["- Shirdi Saibaba Taxi Service", "- Shirdi Saibaba Cab Booking Online"],
        ["- SaitirthaTours & Travels Shirdi", "- Online Taxi Booking for Shirdi Saibaba"],
        ["- Shirdi Saibaba Car Rental", "- Shirdi Saibaba Taxi Online Booking"],
        ["- Shirdi Saibaba Taxi Fare", "- Shirdi Saibaba Cab Service Online"],
        ["- Book Taxi to Shirdi Saibaba Temple", "- Shirdi Saibaba Travel by Taxi"],
        ["- Shirdi Taxi Booking Online", "- Shirdi Airport Taxi Booking"],
        ["- Shirdi Airport Online Taxi Booking", "- Shani Shingnapur Online Taxi Booking"],
        ["- Online Cab Booking in Ahmednagar", "- Taxi Booking in Ahilyanagar"],
        ["- Affordable Taxi Near Me", "- Affordable Taxi Service in Shirdi"],
        ["- Cheapest Taxi Service in Shirdi", "-"]
    ],

    "whychoose": [
        {
            "WhyChooseheading": "Easy Online Booking:",
            "WhyChoosedescription": "Our user-friendly online booking system makes it simple to reserve your taxi for your visit to Shirdi, saving you time and effort. With just a few clicks, you can select your desired vehicle, choose your travel dates, and confirm your booking instantly."
        },
        {
            "WhyChooseheading": "Reliable Service:",
            "WhyChoosedescription": "We are committed to providing reliable transportation services for your pilgrimage to Shirdi, ensuring you arrive at your destination safely and on time. Our punctuality and consistency in service have earned us a loyal customer base."
        },
        {
            "WhyChooseheading": "Professional Drivers:",
            "WhyChoosedescription": "Our experienced drivers are familiar with the area and committed to your comfort and safety, providing a pleasant journey to and from the temple. They are trained to handle various driving conditions and prioritize customer satisfaction throughout the ride."
        },
        {
            "WhyChooseheading": "Variety of Vehicle Options:",
            "WhyChoosedescription": "Choose from a range of vehicle types to suit your group size and travel needs, from compact cars to spacious vans. We also offer premium vehicles for those looking for a more luxurious travel experience."
        },
        {
            "WhyChooseheading": "Transparent Pricing:",
            "WhyChoosedescription": "We offer competitive rates with clear, upfront pricing and no hidden charges, so you can plan your budget accordingly. Our pricing structure is straightforward, allowing you to see exactly what you’re paying for."
        },
        {
            "WhyChooseheading": "24/7 Customer Support:",
            "WhyChoosedescription": "Our dedicated customer support team is available around the clock to assist you with any inquiries or changes to your booking. Whether you have a last-minute question or need assistance during your trip, we are just a call away."
        },
        {
            "WhyChooseheading": "Safety and Hygiene Measures:",
            "WhyChoosedescription": "We prioritize your safety with regular vehicle inspections and adherence to hygiene protocols. Our vehicles are thoroughly cleaned and sanitized after each trip, ensuring a safe environment for all passengers."
        },
        {
            "WhyChooseheading": "Flexible Travel Options:",
            "WhyChoosedescription": "We understand that every traveler has unique needs. Our service allows you to customize your itinerary, whether you need to make additional stops or require a specific type of vehicle for your journey."
        },
        {
            "WhyChooseheading": "Local Knowledge:",
            "WhyChoosedescription": "Our drivers are not only skilled but also knowledgeable about the local area. They can provide recommendations on attractions and local dining options, enhancing your overall travel experience."
        },
        {
            "WhyChooseheading": "Feedback-Driven Improvements:",
            "WhyChoosedescription": "We actively seek feedback from our customers to enhance our services. Your insights help us refine our offerings and ensure we meet your travel needs effectively. We value your opinion and continuously strive to improve."
        },
        {
            "WhyChooseheading": "Commitment to Customer Satisfaction:",
            "WhyChoosedescription": "Our goal is to ensure that you have a delightful experience from start to finish. We listen to your feedback and continuously improve our services to meet your expectations."
        },
        {
            "WhyChooseheading": "Special Packages and Offers:",
            "WhyChoosedescription": "We frequently offer special packages and discounts for various routes, including group bookings and seasonal promotions. This allows you to enjoy quality service at even more affordable rates."
        },
        {
            "WhyChooseheading": "Eco-Friendly Practices:",
            "WhyChoosedescription": "We are committed to sustainability and incorporate eco-friendly practices into our operations. Our fleet includes vehicles that are fuel-efficient, reducing our carbon footprint while you travel."
        },
        {
            "WhyChooseheading": "Seamless Payment Options:",
            "WhyChoosedescription": "We provide various payment methods for your convenience, including online payment, mobile wallets, and cash. Choose what works best for you to make the payment process easy."
        },
        {
            "WhyChooseheading": "Loyalty Rewards Program:",
            "WhyChoosedescription": "Join our loyalty program to earn points for every ride you take with us. Redeem these points for discounts or free rides, rewarding you for choosing Saitirtha Travels repeatedly."
        },
        {
            "WhyChooseheading": "Experienced Management Team:",
            "WhyChoosedescription": "Our experienced management team ensures that all operations run smoothly. They oversee every aspect of the service, from vehicle maintenance to customer relations, for a seamless experience."
        }
    ]
        
  }


  const faqData = [
    {
        question: "How do I book a taxi online for my visit to Shirdi?",
        answer: "You can easily book your taxi through our website by filling out the online booking form, or you can contact our customer service team for assistance."
    },
    {
        question: "What types of vehicles are available for booking?",
        answer: "We offer a variety of vehicles including sedans, SUVs, and larger vans to accommodate different group sizes and preferences."
    },
    {
        question: "What is the fare for a taxi to Shirdi?",
        answer: "Fares vary depending on the type of vehicle and distance traveled. Please check our website for the most current rates or contact us for more information."
    },
    {
        question: "Are there any additional charges?",
        answer: "Additional charges may apply for nighttime travel or for any extra stops requested during your journey."
    },
    {
        question: "What payment methods are accepted for online bookings?",
        answer: "We accept various payment methods including credit/debit cards, UPI, and cash for your convenience."
    },
    {
        question: "Can I modify or cancel my online booking?",
        answer: "Yes, you can modify or cancel your booking through our website or by contacting our customer service team, subject to our cancellation policy."
    },
    {
        question: "Is my taxi sanitized before each trip?",
        answer: "Yes, all our vehicles are thoroughly cleaned and sanitized before each trip to ensure your safety and comfort."
    },
    {
        question: "What should I do if I have specific requests for my taxi service?",
        answer: "Please mention any specific requests during the booking process, and we will do our best to accommodate your needs."
    }
];

const testimonials = [
    {
        name: "Mr. Vinay Patil",
        text: "I booked a taxi online with Saitirtha Travels for my trip to Shirdi, and it was a seamless experience. The booking process was straightforward, and the driver was punctual and professional. Highly recommend!"
    },
    {
        name: "Ms. Riya Sharma",
        text: "Saitirtha Travels made my pilgrimage to Shirdi easy and enjoyable. The online booking was hassle-free, and the vehicle was clean and comfortable. I will definitely use their services again!"
    }
];

  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>

<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Shirdi Saibaba Taxi Booking Online | SaitirthaTours & Travels</title>
    <meta name="description" content="Book your Shirdi Saibaba taxi online with SaitirthaTours & Travels. Affordable, reliable, and comfortable taxi services to Shirdi Saibaba temple and surrounding areas. Easy online booking, fast confirmation." />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirtha Travels | Shirdi Saibaba Taxi Booking Online | SaitirthaTours & Travels" />
    <meta property="og:description" content="Book your Shirdi Saibaba taxi online with SaitirthaTours & Travels. Affordable, reliable, and comfortable taxi services to Shirdi Saibaba temple and surrounding areas. Easy online booking, fast confirmation." />
    <meta property="og:url" content="https://saitirthatravels.com/shirdi-saibaba-taxi-booking-online" />
    <meta property="og:image" content="https://saitirthatravels.com/images/shirdi-saibaba-taxi.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Book your Shirdi Saibaba taxi online with SaitirthaTours & Travels. Affordable, reliable, and comfortable taxi services to Shirdi Saibaba temple and surrounding areas. Easy online booking, fast confirmation.",
            "url": "https://saitirthatravels.com/shirdi-saibaba-taxi-booking-online",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/shirdi-saibaba-taxi.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi Saibaba Taxi Service", 
                "Shirdi Saibaba Cab Booking Online", 
                "SaitirthaTours & Travels Shirdi", 
                "Online Taxi Booking for Shirdi Saibaba", 
                "Shirdi Saibaba Car Rental", 
                "Shirdi Saibaba Taxi Online Booking", 
                "Shirdi Saibaba Taxi Fare", 
                "Shirdi Saibaba Cab Service Online", 
                "Book Taxi to Shirdi Saibaba Temple", 
                "Shirdi Saibaba Travel by Taxi", 
                "Shirdi Taxi Booking Online", 
                "Shirdi Taxi Booking Online", 
                "Shirdi Airport Taxi Booking", 
                "Shirdi Airport Online Taxi Booking", 
                "Shani Shingnapur Online Taxi Booking", 
                "Online Cab Booking in Ahmednagar", 
                "Taxi Booking in Ahilyanagar", 
                "Affordable Taxi Near Me", 
                "Affordable Taxi Service in Shirdi", 
                "Cheapest Taxi Service in Shirdi"
            ]
        })}
    </script>
</Helmet>

      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/29.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirdisaibaba;