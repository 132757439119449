
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Forceurbaniaonrentinshirdi() {



  const cardData =
  {
    keyword: ' Force Urbania on Rent in Shirdi',
    heading: 'Saitirtha Travels: Force Urbania on Rent in Shirdi',
    headingDescription: 'Saitirtha Travels proudly offers the Force Urbania for rent in Shirdi, a vehicle designed for comfort and spaciousness, making it an excellent choice for families, groups, or pilgrims traveling together. The Force Urbania is equipped with modern amenities that ensure a smooth ride, allowing you to relax and enjoy the journey without any worries. Whether you are visiting Shirdi for spiritual reasons or exploring the beautiful surroundings, our rental service provides the flexibility and convenience you need.',

    top: 'Top Places to Visit in Shirdi with SaitirthaTravel',

    topPlaces: [
        {
            "title": "Sai Baba Temple",
            "description": "The Sai Baba Temple is the heart of Shirdi, attracting millions of visitors every year. This magnificent temple is renowned for its serene ambiance and stunning architecture, featuring a white marble statue of Sai Baba that is beautifully adorned with flowers and offerings from devotees. Visitors can participate in the daily aarti (worship ceremony), feeling the spiritual energy that permeates the atmosphere. The temple complex is equipped with facilities for devotees, including comfortable accommodation and dining options, ensuring a fulfilling spiritual journey for all who visit."
        },
        {
            "title": "Khandoba Mandir",
            "description": "Perched atop a hill, Khandoba Mandir is dedicated to Lord Khandoba, a revered deity in Maharashtra. This temple not only offers breathtaking panoramic views of the surrounding landscape but also stands out for its historical significance. Known for its vibrant festivals that attract large crowds, the temple showcases traditional architectural designs, making it a beautiful spot for photography and exploration. The climb to the temple is invigorating, rewarding visitors with a sense of peace and reverence."
        },
        {
            "title": "Lendi Baug",
            "description": "Lendi Baug is a lush garden that Sai Baba frequently visited, known for its tranquil setting and beautiful trees. Visitors can take a leisurely stroll through the garden, enjoying the peaceful surroundings while visiting the sacred well believed to possess healing properties. The garden is also home to various species of plants and flowers, creating a serene escape from the hustle and bustle of the town. It’s an ideal spot for meditation, reflection, or simply enjoying nature's beauty."
        },
        {
            "title": "Dixit Wada Museum",
            "description": "Located within the Sai Baba Temple complex, the Dixit Wada Museum houses a rich collection of artifacts related to Sai Baba's life, including photographs, personal items, and writings. This museum offers visitors a unique opportunity to delve deeper into the teachings and life of Sai Baba, enhancing their understanding of his profound impact on spirituality in India. The displays provide valuable context about his philosophy and the historical significance of his presence in Shirdi."
        },
        {
            "title": "Gurusthan",
            "description": "Gurusthan is a revered site that marks the place where Sai Baba first made his public appearance. This sacred location features a shrine, a holy tree, and a tranquil environment conducive to meditation and reflection. Many devotees visit Gurusthan to pay their respects and seek blessings, adding to the spiritual experience of their journey. The serene atmosphere allows for personal contemplation, making it a cherished stop for all who come to Shirdi."
        },
        {
            "title": "Shri Saibaba Sansthan Trust",
            "description": "The Shri Saibaba Sansthan Trust plays a vital role in managing the Sai Baba Temple and its charitable initiatives. Visitors can learn about the various community service projects, including healthcare, education, and social welfare programs, which exemplify Sai Baba's teachings of love and compassion. The trust’s efforts in serving the needy are a testament to Sai Baba's legacy, making it a significant aspect of any visit to Shirdi."
        },
        {
            "title": "Manmad Fort",
            "description": "Located just a short drive from Shirdi, Manmad Fort offers a fascinating glimpse into the region’s rich history. The fort is strategically positioned, providing stunning views of the surrounding area and serving as a reminder of the past. It’s an excellent spot for history buffs and adventure seekers, with opportunities for trekking and exploring the fort's ruins. The site’s historical significance and natural beauty make it a rewarding destination for those looking to experience the region beyond its religious offerings."
        },
        {
            "title": "Nashik Caves",
            "description": "The Nashik Caves, located nearby, are ancient rock-cut caves that date back to historic times. Renowned for their exquisite carvings and sculptures, these caves reflect the artistic skills of their creators. A guided tour of the caves reveals their historical significance and the legends associated with them, making it a fascinating excursion for visitors. The caves are a perfect blend of history, art, and spirituality, adding depth to any itinerary while exploring the Shirdi area."
        },
        {
            "title": "Khandala Waterfall",
            "description": "Just a short drive from Shirdi, Khandala Waterfall is a hidden gem celebrated for its breathtaking beauty, particularly during the monsoon season. The waterfall cascades down amid lush greenery, creating a picturesque setting perfect for picnics, photography, and enjoying the tranquility of nature. It’s a popular spot for families and nature enthusiasts looking to escape the city, offering a refreshing retreat and an opportunity to immerse in the serene ambiance of the surrounding landscapes."
        },
        {
            "title": "Shirdi Market",
            "description": "Immerse yourself in the local culture by visiting the bustling Shirdi Market. Here, you can find a variety of souvenirs, religious items, and traditional snacks that provide a glimpse into the everyday life of the locals. The market is vibrant and lively, offering visitors an opportunity to interact with shopkeepers and sample local delicacies. Whether you’re looking for mementos of your visit or simply want to soak in the atmosphere, the market is a lively end to your spiritual journey in Shirdi."
        }
    ],  
    
            
  "services": [
    {
        "name": "Force Urbania On Rent in Shirdi",
        "description": "Explore the beauty of Shirdi and its surrounding areas with our Force Urbania rental service, ideal for families and larger groups seeking a comfortable and memorable travel experience. At Sai Tirth Cabs, we offer well-maintained Force Urbania vehicles, ensuring spacious seating, ample luggage space, and top-notch amenities for all passengers. Our drivers are experienced and familiar with local routes, providing a hassle-free, scenic journey whether you're visiting temples, attractions, or simply enjoying the landscapes. Perfect for group outings, day tours, or extended travel in and around Shirdi."
    },
    {
        "name": "Force Urbania on Rent in Shirdi Contact Number",
        "description": "For seamless and easy bookings, reach out to us at +91 9923879009 / 9922479009. Our friendly and knowledgeable customer support team is available to assist with rental inquiries, itinerary planning, and ensuring you find the best vehicle option for your group’s needs. We pride ourselves on prompt communication and are dedicated to making your transportation experience as smooth as possible from start to finish."
    },
    {
        "name": "Force Urbania on Rent in Manmad",
        "description": "For travelers heading from Shirdi to Manmad, our Force Urbania rental service offers an ideal solution for groups needing extra space and comfort. Our rentals provide high-quality seating, air conditioning, and ample legroom, allowing you to enjoy the scenic routes between these cities. Our experienced drivers prioritize safety and comfort, ensuring timely and efficient service without compromising on quality. Let us handle the driving so you can relax, chat, and enjoy the journey together."
    },
    {
        "name": "Force Urbania on Rent in Shani Shingnapur",
        "description": "Plan a convenient and enjoyable visit to Shani Shingnapur with our spacious Force Urbania rentals, offering plenty of room for groups of all sizes. Designed to provide comfort on longer routes, this vehicle ensures everyone in your party can sit back and unwind. Our drivers are familiar with the best routes to this popular pilgrimage site, allowing you to travel at ease and reach your destination without any stress. This service is perfect for those looking to explore with friends or family, with sufficient space for personal items and bags."
    },
    {
        "name": "Shirdi to Bhimashankar Urbania on Rent",
        "description": "Embark on a scenic journey from Shirdi to Bhimashankar with our Force Urbania rental service, built for group travel. Our Urbania vehicles are equipped with comfortable seating, air conditioning, and plenty of room for passengers and luggage, making this an ideal choice for religious pilgrims, family vacations, or group outings. Our drivers take the safest, most efficient routes, ensuring a relaxed travel experience so you can focus on your trip's highlights. Enjoy the beauty of Maharashtra with us as we provide a reliable and comfortable ride."
    },
    {
        "name": "Shirdi to Nashik Force Urbania on Rent",
        "description": "Travel from Shirdi to Nashik in style and comfort with our Force Urbania rental. This spacious vehicle is designed for group travel, making it perfect for family trips, pilgrimages, or friend getaways. With comfortable seating, a smooth ride, and a well-maintained interior, our Urbania ensures everyone in your group has an enjoyable experience on the road. Our drivers are experienced in handling group travel and offer friendly, professional service, so you can simply relax and look forward to the adventures that await you in Nashik."
    },
    {
        "name": "Force Urbania on Rent in Nashik",
        "description": "If you're in Nashik and looking for a spacious, reliable vehicle for your group travel needs, our Force Urbania rentals provide the perfect solution. Our vehicles are ideal for sightseeing, events, or even extended trips, with well-cushioned seating, ample space for luggage, and a reliable driver who knows the local routes. Whether you're exploring the vineyards, visiting temples, or planning a pilgrimage, our Force Urbania vehicles offer the comfort and space to make group travel seamless and enjoyable."
    },
    {
        "name": "Force Urbania on Rent in Shirdi Airport",
        "description": "Arriving at Shirdi Airport and in need of a group transportation solution? Our Force Urbania rentals offer ample space and comfort, ideal for larger families or groups who want a relaxing transfer to their destination. Our drivers will be ready at the airport, helping with luggage and ensuring a pleasant journey from the start. With spacious seating and a professional chauffeur, your experience will be smooth and comfortable, allowing you to begin your trip on a high note."
    },
    {
        "name": "Mini AC Bus on Rent in Shirdi Airport",
        "description": "For larger groups arriving at Shirdi Airport, our mini AC bus rentals are a convenient choice for a comfortable and affordable group transfer. Perfect for religious groups, families, or large tour groups, our buses offer air-conditioned comfort, ample legroom, and spacious seating. Our drivers are trained to assist with luggage and ensure timely, pleasant rides, allowing your group to travel comfortably from the airport to various destinations in Shirdi and nearby locations."
    },
    {
        "name": "Mini Bus On Rent in Shirdi",
        "description": "Ideal for sightseeing tours, group outings, and large family gatherings, our mini bus rental in Shirdi provides comfortable seating and reliable service. With our spacious mini buses, your group can travel together with plenty of room to relax. Whether you’re visiting temples, going on a pilgrimage, or exploring local attractions, our mini buses ensure a memorable and stress-free travel experience with ample space and dedicated service."
    },
    {
        "name": "Mini Luxury Bus on Rent in Shirdi",
        "description": "Add a touch of luxury to your group travel experience with our mini luxury bus rentals in Shirdi. With premium interiors, plush seating, air conditioning, and additional amenities, our mini luxury buses provide an upscale transportation experience. Perfect for corporate events, special occasions, and VIP groups, these buses allow you to travel in comfort and style, with enough room to relax and enjoy the ride. Our service is designed to make your travel truly memorable."
    },
    {
        "name": "13 Seater Urbania on Rent in Shirdi",
        "description": "Our 13-seater Force Urbania is the ideal choice for small group travel, offering plenty of space for passengers and luggage. With comfortable seating and modern amenities, this vehicle is perfect for family outings, pilgrimages, and sightseeing tours. Enjoy the spacious, clean interiors and a smooth ride as our experienced drivers ensure a safe and pleasant journey for everyone on board."
    },
    {
        "name": "17 Seater Urbania on Rent in Shirdi",
        "description": "Our 17-seater Force Urbania is a great option for larger groups who need extra space and comfort. Equipped with roomy seating and air conditioning, this vehicle is ideal for group travel, whether for pilgrimages, family trips, or sightseeing. With professional drivers and well-maintained vehicles, we guarantee a comfortable and enjoyable journey for all passengers, letting you focus on the fun of your trip."
    },
    {
        "name": "Luxury Force Urbania on Rent In Shirdi",
        "description": "Travel in luxury with our high-end Force Urbania rentals, designed for those who prioritize comfort and elegance. With upscale interiors, spacious seating, and premium amenities, our luxury Urbania vehicles provide a sophisticated travel experience. Perfect for VIP events, special occasions, or corporate trips, our luxury rentals ensure you and your group travel in style and comfort."
    },
    {
        "name": "Force Urbania on Rent In Shirdi Packages",
        "description": "We offer various customizable packages for Force Urbania rentals in Shirdi to suit your specific travel needs. Whether for day tours, multi-day excursions, or custom group itineraries, our packages provide flexibility and convenience. Reach out to us for details and let us tailor the perfect rental package to match your itinerary, group size, and budget."
    },
    {
        "name": "Force Urbania on Rent in Shirdi Contact Information",
        "description": "For all Force Urbania bookings, contact SaitirthaTours & Travels at +91 9923879009 / 9922479009. Our team is ready to help with booking inquiries, itinerary suggestions, and finding the best rental options for your group. We are committed to providing excellent customer service, ensuring a smooth and enjoyable experience from start to finish."
    }
],

tableData: [
    ["- Force Urbania on Rent in Shirdi Contact Number", "- Force Urbania on Rent in Manmad"],
    ["- Force Urbania on Rent in Shani Shingnapur", "- Shirdi to Bhimashankar Urbania on Rent"],
    ["- Shirdi to Nashik Force Urbania on Rent", "- Force Urbania on Rent in Nashik"],
    ["- Force Urbania on Rent in Shirdi Airport", "- Mini AC Bus on Rent in Shirdi Airport"],
    ["- Mini Bus on Rent in Shirdi", "- Mini Luxury Bus on Rent in Shirdi"],
    ["- Sai Tirth Cabs Shirdi", "- 13 Seater Urbania on Rent in Shirdi"],
    ["- 17 Seater Urbania on Rent in Shirdi", "- Luxury Force Urbania on Rent in Shirdi"],
    ["- Force Urbania on Rent in Shirdi Packages", ""]
  ],
  whychoose: [
    {
      "WhyChooseheading": "Spacious Comfort",
      "WhyChoosedescription": "The Force Urbania is renowned for its spaciousness, making it the ideal vehicle for larger groups, families, or corporate events. With abundant legroom, generous headspace, and ample luggage capacity, it provides maximum comfort on both short and long journeys. Whether you're heading to a religious site, embarking on a group trip, or attending a corporate retreat, this vehicle offers the perfect balance of comfort and practicality, ensuring a pleasant experience for all passengers."
    },
    {
      "WhyChooseheading": "Versatile Seating Arrangements",
      "WhyChoosedescription": "The Force Urbania is designed with flexibility in mind, offering adaptable seating configurations to accommodate varying group sizes and needs. Whether you need a 13-seater for a family outing or a 17-seater for a large corporate group, we can arrange the seating according to your preference. This versatility ensures that every traveler has enough space, whether for a comfortable journey or easy access to luggage, making it perfect for everything from short city rides to long-distance trips."
    },
    {
      "WhyChooseheading": "Quality Service",
      "WhyChoosedescription": "Our team provides more than just a vehicle— we offer a complete service. Each Force Urbania rental comes with a professional and experienced driver, trained to ensure your safety and comfort. Our drivers are not just skilled on the road but also friendly and courteous, making your travel experience pleasant from start to finish. Whether you're embarking on a religious pilgrimage or a business trip, we aim to make your journey seamless and enjoyable."
    },
    {
      "WhyChooseheading": "Affordable Rentals",
      "WhyChoosedescription": "We believe that quality transportation shouldn’t come with an inflated price tag. Our Force Urbania rental rates are highly competitive, and we ensure complete transparency, with no hidden charges or extra fees. Whether you're booking for a day trip or an extended rental, you can count on us for affordable, fair pricing without compromising on service quality. Our pricing is designed to offer great value for groups, families, and businesses alike."
    },
    {
      "WhyChooseheading": "Well-Maintained Fleet",
      "WhyChoosedescription": "We maintain our entire fleet of Force Urbania vehicles to the highest standards. Each vehicle undergoes regular servicing and safety checks to ensure that it's in top condition before every trip. From ensuring the mechanical integrity of the vehicle to the cleanliness and comfort of the interiors, we prioritize the upkeep of our fleet to provide you with a reliable and worry-free travel experience. A well-maintained vehicle means fewer disruptions, ensuring you can enjoy your journey with peace of mind."
    },
    {
      "WhyChooseheading": "Customizable Packages",
      "WhyChoosedescription": "We understand that every trip is unique. Whether you're looking to hire the Force Urbania for a few hours, a day, or even multiple days, we offer flexible rental packages to meet your specific needs. You can choose the duration of your rental, the itinerary, and any additional services you may need (such as a guided tour or specific drop-off points). Our customizable packages ensure you get exactly what you need, with no extra or unnecessary costs."
    },
    {
      "WhyChooseheading": "24/7 Availability",
      "WhyChoosedescription": "No matter the time, we are always ready to serve you. Whether you need a Force Urbania for an early morning trip to Shirdi Airport or a late-night return journey, our services are available 24/7. With no limitations on booking times, we ensure that your travel plans are not constrained by the clock. Whether it's an emergency booking or a last-minute change, we’re here to help—just give us a call, and we’ll have your vehicle ready."
    },
    {
      "WhyChooseheading": "Easy Booking Process",
      "WhyChoosedescription": "Booking a Force Urbania with us is simple and straightforward. You can quickly reserve your vehicle through our user-friendly online platform or by contacting our customer service team directly. We also offer phone support for any last-minute bookings, changes, or special requests. Once your booking is confirmed, you’ll receive all the details you need, ensuring a hassle-free process from start to finish. Plus, we offer multiple payment options to make the transaction as smooth as possible."
    },
    {
      "WhyChooseheading": "Customer Satisfaction",
      "WhyChoosedescription": "Our customers are at the heart of everything we do. From the moment you inquire about a booking to the completion of your journey, we strive to provide exceptional service that exceeds expectations. Our high rate of repeat customers and glowing reviews reflect our dedication to customer satisfaction. We take the time to listen to your needs and ensure that every trip, no matter how big or small, is perfectly executed. Your comfort and satisfaction are our priorities."
    },
    {
      "WhyChooseheading": "Local Expertise",
      "WhyChoosedescription": "Our drivers are not only experts in driving but also familiar with the region's local attractions and routes. This means they can offer helpful suggestions for sightseeing, dining, and other local experiences, allowing you to make the most of your journey. Whether you’re exploring religious sites, visiting tourist attractions, or simply passing through, our drivers can share valuable local knowledge that enhances your travel experience. We pride ourselves on being more than just a transport service—we're your local guide on the road."
    }
  ]
  


        
  }


  const faqData = [
    {
        question: "How can I book a Force Urbania for rent?",
        answer: "You can easily book the vehicle through our website or by calling our customer service team for assistance."
    },
    {
        question: "What is the seating capacity of the Force Urbania?",
        answer: "The Force Urbania typically accommodates 12 to 15 passengers, making it perfect for medium to large groups."
    },
    {
        question: "Are there any additional charges for fuel?",
        answer: "Fuel charges may vary based on your rental agreement. We provide detailed pricing during the booking process."
    },
    {
        question: "What if I need the vehicle for an extended period?",
        answer: "We offer customizable rental packages, allowing you to rent the vehicle for as long as you need."
    },
    {
        question: "Can I choose my own driver?",
        answer: "All our rentals come with professional drivers, but you can request specific preferences when booking."
    },
    {
        question: "Is there a cancellation policy?",
        answer: "Yes, we have a flexible cancellation policy. Please contact us for details regarding your specific booking."
    },
    {
        question: "What payment methods do you accept?",
        answer: "We accept multiple payment options including cash, credit/debit cards, and mobile wallets."
    },
    {
        question: "Are the vehicles insured?",
        answer: "Yes, all our vehicles are insured for your safety and peace of mind while traveling."
    },
    {
        question: "Do you provide child seats in the Force Urbania?",
        answer: "Yes, child seats can be arranged upon request during the booking process."
    },
    {
        question: "What is your contact information?",
        answer: "For bookings and inquiries, you can reach us at:"
    }
];

const testimonials = [
    {
        name: "Mr. Rajesh Nair",
        text: "Renting the Force Urbania from Saitirtha Travels in Shirdi was a great decision for our group trip! The vehicle was spacious and comfortable, accommodating all our needs. The service provided by the staff was excellent, making the entire rental process easy and efficient. I highly recommend Saitirtha Travels for anyone looking to rent a vehicle in Shirdi!"
    },
    {
        name: "Ms. Priya Deshmukh",
        text: "I had a wonderful experience with Saitirtha Travels while renting the Force Urbania for my family trip in Shirdi. The van was in excellent condition and perfect for our group size. The team was very helpful and attentive, ensuring we had everything we needed. I will definitely choose Saitirtha Travels for my future rentals!"
    }
];



  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
<Helmet>
    <title>Contact: +91 9923879009 | Force Urbania On Rent in Shirdi | Luxury Urbania Bus Rental | Affordable and Reliable Service</title>
    <meta name="description" content="Book Force Urbania on Rent in Shirdi with Sai Tirth Cabs. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Reliable and comfortable mini bus rental services." />
    <meta name="keywords" content="Force Urbania on Rent in Shirdi, Urbania on Rent in Manmad, Force Urbania on Rent in Shani Shingnapur, Shirdi to Bhimashankar Urbania on Rent, Shirdi to Nashik Force Urbania on Rent, Mini Bus on Rent in Shirdi, Luxury Force Urbania on Rent in Shirdi, 13 Seater Urbania on Rent, 17 Seater Urbania on Rent, Shirdi Airport Urbania on Rent, Sai Tirth Cabs Shirdi, Mini AC Bus on Rent, Mini Luxury Bus on Rent in Shirdi" />
    <meta property="og:title" content="Contact: +91 9923879009 | Force Urbania On Rent in Shirdi | Luxury Urbania Bus Rental | Affordable and Reliable Service" />
    <meta property="og:description" content="Book Force Urbania on Rent in Shirdi with Sai Tirth Cabs. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Reliable and comfortable mini bus rental services." />
    <meta property="og:url" content="https://saitirthatravels.com/force-urbania-on-rent-in-shirdi" />
    <meta property="og:image" content="https://saitirthatravels.com/images/urbania-on-rent.jpg" />
    <meta name="twitter:card" content="summary_large_image" />
    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Sai Tirth Cabs Shirdi",
            "description": "Book Force Urbania on Rent in Shirdi. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Affordable and reliable mini bus rental services.",
            "url": "https://saitirthatravels.com/force-urbania-on-rent-in-shirdi",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/urbania-on-rent.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "250"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Force Urbania on Rent in Shirdi",
                "Urbania on Rent in Manmad",
                "Force Urbania on Rent in Shani Shingnapur",
                "Shirdi to Bhimashankar Urbania on Rent",
                "Shirdi to Nashik Force Urbania on Rent",
                "Mini Bus on Rent in Shirdi",
                "Luxury Force Urbania on Rent in Shirdi",
                "13 Seater Urbania on Rent",
                "17 Seater Urbania on Rent",
                "Shirdi Airport Urbania on Rent",
                "Sai Tirth Cabs Shirdi"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/93.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Forceurbaniaonrentinshirdi;