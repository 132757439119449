import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const TestimonialSection = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const testimonials = [
        {
            text: "I had a wonderful experience with Saitirtha Travels! The team was professional, and everything was well-organized. Highly recommend them for a smooth and enjoyable trip!",
            author: "Mr. Ravi Deshmukh",
            designation: "CEO & Founder",
        },
        {
            text: "Saitirtha Travels made our group trip stress-free with their excellent service. The drivers were friendly, and the trip went without a hitch. Fantastic service!",
            author: "Miss. Neha Kulkarni",
            designation: "CEO & Founder",
        },
    ];

    return (
        <section className="testimonial-section-three">
            <div className="bg bg-image"></div>
            <div className="image-man"></div>
            <div className="container pt-0 pb-0">
                <div className="row justify-content-xl-end g-0">
                    <div className="col-xl-7">
                        <div className="sec-title">
                            <h2 className="letters-slide-up text-split">Citycar Passengers Reviews...</h2>
                        </div>
                        <Slider {...settings} className="testimonial-carousel">
                            {testimonials.map((item, index) => (
                                <div className="testimonial-block-three" key={index}>
                                    <div className="inner-box">
                                        <div className="content">
                                            <div className="text">{item.text}</div>
                                            <div className="box-info">
                                                <div className="author-box">
                                                    <figure className="author-image">
                                                    </figure>
                                                    <div className="author-info">
                                                        <h4 className="name">{item.author}</h4>
                                                    </div>
                                                </div>
                                                <div className="author-rating">
                                                    <div className="rating-title">Quality Services</div>
                                                    <div className="rating">
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TestimonialSection;
