
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Monthlycabsservice() {



  const cardData =
  {
    keyword: ' Monthly Cab Service Shirdi',
    heading: 'Saitirtha Travels: Monthly Cab Service Shirdi',
    headingDescription: 'At Saitirtha Travels, we recognize the growing demand for reliable and flexible transportation options in Shirdi. Our Monthly Cab Service is designed to provide individuals and businesses with a convenient solution for all their travel needs. Whether you require transportation for work, family outings, or regular trips around Shirdi, our monthly cab service offers exceptional value and flexibility.',

    top: 'Top Places to Visit in Shirdi with Saitirtha Travels',

  "topPlaces": [
    {
        "title": "1. Shri Saibaba Samadhi Mandir",
        "description": "Shri Saibaba Samadhi Mandir is the main attraction in Shirdi, drawing devotees from around the world who come to pay their respects to Sai Baba. This sacred shrine houses the samadhi, or final resting place, of Sai Baba, offering a serene atmosphere ideal for meditation and prayer. The temple complex is beautifully maintained and serves as a focal point for spiritual activities, with many visitors experiencing profound peace and connection to the divine during their visits."
    },
    {
        "title": "2. Dwarkamai Masjid",
        "description": "Dwarkamai is an ancient mosque where Sai Baba spent a significant part of his life, making it a vital historical site for his followers. The site features a sacred fire, known as the Dhuni, that Baba lit and maintained throughout his life, symbolizing spiritual warmth and purity. Visitors can witness the many offerings made by devotees over the years, each representing the love and devotion towards Sai Baba, and experience the deep spiritual energy that permeates this revered space."
    },
    {
        "title": "3. Chavadi",
        "description": "The Chavadi is a simple yet significant structure where Sai Baba would rest on alternate nights. Adorned with beautiful flowers, this site holds great importance for his followers as it reflects Baba's humble lifestyle. The Chavadi serves as a poignant reminder of Sai Baba's teachings and the devotion of his followers, offering visitors a glimpse into the daily life and spiritual practices of the saint."
    },
    {
        "title": "4. Shirdi Saibaba Museum",
        "description": "The Shirdi Saibaba Museum houses a fascinating collection of artifacts and personal belongings of Sai Baba, providing valuable insights into his life and teachings. The displays include photographs, clothing, and items used by Baba, which help visitors understand his profound impact on spirituality and humanity. This informative stop is a must-visit for those looking to deepen their knowledge of Sai Baba's legacy and teachings."
    },
    {
        "title": "5. Lendi Baug",
        "description": "Lendi Baug is a beautiful garden located near the Samadhi Mandir, offering a peaceful retreat where Sai Baba often meditated. The garden features a small well and lush greenery, making it an ideal place for relaxation and reflection. Visitors can enjoy a tranquil environment, connecting with nature while contemplating the teachings of Sai Baba, and experiencing the serene atmosphere that he cherished."
    },
    {
        "title": "6. Shri Saibaba Sansthan Temple Trust",
        "description": "The Shri Saibaba Sansthan Temple Trust is the organization responsible for managing the affairs of the Sai Baba temple and providing various services for devotees. Visitors can learn about the trust's history and activities, which play a crucial role in maintaining the sanctity of this important pilgrimage site. The trust also organizes events, community service programs, and spiritual initiatives, allowing visitors to participate in the continuing legacy of Sai Baba's teachings."
    },
    {
        "title": "7. Khandoba Mandir",
        "description": "Located just a short distance from the main temple, Khandoba Mandir is dedicated to Lord Khandoba, a deity worshipped by Sai Baba. The temple is renowned for its beautiful architecture and serene atmosphere, attracting many devotees who come to seek blessings. The site is often filled with spiritual energy, making it a perfect location for worship and reflection, and it stands as a testament to Sai Baba's reverence for all forms of divinity."
    },
    {
        "title": "8. Maalik Dham",
        "description": "Maalik Dham is a temple dedicated to Sai Baba’s guru, Abdul Baba. This peaceful place offers a tranquil setting for meditation and prayer, allowing visitors to connect with the spiritual essence of Shirdi. The atmosphere is one of calm and introspection, providing a perfect backdrop for those seeking solace and guidance from the teachings of their spiritual ancestors."
    },
    {
        "title": "9. Sainath Nagar",
        "description": "Sainath Nagar is a vibrant residential area that has developed around the main temple complex. It offers a variety of accommodations and dining options for visitors, making it a convenient spot for those looking to immerse themselves in the local culture. This area is lively with shops, eateries, and cultural experiences that enrich the pilgrimage, allowing guests to experience the hospitality and warmth of the Shirdi community."
    },
    {
        "title": "10. Shri Sai Baba's Chavadi",
        "description": "Shri Sai Baba's Chavadi holds special significance as it showcases the simplicity of Sai Baba’s life. Often adorned with beautiful flowers, it serves as a reminder of Baba’s teachings on humility and compassion. Visitors are drawn to this site not only to witness its historical value but also to reflect on the profound lessons imparted by Sai Baba through his humble existence, making it a meaningful part of their spiritual journey."
    }
],

    
            
"services": [
    {
        "name": "Monthly Cab Service Shirdi",
        "description": "When you need a reliable transportation solution for an extended period, our Monthly Cab Service Shirdi is the perfect choice. Whether you're a local resident or a visitor, we provide a hassle-free and affordable way to meet your daily travel needs. At SaitirthaTours & Travels, we specialize in Shirdi monthly taxi rental options that cater to both individual and corporate clients. Enjoy the peace of mind that comes with knowing your transportation is covered for the entire month, allowing you to focus on what matters most."
    },
    {
        "name": "Shirdi Monthly Taxi Rental",
        "description": "Our Shirdi monthly taxi rental services are designed for those who require consistent transportation without the commitment of owning a vehicle. Enjoy the convenience of having a dedicated cab at your service for the entire month, allowing you to travel at your own pace and schedule. Whether for daily commuting, errands, or leisure trips, our rental service provides flexibility and reliability."
    },
    {
        "name": "Long-Term Cab Service Shirdi",
        "description": "For long-term commitments, our long-term cab service Shirdi ensures you have access to comfortable and reliable transportation. Whether it’s for personal use or business needs, we offer flexible arrangements to suit your requirements, including customized packages tailored to your travel frequency. Our professional drivers are always punctual, making your long-term travel experience stress-free."
    },
    {
        "name": "Monthly Car Rental Shirdi",
        "description": "Experience the flexibility of our monthly car rental Shirdi, where you can choose from a variety of vehicles, including sedans, SUVs, and larger vans, depending on your needs. All our cars are well-maintained and equipped with modern amenities to guarantee a comfortable journey. This option is ideal for families, groups, or anyone needing reliable transport on a regular basis."
    },
    {
        "name": "Shirdi Monthly Taxi Service",
        "description": "Our Shirdi monthly taxi service stands out for its affordability and convenience. We aim to provide high-quality service with professional drivers who know the local area well, ensuring you arrive at your destination safely and on time. Our commitment to customer satisfaction means we listen to your needs and adapt our service accordingly."
    },
    {
        "name": "Affordable Monthly Cab Shirdi",
        "description": "Looking for an affordable monthly cab Shirdi? We understand the importance of budget-friendly transportation. That’s why we offer competitive pricing for all our monthly cab services, helping you save money while enjoying top-notch service. Our transparent pricing structure ensures you know exactly what you're paying for, with no hidden fees."
    },
    {
        "name": "Shirdi Monthly Cab Booking",
        "description": "Booking your monthly cab with us is simple and efficient. Our user-friendly online booking system allows you to reserve your cab quickly, ensuring you have your transportation arranged well in advance. With just a few clicks, you can secure reliable transportation tailored to your schedule and needs."
    },
    {
        "name": "Monthly Cab Hire Shirdi",
        "description": "When you choose our monthly cab hire Shirdi, you are selecting a service committed to your satisfaction. Whether you need a cab for personal errands, commuting to work, or attending events, we have the right solution for you. Our flexible options allow you to modify your rental terms as needed, providing convenience and peace of mind."
    },
    {
        "name": "Shirdi Taxi for a Month",
        "description": "Our Shirdi taxi for a month service is perfect for those who require a consistent transportation option. With flexible pick-up and drop-off times, we make it easy for you to travel according to your schedule. Enjoy the convenience of having a dedicated taxi ready when you need it, without the hassles of ownership."
    },
    {
        "name": "Shirdi Monthly Cab Rates",
        "description": "Our Shirdi monthly cab rates are competitive and transparent, allowing you to budget your transportation costs effectively. We provide clear pricing with no hidden charges, ensuring you know exactly what to expect. Our aim is to deliver great value for money while maintaining high service standards."
    },
    {
        "name": "Monthly Cab Service Shirdi",
        "description": "At SaitirthaTours & Travels, we pride ourselves on our Monthly Cab Service Shirdi. Our team is dedicated to providing reliable transportation, and our vehicles are regularly serviced to ensure safety and comfort. Whether for personal or business use, we are committed to making your travel experience enjoyable and stress-free."
    },
    {
        "name": "Cab on Monthly Basis for Pick and Drop to Office Shirdi",
        "description": "If you need a cab on a monthly basis for pick and drop to office Shirdi, look no further. We specialize in corporate transportation, ensuring that employees have reliable transportation to and from work. Our service is designed to enhance employee satisfaction by providing consistent and punctual travel options."
    },
    {
        "name": "Monthly Taxi Service in Shirdi",
        "description": "Our monthly taxi service in Shirdi is designed for both individuals and companies, offering customized solutions to meet various transportation needs. From daily commuting to special events, our service is flexible and can be tailored to suit your specific requirements."
    },
    {
        "name": "Monthly Cab Service Near Me in Ahmednagar",
        "description": "Looking for a monthly cab service near me in Ahmednagar? We cover a wide area, providing convenient and accessible transportation for our customers. Our fleet is ready to serve you, ensuring timely arrivals and departures, no matter your location."
    },
    {
        "name": "Cab Monthly Pickup and Drop to Office Cab",
        "description": "We also offer cab monthly pickup and drop to office cab services, providing seamless transportation for employees and ensuring they arrive at work on time. This service is particularly beneficial for businesses looking to streamline their employees' commuting experience."
    },
    {
        "name": "Cab for Monthly Basis for Corporate Office",
        "description": "Our cab for monthly basis for corporate office service is tailored to meet the needs of businesses, ensuring all employees have access to reliable transport for work-related travel. We provide efficient and punctual services to help you maintain productivity in your operations."
    },
    {
        "name": "Cab Service Monthly Rental in Shirdi MIDC",
        "description": "In addition to our general services, we also provide cab service monthly rental in Shirdi MIDC, making it easy for businesses in the industrial area to manage their transportation needs. Our reliable service helps companies focus on their core activities while we handle their travel logistics."
    },
    {
        "name": "Monthly Cab Hire and Monthly Bus Hire in Shirdi MIDC",
        "description": "We offer both monthly cab hire and monthly bus hire in Shirdi MIDC, accommodating varying group sizes and ensuring everyone has a comfortable ride. Our vehicles are equipped to provide a smooth travel experience, whether for individual employees or larger teams."
    },
    {
        "name": "Monthly Cab Service for Office",
        "description": "Our monthly cab service for office is designed to simplify your commuting experience, allowing employees to focus on their work while we handle their transportation. This reliable service helps maintain a professional environment by ensuring everyone arrives on time."
    },
    {
        "name": "Monthly Private Cab Service for Office Employee in Shirdi MIDC",
        "description": "For companies looking for a personalized approach, we provide a monthly private cab service for office employees in Shirdi MIDC, ensuring exclusive transport arrangements. This service is ideal for those seeking comfort and convenience in their daily commute."
    },
    {
        "name": "SaitirthaTours & Travels",
        "description": "Choose SaitirthaTours & Travels for your Monthly Cab Service Shirdi. Our commitment to excellence and customer satisfaction makes us the ideal choice for all your transportation needs. We prioritize your convenience and comfort, ensuring you have a reliable partner for your travel requirements."
    },
    {
        "name": "Taxi Monthly Booking for Office Pickup and Drop Service",
        "description": "Contact us today for taxi monthly booking for office pickup and drop service. We guarantee a smooth and enjoyable ride for all our customers. Our dedicated team is ready to assist you with your transportation needs, providing timely and professional service."
    },
    {
        "name": "Monthly Cab Service in Shirdi Contact Number",
        "description": "Contact Shirdi Saibaba Travels at +91 9923879009/ 9922479009 for prompt and efficient Shirdi to Manmad Cab. We ensure a smooth and enjoyable ride for all our customers. Our commitment to excellence means you can trust us for all your transportation needs."
    }
],

"tableData": [
    ["- Monthly Cab Service Shirdi", "- Shirdi Monthly Taxi Rental"],
    ["- Long-Term Cab Service Shirdi", "- Monthly Car Rental Shirdi"],
    ["- Shirdi Monthly Taxi Service", "- Affordable Monthly Cab Shirdi"],
    ["- Shirdi Monthly Cab Booking", "- Monthly Cab Hire Shirdi"],
    ["- Shirdi Taxi for a Month", "- Shirdi Monthly Cab Rates"],
    ["- Cab on Monthly Basis for Pick and Drop to Office Shirdi", "- Monthly Taxi Service in Shirdi"],
    ["- Monthly Cab Service Near Me in Ahmednagar", "- Cab Monthly Pickup and Drop to Office Cab"],
    ["- Cab for Monthly Basis for Corporate Office", "- Cab Service Monthly Rental in Shirdi MIDC"],
    ["- Monthly Cab Hire, Monthly Bus Hire in Shirdi MIDC", "- Monthly Cab Service for Office"],
    ["- Monthly Private Cab Service for Office Employees in Shirdi MIDC", "- SaitirthaTours & Travels Shirdi"],
    ["- Taxi Monthly Booking for Office Pickup and Drop Service", ""]
],
"whychoose": [
    {
        "WhyChooseheading": "Cost-Effective Solutions",
        "WhyChoosedescription": "Our monthly cab service is designed to provide substantial savings compared to daily rentals, making it an economical choice for frequent travelers and businesses with ongoing transportation needs. By opting for this service, you not only secure reliable transportation but also simplify your budgeting process, as you can plan your expenses well in advance. This is particularly advantageous for businesses looking to reduce costs without compromising on service quality."
    },
    {
        "WhyChooseheading": "Flexible Scheduling",
        "WhyChoosedescription": "Understanding that plans can often change, our monthly cab service provides you with the flexibility to modify your travel arrangements as required. This adaptability allows you to accommodate last-minute changes without hassle. Whether you need a ride for an impromptu meeting, a late-night pickup, or an early morning departure, our service is designed to fit seamlessly into your busy life, ensuring you never miss an important engagement."
    },
    {
        "WhyChooseheading": "Dedicated Driver",
        "WhyChoosedescription": "With our monthly service, you will benefit from having a dedicated driver who becomes well-acquainted with your preferences and routes. This personalized approach not only enhances your comfort during rides but also builds trust and rapport between you and your driver, leading to a smoother experience overall. Your driver will be able to suggest the best routes, keeping you informed about local traffic conditions, and helping you optimize your travel time."
    },
    {
        "WhyChooseheading": "Well-Maintained Vehicles",
        "WhyChoosedescription": "Your safety and comfort are our highest priorities. Our fleet of vehicles undergoes rigorous inspections and regular maintenance to guarantee a smooth, safe, and enjoyable ride. Each vehicle is meticulously cleaned and equipped with modern amenities, such as air conditioning, comfortable seating, and GPS systems, to enhance your travel experience. We strive to ensure that every ride you take is not only pleasant but also worry-free, so you can focus on what truly matters."
    },
    {
        "WhyChooseheading": "24/7 Availability",
        "WhyChoosedescription": "Recognizing that travel needs can arise at any time, our monthly cab service operates around the clock. This ensures that you have access to reliable transportation whenever you need it, whether for spontaneous trips or pre-scheduled rides. You can rest easy knowing that our services are just a call away, ready to assist you at any hour. This level of availability is crucial for those with unpredictable schedules, giving you the peace of mind that reliable transport is always within reach."
    },
    {
        "WhyChooseheading": "Easy Booking Process",
        "WhyChoosedescription": "We strive to make the booking process as seamless as possible. Our user-friendly online booking platform allows you to reserve your monthly cab service in just a few clicks. Additionally, you can always reach out to our dedicated customer support team for assistance, whether you have questions or need help with your booking. We prioritize convenience, ensuring that you can secure your transportation without any unnecessary complications or delays."
    },
    {
        "WhyChooseheading": "Transparent Pricing",
        "WhyChoosedescription": "We believe in clarity and honesty when it comes to pricing. You will receive a comprehensive quote upfront that includes all costs associated with your monthly service, with absolutely no hidden charges. This transparency allows you to plan your budget effectively and ensures that you know exactly what you're paying for. Moreover, our flexible packages can be customized to suit your specific travel needs, ensuring that you receive exceptional value for your investment."
    },
    {
        "WhyChooseheading": "Seamless Communication",
        "WhyChoosedescription": "Our customer service team is committed to providing you with excellent support throughout your experience. You can easily reach out for any assistance or inquiries, ensuring you receive timely responses and helpful guidance. We strive to create a positive experience from start to finish, making sure that all your needs are addressed promptly. Our goal is to ensure that you feel valued and supported at every step of your journey with us."
    }
]

        
  }


  const faqData = [
    {
        question: "How does the monthly cab service work?",
        answer: "Our monthly cab service provides a dedicated vehicle and driver for your transportation needs throughout the month. You can schedule rides according to your convenience, whether for daily commutes, business meetings, or personal errands."
    },
    {
        question: "What is included in the monthly package?",
        answer: "The package typically includes a specified number of kilometers per month, daily usage limits, and a dedicated driver. We can also customize services based on your requirements, such as additional mileage or specific vehicle preferences."
    },
    {
        question: "Can I change my schedule after booking?",
        answer: "Yes, you can modify your schedule at any time. We ask that you inform us of any changes as soon as possible so we can accommodate your needs effectively."
    },
    {
        question: "Are there any cancellation fees?",
        answer: "Our cancellation policy allows for cancellations without penalties up to 24 hours in advance. After this window, a small cancellation fee may apply. Please refer to our terms for detailed information."
    },
    {
        question: "What types of vehicles are available for the monthly service?",
        answer: "We offer a variety of vehicles to suit your needs, including sedans for comfortable solo travel, SUVs for families or groups, and luxury cars for special occasions or business travel."
    },
    {
        question: "Is there a limit on the kilometers I can travel?",
        answer: "Yes, each monthly service package comes with a specified kilometer allowance. If you exceed this limit, additional charges will apply. However, we provide competitive rates for extra kilometers to ensure affordability."
    },
    {
        question: "What payment options do you accept?",
        answer: "We offer multiple payment methods for your convenience, including credit and debit cards, UPI transfers, and cash payments. This flexibility makes it easy for you to manage your transportation expenses."
    },
    {
        question: "Can I use the service for outstation travel?",
        answer: "Yes, our monthly cab service can be extended for outstation travel. We can arrange for longer trips, and additional charges will be discussed during the booking process based on distance and duration."
    },
    {
        question: "What measures are taken for safety and hygiene?",
        answer: "Your safety is paramount. We adhere to strict hygiene protocols, including regular cleaning and sanitizing of our vehicles. Our drivers also follow safety guidelines to ensure your peace of mind during every ride."
    },
    {
        question: "What should I do if I have an emergency during a ride?",
        answer: "If you encounter any issues or emergencies during your ride, please communicate directly with your driver, who is trained to handle various situations. Additionally, our customer support team is available 24/7 for assistance."
    }
];

const testimonials = [
    {
        name: "Mr. Rahul Khanna",
        text: "Saitirtha Travels' monthly cab service has been a lifesaver for my daily commute. The driver is punctual, and the service is reliable. The vehicle is always clean, and I appreciate the personal touch of having the same driver. Highly recommend!"
    },
    {
        name: "Mrs. Priya Mehta",
        text: "I opted for the monthly cab service for my business trips, and it has exceeded my expectations. The convenience and quality of service are top-notch! My driver is always on time and provides a comfortable travel experience."
    },
    {
        name: "Mr. Sanjay Deshmukh",
        text: "Using Saitirtha Travels for our family outings has made traveling a breeze. The driver knows the best routes and is very friendly. It's nice to have a trusted service for our monthly trips!"
    }
];



  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Monthly Cab Service in Shirdi | SaitirthaTours & Travels</title>
    <meta name="description" content="Book affordable and reliable monthly cab service in Shirdi. We offer long-term taxi rentals, office pickups, drop services, and private cabs for your daily commute. Book now!" />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirtha Travels | Monthly Cab Service in Shirdi | SaitirthaTours & Travels" />
    <meta property="og:description" content="Book affordable and reliable monthly cab service in Shirdi. We offer long-term taxi rentals, office pickups, drop services, and private cabs for your daily commute. Book now!" />
    <meta property="og:url" content="https://saitirthatravels.com/monthly-cab-service-shirdi" />
    <meta property="og:image" content="https://saitirthatravels.com/images/monthly-cab-service-shirdi.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Book affordable and reliable monthly cab service in Shirdi. We offer long-term taxi rentals, office pickups, drop services, and private cabs for your daily commute. Book now!",
            "url": "https://saitirthatravels.com/monthly-cab-service-shirdi",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/monthly-cab-service-shirdi.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Monthly cab service Shirdi",
                "Shirdi monthly taxi rental",
                "Long-term cab service Shirdi",
                "Monthly car rental Shirdi",
                "Shirdi monthly taxi service",
                "Affordable monthly cab Shirdi",
                "Shirdi monthly cab booking",
                "Monthly cab hire Shirdi",
                "Shirdi taxi for a month",
                "Shirdi monthly cab rates",
                "Monthly Cab Service Shirdi",
                "Cab on monthly basis for pick and drop to office Shirdi",
                "Monthly taxi service in Shirdi",
                "Monthly cab service near me in Ahmednagar",
                "Cab monthly pickup and drop to office",
                "Cab for monthly basis for corporate office",
                "Cab service monthly rental in Shirdi MIDC",
                "Monthly cab hire in Shirdi MIDC",
                "Monthly private cab service for office employee in Shirdi MIDC",
                "SaitirthaTours & Travels Shirdi",
                "Taxi monthly booking for office pickup and drop service"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/41.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Monthlycabsservice;