import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const services = [
    {
        title: 'Airport Pickup & Drop ',
        description: '"Seamless Airport Pickup & Drop with Saitirtha Travels – Reliable, Comfortable, and Always On Time. Book your stress-free journey today!"',
        image: '/images/saitirthaservicesimages/Image for Service- 350x250px-01.jpg',
        icon: 'flaticon-taxi-2',
    },
   
    {
        title: 'Car Rental ',
        description: '"Explore with Ease – Saitirtha Travels Car Rental Service. Flexible, Affordable, and Ready for Your Journey. Book Your Ride Today!"',
        image: '/images/saitirthaservicesimages/Image for Service- 350x250px-02.jpg',
        icon: 'flaticon-taxi-2',
    },
    {
        title: 'Temple Tours and Pilgrimages ',
        description: 'Discover sacred journeys with Saitirtha Travels—explore revered temples and pilgrimage sites with peace of mind.',
        image: '/images/saitirthaservicesimages/Image for Service- 350x250px-03.jpg',
        icon: 'flaticon-taxi-2',
    },
    {
        title: ' Taxi Service',
        description: '"Reliable rides, anytime, anywhere! Choose Saitirtha Travels for safe, comfortable, and convenient taxi services. #SaitirthaTravels',
        image: '/images/saitirthaservicesimages/Image for Service- 350x250px-04.jpg',
        icon: 'flaticon-taxi-2',
    },
    {
        title: 'Cabs for events ',
        description: 'Make your events seamless with Saitirtha Travels! Reliable, comfortable cabs for every occasion. #EventCabs #SaitirthaTravels',
        image: '/images/saitirthaservicesimages/Image for Service- 350x250px-05.jpg',
        icon: 'flaticon-taxi-2',
    },
    {
        title: 'Outstation Cab Service ',
        description: 'Explore beyond the city with Saitirtha Travels reliable outstation cab service! Safe, comfortable rides to your favorite destinations.',
        image: '/images/saitirthaservicesimages/Image for Service- 350x250px-06.jpg',
        icon: 'flaticon-taxi-2',
    },

    // Repeat the services if needed
];

const ServiceSection = () => {
    return (
        <div>

<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Ertiga On Rent in Shirdi | Kia Carens on rent in Shirdi | Affordable and Reliable Service</title> 
    <meta name="description" content="Book Ertiga on Rent in Shirdi with Sai Tirth Cabs. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Reliable and comfortable mini bus rental services." />
    <meta name="keywords" content="Force Urbania on Rent in Shirdi, Urbania on Rent in Manmad, Force Urbania on Rent in Shani Shingnapur, Shirdi to Bhimashankar Urbania on Rent, Shirdi to Nashik Force Urbania on Rent, Mini Bus on Rent in Shirdi, Luxury Force Urbania on Rent in Shirdi, 13 Seater Urbania on Rent, 17 Seater Urbania on Rent, Shirdi Airport Urbania on Rent, Sai Tirth Cabs Shirdi, Mini AC Bus on Rent, Mini Luxury Bus on Rent in Shirdi, Aura on Rent in Shirdi, Ertiga on Rent in Shirdi, Scorpio on Rent in Shirdi, Kia Carens on Rent in Shirdi" />
    <meta property="og:title" content="Contact: +91 9923879009 | Force Urbania On Rent in Shirdi | Luxury Urbania Bus Rental | Affordable and Reliable Service" />
    <meta property="og:description" content="Book Force Urbania on Rent in Shirdi with Sai Tirth Cabs. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Reliable and comfortable mini bus rental services." />
    <meta property="og:url" content="https://saitirthatravels.com/force-urbania-on-rent-in-shirdi" />
    <meta property="og:image" content="https://saitirthatravels.com/images/urbania-on-rent.jpg" />
    <meta name="twitter:card" content="summary_large_image" />
    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Sai Tirth Cabs Shirdi",
            "description": "Book Force Urbania on Rent in Shirdi. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Affordable and reliable mini bus rental services.",
            "url": "https://saitirthatravels.com/force-urbania-on-rent-in-shirdi",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/urbania-on-rent.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "250"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Force Urbania on Rent in Shirdi",
                "Urbania on Rent in Manmad",
                "Force Urbania on Rent in Shani Shingnapur",
                "Shirdi to Bhimashankar Urbania on Rent",
                "Shirdi to Nashik Force Urbania on Rent",
                "Mini Bus on Rent in Shirdi",
                "Luxury Force Urbania on Rent in Shirdi",
                "13 Seater Urbania on Rent",
                "17 Seater Urbania on Rent",
                "Shirdi Airport Urbania on Rent",
                "Sai Tirth Cabs Shirdi",
                "Aura on Rent in Shirdi",
                "Ertiga on Rent in Shirdi",
                "Scorpio on Rent in Shirdi",
                "Kia Carens on Rent in Shirdi"
            ]
        })}
    </script>
</Helmet>

        <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
            <div className="title-outer text-center">
                <h1 className="title">Services</h1>
              
            </div>
        </div>
    </section>


        <section className="service-section-three pb-90">
            <div className="auto-container">
                <div className="row">
                    {services.map((service, index) => (
                        <div className="col-lg-4 col-sm-6" key={index}>
                            {/* Service Block */}
                            <div className="service-block-three">
                                <div className="inner-box">
                                    <div className="image-box">
                                        <figure className="image">
                                            <img src={service.image} alt={service.title} />
                                        </figure>
                                        <i className={`icon ${service.icon}`}></i>
                                    </div>
                                    <div className="content bg-dark">
                                        <h4 className="title darkcolor">
                                            <Link className='darkcolor' to="/booking">{service.title}</Link>
                                        </h4>
                                        <div className="text text-white">{service.description}</div>
                                        <Link to="/booking" className="icon fa fa-angle-double-right"></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
        </div>
    );
};

export default ServiceSection;
