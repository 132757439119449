import React from 'react';
import '../css/UniqueSection.css';

const UniqueSection = () => {
    return (
        <section className="unique-section bg-dark text-white">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-3 d-flex flex-column align-items-center ">
                        <div className="info-item">
                            <strong className="info-title">
                                <i className="fas fa-globe"></i>
                                Tailored Travel Experiences
                            </strong>
                            <p className="info-description">
                                Explore exclusive travel experiences designed to reflect your unique interests and aspirations.
                            </p>
                        </div>
                        <div className="info-item">
                            <strong className="info-title">
                                <i className="fas fa-user-check"></i>
                                Dedicated Support Team
                            </strong>
                            <p className="info-description">
                                Our knowledgeable team is available 24/7 to assist you at every step of your journey.
                            </p>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 d-flex justify-content-center">
                    <img
                     src="/images/gallery/Img for Gallery-600x400px-05.jpg"
                     alt="Unique Travel Experience"
                      className="experience-image img-fluid"
                     width="100%"  height="100%"
                        />

                    </div>

                    <div className="col-12 col-md-3 d-flex flex-column align-items-center">
                        <div className="info-item">
                            <strong className="info-title">
                                <i className="fas fa-thumbs-up"></i>
                                Value for Your Money
                            </strong>
                            <p className="info-description">
                                Experience premium services without the hefty price tag, maximizing your travel budget.
                            </p>
                        </div>
                        <div className="info-item">
                            <strong className="info-title">
                                <i className="fas fa-shuttle-van"></i>
                                Complimentary Transfers
                            </strong>
                            <p className="info-description">
                                Enjoy free airport transfers for a seamless travel experience from start to finish.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default UniqueSection;
