

import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaMobileAlt, FaEnvelope } from 'react-icons/fa';

const Header = () => {
  const navbarCollapseRef = useRef(null);
  const mobileMenuRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState({ about: false, contact: false });

  const handleNavItemClick = () => {
    if (navbarCollapseRef.current) {
      navbarCollapseRef.current.classList.remove('show');
    }
    setDropdownOpen({ about: false, contact: false }); // Close dropdowns
  };

  const toggleDropdown = (menu) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [menu]: !prevState[menu]
    }));
  };

  useEffect(() => {
    const handleMobileMenuToggle = () => {
      if (navbarCollapseRef.current) {
        navbarCollapseRef.current.classList.toggle('show');
      }
    };

    const mobileMenuIcon = mobileMenuRef.current;
    mobileMenuIcon.addEventListener('click', handleMobileMenuToggle);

    return () => {
      mobileMenuIcon.removeEventListener('click', handleMobileMenuToggle);
    };
  }, []);

  return (
    <header className="main-header">
      <div className="header-top">
        <div className="auto-container">
          <div className="inner-container bg-redd text-white py-md-2">
            <div className="top-left">
              <ul className="header-info list-unstyled mb-0 d-flex flex-column flex-md-row align-items-center">
                <li className="d-flex align-items-center mb-3 mb-md-0 me-md-4">
                  <div>
                    <p className="mb-0">
                      <div className="phone-numbers d-md-flex">
                        <a href="tel:+919923879009" className="d-block fw-bold text-white fs-">
                          <i className="fas fa-phone-alt text-dark colored me-2"></i>+91 9923879009
                        </a>
                        <a href="tel:+919922479009" className="d-none d-md-block fw-bold text-white fs">
                          &nbsp; +91 9922479009
                        </a>
                        <a href="tel:+919922479009" className="d-block d-md-none text-white fw-bold fs">
                          <i className="fas fa-phone-alt text-dark colored me-2"></i>+91 9922479009
                        </a>
                      </div>
                    </p>
                  </div>
                </li>
                <li className="d-flex align-items-center mb-3 mb-md-0 ms-md-4">
                  <FaEnvelope className="text-dark colored me-2" />
                  <div>
                    <p className="mb-0">
                      <a href="mailto:booking@saitirthatravels.com" className="d-block fw-bold text-white">booking@saitirthatravels.com</a>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <nav className="navbar navbar-expand-lg navbar-light ">
        <div className="container ">
          <Link className="navbar-brand" to="/">
            <img src="/images/logo2.jpg" alt="Logo" />
          </Link>
          <button
            className="navbar-toggler bordee"
            type="button"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            ref={mobileMenuRef}
          >
            <span className="navbar-toggler-icon "></span>
          </button>
          <div className="collapse navbar-collapse" ref={navbarCollapseRef} id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link active fw-bold" to="/" onClick={handleNavItemClick}>Home</Link>
              </li>
              
              <li className="nav-item dropdown">
                <span
                  className="nav-link dropdown-toggle active fw-bold"
                  onClick={() => toggleDropdown('about')}
                  style={{ cursor: 'pointer' }}
                >
                  About
                </span>
                <ul className={`dropdown-menu ${dropdownOpen.about ? 'show' : ''}`}>
                  <li><Link className="dropdown-item fw-bold" to="/about" onClick={handleNavItemClick}>About Us</Link></li>
                  <li><Link className="dropdown-item fw-bold" to="/term-condition" onClick={handleNavItemClick}>Term & Condition</Link></li>
                  <li><Link className="dropdown-item fw-bold" to="/privacy-policy" onClick={handleNavItemClick}>Privacy & Policy</Link></li>
                </ul>
              </li>
              
              <li className="nav-item">
                <Link className="nav-link active fw-bold" to="/service" onClick={handleNavItemClick}>Service</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active fw-bold" to="/our-fleets" onClick={handleNavItemClick}>Our Fleet</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active fw-bold" to="/package" onClick={handleNavItemClick}>Packages</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active fw-bold" to="/our-network" onClick={handleNavItemClick}>Our Network</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active fw-bold" to="/booking" onClick={handleNavItemClick}>Booking</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active fw-bold" to="/gallery" onClick={handleNavItemClick}>Gallery</Link>
              </li>
              
              <li className="nav-item dropdown">
                <span
                  className="nav-link dropdown-toggle active fw-bold"
                  onClick={() => toggleDropdown('contact')}
                  style={{ cursor: 'pointer' }}
                >
                  Contact
                </span>
                <ul className={`dropdown-menu ${dropdownOpen.contact ? 'show' : ''}`}>
                  <li><Link className="dropdown-item fw-bold" to="/contact" onClick={handleNavItemClick}>Contact</Link></li>
                  <li><Link className="dropdown-item fw-bold" to="/enquiry" onClick={handleNavItemClick}>Enquiry</Link></li>
                  <li><Link className="dropdown-item fw-bold" to="/career" onClick={handleNavItemClick}>Career</Link></li>
                </ul>
              </li>
              
              <li className="nav-item">
                <Link className="nav-link active fw-bold" to="/blog" onClick={handleNavItemClick}>Blog</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;

















// import React, { useRef, useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import { FaMobileAlt, FaEnvelope } from 'react-icons/fa';

// const Header = () => {
//   const navbarCollapseRef = useRef(null);
//   const mobileMenuRef = useRef(null);
//   const [dropdownOpen, setDropdownOpen] = useState({ about: false, contact: false });

//   const handleNavItemClick = () => {
//     if (navbarCollapseRef.current) {
//       navbarCollapseRef.current.classList.remove('show');
//     }
//   };

//   const toggleDropdown = (menu) => {
//     setDropdownOpen((prevState) => ({
//       ...prevState,
//       [menu]: !prevState[menu]
//     }));
//   };

//   useEffect(() => {
//     const handleMobileMenuToggle = () => {
//       if (navbarCollapseRef.current) {
//         navbarCollapseRef.current.classList.toggle('show');
//       }
//     };

//     const mobileMenuIcon = mobileMenuRef.current;
//     mobileMenuIcon.addEventListener('click', handleMobileMenuToggle);

//     return () => {
//       mobileMenuIcon.removeEventListener('click', handleMobileMenuToggle);
//     };
//   }, []);

//   return (
//     <header className="main-header">
//       <div className="header-top">
//         <div className="auto-container">
//           <div className="inner-container bg-info py-md-2">
//             <div className="top-left">
//               <ul className="header-info list-unstyled mb-0 d-flex flex-column flex-md-row align-items-center">
//                 <li className="d-flex align-items-center mb-3 mb-md-0 me-md-4">
//                   {/* <FaMobileAlt className="fs-4 text-white colored me-2" /> */}
//                   <div>
//                     <p className="mb-0">
//                       <div className="phone-numbers d-md-flex">
                 
//                   <a href="tel:+919923879009" className="d-block fw-bold fs-"> <i className="fas fa-phone-alt text-white colored me-2"></i>+91 9923879009</a>
//                         <a href="tel:+919922479009" className="d-none d-md-block fw-bold fs"> &nbsp; +91 9922479009</a>
//                         <a href="tel:+919922479009" className="d-block d-md-none fw-bold fs"> <i className="fas fa-phone-alt text-white colored me-2"></i>+91 9922479009</a>
//                       </div>
//                     </p>
//                   </div>
//                 </li>
//                 <li className="d-flex align-items-center mb-3 mb-md-0 ms-md-4">
//                   <FaEnvelope className="text-white colored me-2" />
//                   <div>
//                     <p className="mb-0">
//                       <a href="mailto:booking@saitirtha Travels.com" className="d-block fw-bold">booking@saitirtha Travels.com</a>
//                     </p>
//                   </div>
//                 </li>
//               </ul>
//             </div>
//           </div>
//         </div>
//       </div>

//       <nav className="navbar navbar-expand-lg navbar-light">
//         <div className="container">
//           <Link className="navbar-brand" to="/">
//             <img src="/images/logo-2.png" alt="Logo" />
//           </Link>
//           <button
//             className="navbar-toggler"
//             type="button"
//             aria-controls="navbarSupportedContent"
//             aria-expanded="false"
//             aria-label="Toggle navigation"
//             ref={mobileMenuRef}
//           >
//             <span className="navbar-toggler-icon"></span>
//           </button>
//           <div className="collapse navbar-collapse" ref={navbarCollapseRef} id="navbarSupportedContent">
//             <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
//               <li className="nav-item">
//                 <Link className="nav-link active fw-bold" to="/" onClick={handleNavItemClick}>Home</Link>
//               </li>
              
//               <li className="nav-item dropdown">
//                 <span
//                   className="nav-link dropdown-toggle active fw-bold"
//                   onClick={() => toggleDropdown('about')}
//                   style={{ cursor: 'pointer' }}
//                 >
//                   About
//                 </span>
//                 <ul className={`dropdown-menu ${dropdownOpen.about ? 'show' : ''}`}>
//                   <li><Link className="dropdown-item fw-bold" to="/about" onClick={handleNavItemClick}>About Us</Link></li>
//                   <li><Link className="dropdown-item fw-bold" to="/term-condition" onClick={handleNavItemClick}>Term & Condition</Link></li>
//                   <li><Link className="dropdown-item fw-bold" to="/privacy-policy" onClick={handleNavItemClick}>Privacy & Policy</Link></li>
//                 </ul>
//               </li>
              
//               <li className="nav-item">
//                 <Link className="nav-link active fw-bold" to="/service" onClick={handleNavItemClick}>Service</Link>
//               </li>
//               <li className="nav-item">
//                 <Link className="nav-link active fw-bold" to="/our-fleets" onClick={handleNavItemClick}>Our Fleet</Link>
//               </li>
//               <li className="nav-item">
//                 <Link className="nav-link active fw-bold" to="/package" onClick={handleNavItemClick}>Packages</Link>
//               </li>
//               <li className="nav-item">
//                 <Link className="nav-link active fw-bold" to="/our-network" onClick={handleNavItemClick}>Our Network</Link>
//               </li>
//               <li className="nav-item">
//                 <Link className="nav-link active fw-bold" to="/booking" onClick={handleNavItemClick}>Booking</Link>
//               </li>
//               <li className="nav-item">
//                 <Link className="nav-link active fw-bold" to="/gallery" onClick={handleNavItemClick}>Gallery</Link>
//               </li>
              
//               <li className="nav-item dropdown">
//                 <span
//                   className="nav-link dropdown-toggle active fw-bold"
//                   onClick={() => toggleDropdown('contact')}
//                   style={{ cursor: 'pointer' }}
//                 >
//                   Contact
//                 </span>
//                 <ul className={`dropdown-menu ${dropdownOpen.contact ? 'show' : ''}`}>
//                   <li><Link className="dropdown-item fw-bold" to="/contact" onClick={handleNavItemClick}>Contact</Link></li>
//                   <li><Link className="dropdown-item fw-bold" to="/enquiry" onClick={handleNavItemClick}>Enquiry</Link></li>
//                   <li><Link className="dropdown-item fw-bold" to="/career" onClick={handleNavItemClick}>Career</Link></li>
//                 </ul>
//               </li>
              
//               <li className="nav-item">
//                 <Link className="nav-link active fw-bold" to="/blog" onClick={handleNavItemClick}>Blog</Link>
//               </li>
//             </ul>
//           </div>
//         </div>
//       </nav>
//     </header>
//   );
// };

// export default Header;










// import React, { useRef, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import { FaMobileAlt, FaEnvelope, FaFacebookF, FaTwitter, FaLinkedin, FaInstagram, FaSearch, FaPinterest } from 'react-icons/fa';

// const Header = () => {
//   const navbarCollapseRef = useRef(null);
//   const mobileMenuRef = useRef(null);

//   const handleNavItemClick = () => {
//     if (navbarCollapseRef.current) {
//       navbarCollapseRef.current.classList.remove('show');
//     }
//   };

//   useEffect(() => {
//     const handleMobileMenuToggle = () => {
//       if (navbarCollapseRef.current) {
//         navbarCollapseRef.current.classList.toggle('show');
//       }
//     };

//     const mobileMenuIcon = mobileMenuRef.current;
//     mobileMenuIcon.addEventListener('click', handleMobileMenuToggle);

//     return () => {
//       mobileMenuIcon.removeEventListener('click', handleMobileMenuToggle);
//     };
//   }, []);

//   return (
//     <header className="main-header">
//       <div className="header-top">
//         <div className="auto-container">
//           <div className="inner-container bg-info py-md-2">
//             <div className="top-left">
//               <ul className="header-info list-unstyled mb-0 d-flex flex-column flex-md-row align-items-center">
//                 <li className="d-flex align-items-center mb-3 mb-md-0 me-md-4">
//                   <FaMobileAlt className="fs-4 text-white colored me-2" />
//                   <div>
//                     <h6 className="mb-0">
//                       <div className="phone-numbers d-md-flex">
//                         <a href="tel:+919923879009" className="d-block  fw-bold fs-5">
//                            +91 9923879009
//                         </a>
//                         <a href="tel:+919922479009" className="d-none d-md-block fw-bold fs-5">
//                           &nbsp; +91 9922479009
//                         </a>
//                         <a href="tel:+919922479009" className="d-block d-md-none  fw-bold fs-5">
//                          +91 9922479009
//                         </a>
//                       </div>
//                     </h6>
//                   </div>
//                 </li>
//                 <li className="d-flex align-items-center mb-3 mb-md-0 ms-md-4">
//                   <FaEnvelope className="f text-white colored me-2" />
//                   <div>
//                     <p className="mb-0">
//                       <a href="mailto:booking@saitirtha Travels.com" className="d-block fw-bold ">
//                          booking@saitirtha Travels.com

//                       </a>
//                     </p>
//                   </div>
//                 </li>
//               </ul>
//             </div>
          
//           </div>
//         </div>
//       </div>

//       <nav className="navbar navbar-expand-lg navbar-light ">
//         <div className="container">
//           <Link className="navbar-brand" to="/">
//             <img src="/images/logo-2.png" alt="Logo" />
//           </Link>
//           <button
//             className="navbar-toggler"
//             type="button"
//             aria-controls="navbarSupportedContent"
//             aria-expanded="false"
//             aria-label="Toggle navigation"
//             ref={mobileMenuRef}
//           >
//             <span className="navbar-toggler-icon"></span>
//           </button>
//           <div className="collapse navbar-collapse" ref={navbarCollapseRef} id="navbarSupportedContent">
//             <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
//               <li className="nav-item">
//                 <Link className="nav-link active fw-bold" to="/" onClick={handleNavItemClick}>Home</Link>
//               </li>
//               <li className="nav-item dropdown">
//                 <Link className="nav-link dropdown-toggle active fw-bold" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
//                   About
//                 </Link>
//                 <ul className="dropdown-menu ">
//                   <li><Link className="dropdown-item  fw-bold" to="/about" onClick={handleNavItemClick}>About Us&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Link></li>
//                   <li><Link className="dropdown-item  fw-bold" to="/term-condition" onClick={handleNavItemClick}>Term & Condition</Link></li>
//                   <li><Link className="dropdown-item fw-bold" to="/privacy-policy" onClick={handleNavItemClick}>Privacy & Policy</Link></li>
//                 </ul>
//               </li>
            
//               <li className="nav-item">
//                 <Link className="nav-link active fw-bold" to="/service" onClick={handleNavItemClick}>Service</Link>
//               </li>
//               <li className="nav-item">
//                 <Link className="nav-link active fw-bold" to="/our-fleets" onClick={handleNavItemClick}>Our&nbsp;Fleet</Link>
//               </li>
//               <li className="nav-item">
//                 <Link className="nav-link active fw-bold" to="/package" onClick={handleNavItemClick}>Packages</Link>
//               </li>
//               <li className="nav-item">
//                 <Link className="nav-link active fw-bold" to="/our-network" onClick={handleNavItemClick}>Our&nbsp;Network</Link>
//               </li>
//               <li className="nav-item">
//                 <Link className="nav-link active fw-bold" to="/booking" onClick={handleNavItemClick}>Booking</Link>
//               </li>
//               <li className="nav-item">
//                 <Link className="nav-link active fw-bold" to="/gallery" onClick={handleNavItemClick}>Gallery</Link>
//               </li>
//               <li className="nav-item dropdown">
//                 <Link className="nav-link dropdown-toggle active fw-bold" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
//                   Contact
//                 </Link>
//                 <ul className="dropdown-menu active">
//                   <li><Link className="dropdown-item   fw-bold" to="/contact" onClick={handleNavItemClick}>Contact</Link></li>
//                   <li><Link className="dropdown-item  fw-bold" to="/enquiry" onClick={handleNavItemClick}>Enquiry</Link></li>
//                   <li><Link className="dropdown-item  fw-bold" to="/career" onClick={handleNavItemClick}>Career</Link></li>
//                 </ul>
//               </li>
//               <li className="nav-item">
//                 <Link className="nav-link active fw-bold" to="/blog" onClick={handleNavItemClick}>Blog</Link>
//               </li>
//             </ul>
//           </div>
//         </div>
//       </nav>
//     </header>
//   );
// };

// export default Header;




















// import React from 'react';
// import { FaMobileAlt, FaEnvelope, FaFacebookF, FaTwitter, FaLinkedin, FaInstagram, FaSearch } from 'react-icons/fa';
// import { FaPinterest } from 'react-icons/fa';
// import { FaBars } from 'react-icons/fa';

// const Header = () => {
//     return (
//         <header className="main-header header-style-one">
//             <HeaderTop />
//             <HeaderLower />
//             <MobileMenu />
//             <SearchPopup />
//             <StickyHeader />
//         </header>
//     );
// };

// const HeaderTop = () => (
//     <div className="header-top">
//         <div className="auto-container">
//             <div className="inner-container">
//                 <div className="top-left">
//                     <ul className="list-style-one">
//                         <li><FaMobileAlt /> <a href="tel:01750050088">+01 7500 50088</a></li>
//                         <li><FaEnvelope /> <a href="mailto:protected@example.com">[email protected]</a></li>
//                     </ul>
//                 </div>
//                 <div className="top-right">
//                     <ul className="social-icon-one">
//                         <li><a href="#"><FaFacebookF /></a></li>
//                         <li><a href="#"><FaTwitter /></a></li>
//                         <li><a href="#"><FaLinkedin /></a></li>
//                         <li><a href="#"><FaInstagram /></a></li>
//                     </ul>
//                 </div>
//             </div>
//         </div>
//     </div>
// );

// const HeaderLower = () => (
//     <div className="header-lower">
//         <div className="auto-container">
//             <div className="main-box">
//                 <div className="logo-box">
//                     <div className="logo">
//                         <a href="index-2.html"><img src="/images/logo-2.png" alt="Logo" /></a>
//                     </div>
//                 </div>
//                 <div className="nav-outer">
//                     <nav className="nav main-menu">
//                         <ul className="navigation">
//                             <li className="current dropdown"><a href="index.html">Home</a>
//                             </li>
//                             <li className="dropdown"><a href="#">Pages</a>
//                                 <ul>
//                                     <li><a href="/about">About</a></li>
//                                     <li><a href="page-booking.html">Booking</a></li>
//                                     <li><a href="page-taxi.html">Taxi List</a></li>
                                  
//                                     <li><a href="page-testimonial.html">Testimonial</a></li>
//                                     <li><a href="page-faq.html">FAQ</a></li>
                               
//                                 </ul>
//                             </li>
//                             {/* More navigation items here */}
//                             <li><a href="page-contact.html">Contact</a></li>
//                         </ul>
//                     </nav>
//                 </div>
//                 <div className="outer-box">
//                     <button className="ui-btn search-btn light"><FaSearch /></button>
//                     <div className="btn-box">
//                         <a href="page-booking.html" className="theme-btn btn-style-one dark-line-two hover-light">
//                             <span className="btn-title">Book a Taxi</span>
//                         </a>
//                     </div>
//                     {/* <div className="mobile-nav-toggler light"><LnrIconBars /></div> */}
//                 </div>
//             </div>
//         </div>
//     </div>
// );

// const MobileMenu = () => (
//     <div className="mobile-menu">
//         <div className="menu-backdrop"></div>
//         <nav className="menu-box">
//             <div className="upper-box">
//                 <div className="nav-logo">
//                     <a href="index-2.html"><img src="images/logo-2.png" alt="Logo" /></a>
//                 </div>
//                 <div className="close-btn"><i className="icon fa fa-times"></i></div>
//             </div>
//             <ul className="navigation clearfix">
//                 {/* Mobile menu items can be duplicated here */}
//             </ul>
//             <ul className="contact-list-one light">
//                 <li>
//                     <i className="icon lnr-icon-phone-handset"></i> <span className="title">Call Now</span>
//                     <div className="text"><a href="tel:+92880098670">+92 (8800) - 98670</a></div>
//                 </li>
//                 <li>
//                     <i className="icon lnr-icon-envelope1"></i> <span className="title">Send Email</span>
//                     <div className="text"><a href="mailto:protected@example.com">[email protected]</a></div>
//                 </li>
//                 <li>
//                     <i className="icon lnr-icon-map-marker"></i> <span className="title">Address</span>
//                     <div className="text">66 Broklyant, New York India 3269</div>
//                 </li>
//             </ul>
//             <ul className="social-links">
//                 <li><a href="#"><FaTwitter /></a></li>
//                 <li><a href="#"><FaFacebookF /></a></li>
//                 <li><a href="#"><FaPinterest /></a></li>
//                 <li><a href="#"><FaInstagram /></a></li>
//             </ul>
//         </nav>
//     </div>
// );

// const SearchPopup = () => (
//     <div className="search-popup">
//         <span className="search-back-drop"></span>
//         <button className="close-search"><span className="fa fa-times"></span></button>
//         <div className="search-inner">
//             <form method="post" action="#">
//                 <div className="form-group">
//                     <input type="search" name="search-field" placeholder="Search..." required />
//                     <button type="submit"><FaSearch /></button>
//                 </div>
//             </form>
//         </div>
//     </div>
// );

// const StickyHeader = () => (
//     <div className="sticky-header">
//         <div className="auto-container">
//             <div className="inner-container">
//                 <div className="logo">
//                     <a href="index-2.html"><img src="images/logo.png" alt="Logo" /></a>
//                 </div>
//                 <div className="nav-outer">
//                     <nav className="main-menu">
//                         <div className="navbar-collapse show collapse clearfix">
//                             <ul className="navigation clearfix">
//                                 {/* Keep this empty / Menu will come through Javascript */}
//                             </ul>
//                         </div>
//                     </nav>
//                     {/* <div className="mobile-nav-toggler"><LnrIconBars /></div> */}
//                 </div>
//             </div>
//         </div>
//     </div>
// );

// export default Header;
