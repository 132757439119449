import React from 'react';
import '../css/OurFleet.css'; // Import your CSS file
import { Helmet } from 'react-helmet';

const fleetItems = [
    {
        name: 'Maruti Suzuki Swift Dzire',
        image: '/images/our-fleets/Img for Our Fleet- 345x220px-01.jpg',
        description: 'A comfortable sedan for small groups or individuals.',
        capacity: 'Up to 4+1 passengers',
        link: 'fleet-details/sedan.html'
    },
    {
        name: 'Toyota Etios',
        image: '/images/our-fleets/Img for Our Fleet- 345x220px-02.jpg',
        description: 'Spacious SUV perfect for family trips.',
        capacity: 'Up to 4+1 passengers',
        link: 'fleet-details/suv.html'
    },
    {
        name: 'Maruti Suzuki Ciaz',
        image: '/images/our-fleets/Img for Our Fleet- 345x220px-03.jpg',
        description: 'Experience luxury and comfort on the go.',
        capacity: 'Up to 4+1 passengers',
        link: 'fleet-details/luxury-car.html'
    },
    {
        name: 'Hyundai Aura',
        image: '/images/our-fleets/Img for Our Fleet- 345x220px-04.jpg',
        description: 'A spacious van ideal for group trips.',
        capacity: 'Up to 4+1 passengers',
        link: 'fleet-details/van.html'
    },
    {
        name: 'Hyundai Verna',
        image: '/images/our-fleets/Img for Our Fleet- 345x220px-05.jpg',
        description: 'Comfortable mini bus suitable for larger groups.',
        capacity: 'Up to 4+1 passengers',
        link: 'fleet-details/mini-bus.html'
    },
    {
        name: 'Toyota Innova Crysta',
        image: '/images/our-fleets/Img for Our Fleet- 345x220px-06.jpg',
        description: 'Compact car for easy city travel.',
        capacity: 'Up to 4+1 passengers',
        link: 'fleet-details/hatchback.html'
    },
    {
        name: 'Innova  Cab',
        image: '/images/our-fleets/Img for Our Fleet- 345x220px-077.jpg',
        description: 'Eco-friendly electric car for a greener journey.',
        capacity: 'Up to 4+1 passengers',
        link: 'fleet-details/electric-car.html'
    },
    {
        name: 'Mahindra Scorpio',
        image: '/images/our-fleets/Img for Our Fleet- 345x220px-08.jpg',
        description: 'Stylish convertible for a premium experience.',
        capacity: 'Up to 8+1 passengers',
        link: 'fleet-details/convertible.html'
    },
    {
        name: 'Maruti Suzuki Ertiga',
        image: '/images/our-fleets/Img for Our Fleet- 345x220px-09.jpg',
        description: 'Heavy-duty pickup truck for cargo and travel.',
        capacity: 'Up to 6+1 passengers',
        link: 'fleet-details/pickup-truck.html'
    },
    {
        name: 'Kia Carens',
        image: '/images/our-fleets/Img for Our Fleet- 345x220px-10.jpg',
        description: 'Motorhome for comfortable long trips.',
        capacity: 'Up to 4+1 passengers',
        link: 'fleet-details/motorhome.html'
    },
    {
        name: 'Tavera',
        image: '/images/our-fleets/Img for Our Fleet- 345x220px-11.jpg',
        description: 'Luxury limousine for special occasions.',
        capacity: 'Up to 8+1 passengers',
        link: 'fleet-details/limousine.html'
    },
    {
        name: 'Tempo Traveller',
        image: '/images/our-fleets/Img for Our Fleet- 345x220px-12.jpg',
        description: 'Versatile crossover vehicle for city and highway.',
        capacity: 'Up to 13/17 passengers',
        link: 'fleet-details/crossover.html'
    },
    {
        name: '13 Seater Tempo Traveller',
        image: '/images/our-fleets/Img for Our Fleet- 345x220px-13.jpg',
        description: 'Motorcycle for quick, solo rides.',
        capacity: 'Up to 13 passenger',
        link: 'fleet-details/motorcycle.html'
    },
    {
        name: '17 Seater Tempo Traveller ',
        image: '/images/our-fleets/Img for Our Fleet- 345x220px-14.jpg',
        description: 'Spacious coach bus for large groups.',
        capacity: 'Up to 17 passengers',
        link: 'fleet-details/coach-bus.html'
    },
    {
        name: '13 Seater Urbania',
        image: '/images/our-fleets/Img for Our Fleet- 345x220px-15.jpg',
        description: 'Premium SUV for comfort and style.',
        capacity: 'Up to 13 passengers',
        link: 'fleet-details/luxury-suv.html'
    },
    {
        name: '17 Seater Urbania',
        image: '/images/our-fleets/Img for Our Fleet- 345x220px-16.jpg',
        description: 'Vintage classic car for a nostalgic ride.',
        capacity: 'Up to 17 passengers',
        link: 'fleet-details/classic-car.html'
    },
    {
        name: '20 Seater Tempo Traveller',
        image: '/images/our-fleets/Img for Our Fleet- 345x220px-17.jpg',
        description: 'Taxi service for local or intercity trips.',
        capacity: 'Up to 20 passengers',
        link: 'fleet-details/taxi.html'
    },
    {
        name: '26 Seater Tempo Traveller',
        image: '/images/our-fleets/Img for Our Fleet- 345x220px-18.jpg',
        description: 'Rugged jeep for off-road adventures.',
        capacity: 'Up to 26 passengers',
        link: 'fleet-details/jeep.html'
    },
    {
        name: '32 Seater Tempo Traveller',
        image: '/images/our-fleets/Img for Our Fleet- 345x220px-19.jpg',
        description: 'Minivan ideal for family outings.',
        capacity: 'Up to 32 passengers',
        link: 'fleet-details/minivan.html'
    },
    {
        name: '40 Seater Ac/ Non AC bus',
        image: '/images/our-fleets/Img for Our Fleet- 345x220px-20.jpg',
        description: 'Economical compact car for city travel.',
        capacity: 'Up to 40 passengers',
        link: 'fleet-details/compact-car.html'
    },
    {
        name: '45 Seater Ac/ Non AC bus ',
        image: '/images/our-fleets/Img for Our Fleet- 345x220px-21.jpg',
        description: 'Executive car for business travel.',
        capacity: 'Up to 45 passengers',
        link: 'fleet-details/executive-car.html'
    },
    {
        name: '50 Seater Ac/ Non AC bus ',
        image: '/images/our-fleets/Img for Our Fleet- 345x220px-22.jpg',
        description: 'Convenient shuttle bus for group travel.',
        capacity: 'Up to 50 passengers',
        link: 'fleet-details/shuttle-bus.html'
    }
];


const OurFleet = () => {
    return (
        <div>
            <Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Ertiga On Rent in Shirdi | Kia Carens on rent in Shirdi | Affordable and Reliable Service</title> 
    <meta name="description" content="Book Ertiga on Rent in Shirdi with Sai Tirth Cabs. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Reliable and comfortable mini bus rental services." />
    <meta name="keywords" content="Force Urbania on Rent in Shirdi, Urbania on Rent in Manmad, Force Urbania on Rent in Shani Shingnapur, Shirdi to Bhimashankar Urbania on Rent, Shirdi to Nashik Force Urbania on Rent, Mini Bus on Rent in Shirdi, Luxury Force Urbania on Rent in Shirdi, 13 Seater Urbania on Rent, 17 Seater Urbania on Rent, Shirdi Airport Urbania on Rent, Sai Tirth Cabs Shirdi, Mini AC Bus on Rent, Mini Luxury Bus on Rent in Shirdi, Aura on Rent in Shirdi, Ertiga on Rent in Shirdi, Scorpio on Rent in Shirdi, Kia Carens on Rent in Shirdi" />
    <meta property="og:title" content="Contact: +91 9923879009 | Force Urbania On Rent in Shirdi | Luxury Urbania Bus Rental | Affordable and Reliable Service" />
    <meta property="og:description" content="Book Force Urbania on Rent in Shirdi with Sai Tirth Cabs. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Reliable and comfortable mini bus rental services." />
    <meta property="og:url" content="https://saitirthatravels.com/force-urbania-on-rent-in-shirdi" />
    <meta property="og:image" content="https://saitirthatravels.com/images/urbania-on-rent.jpg" />
    <meta name="twitter:card" content="summary_large_image" />
    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Sai Tirth Cabs Shirdi",
            "description": "Book Force Urbania on Rent in Shirdi. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Affordable and reliable mini bus rental services.",
            "url": "https://saitirthatravels.com/force-urbania-on-rent-in-shirdi",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/urbania-on-rent.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "250"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Force Urbania on Rent in Shirdi",
                "Urbania on Rent in Manmad",
                "Force Urbania on Rent in Shani Shingnapur",
                "Shirdi to Bhimashankar Urbania on Rent",
                "Shirdi to Nashik Force Urbania on Rent",
                "Mini Bus on Rent in Shirdi",
                "Luxury Force Urbania on Rent in Shirdi",
                "13 Seater Urbania on Rent",
                "17 Seater Urbania on Rent",
                "Shirdi Airport Urbania on Rent",
                "Sai Tirth Cabs Shirdi",
                "Aura on Rent in Shirdi",
                "Ertiga on Rent in Shirdi",
                "Scorpio on Rent in Shirdi",
                "Kia Carens on Rent in Shirdi"
            ]
        })}
    </script>
</Helmet>

            <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
                <div className="auto-container">
                    <div className="title-outer text-center">
                        <h1 className="title">Our Fleets</h1>
                    </div>
                </div>
            </section>

            <section className="our-fleet-section " style={{backgroundColor :'#E3EFEF'}}>
                <div className="auto-container">
                    <div className="sec-title text-center">
                    </div>
                    <div className="row">
                        {fleetItems.map((item, index) => (
                            <div className="fleet-block col-lg-4 col-md-6 col-sm-12" key={index}>
                                <div className="inner-box">
                                    <figure className="image">
                                        <a>
                                            <img src={item.image} alt={item.name} />
                                        </a>
                                    </figure>
                                    <div className="content">
                                        <h4 className="title">
                                            <a>{item.name}</a>
                                        </h4>
                                        <div className="text">{item.description}</div>
                                        <div className="capacity">Capacity: {item.capacity}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default OurFleet;
