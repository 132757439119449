
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Shirditobhimashankar() {



  const cardData =
  {
    keyword: '  Shirdi to Bhimashankar Cabs ',
    heading: 'Saitirtha Travels: Shirdi to Bhimashankar Cabs ',
    headingDescription: 'When it comes to traveling from Shirdi to Bhimashankar, Saitirtha Travels stands out as the premier choice for discerning travelers. We understand that your journey should be as enjoyable as the destination itself. Our commitment to providing top-notch cab services ensures that you have a comfortable, reliable, and seamless travel experience, allowing you to make the most of your time in Bhimashankar.',

    top: 'Top Places to Visit in Bhimashankar with Saitirtha Travels',

    topPlaces: [
        {
            "title": "1. Bhimashankar Temple",
            "description": "This ancient temple is dedicated to Lord Shiva and is revered as one of the twelve Jyotirlingas in India. Built in the 18th century, the temple showcases exquisite Nagara-style architecture, characterized by its intricate carvings and stone sculptures. The serene environment, set amidst dense forests, attracts countless pilgrims and tourists who seek blessings and spiritual solace. The temple is also a site for various festivals, drawing large crowds during Maha Shivaratri."
        },
        {
            "title": "2. Bhimashankar Wildlife Sanctuary",
            "description": "This sanctuary is a biodiversity hotspot, spanning over 131 square kilometers and home to numerous species of flora and fauna. The sanctuary is recognized for its rich biodiversity and is a haven for nature lovers and adventure seekers. Visitors can explore well-marked trekking paths, where they might encounter rare wildlife, including the Indian Giant Squirrel, various species of deer, and a plethora of bird species, such as the Malabar Whistling Thrush."
        },
        {
            "title": "3. Hanuman Lake",
            "description": "A tranquil spot ideal for picnics and relaxation, Hanuman Lake is surrounded by stunning natural beauty and offers a perfect escape from the hustle and bustle of city life. Visitors can unwind by the lake, enjoy the fresh air, and take in picturesque views of the surrounding hills and forests, making it a popular spot for families and friends."
        },
        {
            "title": "4. Patsachiv Nala",
            "description": "Known for its breathtaking waterfall, Patsachiv Nala is a popular destination for nature lovers and photographers alike. The waterfall cascades beautifully, especially during the monsoon season, creating a mesmerizing sight. Visitors can enjoy trekking to the waterfall, exploring the surrounding trails, and capturing stunning photographs of the cascading water against a backdrop of lush greenery."
        },
        {
            "title": "5. Shivneri Fort",
            "description": "Located nearby, this historic fort holds great significance in Maratha history as the birthplace of Chhatrapati Shivaji Maharaj. The fort offers breathtaking views of the surrounding landscape, making it an excellent spot for history enthusiasts and adventure lovers alike. The trek to the fort is invigorating, rewarding visitors with panoramic views once at the top."
        },
        {
            "title": "6. Nava Ganesh Mandir",
            "description": "Close to Bhimashankar Temple, this temple is dedicated to Lord Ganesh and is known for its intricate carvings and serene ambiance. Visitors can admire the craftsmanship and participate in peaceful rituals held here, making it an ideal setting for meditation and spiritual reflection."
        },
        {
            "title": "7. Gurudev Datta Mandir",
            "description": "This peaceful temple is dedicated to Guru Dattatreya, surrounded by lush greenery that creates a serene environment perfect for meditation and contemplation. The temple is an architectural delight, adorned with beautiful frescoes and intricate carvings."
        },
        {
            "title": "8. Bhimashankar Trekking Trails",
            "description": "For trekking enthusiasts, the trails around Bhimashankar offer stunning views and diverse landscapes. Each trail provides a unique experience, showcasing the area's rich natural beauty, from dense forests to rocky outcrops."
        },
        {
            "title": "9. Mahalaxmi Mandir",
            "description": "This temple, dedicated to Goddess Mahalaxmi, offers a tranquil spot for worship and reflection. Surrounded by lush greenery, it enhances the peaceful ambiance, making it a perfect location for those seeking spiritual solace."
        },
        {
            "title": "10. Local Markets",
            "description": "Explore the local markets of Bhimashankar to discover traditional handicrafts, religious items, and unique souvenirs. The markets are vibrant with activity, offering a chance to engage with local artisans and sample local delicacies from street vendors."
        }
    ],    
        
    
    "services": [
    {
        "name": "Shirdi to Bhimashankar Cab Service",
        "description": "When embarking on a spiritual journey from Shirdi to Bhimashankar, SaitirthaTours & Travels provides an exceptional cab service tailored for comfort and convenience. Our experienced drivers ensure a smooth ride, allowing you to relax and focus on your pilgrimage."
    },
    {
        "name": "Bhimashankar from Shirdi Cab",
        "description": "Our Bhimashankar from Shirdi cab services are crafted for reliability and ease. We recognize the spiritual significance of Bhimashankar Jyotirlinga, and our drivers are committed to delivering timely and safe transportation to this sacred site."
    },
    {
        "name": "Shirdi Bhimashankar Car Rental",
        "description": "For those seeking flexibility, our Shirdi Bhimashankar car rental options are perfect. Choose from a diverse fleet of well-maintained vehicles to suit your comfort needs, whether traveling solo or in a group, ensuring a delightful journey through stunning landscapes."
    },
    {
        "name": "Taxi Service Shirdi to Bhimashankar",
        "description": "Our taxi service from Shirdi to Bhimashankar goes beyond just transportation. Our knowledgeable drivers provide insights about attractions along the way, enriching your travel experience while ensuring exceptional customer service."
    },
    {
        "name": "Shirdi to Bhimashankar Cab Fare",
        "description": "Understanding the Shirdi to Bhimashankar cab fare is crucial for planning your spiritual journey. We offer competitive and transparent pricing with no hidden charges, plus various packages that include sightseeing options for a fulfilling experience."
    },
    {
        "name": "Bhimashankar Trip from Shirdi",
        "description": "Choosing a Bhimashankar trip from Shirdi means embarking on a culturally rich journey. Our drivers can guide you to additional attractions near Bhimashankar, enhancing your pilgrimage with more spiritual insights."
    },
    {
        "name": "Best Cab for Bhimashankar from Shirdi",
        "description": "We pride ourselves on providing the best cab service for Bhimashankar from Shirdi. Our vehicles are equipped with modern amenities to ensure comfort, catering to your needs for a day visit or a longer stay."
    },
    {
        "name": "Shirdi Bhimashankar Taxi Booking",
        "description": "Booking a Shirdi Bhimashankar taxi is effortless with SaitirthaTours & Travels. Our straightforward online reservation system and friendly staff are ready to help plan your travel itinerary based on your preferences."
    },
    {
        "name": "Shirdi to Bhimashankar Distance by Cab",
        "description": "The distance from Shirdi to Bhimashankar is approximately 175 kilometers, typically taking around 4 to 5 hours by cab. Our experienced drivers navigate the route efficiently, ensuring you arrive at your destination on time."
    },
    {
        "name": "Affordable Taxi Shirdi to Bhimashankar",
        "description": "We offer affordable taxi options from Shirdi to Bhimashankar without sacrificing quality. Our transparent pricing ensures you receive excellent value, making your spiritual journey both enjoyable and accessible."
    },
    {
        "name": "Shirdi to Bhimashankar Jyotirlinga Darshan",
        "description": "Visiting the Shirdi to Bhimashankar Jyotirlinga darshan is a spiritually enriching experience. Our cabs provide comfortable transportation to this holy site, where you can engage in prayers and rituals at the temple."
    },
    {
        "name": "Shirdi to 5 Jyotirlinga Package",
        "description": "For a comprehensive pilgrimage experience, consider our Shirdi to 5 Jyotirlinga package, allowing visits to multiple Jyotirlingas, including Bhimashankar, Trimbakeshwar, and Grishneshwar, enriching your spiritual journey across Maharashtra."
    },
    {
        "name": "Shirdi to Trimbakeshwar Bhimashankar Grishneshwar Trip",
        "description": "Our Shirdi to Trimbakeshwar, Bhimashankar, and Grishneshwar trip is designed for those wanting to explore significant religious sites in one journey. Enjoy seamless travel between these sacred destinations, guided by knowledgeable drivers."
    },
    {
        "name": "Shirdi with 3 Jyotirlinga Darshan Booking",
        "description": "If you're planning a visit to Shirdi with 3 Jyotirlinga darshan, we offer customized booking options perfect for devotees looking to immerse themselves in the divine atmosphere of multiple Jyotirlingas in a short span."
    },
    {
        "name": "Shirdi to Bhimashankar Cab Contact Number",
        "description": "For prompt and efficient Shirdi to Bhimashankar Cab services, contact Shirdi Saibaba Travels at +91 9923879009/9922479009. We ensure a smooth and enjoyable ride for all our customers."
    }
],
"tableData": [
    ["- Shirdi to Bhimashankar Taxi", "- Bhimashankar from Shirdi Cab"],
    ["- SaitirthaTours & Travels Shirdi", "- Shirdi Bhimashankar Car Rental"],
    ["- Taxi Service Shirdi to Bhimashankar", "- Shirdi to Bhimashankar Cab Fare"],
    ["- Bhimashankar Trip from Shirdi", "- Best Cab for Bhimashankar from Shirdi"],
    ["- Shirdi Bhimashankar Taxi Booking", "- Shirdi to Bhimashankar distance by cab"],
    ["- Affordable taxi Shirdi to Bhimashankar", "- Shirdi to Bhimashankar Jyotirlinga darshan"],
    ["- Shirdi to Trimbakeshwar Bhimashankar Grishneshwar Trip", "- Shirdi with 3 Jyotirlinga darshan booking"],
    ["- Shirdi to 5 Jyotirlinga package", "- Bhimashankar Trip Booking from Shirdi"]
],
"whychoose": [
    {
        "WhyChooseheading": "Prompt and Reliable Service:",
        "WhyChoosedescription": "Our cab services ensure a quick and reliable journey from Shirdi to Bhimashankar. We value punctuality, so our drivers arrive on time and take efficient routes to help you reach your destination smoothly and without any delays. From pick-up to drop-off, our focus is on delivering a seamless, stress-free experience."
    },
    {
        "WhyChooseheading": "Experienced Drivers:",
        "WhyChoosedescription": "Our drivers are not only well-versed with the Shirdi to Bhimashankar route, but they also have extensive experience in providing safe and courteous service. They are trained to handle different terrains and driving conditions, ensuring a comfortable and secure ride. With their local knowledge, they can also suggest stops for sightseeing, meals, or breaks, making your journey enjoyable."
    },
    {
        "WhyChooseheading": "Competitive Pricing:",
        "WhyChoosedescription": "We offer highly competitive pricing with full transparency. Our fare structure includes all necessary charges upfront, so you won’t face hidden fees or unexpected costs at the end of your trip. We believe in providing excellent value by combining affordability with high-quality service, ensuring you get the best deal for your journey from Shirdi to Bhimashankar."
    },
    {
        "WhyChooseheading": "Well-Maintained Vehicles:",
        "WhyChoosedescription": "Our fleet is kept in top condition, with regular maintenance checks to ensure reliability and comfort. Each vehicle is thoroughly cleaned and sanitized before every trip, giving you a hygienic and pleasant travel experience. We offer a range of cars to suit your preferences, from comfortable sedans to spacious SUVs, all equipped with essentials like air conditioning for a relaxed journey."
    },
    {
        "WhyChooseheading": "Flexible Booking Options:",
        "WhyChoosedescription": "We understand that travel plans can change, so we offer a variety of booking options to fit your schedule. Whether you need a one-way or round trip, or last-minute booking, we accommodate your needs. Our cancellation policies are also customer-friendly, giving you peace of mind if your plans shift unexpectedly."
    },
    {
        "WhyChooseheading": "24/7 Customer Support:",
        "WhyChoosedescription": "Our dedicated customer support team is available around the clock to help you with bookings, answer queries, or assist with any issues that may arise during your journey. We prioritize clear and prompt communication, ensuring that you feel supported and informed at every step of your trip from Shirdi to Bhimashankar."
    }
]
        
  }


  const faqData = [
    {
      question: "What types of cabs are available for the Shirdi to Bhimashankar route?",
      answer: "We provide a range of options, including sedans and SUVs, suitable for different group sizes and preferences."
    },
    {
      question: "How long does the journey take from Shirdi to Bhimashankar?",
      answer: "The drive usually takes about 3 to 4 hours, depending on traffic and road conditions."
    },
    {
      question: "Are there extra charges for nighttime travel?",
      answer: "Yes, there may be an additional fee for travel between 10 PM and 6 AM for safety and driver convenience."
    },
    {
      question: "How can I book a cab from Shirdi to Bhimashankar?",
      answer: "You can easily book a cab online through our website or contact our customer service for assistance."
    },
    {
      question: "Can I request specific stops during the journey?",
      answer: "Yes, you can request reasonable stops along the way for refreshments or sightseeing, based on your needs."
    },
    {
      question: "What payment methods do you accept?",
      answer: "We accept various payment methods, including credit/debit cards, UPI, and cash, for your convenience."
    },
    {
      question: "Is it possible to cancel or change my booking?",
      answer: "Yes, we offer flexible cancellation and rescheduling options. Please check our cancellation policy on the website for more details."
    },
    {
      question: "Are the cabs cleaned and sanitized before each trip?",
      answer: "Absolutely! We ensure all our vehicles are thoroughly cleaned and sanitized before every journey for your safety."
    }
  ];
  
  const testimonials = [
    {
      name: "Mr. Ramesh Kothari",
      text: "My experience with Saitirtha Travels for the Shirdi to Bhimashankar cab was fantastic. The driver was punctual and knowledgeable, making our trip enjoyable. The car was clean and comfortable, which made the long drive much more pleasant. I highly recommend them for anyone traveling in this area!"
    },
    {
      name: "Ms. Anita Sharma",
      text: "I recently used Saitirtha Travels for my trip from Shirdi to Bhimashankar, and it was a delightful experience. The booking was straightforward, and our driver was friendly and accommodating. I appreciated the well-maintained vehicle and the smooth journey. I will definitely use their services again!"
    }
  ];
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>

<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Shirdi to Bhimashankar Cab | SaitirthaTours & Travels</title>
    <meta name="description" content="Book a reliable and affordable cab for your trip from Shirdi to Bhimashankar. Visit Bhimashankar Jyotirlinga and other nearby attractions with SaitirthaTours & Travels. Enjoy a comfortable journey with our taxi services." />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirtha Travels | Shirdi to Bhimashankar Cab | SaitirthaTours & Travels" />
    <meta property="og:description" content="Book a reliable and affordable cab for your trip from Shirdi to Bhimashankar. Visit Bhimashankar Jyotirlinga and other nearby attractions with SaitirthaTours & Travels. Enjoy a comfortable journey with our taxi services." />
    <meta property="og:url" content="https://saitirthatravels.com/shirdi-to-bhimashankar-cab" />
    <meta property="og:image" content="https://saitirthatravels.com/images/shirdi-to-bhimashankar-cab.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Book a reliable and affordable cab for your trip from Shirdi to Bhimashankar. Visit Bhimashankar Jyotirlinga and other nearby attractions with SaitirthaTours & Travels. Enjoy a comfortable journey with our taxi services.",
            "url": "https://saitirthatravels.com/shirdi-to-bhimashankar-cab",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/shirdi-to-bhimashankar-cab.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi to Bhimashankar Taxi", 
                "Bhimashankar from Shirdi Cab", 
                "SaitirthaTours & Travels Shirdi", 
                "Shirdi Bhimashankar Car Rental", 
                "Taxi Service Shirdi to Bhimashankar", 
                "Shirdi to Bhimashankar Cab Fare", 
                "Bhimashankar Trip from Shirdi", 
                "Best Cab for Bhimashankar from Shirdi", 
                "Shirdi Bhimashankar Taxi Booking", 
                "Shirdi to Bhimashankar Distance by Cab", 
                "Affordable Taxi Shirdi to Bhimashankar", 
                "Shirdi to Bhimashankar Jyotirlinga Darshan", 
                "Shirdi to 5 Jyotirlinga Package", 
                "Shirdi to Trimbakeshwar Bhimashankar Grishneshwar Trip", 
                "Shirdi with 3 Jyotirlinga Darshan Booking"
            ]
        })}
    </script>
</Helmet>

      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/22.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirditobhimashankar;