
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Innovaonrent() {



  const cardData =
  {
    keyword: '  Innova on Rent in Shirdi',
    heading: 'Saitirtha Travels: Innova on Rent in Shirdi',
    headingDescription: 'For travelers looking for spacious and comfortable transportation in Shirdi, Saitirtha Travels offers the Toyota Innova for rent. Known for its robust performance and roomy interior, the Innova is perfect for families and groups. Whether you are visiting the holy sites or exploring nearby attractions, our Innova rental ensures a pleasant and hassle-free journey.',

    top: 'Top Places to Visit in Shirdi with Saitirtha Travels',

"topPlaces": [
    {
        "title": "Shri Sai Baba Samadhi Mandir",
        "description": "The Shri Sai Baba Samadhi Mandir stands as the main attraction in Shirdi, dedicated to the revered saint Sai Baba, who is beloved by millions worldwide. This temple draws devotees from all corners of the globe, seeking spiritual solace and blessings. The serene ambiance, marked by the soft glow of oil lamps and the scent of incense, creates a spiritually uplifting environment. The beautiful architecture, featuring intricate carvings and a magnificent dome, reflects the cultural heritage of India. Particularly during the evening aarti, when hymns resonate through the temple, the atmosphere transforms into one of deep devotion, allowing visitors to connect with the divine in a truly profound way."
    },
    {
        "title": "Dwarkamai Masjid",
        "description": "Dwarkamai Masjid is a significant historical site where Sai Baba spent a large part of his life, embodying his teachings and principles. The sacred fire, known as the dhuni, that burns here symbolizes the light of knowledge and spirituality. Visitors are welcomed to experience the deep devotion of fellow pilgrims, many of whom come to offer prayers and seek blessings. The tranquil atmosphere, filled with the sound of chanting and the fragrance of flowers, enhances the spiritual experience, allowing guests to reflect on Sai Baba’s message of love, tolerance, and harmony."
    },
    {
        "title": "Chavadi",
        "description": "Chavadi is a place of immense spiritual significance, as it is where Sai Baba rested every alternate night. The simple yet elegant structure is adorned with colorful offerings and fresh flowers, showcasing the love and devotion of his followers. Visitors can partake in the evening prayers, known as the aarti, which are attended by many devotees, creating a spiritually enriching experience. The atmosphere is filled with devotion and gratitude, allowing visitors to feel a deep connection to Sai Baba and his teachings of faith and humility."
    },
    {
        "title": "Shri Sai Baba Museum",
        "description": "The Shri Sai Baba Museum is an insightful destination that showcases a rich collection of artifacts and belongings that belonged to Sai Baba. Through photographs, manuscripts, and personal items, visitors can learn about the life and legacy of this revered saint. The museum provides a unique opportunity to delve into the teachings of Sai Baba, highlighting his philosophy of love, compassion, and service to humanity. Each exhibit offers a glimpse into his life, encouraging visitors to reflect on his profound impact on spirituality and the lives of countless devotees."
    },
    {
        "title": "Khandoba Temple",
        "description": "Perched majestically on a hill, Khandoba Temple is dedicated to Lord Khandoba, a popular deity in the region. This ancient temple not only serves as a vital pilgrimage site but also offers stunning panoramic views of the surrounding landscape, making it a favorite among visitors. The temple's architecture is a beautiful example of traditional Indian design, adorned with intricate carvings and colorful decorations. It becomes especially vibrant during festive occasions, attracting devotees who come to partake in rituals and celebrations, creating a lively and joyful atmosphere."
    },
    {
        "title": "Saptashrungi Devi Temple",
        "description": "A short distance from Shirdi lies the Saptashrungi Devi Temple, nestled amidst picturesque hills. This temple, dedicated to Goddess Saptashrungi, is known for its tranquil ambiance and breathtaking natural beauty. It serves as an ideal destination for both worshippers and nature lovers alike, offering a peaceful retreat for reflection and meditation. The scenic beauty surrounding the temple enhances the spiritual experience, inviting visitors to connect with nature while seeking the blessings of the goddess. The approach to the temple often involves a delightful trek, adding an element of adventure to the visit."
    },
    {
        "title": "Shirdi Village",
        "description": "Exploring Shirdi Village offers a delightful opportunity to immerse yourself in the local culture and traditions. As you wander through the quaint streets, you can shop for unique religious souvenirs and handicrafts, perfect for remembering your visit. Local snacks and delicacies provide a taste of the region's culinary heritage, enriching your experience. Engaging with the warm and friendly locals enhances your understanding of their way of life and their deep reverence for Sai Baba, making your visit to Shirdi a truly holistic experience."
    },
    {
        "title": "Naldurg Fort",
        "description": "Naldurg Fort is a historic landmark that provides fascinating insights into the region's rich past. With its impressive architecture and strategic design, the fort offers a glimpse into the historical significance of the area. Visitors can explore its expansive grounds, featuring ancient walls and bastions that tell stories of bygone eras. The fort also provides scenic views of the surrounding landscape, making it a perfect spot for history enthusiasts and adventure seekers alike. The combination of history and natural beauty makes Naldurg Fort a must-visit destination for those exploring the Shirdi region."
    },
    {
        "title": "Bhimashankar Wildlife Sanctuary",
        "description": "Located a short drive from Shirdi, Bhimashankar Wildlife Sanctuary is a haven for nature lovers and adventure seekers. Spanning lush green landscapes, the sanctuary is home to a rich diversity of flora and fauna, including the endangered Giant Indian Squirrel. The sanctuary offers numerous trekking trails, providing visitors with opportunities to experience the beauty of nature while spotting various wildlife species in their natural habitat. The tranquility and biodiversity of Bhimashankar make it an ideal retreat for those looking to escape the hustle and bustle of daily life and reconnect with nature."
    },
    {
        "title": "Aurangabad Caves",
        "description": "The Aurangabad Caves, located near Shirdi, are an exquisite example of ancient rock-cut architecture, showcasing remarkable sculptures and historical significance. Dating back to the 2nd to 6th centuries, these caves are adorned with intricate carvings that reflect the artistic heritage of the region. Visitors can explore the various caves, each telling stories from Buddhist scriptures through its stunning artwork. A visit to the Aurangabad Caves not only provides a visual feast but also an educational experience, allowing guests to appreciate the craftsmanship of ancient civilizations and the rich cultural legacy of the area."
    }
],
    
            
        
"services": [
    {
        "name": "Innova on Rent in Shirdi",
        "description": "Travel in comfort and style with our Innova on rent in Shirdi. The Toyota Innova is renowned for its spacious interiors, versatile design, and excellent ride quality. It comfortably seats up to seven passengers, making it the perfect choice for families, group outings, or business trips. Enjoy a smooth ride while exploring Shirdi and its surrounding attractions, ensuring a pleasant travel experience."
    },
    {
        "name": "Toyota Innova Shirdi Rental",
        "description": "Our Toyota Innova Shirdi rental services ensure you experience the best of this versatile vehicle. Whether you're visiting for a spiritual pilgrimage, family vacation, or sightseeing, the Innova’s roomy cabin provides ample legroom and luggage space. Equipped with air conditioning and modern amenities, your journey will be comfortable, no matter the distance."
    },
    {
        "name": "Rent Toyota Innova in Shirdi",
        "description": "Renting a Toyota Innova in Shirdi is simple and convenient. We provide flexible rental options, including hourly, daily, and weekly rates tailored to your specific travel needs. Our straightforward booking process ensures that your vehicle is ready when you need it, allowing you to focus on enjoying your journey without any hassle."
    },
    {
        "name": "Innova Car Hire Shirdi",
        "description": "With our Innova car hire services in Shirdi, expect quality and reliability. We prioritize customer satisfaction by offering vehicles that are regularly maintained to ensure safety and performance. Our experienced drivers are knowledgeable about the local area, providing you with a guided experience that enhances your travel adventure."
    },
    {
        "name": "Affordable Innova Rental Shirdi",
        "description": "Looking for affordable Innova rental in Shirdi? We offer competitive pricing that ensures you get the best value for your money without compromising on service quality. Our rental packages are designed to accommodate various budgets, and our transparent pricing policy means you won’t encounter hidden charges or unexpected fees."
    },
    {
        "name": "Toyota Innova for Hire in Shirdi",
        "description": "Our Toyota Innova for hire in Shirdi is perfect for various travel purposes, including family trips, corporate travel, or sightseeing excursions. The spacious interiors and comfortable seating allow you to relax during long journeys. Whether you’re heading to a temple or planning a weekend getaway, our Innova is equipped to meet all your transportation needs."
    },
    {
        "name": "Best Innova Rental Deals Shirdi",
        "description": "Discover the best Innova rental deals in Shirdi with our exclusive offers and discounts. We frequently update our promotional packages, making it easier for you to save on your rental costs. Our commitment to customer satisfaction ensures you receive top-notch service, making your journey memorable and enjoyable."
    },
    {
        "name": "Shirdi Toyota Innova Rental Services",
        "description": "Our Shirdi Toyota Innova rental services are designed to meet the diverse needs of our customers. We offer a fleet of well-maintained vehicles, ensuring you have reliable transportation for all your journeys. Our knowledgeable staff is available to assist you in choosing the best vehicle for your travel needs, ensuring a seamless booking process and exceptional service."
    },
    {
        "name": "Toyota Innova On Hire Near Shirdi",
        "description": "Finding a Toyota Innova on hire near Shirdi is easy with us. Our services ensure quick access to reliable transportation, whether you’re planning a short trip to nearby attractions or a long journey across the region. With convenient pickup and drop-off options, you can travel without worries, allowing you to enjoy the scenic routes and local sights."
    },
    {
        "name": "Shirdi Toyota Innova Car Rental Rates",
        "description": "Check our Shirdi Toyota Innova car rental rates, structured to fit different budgets. We believe in transparency, so you can expect no hidden charges or extra fees. Our flexible rental durations accommodate everything from quick errands to extended vacations, helping you budget your travel expenses effectively."
    },
    {
        "name": "Innova Rental Agency Shirdi",
        "description": "As a trusted Innova rental agency in Shirdi, we guarantee high standards of service and vehicle maintenance. Our fleet is regularly inspected to ensure your safety and comfort. Our drivers are trained professionals who prioritize your well-being, making your travel experience smooth and enjoyable from start to finish."
    },
    {
        "name": "Shirdi to Pune Innova Cab",
        "description": "Planning a trip from Shirdi to Pune? Our Innova cab services provide a comfortable and convenient means of transportation. Enjoy a smooth ride with our professional drivers who are well-versed in the best routes, ensuring you arrive at your destination on time and relaxed."
    },
    {
        "name": "Shirdi Airport Innova Taxi Service",
        "description": "Arriving at Shirdi Airport? Book our Innova taxi service for easy and convenient transportation to your destination. We ensure timely pickups and drop-offs for all our customers. Our drivers will assist you with your luggage, making your journey stress-free and enjoyable from the moment you land."
    },
    {
        "name": "Shirdi to Mumbai Innova Crysta",
        "description": "Travel from Shirdi to Mumbai in style with our Innova Crysta services. Experience the luxury and comfort of this premium vehicle, equipped with advanced features for a superior travel experience. Perfect for longer trips, the Innova Crysta elevates your journey, providing a relaxing atmosphere as you travel."
    },
    {
        "name": "Innova Crysta Car Hire in Shirdi",
        "description": "Our Innova Crysta car hire in Shirdi is available for various travel purposes. Enjoy spacious seating and premium features designed for comfort and convenience, whether for business meetings, family outings, or special events. Our Crysta models provide the comfort you need on the road."
    },
    {
        "name": "Book Innova Cabs in Shirdi",
        "description": "Booking an Innova cab in Shirdi is quick and easy. Our user-friendly booking process ensures you can secure a vehicle that meets your travel needs with minimal effort. You can reach us through our website or by phone, and our dedicated team will assist you with your reservations, making the entire process smooth."
    },
    {
        "name": "Innova Cab in Shirdi for Outstation",
        "description": "We offer Innova cabs in Shirdi for outstation travel, perfect for family trips, business meetings, or pilgrimages. Experience comfort and reliability on your journey with our well-maintained vehicles and experienced drivers who know the best routes to ensure a pleasant travel experience."
    },
    {
        "name": "Contact Information",
        "description": "For prompt and efficient Innova rentals in Shirdi, contact Saitirtha Travels at +91 9923879009 / 9922479009. Our friendly staff is ready to assist you with any inquiries or bookings. Enjoy a smooth and enjoyable ride with us, tailored to meet all your transportation needs."
    }
]
,
"tableData": [
    ["- Toyota Innova Shirdi Rental", "- Rent Toyota Innova in Shirdi"],
    ["- Innova Car Hire Shirdi", "- Affordable Innova Rental Shirdi"],
    ["- Toyota Innova for Hire in Shirdi", "- Best Innova rental deals Shirdi"],
    ["- Shirdi Toyota Innova Rental Services", "- SaitirthaTours & Travels Shirdi"],
    ["- Toyota Innova On Hire Near Shirdi", "- Shirdi Toyota Innova Car Rental Rates"],
    ["- Innova Rental Agency Shirdi", "- Shirdi to Pune Innova Cab"],
    ["- Shirdi Airport Innova Taxi Service", "- Shirdi to Mumbai Innova Crysta"],
    ["- Innova Crysta Car Hire in Shirdi", "- Book Innova Cabs in Shirdi"],
    ["- Innova Cab in Shirdi for Outstation"]
],
"whychoose": [
    {
        "WhyChooseheading": "Spacious Comfort:",
        "WhyChoosedescription": "The Toyota Innova is designed with spaciousness in mind, offering generous legroom and ample luggage space. This makes it an exceptional choice for family trips, group travel, or any journey where comfort is a priority. Passengers can stretch out, relax, and enjoy the ride, whether it's a short trip around town or a long-distance journey."
    },
    {
        "WhyChooseheading": "Luxury and Style:",
        "WhyChoosedescription": "Step into a world of luxury with the Toyota Innova. Its premium interiors, plush seating, and stylish design create an inviting atmosphere that enhances your travel experience. The comfortable seating ensures that every passenger enjoys a pleasant journey, making it perfect for both business and leisure travel."
    },
    {
        "WhyChooseheading": "Reliable Performance:",
        "WhyChoosedescription": "Our fleet of Innova vehicles is meticulously maintained to ensure reliability and performance. Whether navigating busy city streets or cruising along highways, these vehicles deliver a smooth and safe ride. You can trust that your Innova will be equipped to handle any travel scenario, providing peace of mind throughout your journey."
    },
    {
        "WhyChooseheading": "Professional Drivers:",
        "WhyChoosedescription": "Our team of professional drivers is dedicated to your safety and comfort. With extensive knowledge of local routes, they can navigate efficiently, avoiding traffic and ensuring timely arrivals. They are also committed to providing friendly service, making your travel experience enjoyable from start to finish."
    },
    {
        "WhyChooseheading": "Affordable Rates:",
        "WhyChoosedescription": "At Saitirtha Travels, we offer competitive pricing for our Innova rentals without compromising on quality. Our transparent pricing structure ensures that you know exactly what you’re paying for, with no hidden fees. This commitment to affordability allows you to plan your travel budget effectively while enjoying top-notch service."
    },
    {
        "WhyChooseheading": "Flexible Rental Packages:",
        "WhyChoosedescription": "Understanding that each traveler's needs are unique, we provide a variety of rental options tailored to your specific requirements. Whether you need the vehicle for a few hours, a full day, or even longer, our flexible packages make it easy to choose what works best for you, ensuring a seamless travel experience."
    },
    {
        "WhyChooseheading": "24/7 Support:",
        "WhyChoosedescription": "Our customer service team is available 24/7 to assist you with any aspect of your rental experience. Whether you have questions about your booking, need to make changes to your itinerary, or require assistance during your trip, we’re always just a phone call away. Our commitment to support means you can travel with confidence."
    },
    {
        "WhyChooseheading": "Hygiene and Safety:",
        "WhyChoosedescription": "We prioritize your health and safety by implementing strict hygiene protocols. Our vehicles are regularly sanitized, and we adhere to health guidelines to create a clean and safe environment for our passengers. This commitment to cleanliness ensures that you can travel comfortably and confidently, knowing your well-being is our top priority."
    },
    {
        "WhyChooseheading": "Convenient Booking Process:",
        "WhyChoosedescription": "Booking your Innova has never been easier. Our user-friendly online platform allows you to reserve your vehicle in just a few clicks, and our quick confirmation process ensures that you can start your journey with minimal delay. For those who prefer personalized assistance, our support team is readily available to help via phone."
    },
    {
        "WhyChooseheading": "Positive Customer Feedback:",
        "WhyChoosedescription": "We take great pride in the positive feedback from our customers, which reflects our dedication to providing excellent service. Our many satisfied clients are a testament to our commitment to quality and reliability, and we continually strive to meet and exceed your expectations. Your satisfaction is our goal, and we work hard to earn your trust."
    }
]


        
  }


  const faqData = [
    {
        question: "How do I book an Innova in Shirdi?",
        answer: "You can book an Innova through our website or by contacting our customer support for assistance."
    },
    {
        question: "Is the rental cost inclusive of the driver’s fee?",
        answer: "Yes, the rental price typically includes the driver’s fee, but please verify during the booking process."
    },
    {
        question: "How many passengers can the Innova accommodate?",
        answer: "The Toyota Innova can comfortably seat up to 7 passengers, making it perfect for larger groups or families."
    },
    {
        question: "Are there any additional charges?",
        answer: "We believe in transparent pricing. Any additional charges will be communicated to you before confirming your booking."
    },
    {
        question: "Can I rent the Innova for a one-way trip?",
        answer: "Yes, we offer one-way rental options for your convenience."
    },
    {
        question: "What payment methods do you accept?",
        answer: "We accept a variety of payment methods, including credit/debit cards, UPI, and cash."
    },
    {
        question: "What is your cancellation policy?",
        answer: "You can cancel your booking by contacting our customer support team prior to your scheduled rental date."
    },
    {
        question: "Is customer support available for queries?",
        answer: "Yes, our customer support team is available 24/7 to assist with any questions or concerns."
    },
    {
        question: "Can I request additional amenities with my rental?",
        answer: "Yes, you can specify any special requests during the booking process, and we will do our best to accommodate them."
    },
    {
        question: "Are your drivers licensed and experienced?",
        answer: "Yes, all our drivers are licensed professionals with experience in providing safe and pleasant travel experiences."
    }
];

const testimonials = [
    {
        name: "Ms. Neha Kapoor",
        text: "We hired an Innova for our family trip to Shirdi, and it was an excellent choice. The vehicle was spacious and comfortable, perfect for our group. Our driver was friendly, punctual, and knowledgeable, which made the journey even better. Highly recommend Saitirtha Travels!"
    },
    {
        name: "Mr. Ramesh Sharma",
        text: "Saitirtha Travels provided us with an Innova for our Shirdi pilgrimage, and we were extremely satisfied. The car was clean, well-maintained, and our driver was very accommodating. He ensured we had a pleasant experience throughout the trip. I will definitely choose them for future travel!"
    }
];




  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>

<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Innova on Rent in Shirdi | Affordable Toyota Innova Rentals | SaitirthaTours & Travels</title>
    <meta name="description" content="Rent a Toyota Innova in Shirdi. Affordable Innova rental, best rates for car hire, Shirdi Innova cabs for outstation, and more. Book your Innova now!" />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirtha Travels | Innova on Rent in Shirdi | Affordable Toyota Innova Rentals | SaitirthaTours & Travels" />
    <meta property="og:description" content="Rent a Toyota Innova in Shirdi. Affordable Innova rental, best rates for car hire, Shirdi Innova cabs for outstation, and more. Book your Innova now!" />
    <meta property="og:url" content="https://saitirthatravels.com/innova-on-rent-shirdi" />
    <meta property="og:image" content="https://saitirthatravels.com/images/innova-on-rent-shirdi.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Rent a Toyota Innova in Shirdi. Affordable Innova rental, best rates for car hire, Shirdi Innova cabs for outstation, and more. Book your Innova now!",
            "url": "https://saitirthatravels.com/innova-on-rent-shirdi",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/innova-on-rent-shirdi.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "180"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Toyota Innova Shirdi rental",
                "Rent Toyota Innova in Shirdi",
                "Innova car hire Shirdi",
                "Affordable Innova rental Shirdi",
                "Toyota Innova for hire in Shirdi",
                "Best Innova rental deals Shirdi",
                "Shirdi Toyota Innova rental services",
                "Toyota Innova on hire near Shirdi",
                "Shirdi Toyota Innova car rental rates",
                "Innova rental agency Shirdi",
                "Shirdi to Pune Innova cab",
                "Shirdi Airport Innova taxi service",
                "Shirdi to Mumbai Innova Crysta",
                "Innova Crysta car hire in Shirdi",
                "Book Innova cabs in Shirdi",
                "Innova cab in Shirdi for outstation"
            ]
        })}
    </script>
</Helmet>

      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/50.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Innovaonrent;