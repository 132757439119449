
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Punetoshani() {



  const cardData =
  {
    keyword: 'Pune to Shani Shingnapur Cab',
    heading: 'Saitirtha Travels: Pune to Shani Shingnapur Cab',
    headingDescription: 'For those looking to visit the revered Shani Shingnapur from Pune, Saitirtha Travels offers reliable and comfortable cab services. Our experienced drivers ensure a smooth ride, allowing you to relax and enjoy the journey to this sacred destination.',

    top: 'Top Places to Visit in Shani Shingnapur with Saitirtha Travels',

    topPlaces: [
        {
            "title": "Shani Shingnapur Temple",
            "description": "The main attraction of Shani Shingnapur is the famous Shani Shingnapur Temple, dedicated to Lord Shani. This unique temple is known for its open-air sanctum, where the idol of Lord Shani is installed on a black stone, symbolizing the planet Saturn. Devotees flock here to seek blessings and perform rituals."
        },
        {
            "title": "The Black Stone (Shani Lingam)",
            "description": "The black stone representing Lord Shani is the centerpiece of the temple. It is believed that the stone has immense power, and devotees often perform prayers and offer oil as a mark of respect. The stone's significance adds to the spiritual atmosphere of the temple."
        },
        {
            "title": "Akkalkot Swami Samarth Mandir",
            "description": "Located nearby, this temple is dedicated to Swami Samarth, a revered saint in Maharashtra. The temple attracts many devotees and is known for its serene environment, where visitors can meditate and seek blessings."
        },
        {
            "title": "Khandoba Temple",
            "description": "Situated a short distance from Shani Shingnapur, Khandoba Temple is dedicated to Lord Khandoba, an incarnation of Lord Shiva. The temple is beautifully designed and is an essential stop for those seeking spiritual fulfillment."
        },
        {
            "title": "Shani Shingnapur Village",
            "description": "The village itself is a fascinating place to explore. Known for its unique feature of having no doors on houses, the villagers believe that the blessings of Lord Shani protect them. Walking through the village gives visitors insights into the local culture and traditions."
        },
        {
            "title": "Maharashtra Police Museum",
            "description": "Located in the vicinity, this museum showcases the history and evolution of the police force in Maharashtra. It's an interesting stop for those keen on learning about the state’s law enforcement history and its artifacts."
        },
        {
            "title": "Kalyan Dombivli",
            "description": "While not directly in Shani Shingnapur, nearby towns like Kalyan and Dombivli offer historical sites and temples, making them worth a visit. The region is known for its rich cultural heritage and vibrant markets."
        },
        {
            "title": "Shivneri Fort",
            "description": "A short drive from Shani Shingnapur, this historical fort is the birthplace of Chhatrapati Shivaji Maharaj. The fort offers breathtaking views and a glimpse into Maharashtra's rich history."
        },
        {
            "title": "Malshej Ghat",
            "description": "A bit further away, Malshej Ghat is a stunning mountain pass known for its scenic beauty, especially during the monsoon season. It’s perfect for nature lovers and adventure enthusiasts looking to explore waterfalls and trekking trails."
        },
        {
            "title": "Ajanta and Ellora Caves",
            "description": "Located a few hours from Shani Shingnapur, these UNESCO World Heritage Sites feature stunning rock-cut caves and ancient sculptures, showcasing the region’s historical and artistic significance."
        }
    ],  
    
            
        
"services": [
    {
        "name": "Pune to Shani Shingnapur Cab Service",
        "description": "Travel conveniently from Pune to Shani Shingnapur with our reliable cab service. Known for its famous temple dedicated to Lord Shani, Shani Shingnapur is a popular destination for pilgrims seeking blessings and spiritual solace. Our cab service ensures that you have a comfortable journey, making it easier for you to focus on your pilgrimage."
    },
    {
        "name": "Pune to Shani Shingnapur Taxi",
        "description": "Our dedicated taxi service from Pune to Shani Shingnapur ensures a comfortable and hassle-free journey. Enjoy a smooth ride with our experienced drivers, who are familiar with the best routes and traffic conditions. We prioritize your comfort and safety, allowing you to arrive at your destination relaxed and ready for your spiritual experience."
    },
    {
        "name": "Shani Shingnapur to Pune Taxi Fare",
        "description": "The fare for a taxi ride from Shani Shingnapur to Pune varies based on the vehicle type and booking duration. We offer competitive rates with no hidden charges, providing you with peace of mind when it comes to budgeting your trip. Our transparent pricing structure is designed to give you the best value for your money."
    },
    {
        "name": "Pune to Shani Shingnapur Taxi Fare",
        "description": "Traveling from Pune to Shani Shingnapur? Our transparent pricing ensures you know the fare before you book your taxi, making your travel budget-friendly. We are committed to providing a clear breakdown of costs, allowing you to plan your pilgrimage without any financial stress."
    },
    {
        "name": "Pune to Shirdi and Shani Shingnapur Cab",
        "description": "We provide combined cab services for those wishing to visit both Shirdi and Shani Shingnapur. Enjoy a complete pilgrimage experience in one trip with our flexible itineraries. Our drivers are knowledgeable about both destinations, ensuring a smooth transition between these significant spiritual sites."
    },
    {
        "name": "Pimpri Chinchwad to Shani Shingnapur Cab",
        "description": "Our taxi service is also available for travelers from Pimpri Chinchwad to Shani Shingnapur. We ensure timely pickups and comfortable rides, making your journey enjoyable and stress-free. Our local drivers are well-acquainted with the area, allowing for a quick and efficient trip."
    },
    {
        "name": "Pimpri Chinchwad to Shirdi Shani Shingnapur",
        "description": "For those traveling from Pimpri Chinchwad to both Shirdi and Shani Shingnapur, we offer customized cab packages for your convenience. Experience seamless travel between these iconic pilgrimage sites, ensuring that your spiritual journey is both fulfilling and hassle-free."
    },
    {
        "name": "Pimpri Chinchwad to Ahilya Nagar Cab",
        "description": "We provide cab services from Pimpri Chinchwad to Ahilya Nagar, ensuring you reach your destination comfortably. Our reliable service and well-maintained vehicles make traveling easy, whether for business or leisure."
    },
    {
        "name": "Chinchwad to Ahilyanagar Taxi Fare",
        "description": "Our competitive taxi fares from Chinchwad to Ahilyanagar ensure you get value for your money while enjoying a pleasant travel experience. We offer clear pricing without hidden fees, allowing you to plan your journey with confidence."
    },
    {
        "name": "Pune to Shani Shingnapur Innova Crysta Rent",
        "description": "Travel in comfort and style with our Innova Crysta rental service from Pune to Shani Shingnapur. Ideal for families and larger groups, this spacious vehicle provides ample room for luggage and passengers, ensuring a relaxing travel experience."
    },
    {
        "name": "Pune to Shani Shingnapur Ertiga Cab on Rent",
        "description": "For budget-friendly travel, we offer Ertiga cabs on rent for your journey from Pune to Shani Shingnapur. These spacious and comfortable vehicles are perfect for family trips, allowing everyone to travel together without feeling cramped."
    },
    {
        "name": "Pune to Shirdi Cabs Round Trip",
        "description": "We offer round trip cab services from Pune to Shirdi and Shani Shingnapur, providing you with flexibility in your travel plans. This option is perfect for those who want to explore both destinations without the hassle of arranging multiple transportation methods."
    },
    {
        "name": "Pune to Bhimashankar Shirdi Shani Shingnapur Cab",
        "description": "Our service extends to trips from Pune to Bhimashankar, Shirdi, and Shani Shingnapur. Experience the best of these spiritual destinations with ease, as our dedicated drivers navigate the routes for a smooth and memorable journey."
    },
    {
        "name": "SaitirthaTours & Travels",
        "description": "Choose SaitirthaTours & Travels for all your transportation needs from Pune to Shani Shingnapur. We are committed to providing excellent service and customer satisfaction, ensuring that your journey is as enjoyable as your destination. Our professional team is here to assist you every step of the way."
    },
    {
        "name": "Pune to Shani Shingnapur Cab Contact Information",
        "description": "For bookings or inquiries, please contact SaitirthaTours & Travels at +91 9923879009 / +91 9922479009. Let us make your journey from Pune to Shani Shingnapur a memorable one! Our friendly staff is ready to assist you in planning the perfect trip."
    }
],


tableData: [
    ["- Pune to Shani Shingnapur Taxi", "- Shani Shingnapur to Pune Taxi Fare"],
    ["- Pune to Shani Shingnapur Taxi Fare", "- Pune to Shirdi and Shani Shingnapur Cab"],
    ["- Pimpri Chinchwad to Shani Shingnapur Cab", "- Pimpri Chinchwad to Shirdi Shani Shingnapur"],
    ["- Pimpri Chinchwad to Ahilya Nagar Cab", "- Chinchwad To Ahilyanagar Taxi Fare"],
    ["- Pune to Shani Shingnapur Innova Crysta Rent", "- Pune to Shani Shingnapur Ertiga Cab on Rent"],
    ["- Pune to Shirdi Cabs Round Trip", "- Pune to Bhimashankar Shirdi Shani Shingnapur Cab"],
    ["- SaitirthaTours & Travels Shirdi", ""]
  ],
  
  whychoose: [
    {
      "WhyChooseheading": "Reliable Transportation",
      "WhyChoosedescription": "We provide dependable taxi services from Pune to Shani Shingnapur, ensuring a smooth journey to your destination. Our vehicles are always ready and punctual, helping you avoid any delays and providing you with peace of mind throughout your trip."
    },
    {
      "WhyChooseheading": "Comfortable Rides",
      "WhyChoosedescription": "Our vehicles are well-maintained, equipped with modern amenities such as air conditioning, comfortable seating, and ample space to ensure you have a comfortable travel experience. We focus on providing you with a relaxing ride, no matter how long your journey may be."
    },
    {
      "WhyChooseheading": "Professional Drivers",
      "WhyChoosedescription": "Our drivers are highly trained, courteous, and experienced in handling long-distance trips. They are familiar with the best routes to Shani Shingnapur and ensure a smooth and safe ride. They are always ready to assist with any travel-related needs or questions you may have along the way."
    },
    {
      "WhyChooseheading": "Affordable Pricing",
      "WhyChoosedescription": "We offer competitive pricing with no hidden charges, ensuring transparency in all our transactions. Our rates are affordable, allowing you to enjoy a comfortable and safe ride without breaking your budget. Whether you’re booking a one-way trip or a round trip, we provide you with great value for your money."
    },
    {
      "WhyChooseheading": "24/7 Availability",
      "WhyChoosedescription": "Our taxi service is available around the clock, allowing you to book a ride at any time that suits your travel schedule. Whether it's an early morning flight, a late-night ride, or a last-minute trip, you can rely on us to be available when you need us the most."
    },
    {
      "WhyChooseheading": "Easy Booking Process",
      "WhyChoosedescription": "Booking your ride from Pune to Shani Shingnapur is quick and easy. Our online platform allows you to book a cab with just a few clicks. If you prefer personalized assistance, our customer service team is always available to help with bookings and any travel-related queries."
    },
    {
      "WhyChooseheading": "Flexible Itineraries",
      "WhyChoosedescription": "We understand that every traveler has unique needs, which is why we offer flexible itineraries that can be customized based on your specific requirements. Whether you need a one-way trip, round-trip, or a multi-day package, we tailor our services to ensure your convenience."
    },
    {
      "WhyChooseheading": "Safety First",
      "WhyChoosedescription": "Your safety is our top priority. All our vehicles undergo regular maintenance, and we adhere to strict safety standards. Additionally, we maintain a strict sanitization routine to ensure the cleanliness and hygiene of all our vehicles, creating a safe environment for you and your loved ones."
    },
    {
      "WhyChooseheading": "Dedicated Customer Support",
      "WhyChoosedescription": "Our dedicated customer support team is always ready to assist you with any inquiries or concerns, whether it's related to bookings, travel arrangements, or special requests. We ensure a seamless experience from start to finish, ensuring your complete satisfaction throughout your journey."
    },
    {
      "WhyChooseheading": "Tourist-Friendly Service",
      "WhyChoosedescription": "Our drivers are not only skilled at navigating the routes, but they also have a wealth of local knowledge. They can share information about the attractions along the way, provide recommendations for local sights, and help you plan a memorable trip. We make your journey more enjoyable and informative, adding a personal touch to your travel experience."
    }
  ]
  




        
  }


  const faqData = [
    {
        question: "What is the distance from Pune to Shani Shingnapur?",
        answer: "The distance is approximately 200 kilometers, which usually takes about 4-5 hours by cab."
    },
    {
        question: "How do I book a cab from Pune to Shani Shingnapur?",
        answer: "You can easily book a cab through our website or by contacting our customer service team."
    },
    {
        question: "What types of vehicles are available for this route?",
        answer: "We offer a variety of vehicles, including sedans, SUVs, and vans, to accommodate different group sizes."
    },
    {
        question: "Are there additional charges for tolls or parking?",
        answer: "Yes, any applicable tolls and parking fees will be added to the final fare and communicated during booking."
    },
    {
        question: "Can I book a return trip from Shani Shingnapur to Pune?",
        answer: "Yes, we provide round-trip services and can tailor your travel itinerary according to your needs."
    },
    {
        question: "What are the fare rates for Pune to Shani Shingnapur?",
        answer: "The fare varies based on vehicle type and duration of travel. Please contact us for a detailed quote."
    },
    {
        question: "Is it safe to travel with Saitirtha Travels?",
        answer: "Yes, we prioritize your safety with well-maintained vehicles and experienced drivers."
    },
    {
        question: "Can I modify my booking after confirmation?",
        answer: "Yes, you can modify your booking; just inform us as soon as possible to accommodate your changes."
    },
    {
        question: "What is your cancellation policy?",
        answer: "We have a cancellation policy in place. Please reach out for specific details."
    },
    {
        question: "How can I contact customer support?",
        answer: "You can reach our customer support team via phone or email for any inquiries or assistance."
    }
];

const testimonials = [
    {
        name: "Mr. Suresh Patil",
        text: "I had a fantastic experience with Saitirtha Travels for my cab ride from Pune to Shani Shingnapur. The booking process was easy, and the driver was very punctual and professional. The ride was comfortable, and I appreciated the great service throughout the journey. I highly recommend Saitirtha Travels for anyone planning to visit Shani Shingnapur!"
    },
    {
        name: "Ms. Radhika Mehta",
        text: "Renting a cab from Pune to Shani Shingnapur with Saitirtha Travels was a great decision! The vehicle was clean and well-maintained, making our trip enjoyable. The driver was knowledgeable about the route, which made the journey smooth and pleasant. I will definitely use their services again!"
    }
];





  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Pune to Shani Shingnapur Cab | Shirdi & Shani Shingnapur Taxi Services</title>
    <meta name="description" content="Book a Pune to Shani Shingnapur Cab with Saitirtha Travels. We offer affordable taxi services for a seamless journey to Shani Shingnapur from Pune, including round trips, Innova Crysta, Ertiga cabs, and more." />
    <meta name="keywords" content="Pune to Shani Shingnapur Taxi, Shani Shingnapur to Pune Taxi Fare, Pune to Shani Shingnapur Taxi Fare, Pune to Shirdi and Shani Shingnapur Cab, Pimpri Chinchwad to Shani Shingnapur Cab, Pimpri Chinchwad to Shirdi Shani Shingnapur, Pimpri Chinchwad to Ahilya Nagar Cab, Pune to Shani Shingnapur Innova Crysta Rent, Pune to Shani Shingnapur Ertiga Cab on Rent, Pune to Shirdi Cabs Round Trip, Pune to Bhimashankar Shirdi Shani Shingnapur Cab" />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirtha Travels | Pune to Shani Shingnapur Cab | Shirdi & Shani Shingnapur Taxi Services" />
    <meta property="og:description" content="Book a reliable and affordable Pune to Shani Shingnapur Cab with Saitirtha Travels. We offer convenient taxi services, including round trips, Innova Crysta, Ertiga cabs, and more." />
    <meta property="og:url" content="https://saitirthatravels.com/pune-to-shani-shingnapur-cab" />
    <meta property="og:image" content="https://saitirthatravels.com/images/pune-to-shani-shingnapur-cab.jpg" />
    <meta name="twitter:card" content="summary_large_image" />
    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Book a Pune to Shani Shingnapur Cab with Saitirtha Travels. We offer affordable taxi services, round trips, and car rentals including Innova Crysta and Ertiga for a smooth journey to Shani Shingnapur.",
            "url": "https://saitirthatravels.com/pune-to-shani-shingnapur-cab",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/pune-to-shani-shingnapur-cab.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "250"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Pune to Shani Shingnapur Taxi",
                "Shani Shingnapur to Pune Taxi Fare",
                "Pune to Shani Shingnapur Taxi Fare",
                "Pune to Shirdi and Shani Shingnapur Cab",
                "Pimpri Chinchwad to Shani Shingnapur Cab",
                "Pimpri Chinchwad to Shirdi Shani Shingnapur",
                "Pimpri Chinchwad to Ahilya Nagar Cab",
                "Pune to Shani Shingnapur Innova Crysta Rent",
                "Pune to Shani Shingnapur Ertiga Cab on Rent",
                "Pune to Shirdi Cabs Round Trip",
                "Pune to Bhimashankar Shirdi Shani Shingnapur Cab"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/83.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Punetoshani;