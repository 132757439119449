
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function BestCabserviceinshirdi() {



  const cardData =
  {
    keyword: 'Best Cab Service in Shirdi',
    heading: 'Best Cab Service in Shirdi – Saitirtha Travels',
    headingDescription: 'Saitirtha Travels is the leading cab service in Shirdi, known for its reliable, affordable, and comfortable transportation solutions. Whether you are on a pilgrimage or exploring the historic town, Saitirtha Travels ensures a seamless travel experience. With well-maintained vehicles and experienced drivers, it is the best choice for tourists seeking safe and convenient transportation in and around Shirdi.',
    top: 'Top Places to Visit in Shirdi with Saitirtha Travels',

    topPlaces: [
      {
        "title": "1. Shirdi Sai Baba Temple",
        "description": "The Sai Baba Temple, also known as Shri Saibaba Sansthan Temple, is the main attraction in Shirdi. This sacred shrine holds the samadhi (resting place) of Sai Baba and is visited by thousands of devotees daily. The temple complex is spacious and peaceful, creating an atmosphere that promotes spiritual connection. Visitors can witness the daily rituals and experience the serene environment that enhances their spiritual journey."
      },
      {
        "title": "2. Dwarkamai",
        "description": "Dwarkamai is a significant mosque where Sai Baba spent a considerable amount of his life. Here, devotees can see relics like the sacred fire, which continues to burn to this day. Dwarkamai stands as a symbol of love and unity, welcoming people from all faiths. The mosque is adorned with pictures and articles related to Sai Baba's life, making it a place of reflection and devotion."
      },
      {
        "title": "3. Chavadi",
        "description": "Located near Dwarkamai, Chavadi was where Sai Baba used to rest every alternate night. The Chavadi procession is a popular event in Shirdi, which re-enacts Sai Baba’s journey to this place. This site is revered by devotees and holds great historical significance. It is a place where you can feel the essence of Sai Baba’s teachings and legacy."
      },
      {
        "title": "4. Sai Heritage Village",
        "description": "The Sai Heritage Village is a theme park that recreates the life and times of Sai Baba, offering an interactive experience for visitors. It is an excellent place for families, providing insights into rural life during Sai Baba’s era with life-sized statues and models. The park includes fun activities for children and adults alike, making it a wonderful educational experience."
      },
      {
        "title": "5. Gurusthan",
        "description": "Gurusthan is the place where Sai Baba was first spotted meditating under a neem tree by locals. It holds spiritual importance as Sai Baba himself declared this place as sacred. The neem tree still stands and is believed to possess healing powers. Visitors often come to meditate and seek blessings in this tranquil environment."
      },
      {
        "title": "6. Lendi Baug",
        "description": "Lendi Baug is a beautiful garden where Sai Baba used to meditate and water plants. The garden contains a lamp that was lit by Sai Baba and continues to burn in his memory. The peaceful atmosphere of Lendi Baug makes it an ideal spot for relaxation and reflection, allowing visitors to connect with nature and spirituality."
      },
      {
        "title": "7. Khandoba Mandir",
        "description": "Located near the Sai Baba Temple, Khandoba Mandir is another popular temple in Shirdi. It is dedicated to Lord Khandoba, a form of Lord Shiva. This temple has historical importance as it is believed to be the first place where Sai Baba was addressed as 'Sai.' The vibrant festivals celebrated here attract many devotees and add to the spiritual ambiance."
      },
      {
        "title": "8. Wet N Joy Water Park",
        "description": "For those looking for a fun outing, Wet N Joy Water Park offers an excellent break from the spiritual journey. With water rides, wave pools, and entertainment for all ages, it’s a family-friendly spot close to the main temple area. It provides a perfect way to relax and unwind after a day of devotion."
      },
      {
        "title": "9. Sai Baba’s Prasadalaya",
        "description": "Sai Baba's Prasadalaya serves free meals to thousands of devotees every day. Experiencing the prasad (holy meal) here is a fulfilling experience, where visitors can enjoy a simple, delicious meal served in a spirit of community and love. This act of sharing food reflects Sai Baba's teachings on generosity and compassion."
      },
      {
        "title": "10. Upasani Maharaj Ashram",
        "description": "The Upasani Maharaj Ashram is dedicated to Sai Baba’s disciple, Upasani Maharaj. This tranquil place offers visitors a chance to meditate and reflect on the teachings of both Sai Baba and his devoted follower. It’s a lesser-known spot that provides spiritual solace, allowing for quiet contemplation and inner peace amidst the bustling town."
      }
    ],

    "services": [
      {
        "name": "Best Cab Service in Shirdi",
        "description": "When it comes to exploring the spiritual haven of Shirdi, SaitirthaTours & Travels offers the best cab service tailored to your travel needs. Our cabs are comfortable and reliable, ensuring a smooth journey whether you're visiting the iconic Shirdi Sai Baba Temple or exploring local attractions. Experience our commitment to excellence and convenience throughout your travels."
      },
      {
        "name": "Reliable Taxi Service in Shirdi",
        "description": "Our reliable taxi service in Shirdi guarantees punctuality and safety, making us the preferred choice for both pilgrims and tourists. With experienced drivers who know the local routes, you can rest assured that you will reach your destination on time and enjoy a stress-free journey."
      },
      {
        "name": "Affordable Cab Service in Shirdi",
        "description": "Traveling on a budget? Our affordable cab service in Shirdi is designed to provide quality transportation without breaking the bank. We offer a variety of packages that cater to diverse needs, allowing you to experience the best of Shirdi without worrying about high transportation costs."
      },
      {
        "name": "Shirdi Car Rental Options",
        "description": "For those who prefer flexibility, our Shirdi car rental options allow you to hire a vehicle for a day or longer. Explore Shirdi at your own pace with our well-maintained cars that ensure a comfortable and safe driving experience."
      },
      {
        "name": "Top Cab Company in Shirdi",
        "description": "As the top cab company in Shirdi, we prioritize customer satisfaction above all else. Our fleet includes various vehicles suitable for solo travelers, families, and groups, all equipped with modern amenities to enhance your journey."
      },
      {
        "name": "Taxi Booking in Shirdi",
        "description": "Booking a taxi in Shirdi has never been easier! Our online taxi booking service allows you to reserve your ride with just a few clicks, ensuring a seamless travel experience from start to finish."
      },
      {
        "name": "Shirdi Cab Transportation Service",
        "description": "Our Shirdi cab transportation service covers all essential routes and attractions, from bustling local markets to serene temples. We ensure you visit all the highlights of Shirdi with ease and comfort."
      },
      {
        "name": "Comfortable Cabs in Shirdi",
        "description": "Comfort is our priority. Our comfortable cabs in Shirdi are designed for a relaxing journey for every passenger. Whether you’re traveling alone or with companions, expect a smooth ride and friendly service."
      },
      {
        "name": "Shirdi Taxi Hire",
        "description": "Looking for a Shirdi taxi hire service? We offer flexible hiring options to accommodate your travel itinerary, ensuring our dedicated team is ready to assist with all your transportation needs."
      },
      {
        "name": "Best Taxi Service in Shirdi",
        "description": "At SaitirthaTours & Travels, we take pride in being the best taxi service in Shirdi. Our knowledgeable drivers are familiar with local attractions, ensuring you get the most out of your visit while enjoying reliability and convenience."
      },
      {
        "name": "Innova Crysta on Rent in Shirdi",
        "description": "Traveling with a group? Our Innova Crysta on rent in Shirdi is an excellent choice for larger parties. This spacious vehicle allows families to travel together comfortably, enjoying ample legroom and modern amenities."
      },
      {
        "name": "Tempo Traveller on Rent in Shirdi",
        "description": "For larger groups, we offer the best tempo traveller on rent in Shirdi. Ideal for family outings or group pilgrimages, our tempo travellers are well-equipped to ensure a comfortable journey for everyone."
      },
      {
        "name": "Online Cab Booking in Shirdi",
        "description": "Experience the convenience of our online cab service in Shirdi. With just a few clicks, you can book a cab that suits your travel needs, allowing you to focus on enjoying your spiritual journey."
      },
      {
        "name": "Outstation Taxi in Shirdi",
        "description": "Planning to explore nearby destinations? Our outstation taxi in Shirdi service is perfect for day trips or extended excursions. Whether you want to visit Nasik, Aurangabad, or any other nearby location, we provide comfortable and reliable transportation."
      },
      {
        "name": "Contact Information for Best Cab Service in Shirdi",
        "description": "Contact SaitirthaTours & Travels at +91 9923879009 or +91 9922479009 for prompt and efficient cab service in Shirdi. We ensure a smooth and enjoyable ride for all our customers, making your journey memorable."
      }
    ],
    tableData: [
      ['- Best cab in Shirdi', '- Reliable Taxi Shirdi'],
      ['- Affordable Cab Service', '- Shirdi Car Rental'],
      ['- Top Cab Company', '- Shirdi Taxi Booking'],
      ['- Shirdi Cab Transportation Service', '- Comfortable Cab Shirdi'],
      ['- Shirdi Taxi Hire', '- Best Taxi Service in Shirdi'],
      ['- Best Cab Service in Shirdi', '- Shirdi Saibaba Travels'],
      ['- Taxi Service in Shirdi', '- Shirdi Cab Service'],
      ['- Cab booking in Shirdi', '- Top Rated Cab in Shirdi'],
      ['- Innova Crysta on Rent in Shirdi', '- Best Tempo Traveller on Rent in Shirdi'],
      ['- Online Cab Service in Shirdi', '- Online Cab Booking in Shirdi']
    ],

    whychoose: [
      {
        WhyChooseheading: "Why Choose Saitirtha Travels Best Cab Service in Shirdi?",
        WhyChoosedescription: "At Saitirtha Travels, we are committed to providing the best cab service in Shirdi, ensuring that your journey is as enjoyable as your destination. Here’s why you should choose us:"
      },
      {
        WhyChooseheading: "Reliability:",
        WhyChoosedescription: "Our drivers are punctual and professional, guaranteeing timely pickups and drop-offs. With Saitirtha Travels, you can trust that we will always be on time, allowing you to plan your day without any hassle or stress. Our commitment to reliability means you can focus on enjoying your trip rather than worrying about transportation."
      },
      {
        WhyChooseheading: "Comfort:",
        WhyChoosedescription: "We prioritize your comfort by offering a fleet of well-maintained vehicles equipped with modern amenities such as air conditioning, comfortable seating, and entertainment options. Whether you’re traveling alone or with family, you’ll enjoy a smooth and relaxing ride, making every journey a pleasure. Our vehicles are designed to provide ample space for luggage and personal belongings."
      },
      {
        WhyChooseheading: "Affordability:",
        WhyChoosedescription: "We offer competitive rates without compromising on service quality. Our various packages are designed to fit different budgets, allowing you to explore Shirdi without financial stress. With transparent pricing and no hidden charges, you can enjoy peace of mind knowing exactly what you’ll pay for your ride. We also offer special discounts for group bookings and repeat customers."
      },
      {
        WhyChooseheading: "Experienced Drivers:",
        WhyChoosedescription: "Our drivers are not only skilled at navigating local roads but also possess in-depth knowledge of Shirdi’s attractions and best routes. They can provide valuable insights and recommendations during your journey, ensuring you don’t miss out on popular sights and local gems. Our drivers undergo thorough training to ensure they provide safe and enjoyable rides for all passengers."
      },
      {
        WhyChooseheading: "Flexible Booking Options:",
        WhyChoosedescription: "With our user-friendly online cab booking platform, reserving a ride has never been easier. You can book your cab in just a few clicks, ensuring convenience and peace of mind. Our system allows for instant confirmation and flexible cancellation policies, so you can modify your plans as needed. We also offer phone support for customers who prefer to book their rides through direct communication."
      },
      {
        WhyChooseheading: "Customer Satisfaction:",
        WhyChoosedescription: "We take pride in our commitment to customer service, actively seeking feedback to improve our offerings continuously. Our dedicated customer support team is available 24/7 to assist with your needs, ensuring your experience is seamless from start to finish. We value our customers and strive to exceed their expectations in every ride, aiming for long-term relationships built on trust and satisfaction."
      },
      {
        WhyChooseheading: "Variety of Services:",
        WhyChoosedescription: "Whether you need a local taxi for a short trip, outstation travel for a weekend getaway, or a rental vehicle like the Innova Crysta or tempo traveler for larger groups, we have a wide range of options to meet your specific requirements. Our service flexibility means you can choose the right vehicle based on your travel needs, ensuring comfort and convenience no matter the occasion."
      }
    ]
  }



  const faqData = [
    {
      question: "How do I book a cab with Saitirtha Travels?",
      answer: "You can easily book a cab through our user-friendly online platform. Simply enter your pickup location, destination, and choose your preferred vehicle to complete your booking."
    },
    {
      question: "Are your rates competitive?",
      answer: "Yes, we offer competitive rates for all our services. Our pricing is transparent, with no hidden fees, ensuring you get value for your money."
    },
    {
      question: "What types of vehicles do you offer?",
      answer: "We provide a variety of vehicles, including sedans, SUVs, and larger options like the Innova Crysta and tempo travelers, catering to solo travelers, families, and groups."
    },
    {
      question: "Are your drivers experienced?",
      answer: "Absolutely! Our drivers are well-trained, professional, and knowledgeable about local routes and attractions, ensuring a safe and pleasant journey."
    },
    {
      question: "Is your cab service available for outstation travel?",
      answer: "Yes, we offer outstation taxi services for trips to nearby destinations like Nasik and Aurangabad. Our comfortable vehicles are perfect for long journeys."
    },
    {
      question: "What if I need to cancel my booking?",
      answer: "We have a flexible cancellation policy. Please contact our customer service team, and they will assist you with the cancellation process."
    },
    {
      question: "How can I contact customer support?",
      answer: "You can reach our customer support team via phone or email. We are available 24/7 to assist you with any questions or concerns."
    },
    {
      question: "Do you provide pick-up and drop-off services at the airport?",
      answer: "Yes, we offer airport pick-up and drop-off services as part of our comprehensive cab services in Shirdi."
    }
  ];



  const testimonials = [
    {
      name: "Mrs. Anita Sharma",
      text: "I recently visited Shirdi for a pilgrimage and chose Saitirtha Travels for my transportation needs. From the moment I booked my cab, I was impressed by their professionalism and attention to detail. The driver was punctual, friendly, and knowledgeable about the local area, which made my journey even more enjoyable. The cab was clean and comfortable, making it easy to relax between visits to the temples. I highly recommend Saitirtha Travels to anyone looking for the best cab service in Shirdi!"
    },
    {
      name: "Mr. Rajesh Gupta",
      text: "Saitirtha Travels truly lives up to its reputation as the best cab service in Shirdi! I hired them for a two-day trip, and everything was perfect. The booking process was straightforward, and the customer support team was very helpful. Our driver was not only on time but also went above and beyond to ensure we had a pleasant experience. The vehicle was spacious and well-maintained, making our travels comfortable. I will definitely use their services again on my next visit!"
    }
  ];

  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>

<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Best Cab Service in Shirdi | SaitirthaTours & Travels</title>
    <meta name="description" content="Reliable and affordable cab service in Shirdi. Book the best taxi for local and outstation travel with SaitirthaTours & Travels. Experience comfort and convenience in Shirdi." />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirtha Travels | Best Cab Service in Shirdi | SaitirthaTours & Travels" />
    <meta property="og:description" content="Reliable and affordable cab service in Shirdi. Book the best taxi for local and outstation travel with SaitirthaTours & Travels. Experience comfort and convenience in Shirdi." />
    <meta property="og:url" content="https://saitirthatravels.com/shirdi-cab-service" />
    <meta property="og:image" content="https://saitirthatravels.com/images/shirdi-cab-service.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Best cab service in Shirdi for local and outstation travel. Reliable, affordable, and comfortable cab and taxi rentals.",
            "url": "https://saitirthatravels.com/shirdi-cab-service",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/shirdi-cab-service.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi Cab Service", 
                "SaitirthaTours & Travels Shirdi", 
                "Best cab in Shirdi", 
                "Reliable Taxi Shirdi", 
                "Affordable Cab Service", 
                "Shirdi Car Rental", 
                "Top Cab Company Shirdi", 
                "Taxi Booking Shirdi", 
                "Shirdi Cab Transportation Service", 
                "Comfortable Cab Shirdi", 
                "Shirdi Taxi Hire", 
                "Best Taxi Service in Shirdi", 
                "Best Cab Service in Shirdi", 
                "Shirdi Saibaba Travels", 
                "Taxi Service in Shirdi", 
                "Shirdi Cab Service", 
                "Cab booking in Shirdi", 
                "Top Rated Cab In Shirdi", 
                "Outstation Taxi in Shirdi", 
                "Innova Crysta On Rent in Shirdi", 
                "Best Tempo Traveller on Rent in Shirdi", 
                "Online Cab Service in Shirdi", 
                "Online Cab Booking in Shirdi"
            ]
        })}
    </script>
</Helmet>

      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/16. Best cab service in Shirdi.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs Best Cab Service in Shirdi for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default BestCabserviceinshirdi;