
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Shirdionewaycabservice() {



  const cardData =
  {
    keyword: ' Shirdi One Way Cab Service',
    heading: 'Saitirtha Travels: Shirdi One Way Cab Service',
    headingDescription: 'For travelers seeking flexibility and convenience, Saitirtha Travels offers reliable one way cab services from Shirdi to various destinations. Our focus is on providing you with a hassle-free journey, allowing you to enjoy the ride without the stress of round-trip travel.',

    top: 'Top Places to Visit from Shirdi with Saitirtha Travels',

    topPlaces: [
        {
            "title": "Nashik",
            "description": "Located approximately 90 kilometers from Shirdi, Nashik is a vibrant city renowned for its lush wineries, ancient temples, and the sacred Godavari River. It is a key pilgrimage destination, especially the Panchavati area, where several significant temples like the Kalaram Temple and the Saptashrungi Temple are situated. Visitors can enjoy wine tours, explore historical sites, and participate in local festivities, making Nashik a delightful blend of spirituality and nature."
        },
        {
            "title": "Shani Shingnapur",
            "description": "About 70 kilometers from Shirdi, Shani Shingnapur is famous for its unique temple dedicated to Lord Shani, the deity of justice. The village is notable for its houses built without doors, a testament to the residents' unwavering faith in the deity's protection from theft and harm. Visitors can experience the temple rituals, learn about the local customs, and explore the intriguing traditions that make this village a fascinating destination."
        },
        {
            "title": "Ajanta and Ellora Caves",
            "description": "Approximately 100 kilometers from Shirdi, the Ajanta and Ellora Caves are UNESCO World Heritage Sites celebrated for their stunning rock-cut architecture and intricate sculptures dating back to ancient times. Ajanta is renowned for its exquisite Buddhist paintings, while Ellora showcases a remarkable blend of Hindu, Buddhist, and Jain cave temples. The artistic brilliance and historical significance of these caves make them a must-visit for anyone interested in India's rich cultural heritage."
        },
        {
            "title": "Bhandardara",
            "description": "Situated around 150 kilometers from Shirdi, Bhandardara is a tranquil hill station known for its breathtaking landscapes, picturesque waterfalls, and the serene Arthur Lake. This idyllic destination is perfect for nature lovers and adventure seekers, offering opportunities for trekking, camping, and photography. The serene environment, combined with the stunning views of the Western Ghats, makes Bhandardara an ideal retreat for those looking to escape the city's chaos."
        },
        {
            "title": "Saptashrungi Devi Temple",
            "description": "Located about 130 kilometers from Shirdi, the Saptashrungi Devi Temple is dedicated to Goddess Saptashrungi and perched on a hill, offering stunning views of the surrounding landscape. The trek to the temple is both a spiritual journey and a rewarding experience, as visitors can enjoy the natural beauty along the way. This temple is a popular pilgrimage site, attracting devotees seeking blessings and spiritual fulfillment in a serene setting."
        },
        {
            "title": "Khandoba Temple",
            "description": "Khandoba Temple, situated in Jejuri approximately 100 kilometers from Shirdi, is a significant pilgrimage site dedicated to Lord Khandoba, a deity worshiped for strength and protection. The temple attracts numerous devotees, especially during festivals when vibrant celebrations fill the air. Visitors can explore the temple's intricate architecture and immerse themselves in the lively atmosphere that characterizes this important religious site."
        },
        {
            "title": "Dharampur",
            "description": "Roughly 120 kilometers from Shirdi, Dharampur is a peaceful retreat surrounded by lush greenery and tranquil landscapes. This serene destination is ideal for those seeking to escape the hustle and bustle of city life and immerse themselves in nature. Visitors can enjoy leisurely walks, indulge in local cuisine, and experience the calming ambiance, making Dharampur a perfect getaway for relaxation and rejuvenation."
        },
        {
            "title": "Anjneri Hill",
            "description": "Anjneri Hill, located near Nashik, is believed to be the birthplace of Lord Hanuman and is a popular trekking spot for adventure enthusiasts. The trek to the hilltop offers breathtaking views of the surrounding valleys and a chance to connect with nature. The hill is also home to several temples and is often frequented by those seeking spiritual growth and adventure, making it a rewarding destination for all."
        },
        {
            "title": "Panchavati",
            "description": "Panchavati is a sacred area in Nashik, rich in history and mythology, known for its association with the epic Ramayana. This serene region is home to several important temples, including the Kalaram Temple, and provides a peaceful ambiance along the banks of the Godavari River. Visitors can explore the temples, enjoy the natural beauty, and engage in local rituals, making Panchavati a significant spiritual and cultural destination."
        },
        {
            "title": "Manmad",
            "description": "Located about 60 kilometers from Shirdi, Manmad is a town of spiritual significance featuring numerous temples and historical sites. It serves as a convenient stopover for pilgrims heading to various religious destinations in the region. The town's vibrant culture and religious atmosphere offer visitors a chance to immerse themselves in the local traditions and explore the spiritual landscape that defines this unique destination."
        }
    ],    
    
            
        
"services": [
    {
        "name": "Shirdi One Way Cab Service",
        "description": "Explore our convenient Shirdi one way cab service for your travel needs, offering affordable rates and reliable transportation. This service is ideal for travelers seeking a straightforward and hassle-free journey to their destination without the need for round-trip arrangements. Our professional drivers ensure that you enjoy a comfortable ride while providing you with insights about the local area, making your journey not just a commute, but a part of your overall travel experience."
    },
    {
        "name": "Shirdi One Way Cab Services Rates",
        "description": "We provide competitive rates for our Shirdi one way cab services, ensuring you receive excellent value for your journey. Our transparent pricing policy means that you’ll know exactly what you’re paying for, with no hidden charges. We offer various vehicle options to suit your budget and preferences, from economical sedans to larger SUVs, ensuring that there’s a perfect fit for every traveler."
    },
    {
        "name": "Shirdi to Mumbai Cab One Way",
        "description": "Travel from Shirdi to Mumbai with ease. Our one way cab service offers direct routes and comfortable rides tailored to your schedule. Enjoy the scenic views along the way while our experienced drivers take care of the journey. Whether you're heading for business, leisure, or a family visit, we ensure a pleasant ride with timely pick-up and drop-off, allowing you to focus on what matters most during your trip."
    },
    {
        "name": "SaitirthaTours & Travels Shirdi",
        "description": "Trust SaitirthaTours & Travels for all your one way cab service needs in Shirdi. We are dedicated to customer satisfaction and strive to provide an exceptional travel experience. Our team is committed to understanding your needs and providing solutions that cater to them. With a reputation for reliability and quality service, we are your go-to option for transportation in and around Shirdi."
    },
    {
        "name": "Shirdi to Mumbai Cab One Way Fare",
        "description": "Contact us for the latest fare information on our Shirdi to Mumbai one way cab service. We offer transparency and no hidden charges, allowing you to plan your travel budget without surprises. Our pricing structure is designed to be competitive while maintaining high standards of service, ensuring you receive both quality and value for your money."
    },
    {
        "name": "Shirdi to Pune Cab One Way",
        "description": "Need a ride from Shirdi to Pune? Our one way cab service is designed to meet your schedule and travel preferences. We offer flexible pick-up times and comfortable vehicles, ensuring that your journey is not only convenient but also enjoyable. Our professional drivers will navigate the best routes to make sure you reach Pune swiftly and safely."
    },
    {
        "name": "Shirdi to Pune Aurangabad One Way Cab",
        "description": "Book a one way cab from Shirdi to Pune Aurangabad for a hassle-free travel experience. Enjoy comfortable seating and a smooth ride, whether you’re traveling for business or leisure. Our drivers are well-acquainted with the route and local attractions, providing a seamless travel experience from start to finish."
    },
    {
        "name": "Shirdi to Bhimashankar One Way Cab",
        "description": "Choose our one way cab service from Shirdi to Bhimashankar for a spiritual journey. We ensure a pleasant and safe trip, allowing you to focus on your pilgrimage. Our drivers are knowledgeable about the area, providing insights along the way and ensuring you arrive at your destination relaxed and ready for your spiritual experience."
    },
    {
        "name": "Shirdi to Nagpur One Way Taxi",
        "description": "Our Shirdi to Nagpur one way taxi service offers reliable transportation for your convenience. Sit back and relax during your journey as our experienced drivers handle the road. We prioritize your comfort and safety, providing you with a reliable travel option for your trip to Nagpur."
    },
    {
        "name": "Shirdi to Mahakaleshwar One Way Taxi",
        "description": "Travel to Mahakaleshwar from Shirdi in comfort with our one way taxi service. We take care of your travel needs with timely pick-up, comfortable vehicles, and professional drivers. Experience a smooth journey as you visit this sacred site, making your trip both memorable and spiritually fulfilling."
    },
    {
        "name": "Shirdi to Nashik One Day Trip",
        "description": "Plan a one day trip from Shirdi to Nashik with our one way cab service, perfect for exploring the beautiful sights. Our service allows you to discover the renowned vineyards, temples, and scenic landscapes of Nashik without the stress of navigation or logistics. We ensure a delightful travel experience, making your day trip enjoyable and hassle-free."
    },
    {
        "name": "Shirdi to Nashik One Way Cab Booking",
        "description": "Easily book your one way cab from Shirdi to Nashik with us. Our simple booking process makes travel planning a breeze, whether you’re arranging a last-minute trip or planning in advance. Enjoy the convenience of a dedicated vehicle and driver, allowing you to explore Nashik at your own pace."
    },
    {
        "name": "Shirdi to Nasik Trimbakeshwar Tour Package",
        "description": "Explore our tour package from Shirdi to Nasik Trimbakeshwar, including one way cab service for your convenience. This package offers a combination of spiritual exploration and comfortable transportation, allowing you to visit the sacred Trimbakeshwar Temple while enjoying a relaxed journey."
    },
    {
        "name": "Shirdi to Shani Shingnapur One Way Cab",
        "description": "We offer a one way cab service from Shirdi to Shani Shingnapur for a spiritual journey that is comfortable and stress-free. Our experienced drivers ensure a smooth ride, allowing you to focus on your pilgrimage while we take care of the rest. Experience the peace of mind that comes with reliable transportation."
    },
    {
        "name": "Shirdi to Navi Mumbai One Way Cab",
        "description": "Travel from Shirdi to Navi Mumbai with our one way cab service. Enjoy a comfortable ride at your convenience, with flexible pick-up and drop-off options tailored to your schedule. We guarantee a seamless journey that meets your travel needs."
    },
    {
        "name": "Shirdi to Hyderabad One Way Cab",
        "description": "Our Shirdi to Hyderabad one way cab service ensures a smooth and enjoyable journey. We cater to your travel needs with well-maintained vehicles and professional drivers who are committed to providing you with a safe and pleasant travel experience."
    },
    {
        "name": "Innova Crysta on Rent in Shirdi for One Way",
        "description": "Rent an Innova Crysta for your one way trip in Shirdi. Enjoy luxury and comfort during your travels, perfect for families or groups looking for ample space. Our Innova Crysta vehicles come equipped with modern amenities, ensuring that your journey is both enjoyable and memorable."
    },
    {
        "name": "Shirdi One Way Cab Service Contact Information",
        "description": "For bookings or inquiries, please reach out to SaitirthaTours & Travels at +91 9923879009 / +91 9922479009. Let us help you make your journey memorable! Our dedicated team is ready to assist you with all your travel needs, ensuring a smooth and enjoyable experience from start to finish."
    }
],

tableData: [
    ["- Shirdi One Way Cab Services Rates", "- Shirdi to Mumbai Cab One Way"],
    ["- Shirdi to Mumbai Cab One Way Fare", "- Shirdi to Pune Cab One Way"],
    ["- Shirdi to Pune Aurangabad One Way Cab", "- Shirdi to Bhimashankar One Way Cab"],
    ["- Shirdi to Nagpur One Way Taxi", "- Shirdi to Mahakaleshwar One Way Taxi"],
    ["- Shirdi to Nashik One Day Trip", "- Shirdi to Nashik One Way Cab Booking"],
    ["- Shirdi to Nasik Trimbakeshwar Tour Package", "- Shirdi to Shani Shingnapur One Way Cab"],
    ["- Shirdi to Navi Mumbai One Way Cab", "- Shirdi to Hyderabad One Way Cab"],
    ["- Innova Crysta on Rent in Shirdi for One Way", ""]
  ],
  
  whychoose: [
    {
      "WhyChooseheading": "Convenience of One-Way Travel",
      "WhyChoosedescription": "Our one-way cab service is ideal for those who wish to travel directly from Shirdi to their destination without the hassle of a return trip. This service is perfect for both solo travelers and groups, offering a convenient, stress-free solution for one-way journeys. Whether you're heading to Mumbai, Pune, or any other destination, we make sure you travel comfortably with a seamless experience from start to finish."
    },
    {
      "WhyChooseheading": "Transparent Pricing",
      "WhyChoosedescription": "We believe in transparency when it comes to pricing. With our one-way cab service, you will know the total cost upfront, so there are no surprises along the way. Our competitive rates are designed to give you the best value for your money, ensuring that you get high-quality service at a reasonable price. Our clear pricing structure makes budgeting easy and convenient for all our customers."
    },
    {
      "WhyChooseheading": "Reliable Fleet",
      "WhyChoosedescription": "Our fleet consists of well-maintained vehicles equipped with the latest amenities, ensuring you have a safe, comfortable, and hassle-free journey. Whether you're traveling solo or with a group, we have the right vehicle to suit your needs. From compact cars to spacious SUVs and luxurious Innova Crystas, our fleet is ready to take you wherever you need to go with ease."
    },
    {
      "WhyChooseheading": "Experienced Drivers",
      "WhyChoosedescription": "Our drivers are the key to providing a smooth and efficient one-way trip. With years of experience and knowledge of the best routes, traffic patterns, and safety protocols, our drivers ensure you arrive at your destination on time and safely. Their courteous and professional service is what makes your journey comfortable and reliable, whether you're traveling short or long distances."
    },
    {
      "WhyChooseheading": "Flexible Booking Options",
      "WhyChoosedescription": "We offer a flexible booking system that makes securing your one-way cab service simple and hassle-free. You can book your ride online through our user-friendly website or by calling our customer service team directly. Our booking process is designed to be quick and easy, so you can focus on your journey while we take care of the logistics."
    },
    {
      "WhyChooseheading": "24/7 Availability",
      "WhyChoosedescription": "Our one-way cab services are available around the clock to accommodate any travel schedule. Whether you're planning an early morning departure or need a ride late at night, we are always here to meet your transportation needs. Our 24/7 availability ensures that you have access to reliable transportation whenever you need it, making your travel plans more flexible and stress-free."
    },
    {
      "WhyChooseheading": "Customer-Centric Approach",
      "WhyChoosedescription": "We prioritize customer satisfaction above all else. From booking your ride to the moment you are dropped off at your destination, we go the extra mile to ensure that your needs are met. Our dedicated customer support team is available to assist you with any inquiries, help you with modifications to your booking, or address any concerns during your journey. Your satisfaction is our number one priority."
    },
    {
      "WhyChooseheading": "Comfort and Cleanliness",
      "WhyChoosedescription": "We maintain the highest standards of cleanliness and hygiene. Our vehicles are thoroughly sanitized before each trip to ensure a clean and safe environment for our passengers. With well-maintained interiors, air conditioning, and ample seating, you can rest assured that your ride will be comfortable and pleasant throughout the journey."
    },
    {
      "WhyChooseheading": "Positive Customer Feedback",
      "WhyChoosedescription": "Our one-way cab service has received consistently positive feedback from customers who appreciate our commitment to quality and reliability. We take pride in the trust our customers place in us, and their testimonials speak to the high standards of service we provide. With numerous repeat customers and referrals, we strive to continue delivering excellent experiences every time."
    },
    {
      "WhyChooseheading": "Seamless Experience",
      "WhyChoosedescription": "We aim to provide a seamless and effortless travel experience from start to finish. From the moment you book your cab to the moment you reach your destination, we ensure that all logistics are handled with care. Our goal is to make your journey as smooth, comfortable, and stress-free as possible, so you can focus on enjoying your trip rather than worrying about transportation."
    }
  ]
  




        
  }


  const faqData = [
    {
        question: "What destinations do you cover for one-way cab service from Shirdi?",
        answer: "We provide one-way cab services to various locations, including Mumbai, Pune, Nasik, Aurangabad, and more."
    },
    {
        question: "How is the pricing calculated for one-way trips?",
        answer: "Pricing is based on the distance between Shirdi and your destination, along with any applicable surcharges."
    },
    {
        question: "Can I book a one-way cab at the last minute?",
        answer: "Yes, we accommodate last-minute bookings based on vehicle availability. However, advance booking is recommended for guaranteed service."
    },
    {
        question: "Are there any additional charges for one-way cab service?",
        answer: "Additional charges may apply depending on the route and travel time, which will be communicated during the booking process."
    },
    {
        question: "Is it possible to change my booking after it's confirmed?",
        answer: "Yes, you can modify your booking based on availability. Please contact us as soon as possible to make changes."
    },
    {
        question: "Do you provide child seats for one-way trips?",
        answer: "Yes, child seats are available upon request. Please inform us during your booking for proper arrangements."
    },
    {
        question: "What if my flight or train is delayed?",
        answer: "We monitor flight and train schedules to adjust your pick-up time accordingly, ensuring you are not charged for delays beyond your control."
    },
    {
        question: "Can I request a specific vehicle for my one-way trip?",
        answer: "Yes, you can request a specific vehicle type based on availability at the time of booking."
    },
    {
        question: "How can I book a one-way cab?",
        answer: "You can easily book a one-way cab online through our website or by calling our customer service team."
    },
    {
        question: "What is your contact information for inquiries?",
        answer: "Our customer support team is available via phone or email to assist you with any questions or bookings."
    }
];

const testimonials = [
    {
        name: "Mr. Rajesh Kulkarni",
        text: "I recently used Saitirtha Travels for their one way cab service from Shirdi, and it was a fantastic experience! The booking was quick and easy, and the driver arrived on time. The ride was comfortable and hassle-free. I highly recommend Saitirtha Travels for anyone needing a reliable one way service!"
    },
    {
        name: "Ms. Manisha Joshi",
        text: "The one way cab service from Shirdi provided by Saitirtha Travels was exceptional! The car was clean and well-maintained, and the driver was very professional. The whole process was smooth, and I felt at ease throughout the journey. I will definitely use their services again!"
    }
];




  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>
<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Shirdi One Way Cab Service | Affordable Taxi Booking</title>
    <meta name="description" content="Book One Way Cab Service from Shirdi with Saitirtha Travels. We offer affordable and reliable one way taxi services for destinations like Mumbai, Pune, Nagpur, Nashik, and more. Rent cars like Innova Crysta for your one way trip." />
    <meta name="keywords" content="Shirdi One Way Cab Service, Shirdi to Mumbai cab one way, Shirdi to Pune cab one way, Shirdi to Aurangabad one way cab, Shirdi to Bhimashankar one way cab, Shirdi to Nagpur one way taxi, Shirdi to Mahakaleshwar one way taxi, Shirdi to Nashik one day trip, Shirdi to Shani Shingnapur one way cab, Shirdi to Navi Mumbai one way cab, Shirdi to Hyderabad one way cab, Innova Crysta on rent in Shirdi for one way" />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirtha Travels | Shirdi One Way Cab Service | Affordable Taxi Booking" />
    <meta property="og:description" content="Book One Way Cab Service from Shirdi with Saitirtha Travels. We provide affordable taxi services for one way trips to cities like Mumbai, Pune, Nashik, Nagpur, and more." />
    <meta property="og:url" content="https://saitirthatravels.com/shirdi-one-way-cab-service" />
    <meta property="og:image" content="https://saitirthatravels.com/images/shirdi-one-way-cab.jpg" />
    <meta name="twitter:card" content="summary_large_image" />
    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Book One Way Cab Service from Shirdi with Saitirtha Travels. Affordable and reliable taxi services for one way trips to various destinations like Mumbai, Pune, Nashik, Nagpur, and more.",
            "url": "https://saitirthatravels.com/shirdi-one-way-cab-service",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/shirdi-one-way-cab.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "250"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi One Way Cab Service",
                "Shirdi to Mumbai cab one way",
                "Shirdi to Pune cab one way",
                "Shirdi to Aurangabad one way cab",
                "Shirdi to Bhimashankar one way cab",
                "Shirdi to Nagpur one way taxi",
                "Shirdi to Mahakaleshwar one way taxi",
                "Shirdi to Nashik one day trip",
                "Shirdi to Shani Shingnapur one way cab",
                "Shirdi to Navi Mumbai one way cab",
                "Shirdi to Hyderabad one way cab",
                "Innova Crysta on rent in Shirdi for one way"
            ]
        })}
    </script>
</Helmet>


      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/Keyword 5-06.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirdionewaycabservice;