import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faPeopleCarry, faUserFriends, faHeadset } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
import '../css/Career.css';

const CareerItem = ({ icon, title, description }) => {
    return (
        <div className="col-lg-6 mb-4">
            <div className="career-item p-4 shadow-sm rounded">
                <div className="career-icon mb-3 text-center">
                    <FontAwesomeIcon icon={icon} size="3x" className="text-primary" />
                </div>
                <h4 className="career-title text-center">
                    <a href="#" className="text-dark">{title}</a>
                </h4>
                <p>{description}</p>
            </div>
        </div>
    );
};

const CareersSection = () => {
    return (
        <div>

<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Ertiga On Rent in Shirdi | Kia Carens on rent in Shirdi | Affordable and Reliable Service</title> 
    <meta name="description" content="Book Ertiga on Rent in Shirdi with Sai Tirth Cabs. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Reliable and comfortable mini bus rental services." />
    <meta name="keywords" content="Force Urbania on Rent in Shirdi, Urbania on Rent in Manmad, Force Urbania on Rent in Shani Shingnapur, Shirdi to Bhimashankar Urbania on Rent, Shirdi to Nashik Force Urbania on Rent, Mini Bus on Rent in Shirdi, Luxury Force Urbania on Rent in Shirdi, 13 Seater Urbania on Rent, 17 Seater Urbania on Rent, Shirdi Airport Urbania on Rent, Sai Tirth Cabs Shirdi, Mini AC Bus on Rent, Mini Luxury Bus on Rent in Shirdi, Aura on Rent in Shirdi, Ertiga on Rent in Shirdi, Scorpio on Rent in Shirdi, Kia Carens on Rent in Shirdi" />
    <meta property="og:title" content="Contact: +91 9923879009 | Force Urbania On Rent in Shirdi | Luxury Urbania Bus Rental | Affordable and Reliable Service" />
    <meta property="og:description" content="Book Force Urbania on Rent in Shirdi with Sai Tirth Cabs. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Reliable and comfortable mini bus rental services." />
    <meta property="og:url" content="https://saitirthatravels.com/force-urbania-on-rent-in-shirdi" />
    <meta property="og:image" content="https://saitirthatravels.com/images/urbania-on-rent.jpg" />
    <meta name="twitter:card" content="summary_large_image" />
    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "Sai Tirth Cabs Shirdi",
            "description": "Book Force Urbania on Rent in Shirdi. Rent a 13-seater or 17-seater Urbania for your Shirdi trips, Shani Shingnapur, Nashik, Bhimashankar, and more. Affordable and reliable mini bus rental services.",
            "url": "https://saitirthatravels.com/force-urbania-on-rent-in-shirdi",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/urbania-on-rent.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "250"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Force Urbania on Rent in Shirdi",
                "Urbania on Rent in Manmad",
                "Force Urbania on Rent in Shani Shingnapur",
                "Shirdi to Bhimashankar Urbania on Rent",
                "Shirdi to Nashik Force Urbania on Rent",
                "Mini Bus on Rent in Shirdi",
                "Luxury Force Urbania on Rent in Shirdi",
                "13 Seater Urbania on Rent",
                "17 Seater Urbania on Rent",
                "Shirdi Airport Urbania on Rent",
                "Sai Tirth Cabs Shirdi",
                "Aura on Rent in Shirdi",
                "Ertiga on Rent in Shirdi",
                "Scorpio on Rent in Shirdi",
                "Kia Carens on Rent in Shirdi"
            ]
        })}
    </script>
</Helmet>

            <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
                <div className="auto-container">
                    <div className="title-outer text-center">
                        <h1 className="title">Join the Saitirtha Travels Family</h1>
                    </div>
                </div>
            </section>

            <section aria-label="Careers" className="bg-dark py-5 mb-3">
                <div className="container px-0">
                    <div className="row g-2">
                        <CareerItem 
                            icon={faCar}
                            title="Professional Driver"
                            description="Become a Professional Driver at Saitirtha Travels! You’ll play a vital role in creating memorable journeys for our clients. Your expertise and dedication will ensure that every ride is safe, comfortable, and enjoyable. Join us in shaping unforgettable travel experiences!"
                        />
                        <CareerItem
                            icon={faPeopleCarry}
                            title="Client Relations Specialist"
                            description="As a Client Relations Specialist, you'll be the voice of Saitirtha Travels. Your responsibilities will include addressing client inquiries, resolving concerns, and ensuring that every customer feels valued and heard. Help us build lasting relationships and enhance our service reputation!"
                        />
                        <CareerItem
                            icon={faUserFriends}
                            title="Travel Consultant"
                            description="Step into the role of a Travel Consultant with Saitirtha Travels. Use your knowledge to guide clients in crafting their perfect travel itineraries. Your passion for travel and customer service will help clients navigate their journey with ease and joy!"
                        />
                        <CareerItem
                            icon={faHeadset}
                            title="Operations Manager"
                            description="Join our team as an Operations Manager at Saitirtha Travels. You’ll oversee the logistics of our transport services, ensuring that everything runs smoothly behind the scenes. Your organizational skills will be key in delivering exceptional service to our valued customers!"
                        />
                    </div>
                </div>
            </section>
        </div>
    );
};

export default CareersSection;
