
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function Shirditoshegaon() {



  const cardData =
  {
    keyword: '  Shirdi to Shegaon Cab',
    heading: 'Saitirtha Travels: Shirdi to Shegaon Cab',
    headingDescription: 'Traveling from Shirdi to Shegaon is a seamless experience with Saitirtha Travels. Our reliable and comfortable cab services are tailored to provide you with a smooth journey, whether you are visiting for pilgrimage or leisure. We are committed to ensuring that your travel experience is enjoyable and hassle-free.',

    top: 'Top Places to Visit in Shegaon with Saitirtha Travels',

 "topPlaces": [
    {
        "title": "Gajanan Maharaj Temple",
        "description": "Gajanan Maharaj Temple stands as a beacon of spirituality and devotion in Shegaon. Dedicated to the revered saint Gajanan Maharaj, this temple is a major pilgrimage site that attracts thousands of devotees each year. The temple's architecture showcases intricate carvings and a majestic dome, creating a visually stunning environment that enhances the spiritual experience. The daily aarti, a devotional ritual involving the offering of light, resonates with deep spirituality, while special events during festivals such as Gudi Padwa and Hanuman Jayanti transform the temple into a vibrant hub of activity. These celebrations are marked by devotional singing, dancing, and large gatherings, infusing the atmosphere with joy and devotion. The peaceful ambiance, combined with the presence of the saint's idol, creates a sanctuary for worshippers seeking solace and blessings. Visitors often find themselves captivated by the serene beauty of the temple grounds, where they can meditate and reflect amidst the hustle of everyday life."
    },
    {
        "title": "Shegaon Railway Station",
        "description": "Shegaon Railway Station is more than just a transit point; it is a remarkable historical site known for its cleanliness and architectural elegance. Established in the 19th century, the station reflects the region's rich heritage with its unique blend of modern functionality and traditional aesthetics. The station's well-maintained facilities ensure a comfortable experience for travelers, making it a notable landmark for both locals and visitors. The architecture features arches, columns, and detailed stonework, reminiscent of the colonial style, providing a glimpse into the past. Travelers often take time to appreciate the station's design while waiting for their trains, capturing beautiful photographs of the historical structure. As an essential junction in the railway network, the station serves as a gateway to the spiritual and cultural attractions of Shegaon, making it an integral part of the journey for pilgrims heading to the Gajanan Maharaj Temple and other local sites."
    },
    {
        "title": "Anand Sagar",
        "description": "Anand Sagar is a beautifully landscaped park that offers a serene retreat for both locals and tourists. Spanning across several acres, this scenic park features a large lake surrounded by lush gardens, walking paths, and picturesque picnic spots. Visitors can enjoy the calming ambiance as they stroll along the water's edge, take in the vibrant flora, or simply relax on benches while listening to the soothing sounds of nature. A highlight of Anand Sagar is the miniature replica of the Gajanan Maharaj Temple, which adds a spiritual touch to the park. The area also hosts various attractions such as children's play areas, boating facilities, and vibrant flower beds that bloom in different seasons, creating a colorful landscape. Families often gather here for outings, making it an ideal place for leisurely afternoons, meditation, or enjoying the company of loved ones in a peaceful setting."
    },
    {
        "title": "Kusumagraj Memorial",
        "description": "The Kusumagraj Memorial is a tribute to the illustrious Marathi poet Kusumagraj, celebrated for his profound impact on Marathi literature and culture. This memorial serves as a cultural hub, showcasing exhibits that highlight his literary works, personal artifacts, and contributions to society. Visitors can explore the well-curated displays that narrate the poet's journey, including his famous poems, novels, and plays, which continue to inspire generations. The peaceful surroundings of the memorial feature gardens and quiet areas for reflection, making it an ideal spot for literary enthusiasts and those seeking inspiration. The memorial often hosts cultural events, readings, and workshops, fostering a deeper appreciation for Marathi literature. By immersing themselves in the legacy of Kusumagraj, visitors can gain insights into the social issues of his time and the enduring power of poetry to shape thought and culture."
    },
    {
        "title": "Sai Mandir",
        "description": "Sai Mandir, located a short distance from Shegaon, is a revered spiritual site dedicated to the beloved saint Sai Baba. The temple is renowned for its tranquil ambiance, featuring beautiful architecture that reflects the simplicity and humility of Sai Baba's teachings. Devotees flock to the temple to seek blessings and participate in various spiritual activities, including daily prayers and community gatherings. The temple grounds are adorned with vibrant flowers and peaceful courtyards, providing a welcoming space for meditation and reflection. Special occasions such as Sai Baba's birth anniversary attract large crowds, where the atmosphere buzzes with devotional fervor, bhajans, and community meals. The temple serves as a center for spiritual learning, where visitors can delve into the teachings of Sai Baba, emphasizing love, compassion, and service to others. This makes Sai Mandir not just a place of worship, but a nurturing environment for personal growth and spiritual development."
    },
    {
        "title": "Bharadi Devi Temple",
        "description": "Nestled atop a hill, Bharadi Devi Temple is dedicated to the goddess Bharadi and offers breathtaking views of the surrounding landscape. The temple, known for its spiritual significance, attracts a multitude of devotees, especially during festivals when elaborate rituals and celebrations take place. The journey to the temple involves a scenic trek, rewarding visitors with stunning vistas of the countryside and the tranquil atmosphere of the hills. As one approaches the temple, the air is filled with the sounds of devotional songs and the scent of incense, enhancing the spiritual experience. The temple grounds are adorned with colorful flags and traditional decorations, creating a vibrant setting for worship. Devotees often come to seek blessings for prosperity, health, and happiness. The panoramic views from the temple provide a peaceful retreat, allowing visitors to connect with nature and reflect on their spiritual journey."
    },
    {
        "title": "Shegaon Water Park",
        "description": "Shegaon Water Park is a lively and exciting destination designed for family fun and adventure. The park boasts a wide range of water attractions, including thrilling water slides, lazy rivers, and wave pools, catering to visitors of all ages. Families can enjoy a day filled with laughter and excitement, creating unforgettable memories as they splash around in the refreshing water. The park also features landscaped gardens, relaxation areas, and snack stalls offering delicious treats to keep visitors energized. Lifeguards are present throughout the park, ensuring the safety of all guests. Special events, like pool parties and themed nights, add to the excitement and draw large crowds during peak seasons. Whether you’re looking to cool off in the summer heat or simply have a fun day out, Shegaon Water Park provides a perfect escape filled with enjoyment and relaxation."
    },
    {
        "title": "Shri Gajanan Maharaj Sansthan",
        "description": "Shri Gajanan Maharaj Sansthan is a significant religious institution dedicated to the teachings and philosophy of Gajanan Maharaj. The Sansthan is committed to preserving and promoting the saint's values of love, service, and humility. Visitors can immerse themselves in various spiritual activities, including attending lectures, participating in community service, and engaging in prayer sessions. The institution also runs several charitable initiatives aimed at helping the underprivileged, embodying the spirit of selfless service that Gajanan Maharaj advocated. The serene environment of the Sansthan, combined with its emphasis on spiritual growth, offers a nurturing space for visitors to deepen their understanding of their faith. Regular spiritual discourses and festivals held at the Sansthan attract numerous devotees, creating a vibrant atmosphere of devotion and community engagement."
    },
    {
        "title": "Shree Ram Temple",
        "description": "Shree Ram Temple is an architectural marvel dedicated to Lord Rama, attracting both devotees and architecture enthusiasts. The temple features intricate designs, elaborate carvings, and beautiful frescoes that depict scenes from the Ramayana, immersing visitors in the rich storytelling tradition of Indian mythology. The serene atmosphere within the temple provides a peaceful space for worship, reflection, and prayer. Special rituals and festivals like Ram Navami draw large crowds, where devotees engage in bhajans and community celebrations, creating a lively and spiritual ambiance. The temple also serves as a cultural center, hosting various events and workshops that promote values of righteousness and devotion. Visitors can spend time contemplating the teachings of Lord Rama, making it not just a place of worship but also a center for spiritual learning."
    },
    {
        "title": "Local Markets",
        "description": "Exploring the local markets in Shegaon provides a vibrant taste of the region's rich culture and heritage. The bustling markets are filled with colorful stalls offering a variety of traditional handicrafts, religious items, and mouthwatering local snacks. Shoppers can find unique souvenirs, such as handmade crafts and traditional attire, while also indulging in delicious local delicacies like sweets and savory snacks. Engaging with local vendors offers insights into daily life in Shegaon and the traditions that shape this charming town. The lively atmosphere of the markets, filled with sounds of bargaining and laughter, creates an immersive cultural experience. Whether you're looking to shop or simply soak in the local vibe, the markets of Shegaon are a must-visit destination that adds depth to your travel experience."
    }
]
,
    
            
        
"services": [
    {
        "name": "Shirdi to Shegaon Cab",
        "description": "Planning a trip from Shirdi to Shegaon? Our Shirdi to Shegaon cab service provides a seamless travel experience, allowing you to enjoy the journey while we take care of your transportation needs. With our commitment to comfort and reliability, you can focus on your pilgrimage or leisure activities while we ensure you reach your destination safely. Each ride is tailored to enhance your travel experience, making it as enjoyable as possible."
    },
    {
        "name": "Shirdi to Shegaon Cab Service",
        "description": "Our Shirdi to Shegaon cab service is designed to cater to a variety of travel requirements. Whether you’re traveling for pilgrimage, leisure, or business, we offer a fleet of well-maintained vehicles to suit your needs. Each vehicle is equipped with modern amenities, including air conditioning, comfortable seating, and ample legroom to enhance your travel experience, making your journey as pleasant as possible."
    },
    {
        "name": "Shirdi Shegaon Taxi Fare",
        "description": "We provide transparent pricing for our Shirdi to Shegaon taxi fare. Our competitive rates ensure you get the best value for your money without any hidden charges. We believe in providing clear and upfront pricing, so you can budget your trip effectively. Additionally, our pricing includes all essential services, ensuring that you won't face unexpected costs during your journey."
    },
    {
        "name": "Best Cab from Shirdi to Shegaon",
        "description": "When searching for the best cab from Shirdi to Shegaon, look no further! Our professional drivers are well-trained and knowledgeable about the route, ensuring a safe and comfortable ride to your destination. We prioritize your safety and comfort, making every trip enjoyable. Our cabs are regularly serviced to maintain high standards of hygiene and reliability, allowing you to travel with peace of mind."
    },
    {
        "name": "Cheap Taxi Shirdi Shegaon",
        "description": "Affordability is important to us. That’s why we offer cheap taxi services from Shirdi to Shegaon, making it possible for everyone to travel comfortably without breaking the bank. Enjoy quality service at budget-friendly prices, so you can focus on your journey rather than expenses. We offer various payment options to make the process even more convenient for you."
    },
    {
        "name": "Book Cab Shirdi Shegaon",
        "description": "Booking a cab from Shirdi to Shegaon is simple and convenient. Use our online booking system to choose your vehicle, confirm your reservation, and receive instant confirmation. You can also call our support team for assistance, ensuring a smooth booking process that meets your needs. Our customer service team is available to help you with any questions or special requests you may have."
    },
    {
        "name": "One-Way Taxi Shirdi to Shegaon",
        "description": "For those needing a one-way taxi from Shirdi to Shegaon, we offer flexible options that allow you to travel on your own terms. Enjoy the freedom to travel without the need for a round trip, providing you with a straightforward solution for your journey. This option is particularly convenient for travelers who may be arriving in Shegaon from different locations."
    },
    {
        "name": "Round Trip Cab Shirdi Shegaon",
        "description": "If you're planning to return, our round trip cab service from Shirdi to Shegaon is ideal. Enjoy a smooth journey both ways, with the convenience of having your transportation arranged in advance. Our round trip service ensures your travel plans are hassle-free, allowing you to enjoy your time in Shegaon without worrying about how to get back."
    },
    {
        "name": "Car Rental Shirdi to Shegaon",
        "description": "Our car rental services from Shirdi to Shegaon give you the flexibility to explore at your own pace. Choose from a range of vehicles to find the one that best fits your travel plans, whether for a short visit or an extended stay. Enjoy the independence of having your own vehicle while we provide the option of a driver if needed, making your journey more convenient."
    },
    {
        "name": "Reliable Cab Shirdi Shegaon",
        "description": "Reliability is key in our service. With our reliable cab service from Shirdi to Shegaon, you can trust that your driver will be punctual and well-versed in navigating the route, ensuring a timely arrival. Your satisfaction and peace of mind are our top priorities, and our drivers are trained to assist with any needs you may have during your trip."
    },
    {
        "name": "Distance Shirdi Shegaon Taxi",
        "description": "The distance from Shirdi to Shegaon is approximately 270 kilometers, making it a manageable journey. Our experienced drivers are familiar with the road, ensuring a smooth ride throughout the trip. We monitor traffic conditions to take the best routes, minimizing delays and enhancing your travel experience."
    },
    {
        "name": "Shirdi to Shegaon Cab Fare",
        "description": "The Shirdi to Shegaon cab fare varies based on the type of vehicle and duration of the journey. For a personalized quote, feel free to contact us, and we'll provide detailed pricing options tailored to your specific requirements. We ensure that all our rates are competitive, making it easy for you to choose the right service within your budget."
    },
    {
        "name": "Shirdi to Shegaon Tempo Traveller On Rent",
        "description": "Traveling with a group? Our Shirdi to Shegaon Tempo Traveller on rent is perfect for larger parties. It provides ample space for both passengers and luggage, ensuring a comfortable trip. Enjoy the journey together, making lasting memories along the way. Our Tempo Travellers are equipped with modern amenities to keep everyone comfortable during the ride."
    },
    {
        "name": "Shirdi to Shegaon Innova on Rent",
        "description": "For a more luxurious experience, consider our Shirdi to Shegaon Innova on rent. Known for its spacious interiors and smooth ride, the Innova is ideal for long journeys and provides both comfort and style, making your trip more enjoyable. The Innova is perfect for families or small groups looking to travel in comfort and elegance."
    },
    {
        "name": "Shirdi to Shegaon Mini Bus on Rent",
        "description": "If you're organizing a larger event or trip, our Shirdi to Shegaon mini bus on rent is the perfect solution. It can accommodate larger groups, making it ideal for family gatherings, school trips, or corporate outings. Travel together comfortably without the stress of arranging multiple vehicles. Our mini buses are maintained to high standards, ensuring safety and comfort throughout your journey."
    },
    {
        "name": "Shirdi to Shegaon Packages",
        "description": "We offer a variety of Shirdi to Shegaon packages that include transportation, sightseeing, and more. These packages provide great value and convenience for those looking to explore the region without the hassle of planning every detail. Enjoy a curated experience that allows you to focus on enjoying your trip rather than logistics."
    },
    {
        "name": "Shirdi Shegaon Tour Packages",
        "description": "Explore the spiritual and cultural sites with our Shirdi Shegaon tour packages. These packages often include guided tours and visits to significant locations, ensuring you have a comprehensive experience that enriches your understanding of the area. Our guides are knowledgeable and can provide insights that enhance your visit."
    },
    {
        "name": "Shirdi to Shegaon Cab Contact Information",
        "description": "For more information or to make a booking, contact SaitirthaTours & Travels at +91 9923879009 / 9922479009. Experience a hassle-free journey with our Shirdi to Shegaon cab services. Our dedicated team is here to assist you with all your travel needs, ensuring you have a pleasant and memorable experience from start to finish."
    }
],
"tableData": [
    ["- Shirdi to Shegaon Cab Service", "- Shirdi Shegaon Taxi Fare"],
    ["- Best Cab from Shirdi to Shegaon", "- Cheap Taxi Shirdi Shegaon"],
    ["- Book Cab Shirdi Shegaon", "- One-Way Taxi Shirdi to Shegaon"],
    ["- Round Trip Cab Shirdi Shegaon", "- Car Rental Shirdi to Shegaon"],
    ["- Reliable Cab Shirdi Shegaon", "- Distance Shirdi Shegaon Taxi"],
    ["- Shirdi to Shegaon Cab Fare", "- Shirdi to Shegaon Tempo Traveller on Rent"],
    ["- Shirdi to Shegaon Innova on Rent", "- Shirdi to Shegaon Mini Bus on Rent"],
    ["- Shirdi to Shegaon Packages", "- Shirdi Shegaon Tour Packages"],
    ["- SaitirthaTours & Travels Shirdi"]
],
"whychoose": [
    {
        "WhyChooseheading": "Easy Booking Process:",
        "WhyChoosedescription": "We provide a straightforward and user-friendly booking experience that allows you to arrange your travel effortlessly. You can book your cab through our intuitive website or by calling our customer service team. Our booking system is designed to save you time, and we ensure that all confirmations and details are communicated promptly to keep you informed every step of the way."
    },
    {
        "WhyChooseheading": "Comfortable Vehicles:",
        "WhyChoosedescription": "Our cabs are meticulously maintained and equipped with comfortable seating, air conditioning, and modern amenities. We understand the importance of comfort during travel, which is why our vehicles are designed to provide you with a relaxing environment. Whether you're traveling alone or with family, you can enjoy a smooth ride with plenty of legroom and storage for your belongings."
    },
    {
        "WhyChooseheading": "Experienced Drivers:",
        "WhyChoosedescription": "Our team of drivers is not only professional and skilled but also possesses extensive knowledge of the best routes between Shirdi and Shegaon. They are trained to prioritize your safety and comfort, navigating through traffic with ease. Their familiarity with local conditions means they can avoid delays and recommend interesting stops along the way, enhancing your travel experience."
    },
    {
        "WhyChooseheading": "Competitive Pricing:",
        "WhyChoosedescription": "We pride ourselves on offering affordable rates for our cab services, ensuring you receive excellent value for your money. Our transparent pricing model means there are no hidden fees, so you can plan your budget confidently. We regularly review our pricing to remain competitive while maintaining high service quality, so you never have to compromise on your travel experience."
    },
    {
        "WhyChooseheading": "Flexible Itineraries:",
        "WhyChoosedescription": "We understand that every traveler has unique needs, which is why we offer flexible itineraries. Whether you require a one-way ride or a round trip, we can adjust our services to fit your schedule. If your plans change last minute, our team is here to accommodate those changes, ensuring your travel experience is as seamless as possible."
    },
    {
        "WhyChooseheading": "24/7 Customer Service:",
        "WhyChoosedescription": "Our customer support team is available 24/7 to assist you with any questions or concerns regarding your booking. Whether you need help with a last-minute change, want to inquire about our services, or require assistance during your journey, our responsive team is always ready to provide you with the support you need, ensuring peace of mind throughout your travel experience."
    },
    {
        "WhyChooseheading": "Hygiene and Safety Measures:",
        "WhyChoosedescription": "We prioritize the health and safety of our passengers by adhering to strict hygiene standards. Our vehicles are regularly sanitized, and we follow health protocols to ensure a clean and safe environment for all travelers. From thorough cleaning before and after each ride to providing hand sanitizers in our vehicles, we take every precaution to make your journey safe and worry-free."
    },
    {
        "WhyChooseheading": "Positive Reputation:",
        "WhyChoosedescription": "Our commitment to quality service has earned us a positive reputation among our customers. We value customer feedback and continuously strive to improve our services based on their experiences. Many of our clients return to us for their travel needs, reflecting our dedication to providing reliable and satisfactory service. Your trust is our priority, and we work hard to maintain it."
    },
    {
        "WhyChooseheading": "Punctuality Guaranteed:",
        "WhyChoosedescription": "We understand the importance of your time, which is why we guarantee punctuality. Our drivers arrive on time for pick-up, ensuring that you reach your destination as scheduled. We closely monitor traffic conditions and plan our routes in advance to minimize delays, allowing you to travel stress-free and arrive on time for your commitments."
    },
    {
        "WhyChooseheading": "Additional Services Available:",
        "WhyChoosedescription": "In addition to our standard cab services, we can offer extra services upon request to enhance your travel experience. This includes luggage assistance, flexible drop-off points along your route, or even the option to have multiple stops for sightseeing. We are dedicated to providing a personalized experience that caters to all your travel requirements, ensuring your journey is tailored to your preferences."
    }
]



        
  }


  const faqData = [
    {
        question: "How can I book a cab from Shirdi to Shegaon?",
        answer: "You can book a cab through our website or by calling our customer service for assistance."
    },
    {
        question: "What is the distance from Shirdi to Shegaon?",
        answer: "The distance is approximately 260 kilometers, and the travel time is around 5 to 6 hours by road."
    },
    {
        question: "Does the fare cover tolls and taxes?",
        answer: "Yes, our fares include all applicable tolls and taxes, ensuring transparency in our pricing."
    },
    {
        question: "Can I change my booking after it’s confirmed?",
        answer: "Yes, you can modify your booking by contacting our customer support team prior to your travel date."
    },
    {
        question: "What types of vehicles do you offer for the trip?",
        answer: "We provide a variety of vehicles, including sedans, SUVs, and luxury options to cater to your needs."
    },
    {
        question: "Are your drivers experienced and licensed?",
        answer: "Yes, all our drivers are licensed professionals with extensive experience in providing safe transport."
    },
    {
        question: "What payment options are available?",
        answer: "We accept multiple payment methods, including credit/debit cards, UPI, and cash for your convenience."
    },
    {
        question: "Is customer support available 24/7?",
        answer: "Yes, our customer support is available around the clock to assist you with any inquiries."
    },
    {
        question: "Can I book a round trip from Shirdi to Shegaon?",
        answer: "Absolutely! We offer flexible round trip options to suit your travel plans."
    },
    {
        question: "What if there are delays in my travel plans?",
        answer: "Our drivers monitor travel schedules and can adjust pick-up times to accommodate any delays."
    }
];

const testimonials = [
    {
        name: "Mr. Ravi Joshi",
        text: "I booked a cab from Shirdi to Shegaon and had a wonderful experience with Saitirtha Travels. The booking was seamless, and our driver was incredibly friendly and professional. The vehicle was clean and comfortable, making the journey enjoyable. I will definitely recommend this service to friends!"
    },
    {
        name: "Mrs. Seema Rao",
        text: "My trip from Shirdi to Shegaon with Saitirtha Travels was fantastic. The driver arrived right on time and took the best route, ensuring a smooth ride. I appreciated the cleanliness of the cab and the driver's courteous nature. I will choose them for my future travels!"
    }
];



  
  
  
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
     heading: `${cardData.keyword} Contact Number`,
    contactNumbers: [
      "+91 9923879009",
      "+91 9922479009",

    ],
    email: "booking@saitirthatravels.com"
  };


  const Images = [
    {
      place: "/images/keyword-img/16. Best cab service in Shirdi.jpg",
      text: "Best cab service in Shirdi",
    },
    {
      place: "/images/keyword-img/18.jpg",
      text: "Shirdi Sightseeing Cab",
    },
    {
      place: "/images/keyword-img/keyword 5-01.jpg",
      text: "Shirdi to Manmad Cab",
    },
    {
      place: "/images/keyword-img/20.jpg",
      text: "Shirdi to Mumbai Cabs",
    },
    {
      place: "/images/keyword-img/22.jpg",
      text: "Shirdi to Bhimashankar Cab",
    },
    {
      place: "/images/keyword-img/23.jpg",
      text: "Shirdi Darshan Taxi",
    },

    {
      place: "/images/keyword-img/24.jpg",
      text: "Shirdi to Pune Cab",
    },
    {
      place: "/images/keyword-img/25.jpg",
      text: "Shirdi to Ahmednagar Cab",
    },
    {
      place: "/images/keyword-img/26.jpg",
      text: "Shirdi Airport to Shirdi Temple Taxi Fare",
    },
    {
      place: "/images/keyword-img/27.jpg",
      text: "Shirdi to Nasik Taxi Fare",
    },

    {
      place: "/images/keyword-img/28.jpg",
      text: "Shirdi to Trimbakeshwar Cab Fare",
    },
    {
      place: "/images/keyword-img/29.jpg",
      text: "Shirdi Saibaba Taxi Booking Online",
    },

    {
      place: "/images/keyword-img/31.jpg",
      text: "Kopargaon to Shirdi Taxi",
    },
    {
      place: "/images/keyword-img/32.jpg",
      text: "Shirdi to Shani Shingnapur Cab",
    },

    {
      place: "/images/keyword-img/33.jpg",
      text: "Shirdi to Ajanta Ellora Taxi Fare",
    },

    {
      place: "/images/keyword-img/34.jpg",
      text: "Shirdi to Grishneshwar Cab",
    },
    {
      place: "/images/keyword-img/35.jpg",
      text: "Shirdi to Lonavala Taxi",
    },
    {
      place: "/images/keyword-img/36.jpg",
      text: "Shirdi to Mahabaleshwar Cab",
    },
    {
      place: "/images/keyword-img/37.jpg",
      text: "Shirdi to Matheran Taxi",
    },
    {
      place: "/images/keyword-img/38.jpg",
      text: "Shirdi to Kolhapur Taxi",
    },
    {
      place: "/images/keyword-img/39.jpg",
      text: "Shirdi to Konkan Darshan Cab",
    },
    {
      place: "/images/keyword-img/40.jpg",
      text: "Cabs for Events in Shirdi",
    },
    {
      place: "/images/keyword-img/41.jpg",
      text: "Monthly Cab Service Shirdi",
    },
    {
      place: "/images/keyword-img/42.jpg",
      text: "Corporate Cab Services in Shirdi",
    },
    {
      place: "/images/keyword-img/43.jpg",
      text: "Kalyan to Shirdi Car Rental",
    },
    {
      place: "/images/keyword-img/44.jpg",
      text: "Shirdi to Pandharpur Cab",
    },
    {
      place: "/images/keyword-img/45.jpg",
      text: "Shirdi to Ashtavinayak Darshan Cab",
    },
    {
      place: "/images/keyword-img/46.jpg",
      text: "Shirdi to Pune Airport Cab",
    },
    {
      place: "/images/keyword-img/47.jpg",
      text: "Shirdi to Verul Car Rental",
    },
  ];


  const images = [
    { src: "/images/gallery/Img for Gallery-600x400px-01.jpg", alt: "Image 1" },
    { src: "/images/gallery/Img for Gallery-600x400px-02.jpg", alt: "Image 2" },
    { src: "/images/gallery/Img for Gallery-600x400px-03.jpg", alt: "Image 3" },
    { src: "/images/gallery/Img for Gallery-600x400px-04.jpg", alt: "Image 4" },
    { src: "/images/gallery/Img for Gallery-600x400px-05.jpg", alt: "Image 5" },
    { src: "/images/gallery/Img for Gallery-600x400px-06.jpg", alt: "Image 6" },
    { src: "/images/gallery/Img for Gallery-600x400px-07.jpg", alt: "Image 7" },
    { src: "/images/gallery/Img for Gallery-600x400px-08.jpg", alt: "Image 8" },
    { src: "/images/gallery/Img for Gallery-600x400px-09.jpg", alt: "Image 9" },
    { src: "/images/gallery/Img for Gallery-600x400px-10.jpg", alt: "Image 10" },
    { src: "/images/gallery/Img for Gallery-600x400px-11.jpg", alt: "Image 11" },
    { src: "/images/gallery/Img for Gallery-600x400px-12.jpg", alt: "Image 12" },
    { src: "/images/gallery/Img for Gallery-600x400px-13.jpg", alt: "Image 13" },
    { src: "/images/gallery/Img for Gallery-600x400px-14.jpg", alt: "Image 14" },
    { src: "/images/gallery/Img for Gallery-600x400px-15.jpg", alt: "Image 15" },
  ];




  return (
    <div>

<Helmet>
    <title>Contact: +91 9923879009 | Saitirtha Travels | Shirdi to Shegaon Cab Service | Affordable Taxi Service | SaitirthaTours & Travels</title>
    <meta name="description" content="Book reliable and affordable cab services from Shirdi to Shegaon. One-way and round-trip options, including Innova, Tempo Traveller & Mini Bus rentals. Book now!" />
    <meta property="og:title" content="Contact: +91 9923879009 | Saitirtha Travels | Shirdi to Shegaon Cab Service | Affordable Taxi Service | SaitirthaTours & Travels" />
    <meta property="og:description" content="Book reliable and affordable cab services from Shirdi to Shegaon. One-way and round-trip options, including Innova, Tempo Traveller & Mini Bus rentals. Book now!" />
    <meta property="og:url" content="https://saitirthatravels.com/shirdi-to-shegaon-cab" />
    <meta property="og:image" content="https://saitirthatravels.com/images/shirdi-to-shegaon-cab.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "SaitirthaTours & Travels",
            "description": "Book reliable and affordable cab services from Shirdi to Shegaon. One-way and round-trip options, including Innova, Tempo Traveller & Mini Bus rentals. Book now!",
            "url": "https://saitirthatravels.com/shirdi-to-shegaon-cab",
            "logo": "https://saitirthatravels.com/images/logo.png",
            "image": "https://saitirthatravels.com/images/shirdi-to-shegaon-cab.jpg",
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "reviewCount": "200"
            },
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9923879009",
                "contactType": "Customer Service"
            },
            "keywords": [
                "Shirdi to Shegaon Cab Service",
                "Shirdi Shegaon taxi fare",
                "Best cab from Shirdi to Shegaon",
                "Cheap taxi Shirdi Shegaon",
                "Book cab Shirdi Shegaon",
                "One-way taxi Shirdi to Shegaon",
                "Round trip cab Shirdi Shegaon",
                "Car rental Shirdi to Shegaon",
                "Reliable cab Shirdi Shegaon",
                "Distance Shirdi Shegaon taxi",
                "Shirdi to Shegaon cab fare",
                "Shirdi to Shegaon Tempo Traveller on Rent",
                "Shirdi to Shegaon Innova on Rent",
                "Shirdi to Shegaon mini bus on rent",
                "Shirdi to Shegaon Packages",
                "Shirdi Shegaon Tour Packages",
                "SaitirthaTours & Travels Shirdi"
            ]
        })}
    </script>
</Helmet>

      <section className="page-title" style={{ backgroundImage: 'url(images/background/page-title.png)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h3 className="title">{cardData.keyword}</h3>
          </div>
        </div>
      </section>



      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/images/keyword-img/53.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#EE1623', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs {cardData.keyword} for Saitirtha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className='py-3'>
                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div>

                <div className="contact-box Borderr">
                  <h3>{contactData.heading}</h3>
                  <p className='text-black'>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className='darkcolor'>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className='darkcolor'>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}


              <div className="gallery-container">
                <h2 className="gallery-title">Our Fleets</h2>
                <div className="gallery-row">
                  {images.map((image, index) => (
                    <div className="gallery-item col-md-4" key={index}>
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="gallery-image"

                      />
                    </div>
                  ))}
                  <Link to='/gallery' className=' border radius-5 bg-redd p-3 text-white rounded-5'>
                    See Gallery
                  </Link>
                </div>
              </div>

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '3px dotted #EE1623',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{ backgroundColor: '#EE1623' }}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 9923879009" className="d-block  text-white">+91 9923879009</a>
                        <a href="tel:+91 9922479009" className="d-block text-white">+91 9922479009</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@saitirthatravels.com" className=" text-white d-block">
                          booking@saitirthatravels.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{ backgroundColor: '#EE1623' }}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i> Saitirtha Travels:<br />
                          Hotel sai jashan Sana and   <br/>
                          son Road Shirdi,  Taluka Rahata <br/>
                           District Ahmednagar, Maharashtra - 423109
                            
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Shirditoshegaon;